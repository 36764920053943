import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
  Radio,
} from "@mui/material";
import styles from "../../GraphicForm/GraphicForm.module.css";

const GraphicFormLeft = ({
  formData,
  numGroups,
  showInputs,
  scheduleType,
  handleChange,
  handleChangeLabe,
  handleChangeType,
}) => {
  return (
    <FormControl className={styles.graphicFormLeft}>
      <div className={styles.graphicFormLeftSelect}>
        <div>
          <InputLabel id="demo-simple-select-label">Graphic</InputLabel>
          <Select
            className={styles.leftSelect}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="scheduleType"
            value={scheduleType}
            label="Graphic"
            onChange={handleChangeType}
          >
            <MenuItem value="standard">Standart</MenuItem>
            <MenuItem value="shift">Növbəli</MenuItem>
            <MenuItem value="flexible">Sürüşkən</MenuItem>
          </Select>
        </div>

        {showInputs && scheduleType !== "flexible" && (
          <FormControl style={{ width: "100%" }}>
            <InputLabel id={`workType_label`}>İş günləri</InputLabel>
            <Select
              value={formData.workType}
              label="İş günləri"
              name={`workType`}
              labelId={`workType_label`}
              id={`workType`}
              onChange={handleChange}
            >
              <MenuItem value={5}>5 Günlük</MenuItem>
              <MenuItem value={6}>6 Günlük</MenuItem>
            </Select>
          </FormControl>
        )}
        {showInputs && scheduleType === "shift" && (
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Növbələr</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              defaultValue="2 növbəli"
            >
              <FormControlLabel
                value="2 növbəli"
                control={<Radio />}
                label="2 növbəli"
                onChange={handleChangeLabe}
              />
              <FormControlLabel
                value="3 növbəli"
                control={<Radio />}
                label="3 növbəli"
                onChange={handleChangeLabe}
              />
              <FormControlLabel
                value="4 növbəli"
                control={<Radio />}
                label="4 növbəli"
                onChange={handleChangeLabe}
              />
            </RadioGroup>
          </FormControl>
        )}
      </div>
    </FormControl>
  );
};

export default GraphicFormLeft;
