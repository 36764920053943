/* eslint-disable prettier/prettier */
import { api } from "../../api/axiosInterceptor";

export const addRemoval = async (payload, docModel) => {
  try {
    const response = await api.post(
      `/employee/employee-application/manager/ExclusionApplication`,
      payload
    );
    return await response.data;
  } catch (error) {
    console.log("addremoval: ", error);
  }
};

export const getRemovals = async ({ offset, limit, filter }) => {
  try {
    const response = await api.get(
      `/employee/employee-application/manager/?limit=${limit}&offset=${offset}&filter=${filter}`
    );
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const updateRemoval = async (id, body) => {
  try {
    const response = await api.put(
      `employee/employee-application/manager/${id}`,
      { ...body }
    );
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const getRemoval = async (id) => {
  try {
    const response = await api.get(
      `/employee/employee-application/manager/${id}`
    );
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const addExplanation = async (id) => {
  try {
    const response = await api.patch(
      `employee/employee-application/manager/${id}/sendRequest`
    );
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const addExplanationAsFile = async (id, explanationFileAsBase64) => {
  try {
    const response = await api.put(
      `/employee/employee-application/manager/${id}/sendExplanation`,
      { explanationFileAsBase64 }
    );
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};
export const approveRejectStatus = async (id, status, description) => {
  try {
    const response = await api.patch(
      `/employee/employee-application/manager/${id}/status`,
      { status, description }
    );
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const download = async (id) => {
  try {
    const response = await api.get(
      `/employee/employee-application/${id}/file-download`,
      { responseType: "blob" }
    );
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const preview_removal = async (id) => {
  try {
    const response = await api.get(
      `/employee/contract/static/exclusion-order?employeeApplicationId=${id}`
    );
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const create_order_removal = async (id) => {
  try {
    const response = await api.post(
      `/employee/contract/static/exclusion-order?employeeApplicationId=${id}`
    );
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const downloadRemovalApps = async ({
  filter,
  columns,
  isWithFilter,
}) => {
  const url = isWithFilter
    ? `/employee/employee-application/manager/download?columns=${columns}&filter=${filter}`
    : `/employee/employee-application/manager/download?columns=${columns}`;
  return await api.get(url);
};
