import React, { useEffect, useState } from "react";
import { Field, FieldArray, ErrorMessage, useFormikContext } from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Button,
  Checkbox,
  InputAdornment,
  IconButton,
  Typography,
  FormHelperText,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import ClearIcon from "@mui/icons-material/Clear";
import { getCommonData } from "../../api/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkUniqueness } from "../../utils/checkUniquenessForEmployee";

const SocialStatus = ({
  values,
  setSelectedType,
  setOpenAddDialog,
  submitted,
}) => {
  const [socialTypes, setSocialTypes] = useState([]);
  const { validateForm, errors, setErrors, setFieldError, setFieldValue } =
    useFormikContext();

  useEffect(() => {
    const handleSocialType = async () => {
      const response = await getCommonData("socialStatusTypes");
      setSocialTypes(response.data);
    };
    handleSocialType();
  }, [submitted]);

  const handleStatusSelect = (item) => {
    const selectedStatus = socialTypes.find((status) => {
      return status.name === item;
    });
    return selectedStatus;
  };
  return (
    <>
      <FieldArray name="socialStatus">
        {({ push, remove }) => (
          <div>
            {values?.socialStatus &&
              values?.socialStatus.map((form, index) => (
                <div key={index}>
                  <>
                    <Typography mt={3} mb={3} variant="h6">
                      {index + 1})Sosial Status
                    </Typography>

                    {/* status type and date */}
                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Grid sx={{ width: "100%" }}>
                        <FormControl
                          error={
                            errors?.socialStatus?.[index]?.statusType &&
                            Boolean(errors?.socialStatus?.[index]?.statusType)
                          }
                          fullWidth
                        >
                          <InputLabel id={`socialStatus[${index}].statusType`}>
                            Sosial Status növü *
                          </InputLabel>

                          <Field
                            as={Select}
                            name={`socialStatus[${index}].statusType`}
                            labelId={`socialStatus[${index}].statusType`}
                            label="Sosial Status növü *"
                            id={`socialStatus[${index}].statusType`}
                            defaultValue=""
                            onChange={(event) => {
                              checkUniqueness(
                                values.socialStatus,
                                "statusType",
                                event,
                                setFieldValue,
                                `socialStatus[${index}]`,
                                "sosial status",
                                handleStatusSelect
                              );
                              if (errors?.socialStatus?.[index]?.statusType) {
                                setErrors({
                                  ...errors,
                                  socialStatus: [
                                    ...errors.socialStatus.slice(0, index),
                                    {
                                      ...errors.socialStatus[index],
                                      statusType: "",
                                    },
                                    ...errors.socialStatus.slice(index + 1),
                                  ],
                                });
                              }
                            }}
                            error={
                              errors?.socialStatus?.[index]?.statusType &&
                              Boolean(errors?.socialStatus?.[index]?.statusType)
                            }
                            fullWidth
                          >
                            {socialTypes &&
                              socialTypes
                                .sort((a, b) =>
                                  a?.name?.localeCompare(b?.name, "az")
                                )
                                .map((item, index) => (
                                  <MenuItem key={index} value={item.name}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                            <Button
                              sx={{
                                mt: 3,
                                ml: 3,
                              }}
                              variant="outlined"
                              onClick={() => {
                                setOpenAddDialog(true);
                                setSelectedType([
                                  "socialStatusTypes",
                                  { name: "Sosial statuslar" },
                                ]);
                              }}
                            >
                              Yeni sosial status növü əlavə et
                            </Button>
                          </Field>
                          {errors?.socialStatus?.[index]?.statusType &&
                            errors?.socialStatus?.[index]?.statusType && (
                              <FormHelperText error>
                                {errors?.socialStatus?.[index]?.statusType}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                      <Grid sx={{ width: "100%" }}>
                        <Field name={`socialStatus[${index}].date`}>
                          {({ field, form }) => (
                            <DatePicker
                              {...field}
                              label="Sosial Statusun qüvvəyə minmə tarixi *"
                              inputFormat="dd/MM/yyyy"
                              value={field.value || null}
                              onChange={(date) => {
                                if (date instanceof Date && !isNaN(date)) {
                                  form.setFieldValue(
                                    `socialStatus[${index}].date`,
                                    date
                                  );
                                  if (errors?.socialStatus?.[index]?.date) {
                                    setErrors({
                                      ...errors,
                                      socialStatus: [
                                        ...errors.socialStatus.slice(0, index),
                                        {
                                          ...errors.socialStatus[index],
                                          date: "",
                                        },
                                        ...errors.socialStatus.slice(index + 1),
                                      ],
                                    });
                                  }
                                } else {
                                  form.setFieldValue(
                                    `socialStatus[${index}].date`,
                                    ""
                                  );
                                }
                              }}
                              maxDate={new Date()}
                              minDate={new Date(1940, 0, 1)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  helperText={
                                    form.errors?.socialStatus?.[index]?.date
                                  }
                                  error={Boolean(
                                    form.errors?.socialStatus?.[index]?.date
                                  )}
                                  fullWidth
                                />
                              )}
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>

                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Grid sx={{ width: "100%" }}>
                        <Field
                          type="text"
                          value={values.socialStatus[index].value}
                          as={TextField}
                          name={`socialStatus[${index}].value`}
                          label="Sosial Statusun dəyəri"
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "19px",
                                }}
                              >
                                &#8380;
                              </span>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>

                  <Button
                    sx={{ mt: 3 }}
                    variant="contained"
                    color="error"
                    type="button"
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    Sosial statusu sil
                  </Button>
                </div>
              ))}
            <Button
              sx={{ mt: 3 }}
              variant="contained"
              type="button"
              onClick={() => push({ statusType: "", data: "", value: "" })}
            >
              Sosial status əlavə et
            </Button>
          </div>
        )}
      </FieldArray>
    </>
  );
};

export default SocialStatus;
