import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import CardItem from "./CardItem";

// Services
import * as companyStructureServices from "../../../services/structure/StructureServices";

// Sweetalert
import swal from "sweetalert";

// Toastify
import { toast } from "react-toastify";

const CardList = ({
  data,
  totalCount,
  isDeleted,
  structureId,
  setIsDeleted,
  saveCompanyPartsToStructure,
  handleUploadLogo,
  handleDeleteLogo,
  fetchMoreCompanyStructures,
  setLoading,
}) => {
  const navigate = useNavigate();

  const handleView = (id) => {
    const basePath = window.location.pathname;
    navigate(`${basePath}/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const willDelete = await swal({
        title: "Əminsiniz mi?",
        text: "Bu məlumatı silmək üçün əminsiniz mi?",
        icon: "warning",
        dangerMode: true,
        buttons: ["Ləğv et", "Sil"],
      });

      if (willDelete) {
        const response = await companyStructureServices.deleteCompanyStructure(
          id
        );
        setLoading(false);
        fetchMoreCompanyStructures();
        if (response) {
          // setIsDeleted(!isDeleted);
          // swal("Silindi!", "Seçdiyiniz məlumat uğurla silindi!", "success");
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("error: ", error);
    }
  };

  const _deleteLogo = async (id) => {
    try {
      const response = await companyStructureServices.saveUpdateLogo(
        id,
        undefined,
        true
      );
      if (response) {
        toast.success("Struktur loqosu uğurla silindi!");
        fetchMoreCompanyStructures();
      } else {
        toast.error("Hər hansı bir xəta baş verdi!");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <>
      {data.map((item) => (
        <CardItem
          key={item._id}
          {...item}
          onDelete={() => handleDelete(item._id)}
          onView={() => handleView(item._id)}
          structureId={structureId}
          onSaveCompanyPartsToStructure={saveCompanyPartsToStructure}
          onChange={(e) => handleUploadLogo(e)}
          onDeleteLogo={() => _deleteLogo(item._id)}
        />
      ))}
    </>
  );
};

export default CardList;
