import { api } from "../../api/axiosInterceptor";

export const handleOtp = async (endPoint, payload) => {
  try {
    const res = await api.post(`/users/${endPoint}`, payload);
    return res;
  } catch (error) {
    console.log("error: ", error);
    throw error;
  }
};
