import Cookies from "js-cookie";
import jwt from "jwt-decode";

export const getAccessToken = () => {
  const local = window.localStorage.getItem("accessToken");
  const session = Cookies.get("accessToken");
  const currentTime = Date.now() / 1000;

  if (local) {
    const decoded = jwt(local);
    return !decoded.exp < currentTime ? local : undefined;
  } else if (session) {
    const decoded = jwt(session);

    return !decoded.exp < currentTime ? session : undefined;
  }

  return undefined;
};

export const getRefreshTokenStorage = () => {
  const local = window.localStorage.getItem("refreshToken");
  const session = Cookies.get("refreshToken");
  const currentTime = Date.now() / 1000;

  if (local) {
    const decoded = jwt(local);
    return !decoded.exp < currentTime ? local : undefined;
  } else if (session) {
    const decoded = jwt(session);
    return !decoded.exp < currentTime ? session : undefined;
  }

  return undefined;
};

export const deleteTokensStorage = (clearOpenItem) => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  sessionStorage.removeItem("openItem");
  Cookies.remove("accessToken");
  Cookies.remove("refreshToken");

  if (clearOpenItem) {
    clearOpenItem();
  }
};
