import React, { useEffect, useRef, useState } from "react";
import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { getNotificationSettings } from "../../../services/documentations/EmploymentContractsService";
import * as Yup from "yup";
import { ErrorMessage } from "formik";
import SelectInput from "../../../components/ui/Select/SelectInput";
import styled from "styled-components";

const CustomSelect = styled.div`
  position: relative;
  width: 100%; /* Adjust the width as needed */
  height: 100%;

  select {
    appearance: none;
    outline: 0;
    cursor: pointer;
    width: 100%;
    background-color: #1b2635;
    padding: 5px 10px;
    border: 1px solid #333;
    border-radius: 5px;
    color: white;
    transition: border-color 0.3s ease-in-out;
    position: relative;
    z-index: 1;

    &:focus,
    &:hover {
      border-color: #fff;
    }
  }

  /* Styling the dropdown indicator */
  &::after {
    content: "";
    position: absolute;
    top: calc(50% - 3px);
    right: 10px;
    border-style: solid;
    border-width: 6px 6px 0;
    border-color: white transparent transparent;
    pointer-events: none;
    z-index: 2;
  }
`;

const listDateType = [
  { key: "Gün", value: "day" },
  { key: "Həftə", value: "week" },
  { key: "Ay", value: "month" },
  { key: "İl", value: "year" },
];

const validationSchema = Yup.object().shape({
  dateType: Yup.string().required("Müddət tipini seçin"),
  dateValue: Yup.number()
    .moreThan(0, "Müddət sıfırdan böyük olmalıdır")
    .positive("Müddət sıfırdan böyük olmalıdır")
    .required("Müddət daxil edin"),
});

export default function FilterDropdown({ onClose, dataSend, onSubmit }) {
  const [calculatedDate, setCalculatedDate] = useState(new Date());

  const formikRef = useRef();

  const initialValues = {
    dateType: "",
    dateValue: "",
  };

  useEffect(() => {
    calculateDate();
  }, [initialValues]);

  useEffect(() => {
    getExpiringValue();
  }, []);

  const getExpiringValue = async () => {
    try {
      const { data } = await getNotificationSettings();
      if (data) {
        // initialValues.dateType = data.unit;
        // initialValues.dateValue = data.value;
        formikRef.current.setFieldValue("dateType", data.unit);
        formikRef.current.setFieldValue("dateValue", data.value);
      }
    } catch (error) {}
  };

  // const dateValueHandler = (e) => {
  //   let value = e.target.value;
  //   if (value.length > 1 && value.charAt(0) === "0") {
  //     value = value.charAt(1);
  //   }
  //   if (isNaN(value) || value < 1) value = "";
  //   setDateValue(value);
  // };

  const onHandleChange = (e, key, setFieldValue) => {
    const value = e.target.value;
  };

  const submitHandler = (values) => {
    // You can access the values.dateType and values.dateValue here
    onSubmit(values.dateType, values.dateValue);
  };

  const calculateDate = () => {
    const currentDate = new Date();

    if (
      !initialValues.dateType ||
      !initialValues.dateValue ||
      initialValues.dateValue < 0
    ) {
      return;
    }

    switch (initialValues.dateType) {
      case "day":
        currentDate.setDate(
          currentDate.getDate() + parseInt(initialValues.dateValue)
        );
        break;
      case "week":
        currentDate.setDate(
          currentDate.getDate() + parseInt(initialValues.dateValue) * 7
        );
        break;
      case "month":
        currentDate.setMonth(
          currentDate.getMonth() + parseInt(initialValues.dateValue)
        );
        break;
      case "year":
        currentDate.setFullYear(
          currentDate.getFullYear() + parseInt(initialValues.dateValue)
        );
        break;
      default:
        break;
    }

    setCalculatedDate(currentDate);
  };

  return (
    <Grid sx={styles.container}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
        innerRef={formikRef}
      >
        {({ values, handleChange, handleBlur, setFieldValue }) => (
          <Form>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: "100%",
                padding: "3px",
              }}
            >
              <Grid width={"45%"}>
                <TextField
                  InputProps={{ inputProps: { min: 1 } }}
                  type="number"
                  name="dateValue"
                  label={<span style={{ position: "relative" }}>Müddət</span>}
                  fullWidth
                  variant="outlined"
                  value={values.dateValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputLabelProps={{ shrink: true }}
                />
                <ErrorMessage
                  name="dateValue"
                  component="div"
                  className="error"
                  render={(msg) => (
                    <div
                      style={{
                        position: "absolute",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        padding: "5px",
                        zIndex: 999,
                        backgroundColor: "#fff",
                        bottom: "7px",
                      }}
                    >
                      <span style={{ color: "red" }}>{msg}</span>
                    </div>
                  )}
                />
              </Grid>
              <Grid width={"45%"}>
                {/* <TextField
                  id="dateType"
                  labelId="label-dateType"
                  label={
                    <span style={{ position: "relative" }}>Müddət tipi</span>
                  }
                  select
                  name="dateType"
                  fullWidth
                  value={values.dateType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value="">Müddət tipini seçin</MenuItem>
                // </TextField> */}
                <CustomSelect>
                  <select
                    id="dateType"
                    name="dateType"
                    style={{ width: "100%", height: "100%" }}
                    value={values.dateType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {listDateType?.map((item, index) => (
                      <option key={item.key + index} value={item.value}>
                        {item.key}
                      </option>
                    ))}
                  </select>
                </CustomSelect>

                <ErrorMessage
                  name="dateType"
                  component="div"
                  className="error"
                />
              </Grid>
            </Grid>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "auto",
              }}
            >
              <Button type="submit" variant="contained">
                <Typography>Daxil et</Typography>
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

const styles = {
  container: {
    // padding: "5px",
    height: "auto",
    width: "100%",
  },
};
