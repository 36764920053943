import { toast } from "react-toastify";
import { api } from "./axiosInterceptor";

export const getDesigns = async () => {
  try {
    const response = await api.get("employee/structure-level");
    return response;
  } catch (err) {
    err?.response?.data?.errors?.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const postDesign = async (body) => {
  try {
    const response = await api.post("employee/structure-level", { ...body });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const deleteDesign = async (id) => {
  try {
    const response = await api.delete(`employee/structure-level/${id}`);
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const putDesign = async (body, id) => {
  try {
    const response = await api.put(`employee/structure-level/${id}`, {
      ...body,
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};
