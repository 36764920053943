import React, { useRef, useState } from "react";
import {
  Field,
  FieldArray,
  ErrorMessage,
  Formik,
  Form,
  useFormik,
} from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Button,
  Checkbox,
  InputAdornment,
  FormHelperText,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CreateIcon from "@mui/icons-material/Create";
import { sendPersonalInfoChanges } from "../../../services/employeeCabinet/employeeCabinet";
import { forEach } from "lodash";
import { ContactInfoValidation } from "./Validations";

const cities = [
  { value: "baku", name: "Bakı" },
  { value: "ganja", name: "Gəncə" },
  { value: "nakhchivan", name: "Naxçıvan" },
  { value: "khankendi", name: "Xankəndi" },
  { value: "lankaran", name: "Lənkəran" },
  { value: "mingachevir", name: "Mingəçevir" },
  { value: "naftalan", name: "Naftalan" },
  { value: "Sumgait", name: "Sumqayıt" },
  { value: "shaki", name: "Şəki" },
  { value: "shirvan", name: "Şirvan" },
  { value: "yevlax", name: "Yevlax" },
  { value: "shusha", name: "Şuşa" },
  { value: "culfa", name: "Culfa" },
  { value: "ordubad", name: "Ordubad" },
];

const bakuDistricts = [
  { value: "binaqadi", name: "Binəqədi" },
  { value: "qaradag", name: "Qaradağ" },
  { value: "narimanov", name: "Nərimanov" },
  { value: "nasimi", name: "Nəsimi" },
  { value: "nizami", name: "Nizami" },
  { value: "sabuncu", name: "Sabunçu" },
  { value: "sabail", name: "Səbail" },
  { value: "suraxani", name: "Suraxanı" },
  { value: "pirallahi", name: "Pirallahı" },
  { value: "xatai", name: "Xətai" },
  { value: "khazar", name: "Xəzər" },
  { value: "yasamal", name: "Yasamal" },
];

const sumqayitDistricts = [
  "Sumqayıt şəhəri",
  "Corat qəsəbəsi",
  "Hacı Zeynalabdin qəsəbəsi",
];

const districts = [
  { value: "absheron", name: "Abşeron rayonu" },
  { value: "aghjabadi", name: "Ağcabədi rayonu" },
  { value: "aghdam", name: "Ağdam rayonu" },
  { value: "agdash", name: "Ağdaş rayonu" },
  { value: "agstafa", name: "Ağstafa rayonu" },
  { value: "agsu", name: "Ağsu rayonu" },
  { value: "astara", name: "Astara rayonu" },
  { value: "babak", name: "Babək rayonu" },
  { value: "balakan", name: "Balakən rayonu" },
  { value: "beylaqan", name: "Beyləqan rayonu" },
  { value: "barda", name: "Bərdə rayonu" },
  { value: "bilasuvar", name: "Biləsuvar rayonu" },
  { value: "jabrayil", name: "Cəbrayıl rayonu" },
  { value: "jalilabad", name: "Cəlilabad rayonu" },
  { value: "julfa", name: "Culfa rayonu" },
  { value: "dashkasan", name: "Daşkəsən rayonu" },
  { value: "fuzuli", name: "Füzuli rayonu" },
  { value: "gadabay", name: "Gədəbəy rayonu" },
  { value: "goranboy", name: "Goranboy rayonu" },
  { value: "goychay", name: "Göyçay rayonu" },
  { value: "goygol", name: "Göygöl rayonu" },
  { value: "hajigabul", name: "Hacıqabul rayonu" },
  { value: "khachmaz", name: "Xaçmaz rayonu" },
  { value: "khizi", name: "Xızı rayonu" },
  { value: "khojali", name: "Xocalı rayonu" },
  { value: "", name: "Xocavənd rayonu" },
  { value: "imishli", name: "İmişli rayonu" },
  { value: "ismayilli", name: "İsmayıllı rayonu" },
  { value: "kelbecer", name: "Kəlbəcər rayonu" },
  { value: "kengerli", name: "Kəngərli rayonu" },
  { value: "kurdemir", name: "Kürdəmir rayonu" },
  { value: "qax", name: "Qax rayonu" },
  { value: "qazax", name: "Qazax rayonu" },
  { value: "qebele", name: "Qəbələ rayonu" },
  { value: "qobustan", name: "Qobustan rayonu" },
  { value: "quba", name: "Quba rayonu" },
  { value: "qubadli", name: "Qubadlı rayonu" },
  { value: "qusar", name: "Qusar rayonu" },
  { value: "lacin", name: "Laçın rayonu" },
  { value: "lerik", name: "Lerik rayonu" },
  { value: "lenkeran", name: "Lənkəran rayonu" },
  { value: "masalli", name: "Masallı rayonu" },
  { value: "neftchala", name: "Neftçala rayonu" },
  { value: "oguz", name: "Oğuz rayonu" },
  { value: "ordubad", name: "Ordubad rayonu" },
  { value: "saatli", name: "Saatlı rayonu" },
  { value: "sabirabad", name: "Sabirabad rayonu" },
  { value: "salyan", name: "Salyan rayonu" },
  { value: "samukh", name: "Samux rayonu" },
  { value: "sederak", name: "Sədərək rayonu" },
  { value: "siyazan", name: "Siyəzən rayonu" },
  { value: "shabran", name: "Şabran rayonu" },
  { value: "shahbuz", name: "Şahbuz rayonu*" },
  { value: "shamakhi", name: "Şamaxı rayonu" },
  { value: "sheki", name: "Şəki rayonu" },
  { value: "shemkir", name: "Şəmkir rayonu" },
  { value: "sharur", name: "Şərur rayonu*" },
  { value: "shusha", name: "Şuşa rayonu" },
  { value: "terter", name: "Tərtər rayonu" },
  { value: "tovuz", name: "Tovuz rayonu" },
  { value: "ucar", name: "Ucar rayonu" },
  { value: "yardimli", name: "Yardımlı rayonu" },
  { value: "yevlakh", name: "Yevlax rayonu" },
  { value: "zaqatala", name: "Zaqatala rayonu" },
  { value: "zangilan", name: "Zəngilan rayonu" },
  { value: "zardab", name: "Zərdab rayonu" },
];

const operators = [
  { code: "055", name: "Bakcell" },
  { code: "099", name: "Bakcell" },
  { code: "010", name: "Azercell" },
  { code: "050", name: "Azercell" },
  { code: "051", name: "Sim" },
  { code: "070", name: "Nar" },
  { code: "077", name: "Nar" },
];

const MCViewContactInfo = ({ employeeById }) => {
  const [formCorrection, setFormCorrection] = useState(false);
  const [requestButton, setRequestButton] = useState(false);
  const formikRef = useRef();

  const onClickCB = () => {
    setFormCorrection(!formCorrection);
  };

  const initialValues = employeeById && {
    actualAddress: {
      ...employeeById?.actualAddress,
      homeNumber:
        (employeeById?.actualAddress?.homeNumber &&
          employeeById?.actualAddress?.homeNumber?.map((number, index) => ({
            id: index,
            data: number,
          }))) ||
        [],
    },
    registeredAddress: {
      ...employeeById?.registeredAddress,
      homeNumber:
        employeeById?.registeredAddress?.homeNumber?.map((number, index) => ({
          id: index,
          data: number,
        })) || [],
    },
    email:
      employeeById?.email?.map((item, index) => ({
        id: index,
        data: item,
      })) || [],
    mobile: employeeById?.mobile
      ? employeeById?.mobile.map((number, index) => ({
          id: index,
          code: number.substring(0, 3),
          number: number.substring(3),
        }))
      : [""],
    sameAddress:
      employeeById?.actualAddress?.address ===
      employeeById?.registeredAddress?.address
        ? true
        : false,
  };

  // change request methods

  function mailChecker(newMails, oldMails) {
    const mailData = {
      changes: [],
    };

    let firstArray = [];
    let secondArray = [];

    //for find new mails
    newMails.forEach((newData) => {
      const obj = oldMails.find((oldData) => newData.id === oldData.id);

      if (!obj) {
        mailData.changes.push({
          operation: "create",
          data: newData.data,
        });
      } else {
        firstArray.push(obj);
      }
    });

    // for find deleted mails
    oldMails.forEach((oldData) => {
      const obj = newMails.find((newData) => oldData.id === newData.id);

      if (!obj) {
        mailData.changes.push({
          operation: "delete",
          index: oldData.id,
          data: oldData.data,
        });
      } else {
        secondArray.push(obj);
      }
    });

    for (let i = 0; i < firstArray.length; i++) {
      const data = updateData(firstArray[i], secondArray[i]);
      data && mailData.changes.push(data);
    }

    return mailData;
  }

  function numberChecker(newNumbers, oldNumbers) {
    const numberData = {
      changes: [],
    };

    const firstArray = [];
    const secondArray = [];
    //for find new mails
    newNumbers.forEach((newData) => {
      const obj = oldNumbers.find(
        (oldData) => newData.id === oldData.id
        // newData.code + newData.number === oldData.code + oldData.number
      );

      if (!obj) {
        numberData.changes.push({
          operation: "create",
          data: newData.code + newData.number,
        });
      } else {
        firstArray.push(obj);
      }
    });

    // for find deleted mails
    oldNumbers.forEach((oldData, index) => {
      const obj = newNumbers.find(
        (newData) => newData.id === oldData.id
        // oldData.code + oldData.number === newData.code + newData.number
      );

      if (!obj) {
        numberData.changes.push({
          operation: "delete",
          index: oldData.id,
          data: oldData.code + oldData.number,
        });
      } else {
        secondArray.push(obj);
      }
    });

    for (let i = 0; i < firstArray.length; i++) {
      const data = updateData(firstArray[i], secondArray[i]);
      data && numberData.changes.push(data);
    }

    return numberData;
  }

  function updateData(newObject, oldObject) {
    let updateData = {
      index: newObject.id,
      operation: "update",
      data: {},
    };

    const keys = Object.keys(newObject);

    for (const key of keys) {
      if (newObject[key] !== oldObject[key] && newObject.code) {
        updateData.data.old = newObject.code + newObject.number;
        updateData.data.new = oldObject.code + oldObject.number;
      } else if (newObject[key] !== oldObject[key] && !newObject.code) {
        updateData.data.old = newObject[key];
        updateData.data.new = oldObject[key];
      }
    }
    const a = Object.keys(updateData.data);
    if (a.length) {
      return updateData;
    }
  }

  function homeNumberChecker(newNumbers, oldNumbers) {
    const numberData = {
      changes: [],
    };

    let firstArray = [];
    let secondArray = [];

    //for find new numbers
    newNumbers.forEach((newData) => {
      const obj = oldNumbers.find((oldData) => newData.id === oldData.id);

      if (!obj) {
        numberData.changes.push({
          operation: "create",
          data: newData.data,
        });
      } else {
        firstArray.push(obj);
      }
    });

    // for find deleted numbers
    oldNumbers.forEach((oldData) => {
      const obj = newNumbers.find((newData) => oldData.id === newData.id);

      if (!obj) {
        numberData.changes.push({
          operation: "delete",
          index: oldData.id,
          data: oldData.data,
        });
      } else {
        secondArray.push(obj);
      }
    });

    for (let i = 0; i < firstArray.length; i++) {
      const data = updateData(firstArray[i], secondArray[i]);
      data && numberData.changes.push(data);
    }

    return numberData;
  }

  function addressChecker(newAddress, oldAddress) {
    const changes = {};

    const keys = Object.keys(newAddress);

    for (const key of keys) {
      if (
        typeof oldAddress[key] !== "object" &&
        oldAddress[key] !== newAddress[key]
      ) {
        changes[key] = {
          old: oldAddress[key],
          new: newAddress[key],
        };
      } else if (key === "homeNumber") {
        const homeNumber = homeNumberChecker(newAddress[key], oldAddress[key]);
        if (homeNumber.changes.length) {
          changes["homeNumber"] = homeNumber;
        }
      }
    }
    return changes;
  }

  function getChanges(newData, oldData) {
    let changesData = {
      section: "contact-info",

      changes: [
        {
          operation: "update",
          data: {},
        },
      ],
    };

    const keys = Object.keys(newData);

    for (const key of keys) {
      if (typeof oldData[key] !== "object" && oldData[key] !== newData[key]) {
        changesData.changes[0].data[key] = {
          old: oldData[key],
          new: newData[key],
        };
      } else if (
        // mail comparing
        oldData[key] instanceof Array &&
        key === "email"
      ) {
        const email = mailChecker(newData[key], oldData[key]);
        if (email.changes.length) {
          changesData.changes[0].data["email"] = email;
        }
      } else if (
        // mobilePhone comparing
        oldData[key] instanceof Array &&
        oldData[key][0]?.code
      ) {
        const number = numberChecker(newData[key], oldData[key]);
        if (number.changes.length) {
          changesData.changes[0].data["mobile"] = number;
        }
      } else if (
        // adressData comparing
        key === "actualAddress" ||
        key === "registeredAddress"
      ) {
        const address = addressChecker(newData[key], oldData[key]);
        if (Object.keys(address).length) {
          changesData.changes[0].data[key] = address;
        }
      }
    }

    sendPersonalInfoChanges(changesData);

    // const data = changesData.changes[0].data;
    // if (Object.keys(data).length) {
    //   sendPersonalInfoChanges(changesData);
    // } else {
    //   setRequestButton(false);
    // }
  }

  function handleSubmit(data) {
    getChanges(data, initialValues);
  }

  // form controller for submitting

  function controlFormForSubmitting(data) {
    controllData(data, initialValues);
  }

  function controllData(newData, oldData) {
    let changesData = {
      section: "contact-info",

      changes: [
        {
          operation: "update",
          data: {},
        },
      ],
    };

    const keys = Object.keys(newData);

    for (const key of keys) {
      if (typeof oldData[key] !== "object" && oldData[key] !== newData[key]) {
        changesData.changes[0].data[key] = {
          old: oldData[key],
          new: newData[key],
        };
      } else if (
        // mail comparing
        oldData[key] instanceof Array &&
        key === "email"
      ) {
        const email = mailChecker(newData[key], oldData[key]);
        if (email.changes.length) {
          changesData.changes[0].data["email"] = email;
        }
      } else if (
        // mobilePhone comparing
        oldData[key] instanceof Array &&
        oldData[key][0]?.code
      ) {
        const number = numberChecker(newData[key], oldData[key]);
        if (number.changes.length) {
          changesData.changes[0].data["mobile"] = number;
        }
      } else if (
        // adressData comparing
        key === "actualAddress" ||
        key === "registeredAddress"
      ) {
        const address = addressChecker(newData[key], oldData[key]);
        if (Object.keys(address).length) {
          changesData.changes[0].data[key] = address;
        }
      }
    }

    const data = changesData.changes[0].data;
    if (Object.keys(data).length) {
      return;
    } else {
      setRequestButton(false);
    }
  }

  return (
    <>
      {employeeById ? (
        <Grid>
          <Button
            style={
              formCorrection
                ? {
                    backgroundColor: "rgba(121,46,46, 0.9)",
                    marginTop: "10px",
                  }
                : { marginTop: "10px" }
            }
            onClick={onClickCB}
            variant="contained"
            color="secondary"
          >
            {formCorrection ? "Ləğv et" : "Dəyişiklik et"}

            <CreateIcon sx={{ marginLeft: 5 }} />
          </Button>

          <Formik
            innerRef={formikRef}
            onSubmit={handleSubmit}
            validationSchema={ContactInfoValidation(employeeById)}
            initialValues={initialValues}
          >
            {({ values, errors, setFieldValue }) => (
              <Form
                style={
                  !formCorrection
                    ? { pointerEvents: "none" }
                    : { pointerEvents: "auto" }
                }
              >
                <>
                  {/* Actual address and registered address*/}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3, mt: 5 }}>
                    <Grid sx={{ width: "100%" }}>
                      <Field
                        type="text"
                        name="actualAddress.address"
                        as={TextField}
                        fullWidth
                        label="Ünvanı (faktiki)"
                        onChange={(event) => {
                          setRequestButton(true);
                          setFieldValue(
                            "actualAddress.address",
                            event.target.value
                          );
                          if (values.sameAddress) {
                            setFieldValue(
                              "registeredAddress.address",
                              event.target.value
                            );
                            setFieldValue(
                              "actualAddress.address",
                              event.target.value
                            );
                          }
                        }}
                        error={
                          errors.actualAddress?.address &&
                          Boolean(errors?.actualAddress?.address)
                        }
                        helperText={
                          errors.actualAddress?.address &&
                          errors.actualAddress?.address
                        }
                      />
                    </Grid>
                    {!values.sameAddress ? (
                      <Grid sx={{ width: "100%" }}>
                        <Field
                          type="text"
                          name="registeredAddress.address"
                          value={
                            values.sameAddress
                              ? values.actualAddress.address
                              : values.registeredAddress.address
                          }
                          as={TextField}
                          fullWidth
                          disabled={values.sameAddress}
                          label="Ünvanı (qeydiyyat)"
                          onChange={(e) => {
                            setRequestButton(true);
                            setFieldValue(
                              "registeredAddress.address",
                              e.target.value
                            );
                          }}
                          error={
                            errors.registeredAddress?.address &&
                            Boolean(errors?.registeredAddress?.address)
                          }
                          helperText={
                            errors.registeredAddress?.address &&
                            errors.registeredAddress?.address
                          }
                        />
                      </Grid>
                    ) : null}
                  </Grid>

                  {/* Actual address city name and registered city */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Grid sx={{ width: "100%" }}>
                      <FormControl
                        error={
                          errors.actualAddress?.city &&
                          Boolean(errors?.actualAddress?.city)
                        }
                        fullWidth
                      >
                        <InputLabel id={`actualAddress.city`}>
                          Şəhər (faktiki)
                        </InputLabel>
                        <Select
                          name={`actualAddress.city`}
                          labelId={`actualAddress.city`}
                          label="Şəhər (faktiki)"
                          id={`actualAddressCity-select`}
                          onChange={(event) => {
                            setRequestButton(true);

                            setFieldValue(
                              "actualAddress.city",
                              event.target.value
                            );
                            if (values.sameAddress) {
                              setFieldValue(
                                "registeredAddress.city",
                                event.target.value
                              );
                              setFieldValue(
                                "actualAddress.city",
                                event.target.value
                              );
                            }
                          }}
                          defaultValue={values.actualAddress.city}
                          value={values.actualAddress.city}
                          fullWidth
                          error={
                            errors.actualAddress?.city &&
                            Boolean(errors?.actualAddress?.city)
                          }
                          input={
                            <OutlinedInput
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    sx={{ mr: 3, size: "12px" }}
                                    size="small"
                                    onClick={() => {
                                      setFieldValue("actualAddress.city", "");
                                    }}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Rayon (faktiki)"
                            />
                          }
                        >
                          {cities.map((item, index) => (
                            <MenuItem key={index} value={item.name}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.actualAddress?.city &&
                          errors.actualAddress?.city && (
                            <FormHelperText error>
                              {errors.actualAddress?.city}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                    {!values.sameAddress ? (
                      <Grid sx={{ width: "100%" }}>
                        <FormControl
                          error={
                            errors.registeredAddress?.city &&
                            Boolean(errors?.registeredAddress?.city)
                          }
                          fullWidth
                        >
                          <InputLabel id={`registeredAddress.city`}>
                            Şəhər (qeydiyyat)
                          </InputLabel>
                          <Select
                            name={`registeredAddress.city`}
                            labelId={`registeredAddress.city`}
                            label="Şəhər (qeydiyyat)"
                            id={`registeredAddress.city`}
                            onChange={(event) => {
                              setRequestButton(true);

                              setFieldValue(
                                "registeredAddress.city",
                                event.target.value
                              );
                            }}
                            defaultValue={
                              values.sameAddress
                                ? values.actualAddress.city
                                : values.registeredAddress.city
                            }
                            value={
                              values.sameAddress
                                ? values.actualAddress.city
                                : values.registeredAddress.city
                            }
                            disabled={values.sameAddress}
                            fullWidth
                            input={
                              <OutlinedInput
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      sx={{ mr: 3, size: "12px" }}
                                      size="small"
                                      disabled={values.sameAddress}
                                      onClick={() => {
                                        setFieldValue(
                                          "registeredAddress.city",
                                          ""
                                        );
                                      }}
                                    >
                                      <ClearIcon />
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Şəhər (qeydiyyat)"
                              />
                            }
                            error={
                              errors.registeredAddress?.city &&
                              Boolean(errors?.registeredAddress?.city)
                            }
                          >
                            {cities.map((item, index) => (
                              <MenuItem key={index} value={item.name}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.registeredAddress?.city &&
                            errors.registeredAddress?.city && (
                              <FormHelperText error>
                                {errors.registeredAddress?.city}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    ) : null}
                  </Grid>

                  {/* actual distric and registered district */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Grid sx={{ width: "100%" }}>
                      <FormControl
                        error={
                          errors.actualAddress?.district &&
                          Boolean(errors?.actualAddress?.district)
                        }
                        fullWidth
                      >
                        <InputLabel id={`actualAddress.district`}>
                          Rayon (faktiki)
                        </InputLabel>
                        <Select
                          disabled={values.actualAddress.city === "Gəncə"}
                          name={`actualAddress.district`}
                          labelId={`actualAddress.district`}
                          label="Rayon (faktiki)"
                          id={`actualAddressDistrict-select`}
                          defaultValue={values.actualAddress.district}
                          value={values.actualAddress.district}
                          onChange={(event) => {
                            setRequestButton(true);

                            setFieldValue(
                              "actualAddress.district",
                              event.target.value
                            );
                            if (values.sameAddress) {
                              setFieldValue(
                                "registeredAddress.district",
                                event.target.value
                              );
                            }
                          }}
                          fullWidth
                          error={
                            errors.actualAddress?.district &&
                            Boolean(errors?.actualAddress?.district)
                          }
                          input={
                            <OutlinedInput
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    sx={{ mr: 3, size: "12px" }}
                                    size="small"
                                    onClick={() => {
                                      setFieldValue(
                                        "actualAddress.district",
                                        ""
                                      );
                                    }}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Rayon (faktiki)"
                            />
                          }
                        >
                          {values.actualAddress.city === "Bakı"
                            ? bakuDistricts.map((item, index) => (
                                <MenuItem key={index} value={item.name}>
                                  {item.name}
                                </MenuItem>
                              ))
                            : values.actualAddress.city === "Sumqayıt"
                            ? sumqayitDistricts.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                  {item}
                                </MenuItem>
                              ))
                            : districts.map((item, index) => (
                                <MenuItem key={index} value={item.name}>
                                  {item.name}
                                </MenuItem>
                              ))}
                        </Select>
                        {errors.actualAddress?.district && (
                          <FormHelperText error>
                            {errors.actualAddress?.district}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    {!values.sameAddress ? (
                      <Grid sx={{ width: "100%" }}>
                        <FormControl
                          error={
                            errors.registeredAddress?.district &&
                            Boolean(errors?.registeredAddress?.district)
                          }
                          fullWidth
                        >
                          <InputLabel id={`registeredAddress.district`}>
                            Rayon (qeydiyyat)
                          </InputLabel>
                          <Select
                            disabled={values.registeredAddress.city === "Gəncə"}
                            name={`registeredAddress.district`}
                            labelId={`registeredAddress.district`}
                            label="Rayon (qeydiyyat)"
                            id={`registeredAddress.district-select`}
                            defaultValue={
                              values.sameAddress
                                ? values.actualAddress.district
                                : values.registeredAddress.district
                            }
                            value={
                              values.sameAddress
                                ? values.actualAddress.district
                                : values.registeredAddress.district
                            }
                            fullWidth
                            onChange={(event) => {
                              setRequestButton(true);

                              setFieldValue(
                                "registeredAddress.district",
                                event.target.value
                              );
                            }}
                            error={
                              errors.registeredAddress?.district &&
                              Boolean(errors?.registeredAddress?.district)
                            }
                            input={
                              <OutlinedInput
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      sx={{ mr: 3, size: "12px" }}
                                      size="small"
                                      disabled={values.sameAddress}
                                      onClick={() => {
                                        setFieldValue(
                                          "registeredAddress.district",
                                          ""
                                        );
                                      }}
                                    >
                                      <ClearIcon />
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Rayon (faktiki)"
                              />
                            }
                          >
                            {values.registeredAddress.city === "Bakı"
                              ? bakuDistricts.map((item, index) => (
                                  <MenuItem key={index} value={item.name}>
                                    {item.name}
                                  </MenuItem>
                                ))
                              : values.registeredAddress.city === "Sumqayıt"
                              ? sumqayitDistricts.map((item, index) => (
                                  <MenuItem key={index} value={item}>
                                    {item}
                                  </MenuItem>
                                ))
                              : districts.map((item, index) => (
                                  <MenuItem key={index} value={item.name}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                          </Select>
                          {errors.registeredAddress?.district &&
                            errors.registeredAddress?.district && (
                              <FormHelperText error>
                                {errors.registeredAddress?.district}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    ) : null}
                  </Grid>

                  {/* Home number and mobile number */}
                  <Grid sx={{ display: "flex", gap: 3 }}>
                    <Grid sx={{ width: "100%" }}>
                      <FieldArray name="actualAddress.homeNumber">
                        {({ push, remove }) => (
                          <>
                            {values.actualAddress.homeNumber.map(
                              (item, index) => (
                                <Grid
                                  mb={3}
                                  key={index}
                                  container
                                  spacing={2}
                                  alignItems="center"
                                >
                                  <Grid item xs={9}>
                                    <Field
                                      type={"number"}
                                      name={`actualAddress.homeNumber[${index}]`}
                                      as={TextField}
                                      fullWidth
                                      label={`Ev telefonu ${index + 1}`}
                                      value={item.data}
                                      onChange={(e) => {
                                        setRequestButton(true);
                                        setFieldValue(
                                          `actualAddress.homeNumber[${index}].data`,
                                          e.target.value
                                        );
                                      }}
                                      error={
                                        errors?.actualAddress?.homeNumber &&
                                        errors?.actualAddress?.homeNumber[index]
                                          ?.data &&
                                        Boolean(
                                          errors?.actualAddress?.homeNumber[
                                            index
                                          ]?.data
                                        )
                                      }
                                    />
                                    {errors?.actualAddress?.homeNumber &&
                                      errors?.actualAddress?.homeNumber[
                                        index
                                      ] &&
                                      errors?.actualAddress?.homeNumber[index]
                                        ?.data && (
                                        <FormHelperText error>
                                          {
                                            errors?.actualAddress?.homeNumber[
                                              index
                                            ]?.data
                                          }
                                        </FormHelperText>
                                      )}
                                    <ErrorMessage
                                      name={`actualAddress.homeNumber[${index}].data`}
                                      component="div"
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    {index ===
                                      values.actualAddress.homeNumber.length -
                                        1 && (
                                      <Button
                                        type="button"
                                        onClick={() =>
                                          push({
                                            id:
                                              values.actualAddress.homeNumber.at(
                                                -1
                                              ).id + 1,
                                          })
                                        }
                                      >
                                        + Ev telefonu əlavə et
                                      </Button>
                                    )}
                                    {index > 0 && (
                                      <Button
                                        type="button"
                                        onClick={() => {
                                          remove(index);
                                          setRequestButton(true);

                                          const updatedArray =
                                            values.actualAddress.homeNumber.filter(
                                              (item) => item.id !== index
                                            );

                                          controlFormForSubmitting({
                                            ...values,
                                            actualAddress: {
                                              ...values.actualAddress,
                                              homeNumber: updatedArray,
                                            },
                                          });
                                        }}
                                      >
                                        - Telefonu sil
                                      </Button>
                                    )}
                                  </Grid>
                                </Grid>
                              )
                            )}
                          </>
                        )}
                      </FieldArray>
                    </Grid>
                    <Grid sx={{ width: "100%" }}>
                      <FieldArray name="mobile">
                        {({ push, remove }) => (
                          <>
                            {values.mobile.map((mobile, index) => (
                              <Grid
                                mb={3}
                                key={index}
                                container
                                spacing={2}
                                alignItems="center"
                              >
                                <Grid
                                  item
                                  xs={9}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 4,
                                  }}
                                >
                                  <Grid sx={{ width: 1 / 3 }}>
                                    <Field
                                      as={Select}
                                      name={`mobile[${index}].code`}
                                      labelId={`mobile[${index}].code`}
                                      label="Operator"
                                      id={`mobile[${index}].code`}
                                      defaultValue=""
                                      fullWidth
                                      onChange={(e) => {
                                        setRequestButton(true);
                                        setFieldValue(
                                          `mobile[${index}].code`,
                                          e.target.value
                                        );
                                      }}
                                      error={
                                        errors?.mobile?.[index]?.code &&
                                        Boolean(errors?.mobile?.[index]?.code)
                                      }
                                    >
                                      {operators.map((item, index) => (
                                        <MenuItem key={index} value={item.code}>
                                          {item.code}
                                        </MenuItem>
                                      ))}
                                    </Field>
                                  </Grid>
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      type="number"
                                      name={`mobile[${index}].number`}
                                      as={TextField}
                                      fullWidth
                                      label={`Mobil ${index + 1}`}
                                      onChange={(e) => {
                                        setRequestButton(true);
                                        setFieldValue(
                                          `mobile[${index}].number`,
                                          e.target.value
                                        );
                                      }}
                                      error={
                                        errors?.mobile?.[index]?.number &&
                                        Boolean(errors?.mobile?.[index]?.number)
                                      }
                                    />
                                  </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                  {index === values.mobile.length - 1 && (
                                    <Button
                                      type="button"
                                      onClick={() =>
                                        push({
                                          id: values.mobile.at(-1).id + 1,
                                        })
                                      }
                                    >
                                      + Mobil əlavə et
                                    </Button>
                                  )}
                                  {index > 0 && (
                                    <Button
                                      type="button"
                                      onClick={() => {
                                        remove(index);
                                        setRequestButton(true);

                                        const updatedArray =
                                          values.mobile.filter(
                                            (item) => item.id !== index
                                          );

                                        controlFormForSubmitting({
                                          ...values,
                                          mobile: updatedArray,
                                        });
                                      }}
                                    >
                                      - Telefonu sil
                                    </Button>
                                  )}
                                </Grid>
                                <Grid sx={{ ml: 2 }}>
                                  {errors.mobile?.[index]?.number &&
                                    errors.mobile?.[index]?.number && (
                                      <FormHelperText error>
                                        {errors.mobile?.[index]?.number}
                                      </FormHelperText>
                                    )}
                                </Grid>
                              </Grid>
                            ))}
                          </>
                        )}
                      </FieldArray>
                    </Grid>
                  </Grid>

                  {/* Email and same address */}
                  <Grid sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                    <Grid sx={{ width: "100%" }}>
                      <FieldArray name="email">
                        {({ push, remove }) => (
                          <>
                            {values.email.map((email, index) => (
                              <Grid
                                mb={3}
                                key={index}
                                container
                                spacing={2}
                                alignItems="center"
                              >
                                <Grid item xs={9}>
                                  <Field
                                    type="email"
                                    name={`email[${index}]`}
                                    value={email.data}
                                    as={TextField}
                                    fullWidth
                                    label={`Email ${index + 1}`}
                                    onChange={(e) => {
                                      setRequestButton(true);
                                      setFieldValue(
                                        `email[${index}].data`,
                                        e.target.value
                                      );
                                    }}
                                    error={
                                      errors?.email &&
                                      errors?.email[index]?.data &&
                                      Boolean(errors?.email?.[index].data)
                                    }
                                    helperText={
                                      errors?.email &&
                                      errors?.email[index]?.data &&
                                      errors?.email?.[index].data
                                    }
                                  />
                                </Grid>

                                <Grid item xs={3}>
                                  {index === values.email.length - 1 && (
                                    <Button
                                      type="button"
                                      onClick={() =>
                                        push({
                                          id: values.email.at(-1).id + 1,
                                        })
                                      }
                                    >
                                      + Email əlavə et
                                    </Button>
                                  )}
                                  {index > 0 && (
                                    <Button
                                      type="button"
                                      onClick={() => {
                                        setRequestButton(true);

                                        remove(index);

                                        const updatedArray =
                                          values.email.filter(
                                            (item) => item.id !== index
                                          );

                                        controlFormForSubmitting({
                                          ...values,
                                          email: updatedArray,
                                        });
                                      }}
                                    >
                                      - Emaili sil
                                    </Button>
                                  )}
                                </Grid>
                              </Grid>
                            ))}
                          </>
                        )}
                      </FieldArray>
                    </Grid>
                    <Grid
                      sx={{
                        width: "100%",
                        mb: 4,
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.sameAddress}
                            name="sameAddress"
                            onChange={(event) => {
                              setFieldValue("sameAddress", !values.sameAddress);
                            }}
                            color="primary"
                          />
                        }
                        label="Faktiki və qeydiyyat ünvanı eynidir"
                      />
                    </Grid>
                  </Grid>
                </>
                <Grid
                  style={
                    formCorrection
                      ? { visibility: "visible", marginTop: "10px" }
                      : { visibility: "hidden" }
                  }
                >
                  <Button
                    type="submit"
                    style={
                      !requestButton
                        ? { pointerEvents: "none" }
                        : {
                            backgroundColor: "#28358a",
                            pointerEvents: "auto",
                          }
                    }
                    variant="contained"
                    color="secondary"
                  >
                    Dəyişikliklər sorğusu göndər
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default MCViewContactInfo;
