import React, { useEffect, useState } from "react";
import { Field, FieldArray, ErrorMessage, useFormikContext } from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Button,
  Checkbox,
  Typography,
  FormHelperText,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { getCommonData } from "../../api/api";
import { endOfDay } from "date-fns";
import NewCommonDataDialog from "./NewCommonDataDialog";

const Vehicles = ({
  values,
  setSelectedType,
  submitted,
  setSubmitted,
  selectedType,
}) => {
  const [carTypes, setCarTypes] = useState([]);
  const [carItems, setCarItems] = useState([[]]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [carName, setCarName] = useState("");
  const today = endOfDay(new Date());
  const { errors, setErrors, setFieldValue } = useFormikContext();

  useEffect(() => {
    const handleInventory = async () => {
      try {
        const response = await getCommonData("carManufacturers");

        setCarTypes(response.data);

        if (values.transportVehicles) {
          for (
            let index = 0;
            index < values.transportVehicles.length;
            index++
          ) {
            if (values.transportVehicles[index].brand) {
              try {
                const value = values.transportVehicles[index].brand;

                const response = await getCommonData("carModels", "", value);
                // Update the state with the response data for the specific item
                setCarItems((prevItems) => {
                  const updatedItems = [...prevItems];
                  updatedItems[index] = response.data;
                  return updatedItems;
                });
              } catch (error) {
                console.log(error);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    handleInventory();
  }, [submitted]);

  const handleItems = async (value, index) => {
    try {
      const response = await getCommonData("carModels", "", value);

      const updatedCarItems = [...carItems];

      updatedCarItems[index] = response.data;

      setCarItems(updatedCarItems);
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdateCarItems = async () => {
    try {
      const response = await getCommonData("carModels", "", carName);
      setCarItems((prevCarItems) => [...prevCarItems, response.data]);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <FieldArray name="transportVehicles">
        {({ push, remove }) => (
          <div>
            {values?.transportVehicles &&
              values?.transportVehicles.map((form, index) => (
                <div key={index}>
                  <>
                    <Typography mt={3} mb={3} variant="h6">
                      {index + 1})Nəqliyyat Vasitələri
                    </Typography>

                    {/* Vehicle type and model */}
                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Grid sx={{ width: "100%" }}>
                        <FormControl
                          error={
                            errors?.transportVehicles?.[index]?.brand &&
                            Boolean(errors?.transportVehicles?.[index]?.brand)
                          }
                          fullWidth
                        >
                          <InputLabel id={`transportVehicles[${index}].brand`}>
                            Marka *
                          </InputLabel>
                          <Field
                            as={Select}
                            name={`transportVehicles[${index}].brand`}
                            labelId={`transportVehicles[${index}].brand`}
                            label="Marka *"
                            id={`transportVehicles[${index}].brand`}
                            onChange={(e) => {
                              setFieldValue(
                                `transportVehicles[${index}].brand`,
                                e.target.value
                              );
                              if (errors?.transportVehicles?.[index]?.brand) {
                                setErrors({
                                  ...errors,
                                  transportVehicles: [
                                    ...errors.transportVehicles.slice(0, index),
                                    {
                                      ...errors.transportVehicles[index],
                                      brand: "",
                                    },
                                    ...errors.transportVehicles.slice(
                                      index + 1
                                    ),
                                  ],
                                });
                              }
                              handleItems(e.target.value, index);
                              setCarName(e.target.value);
                            }}
                            defaultValue=""
                            error={
                              errors?.transportVehicles?.[index]?.brand &&
                              Boolean(errors?.transportVehicles?.[index]?.brand)
                            }
                            fullWidth
                          >
                            {carTypes &&
                              carTypes
                                .sort((a, b) => a.localeCompare(b, "az"))
                                .map((item, index) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))}
                            <Button
                              sx={{
                                mt: 3,
                                ml: 3,
                              }}
                              variant="outlined"
                              onClick={() => {
                                setOpenAddDialog(true);
                                setSelectedType([
                                  "carManufacturers",
                                  {
                                    name: "Nəqliyyat vasitələrinin istehsalçıları",
                                  },
                                ]);
                              }}
                            >
                              Yeni marka əlavə et
                            </Button>
                          </Field>
                          {errors?.transportVehicles?.[index]?.brand &&
                            errors?.transportVehicles?.[index]?.brand && (
                              <FormHelperText error>
                                {errors?.transportVehicles?.[index]?.brand}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                      <Grid sx={{ width: "100%" }}>
                        <FormControl
                          error={
                            errors?.transportVehicles?.[index]?.name &&
                            Boolean(errors?.transportVehicles?.[index]?.name)
                          }
                          fullWidth
                        >
                          <InputLabel id={`transportVehicles[${index}].name`}>
                            Model *
                          </InputLabel>
                          <Field
                            as={Select}
                            name={`transportVehicles[${index}].name`}
                            labelId={`transportVehicles[${index}].name`}
                            label="Model *"
                            id={`transportVehicles[${index}].name`}
                            disabled={!values.transportVehicles[index].brand}
                            defaultValue=""
                            error={
                              errors?.transportVehicles?.[index]?.name &&
                              Boolean(errors?.transportVehicles?.[index]?.name)
                            }
                            fullWidth
                            onChange={(e) => {
                              setFieldValue(
                                `transportVehicles[${index}].name`,
                                e.target.value
                              );
                              if (errors?.transportVehicles?.[index]?.name) {
                                setErrors({
                                  ...errors,
                                  transportVehicles: [
                                    ...errors.transportVehicles.slice(0, index),
                                    {
                                      ...errors.transportVehicles[index],
                                      name: "",
                                    },
                                    ...errors.transportVehicles.slice(
                                      index + 1
                                    ),
                                  ],
                                });
                              }
                            }}
                          >
                            {carItems?.[index] &&
                              Array.isArray(carItems?.[index]) &&
                              carItems[index]
                                .filter(
                                  (carItem) => carItem?.parent === carName
                                )
                                .sort((a, b) =>
                                  (a?.name || "").localeCompare(
                                    b?.name || "",
                                    "az"
                                  )
                                )
                                .map((item, index) => (
                                  <MenuItem key={index} value={item?.name}>
                                    {item?.name}
                                  </MenuItem>
                                ))}
                            <Button
                              sx={{
                                mt: 3,
                                ml: 3,
                              }}
                              variant="outlined"
                              onClick={() => {
                                setOpenAddDialog(true);
                                setSelectedType([
                                  "carModels",
                                  {
                                    name: "Nəqliyyat vasitələrinin modelləri",
                                  },
                                ]);
                              }}
                            >
                              Yeni model əlavə et
                            </Button>
                          </Field>
                          {errors?.transportVehicles?.[index]?.name &&
                            errors?.transportVehicles?.[index]?.name && (
                              <FormHelperText error>
                                {errors?.transportVehicles?.[index]?.name}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Model and registrationPlate */}
                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Grid sx={{ width: "100%" }}>
                        <FormControl
                          error={
                            errors?.transportVehicles?.[index]
                              ?.transportationType &&
                            Boolean(
                              errors?.transportVehicles?.[index]
                                ?.transportationType
                            )
                          }
                          fullWidth
                        >
                          <InputLabel
                            id={`transportVehicles[${index}].transportationType`}
                          >
                            Nəqliyyat Növü *
                          </InputLabel>
                          <Field
                            as={Select}
                            name={`transportVehicles[${index}].transportationType`}
                            labelId={`transportVehicles[${index}].transportationType`}
                            label="Nəqliyyat Növü *"
                            id={`transportVehicles[${index}].transportationType`}
                            defaultValue=""
                            fullWidth
                            error={
                              errors?.transportVehicles?.[index]
                                ?.transportationType &&
                              Boolean(
                                errors?.transportVehicles?.[index]
                                  ?.transportationType
                              )
                            }
                            onChange={(e) => {
                              setFieldValue(
                                `transportVehicles[${index}].transportationType`,
                                e.target.value
                              );
                              if (
                                errors?.transportVehicles?.[index]
                                  ?.transportationType
                              ) {
                                setErrors({
                                  ...errors,
                                  transportVehicles: [
                                    ...errors.transportVehicles.slice(0, index),
                                    {
                                      ...errors.transportVehicles[index],
                                      transportationType: "",
                                    },
                                    ...errors.transportVehicles.slice(
                                      index + 1
                                    ),
                                  ],
                                });
                              }
                            }}
                          >
                            <MenuItem value="yük">Yük</MenuItem>
                            <MenuItem value="minik">Minik</MenuItem>
                            <MenuItem value="qoşqu">Qoşqu</MenuItem>
                            <MenuItem value="xüsusi">Xüsusi</MenuItem>
                          </Field>
                          {errors?.transportVehicles?.[index]
                            ?.transportationType &&
                            errors?.transportVehicles?.[index]
                              ?.transportationType && (
                              <FormHelperText error>
                                {
                                  errors?.transportVehicles?.[index]
                                    ?.transportationType
                                }
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                      <Grid sx={{ width: "100%" }}>
                        <Field
                          type="text"
                          name={`transportVehicles[${index}].registrationNumber`}
                          as={TextField}
                          fullWidth
                          label="N.V. Qeydiyyat Şəhadətnaməsi No. *"
                          error={
                            errors?.transportVehicles?.[index]
                              ?.registrationNumber &&
                            Boolean(
                              errors?.transportVehicles?.[index]
                                ?.registrationNumber
                            )
                          }
                          helperText={
                            errors?.transportVehicles?.[index]
                              ?.registrationNumber &&
                            errors?.transportVehicles?.[index]
                              ?.registrationNumber
                          }
                          onChange={(e) => {
                            setFieldValue(
                              `transportVehicles[${index}].registrationNumber`,
                              e.target.value
                            );
                            if (
                              errors?.transportVehicles?.[index]
                                ?.registrationNumber
                            ) {
                              setErrors({
                                ...errors,
                                transportVehicles: [
                                  ...errors.transportVehicles.slice(0, index),
                                  {
                                    ...errors.transportVehicles[index],
                                    registrationNumber: "",
                                  },
                                  ...errors.transportVehicles.slice(index + 1),
                                ],
                              });
                            }
                          }}
                        />
                      </Grid>
                    </Grid>

                    {/* Number and date */}
                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Grid sx={{ width: "100%" }}>
                        <Field
                          type="text"
                          name={`transportVehicles[${index}].registrationPlate`}
                          as={TextField}
                          fullWidth
                          label="N.V. Qeydiyyat Nişanı *"
                          error={
                            errors?.transportVehicles?.[index]
                              ?.registrationPlate &&
                            Boolean(
                              errors?.transportVehicles?.[index]
                                ?.registrationPlate
                            )
                          }
                          helperText={
                            errors?.transportVehicles?.[index]
                              ?.registrationPlate &&
                            errors?.transportVehicles?.[index]
                              ?.registrationPlate
                          }
                          onChange={(e) => {
                            setFieldValue(
                              `transportVehicles[${index}].registrationPlate`,
                              e.target.value
                            );
                            if (
                              errors?.transportVehicles?.[index]
                                ?.registrationPlate
                            ) {
                              setErrors({
                                ...errors,
                                transportVehicles: [
                                  ...errors.transportVehicles.slice(0, index),
                                  {
                                    ...errors.transportVehicles[index],
                                    registrationPlate: "",
                                  },
                                  ...errors.transportVehicles.slice(index + 1),
                                ],
                              });
                            }
                          }}
                        />
                      </Grid>
                      <Grid sx={{ width: "100%" }}>
                        <Field
                          type="text"
                          name={`transportVehicles[${index}].year`}
                          as={TextField}
                          fullWidth
                          label="Buraxılış ili *"
                          error={
                            errors?.transportVehicles?.[index]?.year &&
                            Boolean(errors?.transportVehicles?.[index]?.year)
                          }
                          helperText={
                            errors?.transportVehicles?.[index]?.year &&
                            errors?.transportVehicles?.[index]?.year
                          }
                        />
                      </Grid>
                    </Grid>

                    {/* Volume and Transmission */}
                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Grid sx={{ width: "100%" }}>
                        <Field
                          type="text"
                          name={`transportVehicles[${index}].engineCapacity`}
                          as={TextField}
                          fullWidth
                          label="Mühərrik Həcmi (sm3) *"
                          error={
                            errors?.transportVehicles?.[index]
                              ?.engineCapacity &&
                            Boolean(
                              errors?.transportVehicles?.[index]?.engineCapacity
                            )
                          }
                          helperText={
                            errors?.transportVehicles?.[index]
                              ?.engineCapacity &&
                            errors?.transportVehicles?.[index]?.engineCapacity
                          }
                          onChange={(e) => {
                            setFieldValue(
                              `transportVehicles[${index}].engineCapacity`,
                              e.target.value
                            );
                            if (
                              errors?.transportVehicles?.[index]?.engineCapacity
                            ) {
                              setErrors({
                                ...errors,
                                transportVehicles: [
                                  ...errors.transportVehicles.slice(0, index),
                                  {
                                    ...errors.transportVehicles[index],
                                    engineCapacity: "",
                                  },
                                  ...errors.transportVehicles.slice(index + 1),
                                ],
                              });
                            }
                          }}
                        />
                      </Grid>
                      <Grid sx={{ width: "100%" }}>
                        <FormControl
                          error={
                            errors?.transportVehicles?.[index]
                              ?.transmissionType &&
                            Boolean(
                              errors?.transportVehicles?.[index]
                                ?.transmissionType
                            )
                          }
                          fullWidth
                        >
                          <InputLabel
                            id={`transportVehicles[${index}].transmissionType`}
                          >
                            Sürət Qutusu *
                          </InputLabel>
                          <Field
                            as={Select}
                            name={`transportVehicles[${index}].transmissionType`}
                            labelId={`transportVehicles[${index}].transmissionType`}
                            label="Sürət Qutusu *"
                            id={`transportVehicles[${index}].transmissionType`}
                            defaultValue=""
                            fullWidth
                            error={
                              errors?.transportVehicles?.[index]
                                ?.transmissionType &&
                              Boolean(
                                errors?.transportVehicles?.[index]
                                  ?.transmissionType
                              )
                            }
                            onChange={(e) => {
                              setFieldValue(
                                `transportVehicles[${index}].transmissionType`,
                                e.target.value
                              );
                              if (
                                errors?.transportVehicles?.[index]
                                  ?.transmissionType
                              ) {
                                setErrors({
                                  ...errors,
                                  transportVehicles: [
                                    ...errors.transportVehicles.slice(0, index),
                                    {
                                      ...errors.transportVehicles[index],
                                      transmissionType: "",
                                    },
                                    ...errors.transportVehicles.slice(
                                      index + 1
                                    ),
                                  ],
                                });
                              }
                            }}
                          >
                            <MenuItem value="Avtomat">Avtomat</MenuItem>
                            <MenuItem value="Mexaniki">Mexaniki</MenuItem>
                            <MenuItem value="Robotlaşdırılmış">
                              Robotlaşdırılmış
                            </MenuItem>
                            <MenuItem value="Variator">Variator</MenuItem>
                          </Field>
                          {errors?.transportVehicles?.[index]
                            ?.transmissionType &&
                            errors?.transportVehicles?.[index]
                              ?.transmissionType && (
                              <FormHelperText error>
                                {
                                  errors?.transportVehicles?.[index]
                                    ?.transmissionType
                                }
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>

                  <Button
                    sx={{ mt: 3 }}
                    variant="contained"
                    color="error"
                    type="button"
                    onClick={() => remove(values.transportVehicles[index])}
                  >
                    Nəqliyyat Vasitəsini sil
                  </Button>
                </div>
              ))}
            <Button
              sx={{ mt: 3 }}
              variant="contained"
              type="button"
              onClick={() => push({})}
            >
              Nəqliyyat Vasitəsi əlavə et
            </Button>
          </div>
        )}
      </FieldArray>
      {openAddDialog && (
        <NewCommonDataDialog
          openAddDialog={openAddDialog}
          selectedType={selectedType}
          setOpenAddDialog={setOpenAddDialog}
          submitted={submitted}
          setSubmitted={setSubmitted}
          setCarItems={handleUpdateCarItems}
          carName={carName}
        />
      )}
    </>
  );
};

export default Vehicles;
