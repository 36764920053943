import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { getCommonData, getFileById, updateEmployee } from "../../api/api";
import useStaticData from "../../hooks/useStaticData/useStaticData";
import { removeEmptyStrings } from "../../utils/removeEmptyString";
import {
  foreignEmployeeValidation,
  personalInformationValidation,
} from "../employees/Validations";

function EditPersonalInformation({
  employeeById,
  submitted,
  setSubmitted,
  setSelectedType,
  setOpenAddDialog,
}) {
  const [oldNameCheckbox, setOldNameCheckbox] = useState(false);
  const [birthPlaces, setBirthPlaces] = useState([]);
  const [pastData, setPastData] = useState([]);
  const [idCardAuthorities, setIdCardAuthorities] = useState([]);
  const [showPicture, setShowPicture] = useState(false);
  const [isDate, setIsDate] = useState(false);
  const [image, setImage] = useState({ src: "", name: "" });
  const [employeePhotoAsBase64, setEmployeePhotoAsBase64] = useState({
    src: "",
    name: "",
  });

  const [state] = useStaticData();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [birthResponse, idCardResponse] = await Promise.all([
          getCommonData("placeOfBirthList"),
          getCommonData("identityCardissuerList"),
        ]);

        const birthPlaces = birthResponse?.data || [];
        const idCardAuthorities = idCardResponse?.data || [];

        setBirthPlaces(birthPlaces);
        setIdCardAuthorities(idCardAuthorities);

        const combinedData = [];
        if (birthPlaces.length) {
          combinedData.push({ birthPlaces: [...birthPlaces] });
        }

        if (idCardAuthorities.length) {
          combinedData.push({ idCardAuthorities: [...idCardAuthorities] });
        }

        handlePastData(combinedData);
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };

    fetchData();
  }, [submitted]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    let modifiedValues = {
      ...values,
      firstname: values.firstname.trim(),
      fathername: values.fathername.trim(),
      placeOfBirth: values.placeOfBirth.trim(),
      idCardAuthority: values.idCardAuthority.trim(),
      ssn: values.ssn.trim(),
      idCardNumber:
        employeeById.isForeigner === true
          ? values.idCardNumber.number
          : values.idCardNumber.code + values.idCardNumber.number,
      oldLastName: oldNameCheckbox ? employeeById.lastname.trim() : null,
      lastname: values.lastname.split(" ")[0].trim(),
      // Format all dates to en-GB
      dob: values.dob ? moment(values.dob).format("MM/DD/yyyy") : null,
      idCardIssuanceDate: values.idCardIssuanceDate
        ? moment(values.idCardIssuanceDate).format("MM/DD/yyyy")
        : null,
      idCardValidityPeriod: values.idCardValidityPeriod
        ? moment(values.idCardValidityPeriod).format("MM/DD/yyyy")
        : "",
      workPermitStartDate: values.workPermitStartDate
        ? moment(values.workPermitStartDate).format("MM/DD/yyyy")
        : null,
      workPermitEndDate: values.workPermitEndDate
        ? moment(values.workPermitEndDate).format("MM/DD/yyyy")
        : null,
      residencePermitStartDate: values.residencePermitStartDate
        ? moment(values.residencePermitStartDate).format("MM/DD/yyyy")
        : null,
      residencePermitEndDate: values.residencePermitEndDate
        ? moment(values.residencePermitEndDate).format("MM/DD/yyyy")
        : null,
      isForeigner: employeeById.isForeigner ? true : null,
      employeePhotoAsBase64: values.employeePhotoAsBase64,
      tableNumber: values?.tableNumber ? values?.tableNumber : "",
    };

    modifiedValues = removeEmptyStrings(modifiedValues);
    // Handle form submission
    await updateEmployee(employeeById._id, modifiedValues);
    setIsDate(false);
    setSubmitting(false);
    setSubmitted(!submitted);
    resetForm({ values });
  };
  const handleFileUpload = useCallback((event, setFieldValue) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const base64Image = reader.result;
      const fieldName = `employeePhotoAsBase64`;
      setFieldValue(fieldName, base64Image);
    };
    reader.readAsDataURL(file);
  }, []);
  const handleShowImage = async (values) => {
    try {
      let imageData;
      if (!values?.employeePhotoAsBase64) {
        const res = await getFileById(
          `${values?.profileImage}?fileFormat=base64`
        );
        if (res && res?.data) {
          imageData = {
            src: res?.data,
          };
        }
      } else {
        imageData = {
          src: values?.employeePhotoAsBase64,
        };
      }
      setImage(imageData);
      setShowPicture(true);
    } catch (err) {
      console.log(err);
    }
  };
  function handlePastData(data) {
    setPastData(data);
  }

  const handleCloseImage = () => {
    setImage({ src: "", name: "" });
    setEmployeePhotoAsBase64({ src: "", name: "" });
    setShowPicture(false);
  };
  const initialValues = {
    firstname: employeeById.firstname || "",
    lastname:
      employeeById.lastname +
        (employeeById.oldLastName ? ` ( ${employeeById?.oldLastName} )` : "") ||
      "",
    fathername: employeeById.fathername || "",
    gender: employeeById.gender || "",
    dob: employeeById.dob || "",
    placeOfBirth: employeeById.placeOfBirth || "",
    pin: employeeById.pin || "",
    idCardIssuanceDate: employeeById.idCardIssuanceDate || "",
    nationality: employeeById.nationality || "",
    idCardAuthority: employeeById.idCardAuthority || "",
    idCardNumber: employeeById.isForeigner
      ? { number: employeeById.idCardNumber }
      : {
          code: employeeById?.idCardNumber?.startsWith("AA")
            ? employeeById.idCardNumber.slice(0, 2)
            : employeeById?.idCardNumber?.startsWith("AZE")
            ? employeeById?.idCardNumber.slice(0, 3)
            : "",
          number: employeeById?.idCardNumber?.startsWith("AA")
            ? employeeById.idCardNumber.slice(2)
            : employeeById?.idCardNumber?.startsWith("AZE")
            ? employeeById?.idCardNumber.slice(3)
            : "",
        },
    idCardValidityPeriod: employeeById.idCardValidityPeriod || "",
    ssn: employeeById.ssn || "",
    citizenship: employeeById.citizenship || "",
    workPermitStartDate: employeeById.workPermitStartDate || "",
    workPermitEndDate: employeeById.workPermitEndDate || "",
    residencePermitStartDate: employeeById.residencePermitStartDate || "",
    residencePermitEndDate: employeeById.residencePermitEndDate || "",
    tRPNumber: employeeById.tRPNumber || "",
    maritalStatus: employeeById.maritalStatus || "",
    profileImage: employeeById.profileImage || "",
    tableNumber: employeeById.tableNumber || "",
  };

  birthPlaces && birthPlaces.push(employeeById?.placeOfBirth);
  idCardAuthorities && idCardAuthorities.push(employeeById?.idCardAuthority);

  function handleHidden(name, data) {
    return pastData.every((past) => !past[name]?.includes(data));
  }
  return (
    <>
      {employeeById ? (
        <Formik
          initialValues={initialValues}
          validationSchema={
            employeeById.isForeigner
              ? foreignEmployeeValidation(employeeById)
              : personalInformationValidation(employeeById)
          }
          onSubmit={handleSubmit}
        >
          {({
            errors,
            isValid,
            touched,
            isSubmitting,
            setFieldValue,
            values,
            dirty,
          }) => (
            <>
              <Form>
                <>
                  <Typography variant="h6">Şəxsi məlumatlar</Typography>

                  {/* Name and surname */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3, mt: 5 }}>
                    <Grid sx={{ width: "100%" }}>
                      <Field
                        type="text"
                        name="firstname"
                        as={TextField}
                        fullWidth
                        label="Ad"
                        error={
                          errors.firstname &&
                          touched.firstname &&
                          Boolean(errors.firstname)
                        }
                        helperText={
                          errors.firstname &&
                          touched.firstname &&
                          errors.firstname
                        }
                      />
                    </Grid>
                    <Grid
                      sx={{
                        width: "100%",
                        display: "flex",
                        gap: 3,
                        alignItems: "center",
                      }}
                    >
                      <Field
                        type="text"
                        name="lastname"
                        as={TextField}
                        label="Soyad"
                        error={
                          errors.lastname &&
                          touched.lastname &&
                          Boolean(errors.lastname)
                        }
                        helperText={
                          errors.lastname && touched.lastname && errors.lastname
                        }
                        fullWidth
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => {
                              setOldNameCheckbox(!oldNameCheckbox);
                            }}
                            name="Old Name Checkbox"
                            color="primary"
                          />
                        }
                        label="Yeniləmədən sonra köhnə soyadı göstər"
                      />
                    </Grid>
                  </Grid>

                  {/* Father name and gender */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Grid sx={{ width: "100%" }}>
                      <Field
                        type="text"
                        name="fathername"
                        as={TextField}
                        fullWidth
                        label="Ata adı"
                        error={
                          errors.fathername &&
                          touched.fathername &&
                          Boolean(errors.fathername)
                        }
                        helperText={
                          errors.fathername &&
                          touched.fathername &&
                          errors.fathername
                        }
                      />
                    </Grid>
                    <Grid sx={{ width: "100%" }}>
                      <FormControl
                        error={errors.gender && errors.gender}
                        fullWidth
                      >
                        <InputLabel id="gender">Cinsi</InputLabel>
                        <Field
                          as={Select}
                          name="gender"
                          labelId="gender"
                          label="Cinsi"
                          id="gender-select"
                          fullWidth
                          error={errors.gender && Boolean(errors.gender)}
                        >
                          <MenuItem value="male">Kişi</MenuItem>
                          <MenuItem value="female">Qadın</MenuItem>
                        </Field>
                        {errors.gender && errors.gender && (
                          <FormHelperText error>{errors.gender}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Birthday and place of birth */}
                  <Grid
                    onClick={() => setIsDate(true)}
                    sx={{ display: "flex", gap: 3, mb: 3 }}
                  >
                    <Field name="dob">
                      {({ field, form }) => (
                        <DatePicker
                          {...field}
                          label="Doğum tarixi"
                          inputFormat="dd/MM/yyyy"
                          value={field.value}
                          onChange={(date) => {
                            if (date instanceof Date && !isNaN(date)) {
                              form.setFieldValue("dob", date);
                              // form.setFieldTouched(`dob`, true);
                            } else {
                              form.setFieldValue("dob", "");
                              // form.setFieldTouched(`dob`, true);
                            }
                          }}
                          maxDate={new Date()}
                          minDate={new Date(1940, 0, 1)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              helperText={isDate && form?.errors?.dob}
                              error={isDate && Boolean(form?.errors?.dob)}
                              fullWidth
                            />
                          )}
                        />
                      )}
                    </Field>

                    <Grid sx={{ width: "100%" }}>
                      <FormControl
                        error={
                          errors?.placeOfBirth &&
                          touched.placeOfBirth &&
                          Boolean(errors?.placeOfBirth)
                        }
                        fullWidth
                      >
                        <InputLabel id={`placeOfBirth`}>
                          Doğulduğu yer *
                        </InputLabel>
                        <Field
                          as={Select}
                          name={`placeOfBirth`}
                          labelId={`placeOfBirth`}
                          label="Doğulduğu yer *"
                          id={`placeOfBirth`}
                          defaultValue=""
                          fullWidth
                          error={
                            errors?.placeOfBirth &&
                            touched.placeOfBirth &&
                            Boolean(errors?.placeOfBirth)
                          }
                        >
                          {[...new Set(birthPlaces)]
                            .sort((a, b) => a.localeCompare(b, "az"))
                            .map((item, index) => (
                              <MenuItem
                                hidden={handleHidden("birthPlaces", item)}
                                key={index}
                                value={item}
                              >
                                {item}
                              </MenuItem>
                            ))}
                          <Button
                            sx={{
                              mt: 3,
                              ml: 3,
                            }}
                            variant="outlined"
                            onClick={() => {
                              setOpenAddDialog(true);
                              setSelectedType([
                                "placeOfBirthList",
                                { name: "Doğum yerləri" },
                              ]);
                            }}
                          >
                            Yeni doğum yeri əlavə et
                          </Button>
                        </Field>
                        {errors.placeOfBirth &&
                          touched.placeOfBirth &&
                          errors.placeOfBirth && (
                            <FormHelperText error>
                              {errors.placeOfBirth}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Nationality and idCardAuthority */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Grid sx={{ width: "100%", display: "flex", gap: 3 }}>
                      <FormControl
                        error={
                          errors.nationality &&
                          touched.nationality &&
                          errors.nationality
                        }
                        fullWidth
                      >
                        <InputLabel id="nationality">Milliyyət</InputLabel>
                        <Field
                          as={Select}
                          name="nationality"
                          labelId="nationality"
                          label="nationality"
                          id="nationality-select"
                          fullWidth
                          error={
                            errors.nationality &&
                            touched.nationality &&
                            Boolean(errors.nationality)
                          }
                        >
                          {state?.nationalities?.data?.map((item, index) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Field>
                        {errors.nationality &&
                          touched.nationality &&
                          errors.nationality && (
                            <FormHelperText error>
                              {errors.nationality}
                            </FormHelperText>
                          )}
                      </FormControl>
                      {employeeById.isForeigner === true ? (
                        <FormControl
                          error={
                            errors.citizenship &&
                            touched.citizenship &&
                            errors.citizenship
                          }
                          fullWidth
                        >
                          <InputLabel id="citizenship">Vətəndaşlığı</InputLabel>
                          <Field
                            as={Select}
                            name="citizenship"
                            labelId="citizenship"
                            label="citizenship"
                            id="citizenship-select"
                            fullWidth
                            error={
                              errors.citizenship &&
                              touched.citizenship &&
                              Boolean(errors.citizenship)
                            }
                            required
                          >
                            {state?.nationalities?.data?.map((item, index) => (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                          </Field>
                          {errors.nationality &&
                            touched.nationality &&
                            errors.nationality && (
                              <FormHelperText error>
                                {errors.nationality}
                              </FormHelperText>
                            )}
                        </FormControl>
                      ) : null}
                    </Grid>
                    <Grid sx={{ width: "100%" }}>
                      <FormControl
                        error={
                          errors?.idCardAuthority &&
                          touched?.idCardAuthority &&
                          Boolean(errors?.idCardAuthority)
                        }
                        fullWidth
                      >
                        <InputLabel id={`idCardAuthority`}>
                          {employeeById.isForeigner
                            ? "MYİ-ni verən təşkilat"
                            : "Şəxsiyyət vəsiqəsini verən təşkilat"}
                        </InputLabel>
                        <Field
                          as={Select}
                          name={`idCardAuthority`}
                          labelId={`idCardAuthority`}
                          label={
                            employeeById.isForeigner
                              ? "MYİ-ni verən təşkilat"
                              : "Şəxsiyyət vəsiqəsini verən təşkilat"
                          }
                          id={`idCardAuthority`}
                          defaultValue=""
                          fullWidth
                          error={
                            errors?.idCardAuthority &&
                            touched?.idCardAuthority &&
                            Boolean(errors?.idCardAuthority)
                          }
                        >
                          {[...new Set(idCardAuthorities)]
                            .sort((a, b) => a.localeCompare(b, "az"))
                            .map((item, index) => (
                              <MenuItem
                                hidden={handleHidden("idCardAuthorities", item)}
                                value={item}
                              >
                                {item}
                              </MenuItem>
                            ))}
                          <Button
                            sx={{
                              mt: 3,
                              ml: 3,
                            }}
                            variant="outlined"
                            onClick={() => {
                              setOpenAddDialog(true);
                              setSelectedType([
                                "identityCardissuerList",
                                { name: "Ş.V verən orqanlar" },
                              ]);
                            }}
                          >
                            {employeeById.nationality === "foreign"
                              ? "Yeni MYİ-ni verən təşkilat əlavə et *"
                              : "Yeni şəxsiyyət vəsiqəsini verən təşkilat əlavə et *"}
                          </Button>
                        </Field>
                        {errors.idCardAuthority &&
                          touched.idCardAuthority &&
                          errors.idCardAuthority && (
                            <FormHelperText error>
                              {errors.idCardAuthority}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* National ID and PIN */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Grid sx={{ width: "100%", display: "flex", gap: 3 }}>
                      {employeeById.isForeigner === true ? null : (
                        <Grid sx={{ width: 1 / 5 }}>
                          <Field
                            as={Select}
                            name={`idCardNumber.code`}
                            labelId={`idCardNumber.code`}
                            label="*"
                            id={`idCardNumber.code`}
                            defaultValue=""
                            fullWidth
                            error={
                              errors.idCardNumber?.code &&
                              touched?.idCardNumber?.code &&
                              Boolean(errors.idCardNumber?.code)
                            }
                            helperText={
                              errors.idCardNumber?.code &&
                              touched?.idCardNumber?.code &&
                              errors.idCardNumber?.code
                            }
                            required
                          >
                            <MenuItem value="AA">AA</MenuItem>
                            <MenuItem value="AZE">AZE</MenuItem>
                          </Field>
                        </Grid>
                      )}

                      <Grid
                        sx={{
                          width:
                            employeeById.isForeigner === true ? "100%" : 4 / 5,
                        }}
                      >
                        <Field
                          type="text"
                          name="idCardNumber.number"
                          as={TextField}
                          fullWidth
                          label={
                            employeeById.isForeigner === true
                              ? "Passport №"
                              : "Ş.V/Seriya №"
                          }
                          error={
                            errors.idCardNumber?.number &&
                            touched?.idCardNumber?.number &&
                            Boolean(errors.idCardNumber?.number)
                          }
                          helperText={
                            errors.idCardNumber?.number &&
                            touched?.idCardNumber?.number &&
                            errors.idCardNumber?.number
                          }
                          required
                        />
                      </Grid>
                    </Grid>
                    <Grid sx={{ width: "100%", display: "flex", gap: 3 }}>
                      <Field
                        type="text"
                        name="pin"
                        as={TextField}
                        fullWidth
                        label="Fərdi identifikasiya Nömrəsi (FİN)"
                        onChange={(event) => {
                          const uppercaseValue =
                            event.target.value.toUpperCase();
                          setFieldValue("pin", uppercaseValue);
                        }}
                        error={
                          errors.pin && touched?.pin && Boolean(errors.pin)
                        }
                        helperText={errors.pin && touched?.pin && errors.pin}
                      />
                      {employeeById.isForeigner === true ? (
                        <Field
                          type="text"
                          name="tRPNumber"
                          as={TextField}
                          fullWidth
                          label="MYİ seriya Nömrəsi"
                          error={
                            errors.tRPNumber &&
                            touched?.tRPNumber &&
                            Boolean(errors.tRPNumber)
                          }
                          helperText={
                            errors.tRPNumber &&
                            touched?.tRPNumber &&
                            errors.tRPNumber
                          }
                          required
                        />
                      ) : null}
                    </Grid>
                  </Grid>

                  {/* National id given date and validity period */}
                  <Grid
                    onClick={() => setIsDate(true)}
                    sx={{ display: "flex", gap: 3, mb: 3 }}
                  >
                    <Field name="idCardIssuanceDate">
                      {({ field, form }) => (
                        <DatePicker
                          {...field}
                          label={
                            employeeById.isForeigner === true
                              ? "Passportun verilmə tarixi"
                              : "Ş.V. verilmə tarixi *"
                          }
                          inputFormat="dd/MM/yyyy"
                          value={field.value}
                          maxDate={new Date()} // Disable future dates
                          minDate={new Date(1940, 0, 1)}
                          onChange={(date) => {
                            if (date instanceof Date && !isNaN(date)) {
                              // Set the correctly formatted Date object using setFieldValue
                              form.setFieldValue("idCardIssuanceDate", date);
                            } else {
                              // If the date is invalid, set the field value to an empty string
                              form.setFieldValue("idCardIssuanceDate", "");
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              helperText={
                                isDate && form.errors.idCardIssuanceDate
                              } // Display the validation error message
                              error={
                                isDate &&
                                Boolean(form.errors.idCardIssuanceDate)
                              } // Set error state based on the validation error
                              fullWidth
                            />
                          )}
                        />
                      )}
                    </Field>
                    {
                      <Field name="idCardValidityPeriod">
                        {({ field, form }) => (
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              flexDirection: "column",
                            }}
                          >
                            <DatePicker
                              {...field}
                              label={
                                employeeById.isForeigner === true
                                  ? "Passportun etibarlılıq müddəti *"
                                  : "Ş.V. etibarlılıq Müddəti *"
                              }
                              inputFormat="dd/MM/yyyy"
                              value={
                                new Date().getFullYear() -
                                  new Date(values.dob).getFullYear() >
                                50
                                  ? null
                                  : field.value || null
                              }
                              minDate={new Date()}
                              onChange={(date) => {
                                if (
                                  new Date().getFullYear() -
                                    new Date(values.dob).getFullYear() >
                                  50
                                ) {
                                  // Reset idCardValidityPeriod to null
                                  form.setFieldValue(
                                    "idCardValidityPeriod",
                                    null
                                  );
                                } else {
                                  // Age is less than or equal to 50, handle other cases
                                  if (date instanceof Date && !isNaN(date)) {
                                    form.setFieldValue(
                                      "idCardValidityPeriod",
                                      date
                                    );
                                  } else {
                                    form.setFieldValue(
                                      "idCardValidityPeriod",
                                      ""
                                    );
                                  }
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  helperText={form.errors.idCardValidityPeriod} // Display the validation error message
                                  error={Boolean(
                                    form.errors.idCardValidityPeriod
                                  )} // Set error state based on the validation error
                                  fullWidth
                                />
                              )}
                              required
                            />
                            {values?.citizenship === "Azərbaycan" &&
                            new Date().getFullYear() -
                              new Date(values.dob).getFullYear() >
                              50 ? (
                              <span>
                                50 yaş üstü vətəndaşların şəxsiyyət
                                vəsiqələrinin etibarlıq müddəti olmaya bilər!
                              </span>
                            ) : null}
                          </div>
                        )}
                      </Field>
                    }
                  </Grid>

                  {/* For foreign employeers */}
                  {employeeById.isForeigner === true ? (
                    <>
                      {/* Living permission start and end date */}
                      <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                        <Field name="residencePermitStartDate">
                          {({ field, form }) => (
                            <DatePicker
                              {...field}
                              label="Yaşamaq icazəsi başlama tarixi *"
                              inputFormat="dd/MM/yyyy"
                              value={field.value || null}
                              maxDate={new Date()} // Disable future dates
                              minDate={new Date(1940, 0, 1)}
                              onChange={(date) => {
                                if (date instanceof Date && !isNaN(date)) {
                                  form.setFieldValue(
                                    "residencePermitStartDate",
                                    date
                                  );
                                } else {
                                  form.setFieldValue(
                                    "residencePermitStartDate",
                                    ""
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  helperText={
                                    form.errors.residencePermitStartDate
                                  } // Display the validation error message
                                  error={Boolean(
                                    form.errors.residencePermitStartDate
                                  )} // Set error state based on the validation error
                                  fullWidth
                                />
                              )}
                              required
                            />
                          )}
                        </Field>
                        <Field name="residencePermitEndDate">
                          {({ field, form }) => (
                            <DatePicker
                              {...field}
                              label="Yaşamaq icazəsi bitmə tarixi *"
                              inputFormat="dd/MM/yyyy"
                              value={field.value || null}
                              minDate={new Date()} // Disable past dates
                              onChange={(date) => {
                                if (date instanceof Date && !isNaN(date)) {
                                  form.setFieldValue(
                                    "residencePermitEndDate",
                                    date
                                  );
                                } else {
                                  form.setFieldValue(
                                    "residencePermitEndDate",
                                    ""
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  helperText={
                                    form.errors.residencePermitEndDate
                                  } // Display the validation error message
                                  error={Boolean(
                                    form.errors.residencePermitEndDate
                                  )} // Set error state based on the validation error
                                  fullWidth
                                />
                              )}
                              required
                            />
                          )}
                        </Field>
                      </Grid>

                      {/* Work permission start and end date */}
                      <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                        <Field name="workPermitStartDate">
                          {({ field, form }) => (
                            <DatePicker
                              {...field}
                              label="İş icazəsi başlama tarixi *"
                              inputFormat="dd/MM/yyyy"
                              value={field.value || null}
                              maxDate={new Date()} // Disable future dates
                              minDate={new Date(1940, 0, 1)}
                              onChange={(date) => {
                                if (date instanceof Date && !isNaN(date)) {
                                  form.setFieldValue(
                                    "workPermitStartDate",
                                    date
                                  );
                                } else {
                                  form.setFieldValue("workPermitStartDate", "");
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  helperText={form.errors.workPermitStartDate}
                                  error={Boolean(
                                    form.errors.workPermitStartDate
                                  )}
                                  fullWidth
                                />
                              )}
                              required
                            />
                          )}
                        </Field>
                        <Field name="workPermitEndDate">
                          {({ field, form }) => (
                            <DatePicker
                              {...field}
                              label="İş icazəsi bitmə tarixi *"
                              inputFormat="dd/MM/yyyy"
                              value={field.value || null}
                              minDate={new Date()} // Disable past dates
                              onChange={(date) => {
                                if (date instanceof Date && !isNaN(date)) {
                                  form.setFieldValue("workPermitEndDate", date);
                                } else {
                                  form.setFieldValue("workPermitEndDate", "");
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  helperText={form.errors.workPermitEndDate}
                                  error={Boolean(form.errors.workPermitEndDate)}
                                  fullWidth
                                />
                              )}
                              required
                            />
                          )}
                        </Field>
                      </Grid>
                    </>
                  ) : null}

                  {/* SSN */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                      }}
                    >
                      <Grid sx={{ width: "100%" }}>
                        <Field
                          type="text"
                          name="ssn"
                          as={TextField}
                          fullWidth
                          label="SSN"
                          error={
                            errors.ssn && touched.ssn && Boolean(errors.ssn)
                          }
                          helperText={errors.ssn && touched.ssn && errors.ssn}
                        />
                      </Grid>
                      <Grid sx={{ width: "100%" }}>
                        <Field
                          type="text"
                          name="tableNumber"
                          as={TextField}
                          fullWidth
                          label="Table Nömrəsi"
                          // onInput={handleInput}
                          // error={errors.ssn && Boolean(errors.ssn)}
                          // helperText={errors.ssn && errors.ssn}
                          // onChange={(event) => {
                          //   setFieldValue("tableNumber", event.target.value);
                          //   if (errors.ssn) {
                          //     setErrors({ ...errors, ssn: "" });
                          //   }
                          // }}
                        />
                      </Grid>
                    </div>
                    <Grid sx={{ width: "100%" }}>
                      <FormControl
                        error={
                          errors?.maritalStatus &&
                          touched?.maritalStatus &&
                          Boolean(errors?.maritalStatus)
                        }
                        fullWidth
                      >
                        <InputLabel id={`maritalStatus`}>
                          Ailə vəziyyəti *
                        </InputLabel>
                        <Field
                          as={Select}
                          name={`maritalStatus`}
                          labelId={`maritalStatus`}
                          label="Ailə vəziyyəti *"
                          id={`maritalStatus`}
                          defaultValue=""
                          fullWidth
                          error={
                            errors?.maritalStatus &&
                            touched?.maritalStatus &&
                            Boolean(errors?.maritalStatus)
                          }
                        >
                          <MenuItem value="married">Evli</MenuItem>
                          <MenuItem value="single">Subay</MenuItem>
                        </Field>
                        {errors?.maritalStatus &&
                          touched?.maritalStatus &&
                          errors?.maritalStatus && (
                            <FormHelperText error>
                              {errors?.maritalStatus}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {values?.profileImage || values?.employeePhotoAsBase64 ? (
                    <Grid sx={{ width: "50%" }}>
                      <Grid
                        sx={{
                          display: "flex",
                          gap: 3,
                          alignItems: "center",
                        }}
                      >
                        <Button
                          sx={{ height: "50px" }}
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            handleShowImage(values);
                          }}
                        >
                          Şəkli göstər
                        </Button>
                        <Grid sx={{ width: "50%" }}>
                          <Button
                            sx={{ height: "50px" }}
                            fullWidth
                            variant="contained"
                            component="label"
                          >
                            Şəkli dəyişdir
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(event) =>
                                handleFileUpload(event, setFieldValue)
                              }
                              fullWidth
                              hidden
                            />
                          </Button>

                          {/* <ErrorMessage name={``} component="div" /> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid sx={{ width: "50%" }}>
                      <Button
                        sx={{ height: "50px" }}
                        fullWidth
                        variant="contained"
                        component="label"
                      >
                        Şəkil yüklə
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(event) =>
                            handleFileUpload(event, setFieldValue)
                          }
                          fullWidth
                          hidden
                        />
                      </Button>

                      {/* <ErrorMessage name={``} component="div" /> */}
                    </Grid>
                  )}

                  {/* Picture dialog */}
                  <Dialog
                    sx={{ paddingTop: "30px", paddingBottom: "30px" }}
                    fullWidth
                    open={showPicture}
                    onClose={handleCloseImage}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogContent>
                      {values.profileImage || values.employeePhotoAsBase64 ? (
                        <figure className="ProfileImageEdit">
                          {!image.src && <div>Loading...</div>}
                          <img
                            src={
                              image.src ||
                              values.profileImage ||
                              values.employeePhotoAsBase64
                            }
                            alt="Profile"
                            style={{ maxWidth: "100%", height: "auto" }}
                          />
                        </figure>
                      ) : (
                        <div>No profile image</div>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseImage} color="primary">
                        Çıxış et
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>

                {/* Submit button */}
                <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!isValid || isSubmitting || !dirty}
                  >
                    Dəyişiklikləri yadda saxla
                  </Button>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}

export default EditPersonalInformation;
