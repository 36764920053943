// DataTableReducer.js
export const initialState = {
  users: [],
  chats: [],
  groupChat: [],
  totalGroupChats: "",
  openGroupChatUsers: false,
  selectedUser: { id: "" },
  createChatDialog: false,
  chatId: "",
  getUserMessage: [],
  sendMessage: "",
  messageLimit: 10,
  searchUsername: "",
  chatLength: null,
  readStatusUsers: null,
  openChatImage: false,
  deleteChatDialog: false,
  submitted: false,
};

export const chatReducer = (state, action) => {
  switch (action.type) {
    case "SET_CHAT_USERS":
      return {
        ...state,
        users: action.payload,
      };
    case "SET_CHATS":
      return {
        ...state,
        chats: action.payload,
      };
    case "SET_GROUP_CHAT":
      return {
        ...state,
        groupChat: action.payload,
      };
    case "SET_TOTAL_GROUP_CHATS":
      return {
        ...state,
        totalGroupChats: action.payload,
      };
    case "SET_OPEN_GROUP_CHAT_USERS":
      return {
        ...state,
        openGroupChatUsers: action.payload,
      };
    case "SET_SELECTED_USER":
      return {
        ...state,
        selectedUser: action.payload,
      };
    case "SET_CREATE_CHAT_DIALOG":
      return {
        ...state,
        createChatDialog: action.payload,
      };
    case "SET_CHAT_ID":
      return {
        ...state,
        chatId: action.payload,
      };
    case "SET_GET_USER_MESSAGES":
      return {
        ...state,
        getUserMessage: action.payload,
      };
    case "SET_SEND_MESSAGE":
      return {
        ...state,
        sendMessage: action.payload,
      };
    case "SET_MESSAGE_LIMIT":
      return {
        ...state,
        messageLimit: action.payload,
      };
    case "SET_SEARCH_USERNAME":
      return {
        ...state,
        searchUsername: action.payload,
      };
    case "SET_CHAT_LENGTH":
      return {
        ...state,
        chatLength: action.payload,
      };
    case "SET_READ_STATUS_USERS":
      return {
        ...state,
        readStatusUsers: action.payload,
      };
    case "SET_OPEN_CHAT_IMAGE":
      return {
        ...state,
        openChatImage: action.payload,
      };
    case "SET_DELETE_CHAT_DIALOG":
      return {
        ...state,
        deleteChatDialog: action.payload,
      };
    case "SET_SUBMITTED":
      return {
        ...state,
        submitted: action.payload,
      };
    default:
      return state;
  }
};
