import { Close } from "@mui/icons-material";
import React from "react";
import styled from "styled-components";

const Label = styled.div`
  width: 120px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  transition: all 0.3s;
`;

export default function LabelOfDay({
  onDragStart,
  onDragEnd,
  bgColor,
  title,
  active,
  onClick,
  draggable,
}) {
  const focused = {
    transform: active ? "scale(1.1)" : "scale(1)",
    border: active ? "2px solid rgba(255, 255, 255, 1)" : "none",
  };

  return (
    <Label
      draggable={draggable}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      style={{ backgroundColor: bgColor, ...focused }}
      onClick={onClick}
    >
      {title}
    </Label>
  );
}
