import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { bgColorHandler, COLORS_STATUS, titleHandler } from "../../constants";
import { Close, Check, Edit } from "@mui/icons-material";

const Container = styled.div`
  text-align: left;
  transition: border 0.3s;
`;

const Day = ({
  day,
  isCurrentMonth,
  isCurrentDay,
  onDrop,
  onDragOver,
  styleContainer,
  isSelect,
  onDelete,
  onClick,
  editable,
  dayIsDeleted,
  handleIsDeletedDay,
  handleDayIsEdited,
  handleDailyHours,
  updatedCalendar,
  limitExceed,
}) => {
  // const [offdayType, setOffdayType] = useState();
  const [alreadyShortened, setAlreadyShortened] = useState();
  // const [dayIsDeleted, setDayIsDeleted] = useState();

  // useEffect(() => {
  //   setOffdayType(day.offDayType);
  // }, [day.offDayType]);

  useEffect(() => {
    setAlreadyShortened(day.alreadyShortened);
  }, [day.alreadyShortened]);

  const deleteLabelHadnler = (dayId) => {
    // setOffdayType(undefined);
    handleIsDeletedDay(dayId);
    // dayIsDeleted.set(!dayIsDeleted.state);
    onDelete(day);
  };

  const onClickHandler = () => {
    onClick(day);
  };

  return (
    <Container
      // className={`day ${
      //   day.isCurrentMonth ? "current-month" : "other-month"
      // }`}
      style={{
        position: "relative",
        height: "100%",
        // color: "white",
        color: day.isCurrentMonth ? "white" : "grey",
        border: isSelect
          ? // && isCurrentMonth
            "1px solid white"
          : "none",

        // backgroundColor:
        //   day.isCurrentMonth && day.backgroundColor && day.backgroundColor,
        ...styleContainer,
      }}
      // onDragOver={day.isCurrentMonth ? onDragOver : () => {}}
      onDragOver={onDragOver}
      // onDrop={day.isCurrentMonth ? onDrop : () => {}}
      onDragEnter={(e) => {
        e.stopPropagation();
        if (day.isCurrentMonth) {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            e.currentTarget.style.border = "1px solid white";
          }
        }
      }}
      onDragLeave={(e) => {
        e.stopPropagation();
        if (day.isCurrentMonth) {
          const container = e.currentTarget;
          const isContained = container.contains(e.relatedTarget);
          if (!isContained) {
            container.style.border = "none";
          }
        }
      }}
      onDrop={(e) => {
        e.stopPropagation();
        // Reset the border on drop
        e.currentTarget.style.border = "none";
        // Handle the drop operation
        if (day.isCurrentMonth) {
          onDrop(e);
        }
      }}
      onClick={onClickHandler}
    >
      {limitExceed && <div style={limitExcedLayer}></div>}
      <div
        style={{
          width: "100%",
          height: "100%",
          padding: "0px 5px",
          position: "relative",
        }}
      >
        {day?.day}
        {day.offDayType ? (
          <div
            style={{
              borderRadius: "5px",
              marginTop: "5px",
              backgroundColor: bgColorHandler(day.offDayType),
              width: "100%",
              textAlign: "center",
              border: "1px solid white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "5px",
              height: "25px",
              opacity: day?.dayIsDeleted && "0.4",
            }}
          >
            <span
              style={{ textDecoration: day?.dayIsDeleted && "line-through" }}
            >
              {titleHandler(day.offDayType)}
            </span>
            {editable && (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => deleteLabelHadnler(day?.dayId)}
              >
                {day?.dayIsDeleted ? <Check /> : <Close />}
              </div>
            )}
          </div>
        ) : alreadyShortened ? (
          <div
            style={{
              borderRadius: "5px",
              marginTop: "5px",
              backgroundColor: bgColorHandler(),
              width: "100%",
              textAlign: "center",
              border: "1px solid yellow",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "5px",
              height: "25px",
              opacity: day?.dayIsDeleted && "0.4",
            }}
          >
            {/* <span
              style={{ textDecoration: day?.dayIsDeleted && "line-through" }}
            >
              Qısa iş günü - {day?.dailyHours} saat
            </span>
            {editable && (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => deleteLabelHadnler(day?.dayId)}
              >
                {day?.dayIsDeleted ? <Check /> : <Edit />}
              </div>
            )} */}
            {!editable && (
              <span
                style={{ textDecoration: day?.dayIsDeleted && "line-through" }}
              >
                Qısa iş günü - {day?.dailyHours} saat
              </span>
            )}
            {editable &&
              (!day?.dayIsEdited ? (
                <>
                  <span>{day?.dailyHours} saat</span>
                  <div
                    onClick={() => handleDayIsEdited(day?.dayId)}
                    style={{ cursor: "pointer" }}
                  >
                    <Edit />
                  </div>
                  {/* // ) : null} */}
                </>
              ) : (
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    // width: "50%",
                  }}
                  //
                >
                  <input
                    style={{
                      width: "100%",
                      border: "1px",
                      borderRadius: "5px",
                      outline: "0",
                      background: "transparent",
                      color: "#fff",
                    }}
                    autoFocus={day?.dayIsEdited}
                    type="text"
                    min={-1}
                    value={day?.dailyHours}
                    // value={
                    //   updatedCalendar?.dayDetails?.find(
                    //     (uc) => uc?.dayId === day?.dayId
                    //   )?.dailyHours > 24
                    //     ? 0
                    //     : updatedCalendar?.dayDetails?.find(
                    //         (uc) => uc?.dayId === day?.dayId
                    //       )?.dailyHours
                    // }
                    onChange={(e) =>
                      handleDailyHours(+e.target.value, day?.dayId)
                    }
                  />{" "}
                  <div
                    // onClick={() => handleDayIsEdited(day?.dayId)}
                    style={{ cursor: "pointer" }}
                  >
                    <Edit />
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div
            style={{
              marginTop: "5px",
              borderRadius: "5px",
              backgroundColor: bgColorHandler(),
              width: "100%",
              textAlign: "center",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "25px",
              border: "1px solid cyan",
              padding: "0 5px",
              // opacity: day?.dayIsDeleted && "0.4",
            }}
          >
            {!editable && <span>{day?.dailyHours} saat</span>}
            {editable &&
              (!day?.dayIsEdited ? (
                <>
                  <span>{day?.dailyHours} saat</span>
                  <div
                    onClick={() => handleDayIsEdited(day?.dayId)}
                    style={{ cursor: "pointer" }}
                  >
                    <Edit />
                  </div>
                  {/* // ) : null} */}
                </>
              ) : (
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    // width: "50%",
                  }}
                  //
                >
                  <input
                    style={{
                      width: "100%",
                      border: "1px",
                      borderRadius: "5px",
                      outline: "0",
                      background: "transparent",
                      color: "#fff",
                    }}
                    autoFocus={day?.dayIsEdited}
                    type="text"
                    min={-1}
                    value={day?.dailyHours}
                    // value={
                    //   updatedCalendar?.dayDetails?.find(
                    //     (uc) => uc?.dayId === day?.dayId
                    //   )?.dailyHours > 24
                    //     ? 0
                    //     : updatedCalendar?.dayDetails?.find(
                    //         (uc) => uc?.dayId === day?.dayId
                    //       )?.dailyHours
                    // }
                    onChange={(e) =>
                      handleDailyHours(+e.target.value, day?.dayId)
                    }
                  />{" "}
                  <div
                    // onClick={() => handleDayIsEdited(day?.dayId)}
                    style={{ cursor: "pointer" }}
                  >
                    <Edit />
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </Container>
  );
};

let limitExcedLayer = {
  background: "#ff000030",
  position: "absolute",
  width: "100%",
  height: "100%",
};
// && day?.offDayType !== null && !day?.isWorkDay
export default Day;
