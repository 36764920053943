import { Cancel, ManageSearch } from "@mui/icons-material";
import React, { createRef, memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import FilterModel from "../../models/table/Filter/FilterModel";
import { getDescendantProp } from "../../utils/helperFunctions";
import ValueInput from "./parts/Filter/parts/dropdown/body/filterItem/ValueInput";
import LoadingTable from "./parts/LoadingTable";
import TableFooter from "./parts/TableFooter";
import Toolbar from "./parts/Toolbar";
import moment from "moment";
import Column from "../../models/table/Column";

const Container = styled.div`
  width: 100%;
  overflow: visible;
  border: 2px solid rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  padding: 5px;
`;

const TableContainer = styled.div`
  overflow-x: auto;
`;

const TableComponent = styled.table`
  border-collapse: collapse;
  border-spacing: 0px;
  border-radius: 5px;
  padding: 10px 40px;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
  min-width: 100%;
  display: table;
`;

const Col = styled.col`
  padding: 5px 15px;
  border: 1px solid rgba(255, 255, 255, 0.025);
  display: table-column;
  overflow: auto;
  min-width: 50px;
`;

const CellCont = styled.div`
  display: flex;
  justify-content: center;
  row-gap: 5px;
  align-items: center;
  text-align: center;
  min-height: 20px;
  position: relative;
  text-overflow: ellipsis;
`;

const TH = styled.th`
  resize: horizontal;
  white-space: nowrap;
  padding: 5px 5px;
  text-align: center;
  font-weight: 500;
  text-overflow: ellipsis;
  cursor: default;
  min-width: fit-content;
  overflow: hidden;
  width: ${({ $width }) => $width}px;
`;
// width: ${({ $isStatic }) => $isStatic && "200px"};

const Td = styled.td`
  padding: 10px;
  margin: 0;
  font-weight: 100;
`;

const Tr = styled.tr`
  border: 1px solid rgba(255, 255, 255, 0.05);
`;
const SubHeaderTr = styled.tr`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  gap: 15px;
`;

const Colgroup = styled.colgroup``;

const Thead = styled.thead`
  border-radius: 3px;
  background-color: rgba(21, 27, 76, 0.7);
  border-bottom: 1px solid rgba(41, 47, 96, 1);
`;

const TableWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  overflow: auto;
`;

function Table({
  columns,
  data,
  totalRows,
  offset,
  setOffset,
  limit,
  setLimit,
  isLoading,
  graphicName,
  applyFiltersHandler,
  cancelAppliedFilters,
  downloadHandler,
  addLeftContButtons,
  addRightContButtons,
  hideColumnVisibility = false,
  hideDownload = false,
  hideFilter = false,
  editMode,
  setShowFilter,
}) {
  const initialInputsValues =
    columns?.length &&
    columns.reduce((acc, column) => {
      if (column.filterType?.length > 0 && column.isSubHeader === false) {
        return {
          ...acc,
          [column.path]: new FilterModel({
            column: column.path,
            operator: column.filterType[0].key,
            value: "",
          }),
        };
      } else if (column.filterType?.length > 0 && column.isSubHeader === true) {
        column.subHeader?.forEach((subHeader) => {
          acc[subHeader.colmun] = new FilterModel({
            column: subHeader.colmun,
            operator: subHeader.filterType[0].key,
            value: "",
          });
        });
        return acc;
      }
      return acc;
    }, {});

  const [showColSearch, setShowColSearch] = useState(false);
  const [fontSize, setFontSize] = useState(14);
  const [cols, setCols] = useState([]);
  const [colWidth, setColWidth] = useState(150);

  const [quickFilters, setQuickFilters] = useState(initialInputsValues || {});

  const tableRef = createRef(null);

  useEffect(() => {
    const { width } = tableRef.current.getBoundingClientRect();
    // setColWidth((+width - 50) / columns.length);
  }, []);

  useEffect(() => {
    const sortedColumns = [...columns].sort((a, b) => {
      if (a.isStaticColumn && !b.isStaticColumn) {
        return 1; // Move 'a' (isStaticColumn: true) to the end
      } else if (!a.isStaticColumn && b.isStaticColumn) {
        return -1; // Keep 'b' (isStaticColumn: true) before 'a'
      } else {
        return 0; // Maintain the current order
      }
    });

    setCols(sortedColumns);
  }, []);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("index", index.toString());
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, newIndex) => {
    e.preventDefault();
    const staticColumns = cols?.filter((item) => item?.isStaticColumn);
    const changingArea = cols?.length - 1 - (staticColumns?.length || 0);

    const oldIndex = parseInt(e.dataTransfer.getData("index"));
    if (cols[oldIndex].isStaticColumn || newIndex > changingArea) return;

    const updatedCols = [...cols];
    const [movedCol] = updatedCols.splice(oldIndex, 1);

    updatedCols.splice(newIndex, 0, movedCol);

    setCols(updatedCols);
  };

  // const generateQuickFilterState = () => {
  //   const arr = columns?.map((col) => {
  //     return {
  //       id: col.path,
  //       column: col.path,
  //       operator: col?.filterType && col.filterType[0]?.key,
  //       isQuickFilter: true,
  //       value: "",
  //     };
  //   });
  //   setQuickFilters(arr);
  // };

  const singleFilterHandler = (column, value) => {
    setQuickFilters((prevValues) => {
      if (value === " ") {
        value = value.trim();
      } else if (value?.slice(value?.length - 2) === "  ") {
        value = value?.slice(0, value?.length - 1);
      }

      // For columns without subheaders
      return {
        ...prevValues,
        [column?.colmun ? column?.colmun : column.path]: new FilterModel({
          column: column?.colmun
            ? column?.colmun
            : column.filterColumnKey
            ? column.filterColumnKey
            : column.path,
          operator: column.filterType[0].key,
          value: column?.colmun ? parseInt(value) : value,
        }),
      };
    });
  };

  const resetQuickFilterHandler = (column) => {
    setQuickFilters((prevValues) => ({
      ...prevValues,
      [column?.colmun ? column?.colmun : column?.path]: new FilterModel({
        column: column?.colmun
          ? column?.colmun
          : column?.filterColumnKey
          ? column?.filterColumnKey
          : column?.path,
        operator: column?.filterType?.[0]?.key,
        value: "",
      }),
    }));
  };

  const emptyRowRender = () => {
    const emptyCount = limit && (isLoading ? limit : limit - data?.length);

    const tableRows = [];

    if (emptyCount <= 0) return;

    for (let i = 0; i < emptyCount; i++) {
      tableRows.push(
        <Tr key={"empty" + i}>
          <Td>
            <CellCont></CellCont>
          </Td>
        </Tr>
      );
    }
    return tableRows;
  };

  const cancelAppliedFiltersHandler = async () => {
    setQuickFilters(initialInputsValues);
    await cancelAppliedFilters();
  };

  const sendColumnsForColumns = async () => {
    await downloadHandler(cols);
  };

  const formatDate = (dateString) => {
    const date = moment(dateString);
    const formattedDate = date.format("DD/MM/YYYY");
    return formattedDate;
  };

  return (
    <Container>
      <Toolbar
        columns={{ data: cols, setData: setCols }}
        fontSize={fontSize}
        fontSizeHandler={(value) => setFontSize(value)}
        limit={limit}
        setLimit={(value) => {
          setOffset(0);
          setLimit(value);
        }}
        applyFiltersHandler={applyFiltersHandler}
        cancelAppliedFilters={cancelAppliedFiltersHandler}
        quickFilters={quickFilters}
        downloadHandler={sendColumnsForColumns}
        addLeftContButtons={addLeftContButtons}
        addRightContButtons={addRightContButtons}
        hideColumnVisibility={hideColumnVisibility}
        hideFilter={hideFilter}
        hideDownload={hideDownload}
        setColWidth={setColWidth}
        colWidth={colWidth}
        editMode={editMode}
        setShowFilter={setShowFilter}
      />
      <div style={{ position: "relative" }}>
        <TableWrapper>
          <TableContainer ref={tableRef}>
            <TableComponent>
              <Colgroup>
                <Col style={{ maxWidth: "60px" }} />
                {cols?.map((col, index) =>
                  col.isShow ? (
                    col.isSubHeader === false ? (
                      <Col
                        key={col.id || index}
                        style={{
                          minWidth: col.minWidth || "auto",
                        }}
                      />
                    ) : (
                      <React.Fragment key={col.id || index}>
                        <Col style={{ minWidth: col.minWidth || "auto" }} />
                        <Col style={{ minWidth: col.minWidth || "auto" }} />
                        <Col style={{ minWidth: col.minWidth || "auto" }} />
                      </React.Fragment>
                    )
                  ) : null
                )}
              </Colgroup>
              <Thead>
                <Tr>
                  <TH
                    style={{
                      userSelect: "none",
                      resize: "none",
                      maxWidth: "18px",
                      width: "18px",
                    }}
                  >
                    <span>№</span>
                  </TH>
                  {cols?.map(
                    (item, index) =>
                      item?.isShow && (
                        <TH
                          $isStatic={item.isStaticColumn}
                          $width={
                            item?.isSubHeader
                              ? "240" || colWidth
                              : item.widthOfColumn || colWidth
                          }
                          key={index}
                          draggable
                          onDragStart={(e) => handleDragStart(e, index)}
                          onDragOver={handleDragOver}
                          onDrop={(e) => handleDrop(e, index)}
                          colSpan={item.colSpan || undefined}
                        >
                          <div
                            style={{
                              width: "100%",
                              textOverflow: "ellipsis",
                              padding: "0px 5px",
                              whiteSpace: "nowrap",
                              textAlign: "center",
                              overflow: "hidden",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                alignItems: "center",
                                fontSize: fontSize,
                                textAlign: "center",
                              }}
                            >
                              {!hideFilter &&
                                !item.disableColSearch &&
                                !item.isSubHeader && (
                                  <ManageSearch
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      setShowColSearch(!showColSearch)
                                    }
                                  />
                                )}
                              {item.headerName}
                            </div>
                            {!item.isSubHeader && (
                              <div
                                style={{
                                  width: "100%",
                                  transition: "all 0.5s",
                                  height: showColSearch ? "40px" : "0px",
                                  opacity: showColSearch ? 1 : 0,
                                  overflow: "hidden",
                                  marginTop: showColSearch && "7px",
                                }}
                              >
                                {!item.disableColSearch && (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <ValueInput
                                      key={item.path}
                                      column={item}
                                      setQuickFilters={setQuickFilters}
                                      values={item.filterValues}
                                      fetchHandler2={item.fetchFilterValuesV2}
                                      dataGridName={item.dataGridName}
                                      fetchHandler={item.fetchFilterValues}
                                      filterType={item.filterType}
                                      onChange={(e) => {
                                        singleFilterHandler(item, e);
                                      }}
                                      value={quickFilters?.[item.path]?.value}
                                      filter={quickFilters?.[item.path]}
                                      filters={quickFilters}
                                      triggerFetching={showColSearch}
                                    />
                                    <div
                                      onClick={() =>
                                        resetQuickFilterHandler(item)
                                      }
                                      style={{
                                        cursor: "pointer",
                                        margin: "auto",
                                      }}
                                    >
                                      <Cancel />
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          {item?.colSpan && (
                            <SubHeaderTr>
                              {item.subHeader?.map((subHeader, subIndex) => (
                                <th key={subIndex}>
                                  {subHeader.name}
                                  <ManageSearch
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      setShowColSearch(!showColSearch)
                                    }
                                  />
                                  <div
                                    style={{
                                      width: "100%",
                                      transition: "all 0.5s",
                                      height: showColSearch ? "40px" : "0px",
                                      opacity: showColSearch ? 1 : 0,
                                      overflow: "hidden",
                                      marginTop: showColSearch && "7px",
                                    }}
                                  >
                                    {!item.disableColSearch && (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <ValueInput
                                          key={subHeader?.column}
                                          column={subHeader}
                                          values={item.filterValues}
                                          fetchHandler2={
                                            item?.fetchFilterValuesV2
                                          }
                                          dataGridName={item?.dataGridName}
                                          fetchHandler={item?.fetchFilterValues}
                                          filterType={subHeader?.filterType}
                                          onChange={(e) => {
                                            singleFilterHandler(subHeader, e);
                                          }}
                                          value={
                                            quickFilters?.[subHeader?.column]
                                              ?.value
                                          }
                                          filter={
                                            quickFilters?.[subHeader?.column]
                                          }
                                          filters={quickFilters}
                                          triggerFetching={showColSearch}
                                        />
                                        <div
                                          onClick={() =>
                                            resetQuickFilterHandler(subHeader)
                                          }
                                          style={{
                                            cursor: "pointer",
                                            margin: "auto",
                                          }}
                                        >
                                          <Cancel />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </th>
                              ))}
                            </SubHeaderTr>
                          )}
                        </TH>
                      )
                  )}
                </Tr>
              </Thead>
              <tbody>
                {!isLoading &&
                  Array.isArray(data) &&
                  data.length > 0 &&
                  data.map((row, rowIndex) => {
                    const maxDays = 7;
                    const thresholdHours = row?.structure?.workHourMode;
                    let totalHours;
                    if (row?.graphics) {
                      for (
                        let i = 0;
                        i <= row?.graphics.length - maxDays;
                        i++
                      ) {
                        const week = row.graphics.slice(i, i + maxDays);
                        totalHours = week.reduce(
                          (sum, day) => sum + day.dailyHours,
                          0
                        );
                      }
                    }
                    return (
                      <Tr
                        key={rowIndex}
                        style={{
                          background:
                            totalHours > thresholdHours && "#ff000014",
                        }}
                      >
                        <Td>
                          {totalHours > thresholdHours && (
                            <div
                              style={{
                                position: "absolute",
                                transform: "translate(-3px, 33px)",
                                fontSize: "11px",
                                background: "#570101",
                              }}
                            >
                              Həftəlik norma saat pozulmuşdur
                            </div>
                          )}
                          <CellCont>{offset + rowIndex + 1}</CellCont>
                        </Td>
                        {cols?.map(
                          (col, colIndex) =>
                            col?.isShow &&
                            (col?.isSubHeader === false && !col?.rowSpan ? (
                              <Td key={colIndex}>
                                {col.renderCell ? (
                                  col.renderCell(row, col)
                                ) : (
                                  <CellCont>
                                    <div
                                      style={{
                                        fontSize: fontSize,
                                        textOverflow: "ellipsis",
                                        width: "250px",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {col.path === "dob"
                                        ? formatDate(
                                            getDescendantProp(row, col.path)
                                          )
                                        : col.getValue
                                        ? col.getValue(row, col)
                                        : getDescendantProp(row, col.path)}
                                    </div>
                                  </CellCont>
                                )}
                              </Td>
                            ) : (
                              col.renderCell && col.renderCell(row, col)
                            ))
                        )}
                      </Tr>
                    );
                  })}
                {emptyRowRender()}
              </tbody>
              {isLoading && <LoadingTable />}
            </TableComponent>
          </TableContainer>
        </TableWrapper>
        <TableFooter
          totalRows={totalRows}
          limit={limit}
          offset={offset}
          paginationHandler={(page) => {
            const value = (+page - 1) * limit;
            setOffset(value);
          }}
        />
      </div>
    </Container>
  );
}

export default memo(Table);
