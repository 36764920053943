import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import DetailedRequestStatistics from "../../../components/vacations/hrpage/DetailedRequestStatistics";
import DetailedVacationTable from "../../../components/vacations/hrpage/DetailedVacationTable";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function DetailedComponents() {
  return (
    <>
      <ToastContainer />

      {/* Statistics */}
      <Card mb={6}>
        <DetailedRequestStatistics />
      </Card>

      <Card mb={6} sx={{ height: "730px" }}>
        <DetailedVacationTable />
      </Card>
    </>
  );
}

function DetailedHrVacations() {
  return (
    <React.Fragment>
      <Helmet title="Cari məzuniyyət sorğularının statusları" />
      <Typography variant="h3" gutterBottom display="inline">
        Cari məzuniyyət sorğularının statusları
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <DetailedComponents />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default DetailedHrVacations;
