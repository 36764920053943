import style from "../myProfileStyle.module.css";
import { Grid } from "@mui/material";

import MCEditPersonalInformation from "../Personal_informations_view_part/MCViewPersonalInformation";

export default function MyProfileInfo({ employeeById }) {
  return (
    <>
      <Grid className={style.myCabinetFirstSection}>
        <MCEditPersonalInformation employeeById={employeeById} />
      </Grid>
    </>
  );
}
