import React from "react";
import { Delete } from "@mui/icons-material";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { ErrorMessage, Field } from "formik";

const DecisionFormControl = ({
  index,
  remove,
  values,
  handleChange,
  setFormValues,
  employeesData,
}) => {
  function handleRenderEmployee(value) {
    const selectedItem =
      employeesData &&
      employeesData.employees.find((item) =>
        item?.staffs?.some((staff) => staff?._id === value)
      );
    if (selectedItem) {
      const selectedStaff = selectedItem?.staffs?.find(
        (staff) => staff?._id === value
      );
      if (
        selectedStaff &&
        selectedStaff.position &&
        selectedStaff.position.name
      ) {
        return `${selectedItem?.firstname} ${selectedItem?.lastname} ${selectedStaff.position.name}`;
      } else {
        return `${selectedItem?.firstname} ${selectedItem?.lastname}`;
      }
    } else {
      return "";
    }
  }
  return (
    <Grid
      fullWidth
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: 3,
      }}
    >
      <FormControl fullWidth>
        <InputLabel id={`decisionChainArray[${index}]`}>
          Təsdiqləyici əməkdaşı seçin
        </InputLabel>
        <Field
          as={Select}
          name={`decisionChainArray[${index}]`}
          labelId={`decisionChainArray[${index}]`}
          label="Təsdiqləyici əməkdaşı seçin"
          id={`decisionChainArray[${index}]`}
          fullWidth
          onChange={(e) => {
            handleChange(e);
            setFormValues(values);
          }}
          renderValue={(value) => handleRenderEmployee(value)}
        >
          {employeesData &&
            employeesData?.employees && // Add conditional check
            employeesData?.employees.map((item, index) => {
              if (item?.staffs?.length === 1) {
                const positionName = item?.staffs[0]?.position?.name;
                const fullName = `${item?.firstname} ${item?.lastname}`;
                return (
                  <MenuItem
                    key={item?.staffs[0]?._id}
                    value={item?.staffs[0]?._id}
                  >
                    {fullName} {positionName ? positionName : ""}
                  </MenuItem>
                );
              } else {
                const fullName = `${item?.firstname} ${item?.lastname}`;
                return [
                  <MenuItem
                    disabled
                    value={item?.staffs.map((staff) => staff?._id)}
                  >
                    {fullName}
                  </MenuItem>,
                  item?.staffs.map((staff) => {
                    const positionName = staff?.position?.name;
                    return (
                      <MenuItem
                        className="disable_worker"
                        value={staff?._id}
                        key={staff?.position?._id}
                      >
                        {positionName ? positionName : ""}
                      </MenuItem>
                    );
                  }),
                ];
              }
            })}
        </Field>
        <ErrorMessage
          name={`decisionChainArray[${index}]`}
          component="div"
          style={{ color: "#f44336" }}
        />
      </FormControl>
      <IconButton onClick={() => remove(index)} size="small">
        <Delete color="error" />
      </IconButton>
    </Grid>
  );
};

export default DecisionFormControl;
