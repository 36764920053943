import {
  Grid,
  Typography,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Avatar,
} from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";

import { spacing } from "@mui/system";
import styled from "@emotion/styled";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const VacationHeader = (props) => {
  const { headerTitle, employee } = props;
  return (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Grid>
        <Helmet title={headerTitle} />
        <Typography variant="h3" gutterBottom display="inline">
          {headerTitle}
        </Typography>
      </Grid>
      <Grid sx={{ display: "flex", alignItems: "center", gap: 3 }}>
        <Grid>
          <Avatar src={employee?.picture} />
        </Grid>
        <Grid>
          <Typography>
            {employee.firstname} {employee.lastname}
          </Typography>
          <Typography>
            {employee?.position?.name
              ? employee.position.name
              : "Vəzifə qeyd olunmayıb"}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VacationHeader;
