import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import { putExplanations } from "../../../services/employeeApplication(employee)/EmployeeApplicationService";

// CSS

export default function ExplanationData({ row, open, onClose, click }) {
  const [inputValue, setInputValue] = useState("");
  async function sendExplanationData() {
    try {
      await putExplanations({
        id: row?.id,
        explanation: inputValue,
      });

      toast.info("Məlumat göndərildi", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      onClose();
    } catch (error) {
      toast.error("Xəta baş verdi", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
    }
  }

  return (
    <>
      <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
        <ToastContainer />

        <DialogTitle>
          İzahat (Xahiş olunur aşağıdakı xanaya izahatınızı qeyd
          edəsiniz.Müvafiq sənəd başlıqları sənədə avtomatik əlavə olunacaq.){" "}
        </DialogTitle>
        <DialogContent sx={{ width: "100%" }}>
          <div
            style={{
              margin: "5px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <textarea
              onChange={(e) => setInputValue(e.target.value)}
              style={{ width: "100%", height: "200px", border: "none" }}
              placeholder="izahatınızı bura yazın"
              name="explanationtext"
            />
            <button
              onClick={sendExplanationData}
              style={
                inputValue.length
                  ? {
                      pointerEvents: "auto",
                      border: "none",
                      backgroundColor: "#2a3889",
                      color: "#fff",
                      borderRadius: "5px",
                      padding: "5px",
                    }
                  : {
                      pointerEvents: "none",
                      border: "none",
                      backgroundColor: "#2a3889",
                      color: "#fff",
                      borderRadius: "5px",
                      padding: "5px",
                    }
              }
            >
              Göndər
            </button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={click} color="primary">
            Bağla
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
