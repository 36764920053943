import { Add as AddIcon } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfDay, isAfter } from "date-fns";
import { Field, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getCommonData } from "../../api/api";
import useStaticData from "../../hooks/useStaticData/useStaticData";

const ChildContainer = styled.div`
  width: 100%;
`;

const LabelContainer = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`;

export default function DrivingLicenseStep({
  isHas,
  setIsHas,
  submitted,
  setSelectedType,
  setOpenAddDialog,
}) {
  const [licenseTypes, setLicenseTypes] = useState([]);
  const { values, errors, setErrors, setFieldValue } = useFormikContext();
  const [issuers, setIssuers] = useState();

  const startDate = values.driverLicense.licenseIssuanceDate;
  const endDate = values.driverLicense.licenseExpirationDate;
  const today = endOfDay(new Date());

  const [empStaticData] = useStaticData();

  useEffect(() => {
    const fetchOptions = async () => {
      const issuersList = await getCommonData("identityCardissuerList");
      setIssuers(issuersList.data);
    };
    fetchOptions();
  }, [submitted]);
  useEffect(() => {
    setLicenseTypes(empStaticData["licenceCategories"].data);
  }, [empStaticData]);

  useEffect(() => {
    // Populate fields with data from props
    if (values.driverLicense) {
      setFieldValue(
        "driverLicense.licenseIssuer",
        values.driverLicense.licenseIssuer || ""
      );
      setFieldValue(
        "driverLicense.licenseIssuanceDate",
        values.driverLicense.licenseIssuanceDate || ""
      );
      setFieldValue(
        "driverLicense.licenseExpirationDate",
        values.driverLicense.licenseExpirationDate || ""
      );
      setFieldValue(
        "driverLicense.licenseNumber",
        values.driverLicense.licenseNumber || ""
      );
      setFieldValue(
        "driverLicense.licenseCategory",
        values.driverLicense.licenseCategory || []
      );
    }
  }, [values.driverLicense, setFieldValue]);

  const renderTextField = ({ name, label }) => (
    <LabelContainer>
      <Field
        type="text"
        name={`driverLicense.${name}`}
        as={TextField}
        fullWidth
        label={`${label}`}
        error={Boolean(errors.driverLicense?.[name])}
        helperText={errors.driverLicense?.[name]}
        onChange={(event) => {
          setFieldValue(`driverLicense.${name}`, event.target.value);
          if (errors?.driverLicense?.[name]) {
            setErrors({
              ...errors,
              driverLicense: {
                ...errors?.driverLicense,
                [name]: "",
              },
            });
          }
        }}
      />
    </LabelContainer>
  );

  const renderDateField = ({ name, label, maxDate, minDate }) => (
    <LabelContainer>
      <Field
        name={`driverLicense.${name}`}
        render={({ field, form }) => (
          <DatePicker
            {...field}
            fullWidth
            label={`${label}`}
            error={Boolean(errors.driverLicense?.[name])}
            helperText={errors.driverLicense?.[name]}
            inputFormat="dd/MM/yyyy"
            value={field.value ? new Date(field.value) : null}
            maxDate={maxDate}
            minDate={minDate ? minDate : today}
            onChange={(date) => {
              if (name === "licenseIssuanceDate") {
                if (date && !isAfter(date, today)) {
                  form.setFieldValue(`driverLicense.${name}`, date);
                  if (errors?.driverLicense?.[name]) {
                    setErrors({
                      ...errors,
                      driverLicense: {
                        ...errors?.driverLicense,
                        [name]: "",
                      },
                    });
                  }
                } else {
                  form.setFieldValue(`driverLicense.${name}`, "");
                }
              } else {
                form.setFieldValue(`driverLicense.${name}`, date);
                if (errors?.driverLicense?.[name]) {
                  setErrors({
                    ...errors,
                    driverLicense: {
                      ...errors?.driverLicense,
                      [name]: "",
                    },
                  });
                }
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={errors.driverLicense?.[name]}
                error={Boolean(errors.driverLicense?.[name])}
                fullWidth
              />
            )}
          />
        )}
      />
    </LabelContainer>
  );

  const renderSelectField = ({ name, label, items }) => (
    <LabelContainer>
      <FormControl error={Boolean(errors.driverLicense?.[name])} fullWidth>
        <InputLabel id={`driverLicense.${name}`}>{`${label} *`}</InputLabel>
        <Field
          as={Select}
          name={`driverLicense.${name}`}
          labelId={`driverLicense.${name}`}
          label={`${label}`}
          fullWidth
          defaultValue=""
          error={Boolean(errors.driverLicense?.[name])}
        >
          <MenuItem hidden value=""></MenuItem>
          {items.map((item, index) => (
            <MenuItem key={`${item} ${index}`} value={item}>
              {item}
            </MenuItem>
          ))}
        </Field>
        {errors.driverLicense?.[name] && (
          <FormHelperText error>{errors.driverLicense?.[name]}</FormHelperText>
        )}
      </FormControl>
    </LabelContainer>
  );

  const renderMultipleSelectField = ({ name, label, options }) => (
    <LabelContainer>
      <FormControl error={Boolean(errors.driverLicense?.[name])} fullWidth>
        <InputLabel id={`driverLicense.${name}`}>{`${label} *`}</InputLabel>
        <Field
          as={Select}
          name={`driverLicense.${name}`}
          labelId={`driverLicense.${name}`}
          label={`${label}`}
          fullWidth
          defaultValue={[]}
          multiple
          error={Boolean(errors.driverLicense?.[name])}
          onChange={(event) => {
            setFieldValue(`driverLicense.${name}`, event.target.value);
            if (errors?.driverLicense?.[name]) {
              setErrors({
                ...errors,
                driverLicense: {
                  ...errors?.driverLicense,
                  [name]: "",
                },
              });
            }
          }}
        >
          {options &&
            [...options]
              .sort((a, b) => a.localeCompare(b, "az"))
              .map((option, index) => (
                <MenuItem key={`${option} ${index}`} value={option}>
                  {option}
                </MenuItem>
              ))}
        </Field>
        {errors.driverLicense?.[name] && (
          <FormHelperText error>{errors.driverLicense?.[name]}</FormHelperText>
        )}
      </FormControl>
    </LabelContainer>
  );

  const resetFormFields = () => {
    setFieldValue("driverLicense.licenseIssuer", "");
    setFieldValue("driverLicense.licenseIssuanceDate", "");
    setFieldValue("driverLicense.licenseExpirationDate", "");
    setFieldValue("driverLicense.licenseNumber", "");
    setFieldValue("driverLicense.licenseCategory", []);
  };
  return (
    <>
      {!isHas && (
        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop: "12px" }}
          onClick={() => setIsHas(true)}
        >
          <AddIcon />
          Sürücülük vəsiqəsi əlavə et
        </Button>
      )}
      {isHas && (
        <>
          <Typography variant="h6" mb={3} mt={3}>
            Sürücülük vəsiqəsi
          </Typography>

          <Grid>
            <ChildContainer>
              <LabelContainer>
                <FormControl
                  error={
                    errors?.driverLicense?.licenseIssuer &&
                    errors.driverLicense.licenseIssuer
                  }
                  fullWidth
                >
                  <InputLabel id="driverLicense.licenseIssuer">
                    Sənədi verən orqan *
                  </InputLabel>

                  <Field
                    as={Select}
                    name="driverLicense.licenseIssuer"
                    labelId="driverLicense.licenseIssuer"
                    label="Sənədi verən orqan"
                    id="licenseIssuer"
                    defaultValue=""
                    fullWidth
                    error={
                      errors.driverLicense?.licenseIssuer &&
                      Boolean(errors.driverLicense.licenseIssuer)
                    }
                    onChange={(event) => {
                      setFieldValue(
                        "driverLicense.licenseIssuer",
                        event.target.value
                      );
                      if (errors?.driverLicense?.licenseIssuer) {
                        setErrors({
                          ...errors,
                          driverLicense: {
                            ...errors?.driverLicense,
                            licenseIssuer: "",
                          },
                        });
                      }
                    }}
                  >
                    <MenuItem hidden value=""></MenuItem>
                    {issuers &&
                      [...new Set(issuers)]
                        .sort((a, b) => a.localeCompare(b, "az"))
                        .map((item, index) => (
                          <MenuItem key={`${item} ${index}`} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                    <Button
                      sx={{
                        mt: 3,
                        ml: 3,
                      }}
                      variant="outlined"
                      onClick={() => {
                        setOpenAddDialog(true);
                        setSelectedType([
                          "identityCardissuerList",
                          { name: "Sənədi verən orqanlar" },
                        ]);
                      }}
                    >
                      Yeni sənəd verən orqan əlavə et
                    </Button>
                  </Field>
                  {errors.driverLicense?.licenseIssuer && (
                    <FormHelperText error>
                      {errors.driverLicense.licenseIssuer}
                    </FormHelperText>
                  )}
                </FormControl>
              </LabelContainer>
              {renderDateField({
                name: "licenseIssuanceDate",
                label: "Vəsiqənin verilmə tarixi *",
                maxDate: endDate ? endDate : today,
                minDate: new Date(1940, 0, 1),
              })}
              {renderDateField({
                name: "licenseExpirationDate",
                label: "Vəsiqənən etibarlıq müddəti *",
              })}
              {renderTextField({
                name: "licenseNumber",
                label: "Vəsiqənin seriya nömrəsi *",
              })}
              {renderMultipleSelectField({
                name: "licenseCategory",
                label: "Kateqoriya",
                options: licenseTypes,
              })}
            </ChildContainer>
            {isHas && (
              <Button
                variant="contained"
                onClick={() => {
                  setIsHas(false);
                  resetFormFields();
                }}
                color="error"
              >
                Sürücülük vəsiqəsini sil
              </Button>
            )}
          </Grid>
        </>
      )}
    </>
  );
}
