import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Button,
  ButtonGroup,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { getAllStructures } from "../../api/api";
import StructureDetailsTable from "../../components/operations/structureDetails/DetailsForm";
import { getStructureById } from "../../services/structure/StructureServices";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function DataTable() {
  const [selectedButton, setSelectedButton] = useState(0);
  const [companyData, setCompanyData] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [tableHeader, setTableHeader] = useState({
    structure: " - Təşkilat",
    description: "məlumatları",
  });
  const [submitted, setSubmitted] = useState(false);

  // Get company data
  const getCompanyDetails = async (id) => {
    const companyResponse = await getStructureById(id);
    if (companyResponse) {
      setCompanyData(companyResponse);
    }
  };

  //Get Structure Naming
  useEffect(() => {
    const getStructureNames = async () => {
      try {
        const response = await getAllStructures();
        if (response && response.data) {
          setDataTable(response.data.structures);
          setSelectedType(response.data.structures[0]);
        }

        getCompanyDetails(response.data.structures[0]._id);
      } catch (err) {
        console.log(err);
      }
    };
    getStructureNames();
  }, [submitted]);

  //   Selected structure part
  useEffect(() => {
    const selectedItem = dataTable.find((item, index) => {
      if (index === selectedButton) {
        return item;
      }
    });

    if (selectedItem) {
      try {
        getCompanyDetails(selectedItem?._id);
      } catch (err) {
        console.log(err);
      }
      setSelectedType(selectedItem);
    }
  }, [submitted, selectedButton]);

  const handleChange = (e, index) => {
    setSelectedButton(index);
  };

  if (!companyData) {
    return "Göstəriləcək şirkət yoxdur";
  }
  return (
    <>
      {/* Naming button group */}
      <Card mb={6}>
        <ButtonGroup aria-label="outlined primary button group" fullWidth>
          <Grid container>
            {dataTable.map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={3}>
                <Button
                  disabled={disableButton}
                  onClick={(e) => handleChange(e, index)}
                  variant={selectedButton === index ? "contained" : "text"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "40px",
                  }}
                >
                  {item?.organization?.name}
                </Button>
              </Grid>
            ))}
          </Grid>
        </ButtonGroup>
      </Card>
      {/* {disableButton ? (
        <p className="disableText">
          Dəyişiklik zamanı təşkilatı dəyişdirmək mümkün deyil!
        </p>
      ) : null} */}
      {/* Data table */}
      <Card>
        <Grid px={5}>
          {selectedType ? (
            <StructureDetailsTable
              submitted={submitted}
              companyData={companyData}
              tableHeader={tableHeader}
              selectedType={selectedType}
              setSubmitted={setSubmitted}
              setDisableButton={setDisableButton}
              setSelectedButton={setSelectedButton}
            />
          ) : (
            <>Loading...</>
          )}
        </Grid>
      </Card>
    </>
  );
}

function StructureDetails() {
  return (
    <React.Fragment>
      <ToastContainer />

      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Təşkilatın məlumatları
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <DataTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default StructureDetails;
