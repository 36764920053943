// DataTableReducer.js
export const initialState = {
  selectedType: [
    "documentTypes",
    {
      name: "Sənədlərin siyahısı",
      values: [],
    },
  ],
  selectedDropdown: [[], []],
  dataTable: [],
  rowName: "",
  submitted: false,
  selectedRow: "",
};

export const dataTableReducer = (state, action) => {
  switch (action.type) {
    case "SET_SELECTED_ROW":
      return {
        ...state,
        selectedRow: action.payload,
      };
    case "SET_SELECTED_TYPE":
      return {
        ...state,
        selectedType: action.payload,
      };
    case "SET_SELECTED_DROPDOWN":
      return {
        ...state,
        selectedDropdown: action.payload,
      };
    case "SET_DATA_TABLE":
      return {
        ...state,
        dataTable: action.payload,
      };
    case "SET_ROW_NAME":
      return {
        ...state,
        rowName: action.payload,
      };
    case "SET_SUBMITTED":
      return {
        ...state,
        submitted: action.payload,
      };
    default:
      return state;
  }
};
