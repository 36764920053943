import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const AddAttachment = ({
  openAddAttachment,
  setOpenAddAttachment,
  setIsSubmitting,
  handleAddAttachment,
}) => {
  const [attachmentName, setAttachmentName] = useState("");
  const [selectedAttachment, setSelectedAttachment] = useState();

  const handleFileInputChange = (event) => {
    setIsSubmitting(true);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        setSelectedAttachment(base64String);
      };
      reader.readAsDataURL(file);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Dialog
        fullWidth
        open={openAddAttachment}
        onClose={() => {
          setOpenAddAttachment(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4">Təhkimlər</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Adı"
            type="text"
            onChange={(e) => setAttachmentName(e.target.value)}
            fullWidth
          />
          <Box>
            <Button
              fullWidth
              variant={selectedAttachment ? "contained" : "outlined"}
              component="label"
            >
              {selectedAttachment ? "Uğurla Yükləndi" : "Təhkimi yükləyin"}
              <input
                type="file"
                onChange={handleFileInputChange}
                fullWidth
                hidden
              />
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenAddAttachment(false);
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          <Button
            onClick={() => {
              setOpenAddAttachment(false);
              handleAddAttachment(attachmentName, selectedAttachment);
            }}
            color="primary"
            disabled={attachmentName.trim() === "" || !selectedAttachment}
          >
            Əlavə et
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddAttachment;
