import React from "react";
import styled from "styled-components";
import FilterItem from "./filterItem/FilterItem";

const Container = styled.div`
  height: max-content;
  width: 100%;
  transition: height 0.5s;
`;

const Body = ({
  filters,
  columns,
  onChange,
  onDelete,
  quickFilters,
  resetRow,
  applyHandler,
}) => {
  return (
    <Container>
      {filters?.map((item, index) => {
        return (
          <FilterItem
            key={item.id}
            filter={item}
            index={index}
            columns={columns}
            onChange={onChange}
            onDelete={onDelete}
            resetRow={resetRow}
            filters={filters}
            applyHandler={applyHandler}
          />
        );
      })}
      {quickFilters?.length > 0 && (
        <div style={{ textAlign: "center" }}>
          Sütunlarda tətbiq olunmuş filterlər
        </div>
      )}
      {quickFilters?.map((item) => {
        if (item === "") return;
        return (
          <FilterItem
            key={item.id}
            filter={item}
            // index={index}
            columns={columns}
            disabled={true}
            disableEditRow={true}
            // onChange={onChange}
            // onDelete={onDelete}
          />
        );
      })}
    </Container>
  );
};

export default Body;
