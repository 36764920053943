/* eslint-disable prettier/prettier */
import { makeStyles } from "@material-ui/core/styles";
import { DoneAll } from "@mui/icons-material";
import { Button, Dialog, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../components/layouts/button/Button";
import Chips from "../../../components/layouts/chips/Chips";
import { setCheckedItems } from "../../../redux/features/slices/structure.slice";
import warningApproveRequestSVG from "../../../vendor/warning-approve-request.svg";

const useStyles = makeStyles(() => ({
  generalStyles: {
    borderRadius: "5px",
  },
  listOfSelectedItemsCard: {
    marginTop: "30px",
    backgroundColor: "rgba(227, 225, 225, 0.27)",
    borderRadius: "7px",
    padding: "10px",
    maxHeight: "280px",
    overflowY: "scroll",
  },
  moreCommentCard: {
    marginTop: "10px",
    backgroundColor: "rgba(255, 255, 255, 0.50)",
    padding: "20px 25px",
  },
  moreCommentTextarea: {
    outline: "none",
    backgroundColor: "#D9D9D9",
    padding: "10px",
  },
  approveRequestModal: {
    borderRadius: "7px",
    backgroundColor: "#FFF",
    padding: "25px 10px",
  },
}));

const ApproveRequestModal = ({ open, handleClose, onComplete }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} style={{ minWidth: "823px" }}>
      <div className={classes.approveRequestModal}>
        {/* Dialog content here */}
        <div>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <img src={warningApproveRequestSVG} alt="Blink BI MMC" />
          </div>
          <Typography
            variant={"h4"}
            textTransform={"uppercase"}
            color={"black"}
            textAlign={"center"}
          >
            DİQQƏT!
          </Typography>
          <div className="text-333 text-center px-4 mt-4">
            Etdiyiniz bütün dəyişikliklər loqlanır və nəzarət altındadır.
            Edəcəyiniz dəyişiklikləri göndərdiyiniz və təsdiq olunmuş dəyişiklik
            sorğusuna uyğun edin.
          </div>
        </div>
        <div className="text-end mt-5">
          <CustomButton
            bgColor={"#1565C0 !important"}
            hoverBgColor={"#3486e4 !important"}
            Icon={DoneAll}
            content={"Aydındır"}
            onClick={(payload) => {
              handleClose();
              onComplete(payload);
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};

const ApproveRequestForm = ({ selectedItems, handleRequestApprove, setIsCompletedChosen }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { structureById } = useSelector((state) => state.structure);
  const [isOpen, setIsOpen] = useState(false);
  const [payloadData, setPayloadData] = useState({
    structureId: structureById?._id,
    relationIds: selectedItems.map((checkedItems) => checkedItems.id),
    message: []
  });

  const onClose = () => {
    setIsOpen(false);
  };
  const handleDelete = (id) => {
    const deletableChipsForId = selectedItems.filter(
      (items) => items.id !== id
    );
    dispatch(setCheckedItems(deletableChipsForId));
    setPayloadData({
      ...payloadData,
      relationIds: deletableChipsForId.map((checkedItems) => checkedItems.id),
    });
    setIsCompletedChosen(false);
  };

  const handleChangeComment = (value, field, id) => {
    let message_arr = [...payloadData.message];
    let deep_copy = JSON.parse(JSON.stringify(message_arr));

    if (message_arr.some(m => m?.key === field)) {
      deep_copy.find(c => c?.id === id).value = value;
    } else {
      deep_copy.push({ key: field, value, id });
    }
    setPayloadData({
      ...payloadData,
      message: deep_copy,
    });
  };

  const chipStyles = {
    borderRadius: "15px",
    background: "rgba(57, 134, 144, 0.43)",
    margin: "5px",
  };
  
  return (
    <>
      <ApproveRequestModal
        open={isOpen}
        handleClose={onClose}
        onComplete={() => handleRequestApprove(payloadData)}
      />
      <Typography
        color={"white"}
        variant={"h3"}
        fontWeight={"bold"}
        textAlign={"center"}
      >
        Dəyişiklik Sorğusunun Yaradılması
      </Typography>
      <div className={classes.listOfSelectedItemsCard}>
        <Typography
          color={"white"}
          variant={"h5"}
          fontWeight={"bold"}
          textAlign={"center"}
        >
          Dəyişiklik olacaq təşkilati struktur vahidlərinin siyahısı
        </Typography>
        {selectedItems.length > 0 ? (
          <div className="mt-3">
            <Chips
              data={selectedItems}
              onDelete={handleDelete}
              styles={chipStyles}
            />
          </div>
        ) : (
          <Typography variant={"h4"} textAlign={"center"} className={"my-3"}>
            Seçilmiş təşkilati struktur vahidi mövcud deyildir. Təşkilati
            struktur vahidi seçdikdən sonra sorğu yara bilərsiniz.
          </Typography>
        )}
      </div>
      <div className={`${classes.moreCommentCard} ${classes.generalStyles}`}>
        <Typography variant={"h5"} color={"black"} className="mb-3">
          Dəyişiklik(lər)ə dair izahlı şərh:
        </Typography>
        {
          selectedItems?.length > 0 && selectedItems?.map(v => (
            <>
              <Typography variant={"h6"} color={"black"} className="mb-1">
                {v?.name}
              </Typography>
              <textarea
                rows={7}
                placeholder={"Şərhinizi yazın..."}
                className={`w-100 ${classes.moreCommentTextarea} ${classes.generalStyles}`}
                onChange={(e) => handleChangeComment(e.target.value, v?.name, v?.id)}
              ></textarea>
            </>
          ))
        }
      </div>
      <div className="text-end mt-4">
        <Button
          variant="outlined"
          className="me-3 mb-3"
          onClick={() => setIsCompletedChosen(false)}
        >Geri</Button>
        <CustomButton
          bgColor={"#3D008B !important"}
          hoverBgColor={"#6313ca !important"}
          Icon={DoneAll}
          content={"Sorğunu Göndər"}
          disabled={!selectedItems.length || (!payloadData?.message?.length || payloadData?.message?.some(m => m?.value === ""))}
          onClick={() => setIsOpen(true)}
        />
      </div>
    </>
  );
};

export default ApproveRequestForm;
