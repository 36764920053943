import { CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Circle from "../layouts/circleDetail/Circle";
import { hexToRgba } from "../../utils/helperFunctions";

export default function StatisticDiagramSquare({
  headerText,
  data,
  height,
  colors,
  titles,
  keys,
  loading,
}) {
  return (
    <Grid sx={[styles.container, { height: height }]}>
      <Grid key="header-container">
        <Typography variant="h3" width={"100%"} textAlign={"center"}>
          {headerText}
        </Typography>
      </Grid>
      <Grid sx={styles.dataContainer}>
        {loading ? (
          <Grid sx={styles.loadingContainer}>
            <CircularProgress color="inherit" />
          </Grid>
        ) : (
          keys?.map((item, index) => {
            return (
              <Grid
                key={index}
                sx={[
                  styles.columnContainer,
                  {
                    width: `${100 / (data?.length > 0 ? data?.length : 1)}%`,
                    height: "60%",
                  },
                ]}
              >
                <Grid
                  sx={[
                    styles.columnHeaderCont,
                    {
                      backgroundColor: colors[item],
                    },
                  ]}
                >
                  <Typography
                    textAlign={"center"}
                    noWrap
                    sx={styles.columnHeaderText}
                    fontSize={17}
                  >
                    {titles[item]}
                  </Typography>
                </Grid>

                <Grid
                  sx={[
                    styles.valueContainer,
                    {
                      backgroundColor: colors[item],
                    },
                  ]}
                >
                  <Typography fontSize={20}>{data[item]}</Typography>
                </Grid>
              </Grid>
            );
          })
        )}
      </Grid>
    </Grid>
  );
}

const styles = {
  container: {
    backgroundColor: "rgba(255,255,255, 0.1)",
    padding: "10px",
    borderRadius: "2px",
  },
  dataContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: "10px",
    height: "77%",
  },
  columnContainer: {
    marginX: "3px",
  },
  columnHeaderCont: {
    padding: "2px",
    borderRadius: "2px",
  },
  columnHeaderText: {
    whiteSpace: "pre-line",
    wordWrap: "break-word",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 2, // Number of lines to show
    overflow: "hidden",
  },
  valueContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    marginTop: "5px",
    borderRadius: "2px",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

// Media query for smaller screens (adjust as needed)
const mediaQuery = `@media (max-width: 600px) {
  .MuiTypography-h3 {
    font-size: 1.5rem;
  }
}`;

// Inject the media query into the styles
const responsiveStyles = {
  ...styles,
  container: { ...styles.container, ...mediaQuery },
};
