import { STAFFS_STATUS_TYPE } from "../../constants";

const title = "Təyinatların izlənməsi";

export const headerData = {
  title: title,
  variant: "h3",
};

export const statisticHeaders = [
  {
    key: STAFFS_STATUS_TYPE.PENDING_APPLICATION,
    label: "Ərizəsi təsdiq gözləyən təyinatların sayı",
  },
  {
    key: STAFFS_STATUS_TYPE.PENDING_CONTRACT,
    label: "Müqavilə yaradılması gözləyən təyinatların sayı",
  },
  {
    key: STAFFS_STATUS_TYPE.PENDING_CONTRACT_APPROVE,
    label: "Müqaviləsi imza gözləyən təyinatların sayı",
  },
  {
    key: STAFFS_STATUS_TYPE.PENDING_ORDER,
    label: "Əmr yaradılmasını gözləyən təyinatların sayı",
  },
  {
    key: STAFFS_STATUS_TYPE.PENDING_ORDER_APPROVE,
    label: "Əmri təsdiq gözləyən təyinatların sayı",
  },
];
