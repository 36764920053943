import React from "react";
import { styled } from "styled-components";
import Body from "./body/Body";
import Toolbar from "./toolbar/Toolbar";
import Footer from "./footer/Footer";

const Container = styled.div`
  height: ${({ $height }) => $height};
  width: 700px;
  overflow: visible;
`;

const ToolbarContainer = styled.div`
  height: ${({ $height }) => $height};
  width: 100%;
`;
const BodyContainer = styled.div`
  height: ${({ $height }) => $height};
`;
const FooterContainer = styled.div`
  height: ${({ $height }) => $height};
`;

const Dropdown = ({
  columns,
  filters,
  hideParts = {
    toolbar: true,
    body: true,
    footer: true,
  },
  heightOfParts = {
    toolbar: "15%",
    body: "70%",
    footer: "15%",
  },
  heightContainer = "max-content",
  addBtnHandler = (e) => {},
  resetHandler = (e) => {},
  cancelHandler = (e) => {},
  applyHandler = (e) => {},
  onChange,
  onDelete,
  isApplied,
  quickFilters,
  resetRow,
}) => {
  return (
    <Container $height={heightContainer}>
      {hideParts.toolbar && (
        <ToolbarContainer $height={heightOfParts.toolbar}>
          <Toolbar addBtnHandler={addBtnHandler} resetHandler={resetHandler} />
        </ToolbarContainer>
      )}
      {hideParts.body && (
        <BodyContainer $height={heightOfParts.body}>
          <Body
            filters={filters}
            columns={columns}
            onChange={onChange}
            onDelete={onDelete}
            quickFilters={quickFilters}
            resetRow={resetRow}
            applyHandler={applyHandler}
          />
        </BodyContainer>
      )}
      {hideParts.footer && (
        <FooterContainer $height={heightOfParts.footer}>
          <Footer
            isApplied={isApplied}
            cancelHandler={cancelHandler}
            applyHandler={applyHandler}
            resetHandler={resetHandler}
          />
        </FooterContainer>
      )}
    </Container>
  );
};

export default Dropdown;
