import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ReplayCircleFilledIcon from "@mui/icons-material/ReplayCircleFilled";
import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import React from "react";
import VacationRequestForm from "./VacationRequestForm.js/VacationRequestForm";

import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../constants";

const VacationDialogs = (props) => {
  const BaseUrl = BASE_URL;
  const navigate = useNavigate();
  const {
    handleVacationDialog,
    setHandleVacationDialog,
    dialogTitle,
    dialogColor,
    dialogContent,
    requestType,
    children,
    approver,
    forwardedPerson,
    handleEmployeeRequest,
  } = props;
  const handleClose = () => {
    setHandleVacationDialog(false);
  };

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={handleVacationDialog}
      onClose={handleClose}
      // PaperProps={{
      //   style: {
      //     backgroundColor: "black",
      //     boxShadow: "none",
      //   },
      // }}
    >
      <DialogTitle
        variant="h3"
        sx={{
          display: "flex",
          justifyContent: "center",
          pb: 10,
          backgroundColor: dialogColor,
        }}
      >
        {dialogTitle}
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          gap: 10,
          backgroundColor: dialogColor,
        }}
      >
        <Grid sx={{ width: "150%" }}>
          <TextField
            sx={{ mb: 5 }}
            variant="standard"
            label="Sorğunu Yaradan"
            value={
              dialogContent?.creator?.firstname +
              " " +
              dialogContent?.creator?.lastname
            }
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            sx={{ mb: 5 }}
            variant="standard"
            label="Struktur (Departament)"
            value={
              dialogContent?.decisionChain?.topCompanyPartRel?.source?.name
            }
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            sx={{ mb: 5 }}
            variant="standard"
            label="Vəzifə"
            value={dialogContent.creator.position?.name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
          {dialogContent.approvementChain.map((item, index) => {
            return (
              <Grid key={index}>
                <TextField
                  sx={{ mb: 5 }}
                  variant="standard"
                  label={`Təsdiq edən ${item.step}`}
                  value={item.employee.firstname + " " + item.employee.lastname}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  sx={{ mb: 5 }}
                  variant="standard"
                  label={`Təsdiq edən vəzifə ${item.step}`}
                  value={item.employee.position.name}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            );
          })}

          {requestType === "Məzuniyyət Sorğusu" ? (
            <VacationRequestForm dialogContent={dialogContent} />
          ) : null}
        </Grid>
        <Grid sx={{ width: "100%" }}>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            Sorğu NO: {dialogContent.requestNO}
          </Grid>

          <Grid sx={{ position: "relative" }}>
            <Box
              component="img"
              sx={{
                height: 650,
                width: 500,
                borderRadius: 2,
              }}
              alt="The house from the offer."
              src="/static/img/vacations/docImage.png"
            />
            <a
              href={`${BaseUrl}employee/contract/${dialogContent?.contract?._id}/download`}
              target="_blank"
              rel="noreferrer"
            >
              <Button
                sx={{
                  position: "absolute",
                  bottom: "10px",
                  left: "50%",
                  transform: "translate(-50%)",
                }}
                size="large"
                variant="contained"
                startIcon={<CloudDownloadIcon />}
              >
                Sənədi Yüklə
              </Button>
            </a>
          </Grid>

          <Grid>{children}</Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: dialogColor,
        }}
      >
        <Button onClick={handleClose} sx={{ color: "white" }}>
          Geri qayıt
        </Button>
        {dialogTitle === "İmtina edilmiş / düzəltməli olduğum sorğu" &&
        !approver ? (
          <Button
            onClick={() => {
              navigate(`/update-vacation/${dialogContent._id}`, {
                replace: true,
              });
            }}
            endIcon={<ReplayCircleFilledIcon />}
            variant="contained"
            color="error"
          >
            Düzəliş et və göndər
          </Button>
        ) : null}

        {forwardedPerson && (
          <Button
            onClick={() => {
              handleEmployeeRequest(dialogContent._id);
            }}
            endIcon={<SendIcon />}
            variant="contained"
            color="warning"
          >
            {forwardedPerson} yönləndir
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default VacationDialogs;
