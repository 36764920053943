import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import DocSVG from "../../../layouts/signing/dialog/docSVG/DocSVG";
import { CloudDownload } from "@mui/icons-material";
import { BASE_URL } from "../../../constants";

// CSS

const style = {
  width: "100%",
  color: "#fff",
  "border-bottom": "1px solid black",
  fontSize: "20px",
  "padding-left": "10px",
};

export default function ExplanationRequestsDialog({
  row,
  open,
  onClose,
  click,
}) {
  return (
    <>
      <Dialog maxWidth={"60%"} open={open} onClose={onClose}>
        <DialogTitle>İzahat Sorğusu</DialogTitle>
        <DialogContent
          sx={{
            width: "100%",
            display: "flex",
            columnGap: "10px",
            boxSizing: "border-box",
          }}
        >
          <Grid
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
            }}
          >
            <div style={style}>Sənəd no: {row?.docno} </div>
            <div style={style}>Ad: {row?.name}</div>
            <div style={style}>Soyad: {row?.surname}</div>
            <div style={style}>Ata adı: {row?.fathername}</div>
            <div style={style}>Məzmun: {row?.content} </div>
            <div style={style}>Tarix: {row?.date}</div>
            <div style={style}>Adresat: {row?.adresat}</div>
          </Grid>
          <Grid
            sx={{
              width: "50%",
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <DocSVG style={{ width: "100%", height: "100%" }} />
            <IconButton
              sx={{
                backgroundColor: "#203d6b",
                borderRadius: "0",
                "&:hover": {
                  backgroundColor: "#407ad6",
                },
              }}
              href={`${BASE_URL}/employee/contract/${""}/download`}
              target="_blank"
            >
              <CloudDownload sx={{ fontSize: 32, marginRight: 2 }} />
              <Typography>Sənədi yüklə</Typography>
            </IconButton>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={click} color="primary">
            Bağla
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
