export const banks = [
  { name: "Azərbaycan Beynəlxalq Bankı", voen: "9900001881", code: 805250 },
  { name: "Accessbank", voen: "1400057421", code: 505000 },
  { name: "AFB Bank", voen: "1301703781", code: 503217 },
  { name: "Azərbaycan Sənayə Bankı", voen: "9900007981", code: 509664 },
  { name: "Azər-Türk bankı", voen: "9900006111", code: 507699 },
  { name: "Bank Avrasiya", voen: "1700792251", code: 505129 },
  { name: "Bank of Baku", voen: "1700038881", code: 506924 },
  { name: "BTB Bank", voen: "1302164881", code: 501145 },
  { name: "Bank Respublika", voen: "9900001901", code: 505668 },
  { name: "Bank VTB (Azərbaycan)", voen: "1400117231", code: 506623 },
  { name: "Expressbank", voen: "1500031691", code: 505099 },
  { name: "Kapital Bank", voen: "9900003611", code: 200004 },
  { name: "Melli İran Bankı ( Bakı )", voen: "1300036291", code: 509761 },
  { name: "Muğanbank", voen: "1400122681", code: 506162 },
  { name: "Naxçıvanbank", voen: "0200432771", code: 505152 },
  { name: "YELO Bank", voen: "9900014901", code: 507064 },
  { name: "PAŞA Bank", voen: "1700767721", code: 505141 },
  { name: "Rabitəbank", voen: "9900001061", code: 506399 },
  { name: "Turanbank", voen: "1300016391", code: 507462 },
  { name: "Unibank", voen: "1300017201", code: 505754 },
  { name: "Xalq Bank", voen: "2000296061", code: 505055 },
  { name: "Yapı Kredi Bank Azərbaycan", voen: "9900009021", code: 509987 },
  { name: "Ziraat Bank Azərbaycan", voen: "1303953611", code: 512372 },
].sort((a, b) => a.name.localeCompare(b.name));

export const workDayModes = [5, 6];
export const workHourModes = [40, 36, 24];

export const RelationshipInfoEnum = {
  father: "father",
  mother: "mother",
  brother: "brother",
  sister: "sister",
  grandmother: "grandmother",
  grandfather: "grandfather",
  husband: "husband",
  wife: "wife",
  "father-in-law": "father-in-law",
  "mother-in-law": "mother-in-law",
  child: "child",
  grandchild: "grandchild",
};
