class FilterModel {
  constructor({ column = "", operator = "", value = "", subColumn = "" }) {
    this.id = Math.random().toString(36).substr(2) + Date.now().toString(36);
    this.column = column;
    this.operator = operator;
    this.value = value;
    this.subColumn = subColumn;
  }
}

export default FilterModel;
