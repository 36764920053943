import { Person, RestartAlt } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Breadcrumb from "../../components/layouts/breadcrumb/Breadcrumb";
import Header from "../../components/layouts/header/Header";
import OperatorGenerator from "../../components/table/OperatorGenerator";
import StatusGenerator from "../../components/table/StatusGenerator";
import Table from "../../components/table/Table";
import SelectInput from "../../components/ui/Select/SelectInput";
import {
  BASE_URL,
  OPERATORS_CONTAINING,
  STAFFS_STATUS_TYPE,
  STAFF_DROPDOWN_VALUE,
} from "../../constants";
import Column from "../../models/table/Column";
import Operator from "../../models/table/Operator";
import { getStaffTracking } from "../../services/staffTracking/StaffTrackingServices";
import { GENERAL_BLOCK } from "../../styled";
import {
  getAllStructuresForFilter,
  getPartsForFilter,
  getPositionsForFilter,
  getTopPartsForFilter,
} from "../../utils/forFilterDropdown/getStructureUtils";
import { getDescendantProp } from "../../utils/helperFunctions";
const title = "İş vaxtının uçot tabeli";

const Container = styled.div``;

// const PageContentContainer = styled.div`
//   background-color: rgba(255, 255, 255, 0.1);
//   height: auto;
//   border-radius: 5px;
// `;

const HeaderContainer = styled.div`
  margin-bottom: 10px;
`;

const SelectDateContainer = styled.div`
  display: flex;
  justify-content: end;
  position: sticky;
  top: 57px;
  background-color: #1b2635;
  padding: 10px 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  // width: max-content;
  z-index: 9999999;
`;

const CalendarContainer = styled.div`
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  margin-bottom: 15px;
  margin-top: 15px;
`;

const TableContainer = styled.div``;

// static datas

const months = [
  {
    name: "Yanvar",
    key: 0,
  },
  {
    name: "Fevral",
    key: 1,
  },
  {
    name: "Mart",
    key: 2,
  },
  {
    name: "Aprel",
    key: 3,
  },
  {
    name: "May",
    key: 4,
  },
  {
    name: "İyun",
    key: 5,
  },
  {
    name: "İyul",
    key: 6,
  },
  {
    name: "Avqust",
    key: 7,
  },
  {
    name: "Sentyabr",
    key: 8,
  },
  {
    name: "Oktyabr",
    key: 9,
  },
  {
    name: "Noyabr",
    key: 10,
  },
  {
    name: "Dekabr",
    key: 11,
  },
];

const WorkHourAccountPage = () => {
  const [currYear, setCurrYear] = useState(new Date().getFullYear());
  const [currMonth, setCurrMonth] = useState(new Date().getMonth());
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(15);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const years = Array.from({ length: 27 }, (_, index) => 2024 + index);
  const [filters, setFilters] = useState([]);
  const [_days, setDays] = useState([]);

  useEffect(() => {
    const currMonth = new Date().getMonth();
    const currYear = new Date().getFullYear();
    setCurrMonth(currMonth);
    setCurrYear(currYear);
  }, []);

  useEffect(() => {
    // Step 2: Get first and last day of the month
    const firstDayOfMonth = new Date(currYear, currMonth, 1);
    const lastDayOfMonth = new Date(currYear, currMonth + 1, 0);

    // Step 3: Get day of the week for the first and last day
    // const firstDayOfWeek = firstDayOfMonth.getDay();
    // const lastDayOfWeek = lastDayOfMonth.getDay();

    // Step 4: Determine the number of days in the previous month
    // const daysInPreviousMonth = new Date(currYear, currMonth, 0).getDate();

    // Step 5: Render the calendar
    const days = [];

    // Render last days of previous month
    // for (let i = firstDayOfWeek - 2; i >= 0; i--) {
    //   days.push({
    //     day: daysInPreviousMonth - i,
    //     isCurrentMonth: false,
    //   });
    // }

    // Render days of current month
    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      days.push({
        day: i,
        isCurrentMonth: true,
      });
    }

    // Render first days of next month
    // const daysInNextMonth = 35 - days.length;
    // for (let i = 1; i <= daysInNextMonth; i++) {
    //   days.push({
    //     day: i,
    //     isCurrentMonth: false,
    //   });
    // }
    setDays(days);
  }, [currMonth, currYear]);

  const columns = [
    new Column({
      headerName: "Operator",
      path: "#",
      // isStaticColumn: false,
      disableColSearch: false,
      minWidth: "10px",
      renderCell: (row, column) => {
        const operators = [
          new Operator({
            icon: <RestartAlt />,
            onClick: () => {},
          }),
        ];
        return <OperatorGenerator operators={operators} />;
      },
    }),
    new Column({
      path: "baseStructure.organization.name",
      headerName: "Təşkilat",
      filterType: OPERATORS_CONTAINING.OPTION,
      fetchFilterValues: getAllStructuresForFilter,
      filterColumnKey: "baseStructure",
    }),
    new Column({
      path: "topPartOfStructure.source.name",
      headerName: "Struktur",
      filterType: OPERATORS_CONTAINING.OPTION,
      fetchFilterValues: getTopPartsForFilter,
      filterColumnKey: "topPartOfStructure",
    }),
    new Column({
      path: "subStructure.source.name",
      headerName: "Sub-struktur",
      isShow: true,
      filterType: OPERATORS_CONTAINING.OPTION,
      fetchFilterValues: getPartsForFilter,
      filterColumnKey: "subStructure",
    }),
    new Column({
      path: "position.source.name",
      headerName: "Vəzifə",
      filterType: OPERATORS_CONTAINING.OPTION,
      fetchFilterValues: getPositionsForFilter,
      filterColumnKey: "position",
    }),
    new Column({
      path: "originalRequest.staff.staffUnit",
      headerName: "Ştat",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: STAFF_DROPDOWN_VALUE,
    }),
    new Column({
      path: "originalRequest.employee.firstname",
      headerName: "Ad",
      filterType: OPERATORS_CONTAINING.TEXT,
      hasCellButton: true,
      cellButtonProps: {
        icon: <Person />,
        onClick: (value) =>
          window.open(
            `${BASE_URL.replace("/api", "")}/employees/${value}/view`
          ),
        value: (row) => {
          return getDescendantProp(row, "originalRequest.employee.id");
        },
      },
    }),
    new Column({
      path: "originalRequest.employee.lastname",
      headerName: "Soyad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "originalRequest.employee.fathername",
      headerName: "Ata adı",
      filterType: OPERATORS_CONTAINING.DATE,
      getValue: (row, column) => {
        return getDescendantProp(row, column.path);
      },
    }),
    new Column({
      path: "originalRequest.staff.status",
      headerName: "Status",
      filterType: OPERATORS_CONTAINING.SINGLE_OPTION,
      filterValues: [
        {
          key: STAFFS_STATUS_TYPE.PENDING_APPLICATION,
          name: "Ərizə təsdiq gözlənilir",
        },
        {
          key: STAFFS_STATUS_TYPE.PENDING_CONTRACT,
          name: "Müqavilə gözlənilir",
        },
        {
          key: STAFFS_STATUS_TYPE.PENDING_CONTRACT_APPROVE,
          name: "Müqavilə imza gözlənilir",
        },
        {
          key: STAFFS_STATUS_TYPE.PENDING_ORDER,
          name: "Əmr gözlənilir",
        },
        {
          key: STAFFS_STATUS_TYPE.PENDING_CONTRACT_APPROVE,
          name: "Əmr imza gözlənilir",
        },
        {
          key: STAFFS_STATUS_TYPE.REJECTED_APPLICATION,
          name: "Ərizə imtina edilib",
        },
        {
          key: STAFFS_STATUS_TYPE.REJECTED_CONTRACT_APPROVE,
          name: "Müqavilə imtina edilib",
        },
      ],
      renderCell: (row, column) => {
        return (
          <StatusGenerator
            value={getDescendantProp(row, column.path)}
            docType={"dissmisal"}
          />
        );
      },
    }),
  ];

  let arr = [];

  for (let i = 0; i < data.length; i++) {
    const schedules = data[i]?.schedules;
    for (let j = 1; j < schedules.length + 1; j++) {
      const currentJ = j; // Capture the current value of j
      arr[j] = new Column({
        path: "schedules",
        headerName: `${currentJ}`,
        disableColSearch: true,
        filterType: OPERATORS_CONTAINING.DATE,
        getValue: (row) => {
          return (
            <>
              <>
                <GENERAL_BLOCK className="mb-2">0</GENERAL_BLOCK>
              </>
            </>
          );
        },
      });
    }
  }

  arr.map((ar) => columns.push(ar));

  const handleYearChange = (value) => {
    setCurrYear(value);
  };

  const handleMonthChange = (value) => {
    const selectedMonth = months.find((month) => month.name === value);
    if (selectedMonth) {
      setCurrMonth(selectedMonth.key);
    }
  };

  const headerData = {
    title: title,
    variant: "h3",
  };

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };

  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };

  const paginationHandler = async () => {
    if (!filters.length > 0) {
      await fetchData();
      return;
    } else {
      const encodedFilter = encodeURIComponent(JSON.stringify(filters));
      await fetchData(encodedFilter);
    }
  };

  useEffect(() => {
    paginationHandler();
  }, [filters, limit, offset, _days]);

  const fetchData = async (filters = undefined) => {
    try {
      setIsLoading(true);
      //   setLoading(true);
      const payload = {
        offset: offset,
        limit: limit,
        filter: filters,
      };
      const { data } = await getStaffTracking(payload);
      //   const arr =
      // data && data.requests.map((item) => ({ ...item, id: item._id }));
      let arr = new Array(_days.length);
      for (let i = 1; i < arr.length; i++) {
        arr[i] = "8";
      }

      data.requests = data?.requests?.map((r) => ({
        ...r,
        schedules: arr,
      }));
      setData(data?.requests);
      setTotal(data?.total);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <React.Fragment>
      <Container>
        <HeaderContainer>
          <Header data={headerData} />
        </HeaderContainer>

        <SelectDateContainer>
          <GENERAL_BLOCK className="text-center" f_size="23px" wid="80%">
            {months[currMonth].name} {currYear}
          </GENERAL_BLOCK>
          <div className="d-flex">
            <SelectInput
              styleOuterContainer={{ width: "130px" }}
              label={"İl: "}
              value={currYear}
              onChange={handleYearChange}
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </SelectInput>

            <SelectInput
              styleOuterContainer={{ width: "130px" }}
              label={`Ay: `}
              value={months[currMonth].name}
              data={months}
              itemName={"name"}
              itemValue={"name"}
              onChange={handleMonthChange}
            >
              <option value={months[currMonth].key}>
                {months[currMonth].name}
              </option>
            </SelectInput>
          </div>
        </SelectDateContainer>

        {
          <Table
            columns={columns}
            data={data}
            totalRows={total}
            offset={offset}
            setOffset={(value) => setOffset(value)}
            limit={limit}
            setLimit={(value) => setLimit(value)}
            isLoading={isLoading}
            applyFiltersHandler={applyFiltersHandler}
            cancelAppliedFilters={cancelAppliedFiltersHandler}
          />
        }
      </Container>
    </React.Fragment>
  );
};

export default WorkHourAccountPage;
