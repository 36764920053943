import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  downloadContractRequestManager,
  getContracts,
  getFilteredContracts,
} from "../../../services/documentations/signingContractServices";
import { removeEmptyStrings } from "../../../utils/removeEmptyString";
import { downloadFile } from "../../../utils/downloadCSV";
import { Card, CircularProgress, Grid } from "@mui/material";
import CardTotals from "../../../layouts/vacations/cards/CardTotals";
import ReportingForm from "../../../components/filterForm/ReportingForm/ReportingForm";
import TotalCards from "../../../layouts/signing/cards/TotalCards";
import { useSelector } from "react-redux";

export const EmptySigningCard = () => {
  const navigate = useNavigate();
  const [totalRequests, setTotalRequests] = useState({
    approved: "",
    pending: "",
    rejected: "",
  });
  const [requests, setRequests] = useState();
  const [limit, setLimit] = useState(5);
  const { currentEmployee } = useSelector((state) => state.commonThings);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshPage, setRefreshPage] = useState();

  const refreshTriggerState = useSelector(
    (state) => state.commonThings.refreshTrigger
  );
  useEffect(() => {
    getContractsRequest();
  }, [refreshTriggerState, refreshPage]);

  const getContractsRequest = async () => {
    setIsLoading(true);
    try {
      const response = await getContracts(limit);
      if (response && response.data) {
        setTotalRequests({
          approved: response.data.approved.total,
          pending: response.data.pending.total,
          rejected: response.data.rejected.total,
        });
        setRequests(response.data);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const getFilteredData = async (value, status) => {
    const newOffset = (value - 1) * limit;

    try {
      const res = await getFilteredContracts(newOffset, limit, status);
      res &&
        res.data &&
        setRequests((prev) => {
          return { ...prev, [status]: res.data };
        });
    } catch (error) {
      console.log("docSign", error);
    }
  };

  // ReportingForm Titles,request status and reporting format
  const reportingTitle = "Hesabat formalaşdırılması";
  const requestStatus = [
    { name: "İmzalanmış sənədlər", value: "approved" },
    { name: "İmza gözləyən sənədlər", value: "pending" },
    { name: "İmtina edilmiş sənədlər", value: "rejected" },
  ];
  const reportingFormat = ["CSV"];
  const requestTypeForm = [
    { name: "Imzamalanmış sənəd sorğusu", value: "VacationRequest" },
  ];

  const handleReportDownload = async (values) => {
    const apiParams = {
      statuses: values.status.join(", "),
      creationDateFrom: values.creationDateFrom,
      creationDateTo: values.creationDateTo,
      structures: values.structures.join(", "),
    };
    const modifiedParams = removeEmptyStrings(apiParams);
    const response = await downloadContractRequestManager(modifiedParams);
    downloadFile(response);
  };

  return (
    <Grid>
      <Grid mb={7} sx={{ alignItems: "center", display: "flex", gap: 5 }}>
        <Grid>
          <CardTotals totalRequests={totalRequests} unshowRerequested={true} />
        </Grid>
        <Grid sx={{ width: "100%" }}>
          <Card sx={{ paddingX: 4, paddingY: 3 }}>
            <ReportingForm
              headerTitle={reportingTitle}
              requestStatus={requestStatus}
              requestType={requestTypeForm}
              reportingFormat={reportingFormat}
              // disableRequestType={true}
              // disableDecisionDate={true}
              // disableReportFormat={true}
              endpoint={handleReportDownload}
            />
          </Card>
        </Grid>
      </Grid>

      {isLoading && (
        <Grid sx={styles.loadingContainer}>
          <CircularProgress color="inherit" />
        </Grid>
      )}
      <Grid sx={{ display: isLoading && "none" }}>
        {requests && (
          <TotalCards
            refreshPage={() => {
              setRefreshPage(!refreshPage);
            }}
            totalRequests={totalRequests}
            requests={requests}
            handlePagination={getFilteredData}
          />
        )}
      </Grid>
    </Grid>
  );
};

const styles = {
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
