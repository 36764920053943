import React, { useEffect } from "react";
import {
  Button,
  Divider,
  Grid,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import { RemoveRedEye, Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";

import { useNavigate } from "react-router-dom";
import { useState } from "react";

// CSS
import style from "../../command/registerOfCommands/registerCommandsStyles.module.css";

//custom components
import Header from "../../../../components/layouts/header/Header";
import Breadcrumb from "../../../../components/layouts/breadcrumb/Breadcrumb";
import { getDocuments } from "../../../../services/documentations/ContractServices";

// .....................

export default function RegisterOfApplications() {
  const navigate = useNavigate();
  const [applications, setApplications] = useState([]);
  const [staticApplications, setStaticApplications] = useState([]);
  const [dynamicApplications, setDynamicApplications] = useState([]);
  const [filteredStaticApplications, setFilteredStaticApplications] = useState(
    []
  );
  const [filteredDynamicApplications, setFilteredDynamicApplications] =
    useState([]);

  useEffect(() => {
    const getDocs = async () => {
      try {
        const { data } = await getDocuments("application");
        setDynamicApplications(data.dynamic.records);
        setStaticApplications(data.static.records);
        setFilteredStaticApplications(data.static.records);
        setFilteredDynamicApplications(data.dynamic.records);
        setApplications(data?.records);
      } catch (error) {}
    };
    getDocs();
  }, []);

  const headerData = {
    title: "Ərizələrin reyestri",
    variant: "h3",
  };

  // search

  const search = (data) => {
    const newStatic = staticApplications.filter((item) => {
      return item.label.toLowerCase().startsWith(data.toLowerCase());
    });
    setFilteredStaticApplications(newStatic);
    const newDynamic = dynamicApplications.filter((item) => {
      return item.label.toLowerCase().startsWith(data.toLowerCase());
    });
    setFilteredDynamicApplications(newDynamic);
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Header data={headerData} />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              navigate("/documentation/application/register-of-applications")
            }
          >
            Ərizələrin reyestri
          </Button>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
      <TextField
        variant="outlined"
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        onChange={(event) => {
          search(event.target.value);
        }}
      />
      <Grid
        sx={{
          marginBottom: "30px",

          textAlign: "center",
        }}
      >
        <Header data={{ title: "Statik ərizə növləri", variant: "h4" }} />
      </Grid>
      {!filteredStaticApplications.length ? (
        <Grid
          sx={{
            width: "100%",
            fontSize: "30px",
            fontWeight: 600,
            textAlign: "center",
            marginTop: "20px",
            backgroundColor: "#233044",
            borderRadius: "10px",
          }}
        >
          Heç bir məlumat tapılmadı
        </Grid>
      ) : (
        <></>
      )}

      <Grid className={style.orderList}>
        {filteredStaticApplications?.map((item, index) => {
          return (
            <React.Fragment key={`${item} ${index}`}>
              <Grid className={style.list} sx={{ transition: "0.3s" }}>
                <Grid className={style.infoSide}>
                  <Tooltip title={item.info}>
                    <IconButton
                      sx={{
                        padding: 0,
                      }}
                    >
                      <div className={style.info}>i</div>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid className={style.listLeftSide}>
                  <Grid className={style.docType}>{item.label}</Grid>
                </Grid>

                <Grid className={style.listRightSide}>
                  <Grid className={style.statistics}>
                    <Grid>
                      <Grid>{item.statistics.total}</Grid>
                      <Grid className={style.statisticType}>Cəm</Grid>
                    </Grid>
                    <div style={{ border: "1px solid black" }}></div>
                    <Grid>
                      <Grid>{item.statistics.pending}</Grid>
                      <Grid className={style.statisticType}>Gözləyən</Grid>
                    </Grid>
                    <Grid>
                      <Grid>{item.statistics.approved}</Grid>
                      <Grid className={style.statisticType}>İmzalanmış</Grid>
                    </Grid>

                    <Grid>
                      <Grid>{item.statistics.rejected}</Grid>
                      <Grid className={style.statisticType}>
                        İmtina edilmiş
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className={style.buttons}>
                    <Tooltip title={"Ərizə yarat"} arrow>
                      {item.isContractCreatable ? (
                        <button
                          className={style.button}
                          onClick={() =>
                            navigate("/documentation/application/create")
                          }
                        >
                          <AddIcon />
                        </button>
                      ) : (
                        <></>
                      )}
                    </Tooltip>
                    {item.statistics.total ? (
                      <Tooltip title="Ərizələrə bax">
                        <button
                          onClick={() =>
                            navigate(
                              `/documentation/contract/viewtypeofcontracts?contractID=${item.id}&contractType=${item.type}`
                            )
                          }
                          className={style.button}
                        >
                          <RemoveRedEye />
                        </button>
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                  </div>
                </Grid>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
      <Grid
        sx={{ marginBottom: "30px", marginTop: "50px", textAlign: "center" }}
      >
        <Header data={{ title: "Dinamik ərizə növləri", variant: "h4" }} />
      </Grid>
      {!filteredDynamicApplications.length ? (
        <Grid
          sx={{
            width: "100%",
            fontSize: "30px",
            fontWeight: 600,
            textAlign: "center",
            marginTop: "20px",
            backgroundColor: "#233044",
            borderRadius: "10px",
          }}
        >
          Heç bir məlumat tapılmadı
        </Grid>
      ) : (
        <></>
      )}
      <Grid className={style.orderList}>
        {filteredDynamicApplications?.map((item, index) => {
          return (
            <React.Fragment key={`${item} ${index}`}>
              <Grid className={style.list} sx={{ transition: "0.3s" }}>
                <Grid className={style.infoSide}>
                  <Tooltip title={item.info}>
                    <IconButton
                      sx={{
                        padding: 0,
                      }}
                    >
                      <div className={style.info}>i</div>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid className={style.listLeftSide}>
                  <Grid className={style.docType}>{item.label}</Grid>
                </Grid>

                <Grid className={style.listRightSide}>
                  <Grid className={style.statistics}>
                    <Grid>
                      <Grid>{item.statistics.total}</Grid>
                      <Grid className={style.statisticType}>Cəm</Grid>
                    </Grid>
                    <div style={{ border: "1px solid black" }}></div>
                    <Grid>
                      <Grid>{item.statistics.pending}</Grid>
                      <Grid className={style.statisticType}>Gözləyən</Grid>
                    </Grid>
                    <Grid>
                      <Grid>{item.statistics.approved}</Grid>
                      <Grid className={style.statisticType}>İmzalanmış</Grid>
                    </Grid>

                    <Grid>
                      <Grid>{item.statistics.rejected}</Grid>
                      <Grid className={style.statisticType}>
                        İmtina edilmiş
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className={style.buttons}>
                    <Tooltip title={"Ərizə yarat"} arrow>
                      {item.isContractCreatable ? (
                        <button
                          className={style.button}
                          onClick={() =>
                            navigate("/documentation/application/create")
                          }
                        >
                          <AddIcon />
                        </button>
                      ) : (
                        <></>
                      )}
                    </Tooltip>
                    {item.statistics.total ? (
                      <Tooltip title="Ərizələrə bax">
                        <button
                          onClick={() =>
                            navigate(
                              `/documentation/contract/viewtypeofcontracts?contractID=${item.id}&contractType=${item.type}`
                            )
                          }
                          className={style.button}
                        >
                          <RemoveRedEye />
                        </button>
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                  </div>
                </Grid>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </>
  );
}
