import React, { useState } from "react";
import { CircularProgress, Grid, Pagination, Typography } from "@mui/material";
import CardItem from "./card/Card";

//icons
import ErrorIcon from "@mui/icons-material/ErrorRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbOn from "@mui/icons-material/DoNotDisturbOn";
import { SIGNING_CARD_TYPES } from "../../../constants";

const TotalCards = ({
  refreshPage,
  totalRequests,
  requests,
  handlePagination,
}) => {
  const [isLoading, setIsLoading] = useState({
    value: false,
    column: undefined,
  });

  const paginationHandler = async (value, type) => {
    setIsLoading({ value: true, column: type });
    await handlePagination(value, type);
    setIsLoading({ value: false, column: undefined });
  };

  const isLoadingCondition = (type) => {
    switch (type) {
      case "pending":
        return (
          isLoading.value && isLoading.column && isLoading.column === "pending"
        );
      case "approved":
        return (
          isLoading.value && isLoading.column && isLoading.column === "approved"
        );
      case "rejected":
        return (
          isLoading.value && isLoading.column && isLoading.column === "rejected"
        );
      default:
        break;
    }
  };

  return (
    <Grid sx={styles.container} container spacing={1}>
      <Grid sx={styles.sectionContainer} xs={12} sm={4}>
        <Grid sx={styles.headerContainer}>
          <Typography variant="h4">İmza gözləyən sənədlər</Typography>
          <ErrorIcon sx={{ fontSize: 32 }} color={"info"} />
        </Grid>
        <Grid sx={styles.paginationContainer}>
          <Pagination
            count={Math.ceil(totalRequests.pending / 5)}
            onChange={(e, value) => {
              paginationHandler(value, "pending");
            }}
          />
        </Grid>
        {isLoadingCondition("pending") && (
          <Grid sx={styles.loadingContainer}>
            <CircularProgress color="inherit" />
          </Grid>
        )}
        {!isLoadingCondition("pending") &&
          requests.pending?.records.map((item, index) => (
            <CardItem
              refreshPage={refreshPage}
              key={`${item} ${index}`}
              type={SIGNING_CARD_TYPES.PENDING}
              data={item}
            />
          ))}
      </Grid>
      <Grid sx={styles.sectionContainer} xs={12} sm={4}>
        <Grid sx={styles.headerContainer}>
          <Typography variant="h4">İmzalanmış sənədlər</Typography>
          <CheckCircleIcon sx={{ fontSize: 32 }} color={"success"} />
        </Grid>
        <Grid sx={styles.paginationContainer}>
          <Pagination
            count={Math.ceil(totalRequests.approved / 5)}
            onChange={(e, value) => paginationHandler(value, "approved")}
          />
        </Grid>
        {isLoadingCondition("approved") && (
          <Grid sx={styles.loadingContainer}>
            <CircularProgress color="inherit" />{" "}
          </Grid>
        )}
        {!isLoadingCondition("approved") &&
          requests.approved?.records.map((item, index) => (
            <CardItem
              key={`${item} ${index}`}
              data={item}
              type={SIGNING_CARD_TYPES.SIGNED}
            />
          ))}
      </Grid>
      <Grid sx={styles.sectionContainer} xs={12} sm={4}>
        <Grid sx={styles.headerContainer}>
          <Typography variant="h4">İmtina edilmiş sənədlər</Typography>
          <DoNotDisturbOn sx={{ fontSize: 32 }} color={"error"} />
        </Grid>
        <Grid sx={styles.paginationContainer}>
          <Pagination
            count={Math.ceil(totalRequests.rejected / 5)}
            onChange={(e, value) => paginationHandler(value, "rejected")}
          />
        </Grid>
        {isLoadingCondition("rejected") && (
          <Grid sx={styles.loadingContainer}>
            <CircularProgress color="inherit" />{" "}
          </Grid>
        )}
        {!isLoadingCondition("rejected") &&
          requests.rejected?.records.map((item, index) => (
            <CardItem
              key={`${index} ${item}`}
              type={SIGNING_CARD_TYPES.CANCELED}
              data={item}
            />
          ))}
      </Grid>
    </Grid>
  );
};

export default TotalCards;

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  sectionContainer: {
    width: "31%",
    backgroundColor: "rgba(255,255,255,0.05)",
    padding: 3,
    border: 1,
    borderColor: "rgba(255,255,255,0.02)",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: 0.9,
    marginBottom: 3,
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginBottom: "10px",
  },
  loadingContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
};
