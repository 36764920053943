import {
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Field, FieldArray, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getCommonData, updateEmployee } from "../../api/api";
import { checkUniqueness } from "../../utils/checkUniquenessForEmployee";
import { academicDegreeValidation } from "../employees/Validations";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomAccordion from "../ui/Accordion/Accordion";

const EditAcademicDegree = ({
  employeeById,
  submitted,
  setSubmitted,
  setOpenAddDialog,
  setSelectedType,
}) => {
  const [degreeName, setDegreeName] = useState([]);
  const [degreeIssuer, setDegreeIssuer] = useState([]);
  const [pastData, setPastData] = useState([]);
  const [isDate, setIsDate] = useState(false);

  // Handle Submit
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    const modifiedValues = {
      // ...values,
      academicDegrees:
        values?.academicDegrees &&
        values?.academicDegrees.map((item) => {
          const { value, ...rest } = item;
          const cleanedItem = { ...rest };

          if (cleanedItem.date) {
            cleanedItem.date = moment(cleanedItem.date).format("MM/DD/yyyy");
          }

          if (cleanedItem.note) {
            cleanedItem.note = cleanedItem.note.trim();
          }

          // Replace "?" key with "value"
          if (cleanedItem["?"]) {
            cleanedItem.value = cleanedItem["?"].value;
            delete cleanedItem["?"];
          }

          return cleanedItem;
        }),
    };

    await updateEmployee(employeeById._id, modifiedValues);
    setIsDate(false);
    setSubmitting(false);
    setSubmitted(!submitted);
    resetForm({ values });
  };

  useEffect(() => {
    const handleAcademicDegrees = async () => {
      try {
        const [degreeNameResponse, degreeIssuerResponse] = await Promise.all([
          getCommonData("academicDegrees"),
          getCommonData("academicDegreeIssuers"),
        ]);
        const degreeName = degreeNameResponse.data;
        const degreeIssuer = degreeIssuerResponse.data;
        setDegreeName(degreeName);
        setDegreeIssuer(degreeIssuer);
        const combinedData = [];
        if (degreeName.length) {
          combinedData.push({ degreeName: [...degreeName] });
        }

        if (degreeIssuer.length) {
          combinedData.push({ degreeIssuer: [...degreeIssuer] });
        }

        handlePastData(combinedData);
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };
    handleAcademicDegrees();
  }, [submitted]);

  const handleDegreeSelected = (item) => {
    const selectedStatus = degreeName.find((status) => {
      return status.name === item;
    });
    return selectedStatus;
  };
  function handlePastData(data) {
    setPastData(data);
  }
  function handlePush(index) {
    degreeName &&
      degreeName.push({
        name: employeeById?.academicDegrees[index]?.name,
        value: employeeById?.academicDegrees[index]?.value,
      });
    degreeIssuer &&
      degreeIssuer.push(employeeById?.academicDegrees[index]?.issuer);
  }
  function handleHidden(name, data, index) {
    return pastData.every((past) => {
      return !past[name]?.some((item) => {
        if (item.name) {
          return item.name === data;
        } else {
          return item === data;
        }
      });
    });
  }
  return (
    <CustomAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h6>Elmi dərəcələri</h6>
      </AccordionSummary>
      <AccordionDetails>
        {employeeById ? (
          <Formik
            initialValues={{
              academicDegrees: employeeById.academicDegrees || [], // Set the initial laborActivity values from employeeById
            }}
            validationSchema={academicDegreeValidation}
            onSubmit={handleSubmit}
          >
            {({
              values,
              isValid,
              errors,
              touched,
              isSubmitting,
              handleChange,
              setFieldValue,
              dirty,
            }) => (
              <>
                <Form>
                  <FieldArray name="academicDegrees">
                    {({ push, remove }) => (
                      <div>
                        {values?.academicDegrees &&
                          values?.academicDegrees.map((form, index) => (
                            <div key={index}>
                              <>
                                {handlePush(index)}
                                <Typography mt={3} mb={3} variant="h6">
                                  {index + 1})Elmi dərəcə
                                </Typography>

                                {/* academicDegree type and issuer */}
                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid sx={{ width: "100%" }}>
                                    <FormControl
                                      error={
                                        touched?.academicDegrees?.[index]
                                          ?.name &&
                                        errors?.academicDegrees?.[index]
                                          ?.name &&
                                        Boolean(
                                          errors?.academicDegrees?.[index]?.name
                                        )
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`academicDegrees[${index}].name`}
                                      >
                                        Elmi dərəcə
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        required
                                        name={`academicDegrees[${index}].name`}
                                        labelId={`academicDegrees[${index}].name`}
                                        label="Elmi dərəcə"
                                        id={`academicDegrees[${index}].name`}
                                        onChange={(event) => {
                                          checkUniqueness(
                                            values.academicDegrees,
                                            "name",
                                            event,
                                            setFieldValue,
                                            `academicDegrees[${index}]`,
                                            "elmi dərəcə",
                                            handleDegreeSelected
                                          );
                                          // setFieldValue(
                                          //   `academicDegrees[${index}].name`,
                                          //   e.target.value
                                          // );
                                          // setFieldValue(
                                          //   `academicDegrees[${index}].value`,
                                          //   handleDegreeSelected(e.target.value).value
                                          // );
                                        }}
                                        defaultValue=""
                                        fullWidth
                                        error={
                                          touched?.academicDegrees?.[index]
                                            ?.name &&
                                          errors?.academicDegrees?.[index]
                                            ?.name &&
                                          Boolean(
                                            errors?.academicDegrees?.[index]
                                              ?.name
                                          )
                                        }
                                      >
                                        {degreeName &&
                                          [
                                            ...degreeName.filter(
                                              (item, index, self) =>
                                                index ===
                                                self.findIndex(
                                                  (t) => t.name === item.name
                                                )
                                            ),
                                          ]
                                            .sort((a, b) =>
                                              (a?.name || "").localeCompare(
                                                b?.name || "",
                                                "az"
                                              )
                                            )
                                            .map((item, index) => (
                                              <MenuItem
                                                hidden={handleHidden(
                                                  "degreeName",
                                                  item.name,
                                                  index
                                                )}
                                                key={index}
                                                value={item.name}
                                              >
                                                {item.name}
                                              </MenuItem>
                                            ))}
                                        <Button
                                          sx={{
                                            mt: 3,
                                            ml: 3,
                                          }}
                                          variant="outlined"
                                          onClick={() => {
                                            setOpenAddDialog(true);
                                            setSelectedType([
                                              "academicDegrees",
                                              { name: "Elmi Dərəcələr" },
                                            ]);
                                          }}
                                        >
                                          Yeni elmi dərəcə əlavə et
                                        </Button>
                                      </Field>
                                      {touched?.academicDegrees?.[index]
                                        ?.name &&
                                        errors.academicDegrees?.[index]?.name &&
                                        errors.academicDegrees?.[index]
                                          ?.name && (
                                          <FormHelperText error>
                                            {
                                              errors.academicDegrees?.[index]
                                                ?.name
                                            }
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  </Grid>
                                  <Grid sx={{ width: "100%" }}>
                                    <FormControl
                                      error={
                                        touched?.academicDegrees?.[index]
                                          ?.issuer &&
                                        errors?.academicDegrees?.[index]
                                          ?.issuer &&
                                        Boolean(
                                          errors?.academicDegrees?.[index]
                                            ?.issuer
                                        )
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`academicDegrees[${index}].issuer`}
                                      >
                                        Elmi dərəcəni verən müəssisə
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`academicDegrees[${index}].issuer`}
                                        labelId={`academicDegrees[${index}].issuer`}
                                        label="Elmi dərəcəni verən müəssisə"
                                        id={`academicDegrees[${index}].issuer`}
                                        defaultValue=""
                                        fullWidth
                                        error={
                                          touched?.academicDegrees?.[index]
                                            ?.issuer &&
                                          errors?.academicDegrees?.[index]
                                            ?.issuer &&
                                          Boolean(
                                            errors?.academicDegrees?.[index]
                                              ?.issuer
                                          )
                                        }
                                      >
                                        {degreeIssuer &&
                                          [...new Set(degreeIssuer)]
                                            .sort((a, b) =>
                                              a.localeCompare(b, "az")
                                            )
                                            .map((item, index) => (
                                              <MenuItem
                                                hidden={handleHidden(
                                                  "degreeIssuer",
                                                  item
                                                )}
                                                key={index}
                                                value={item}
                                              >
                                                {item}
                                              </MenuItem>
                                            ))}
                                        <Button
                                          sx={{
                                            mt: 3,
                                          }}
                                          variant="outlined"
                                          onClick={() => {
                                            setOpenAddDialog(true);
                                            setSelectedType([
                                              "academicDegreeIssuers",
                                              {
                                                name: "Elmi Dərəcəni verən müəssisələr",
                                              },
                                            ]);
                                          }}
                                        >
                                          Yeni elmi dərəcəni verən müəssisə
                                          əlavə et
                                        </Button>
                                      </Field>
                                      {touched?.academicDegrees?.[index]
                                        ?.issuer &&
                                        errors.academicDegrees?.[index]
                                          ?.issuer &&
                                        errors.academicDegrees?.[index]
                                          ?.issuer && (
                                          <FormHelperText error>
                                            {
                                              errors.academicDegrees?.[index]
                                                ?.issuer
                                            }
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  </Grid>
                                </Grid>

                                {/*start date and end date */}
                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid
                                    onClick={() => setIsDate(true)}
                                    sx={{ width: "100%" }}
                                  >
                                    <Field
                                      name={`academicDegrees[${index}].date`}
                                    >
                                      {({ field, form }) => {
                                        return (
                                          <DatePicker
                                            {...field}
                                            label="Elmi dərəcəni aldığı tarix"
                                            inputFormat="dd/MM/yyyy"
                                            value={field.value || null}
                                            maxDate={new Date()}
                                            minDate={new Date(1940, 0, 1)}
                                            onChange={(date) => {
                                              form.setFieldValue(
                                                `academicDegrees[${index}].date`,
                                                date
                                              );
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                helperText={
                                                  isDate &&
                                                  form.errors
                                                    ?.academicDegrees?.[index]
                                                    ?.date
                                                }
                                                error={
                                                  isDate &&
                                                  Boolean(
                                                    form.errors
                                                      ?.academicDegrees?.[index]
                                                      ?.date
                                                  )
                                                }
                                                fullWidth
                                              />
                                            )}
                                          />
                                        );
                                      }}
                                    </Field>
                                  </Grid>
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      type="text"
                                      name={`academicDegrees[${index}].note`}
                                      as={TextField}
                                      label="Qeyd"
                                      fullWidth
                                      // error={
                                      //   errors?.academicDegrees?.[index]?.note &&
                                      //   Boolean(
                                      //     errors?.academicDegrees?.[index]?.note
                                      //   )
                                      // }
                                      // helperText={
                                      //   errors?.academicDegrees?.[index]?.note &&
                                      //   errors?.academicDegrees?.[index]?.note
                                      // }
                                    />
                                  </Grid>
                                </Grid>

                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      type="text"
                                      value={
                                        values?.academicDegrees[index]?.value
                                      }
                                      as={TextField}
                                      name={`academicDegrees[${index}].value`}
                                      label="Elmi dərəcənin dəyəri"
                                      fullWidth
                                      InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "19px",
                                            }}
                                          >
                                            &#8380;
                                          </span>
                                        ),
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </>

                              <Button
                                sx={{ mt: 3 }}
                                variant="contained"
                                color="error"
                                type="button"
                                onClick={() => {
                                  remove(index);
                                  setIsDate(false);
                                }}
                              >
                                Elmi dərəcəni sil
                              </Button>
                            </div>
                          ))}
                        <Button
                          sx={{ mt: 3 }}
                          variant="contained"
                          type="button"
                          onClick={() => {
                            push({ value: "", name: "" });
                            setIsDate(false);
                          }}
                        >
                          Elmi dərəcə əlavə et
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                  {/* Submit button */}
                  <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      Dəyişiklikləri yadda saxla
                    </Button>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        ) : (
          <div>Loading...</div>
        )}
      </AccordionDetails>
    </CustomAccordion>
  );
};

export default EditAcademicDegree;
