import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import axios from "axios";
import { patchChatPicture } from "../../api/api";

const ChatImageDialog = ({ state, dispatch }) => {
  const { chatId, openChatImage, submitted } = state;
  const [chatPicture, setChatPicture] = useState(null);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const handleFileUpload = async (event) => {
    setFile(event.target.files[0]);
  };
  async function handleClick() {
    if (file) {
      setLoading(true);

      try {
        const base64String = await getBase64String(file);
        setChatPicture(base64String);

        // Make API call to upload the image

        const response = await patchChatPicture(chatId, {
          base64: base64String,
        });

        setLoading(false);
        dispatch({
          type: "SET_SUBMITTED",
          payload: !submitted,
        });
      } catch (error) {
        setLoading(false);
        console.error("Error uploading image:", error);
      }
    }
  }

  const getBase64String = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <Dialog
        onClose={() => {
          dispatch({
            type: "SET_OPEN_CHAT_IMAGE",
            payload: false,
          });
        }}
        aria-labelledby="simple-dialog-title"
        open={openChatImage}
        fullWidth
      >
        <DialogTitle id="simple-dialog-title">Şəkli dəyişdirin</DialogTitle>
        <DialogContent>
          <Input
            name="chatImage"
            type="file"
            accept="image/*"
            onChange={handleFileUpload}
            required
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch({
                type: "SET_OPEN_CHAT_IMAGE",
                payload: false,
              });
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          <Button
            color="primary"
            onClick={() => {
              handleClick();
              dispatch({
                type: "SET_OPEN_CHAT_IMAGE",
                payload: false,
              });
            }}
          >
            Çat şəklini dəyişdir
          </Button>
        </DialogActions>
      </Dialog>

      {loading && (
        <Dialog open={true} fullWidth>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
              <Typography variant="body1" ml={2}>
                Uploading...
              </Typography>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default ChatImageDialog;
