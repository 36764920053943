import jwtDecode from "jwt-decode";
import { verify, sign } from "jsonwebtoken";
import axios from "./axios";
import { getAccessToken, getRefreshTokenStorage } from "./token";
import Cookies from "js-cookie";

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  if (!decoded) {
    return false;
  }

  return true;
};

const isValidRefreshToken = (refreshToken) => {
  if (!refreshToken) {
    return false;
  }
  const decoded = jwtDecode(refreshToken);
  if (!decoded) {
    return false;
  }

  return true;
};

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   (timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     ('expired');
//   }, timeLeft);
// };

const setSession = (accessToken, remember) => {
  if (accessToken) {
    if (remember) localStorage.setItem("accessToken", accessToken);
    else Cookies.set("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem("accessToken");
    Cookies.remove("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const setRefreshToken = (refreshToken, remember) => {
  if (refreshToken) {
    if (remember) localStorage.setItem("refreshToken", refreshToken);
    else Cookies.set("refreshToken", refreshToken);
    axios.defaults.headers.common.RefreshToken = `${refreshToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem("refreshToken");
    Cookies.remove("refreshToken");
    delete axios.defaults.headers.common.RefreshToken;
  }
};

const getSession = () => {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return false;
  } else {
    return accessToken;
  }
};

const getRefreshToken = () => {
  const refreshToken = getRefreshTokenStorage();
  if (!refreshToken) {
    return false;
  } else {
    return refreshToken;
  }
};

export {
  verify,
  sign,
  isValidToken,
  setSession,
  getSession,
  setRefreshToken,
  isValidRefreshToken,
  getRefreshToken,
};
