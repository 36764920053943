import axios from "axios";
import { BASE_URL } from "../constants";
import { getAccessToken, getRefreshTokenStorage } from "../utils/token";

export const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshTokenStorage();

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    if (refreshToken) {
      config.headers["RefreshToken"] = refreshToken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
