import React, { useEffect, useState } from "react";
import {
  Field,
  FieldArray,
  ErrorMessage,
  useFormikContext,
  Formik,
  Form,
} from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Button,
  Checkbox,
  FormHelperText,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { getCommonData } from "../../api/api";
import { isValid, parse } from "date-fns";

const ViewAcademicDegree = ({ employeeById }) => {
  const [degreeName, setDegreeName] = useState([]);
  const [degreeIssuer, setDegreeIssuer] = useState([]);

  useEffect(() => {
    const handleAcademicDegrees = async () => {
      const degreeNameResponse = await getCommonData("academicDegrees");
      const degreeIssuerResponse = await getCommonData("academicDegreeIssuers");
      setDegreeName(degreeNameResponse.data);
      setDegreeIssuer(degreeIssuerResponse.data);
    };
    handleAcademicDegrees();
  }, []);

  const initialValues = {
    academicDegrees: employeeById?.academicDegrees || [],
  };
  return (
    <>
      {employeeById ? (
        <Formik
          initialValues={{
            academicDegrees: initialValues.academicDegrees || [], // Set the initial laborActivity values from employeeById
          }}
        >
          {({
            values,
            isValid,
            errors,
            touched,
            isSubmitting,
            handleChange,
          }) => (
            <Form style={{ pointerEvents: "none" }}>
              <FieldArray name="academicDegrees">
                {({ push, remove }) => (
                  <div>
                    {values.academicDegrees.map((form, index) => (
                      <div key={index}>
                        <>
                          <Typography mt={3} mb={3} variant="h6">
                            {index + 1}Elmi dərəcə
                          </Typography>

                          {/* academicDegree type and issuer */}
                          <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                            <Grid sx={{ width: "100%" }}>
                              <FormControl
                                error={
                                  errors?.academicDegrees?.[index]?.name &&
                                  Boolean(
                                    errors?.academicDegrees?.[index]?.name
                                  )
                                }
                                fullWidth
                              >
                                <InputLabel
                                  id={`academicDegrees[${index}].name`}
                                >
                                  Elmi dərəcə
                                </InputLabel>
                                <Field
                                  as={Select}
                                  required
                                  name={`academicDegrees[${index}].name`}
                                  labelId={`academicDegrees[${index}].name`}
                                  label="Elmi dərəcə"
                                  id={`academicDegrees[${index}].name`}
                                  defaultValue=""
                                  fullWidth
                                >
                                  <MenuItem
                                    selected
                                    key={index}
                                    value={
                                      employeeById?.academicDegrees[index]?.name
                                    }
                                  >
                                    {employeeById?.academicDegrees[index]?.name}
                                  </MenuItem>
                                  {/* {degreeName.map((item, index) => (
                                    <MenuItem
                                      key={index}
                                      value={
                                        employeeById?.academicDegrees[index]
                                          ?.name
                                      }
                                    >
                                      {
                                        employeeById?.academicDegrees[index]
                                          ?.name
                                      }
                                    </MenuItem>
                                  ))} */}
                                </Field>
                              </FormControl>
                            </Grid>
                            <Grid sx={{ width: "100%" }}>
                              <FormControl
                                error={
                                  errors?.academicDegrees?.[index]?.issuer &&
                                  Boolean(
                                    errors?.academicDegrees?.[index]?.issuer
                                  )
                                }
                                fullWidth
                              >
                                <InputLabel
                                  id={`academicDegrees[${index}].issuer`}
                                >
                                  Elmi dərəcəni verən müəssisə
                                </InputLabel>
                                <Field
                                  as={Select}
                                  name={`academicDegrees[${index}].issuer`}
                                  labelId={`academicDegrees[${index}].issuer`}
                                  label="Elmi dərəcəni verən müəssisə"
                                  id={`academicDegrees[${index}].issuer`}
                                  defaultValue=""
                                  fullWidth
                                >
                                  <MenuItem
                                    selected
                                    key={index}
                                    value={
                                      employeeById?.academicDegrees[index]
                                        ?.issuer
                                    }
                                  >
                                    {
                                      employeeById?.academicDegrees[index]
                                        ?.issuer
                                    }
                                  </MenuItem>
                                  {/* {degreeIssuer.map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))} */}
                                </Field>
                              </FormControl>
                            </Grid>
                          </Grid>

                          {/*start date and end date */}
                          <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                            <Grid sx={{ width: "100%" }}>
                              <Field name={`academicDegrees[${index}].date`}>
                                {({ field, form }) => {
                                  return (
                                    <DatePicker
                                      {...field}
                                      label="Elmi dərəcəni aldığı tarix"
                                      inputFormat="dd/MM/yyyy"
                                      value={
                                        employeeById?.academicDegrees[index]
                                          ?.date || null
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          helperText={
                                            form.errors?.academicDegrees?.[
                                              index
                                            ]?.startDate
                                          }
                                          error={Boolean(
                                            form.errors?.academicDegrees?.[
                                              index
                                            ]?.startDate
                                          )}
                                          fullWidth
                                        />
                                      )}
                                    />
                                  );
                                }}
                              </Field>
                            </Grid>
                            <Grid sx={{ width: "100%" }}>
                              <Field
                                type="text"
                                name={`academicDegrees[${index}].note`}
                                as={TextField}
                                label="Qeyd *"
                                fullWidth
                                value={
                                  employeeById?.academicDegrees[index]?.note
                                }
                              />
                            </Grid>
                          </Grid>

                          <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                            <Grid sx={{ width: "100%" }}>
                              <Field
                                type="text"
                                value={
                                  employeeById?.academicDegrees?.[index]?.value
                                }
                                as={TextField}
                                name={`academicDegrees[${index}].value`}
                                label="Elmi dərəcənin dəyəri"
                                fullWidth
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "19px",
                                      }}
                                    >
                                      &#8380;
                                    </span>
                                  ),
                                }}
                              />
                            </Grid>
                          </Grid>
                        </>
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default ViewAcademicDegree;
