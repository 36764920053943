import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { Doughnut } from "react-chartjs-2";
import { MoreVertical } from "react-feather";

import { orange, green, red, blue } from "@mui/material/colors";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";
import { getEmployeesAtWork } from "../../../api/vacationRequests/vacationRequests";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 200px;
  position: relative;
  margin-top: -20px;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -19px;
  text-align: center;
  z-index: 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const GreenText = styled.span`
  color: ${() => green[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const BlueText = styled.span`
  color: ${() => blue[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const RedText = styled.span`
  color: ${() => red[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const PeopleAtWorkDoughnut = ({ theme }) => {
  const [employeeAtWork, setEmployeeAtWork] = useState([]);
  const structureId = "64ca4b9056e5fd0f87631508";

  useEffect(() => {
    const getWorkingEmployees = async () => {
      try {
        const response = await getEmployeesAtWork(structureId);
        if (response && response.data) {
          const modifiedResponse = {
            ...response.data,
            atWorkPerc: Math.round(
              (response.data.atWork /
                (response.data.atWork + response.data.onVacation)) *
                100
            ),
            onVacationPerc: Math.round(
              (response.data.onVacation /
                (response.data.atWork + response.data.onVacation)) *
                100
            ),
          };
          setEmployeeAtWork(modifiedResponse);
        }
      } catch (error) {
        console.log("err", error);
      }
    };
    getWorkingEmployees();
  }, []);

  const data = {
    labels: ["İş Başında", "Məzuniyyətdə", "ƏQMİ"],
    datasets: [
      {
        data: [employeeAtWork.atWork, employeeAtWork.onVacation, 0],
        backgroundColor: [
          green[500],
          blue[500],
          orange[500],
          theme.palette.grey[200],
        ],
        borderWidth: 5,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "80%",
  };

  return (
    <Card>
      <CardHeader title="Əməkdaşların iş başında olma faizi - Cari" />
      <CardContent>
        <ChartWrapper>
          <DoughnutInner>
            <Typography variant="h4">{employeeAtWork.atWorkPerc}%</Typography>
            <Typography variant="caption">İş Başında</Typography>
          </DoughnutInner>
          <Doughnut data={data} options={options} />
        </ChartWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell align="right">Əməkdaş Sayı</TableCell>
              <TableCell align="right">Faiz dərəcəsi</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <Button
                  color="success"
                  variant="contained"
                  sx={{ ml: -4, color: "white", width: "120px" }}
                >
                  İş Başında
                </Button>
              </TableCell>
              <TableCell align="right">{employeeAtWork.atWork}</TableCell>
              <TableCell align="right">
                <GreenText>{employeeAtWork.atWorkPerc} %</GreenText>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Button
                  variant="contained"
                  sx={{ ml: -4, color: "white", width: "120px" }}
                >
                  Məzuniyyətdə
                </Button>
              </TableCell>
              <TableCell align="right">{employeeAtWork.onVacation}</TableCell>
              <TableCell align="right">
                <BlueText>{employeeAtWork.onVacationPerc}%</BlueText>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Button
                  variant="contained"
                  color="warning"
                  sx={{ ml: -4, color: "white", width: "120px" }}
                >
                  ƏQMİ
                </Button>
              </TableCell>
              <TableCell align="right">0</TableCell>
              <TableCell align="right">
                <BlueText>0</BlueText>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default withTheme(PeopleAtWorkDoughnut);
