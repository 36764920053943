import React from "react";
import styled from "@emotion/styled";

import {
  Grid,
  List,
  ListItemText as MuiListItemText,
  ListItemButton as MuiListItemButton,
} from "@mui/material";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(0.25)}
    ${(props) => props.theme.spacing(4)};
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItemButton = styled.p`
  display: inline-block;
  width: auto;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: ${(props) => props.theme.spacing(2)} !important;
  padding-right: ${(props) => props.theme.spacing(2)} !important;
`;

const ListItemText = styled(MuiListItemText)`
  span {
  }
`;

function Footer() {
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Grid
          sx={{ display: { xs: "none", md: "block" } }}
          container
          item
          xs={12}
          md={6}
        >
          <List>
            <ListItemButton>
              <span>&copy; Blink-bi.az bütün hüquqları qorunur</span>
            </ListItemButton>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
