import { Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getVacationRequestsCount } from "../../../api/vacationRequests/vacationRequests";

const VacationStatistics = () => {
  const [pendingVacation, setPendingVacation] = useState();
  const [monthVacationRequests, setMonthVacationRequests] = useState();

  const todaysDate = new Date();
  const lastYear = todaysDate.getFullYear();
  const month = todaysDate.getMonth();

  // Get the first day of the current month
  const firstDayOfMonth = new Date(lastYear, month, 1);

  // Get the last day of the current month
  const lastDayOfMonth = new Date(lastYear, month + 1, 0);
  const requestType = "VacationRequest";

  const getVacationsStatistics = async () => {
    try {
      const response = await getVacationRequestsCount("pending", requestType);
      if (response && response.data) {
        setPendingVacation(response.data);
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const getMontlyVacationStatistics = async () => {
    try {
      const monthFirstDay = moment(firstDayOfMonth).format("MM/DD/yyyy");
      const monthLastDay = moment(lastDayOfMonth).format("MM/DD/yyyy");
      const monthlyVacationsResponse = await getVacationRequestsCount(
        "",
        requestType,
        monthFirstDay,
        monthLastDay
      );
      if (monthlyVacationsResponse && monthlyVacationsResponse.data) {
        setMonthVacationRequests(monthlyVacationsResponse.data);
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  useEffect(() => {
    getVacationsStatistics();
    getMontlyVacationStatistics();
  }, []);

  return (
    <Grid
      sx={{ display: "flex", flexDirection: "column", gap: 5, height: "100%" }}
    >
      <Grid
        sx={{
          borderRadius: 4,
          p: 6,
          background: "#0F4C75",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4">Təsdiq gözləyən məzuniyyətlər</Typography>
        <Typography
          variant="h2"
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {pendingVacation?.byStatus?.pending}
        </Typography>
      </Grid>
      <Grid sx={{ display: "flex", gap: 5, height: "100%", width: "100%" }}>
        <Grid
          sx={{
            borderRadius: 4,
            p: 6,
            background: "#903749",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <Typography variant="h4">
            Cari ay üzrə imtina edilmiş məzuniyyətlər
          </Typography>
          <Typography
            variant="h2"
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            {monthVacationRequests?.byStatus?.rejected}
          </Typography>
        </Grid>
        <Grid
          sx={{
            borderRadius: 4,
            width: "50%",
            p: 6,
            background: "#0D7377",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">
            Cari ay təsdiq olunmuş məzuniyyətlər
          </Typography>
          <Typography
            variant="h2"
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            {monthVacationRequests?.byStatus?.approved}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VacationStatistics;
