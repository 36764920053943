import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfDay, isAfter, isBefore } from "date-fns";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { getCommonData } from "../../api/api";
import { BASE_URL } from "../../constants";

const today = endOfDay(new Date());

const ViewEmployeeDocuments = ({ employeeById, submitted, setSubmitted }) => {
  const baseURL = BASE_URL;

  const [documentsType, setDocumentsType] = useState([]);
  const [showPicture, setShowPicture] = useState(false);
  const [image, setImage] = useState({ src: "", name: "" });
  const [fileBase64, setFileBase64] = useState({ src: "", name: "" });

  useEffect(() => {
    const handleDocumentsType = async () => {
      const response = await getCommonData("documentTypes");
      setDocumentsType(response.data);
    };
    handleDocumentsType();
  }, []);

  const initialValues = {
    documents: employeeById.documents || [],
  };

  const handleShowImage = async (values, index) => {
    if (values.documents[index].filename) {
      setImage({
        src: values.documents[index].filename._id,
        name: values.documents[index].docType,
      });
    } else {
      setFileBase64({
        src: values.documents[index].fileBase64,
        name: values.documents[index].docType,
      });
    }
    setShowPicture(true);
  };

  const handleCloseImage = () => {
    setImage({ src: "", name: "" });
    setFileBase64({ src: "", name: "" });
    setShowPicture(false);
  };

  return (
    <>
      {employeeById ? (
        <Formik
          initialValues={{
            documents: initialValues.documents || [], // Set the initial laborActivity values from employeeById
          }}
        >
          {({
            values,
            isValid,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form style={{ pointerEvents: "none" }}>
              <FieldArray name="documents">
                {({ push, remove }) => (
                  <div>
                    {values.documents.map((form, index) => (
                      <div key={index}>
                        <>
                          <Typography mt={5} variant="h6">
                            {index + 1 + ")"} Şəxsi işi - Sənədlər və şəkillər
                          </Typography>

                          {/* documents catogry and itself*/}
                          <Grid
                            sx={{
                              display: "flex",
                              gap: 3,
                              mb: 3,
                              alignItems: "center",
                              mt: 5,
                            }}
                          >
                            <Grid sx={{ width: "100%" }}>
                              <FormControl fullWidth>
                                <InputLabel id={`documents[${index}].docType`}>
                                  Sənədin növü
                                </InputLabel>
                                <Field
                                  as={Select}
                                  name={`documents[${index}].docType`}
                                  labelId={`documents[${index}].docType`}
                                  label="Sənədin növü"
                                  id={`documents[${index}].docType`}
                                  defaultValue=""
                                  fullWidth
                                >
                                  <MenuItem
                                    selected
                                    key={index}
                                    value={
                                      employeeById?.documents[index]?.docType
                                    }
                                  >
                                    {employeeById?.documents[index]?.docType}
                                  </MenuItem>
                                  {/* {documentsType.map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))} */}
                                </Field>
                              </FormControl>
                              <ErrorMessage
                                name={`documents[${index}].docType`}
                                component="div"
                              />
                            </Grid>

                            <Grid sx={{ width: "100%" }}>
                              <Grid
                                sx={{
                                  display: "flex",
                                  gap: 3,
                                  alignItems: "center",
                                }}
                              >
                                <Button
                                  sx={{ height: "50px" }}
                                  fullWidth
                                  variant="contained"
                                  onClick={() => {
                                    handleShowImage(values, index);
                                  }}
                                >
                                  Şəkli göstər
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>

                          {/* Picture dialog */}
                          <Dialog
                            fullWidth
                            open={showPicture}
                            onClose={handleCloseImage}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              {image.name ? image.name : fileBase64.name}
                            </DialogTitle>
                            <DialogContent>
                              {image.src ? (
                                <Box
                                  component="img"
                                  sx={{
                                    height: 350,
                                    width: "100%",
                                  }}
                                  src={`${baseURL}file/${image.src}`}
                                />
                              ) : null}
                              {fileBase64.src ? (
                                <Box
                                  component="img"
                                  sx={{
                                    height: 350,
                                    width: "100%",
                                  }}
                                  src={fileBase64.src}
                                />
                              ) : null}
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={handleCloseImage}
                                color="primary"
                              >
                                Çıxış et
                              </Button>
                            </DialogActions>
                          </Dialog>

                          {/* start date and end date */}
                          <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                            <Grid sx={{ width: "100%" }}>
                              <Field name={`documents[${index}].issuanceDate`}>
                                {({ field, form }) => (
                                  <DatePicker
                                    {...field}
                                    label="Sənədin verilmə tarixi"
                                    inputFormat="dd/MM/yyyy"
                                    value={
                                      employeeById?.documents?.[index]
                                        ?.issuanceDate || null
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        helperText={
                                          form.errors?.documents?.[index]
                                            ?.issuanceDate
                                        }
                                        error={Boolean(
                                          form.errors.documents?.[index]
                                            ?.issuanceDate
                                        )}
                                        fullWidth
                                      />
                                    )}
                                    disableFuture
                                    clearable
                                    clearText="Clear"
                                  />
                                )}
                              </Field>
                            </Grid>
                            {employeeById?.documents?.[index]
                              ?.expirationDate && (
                              <Grid sx={{ width: "100%" }}>
                                <Field
                                  name={`documents[${index}].expirationDate`}
                                >
                                  {({ field, form }) => (
                                    <DatePicker
                                      {...field}
                                      label="Sənədin qüvvədən düşmə tarixi (müddətsiz ola bilər)"
                                      inputFormat="dd/MM/yyyy"
                                      value={
                                        employeeById?.documents?.[index]
                                          ?.expirationDate || null
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          helperText={
                                            form.errors?.documents?.[index]
                                              ?.expirationDate
                                          }
                                          error={Boolean(
                                            form.errors.documents?.[index]
                                              ?.expirationDate
                                          )}
                                          fullWidth
                                        />
                                      )}
                                      minDate={today}
                                      disablePast
                                      clearable
                                      clearText="Clear"
                                    />
                                  )}
                                </Field>
                              </Grid>
                            )}
                          </Grid>
                        </>
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default ViewEmployeeDocuments;
