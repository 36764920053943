import * as Yup from "yup";

const labourPeriodSectionValidator = Yup.object().shape({
  contractPeriodType: Yup.object().shape({
    value: Yup.string(),
  }),
  probationPeriod: Yup.object().shape({
    value: Yup.string().when("type", {
      is: (val) => {
        return !val || val === "";
      },
      then: Yup.string("Müddət tipi seçilməlidir"),
      otherwise: Yup.string(),
    }),
    type: Yup.string(),
  }),

  probationPeriodType: Yup.object().shape({
    value: Yup.string(),
  }),
  reasonOfTemporaryContract: Yup.object().shape({
    value: Yup.string(),
  }),
  contractStartDate: Yup.object().shape({
    value: Yup.string(),
  }),
  contractEndDate: Yup.object().shape({
    value: Yup.string(),
  }),
  jobStartDate: Yup.object().shape({
    value: Yup.string(),
  }),
});

const wageSectionValidator = Yup.object().shape({
  wage: Yup.object().shape({
    value: Yup.string().when("changed", {
      is: (val) => val,
      then: Yup.string().test(
        "isPositive",
        "Müsbət ədəd olmalıdır",
        (val) => +val > 0
      ),
    }),
  }),
  wageBonus: Yup.object().shape({
    value: Yup.string(),
    type: Yup.string(),
  }),
  bonusPeriod: Yup.object().shape({
    value: Yup.string(),
  }),
  givenBonus: Yup.object().shape({
    value: Yup.string().notRequired(),
  }),
  workingCondition: Yup.object().shape({
    value: Yup.string(),
  }),
  extraDueCondition: Yup.object().shape({
    value: Yup.string(),
  }),
  overtime: Yup.object().shape({
    value: Yup.string(),
  }),
  wageDayOfWeek: Yup.object().shape({
    value: Yup.string(),
  }),
  wageDaysOfMonth: Yup.object().shape({
    value: Yup.string(),
  }),
  wageDayOfMonth: Yup.object().shape({
    value: Yup.string(),
  }),
  conditionsOfSalaryPayment: Yup.object().shape({
    value: Yup.string(),
  }),
});

const protectionSectionValidator = Yup.object().shape({
  providedProtectiveEquipment: Yup.object().shape({
    value: Yup.string(),
  }),
  providedFood: Yup.object().shape({
    value: Yup.string(),
  }),
  instructionalPeriod: Yup.object().shape({
    value: Yup.string(),
  }),
});

const workVacationTimeSectionValidator = Yup.object().shape({
  providedCar: Yup.object().shape({
    value: Yup.string(),
  }),
  offDays: Yup.object().shape({
    value: Yup.string(),
  }),
});

const vacationSectionValidator = Yup.object().shape({
  vacationDaysCount: Yup.object().shape({
    value: Yup.string().notRequired(),
  }),
  forWorkConditions: Yup.object().shape({
    value: Yup.string(),
  }),
  forSeniorityLevel: Yup.object().shape({
    value: Yup.string(),
  }),
  forParental: Yup.object().shape({
    value: Yup.string(),
  }),
  forCollectiveAgreement: Yup.object().shape({
    value: Yup.string(),
  }),
  socialHouseholdBenefit: Yup.object().shape({
    value: Yup.string(),
  }),
  aboutEventOrPayer: Yup.object().shape({
    value: Yup.string(),
  }),
});

const physicalEducationSportSectionValidator = Yup.object().shape({
  physicalEducationAndSportsConditions: Yup.object().shape({
    value: Yup.string(),
  }),
  otherAdditionalConditions: Yup.object().shape({
    value: Yup.string(),
  }),
});

const socialProtectionSectionValidator = Yup.object().shape({
  monthlyInsuranceFee: Yup.object().shape({
    value: Yup.string(),
  }),
  extraSumInsured: Yup.object().shape({
    value: Yup.string(),
  }),
});

const regulationPropertyRelation = Yup.object().shape({
  shareOwnershipOfEnterprise: Yup.object().shape({
    value: Yup.string(),
  }),
  dividendAmount: Yup.object().shape({
    value: Yup.string(),
  }),
  personalPropertiesUsedByEmployee: Yup.object().shape({
    value: Yup.string(),
  }),
});

export const CONT_CHANGE_SCHEMA = Yup.object().shape({
  labour_period: labourPeriodSectionValidator,
  for_wage: wageSectionValidator,
  for_protection: protectionSectionValidator,
  for_work_vacation_time: workVacationTimeSectionValidator,
  for_vacation: vacationSectionValidator,
  physical_education_sport: physicalEducationSportSectionValidator,
  social_protection: socialProtectionSectionValidator,
  regulation_property_relations: regulationPropertyRelation,
});
