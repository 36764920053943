import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nationality: "",
};

export const nationalitySlice = createSlice({
  name: "nationality",
  initialState,
  reducers: {
    setNationality: (state, action) => {
      state.nationality = action.payload;
    },
  },
});

export const { setNationality } = nationalitySlice.actions;

export default nationalitySlice.reducer;
