import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ViewDecisionChain = ({ state, dispatch }) => {
  const { selectedDecisionChain, viewDecisionDialog } = state;

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={viewDecisionDialog}
        onClose={() => {
          dispatch({
            type: "SET_VIEW_DECISION_DIALOG",
            payload: false,
          });
        }}
        aria-labelledby="form-dialog-title"
      >
        {selectedDecisionChain ? (
          <>
            {" "}
            <DialogTitle id="form-dialog-title">
              <Typography p={5} textAlign="center" variant="h3">
                {selectedDecisionChain?.structure?.organization?.name} -
                Təsdiqləmə matrisi
              </Typography>
            </DialogTitle>
            <DialogContent sx={{ px: "50px" }}>
              <Grid sx={{ display: "flex", width: "100%", gap: 15 }}>
                <Grid
                  sx={{
                    width: 1 / 3,
                    py: 2,
                    maxHeight: "55vh",
                    overflowY: "auto",
                  }}
                >
                  <FormControl sx={{ mb: 4 }} fullWidth>
                    <Typography>Təşkilat</Typography>
                    <TextField
                      m={2}
                      disabled
                      id="standard-disabled"
                      label={
                        selectedDecisionChain?.structure?.organization?.name
                      }
                    />
                  </FormControl>
                  <FormControl sx={{ mb: 4 }} fullWidth>
                    <Typography>Struktur</Typography>
                    <TextField
                      m={2}
                      disabled
                      id="standard-disabled"
                      label={
                        selectedDecisionChain?.topCompanyPartRel?.source?.name
                      }
                    />
                  </FormControl>
                  <FormControl sx={{ mb: 4 }} fullWidth>
                    <Typography>Substruktur</Typography>
                    <TextField
                      m={2}
                      disabled
                      id="standard-disabled"
                      label={
                        selectedDecisionChain?.companyPartRel?.source?.name
                      }
                    />
                  </FormControl>
                  <FormControl sx={{ mb: 4 }} fullWidth>
                    <Typography>Vəzifə</Typography>
                    <TextField
                      m={2}
                      disabled
                      id="standard-disabled"
                      label={
                        selectedDecisionChain?.positionPartRel?.source?.name
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid
                  sx={{
                    width: 2 / 3,
                    py: 2,
                    maxHeight: "55vh",
                    overflowY: "auto",
                  }}
                >
                  {selectedDecisionChain?.approvementChain.map(
                    (item, index) => (
                      <FormControl
                        key={"form" + index}
                        sx={{ mb: 4 }}
                        fullWidth
                      >
                        <Typography>
                          {index + 1} Təsdiqləyici əməkdaş
                        </Typography>
                        <TextField
                          m={2}
                          disabled
                          id="standard-disabled"
                          label={
                            item?.employee[0]?.firstname +
                            " " +
                            item?.employee[0]?.lastname
                          }
                        />
                      </FormControl>
                    )
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={() => {
                  dispatch({
                    type: "SET_VIEW_DECISION_DIALOG",
                    payload: false,
                  });
                }}
              >
                Çıxış et
              </Button>
            </DialogActions>
          </>
        ) : (
          <>Loading...</>
        )}
      </Dialog>
    </>
  );
};

export default ViewDecisionChain;
