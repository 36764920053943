import styled from "@emotion/styled";
import React from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate } from "react-router-dom";

import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DepartmentsEmployeeAtWork from "../../../components/vacations/hrpage/DepartmentsEmployeeAtWork";
import MonthlyRequestsBar from "../../../components/vacations/hrpage/MonthlyRequestsBar";
import PeopleAtWorkDoughnut from "../../../components/vacations/hrpage/PeopleAtWorkDoughnut";
import VacationStatistics from "../../../components/vacations/hrpage/VacationStatistics";
import VacationsRequestsTable from "../../../components/vacations/hrpage/VacationsRequestsTable";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function VacationsComponent() {
  return (
    // <Card mb={6}>
    <Grid>
      <Grid container spacing={5} mb={5}>
        <Grid item xs={6}>
          <VacationStatistics />
        </Grid>
        <Grid item xs={6}>
          <MonthlyRequestsBar />
        </Grid>
      </Grid>
      <Grid container spacing={5} mb={5}>
        <Grid item xs={7}>
          <DepartmentsEmployeeAtWork />
        </Grid>
        <Grid item xs={5}>
          <PeopleAtWorkDoughnut />
        </Grid>
      </Grid>
      <Grid>
        <VacationsRequestsTable />
      </Grid>
    </Grid>
    // </Card>
  );
}

function Vacations() {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <ToastContainer />
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Məzuniyyətlər
          </Typography>
        </Grid>
        <Grid item>
          {/* <Button
            fullWidth
            onClick={() => {
              navigate("/create-vacation-request");
            }}
            variant="contained"
            endIcon={<AddCircleIcon />}
          >
            <Typography noWrap>Məzuniyyət Sorğusu Yarat</Typography>
          </Button> */}
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <VacationsComponent />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Vacations;
