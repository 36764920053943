import { useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";

const ResizableTextField = (props) => {
  const [width, setWidth] = useState("auto");
  const spanRef = useRef(null);

  useEffect(() => {
    if (spanRef.current) {
      setWidth(`${spanRef.current.offsetWidth + 50}px`);
    }
  }, [props.value]);

  return (
    <div style={{ position: "relative" }}>
      <TextField {...props} style={{ ...props.style, width: width }} />
      <span
        ref={spanRef}
        style={{
          visibility: "hidden",
          whiteSpace: "nowrap",
          position: "absolute",
          fontSize: "16px", // Match your input's font size
          fontFamily: "Roboto",
        }}
      >
        {props.value}
      </span>
    </div>
  );
};

export default ResizableTextField;
