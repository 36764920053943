import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { Grid, Card as MuiCard, Divider as MuiDivider } from "@mui/material";
import { spacing } from "@mui/system";
import VacationHeader from "../../../layouts/vacations/header/VacationHeader";
import useAuth from "../../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeByUserId } from "../../../api/api";
import { setCurrentEmployee } from "../../../redux/features/slices/commonThings.slice";
import CardTotals from "../../../layouts/vacations/cards/CardTotals";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApproverReportingForm from "../../../components/vacations/approver/ApproverReportingForm";
import {
  getApproverVacationRequest,
  getApproverVacationWithFilter,
} from "../../../api/employeeRequests/employeeRequests";
import VacationCards from "../../../layouts/vacations/cards/VacationCards";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const typeOfDialog = "request";

function EmptyCard() {
  const navigate = useNavigate();
  const [totalRequests, setTotalRequests] = useState();
  const [requests, setRequests] = useState({
    approved: "",
    pending: "",
    reRequested: "",
    rejected: "",
  });

  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);

  const [submitted, setSubmitted] = useState(false);

  const cardTitles = {
    pendingTitle: "Yeni Sorğular",
    approvedTitle: "Təsdiq edilmiş sorğular",
    rejectedTitle: "İmtina edilmiş sorğular",
    rerequestedTitle: "Yenidən göndərilmiş sorğular",
  };

  const requestType = { value: "VacationRequest", name: "Məzuniyyət Sorğusu" };
  const approver = true;

  const { currentEmployee } = useSelector((state) => state.commonThings);

  useEffect(() => {
    const getVacations = async () => {
      try {
        const response = await getApproverVacationRequest(limit, offset);
        if (response && response.data) {
          setTotalRequests({
            approved: response.data.approved.total,
            pending: response.data.pending.total,
            rejected: response.data.rejected.total,
            rerequested: response.data.reRequested.total,
          });
          setRequests(response.data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getVacations();
  }, [submitted]);

  const handlePagination = async (value, status) => {
    const newOffset = (value - 1) * 5;
    try {
      const response = await getApproverVacationWithFilter(
        newOffset,
        limit,
        status
      );

      if (response && response.data) {
        if (status === "pending") {
          setRequests((prevState) => ({
            ...prevState,
            pending: response.data,
          }));
        } else if (status === "approved") {
          setRequests((prevState) => ({
            ...prevState,
            approved: response.data,
          }));
        } else if (status === "rejected") {
          setRequests((prevState) => ({
            ...prevState,
            rejected: response.data,
          }));
        } else {
          setRequests((prevState) => ({
            ...prevState,
            reRequested: response.data,
          }));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid>
      <Grid mb={7} sx={{ alignItems: "center", display: "flex", gap: 5 }}>
        <Grid>
          <CardTotals totalRequests={totalRequests} />
        </Grid>
        <Grid sx={{ width: "100%" }}>
          <Card px={4} py={3}>
            <ApproverReportingForm />
          </Card>
        </Grid>
      </Grid>

      <Grid>
        <VacationCards
          requests={requests}
          totalRequests={totalRequests}
          limit={limit}
          cardTitles={cardTitles}
          approver={approver}
          handlePagination={handlePagination}
          submitted={submitted}
          setSubmitted={setSubmitted}
          typeOfDialog={typeOfDialog}
        />
      </Grid>
    </Grid>
  );
}

function VacationApproversPage() {
  const { user } = useAuth();

  const dispatch = useDispatch();

  const { currentEmployee } = useSelector((state) => state.commonThings);

  useEffect(() => {
    if (user) {
      const getUser = async () => {
        try {
          const response = await getEmployeeByUserId(user.id);
          if (response && response.data) {
            dispatch(setCurrentEmployee(response.data));
          }
        } catch (err) {
          console.log(err);
        }
      };
      getUser();
    }
  }, [user]);

  const headerTitle = "Sistem Təsdiqləri";

  return (
    <React.Fragment>
      <VacationHeader headerTitle={headerTitle} employee={currentEmployee} />

      <ToastContainer />

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EmptyCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default VacationApproversPage;
