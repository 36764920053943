import React, { useEffect, useState } from "react";
import { Field, FieldArray, ErrorMessage, Formik, Form } from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Button,
  Checkbox,
  Typography,
  FormHelperText,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { getCommonData, updateEmployee } from "../../api/api";
import { isValid, parse } from "date-fns";
import moment from "moment";
import {
  inventoriesValidation,
  transportVehiclesValidation,
} from "../employees/Validations";
import { checkUniquenessForNext } from "../../utils/checkUniquenessForEmployee";
import NewCommonDataDialog from "../employees/NewCommonDataDialog";
import CustomAccordion from "../ui/Accordion/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const EditVehicles = ({
  submitted,
  selectedType,
  employeeById,
  setSubmitted,
  setSelectedType,
  // setOpenAddDialog,
}) => {
  const [carTypes, setCarTypes] = useState([]);
  const [carItems, setCarItems] = useState([[]]);
  const [pastData, setPastData] = useState([]);
  const [pastDatas, setPastDatas] = useState([]);
  const [initials, setInitials] = useState(true);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [carName, setCarName] = useState("");
  useEffect(() => {
    const handleInventory = async () => {
      try {
        const updateCarItems = [];

        const response = await getCommonData("carManufacturers");
        const carTypes = response?.data || [];
        setCarTypes(carTypes);
        const combinedData = [];
        if (carTypes.length) {
          combinedData.push({ carTypes: [...carTypes] });
        }

        for (
          let index = 0;
          index < employeeById.transportVehicles.length;
          index++
        ) {
          const item = employeeById.transportVehicles[index];
          try {
            // Make the API request using the item's "parent" as a parameter
            const response = await getCommonData("carModels", "", item.parent);
            const carModels = response?.data || [];
            const combinedDatas = [];
            if (carModels.length) {
              combinedDatas.push({ carModels: [...carModels] });
            }
            updateCarItems.push(carModels);
            handlePastData(combinedDatas);
          } catch (error) {
            console.log("Error fetching data for item at index", index, error);
          }
        }

        setCarItems(updateCarItems);
        handlePastData(combinedData);
      } catch (error) {
        console.log(error);
      }
    };
    handleInventory();
  }, [employeeById.transportVehicles, submitted]);

  const handleUpdateCarItems = async () => {
    try {
      const response = await getCommonData("carModels", "", carName);
      setCarItems((prevCarItems) => [...prevCarItems, response.data]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleItems = async (value, index, model) => {
    try {
      const response = await getCommonData("carModels", "", value);

      const updatedCarItems = [...carItems];

      updatedCarItems[index] = response.data;

      setCarItems(updatedCarItems);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const hasDuplicates = checkUniquenessForNext(
      values.transportVehicles,
      "registrationNumber",
      "qeydiyyat şəhadətnaməsi nömrəsi"
    );
    if (!hasDuplicates) {
      setSubmitting(true);
      const modifiedValues = {
        ...values,
        transportVehicles:
          values?.transportVehicles &&
          values?.transportVehicles.map((vehicle) => ({
            ...vehicle,
            registrationNumber: vehicle.registrationNumber.trim(),
            registrationPlate: vehicle.registrationPlate.trim(),
            year: vehicle.year.trim(),
            engineCapacity: vehicle.engineCapacity.trim(),
          })),
      };
      await updateEmployee(employeeById._id, modifiedValues);
      setSubmitting(false);
      setSubmitted(!submitted);
      setInitials(true);
      resetForm({ values });
    }
  };

  const initialValues = {
    transportVehicles: employeeById.transportVehicles || [],
  };
  function handlePastData(data) {
    if (data[0]?.carModels) {
      setPastDatas(data);
    }
    if (data[0]?.carTypes) {
      setPastData(data);
    }
  }
  function handlePush(index) {
    const transportVehicle = employeeById?.transportVehicles[index];

    // Check if carTypes already contains the brand
    if (carTypes && !carTypes.includes(transportVehicle?.brand)) {
      carTypes.push(transportVehicle?.brand);
    }

    // Check if carItems at index already contains an object with the same name and parent
    if (carItems[index]) {
      const alreadyExists = carItems[index].some(
        (item) =>
          item.name === transportVehicle?.name &&
          item.parent === transportVehicle?.brand
      );

      if (!alreadyExists) {
        carItems[index].push({
          name: transportVehicle?.name,
          parent: transportVehicle?.brand,
        });
      }
    }
  }
  function handleHidden(name, data, index) {
    if (name === "carModels") {
      return pastDatas.every((past) => {
        return !past?.[name]?.some((item) => item?.name === data?.name);
      });
    } else {
      return pastData.every((past) => {
        return !past?.[name]?.includes(data);
      });
    }
  }
  return (
    <CustomAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h6>Nəqliyyat Vasitələri</h6>
      </AccordionSummary>
      <AccordionDetails>
        {employeeById ? (
          <Formik
            initialValues={{
              transportVehicles: initialValues.transportVehicles || [], // Set the initial laborActivity values from employeeById
            }}
            onSubmit={handleSubmit}
            validationSchema={transportVehiclesValidation}
          >
            {({
              values,
              isValid,
              errors,
              touched,
              isSubmitting,
              handleChange,
              setFieldValue,
              dirty,
            }) => (
              <>
                <Form>
                  <FieldArray name="transportVehicles">
                    {({ push, remove }) => (
                      <div>
                        {values?.transportVehicles &&
                          values?.transportVehicles.map((form, index) => (
                            <div key={index}>
                              <>
                                {handlePush(index)}
                                <Typography mt={3} mb={3} variant="h6">
                                  {index + 1})Nəqliyyat Vasitələri
                                </Typography>

                                {/* Vehicle type and model */}
                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid sx={{ width: "100%" }}>
                                    <FormControl
                                      error={
                                        touched?.transportVehicles?.[index]
                                          ?.brand &&
                                        errors?.transportVehicles?.[index]
                                          ?.brand &&
                                        Boolean(
                                          errors?.transportVehicles?.[index]
                                            ?.brand
                                        )
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`transportVehicles[${index}].brand`}
                                      >
                                        Marka *
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`transportVehicles[${index}].brand`}
                                        labelId={`transportVehicles[${index}].brand`}
                                        label="Marka *"
                                        id={`transportVehicles[${index}].brand`}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `transportVehicles[${index}].brand`,
                                            e.target.value
                                          );
                                          setInitials(false);
                                          setFieldValue(
                                            `transportVehicles[${index}].name`,
                                            ""
                                          );
                                          handleItems(e.target.value, index);
                                          setCarName(e.target.value);
                                        }}
                                        defaultValue=""
                                        error={
                                          touched?.transportVehicles?.[index]
                                            ?.brand &&
                                          errors?.transportVehicles?.[index]
                                            ?.brand &&
                                          Boolean(
                                            errors?.transportVehicles?.[index]
                                              ?.brand
                                          )
                                        }
                                        fullWidth
                                      >
                                        {carTypes &&
                                          [...new Set(carTypes)]
                                            .sort((a, b) =>
                                              a.localeCompare(b, "az")
                                            )
                                            .map((item, index) => (
                                              <MenuItem
                                                hidden={handleHidden(
                                                  "carTypes",
                                                  item
                                                )}
                                                value={item}
                                              >
                                                {item}
                                              </MenuItem>
                                            ))}
                                        <Button
                                          sx={{
                                            mt: 3,
                                            ml: 3,
                                          }}
                                          variant="outlined"
                                          onClick={() => {
                                            setOpenAddDialog(true);
                                            setSelectedType([
                                              "carManufacturers",
                                              { name: "Marka növləri" },
                                            ]);
                                          }}
                                        >
                                          Yeni marka növü əlavə et
                                        </Button>
                                      </Field>
                                      {touched?.transportVehicles?.[index]
                                        ?.brand &&
                                        errors?.transportVehicles?.[index]
                                          ?.brand &&
                                        errors?.transportVehicles?.[index]
                                          ?.brand && (
                                          <FormHelperText error>
                                            {
                                              errors?.transportVehicles?.[index]
                                                ?.brand
                                            }
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  </Grid>
                                  <Grid sx={{ width: "100%" }}>
                                    <FormControl
                                      error={
                                        touched?.transportVehicles?.[index]
                                          ?.name &&
                                        errors?.transportVehicles?.[index]
                                          ?.name &&
                                        Boolean(
                                          errors?.transportVehicles?.[index]
                                            ?.name
                                        )
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`transportVehicles[${index}].name`}
                                      >
                                        Model *
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`transportVehicles[${index}].name`}
                                        labelId={`transportVehicles[${index}].name`}
                                        label="Model *"
                                        id={`transportVehicles[${index}].name`}
                                        disabled={
                                          initials ||
                                          !values.transportVehicles[index].brand
                                        }
                                        defaultValue=""
                                        error={
                                          touched?.transportVehicles?.[index]
                                            ?.name &&
                                          errors?.transportVehicles?.[index]
                                            ?.name &&
                                          Boolean(
                                            errors?.transportVehicles?.[index]
                                              ?.name
                                          )
                                        }
                                        fullWidth
                                      >
                                        {carItems?.[index] &&
                                          [...new Set(carItems?.[index])]
                                            .filter(
                                              (item, index, self) =>
                                                index ===
                                                self.findIndex(
                                                  (t) => t.name === item.name
                                                )
                                            )
                                            .filter(
                                              (carItem) =>
                                                carItem?.parent ===
                                                values.transportVehicles?.[
                                                  index
                                                ]?.brand
                                            )
                                            ?.sort((a, b) =>
                                              a?.name?.localeCompare(
                                                b?.name,
                                                "az"
                                              )
                                            )
                                            .map((item, idx) => (
                                              <MenuItem
                                                hidden={handleHidden(
                                                  "carModels",
                                                  item
                                                )}
                                                key={idx}
                                                value={item.name}
                                              >
                                                {item.name}
                                              </MenuItem>
                                            ))}

                                        <Button
                                          sx={{
                                            mt: 3,
                                            ml: 3,
                                          }}
                                          variant="outlined"
                                          onClick={() => {
                                            setOpenAddDialog(true);
                                            setSelectedType([
                                              "carModels",
                                              { name: "Model növləri" },
                                            ]);
                                          }}
                                        >
                                          Yeni model əlavə et
                                        </Button>
                                      </Field>
                                      {touched?.transportVehicles?.[index]
                                        ?.name &&
                                        errors?.transportVehicles?.[index]
                                          ?.name &&
                                        errors?.transportVehicles?.[index]
                                          ?.name && (
                                          <FormHelperText error>
                                            {
                                              errors?.transportVehicles?.[index]
                                                ?.name
                                            }
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  </Grid>
                                </Grid>

                                {/* Model and registrationPlate */}
                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid sx={{ width: "100%" }}>
                                    <FormControl
                                      error={
                                        touched?.transportVehicles?.[index]
                                          ?.transportationType &&
                                        errors?.transportVehicles?.[index]
                                          ?.transportationType &&
                                        Boolean(
                                          errors?.transportVehicles?.[index]
                                            ?.transportationType
                                        )
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`transportVehicles[${index}].transportationType`}
                                      >
                                        Nəqliyyat Növü *
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`transportVehicles[${index}].transportationType`}
                                        labelId={`transportVehicles[${index}].transportationType`}
                                        label="Nəqliyyat Növü *"
                                        id={`transportVehicles[${index}].transportationType`}
                                        defaultValue=""
                                        fullWidth
                                        error={
                                          touched?.transportVehicles?.[index]
                                            ?.transportationType &&
                                          errors?.transportVehicles?.[index]
                                            ?.transportationType &&
                                          Boolean(
                                            errors?.transportVehicles?.[index]
                                              ?.transportationType
                                          )
                                        }
                                      >
                                        <MenuItem value="yük">Yük</MenuItem>
                                        <MenuItem value="minik">Minik</MenuItem>
                                        <MenuItem value="qoşqu">Qoşqu</MenuItem>
                                        <MenuItem value="xüsusi">
                                          Xüsusi
                                        </MenuItem>
                                      </Field>
                                      {touched?.transportVehicles?.[index]
                                        ?.transportationType &&
                                        errors?.transportVehicles?.[index]
                                          ?.transportationType &&
                                        errors?.transportVehicles?.[index]
                                          ?.transportationType && (
                                          <FormHelperText error>
                                            {
                                              errors?.transportVehicles?.[index]
                                                ?.transportationType
                                            }
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  </Grid>
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      type="text"
                                      name={`transportVehicles[${index}].registrationNumber`}
                                      as={TextField}
                                      fullWidth
                                      label="N.V. Qeydiyyat Şəhadətnaməsi No. *"
                                      error={
                                        touched?.transportVehicles?.[index]
                                          ?.registrationNumber &&
                                        errors?.transportVehicles?.[index]
                                          ?.registrationNumber &&
                                        Boolean(
                                          errors?.transportVehicles?.[index]
                                            ?.registrationNumber
                                        )
                                      }
                                      helperText={
                                        touched?.transportVehicles?.[index]
                                          ?.registrationNumber &&
                                        errors?.transportVehicles?.[index]
                                          ?.registrationNumber &&
                                        errors?.transportVehicles?.[index]
                                          ?.registrationNumber
                                      }
                                    />
                                  </Grid>
                                </Grid>

                                {/* Number and date */}
                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      type="text"
                                      name={`transportVehicles[${index}].registrationPlate`}
                                      as={TextField}
                                      fullWidth
                                      label="N.V. Qeydiyyat Nişanı *"
                                      error={
                                        touched?.transportVehicles?.[index]
                                          ?.registrationPlate &&
                                        errors?.transportVehicles?.[index]
                                          ?.registrationPlate &&
                                        Boolean(
                                          errors?.transportVehicles?.[index]
                                            ?.registrationPlate
                                        )
                                      }
                                      helperText={
                                        touched?.transportVehicles?.[index]
                                          ?.registrationPlate &&
                                        errors?.transportVehicles?.[index]
                                          ?.registrationPlate &&
                                        errors?.transportVehicles?.[index]
                                          ?.registrationPlate
                                      }
                                    />
                                  </Grid>
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      type="text"
                                      name={`transportVehicles[${index}].year`}
                                      as={TextField}
                                      fullWidth
                                      label="Buraxılış ili *"
                                      error={
                                        touched?.transportVehicles?.[index]
                                          ?.year &&
                                        errors?.transportVehicles?.[index]
                                          ?.year &&
                                        Boolean(
                                          errors?.transportVehicles?.[index]
                                            ?.year
                                        )
                                      }
                                      helperText={
                                        touched?.transportVehicles?.[index]
                                          ?.year &&
                                        errors?.transportVehicles?.[index]
                                          ?.year &&
                                        errors?.transportVehicles?.[index]?.year
                                      }
                                    />
                                  </Grid>
                                </Grid>

                                {/* Volume and Transmission */}
                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      type="text"
                                      name={`transportVehicles[${index}].engineCapacity`}
                                      as={TextField}
                                      fullWidth
                                      label="Mühərrik Həcmi (sm3) *"
                                      error={
                                        touched?.transportVehicles?.[index]
                                          ?.engineCapacity &&
                                        errors?.transportVehicles?.[index]
                                          ?.engineCapacity &&
                                        Boolean(
                                          errors?.transportVehicles?.[index]
                                            ?.engineCapacity
                                        )
                                      }
                                      helperText={
                                        touched?.transportVehicles?.[index]
                                          ?.engineCapacity &&
                                        errors?.transportVehicles?.[index]
                                          ?.engineCapacity &&
                                        errors?.transportVehicles?.[index]
                                          ?.engineCapacity
                                      }
                                    />
                                  </Grid>
                                  <Grid sx={{ width: "100%" }}>
                                    <FormControl
                                      error={
                                        touched?.transportVehicles?.[index]
                                          ?.transmissionType &&
                                        errors?.transportVehicles?.[index]
                                          ?.transmissionType &&
                                        Boolean(
                                          errors?.transportVehicles?.[index]
                                            ?.transmissionType
                                        )
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`transportVehicles[${index}].transmissionType`}
                                      >
                                        Sürət Qutusu *
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`transportVehicles[${index}].transmissionType`}
                                        labelId={`transportVehicles[${index}].transmissionType`}
                                        label="Sürət Qutusu *"
                                        id={`transportVehicles[${index}].transmissionType`}
                                        defaultValue=""
                                        fullWidth
                                        error={
                                          touched?.transportVehicles?.[index]
                                            ?.transmissionType &&
                                          errors?.transportVehicles?.[index]
                                            ?.transmissionType &&
                                          Boolean(
                                            errors?.transportVehicles?.[index]
                                              ?.transmissionType
                                          )
                                        }
                                      >
                                        <MenuItem value="Mexaniki">
                                          Mexaniki
                                        </MenuItem>
                                        <MenuItem value="Avtomat">
                                          Avtomat
                                        </MenuItem>
                                        <MenuItem value="Robotlaşdırılmış">
                                          Robotlaşdırılmış
                                        </MenuItem>
                                        <MenuItem value="Variator">
                                          Variator
                                        </MenuItem>
                                      </Field>
                                      {touched?.transportVehicles?.[index]
                                        ?.transmissionType &&
                                        errors?.transportVehicles?.[index]
                                          ?.transmissionType &&
                                        errors?.transportVehicles?.[index]
                                          ?.transmissionType && (
                                          <FormHelperText error>
                                            {
                                              errors?.transportVehicles?.[index]
                                                ?.transmissionType
                                            }
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </>

                              <Button
                                sx={{ mt: 3 }}
                                variant="contained"
                                color="error"
                                type="button"
                                onClick={() => remove(index)}
                              >
                                Nəqliyyat Vasitəsini sil
                              </Button>
                            </div>
                          ))}
                        <Button
                          sx={{ mt: 3 }}
                          variant="contained"
                          type="button"
                          onClick={() => push({})}
                        >
                          Nəqliyyat Vasitəsi əlavə et
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                  <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!isValid || isSubmitting || !dirty}
                    >
                      Dəyişiklikləri yadda saxla
                    </Button>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        ) : (
          <div>Loading...</div>
        )}
      </AccordionDetails>

      {openAddDialog && (
        <NewCommonDataDialog
          openAddDialog={openAddDialog}
          selectedType={selectedType}
          setOpenAddDialog={setOpenAddDialog}
          submitted={submitted}
          setSubmitted={setSubmitted}
          setCarItems={handleUpdateCarItems}
          carName={carName}
        />
      )}
    </CustomAccordion>
  );
};

export default EditVehicles;
