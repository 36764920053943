/* eslint-disable prettier/prettier */
import {
  Typography,
  Grid,
  Button,
  Dialog,
  Box,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  FormControl,
  InputLabel,
  Select,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  MenuItem,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../components/layouts/header/Header";
import Breadcrumb from "../../components/layouts/breadcrumb/Breadcrumb";
import StatisticDiagramCircle from "../../components/statisticDiagram/StatisticDiagramCircle";
import StatisticDiagramSquare from "../../components/statisticDiagram/StatisticDiagramSquare";
import {
  MailOutline,
  Notifications,
  OfflineShareTwoTone,
  TaskSharp,
} from "@mui/icons-material";
import ContractsDialog from "../../layouts/employment-contracts/dialog/ContractsDialog";
import {
  EMPLOYMENT_CONTRACT_TYPE,
  LEAVES_STATUS,
  OPERATORS_CONTAINING,
  clauses,
} from "../../constants";
import {
  getExpiringContractsFilter as getContractsFilter,
  getContractsStatistics,
} from "../../services/documentations/EmploymentContractsService";
import DataTable from "../../components/layouts/dataTable/DataTable";
import { getDescendantProp } from "../../utils/helperFunctions";
import { DatePicker } from "@mui/x-date-pickers";
import { getAllEmployees } from "../../api/api";
import Column from "../../models/table/Column";
import Table from "../../components/table/Table";

export default function UnknownReason() {
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState();
  const [show_Accordion, set_show_Accordion] = useState(false);
  const [currMonthStat, setCurrMonthStat] = useState([]);
  const [generalStat, setGeneralStat] = useState({
    pending: 0,
    approved: 0,
    rejected: 0,
  });
  const [isLoadingCS, setIsLoadingCS] = useState(false);
  const [isLoadingGS, setIsLoadingGS] = useState(false);
  const [employee, set_employee] = useState({});
  const [create_btn_disable, set_create_btn_disable] = useState(false);
  const [leave_state, set_leave_state] = useState({
    reason: "",
    employee: "",
    start_date: "",
    end_date: "",
    clause: [],
  });
  const [show_dialog, set_show_dialog] = useState(false);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    set_create_btn_disable(
      !leave_state?.clause?.length ||
        leave_state?.reason === "" ||
        leave_state?.start_date === "" ||
        leave_state?.end_date === "" ||
        leave_state?.employee === " "
    );
  }, [leave_state]);
  useEffect(() => {
    //general statistic
    getEmployees();
  }, []);

  //fetch data
  const getEmployees = async () => {
    try {
      setIsLoadingGS(true);
      const response = await getAllEmployees();
      if (response?.data?.employees) {
        let emp = response?.data?.employees?.map((e) => ({
          firstname: e?.firstname,
          lastname: e?.lastname,
          fathername: e?.fathername,
          id: e?._id,
        }));
        set_employee(emp);
      }
      setIsLoadingGS(false);
    } catch (error) {
      console.log("general statistics", error);
      setIsLoadingGS(false);
    }
  };

  const allContColumn = [
    new Column({
      path: "firstname",
      headerName: "Ad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "lastname",
      headerName: "Soyad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "fathername",
      headerName: "Ata adı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "start_date",
      headerName: "Başlama tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
    }),
    new Column({
      path: "end_date",
      headerName: "Bitmə tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
    }),
    new Column({
      path: "clause",
      headerName: "Müddət",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "reason",
      headerName: "Qeyd/Səbəb",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "status",
      headerName: "Status",
      filterType: OPERATORS_CONTAINING.SINGLE_OPTION,
      filterValues: [
        {
          key: EMPLOYMENT_CONTRACT_TYPE.REJECTED,
          name: "Üzrlü",
        },
        {
          key: EMPLOYMENT_CONTRACT_TYPE.APPROVED,
          name: "Üzrsüz",
        },
      ],
      renderCell: (params) => {
        const rowId = params.id;
        const status = params?.row?.status;
        let backgroundColor = "";
        let temp = "";

        switch (status) {
          case LEAVES_STATUS.EXPLANATION_PENDING:
            backgroundColor = "rgba(136, 87, 123, 0.8)";
            temp = "İzahat gözlənilir";
            break;
          case LEAVES_STATUS.ORDER_PENDING:
            backgroundColor = "rgba(75,134,206, 1)";
            temp = "Əmr imzası gözlənilir";
            break;
          case LEAVES_STATUS.REJECTED:
            backgroundColor = "rgba(198, 40, 40, 0.5)";
            temp = "Ləğv edilmişdir";
            break;
          case LEAVES_STATUS.ACCEPTED:
            backgroundColor = "rgba(61,101,80,0.8)";
            temp = "İzahat alınmışdır";
            break;
          default:
            break;
        }

        return (
          <div
            key={rowId}
            // onClick={() => handleCellClick(rowId)}
          >
            <div
              style={{
                backgroundColor,
                padding: "5px",
                borderRadius: "5px",
                width: "110%",
                textAlign: "center",
              }}
            >
              <div>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  title={temp}
                >
                  <Typography>{renderStatus(status)}</Typography>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    }),
  ];

  const headerData = {
    title: "Naməlum səbəbdən işə çıxmama",
    variant: "h3",
  };

  const all_leaves = [
    {
      id: "7019u1jnsj37snns3372932ss2",
      start_date: "20/10/2023",
      end_date: "20/12/2023",
      firstname: "Joe Doe",
      lastname: "zDoe",
      fathername: "Foe",
      reason: "Alkoqol",
      clause: "Naməlum",
      status: "exp",
    },
  ];

  const renderStatus = (status) => {
    switch (status) {
      case LEAVES_STATUS.EXPLANATION_PENDING:
        return "İzahat gözlənilir";
      case LEAVES_STATUS.ORDER_PENDING:
        return "ƏMR İMZASI GÖZLƏNİLİR";
      case LEAVES_STATUS.REJECTED:
        return "LƏĞV EDİLMİŞDİR";
      case LEAVES_STATUS.ACCEPTED:
        return "IZAHAT ALINMISDIR";
      default:
        break;
    }
  };

  const handleLabourItems = (e) => {
    // let filtered = clauses.find(or => or?.childs?.includes(e.target.value));
    let prev = [...leave_state?.clause];
    prev.push(e.target.value);
    set_leave_state({ ...leave_state, clause: prev });
  };

  return (
    <React.Fragment>
      <Box>
        <Grid>
          <Grid>
            <Grid>
              <Header data={headerData} />
            </Grid>
          </Grid>
        </Grid>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => set_show_Accordion(!show_Accordion)}
            variant="contained"
            sx={{ height: "100%" }}
          >
            <Typography>Səbəb yarat</Typography>
          </Button>
        </div>
        {show_Accordion && (
          <>
            <div className="d-flex">
              <div className="w-25">
                <div>Əmək məcəlləsinin maddəsi</div>
                <div className="mt-2">
                  <FormControl fullWidth>
                    <Select
                      native
                      defaultValue=""
                      id="grouped-native-select"
                      label="Grouping"
                      onChange={handleLabourItems}
                      multiple
                      value={leave_state?.clause}
                    >
                      {clauses?.map((l) => (
                        <optgroup label={l?.parent}>
                          {l?.childs?.map((c) => (
                            <option value={c}>{c}</option>
                          ))}
                        </optgroup>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="w-25 ms-2">
                <div>Əməkdaş</div>
                <div className="mt-2">
                  <FormControl fullWidth>
                    {/* <InputLabel id="demo-simple-select-label">
                                            Əməkdaş
                                        </InputLabel> */}
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={leave_state?.employee}
                      label="Age"
                      placeholder="ok"
                      onChange={(e) =>
                        set_leave_state({
                          ...leave_state,
                          employee: e.target.value,
                        })
                      }
                    >
                      <MenuItem value=" ">Seçin</MenuItem>
                      {employee?.length &&
                        employee?.map((e) => (
                          <MenuItem value={e?.id}>
                            {e?.firstname} {e?.lastname} {e?.fathername}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="w-50 ms-2">
                <div>Səbəbi</div>
                <div className="mt-2">
                  <TextField
                    onChange={(e) =>
                      set_leave_state({
                        ...leave_state,
                        reason: e.target.value,
                      })
                    }
                    value={leave_state?.reason}
                    fullWidth
                    placeholder="Səbəbi"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="w-25">
                <div>Başlama tarixi</div>
                <div className="mt-2">
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={leave_state?.start_date || null}
                    // maxDate={new Date()}
                    minDate={new Date(new Date().getTime() - 86400000 * 30)}
                    onChange={(date) =>
                      set_leave_state({ ...leave_state, start_date: date })
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </div>
              </div>
              <div className="w-25 ms-2">
                <div>Bitmə tarixi</div>
                <div className="mt-2">
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={leave_state?.end_date || null}
                    // maxDate={new Date()}
                    minDate={new Date(new Date().getTime() - 86400000 * 30)}
                    onChange={(date) =>
                      set_leave_state({ ...leave_state, end_date: date })
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="w-25 mt-3">
              <Button variant="contained" disabled={create_btn_disable}>
                Yarat
              </Button>
            </div>
          </>
        )}
        <div className="mt-4">
          <Table
            columns={allContColumn}
            data={all_leaves}
            limit={limit}
            setLimit={setLimit}
            setOffset={setOffset}
            isLoading={loading}
          />
        </div>
        <Dialog
          open={show_dialog}
          onClose={() => set_show_dialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Use pams's location service?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Let mas per help apps determine location. This means sending
              anonymous location data to mas, even when no apps are running.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => set_show_dialog(false)}>Agree</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </React.Fragment>
  );
}

// const styles = {
//   statisticContainer: {
//     display: "flex",
//     flexDirection: "row",
//     marginTop: 5,
//   },
//   endContractsContainer: {
//     display: "flex",
//     flexDirection: "row",
//     width: "auto",
//     height: "400px",
//     marginTop: "10px",
//   },
//   contractsSettingsCont: {
//     width: "20%",
//     borderRadius: 2,
//     padding: 2.5,
//     backgroundColor: "rgba(255, 255, 255, 0.1)",
//     marginRight: 2,
//   },
//   settingsHeader: { fontSize: 16 },
//   settingsLabelCont: {
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "flex-start",
//     alignItems: "center",
//     marginBottom: 2,
//     marginLeft: 2,
//   },
//   allContractsContainer: {
//     marginTop: 5,
//     width: "100%",
//   },
//   loadingContainer: {
//     width: "100%",
//     height: "100%",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   },
// };
