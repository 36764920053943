import { Grid, Typography } from "@mui/material";
import React from "react";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import ReplayCircleFilledIcon from "@mui/icons-material/ReplayCircleFilled";

const CardTotals = (props) => {
  const { totalRequests, unshowRerequested } = props;
  return (
    <>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <Grid sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <ErrorIcon color="primary" />
          <Typography>{totalRequests?.pending}</Typography>
        </Grid>
        <Grid sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <CheckCircleIcon color="success" />
          <Typography>{totalRequests?.approved}</Typography>
        </Grid>
        <Grid sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <DoNotDisturbOnIcon color="error" />
          <Typography>{totalRequests?.rejected}</Typography>
        </Grid>
        {!unshowRerequested && (
          <Grid sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <ReplayCircleFilledIcon color="warning" />
            <Typography>{totalRequests?.rerequested}</Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CardTotals;
