import { Accordion } from "@mui/material";
import styled from "styled-components";

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: "24px",
  borderRadius: "6px",
  padding: "6px",
  position: "relative",
  "&:before": {
    content: '""',
    display: "block",
    height: 0,
  },
}));
export default CustomAccordion;
