import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";

const AddChecklistItem = ({
  addItemDialog,
  setAddItemDialog,
  handleAddChecklistItem,
}) => {
  const [itemName, setItemName] = useState();

  return (
    <>
      <Dialog
        fullWidth
        open={addItemDialog}
        onClose={() => {
          setAddItemDialog(false);
          setItemName("");
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4">Checklist item əlavə et</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Item adı"
            type="text"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAddItemDialog(false);
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          <Button
            onClick={() => {
              setAddItemDialog(false);
              handleAddChecklistItem(itemName);
            }}
            color="primary"
          >
            Əlavə et
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddChecklistItem;
