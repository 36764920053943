import React, { useEffect, useState } from "react";
import { Field, FieldArray, Formik, Form } from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormHelperText,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { getCommonData } from "../../api/api";

const ViewInventories = ({ employeeById }) => {
  const [inventoryTypes, setInventoryTypes] = useState([]);
  const [inventoryItems, setInventoryItems] = useState([[]]);

  useEffect(() => {
    const handleInventory = async () => {
      const updatedInventoryItems = [];
      const inventoryTypesResponse = await getCommonData("inventoryTypes");

      setInventoryTypes(inventoryTypesResponse.data);

      for (let index = 0; index < employeeById.inventories.length; index++) {
        const item = employeeById.inventories[index];
        try {
          // Make the API request using the item's "parent" as a parameter
          const response = await getCommonData(
            "inventoryItems",
            "",
            item.parent
          );
          updatedInventoryItems.push(response.data);
        } catch (error) {
          console.log("Error fetching data for item at index", index, error);
        }
      }

      setInventoryItems(updatedInventoryItems);
    };
    handleInventory();
  }, []);

  const initialValues = {
    inventories: employeeById.inventories || [],
  };

  return (
    <>
      {employeeById ? (
        <Formik
          initialValues={{
            inventories: initialValues.inventories || [], // Set the initial laborActivity values from employeeById
          }}
        >
          {({
            values,
            isValid,
            errors,
            touched,
            isSubmitting,
            handleChange,
          }) => (
            <Form style={{ pointerEvents: "none" }}>
              <FieldArray name="inventories">
                {({ push, remove }) => (
                  <div>
                    {values.inventories.map((form, index) => (
                      <div key={index}>
                        <>
                          <Typography mt={3} mb={3} variant="h6">
                            {index + 1})İnventarları
                          </Typography>

                          {/* Inventory model and category */}
                          <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                            <Grid sx={{ width: "100%" }}>
                              <FormControl
                                error={
                                  errors?.inventories?.[index]?.itemType &&
                                  Boolean(
                                    errors?.inventories?.[index]?.itemType
                                  )
                                }
                                fullWidth
                              >
                                <InputLabel
                                  id={`inventories[${index}].itemType`}
                                >
                                  İnventar növü *
                                </InputLabel>
                                <Field
                                  as={Select}
                                  name={`inventories[${index}].itemType`}
                                  labelId={`inventories[${index}].itemType`}
                                  label="İnventar növü *"
                                  id={`inventories[${index}].itemType`}
                                  fullWidth
                                >
                                  <MenuItem
                                    selected
                                    key={index}
                                    value={
                                      employeeById?.inventories[index]?.itemType
                                    }
                                  >
                                    {employeeById?.inventories[index]?.itemType}
                                  </MenuItem>
                                </Field>
                                {errors?.inventories?.[index]?.itemType &&
                                  errors?.inventories?.[index]?.itemType && (
                                    <FormHelperText error>
                                      {errors?.inventories?.[index]?.itemType}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Grid>
                            <Grid sx={{ width: "100%" }}>
                              <FormControl
                                error={
                                  errors?.inventories?.[index]?.name &&
                                  Boolean(errors?.inventories?.[index]?.name)
                                }
                                fullWidth
                              >
                                <InputLabel id={`inventories[${index}].name`}>
                                  İnventarın modeli *
                                </InputLabel>
                                <Field
                                  as={Select}
                                  name={`inventories[${index}].name`}
                                  labelId={`inventories[${index}].name`}
                                  label="İnventarın modeli *"
                                  id={`inventories[${index}].name`}
                                  fullWidth
                                >
                                  <MenuItem
                                    selected
                                    key={index}
                                    value={
                                      employeeById?.inventories[index]?.name
                                    }
                                  >
                                    {employeeById?.inventories[index]?.name}
                                  </MenuItem>
                                </Field>
                              </FormControl>
                            </Grid>
                          </Grid>

                          {/* Inventory number and date */}
                          <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                            <Grid sx={{ width: "100%" }}>
                              <Field
                                type="text"
                                name={`inventories[${index}].number`}
                                as={TextField}
                                fullWidth
                                label="İnventar nömrəsi"
                                value={employeeById?.inventories[index]?.number}
                              />
                            </Grid>
                            <Grid sx={{ width: "100%" }}>
                              <Field name={`inventories[${index}].date`}>
                                {({ field, form }) => (
                                  <DatePicker
                                    {...field}
                                    label="İnventarın təhkim edilmə tarixi"
                                    inputFormat="dd/MM/yyyy"
                                    value={
                                      employeeById?.inventories[index]?.date ||
                                      null
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        helperText={
                                          form.errors?.inventories?.[index]
                                            ?.date
                                        }
                                        error={Boolean(
                                          form.errors?.inventories?.[index]
                                            ?.date
                                        )}
                                        fullWidth
                                      />
                                    )}
                                  />
                                )}
                              </Field>
                            </Grid>
                          </Grid>

                          <Grid sx={{ width: "50%" }}>
                            <Field
                              type="text"
                              name={`inventories[${index}].serialNumber`}
                              as={TextField}
                              fullWidth
                              label="İnventar seriya nömrəsi"
                              value={
                                employeeById?.inventories[index]?.serialNumber
                              }
                            />
                          </Grid>
                        </>
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default ViewInventories;
