import { Close, ExpandMore, Person, Start } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React, { useEffect } from "react";
import styled from "styled-components";
import {
  GROUP_NAME,
  LABOUR_CHANGE_INPUT_FIELDS,
  LABOUR_CONTRACTS_CUSTOM_RENDERS_METHODS,
} from "../../../../../components/dialogs/documentDialog/dialogConstants";
import { formatDateTime } from "../../../../../utils/helperFunctions";

import { Controller, useWatch } from "react-hook-form";
import { PUBLIC_URL } from "../../../../../constants";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;
const InputCont = styled.div`
  width: 100%;
  padding: 0px 20px;
  margin-bottom: 10px;
`;
const ErrorMsgContainer = styled.div`
  width: 100%;
  color: red;
  border-radius: 5px;
  padding: 5px;
`;

const CenterComponent = ({
  data,
  emps,
  setSelectedEmp,
  setSelectedCont,
  contValue,
  empValue,
  fetchContract,
  setFormValue,
  staffsList,
  watch,
  errors,
  control,
}) => {
  // const probationType = watch({
  //   name: "labour_period.probationPeriod.type",
  // });
  // const probationValue = watch({
  //   name: "labour_period.probationPeriod.value",
  // });

  // // Use useEffect to react to changes in these fields
  // useEffect(() => {
  //   if (probationType && probationValue) {
  //     // Set the form value combining both `a` and `b`
  //     setFormValue(
  //       "labour_period.probationPeriod.value",
  //       `${probationValue} ${probationType}`
  //     );
  //   }
  // }, [probationType, probationValue, setFormValue]);
  const individualInput = (
    <div style={{ width: "50%" }}>
      <Typography
        sx={{ paddingLeft: "10px", marginBottom: "10px" }}
        variant="h4"
      >
        Əmək müqaviləsinə dəyişiklik ediləcək əməkdaşı seçin:
      </Typography>
      <InputCont>
        {/* <SelectInput
          data={emps}
          itemName={[
            "employee.lastname",
            "employee.firstname",
            "employee.fathername",
            "*** - ",
            "employee.laborActivity.organizationName",
          ]}
          itemValue={"employee._id"}
          onChange={(val) => {
            setSelectedEmp(val);
          }}
          value={empValue}
        /> */}
        <FormControl fullWidth>
          <InputLabel id="employee-label">Əməkdaş</InputLabel>
          <Select
            label={"Əməkdaş"}
            labelId="employee-label"
            placeholder="Əməkdaş seçin..."
            value={empValue && empValue}
            onChange={(e) => {
              const val = e.target.value;
              setSelectedEmp(val);
            }}
          >
            <MenuItem hidden value={""}>
              Əməkdaş seçin...
            </MenuItem>
            {emps?.map(({ employee }) => (
              <MenuItem
                key={employee._id}
                value={employee._id}
                selected={employee._id === empValue}
              >{`${employee.lastname} ${employee.firstname} ${employee.fathername} - ${employee.laborActivity[0].organizationName}`}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ marginTop: "10px" }}>
          <InputLabel id="cont-label">Əmək müqavilələri</InputLabel>
          <Select
            disabled={!staffsList.length > 0}
            label={"Əmək müqavilələri"}
            labelId="cont-label"
            value={contValue}
            onChange={(e) => {
              const val = e.target.value;
              setSelectedCont(val);
              fetchContract(val);
            }}
          >
            {staffsList?.map(({ contract }) => (
              <MenuItem
                value={contract._id}
                selected={contract._id === empValue}
              >
                {contract.data.docNumber}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </InputCont>
      <div
        style={{
          width: "96%",
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "10px",
        }}
      >
        <IconButton
          onClick={() => window.open(`/employees/${empValue}/view`)}
          sx={{ borderRadius: "5px" }}
          disabled={!empValue}
        >
          <Person />
          <Typography>Əməkdaş haqqında</Typography>
        </IconButton>
      </div>
    </div>
  );

  const handlerOnGroup = () => {
    const uiParts = [];

    if (!data) return;

    for (const key in data) {
      const group = data[key];

      const values = Object.values(group)[0];
      const groupKey = Object.keys(group)[0];

      const utils =
        LABOUR_CONTRACTS_CUSTOM_RENDERS_METHODS["labour-contract"]?.[groupKey];

      uiParts.push(
        <Accordion sx={{ backgroundColor: "rgba(0,0,0,0.1)" }} key={groupKey}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "16px",
                color: "rgba(200,200,200,1)",
              }}
            >
              {GROUP_NAME[groupKey]}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ marginX: "10px" }}>
            {values?.map((item, index) =>
              renderField(
                {
                  ...item,
                  renderValue: utils && utils[item.key],
                },
                index,
                groupKey
              )
            )}
          </AccordionDetails>
        </Accordion>
      );
    }
    return uiParts;
  };

  const renderField = (field, index, groupKey) => {
    if (field.isHide || field?.key === "currentSalary") return;

    const label = field.label ? field.label : "__";
    const value = renderValue(field);

    return (
      <>
        <div style={{ marginTop: "5px", marginBottom: "5px" }}>
          <p style={{ color: "rgba(200,200,200,1)", marginBottom: "10px" }}>
            {label}
          </p>
        </div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            borderBottom: "1px solid rgba(255,255,255,0.1)",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "5px",
          }}
        >
          <p style={{ fontSize: "18px", margin: 0, width: "45%" }}>
            {value || "-"}
          </p>
          <Start
            sx={
              {
                // color: COLORS_STATUS.DARK_RED,
              }
            }
          />
          <div style={{ width: "45%", position: "relative", display: "flex" }}>
            <div>{handlerInput(field, groupKey)}</div>
            <IconButton
              onClick={() => {
                setFormValue(`${groupKey}.${field.key}.changed`, false);
                setFormValue(`${groupKey}.${field.key}.value`, "");
              }}
            >
              <Close />
            </IconButton>
          </div>
        </div>
      </>
    );
  };

  const handlerInput = (field, groupKey) => {
    const prop = field?.key ? LABOUR_CHANGE_INPUT_FIELDS[field.key] : undefined;

    if (!prop) return;
    const {
      type,
      options,
      validations,
      customFields,
      multiple = false,
    } = prop();
    switch (type) {
      case "select":
        return (
          <>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  onChange={(e) => {
                    onChange(e);
                    e.target.value !== ""
                      ? setFormValue(`${groupKey}.${field.key}.changed`, true)
                      : setFormValue(`${groupKey}.${field.key}.changed`, false);
                  }}
                  value={value}
                  sx={{ width: "100%" }}
                  error={
                    errors[groupKey]?.[field.key]?.value?.message?.length > 0
                  }
                >
                  {options?.map((item) => (
                    <MenuItem value={item.key}>{item.name}</MenuItem>
                  ))}
                </Select>
              )}
              name={`${groupKey}.${field.key}.value`}
            />
            {errors[groupKey]?.[field.key]?.value?.message && (
              <ErrorMsgContainer>
                {errors[groupKey][field.key]?.value?.message}
              </ErrorMsgContainer>
            )}
          </>
        );
      case "text":
        return (
          <Controller
            name={`${groupKey}.${field.key}.value`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <TextField
                  // value={state[groupKey][field.key].value}
                  // onChange={(e) => {
                  //   setState((prev) => ({
                  //     ...prev,
                  //     [groupKey]: {
                  //       ...prev[groupKey],
                  //       [field.key]: {
                  //         value: e.target.value,
                  //         changed: true,
                  //       },
                  //     },
                  //   }));
                  // }}
                  onChange={(e) => {
                    onChange(e);
                    e.target.value !== ""
                      ? setFormValue(`${groupKey}.${field.key}.changed`, true)
                      : setFormValue(`${groupKey}.${field.key}.changed`, false);
                  }}
                  value={value}
                  variant="standard"
                  error={
                    errors[groupKey]?.[field.key]?.value?.message?.length > 0
                  }
                  sx={{ width: "100%" }}
                />
                {errors[groupKey]?.[field.key]?.value?.message && (
                  <ErrorMsgContainer>
                    {errors[groupKey][field.key]?.value?.message}
                  </ErrorMsgContainer>
                )}
              </>
            )}
          />
        );
      case "number":
        return (
          <Controller
            name={`${groupKey}.${field.key}.value`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <TextField
                  // value={state[groupKey][field.key].value}
                  // onChange={(e) => {
                  //   setState((prev) => ({
                  //     ...prev,
                  //     [groupKey]: {
                  //       ...prev[groupKey],
                  //       [field.key]: { value: +e.target.value, changed: true },
                  //     },
                  //   }));
                  // }}
                  onChange={(e) => {
                    onChange(e);
                    e.target.value !== ""
                      ? setFormValue(`${groupKey}.${field.key}.changed`, true)
                      : setFormValue(`${groupKey}.${field.key}.changed`, false);
                  }}
                  value={value}
                  variant="standard"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  error={
                    errors[groupKey]?.[field.key]?.value?.message?.length > 0
                  }
                  sx={{ width: "100%" }}
                />
                {errors[groupKey]?.[field.key]?.value?.message && (
                  <ErrorMsgContainer>
                    {errors[groupKey][field.key].value.message}
                  </ErrorMsgContainer>
                )}
              </>
            )}
          />
        );
      case "date":
        return (
          <Controller
            name={`${groupKey}.${field.key}.value`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <TextField
                  // value={state[groupKey][field.key].value}
                  // onChange={(e) => {
                  //   setState((prev) => ({
                  //     ...prev,
                  //     [groupKey]: {
                  //       ...prev[groupKey],
                  //       [field.key]: { value: e.target.value, changed: true },
                  //     },
                  //   }));
                  // }}
                  onChange={(e) => {
                    onChange(e);
                    e.target.value !== ""
                      ? setFormValue(`${groupKey}.${field.key}.changed`, true)
                      : setFormValue(`${groupKey}.${field.key}.changed`, false);
                  }}
                  value={value}
                  variant="standard"
                  type="date"
                  error={
                    errors[groupKey]?.[field.key]?.value?.message?.length > 0
                  }
                  sx={{ width: "100%" }}
                />
                {errors[groupKey]?.[field.key]?.value?.message && (
                  <ErrorMsgContainer>
                    {errors[groupKey][field.key].value.message}
                  </ErrorMsgContainer>
                )}
              </>
            )}
          />
        );
      case "input-select":
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* First Controller for TextField */}
            <Controller
              name={`${groupKey}.${field.key}.${customFields[0]}`}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <TextField
                    onChange={(e) => {
                      onChange(e.target.value);
                      e.target.value !== ""
                        ? setFormValue(`${groupKey}.${field.key}.changed`, true)
                        : setFormValue(
                            `${groupKey}.${field.key}.changed`,
                            false
                          );
                    }}
                    value={value}
                    variant="outlined"
                    type="number"
                    error={
                      errors[groupKey]?.[field.key]?.customFields[0]?.message
                        ?.length > 0
                    }
                    sx={{ width: "50%" }}
                  />
                  {errors[groupKey]?.[field.key]?.customFields[0]?.message && (
                    <ErrorMsgContainer>
                      {errors[groupKey][field.key]?.customFields[0]?.message}
                    </ErrorMsgContainer>
                  )}
                </>
              )}
            />

            {/* Second Controller for Select */}
            <Controller
              name={`${groupKey}.${field.key}.${customFields[1]}`}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <Select
                    onChange={(e) => {
                      onChange(e);
                      e.target.value !== ""
                        ? setFormValue(`${groupKey}.${field.key}.changed`, true)
                        : setFormValue(
                            `${groupKey}.${field.key}.changed`,
                            false
                          );
                    }}
                    value={value}
                    sx={{ width: "50%" }}
                    error={
                      errors[groupKey]?.[field.key]?.customFields?.[1]?.message
                        ?.length > 0
                    }
                  >
                    {options?.map((item) => (
                      <MenuItem key={item.key} value={item.key}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors[groupKey]?.[field.key]?.customFields?.[1]
                    ?.message && (
                    <ErrorMsgContainer>
                      {errors[groupKey][field.key]?.customFields[1]?.message}
                    </ErrorMsgContainer>
                  )}
                </>
              )}
            />
          </div>
        );
      case "custom":
        return;
      case "time":
        return (
          <Controller
            name={`${groupKey}.${field.key}.value`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <TextField
                  type="time"
                  // value={state[groupKey][field.key].value}
                  // onChange={(e) => {
                  //   setState((prev) => ({
                  //     ...prev,
                  //     [groupKey]: {
                  //       ...prev[groupKey],
                  //       [field.key]: { value: e.target.value, changed: true },
                  //     },
                  //   }));
                  // }}
                  onChange={(e) => {
                    onChange(e);
                    e.target.value !== ""
                      ? setFormValue(`${groupKey}.${field.key}.changed`, true)
                      : setFormValue(`${groupKey}.${field.key}.changed`, false);
                  }}
                  value={value}
                  variant="standard"
                  error={
                    errors[groupKey]?.[field.key]?.value?.message?.length > 0
                  }
                  sx={{ width: "100%" }}
                />
                {errors[groupKey]?.[field.key]?.value?.message && (
                  <ErrorMsgContainer>
                    {errors[groupKey][field.key]?.value?.message}
                  </ErrorMsgContainer>
                )}
              </>
            )}
          />
        );
      case "date-date":
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Controller
              name={`${groupKey}.${field.key}.${customFields[0]}`}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <TextField
                    // value={state[groupKey][field.key][customFields[0]]}
                    // onChange={(e) => {
                    //   setState((prev) => ({
                    //     ...prev,
                    //     [groupKey]: {
                    //       ...prev[groupKey],
                    //       [field.key]: {
                    //         ...prev[field.key],
                    //         [customFields[0]]: e.target.value,
                    //         changed: true,
                    //       },
                    //     },
                    //   }));
                    // }}
                    onChange={(e) => {
                      onChange(e);
                      e.target.value !== ""
                        ? setFormValue(`${groupKey}.${field.key}.changed`, true)
                        : setFormValue(
                            `${groupKey}.${field.key}.changed`,
                            false
                          );
                    }}
                    value={value}
                    variant="outlined"
                    type="date"
                    error={
                      errors[groupKey]?.[field.key]?.customFields?.[0]?.message
                        ?.length > 0
                    }
                    sx={{ width: "50%" }}
                  />
                  {errors[groupKey]?.[field.key]?.[customFields?.[0]]
                    ?.message && (
                    <ErrorMsgContainer>
                      {errors[groupKey][field.key][customFields[0]]?.message}
                    </ErrorMsgContainer>
                  )}
                </>
              )}
            />
            <Controller
              name={`${groupKey}.${field.key}.${customFields[1]}`}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <TextField
                    // value={state[groupKey][field.key][customFields[1]]}
                    // onChange={(e) => {
                    //   setState((prev) => ({
                    //     ...prev,
                    //     [groupKey]: {
                    //       ...prev[groupKey],
                    //       [field.key]: {
                    //         ...prev[field.key],
                    //         [customFields[1]]: e.target.value,
                    //         changed: true,
                    //       },
                    //     },
                    //   }));
                    // }}
                    onChange={(e) => {
                      onChange(e);
                      e.target.value !== ""
                        ? setFormValue(`${groupKey}.${field.key}.changed`, true)
                        : setFormValue(
                            `${groupKey}.${field.key}.changed`,
                            false
                          );
                    }}
                    value={value}
                    variant="outlined"
                    type="date"
                    error={
                      errors[groupKey]?.[field.key]?.customFields?.[1]?.message
                        ?.length > 0
                    }
                    sx={{ width: "50%" }}
                  />
                  {errors[groupKey]?.[field.key]?.[customFields?.[1]]
                    ?.message && (
                    <ErrorMsgContainer>
                      {errors[groupKey][field.key][customFields[1]]?.message}
                    </ErrorMsgContainer>
                  )}
                </>
              )}
            />
          </div>
        );
      default:
        return (
          <Controller
            name={`${groupKey}.${field.key}.value`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <TextField
                  // value={state[groupKey][field.key].value}
                  // onChange={(e) => {
                  //   setState((prev) => ({
                  //     ...prev,
                  //     [field.key]: { value: e.target.value, changed: true },
                  //   }));
                  // }}
                  onChange={(e) => {
                    onChange(e);
                    e.target.value !== ""
                      ? setFormValue(`${groupKey}.${field.key}.changed`, true)
                      : setFormValue(`${groupKey}.${field.key}.changed`, false);
                  }}
                  value={value}
                  variant="standard"
                  error={
                    errors[groupKey]?.[field.key]?.value?.message?.length > 0
                  }
                  sx={{ width: "100%" }}
                />
                {errors[groupKey]?.[field.key]?.value?.message && (
                  <ErrorMsgContainer>
                    {errors[groupKey][field.key].value.message}
                  </ErrorMsgContainer>
                )}
              </>
            )}
          />
        );
    }
  };

  const renderValue = (field) => {
    if (!field.value) return "-";
    if (typeof field.value === "object") return;

    if (field.renderValue) {
      const render = field.renderValue;
      return render(field.value);
    }
    switch (field.type) {
      case "string":
        return field.value;
      case "date":
        if (!field.value) {
          return "__";
        }
        return formatDateTime(field.value);
      case "array":
        return "array";
      case "object":
        return "object";
      default:
        return field.value;
    }
  };

  return (
    <Container>
      {/* <InputCont>
        <ItemFlexCont>
          <ReactSelect _onChange={onChangeEmp} />
        </ItemFlexCont>
        <ItemFlexCont>
          <ReactSelect _onChange={onChangeCont} />
        </ItemFlexCont>
      </InputCont> */}

      <div style={{ width: "50%", overflow: "scroll", height: "100%" }}>
        {handlerOnGroup()}
      </div>
      {individualInput}
    </Container>
  );
};

export default CenterComponent;
