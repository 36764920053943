import React, { useEffect, useState } from "react";
import { Field, FieldArray, ErrorMessage, useFormikContext } from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Button,
  Checkbox,
  FormHelperText,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { getCommonData } from "../../api/api";
import { checkUniqueness } from "../../utils/checkUniquenessForEmployee";

const AcademicDegree = ({
  values,
  setSelectedType,
  setOpenAddDialog,
  submitted,
}) => {
  const [degreeName, setDegreeName] = useState([]);
  const [degreeIssuer, setDegreeIssuer] = useState([]);
  const { validateForm, setErrors, errors, setFieldValue } = useFormikContext();

  useEffect(() => {
    const handleAcademicDegrees = async () => {
      const degreeNameResponse = await getCommonData("academicDegrees");
      const degreeIssuerResponse = await getCommonData("academicDegreeIssuers");
      setDegreeName(degreeNameResponse.data);
      setDegreeIssuer(degreeIssuerResponse.data);
    };
    handleAcademicDegrees();
  }, [submitted]);

  const handleDegreeSelected = (item) => {
    const selectedStatus = degreeName.find((status) => {
      return status.name === item;
    });
    return selectedStatus;
  };

  return (
    <FieldArray name="academicDegrees">
      {({ push, remove }) => (
        <div>
          {values.academicDegrees &&
            values.academicDegrees.map((form, index) => (
              <div key={index}>
                <>
                  <Typography mt={3} mb={3} variant="h6">
                    {index + 1})Elmi dərəcə
                  </Typography>

                  {/* academicDegree type and issuer */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Grid sx={{ width: "100%" }}>
                      <FormControl
                        error={
                          errors?.academicDegrees?.[index]?.name &&
                          Boolean(errors?.academicDegrees?.[index]?.name)
                        }
                        fullWidth
                      >
                        <InputLabel id={`academicDegrees[${index}].name`}>
                          Elmi dərəcə *
                        </InputLabel>
                        <Field
                          as={Select}
                          required
                          name={`academicDegrees[${index}].name`}
                          labelId={`academicDegrees[${index}].name`}
                          label="Elmi dərəcə *"
                          id={`academicDegrees[${index}].name`}
                          defaultValue=""
                          onChange={(event) => {
                            checkUniqueness(
                              values.academicDegrees,
                              "name",
                              event,
                              setFieldValue,
                              `academicDegrees[${index}]`,
                              "elmi dərəcə",
                              handleDegreeSelected
                            );
                            if (errors?.academicDegrees?.[index]?.name) {
                              setErrors({
                                ...errors,
                                academicDegrees: [
                                  ...errors.academicDegrees.slice(0, index),
                                  {
                                    ...errors.academicDegrees[index],
                                    name: "",
                                  },
                                  ...errors.academicDegrees.slice(index + 1),
                                ],
                              });
                            }
                          }}
                          fullWidth
                          error={
                            errors?.academicDegrees?.[index]?.name &&
                            Boolean(errors?.academicDegrees?.[index]?.name)
                          }
                        >
                          {degreeName &&
                            degreeName
                              .sort((a, b) =>
                                a.name.localeCompare(b.name, "az")
                              )
                              .map((item, index) => (
                                <MenuItem key={index} value={item.name}>
                                  {item.name}
                                </MenuItem>
                              ))}
                          <Button
                            sx={{
                              mt: 3,
                              ml: 3,
                            }}
                            variant="outlined"
                            onClick={() => {
                              setOpenAddDialog(true);
                              setSelectedType([
                                "academicDegrees",
                                { name: "Elmi Dərəcələr" },
                              ]);
                            }}
                          >
                            Yeni elmi dərəcə əlavə et
                          </Button>
                        </Field>
                        {errors.academicDegrees?.[index]?.name &&
                          errors.academicDegrees?.[index]?.name && (
                            <FormHelperText error>
                              {errors.academicDegrees?.[index]?.name}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                    <Grid sx={{ width: "100%" }}>
                      <FormControl
                        error={
                          errors?.academicDegrees?.[index]?.issuer &&
                          Boolean(errors?.academicDegrees?.[index]?.issuer)
                        }
                        fullWidth
                      >
                        <InputLabel id={`academicDegrees[${index}].issuer`}>
                          Elmi dərəcəni verən müəssisə *
                        </InputLabel>
                        <Field
                          as={Select}
                          name={`academicDegrees[${index}].issuer`}
                          labelId={`academicDegrees[${index}].issuer`}
                          label="Elmi dərəcəni verən müəssisə *"
                          id={`academicDegrees[${index}].issuer`}
                          defaultValue=""
                          fullWidth
                          error={
                            errors?.academicDegrees?.[index]?.issuer &&
                            Boolean(errors?.academicDegrees?.[index]?.issuer)
                          }
                          onChange={async (e) => {
                            setFieldValue(
                              `academicDegrees[${index}].issuer`,
                              e.target.value
                            );
                            if (errors?.academicDegrees?.[index]?.issuer) {
                              setErrors({
                                ...errors,
                                academicDegrees: [
                                  ...errors.academicDegrees.slice(0, index),
                                  {
                                    ...errors.academicDegrees[index],
                                    issuer: "",
                                  },
                                  ...errors.academicDegrees.slice(index + 1),
                                ],
                              });
                            }
                          }}
                        >
                          {degreeIssuer &&
                            degreeIssuer
                              .sort((a, b) => a.localeCompare(b, "az"))
                              .map((item, index) => (
                                <MenuItem key={index} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                          <Button
                            sx={{
                              mt: 3,
                            }}
                            variant="outlined"
                            onClick={() => {
                              setOpenAddDialog(true);
                              setSelectedType([
                                "academicDegreeIssuers",
                                { name: "Elmi Dərəcəni verən müəssisələr" },
                              ]);
                            }}
                          >
                            Yeni elmi dərəcəni verən müəssisə əlavə et
                          </Button>
                        </Field>
                        {errors.academicDegrees?.[index]?.issuer &&
                          errors.academicDegrees?.[index]?.issuer && (
                            <FormHelperText error>
                              {errors.academicDegrees?.[index]?.issuer}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/*date  */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Grid sx={{ width: "100%" }}>
                      <Field name={`academicDegrees[${index}].date`}>
                        {({ field, form }) => {
                          return (
                            <DatePicker
                              {...field}
                              label="Elmi dərəcəni aldığı tarix *"
                              inputFormat="dd/MM/yyyy"
                              value={field.value || null}
                              maxDate={new Date()}
                              minDate={new Date(1940, 0, 1)}
                              onChange={(date) => {
                                form.setFieldValue(
                                  `academicDegrees[${index}].date`,
                                  date
                                );
                                if (errors?.academicDegrees?.[index]?.date) {
                                  setErrors({
                                    ...errors,
                                    academicDegrees: [
                                      ...errors.academicDegrees.slice(0, index),
                                      {
                                        ...errors.academicDegrees[index],
                                        date: "",
                                      },
                                      ...errors.academicDegrees.slice(
                                        index + 1
                                      ),
                                    ],
                                  });
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  helperText={
                                    form.errors?.academicDegrees?.[index]?.date
                                  }
                                  error={Boolean(
                                    form.errors?.academicDegrees?.[index]?.date
                                  )}
                                  fullWidth
                                />
                              )}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid sx={{ width: "100%" }}>
                      <Field
                        type="text"
                        name={`academicDegrees[${index}].note`}
                        as={TextField}
                        label="Qeyd"
                        fullWidth
                        error={
                          errors?.academicDegrees?.[index]?.note &&
                          Boolean(errors?.academicDegrees?.[index]?.note)
                        }
                        helperText={
                          errors?.academicDegrees?.[index]?.note &&
                          errors?.academicDegrees?.[index]?.note
                        }
                        onChange={async (e) => {
                          setFieldValue(
                            `academicDegrees[${index}].note`,
                            e.target.value
                          );
                          if (errors?.academicDegrees?.[index]?.note) {
                            setErrors({
                              ...errors,
                              academicDegrees: [
                                ...errors.academicDegrees.slice(0, index),
                                {
                                  ...errors.academicDegrees[index],
                                  note: "",
                                },
                                ...errors.academicDegrees.slice(index + 1),
                              ],
                            });
                          }
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Grid sx={{ width: "100%" }}>
                      <Field
                        type="text"
                        value={values.academicDegrees[index].value}
                        as={TextField}
                        name={`academicDegrees[${index}].value`}
                        label="Elmi dərəcənin dəyəri"
                        fullWidth
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "19px",
                              }}
                            >
                              &#8380;
                            </span>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </>

                <Button
                  sx={{ mt: 3 }}
                  variant="contained"
                  color="error"
                  type="button"
                  onClick={() => remove(index)}
                >
                  Elmi dərəcəni sil
                </Button>
              </div>
            ))}
          <Button
            sx={{ mt: 3 }}
            variant="contained"
            type="button"
            onClick={() => push({ value: "", name: "" })}
          >
            Elmi dərəcə əlavə et
          </Button>
        </div>
      )}
    </FieldArray>
  );
};

export default AcademicDegree;
