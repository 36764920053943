import React, { useContext, useEffect, useRef, useState } from "react";

import styled from "@emotion/styled";
import Modal from "@mui/material/Modal";
import { format } from "date-fns";
import { az } from "date-fns/locale";
import { useParams } from "react-router-dom";
import * as ticketApi from "../../api/ticket";
import { SocketContext } from "../../contexts/SocketContext";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import SendIcon from "@mui/icons-material/Send";
import {
  Avatar,
  Box,
  Card,
  Fab,
  Grid,
  Divider as MuiDivider,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import htmlParser from "html-react-parser";
import { AuthContext } from "../../contexts/JWTContext";

//#region styling

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const ChatContainer = styled(Grid)`
  width: 100%;
  height: 65vh;
`;

const ChatMain = styled(Grid)``;

const ChatMessages = styled.div`
  overflow-y: scroll;

  height: calc(65vh - 94px);
`;

const ChatMessage = styled.div`
  margin: 30px;
  text-align: ${(props) => props.position};
`;

const ChatMessageInner = styled.div`
  display: inline-block;
`;

const ChatMessageTime = styled(Typography)`
  text-align: right;
  opacity: 0.8;
`;

const ChatMessageAvatar = styled(Avatar)`
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const ChatMessageBubble = styled.div`
  display: inline-block;
  margin-right: auto;
  background: ${(props) =>
    props.highlighted
      ? props.theme.palette.secondary.main
      : props.theme.palette.action.hover};
  color: ${(props) =>
    props.highlighted
      ? props.theme.palette.common.white
      : props.theme.palette.text.primary};
  border-radius: 3px;
  padding: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
  ${(props) => props.theme.shadows[1]};
`;

const ChatMessageBubbleName = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;

const ChatInput = styled(Grid)`
  min-height: 94px;
  padding: ${(props) => props.theme.spacing(5)};
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
  bgcolor: "rgba(229, 231, 240, 0.83)",
  border: "1px solid #000",
  borderRadius: 7,
  boxShadow: 24,
  p: 4,
};

//#endregion

function ChatMessageComponent({
  name,
  message,
  time,
  attachments,
  avatar,
  position = "left",
}) {
  const formattedTime = format(new Date(time), "d MMMM yyyy HH:mm", {
    locale: az,
  });
  const [isImageOpened, setIsImageOpened] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const imageModalCloseHandler = () => {
    setIsImageOpened(false);
  };

  return (
    <ChatMessage position={position}>
      <ChatMessageInner>
        <ChatMessageAvatar alt="Lucy Lavender" src={avatar} />
        <ChatMessageBubble highlighted={position === "right"}>
          <Box>
            <ChatMessageBubbleName variant="body1">
              {name}
            </ChatMessageBubbleName>
          </Box>
          <Typography variant="body2">{message}</Typography>
          {attachments && attachments.length > 0 && (
            <Typography variant="body2">
              <span className="mb-2 ml-6 mr-6">
                <strong>Attachments</strong>: <br />
              </span>
              {attachments.map((attachment, index) => (
                <span
                  onClick={() => {
                    const getFile = async () => {
                      const image = await ticketApi.getFile(attachment);

                      setIsImageOpened(true);

                      setSelectedImage(`data:image/png;base64,${image.base64}`);
                    };

                    getFile();
                  }}
                  sx={{ cursor: "pointer" }}
                  key={index}
                >
                  {`${index + 1}. Image`}
                </span>
              ))}
            </Typography>
          )}
        </ChatMessageBubble>
        <ChatMessageTime variant="body2">{formattedTime}</ChatMessageTime>
      </ChatMessageInner>
      <Modal
        open={isImageOpened}
        onClose={imageModalCloseHandler}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img src={selectedImage} alt="Attachment" />
        </Box>
      </Modal>
    </ChatMessage>
  );
}
function ChatWindow({ replies: data }) {
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [message, setMessage] = useState("");

  const [replies, setReplies] = useState([]);
  const scrollableFeedRef = useRef(null);
  const [filesData, setFilesData] = useState([]);

  const socket = useContext(SocketContext);

  const scrollToBottom = () => {
    if (scrollableFeedRef.current) {
      const scrollableElement = scrollableFeedRef.current;
      scrollableElement.scrollTop = scrollableElement.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [replies]);

  const handleFileChange = async (event) => {
    const files = event.target.files;

    for (const file of files) {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        setFilesData((prevFiles) => [...prevFiles, reader.result]);
      };

      reader.onerror = (err) => {
        console.log("error");
      };
    }

    event.target.value = "";
  };

  const sendReplyHandler = async () => {
    try {
      const response = await ticketApi.addReply(id, {
        description: message,
        attachments: filesData,
      });

      setReplies(response.replies);
      setMessage("");
      setFilesData([]);
      scrollToBottom();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setReplies(data);
  }, [data]);

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      sendReplyHandler();
    }
  };

  useEffect(() => {
    if (!socket) return;

    socket.emit("join-room-client", id);
    socket.on("new-ticket-reply", (data) => {
      if (data.ticket === id) {
        setReplies((prev) => [
          ...prev,
          {
            date: new Date().toISOString(),
            ...data.reply,
          },
        ]);
      }
    });

    return () => {
      socket.emit("leave-room-client", id);
    };
  }, [id]);

  return (
    <ChatContainer container component={Card}>
      <ChatMain item xs={12} md={8} lg={20}>
        <ChatMessages ref={scrollableFeedRef}>
          {replies?.map((reply, index) => (
            <ChatMessageComponent
              key={index}
              name={reply.from.username}
              avatar="/static/img/avatars/avatar-2.jpg"
              message={htmlParser(reply.description)}
              attachments={reply.attachments}
              time={new Date(reply.date).toISOString()}
              position={reply.from.id === user.id ? "right" : "left"}
            />
          ))}
        </ChatMessages>

        <Divider />
        <ChatInput container>
          <Grid item style={{ flexGrow: 1 }}>
            <TextField
              variant="outlined"
              label="Mesaj yaz"
              fullWidth
              value={message}
              onKeyDown={handleKeyPress}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Box ml={2}>
              <label htmlFor="file-upload">
                <Fab
                  variant="contained"
                  component="span"
                  size="medium"
                  color={filesData.length > 0 ? "secondary" : "success"}
                  style={{
                    backgroundColor: filesData.length > 0 ? "" : undefined,
                    color: filesData.length > 0 ? "white" : undefined,
                  }}
                >
                  <FileUploadIcon />
                </Fab>
              </label>
              <input
                id="file-upload"
                type="file"
                hidden
                multiple
                onChange={handleFileChange}
                on
              />
              {/* {filesData && (
                <Typography variant="caption" sx={{ mt: 1 }}>
                {filesData.name}
              </Typography>
              )} */}
            </Box>
          </Grid>
          <Grid item>
            <Box ml={2}>
              <Fab
                color="primary"
                aria-label="add"
                size="medium"
                onClick={sendReplyHandler}
              >
                <SendIcon />
              </Fab>
            </Box>
          </Grid>
        </ChatInput>
      </ChatMain>
    </ChatContainer>
  );
}

function TicketingChat({ replies }) {
  return (
    <React.Fragment>
      <Divider my={1} />
      <ChatWindow replies={replies} />
    </React.Fragment>
  );
}

export default TicketingChat;
