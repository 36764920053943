// React
import { useEffect, useState } from "react";

// Material UI
import { Grid } from "@mui/material";

import DataTable from "../../../components/layouts/dataTable/DataTable";
import useAuth from "../../../hooks/useAuth";
import { getEmployeeByUserId } from "../../../api/api";
import Table from "../../../components/table/Table";
import Column from "../../../models/table/Column";

export default function MCTransportationTable() {
  const [totalRow, setTotalRow] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [rowData, setRowData] = useState([]);
  const [filters, setFilters] = useState([]);

  const { user } = useAuth();

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };

  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };

  useEffect(() => {
    if (user) {
      const getUser = async () => {
        try {
          setIsLoading(true);

          const response = await getEmployeeByUserId(user.id);
          setTotalRow(response.data.total);

          setRowData(
            response.data.transportVehicles?.map((item, index) => {
              return {
                id: item.registrationPlate,
                brand: item.brand,
                model: item.name,
                transporttype: item.transportationType,
                registrationcertificate: item.registrationNumber,
                registrationmark: item.registrationPlate,
                year: item.year,
                enginecapacity: item.engineCapacity,
                gearbox: item.transmissionType,
              };
            })
          );
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      };
      getUser();
    }
  }, [user]);

  const columns = [
    new Column({
      path: "brand",
      headerName: "Marka",
    }),
    new Column({
      path: "model",
      headerName: "Model",
    }),
    new Column({
      path: "transporttype",
      headerName: "Nəq.növü",
    }),
    new Column({
      path: "registrationcertificate",
      headerName: "N.V. qey. şəh.",
    }),
    new Column({
      path: "year",
      headerName: "Buraxılış ili",
    }),
    new Column({
      path: "enginecapacity",
      headerName: "Mühərrik həcmi",
    }),
    new Column({
      path: "gearbox",
      headerName: "Sürət qutusu",
    }),
  ];

  return (
    <>
      {rowData.length !== 0 ? (
        <Grid sx={{ width: "100%" }}>
          <Table
            columns={columns}
            data={rowData}
            totalRows={totalRow}
            offset={offset}
            setOffset={(value) => setOffset(value)}
            limit={limit}
            setLimit={(value) => setLimit(value)}
            isLoading={isLoading}
            applyFiltersHandler={applyFiltersHandler}
            cancelAppliedFilters={cancelAppliedFiltersHandler}
          />
        </Grid>
      ) : (
        <Grid
          sx={{
            width: "100%",
            fontSize: "40px",
            fontWeight: 600,
            textAlign: "center",
            marginTop: "20px",
            backgroundColor: "#233044",
            borderRadius: "10px",
          }}
        >
          Heç bir məlumat tapılmadı
        </Grid>
      )}
    </>
  );
}
