import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { removeEmptyStrings } from "../../utils/removeEmptyString";
import { getActiveStaffss } from "../../services/staff/StaffServices";

const FindEmployee = (props) => {
  const {
    findEmployeeDialog,
    setFindEmployeeDialog,
    selectedUser,
    setSelectedUser,
  } = props;

  const [employees, setEmployees] = useState([]);
  const [totalEmployee, setTotalEmployee] = useState();
  const [employeesPayload, setEmployeesPayload] = useState({
    limit: 5,
    skip: 0,
    filter: {
      employeeFirstName: "",
      employeeLastName: "",
      employeeFatherName: "",
    },
  });
  const [page, setPage] = useState(1);

  const fetchEmployees = async (payload) => {
    try {
      const { data } = await getActiveStaffss(payload);
      if (data) {
        setEmployees(data.records);
        setTotalEmployee(data.total);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchFilteredEmployees = async () => {
      const { limit, skip, filter } = employeesPayload;

      const datas = Object.entries(filter)
        .filter(([key, value]) => value.trim() !== "")
        .map(([key, value]) => ({
          column: key,
          operator: "equalsTo",
          value: value,
        }));

      const payload = { limit, offset: skip };
      if (datas.length > 0) {
        const filterString = JSON.stringify(datas);
        const encodedFilter = encodeURIComponent(filterString);
        payload.filter = encodedFilter;
      }

      await fetchEmployees(payload);
    };

    fetchFilteredEmployees();
  }, [employeesPayload]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    const [firstName, lastName, fatherName] = value
      .split(" ")
      .map((part) => part.trim());

    const newPayload = {
      ...employeesPayload,
      filter: {
        employeeFirstName: firstName || "",
        employeeLastName: lastName || "",
        employeeFatherName: fatherName || "",
      },
    };

    setEmployeesPayload(newPayload);
  };

  const handleClose = () => {
    setFindEmployeeDialog(false);
    // setSelectedUser();
    setEmployeesPayload({
      limit: 5,
      skip: 0,
      filter: {
        employeeFirstName: "",
        employeeLastName: "",
        employeeFatherName: "",
      },
    });
  };
  const handleCancel = () => {
    setFindEmployeeDialog(false);
    setSelectedUser();
    setEmployeesPayload({
      limit: 5,
      skip: 0,
      filter: {
        employeeFirstName: "",
        employeeLastName: "",
        employeeFatherName: "",
      },
    });
  };

  const hasEmployees =
    employees && employees.some((item) => item?.employee?.length > 0);

  return (
    <>
      <Dialog
        fullWidth
        open={findEmployeeDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" mb={-3}>
          <Typography variant="h4" mb={4}>
            Əməkdaşı seçin
          </Typography>
        </DialogTitle>
        <>
          <DialogContent>
            <Box my={3}>
              <TextField
                label="İstifadəçi axtar"
                onChange={handleSearchChange}
                variant="outlined"
                fullWidth
              />
            </Box>

            <List
              dense
              fullWidth
              sx={{
                maxHeight: "40vh",
                bgcolor: "background.paper",
                overflow: "auto",
                mb: 3,
              }}
            >
              {hasEmployees ? (
                employees.map((item, index) => {
                  if (item?.employee.length > 0) {
                    const employee = item.employee[0];
                    return (
                      <ListItem key={index} disablePadding>
                        <ListItemButton
                          selected={selectedUser?._id === item._id}
                          onClick={() => setSelectedUser(item)}
                        >
                          <ListItemAvatar>
                            <Avatar
                              alt={`Avatar n°${index + 1}`}
                              src={`/static/img/avatars/user.png`}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            id={item._id}
                            primary={`${employee.firstname} ${
                              employee.lastname
                            } ${employee.fathername} (${
                              item.position?.name || "---"
                            })`}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <Typography>Hal-hazırda əməkdaş yoxdur</Typography>
              )}
            </List>
            <Pagination
              mb={2}
              sx={{ display: "flex", justifyContent: "center" }}
              count={Math.ceil(totalEmployee / employeesPayload.limit)}
              variant="outlined"
              shape="rounded"
              page={page}
              color="secondary"
              onChange={(event, value) => {
                setPage(value);
                if (value !== page) {
                  setEmployeesPayload((prevState) => ({
                    ...prevState,
                    skip: (value - 1) * prevState.limit,
                  }));
                }
              }}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              Ləğv et
            </Button>
            <Button
              color="primary"
              onClick={handleClose}
              disabled={!selectedUser?._id}
            >
              Seç
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </>
  );
};

export default FindEmployee;
