/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";
// Material UI
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
// Services
import * as staffServices from "../../../services/staff/StaffServices";
// Helpers
import { STAFF_TIME } from "../../../constants";
import {
  blockInvalidChar,
  IconTextField,
} from "../../../utils/helperFunctions";
import { toast } from "react-toastify";
import { enqueueSnackbar } from "notistack";

const StaffDialog = ({
  classes,
  open,
  item,
  onClose,
  setIsUpdated,
  isUpdated,
  setActivePositions,
  getStructure,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [max_salary, set_max_salary] = useState(item?.max_salary);
  const validationSchema = Yup.object({
    staffUnit: Yup.string().required("Bu xana məcburidir"),
    positionSalary: Yup.number()
      .required("Bu xana məcburidir")
      // .min(300, "Minimum əmək haqqı 300 AZN")
      .max(max_salary, `Maksimum əmək haqqı ${max_salary} AZN`),
    count: Yup.number()
      .required("Bu xana məcburidir")
      .min(1, "Minimum say 1 ola bilər"),
  });
  const initialValues = {
    staffUnit: 0.25,
    positionSalary: 300,
    position: item?.part?._id,
    structure: id,
    part: item?._id,
    count: 1,
  }
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,

    onSubmit: async (data) => {
      try {
        const payload = {
          ...data,
          position: item?.part?._id,
          structure: id,
          part: item?._id,
        };
        const resp = await staffServices.addStaff(payload, enqueueSnackbar);
        if (resp && resp.length > 0) {
          let this_part = resp?.[0].part;
          // setActivePositions((activePosition) => {
          //   let positions = JSON.parse(JSON.stringify(activePosition?.positions));
          //   positions = positions?.map(p => {
          //     if(p?.child?._id === this_part) {
          //       let child = p?.child;
          //       let staff = p?.child?.staff;
          //       staff = staff?.concat(resp);
          //       child = {
          //         ...child,
          //         staff,
          //       };
          //       p = {
          //         ...p,
          //         child,
          //       };
          //     }
          //     return p;
          //   });
          //   activePosition.positions = positions;
          //   return activePosition;
          // });
          toast.success("Yeni ştat uğurla əlavə olundu!");
          formik?.resetForm(initialValues);
          getStructure();
          onClose();
          // setIsUpdated(!isUpdated);
          // const response = await structureServices.getStructureById(id);
          // if (response && response?._id) {
          //   dispatch(setStructureDataById(response));
          // }
          // window.location.reload();
        }
      } catch (error) {
        console.log("error: ", error);
      }
    },
  });

  useEffect(() => {
    let max_salary = formik.values.staffUnit * item?.max_salary;
    set_max_salary(max_salary)
  }, [formik, item?.max_salary]);

  const staffTime = STAFF_TIME;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      style={{ backgroundColor: "#ffffff21" }}
    >
      <DialogTitle
        id="form-dialog-title"
        className={`${classes.fs_23} ${classes.mb_2} text-center`}
      >
        Ştat əlavə edin
      </DialogTitle>
      <DialogContent style={{ minWidth: "600px" }}>
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-lg-12 mt-4">
              <FormControl variant="standard" fullWidth>
                <InputLabel
                  htmlFor="max-width"
                  id="demo-simple-select-standard-label"
                  style={
                    formik.touched?.staffUnit && formik.errors?.staffUnit
                      ? { color: "#f44336" }
                      : null
                  }
                >
                  Ştatı vahidi seçin*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  name="staffUnit"
                  label={`Ştatı vahidi seçin*`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(
                    formik.touched?.staffUnit && formik.errors?.staffUnit
                  )}
                  value={formik.values?.staffUnit}
                  className={
                    Boolean(
                      formik.touched?.staffUnit && formik.errors?.staffUnit
                    )
                      ? "error"
                      : ""
                  }
                >
                  {staffTime.length &&
                    staffTime.map((time, index) => (
                      <MenuItem key={index + 1} value={time.timeId}>
                        {time.content}
                      </MenuItem>
                    ))}
                </Select>
                {formik.touched?.staffUnit && (
                  <FormHelperText>
                    <span
                      style={
                        formik.touched?.staffUnit ? { color: "#f44336" } : null
                      }
                    >
                      {formik.errors?.staffUnit}
                    </span>
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className="col-lg-12 mt-4">
              <IconTextField
                id="standard-basic"
                label="Ştat üzrə əmək haqqı*"
                type="number"
                name="positionSalary"
                variant="standard"
                className={classes.input}
                iconEnd={
                  <span
                    className={classes.staffSalary}
                    style={
                      Boolean(
                        formik.touched?.positionSalary &&
                          formik.errors?.positionSalary
                      )
                        ? { color: "#f44336" }
                        : undefined
                    }
                  >
                    ₼
                  </span>
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onKeyDown={blockInvalidChar}
                error={Boolean(
                  formik.touched?.positionSalary &&
                    formik.errors?.positionSalary
                )}
                value={formik.values?.positionSalary}
                helperText={
                  formik.touched?.positionSalary &&
                  formik.errors?.positionSalary
                }
                fullWidth
              />
            </div>
            <div className="col-lg-12 mt-4">
              <TextField
                id="standard-basic"
                variant="standard"
                name="count"
                label={`Ştat sayı`}
                type="number"
                inputProps={{ maxLength: item?.max_salary }}
                className={classes.input}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                onKeyDown={blockInvalidChar}
                value={formik.values.count}
                defaultValue={1}
                error={Boolean(formik.touched?.count && formik.errors?.count)}
                helperText={formik.touched?.count && formik.errors?.count}
                fullWidth
              />
            </div>
            <div className="col-12 d-flex justify-content-end align-items-center mt-5">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!formik.isValid}
              >
                <span className="text-disabled">Təsdiqlə</span>
              </Button>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default StaffDialog;
