import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";

const AddCard = ({
  openAddCard,
  setOpenAddCard,
  handleAddList,
  columnId,
  setColumnId,
}) => {
  const [cardTitle, setCardTitle] = useState("");

  return (
    <>
      <Dialog
        fullWidth
        open={openAddCard}
        onClose={() => {
          setOpenAddCard(false);
          setCardTitle("");
          setColumnId();
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4">Yeni list əlavə et</Typography>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
          To subscribe to this website, please enter your email address
          here. We will send updates occasionally.
        </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            multiline
            rows={2}
            id="name"
            label="List başlığı"
            type="text"
            onChange={(e) => setCardTitle(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenAddCard(false);
              setCardTitle("");
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          <Button
            onClick={() => {
              setOpenAddCard(false);
              handleAddList(cardTitle, columnId);
            }}
            color="primary"
            disabled={cardTitle.trim() === ""}
          >
            Əlavə et
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddCard;
