export const initialState = {
  decisionChains: [],
  selectedDecisionChain: "",
  addDecisionChainDialog: false,
  allStructures: [],
  nestedChildren: [],
  structureId: "",
  organizationId: "",
  nestedChild: [],
  positionId: "",
  utilsEmployees: [],
  nestedChild1: null,
  approvementChain: [],
  rowId: "",
  deleteDecisionDialog: false,
  editDecisionDialog: false,
  viewDecisionDialog: false,
  submitted: false,
  loadingDialog: false,
};

export const decisionChainReducer = (state, action) => {
  switch (action.type) {
    case "SET_DECISION_CHAINS":
      return {
        ...state,
        decisionChains: action.payload,
      };
    case "SET_SELECTED_DECISION_CHAIN":
      return {
        ...state,
        selectedDecisionChain: action.payload,
      };
    case "SET_ADD_DECISION_CHAIN_DIALOG":
      return {
        ...state,
        addDecisionChainDialog: action.payload,
      };
    case "GET_ALL_STRUCTURES":
      return {
        ...state,
        allStructures: action.payload,
      };
    case "GET_NESTED_CHILDREN":
      return {
        ...state,
        nestedChildren: action.payload,
      };
    case "SET_STRUCTURE_ID":
      return {
        ...state,
        structureId: action.payload,
      };
    case "SET_ORGANIZATION_ID":
      return {
        ...state,
        organizationId: action.payload,
      };
    case "SET_NESTED_CHILD":
      return {
        ...state,
        nestedChild: action.payload,
      };
    case "SET_POSITION_ID":
      return {
        ...state,
        positionId: action.payload,
      };
    case "SET_UTILS_EMPLOYEES":
      return {
        ...state,
        utilsEmployees: action.payload,
      };
    case "SET_APPROVEMENT_CHAIN":
      return {
        ...state,
        approvementChain: action.payload,
      };
    case "SET_ROW_ID":
      return {
        ...state,
        rowId: action.payload,
      };
    case "SET_DELETE_DECISION_DIALOG":
      return {
        ...state,
        deleteDecisionDialog: action.payload,
      };
    case "SET_EDIT_DECISION_DIALOG":
      return {
        ...state,
        editDecisionDialog: action.payload,
      };
    case "SET_VIEW_DECISION_DIALOG":
      return {
        ...state,
        viewDecisionDialog: action.payload,
      };
    case "SET_SUBMITTED":
      return {
        ...state,
        submitted: action.payload,
      };
    case "SET_LOADING_DIALOG":
      return {
        ...state,
        loadingDialog: action.payload,
      };
    // Experimental
    case "SET_NESTED_CHILD_1":
      return {
        ...state,
        nestedChild1: action.payload,
      };
    default:
      return state;
  }
};
