import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import DocumentDialog from "../../../../components/dialogs/documentDialog/DocumentDialog";
import { getContract } from "../../../../services/documentations/ContractServices";
import { updateContract } from "../../../../services/labourContractUpdate/labourContractUpdateService";
import LeftComponent from "./parts/LeftComponent";
import StructureSelecting from "./parts/StructureSelecting";

const LabourContractUpdateSections = {
  labour_period: "labourPeriod",
  for_wage: "wage",
  for_protection: "protection",
  for_work_vacation_time: "workVacationTime",
  for_vacation: "vacation",
  physical_education_sport: "physicalEducationSport",
  social_protection: "socialProtection",
  regulation_property_relations: "regulationPropertyRelation",
};

export default function GeneralModal({ open, onClose }) {
  const [staffs, setStaffs] = useState([]);
  const [data, setData] = useState();
  const [changedFields, setChangedFields] = useState({
    labour_period: {
      contractPeriodType: { value: "", changed: false },
      probationPeriod: {
        type: "",
        value: "",
        changed: false,
      },
      probationRefusalTime: {
        type: "",
        value: "",
        changed: false,
      },
      reasonOfTemporaryContract: { value: "", changed: false },
      contractStartDate: { value: "", changed: false },
      contractEndDate: { value: "", changed: false },
      contractPeriod: { value: "", changed: false },
      jobStartDate: { value: "", changed: false },
    },
    for_wage: {
      currentSalary: { value: "", changed: false },
      wage: { value: "", changed: false },
      wageBonus: { type: "", value: "", changed: false },
      bonusPeriod: { value: "", changed: false },
      givenBonus: { value: "", changed: false },
      workingCondition: { value: "", changed: false },
      extraDueCondition: { value: "", changed: false },
      overtime: { value: "", changed: false },
      wageDayOfWeek: { value: "", changed: false },
      wageDaysOfMonth: { value: "", changed: false },
      wageDayOfMonth: { value: "", changed: false },
      employeeBankName: { start: "", end: "", changed: false },
      conditionsOfSalaryPayment: { value: "", changed: false },
    },
    for_protection: {
      providedProtectiveEquipment: { value: "", changed: false },
      providedFood: { value: "", changed: false },
      instructionalPeriod: { value: "", changed: false },
    },
    for_work_vacation_time: {
      workingMode: { value: "", changed: false },
      jobStartHour: { value: "", changed: false },
      jobEndHour: { value: "", changed: false },
      lunchStartHour: { value: "", changed: false },
      lunchEndHour: { value: "", changed: false },
      partTimeWorkHour: { value: "", changed: false },
      workTurnCount: { value: "", changed: false },
      firstTurnStartHour: { value: "", changed: false },
      firstTurnEndHour: { value: "", changed: false },
      secondTurnStartHour: { value: "", changed: false },
      secondTurnEndHour: { value: "", changed: false },
      thirdTurnStartHour: { value: "", changed: false },
      thirdTurnEndHour: { value: "", changed: false },
      fourthTurnStartHour: { value: "", changed: false },
      fourthTurnEndHour: { value: "", changed: false },
      providedCar: { value: "", changed: false },
      offDays: { value: "", changed: false },
    },
    for_vacation: {
      employeeJobYear: { start: "", end: "", changed: false },
      vacationDaysCount: { value: "", changed: false },
      forWorkConditions: { value: "", changed: false },
      forSeniorityLevel: { value: "", changed: false },
      forParental: { value: "", changed: false },
      forCollectiveAgreement: { value: "", changed: false },
      totalVacationDays: { value: "", changed: false },
      socialHouseholdBenefit: { value: "", changed: false },
      aboutEventOrPayer: { value: "", changed: false },
    },
    physical_education_sport: {
      physicalEducationAndSportsConditions: { value: "", changed: false },
      otherAdditionalConditions: { value: "", changed: false },
    },
    social_protection: {
      monthlyInsuranceFee: { value: "", changed: false },
      extraSumInsured: { value: "", changed: false },
    },
    regulation_property_relations: {
      shareOwnershipOfEnterprise: { value: "", changed: false },
      dividendAmount: { value: "", changed: false },
      personalPropertiesUsedByEmployee: { value: "", changed: false },
    },
  });
  const [contId, setContId] = useState("");

  useEffect(() => {
    contId && fetchCurrCont(contId);
  }, [staffs]);

  const onChange = (val) => {};

  const handleStaffsId = (arr) => {
    setStaffs(arr);
  };

  const fetchCurrCont = async (id) => {
    try {
      // setLoading(true);
      const { data } = await getContract(id, true);
      const modifiedData = modifingContForRender(data);
      setData(modifiedData);
    } catch (error) {
      console.log("err", error);
    } finally {
      // setLoading(false);
    }
  };

  const modifingContForRender = (data) => {
    const keys = Object.keys(LabourContractUpdateSections);
    const modifiedData = [];
    keys.map((item) => {
      modifiedData.push({ [item]: data.data[item] });
    });
    return modifiedData;
  };

  const handlerSubmit = async () => {
    try {
      const willSubmit = await swal({
        title: "Əminsiniz mi?",
        text: "Mövcud müqaviləyə dəyişiklik etməyə əminsiniz?",
        icon: "warning",
        dangerMode: true,
        buttons: ["Ləğv et", "Tətbiq et"],
      });

      if (willSubmit) {
        const payload = generateDataForEndPoint(changedFields);
        const resp = await updateContract(payload);
        if (resp) {
          swal(
            "Tamamlandı!",
            "Əmək müqavilələrinə dəyişiklik sorğusu göndərildi!",
            "success"
          );
        }
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const generateDataForEndPoint = (state) => {
    const obj = {
      staffs: [...staffs],
      updates: [],
    };

    for (const key in state) {
      if (Object.hasOwnProperty.call(state, key)) {
        let _obj = {};
        // const element = state[key];
        // element.map((item) => (_obj[item.key] = item.value));
        for (const k in state[key]) {
          if (state[key][k].changed === true) {
            _obj = {
              ..._obj,
              [k]: mutationSpecialFields(state[key][k].value, k),
            };
          }
        }

        if (Object.values(_obj).length > 0)
          obj.updates.push({
            section: keyGenerator(key),
            data: _obj,
          });
      }
    }

    return obj;
  };

  const keyGenerator = (key) => {
    // Vacation = "vacation",

    switch (key) {
      case "labour_period":
        return "labourPeriod";
      case "for_wage":
        return "wage";
      case "for_vacation":
        return "vacation";
      case "for_protection":
        return "protection";
      case "for_work_vacation_time":
        return "workVacationTime";
      case "physical_education_sport":
        return "physicalEducationSport";
      case "social_protection":
        return "SocialProtection";
      case "regulation_property_relations":
        return "regulationPropertyRelation";
      default:
        break;
    }
  };

  const mutationSpecialFields = (val, key) => {
    switch (key) {
      case "probationPeriodType":
      case "wage":
      case "givenBonus":
      case "extraDueCondition":
      case "overtime":
      case "forWorkConditions":
      case "forParental":
      case "socialHouseholdBenefit":
      case "monthlyInsuranceFee":
      case "extraSumInsured":
      case "shareOwnershipOfEnterprise":
      case "dividendAmount":
        return parseInt(val);
      default:
        return val;
    }
  };

  return (
    <DocumentDialog
      open={open}
      onClose={onClose}
      options={{ title: "Ə.M. kütləvi dəyişiklik" }}
      components={{
        rightComponent: (
          <StructureSelecting
            onSelect={handleStaffsId}
            contIdHandler={(id) => setContId(id)}
          />
        ),
        leftComponent: (
          <div
            style={{ width: "50%", paddingRight: "3px", overflow: "scroll" }}
          >
            <LeftComponent
              changedState={[changedFields, setChangedFields]}
              data={data}
            />
          </div>
        ),
      }}
      signingProps={{
        acceptBtnTitle: "Tətbiq et",
        rejectBtnTitle: "Ləğv et",
        handlerAccept: handlerSubmit,
        handlerReject: onClose,
      }}
      hideParts={{
        rightContainer: true,
      }}
    />
  );
}
