import React, { useEffect, useState } from "react";
import DocumentDialog from "../../../../components/dialogs/documentDialog/DocumentDialog";
import {
  BASE_URL,
  COLORS_STATUS,
  CONTRACTS_TYPE_DOC_MODELS_ENUMS,
} from "../../../../constants";
import { getContractUpdate } from "../../../../services/labourContractUpdate/labourContractUpdateService";
import FieldsContChange from "./parts/FieldsContChange";

function ContChangesModal({ open, id, fetchHandler = undefined, onClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [data, setData] = useState();
  const [status, setStatus] = useState();

  useEffect(() => {
    setIsLoading(true);
    id && getData(id);
  }, []);

  const getData = async (_id) => {
    try {
      setIsLoading(true);
      const { data } = fetchHandler
        ? await fetchHandler(_id, true)
        : await getContractUpdate(_id, true);
      if (id === _id) {
        // generateNavList(data);
      }
      data && setData(data);
      data && generateData(data);
    } catch (error) {
      console.log("err", error);
    } finally {
      setIsLoading(false);
    }
  };

  const generateData = (data) => {
    if (!data) return;
    // generateFields(data);
    // generateComments(data);
    generateStatus(data);
    // generateVersions(data);
  };

  const generateStatus = (data) => {
    setStatus(data.status);
  };

  const generateBgColor = () => {
    switch (status) {
      case "pending":
        return COLORS_STATUS.BLUE;
      case "approved":
        return COLORS_STATUS.GREEN;
      case "rejected":
        return COLORS_STATUS.RED;
      default:
        return COLORS_STATUS.GREY;
    }
  };

  const generateHeader = () => {
    const docType = "əmək müqaviləsinə dəyişiklik müqaviləsi";

    switch (status) {
      case "pending":
        return `İmza gözləyən ${docType}`;
      case "approved":
        return `Qüvvədə olan ${docType}`;
      case "rejected":
        return `İmtina edilmiş ${docType}`;
      default:
        return "Sorğu";
    }
  };

  return (
    <DocumentDialog
      open={open}
      onClose={onClose}
      loading={isLoading}
      components={{
        leftComponent: <FieldsContChange data={data?.data} />,
      }}
      // contractNo={data?.data.contract.docNumber}
      options={{
        bgColor: generateBgColor(),
        title: generateHeader(),
      }}
      signingProps={{
        hide: true,
      }}
      fieldsData={fields}
      isGroup={true}
      typeDoc={"labourContractUpdate"}
      downloadBtnProps={{
        generateHref: (value) =>
          `${BASE_URL}/employee/contract/${value}/download`,
        value: data?._id,
      }}
    />
  );
}

export default ContChangesModal;
