import styled from "@emotion/styled";
import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Card as MuiCard,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { spacing } from "@mui/system";
import VacationDialogs from "../dialog/VacationDialogs";
import PendingCard from "./card/PendingCard";
import ApproveCard from "./card/ApproveCard";
import RejectedCard from "./card/RejectedCard";
import ReRequestCard from "./card/ReRequestCard";
import { updateEmployeeRequest } from "../../../api/employeeRequests/employeeRequests";
import RequestShowDialog from "../../../components/dialogs/requestDialog/RequestShowDialog";
import RequestDialog from "../../../components/dialogs/documentDialog/RequestDialog";
import { getEmployeeRequest } from "../../../services/vacation/VacationRequest";

const Card = styled(MuiCard)(spacing);

const VacationCards = (props) => {
  const {
    cardTitles,
    totalRequests,
    limit,
    handlePagination,
    approver,
    requests,
    submitted,
    setSubmitted,
    isFromEmp = false,
  } = props;

  const { approved, pending, reRequested, rejected } = requests;

  const [handleVacationDialog, setHandleVacationDialog] = useState(false);

  const [dialogTitle, setdialogTitle] = useState("");
  const [dialogColor, setDialogColor] = useState();
  const [dialogContent, setDialogContent] = useState();
  const [id, setId] = useState("");

  const [approveDialog, setApproveDialog] = useState(false);
  const [rejectDialog, setRejectDialog] = useState(false);
  const [dialogDescription, setDialogDescription] = useState();

  const [pendingPagination, setPendingPagination] = useState(1);
  const [approvePagination, setApprovePagination] = useState(1);
  const [rejectPagination, setRejectPagination] = useState(1);
  const [rerequestPagination, setRerequestPagination] = useState(1);

  const [statusCode, setStatusCode] = useState();

  const requestType = "Məzuniyyət Sorğusu";

  const findStatus = (item, requestStatus) => {
    const findedRequest = {
      firstname: item.approvementChain.find(
        (employee) => employee.status === requestStatus
      )?.staff.employee[0].firstname,
      lastname: item.approvementChain.find(
        (employee) => employee.status === requestStatus
      )?.staff.employee[0].lastname,
      decisionDate: item.approvementChain.find(
        (employee) => employee.status === requestStatus
      )?.decisionDate,
    };

    return findedRequest;
  };

  const handleUpdateVacation = async (id, status, desc) => {
    try {
      const response = await updateEmployeeRequest(id, status, desc);
      setStatusCode(response);
      setSubmitted(!submitted);
      setHandleVacationDialog(false);
      setDialogDescription();

      if (response) {
        setPendingPagination(1);
        setApprovePagination(1);
        setRejectPagination(1);
        setRerequestPagination(1);
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const handleDialogId = (id) => {
    setId(id);
    setHandleVacationDialog(true);
  };
  return (
    <Grid container spacing={5}>
      {/*  sx={{ display: "flex", gap: 5 }} */}
      <Grid item xs={6} lg={3}>
        <PendingCard
          cardTitles={cardTitles}
          pendingRequests={pending.records}
          setHandleVacationDialog={setHandleVacationDialog}
          setdialogTitle={setdialogTitle}
          setDialogColor={setDialogColor}
          setDialogContent={setDialogContent}
          totalRequests={totalRequests}
          limit={limit}
          handlePagination={handlePagination}
          approver={approver}
          pendingPagination={pendingPagination}
          setPendingPagination={setPendingPagination}
          dialogHandler={handleDialogId}
        />
      </Grid>
      <Grid item xs={6} lg={3}>
        <ApproveCard
          cardTitles={cardTitles}
          approvedRequests={approved.records}
          setHandleVacationDialog={setHandleVacationDialog}
          setdialogTitle={setdialogTitle}
          setDialogColor={setDialogColor}
          setDialogContent={setDialogContent}
          totalRequests={totalRequests}
          limit={limit}
          handlePagination={handlePagination}
          approver={approver}
          approvePagination={approvePagination}
          setApprovePagination={setApprovePagination}
          dialogHandler={(id) => handleDialogId(id)}
        />
      </Grid>
      <Grid item xs={6} lg={3}>
        <RejectedCard
          cardTitles={cardTitles}
          rejectedRequests={rejected.records}
          setHandleVacationDialog={setHandleVacationDialog}
          setdialogTitle={setdialogTitle}
          setDialogColor={setDialogColor}
          setDialogContent={setDialogContent}
          totalRequests={totalRequests}
          limit={limit}
          handlePagination={handlePagination}
          findStatus={findStatus}
          approver={approver}
          rejectPagination={rejectPagination}
          setRejectPagination={setRejectPagination}
          dialogHandler={handleDialogId}
          updateRequest={handleUpdateVacation}
          isFromEmp={isFromEmp}
        />
      </Grid>
      <Grid item xs={6} lg={3}>
        <ReRequestCard
          cardTitles={cardTitles}
          reRequestedRequests={reRequested.records}
          setHandleVacationDialog={setHandleVacationDialog}
          setdialogTitle={setdialogTitle}
          setDialogColor={setDialogColor}
          setDialogContent={setDialogContent}
          totalRequests={totalRequests}
          limit={limit}
          handlePagination={handlePagination}
          findStatus={findStatus}
          approver={approver}
          rerequestPagination={rerequestPagination}
          setRerequestPagination={setRerequestPagination}
          dialogHandler={handleDialogId}
        />
      </Grid>

      {/* {dialogContent ? (
        <VacationDialogs
          handleVacationDialog={handleVacationDialog}
          setHandleVacationDialog={setHandleVacationDialog}
          dialogTitle={dialogTitle}
          dialogColor={dialogColor}
          dialogContent={dialogContent}
          requestType={requestType}
          approver={approver}
        >
          {dialogTitle === "Təsdiq Edilmiş Sorğu" &&
            dialogContent.approvementChain.map((item, index) => (
              <Grid key={index} mt={5}>
                <TextField
                  fullWidth
                  label={`Şərh (Təsdiqləyici - ${
                    item.employee?.firstname +
                    " " +
                    item.employee?.lastname +
                    " " +
                    item.employee?.position?.name
                  })`}
                  value={
                    item.description ? item.description : "Təsdiq gözləyir..."
                  }
                  variant="standard"
                  multiline
                  minRows={3}
                  maxRows={8}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            ))}
          {dialogTitle === "İmtina edilmiş / düzəltməli olduğum sorğu" && (
            <Grid mt={5}>
              <TextField
                fullWidth
                label={`Şərh (Yenidən yönləndirən) - ( ${
                  dialogContent.approvementChain.find(
                    (employee) => employee.status === "rejected"
                  )?.employee.firstname +
                  " " +
                  dialogContent.approvementChain.find(
                    (employee) => employee.status === "rejected"
                  )?.employee.lastname
                } )`}
                value={
                  dialogContent.approvementChain.find(
                    (employee) => employee.status === "rejected"
                  )?.description
                }
                variant="standard"
                multiline
                minRows={3}
                maxRows={8}
                InputProps={{
                  readOnly: true,
                }}
              />
              {dialogContent.hrNotes.map((item, index) => (
                <Box key={index} mt={5}>
                  <TextField
                    fullWidth
                    label={`HR - şərhi`}
                    value={item.description}
                    variant="standard"
                    multiline
                    minRows={3}
                    maxRows={8}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Box>
              ))}
            </Grid>
          )}
          {dialogTitle !== "Təsdiq Edilmiş Sorğu" &&
          dialogTitle !== "İmtina edilmiş / düzəltməli olduğum sorğu" &&
          approver ? (
            <Grid
              sx={{ display: "flex", justifyContent: "space-between", mt: 10 }}
            >
              <Button
                onClick={() => {
                  setApproveDialog(true);
                }}
                variant="contained"
                color="success"
                size="large"
              >
                Təsdiqlə
              </Button>
              <Button
                onClick={() => {
                  setRejectDialog(true);
                }}
                size="large"
                variant="contained"
                color="error"
              >
                İmtina Et
              </Button>
            </Grid>
          ) : null}
        </VacationDialogs>
      ) : null} */}

      {handleVacationDialog && (
        // <RequestShowDialog
        //   open={handleVacationDialog}
        //   onClose={() => setHandleVacationDialog(false)}
        //   updateRequestHandle={(id, status, desc) =>
        //     handleUpdateVacation(id, status, desc)
        //   }
        //   id={id}
        //   typeOfDialog={typeOfDialog}
        // />
        <RequestDialog
          open={handleVacationDialog}
          onClose={() => setHandleVacationDialog(false)}
          id={dialogContent._id}
          // id={id}
          // fetchHandler={getVacationRequest}
          handlerUpdateReq={handleUpdateVacation}
          fetchHandler={isFromEmp && getEmployeeRequest}
        />
      )}

      {/* Approve dialog */}
      <Dialog
        open={approveDialog}
        onClose={() => setApproveDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle
          variant="h3"
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "rgba(71, 109, 70, 0.7)",
          }}
        >
          Təsdiq
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "rgba(71, 109, 70, 0.7)" }}>
          <Typography>
            Xahiş olunur təsdiq olunan sorğunun subyektinin ad və soyadını qeyd
            edəsiniz.
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="description"
            variant="outlined"
            multiline
            onChange={(e) => {
              setDialogDescription(e.target.value);
            }}
            minRows={3}
            maxRows={8}
            type="text"
            fullWidth
            sx={{ borderColor: "rgba(71, 109, 70, 1)" }}
          />
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "rgba(71, 109, 70, 0.7)" }}>
          <Button
            onClick={() => setApproveDialog(false)}
            color="primary"
            sx={{ color: "white" }}
          >
            Geri qayıt
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleUpdateVacation(dialogContent._id, "approve");
              setApproveDialog(false);
            }}
            color="success"
          >
            Göndər
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reject dialog */}
      <Dialog
        open={rejectDialog}
        onClose={() => setRejectDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle
          variant="h3"
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "rgba(106, 17, 17, 0.7)",
          }}
        >
          Təsdiq
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "rgba(106, 17, 17, 0.7)" }}>
          <Typography>
            Xahiş olunur təsdiq olunan sorğunun subyektinin ad və soyadını qeyd
            edəsiniz.
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="description"
            variant="outlined"
            multiline
            onChange={(e) => {
              setDialogDescription(e.target.value);
            }}
            minRows={3}
            maxRows={8}
            type="text"
            fullWidth
            sx={{ borderColor: "rgba(106, 17, 17, 0.7)" }}
          />
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "rgba(106, 17, 17, 0.7)" }}>
          <Button
            onClick={() => setRejectDialog(false)}
            color="primary"
            sx={{ color: "white" }}
          >
            Geri qayıt
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleUpdateVacation(dialogContent._id, "reject");
              setRejectDialog(false);
            }}
            color="error"
          >
            Göndər
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default VacationCards;
