import React, { useState } from "react";
import { ToastContainer } from "react-toastify";

// Components
import Header from "../../components/layouts/header/Header";
import Breadcrumb from "../../components/layouts/breadcrumb/Breadcrumb";
import MyCabinetNavbar from "./MyCabinetNavbar";

// Material UI
import { Divider, Grid } from "@mui/material";
import SickIcon from "@mui/icons-material/Sick";

// CSS
import style from "./myProfileStyle.module.css";
import MCSickLeaveCertificateTable from "./SickLeaveCertificate/MCSickLeaveCertificateTable";
import Navbar from "./Navbar";

export default function SickLeaveCertificate() {
  const [navbarStatus, setNavbarStatus] = useState(false);

  const showHandler = () => setNavbarStatus(!navbarStatus);

  // Header data
  const headerData = {
    title: "Xəstəlik Vərəqələrim",
    variant: "h3",
  };

  const navbarStatusHandler = (currentValue) => {
    setNavbarStatus(currentValue);
  };

  return (
    <>
      <Grid
        className={style.navbarPlace}
        // onMouseEnter={showHandler}
        // onMouseLeave={showHandler}
      >
        {/* {navbarStatus === false ? (
          <Grid className={style.navbarProfile}>
            <SickIcon sx={{ width: "95%", height: "95%" }} />
          </Grid>
        ) : (
          <MyCabinetNavbar />
        )} */}
        <Navbar navbarStatus={navbarStatusHandler} />
      </Grid>
      <React.Fragment>
        <Grid>
          <Grid>
            <Grid>
              <Header data={headerData} />
            </Grid>
          </Grid>
          <ToastContainer />
          <Divider sx={{ marginTop: 5 }} />
          <Grid>
            <Grid
              className={
                style.infoSection +
                " " +
                (navbarStatus ? style.animation2 : style.animation1)
              }
            >
              <Grid className={style.vacationSecondSection}>
                <MCSickLeaveCertificateTable />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    </>
  );
}
