import React, { useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Paper, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../assets/logo/logoWithoutBorder.svg";
import GetOtpComponent from "../../components/auth/GetOtp";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 150px;
  height: 150px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function GetOtp() {
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const handleBackStep = () => {
    if (step === 0) {
      navigate("/auth/sign-in");
    } else if (step === 1) {
      setStep(0);
    } else if (step === 2) {
      setStep(1);
    }
  };
  return (
    <React.Fragment>
      <Brand />
      <Wrapper style={{ position: "relative" }}>
        <Helmet title="Reset Password" />
        <ArrowBackIosIcon onClick={handleBackStep} className="backArrowOtp" />
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Şifrəni unutdum
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          {step === 0 && "Zəhmət olmasa e-poçt ünvanını daxil edin"}
          {step === 1 && "Zəhmət olmasa OTP kodunu daxil edin"}
          {step === 2 && "Zəhmət olmasa yeni şifrəni daxil edin"}
        </Typography>

        <GetOtpComponent step={step} setStep={setStep} />
      </Wrapper>
    </React.Fragment>
  );
}

export default GetOtp;
