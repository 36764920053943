export const fieldOfActivity = {
  0: "01111 DƏNLİ (ÇƏLTİK İSTİSNA OLMAQLA) BİTKİLƏRİN BECƏRİLMƏSİ",
  1: "01112 PAXLALI BİTKİLƏRİN BECƏRİLMƏSİ",
  2: "01113 YAĞLI BİTKİLƏRİN VƏ ONLARIN TOXUMLARININ BECƏRİLMƏSİ",
  3: "01120 ÇƏLTİYİN BECƏRİLMƏSİ",
  4: "01131 TƏRƏVƏZ, BOSTAN, KÖKÜMEYVƏLİ VƏ KÖKÜYUMRULARIN BECƏRİLMƏSİ",
  5: "01132 ŞƏKƏR ÇUĞUNDURUNUN VƏ ONUN TOXUMUNUN BECƏRİLMƏSİ",
  6: "01133 GÖBƏLƏYİN VƏ YERDOMBALANIN BECƏRİLMƏSİ",
  7: "01134 KARTOFUN BECƏRİLMƏSİ",
  8: "01140 ŞƏKƏR QAMIŞININ BECƏRİLMƏSİ",
  9: "01150 TÜTÜNÜN BECƏRİLMƏSİ",
  10: "01161 LİFLİ BİTKİLƏRİN BECƏRİLMƏSİ",
  11: "01162 PAMBIĞIN BECƏRİLMƏSİ",
  12: "01191 YEM BİTKİLƏRİNİN VƏ ONLARIN TOXUMLARININ BECƏRİLMƏSİ",
  13: "01192 YABANI DƏRMAN BİTKİLƏRİ XAMMALININ YIĞILMASI",
  14: "01193 GÜLLƏRİN VƏ ONLARIN TOXUMLARININ BECƏRİLMƏSİ",
  15: "01199 DİGƏR BİRİLLİK BİTKİLƏRİN BECƏRİLMƏSİ",
  16: "01210 ÜZÜMÜN BECƏRİLMƏSİ",
  17: "01220 TROPİK VƏ SUBTROPİK MEYVƏLƏRİN BECƏRİLMƏSİ",
  18: "01230 SİTRUS MEYVƏLƏRİN BECƏRİLMƏSİ",
  19: "01241 TUMLU VƏ ÇƏYİRDƏKLİ MEYVƏLƏRİN BECƏRİLMƏSİ",
  20: "01242 QOZ-FINDIQ VƏ SAİR GİLƏMEYVƏ MƏHSULLARININ BECƏRİLMƏSİ",
  21: "01250 DİGƏR AĞACLARIN, KOL BİTKİLƏRİNİN VƏ QƏRZƏKLİ MEYVƏLƏRİN BECƏRİLMƏSİ",
  22: "01260 YAĞLI MEYVƏLƏRİN BECƏRİLMƏSİ",
  23: "01271 İÇKİ İSTEHSALI ÜÇÜN BİTKİLƏRİN BECƏRİLMƏSİ",
  24: "01272 ÇAY YARPAĞININ BECƏRİLMƏSİ",
  25: "01280 ƏDVİYYAT, ƏTRİYYAT VƏ DƏRMAN BİTKİLƏRİNİN BECƏRİLMƏSİ",
  26: "01290 DİGƏR ÇOXİLLİK BİTKİLƏRİN BECƏRİLMƏSİ",
  27: "01300 BİTKİLƏRİN ÇOXALDILMASI (ƏKİLMƏSİ)",
  28: "01410 SÜDLÜK İRİBUYNUZLU MAL-QARANIN YETİŞDİRİLMƏSİ",
  29: "01420 DİGƏR İRİBUYNUZLU MAL-QARANIN VƏ CAMIŞLARIN YETİŞDİRİLMƏSİ",
  30: "01430 AT, EŞŞƏK VƏ QATIRLARIN YETİŞDİRİLMƏSİ",
  31: "01440 DƏVƏLƏRİN VƏ MAYALARIN YETİŞDİRİLMƏSİ",
  32: "01450 QOYUN VƏ KEÇİLƏRİN YETİŞDİRİLMƏSİ",
  33: "01460 DONUZLARIN YETİŞDİRİLMƏSİ",
  34: "01471 EV (KƏND TƏSƏRRÜFATI) QUŞLARININ YETİŞDİRİLMƏSİ",
  35: "01472 YUMURTA İSTEHSALI",
  36: "01491 ARILARIN BECƏRİLMƏSİ, ARI BALININ VƏ ARI MUMUNUN İSTEHSALI",
  37: "01492 BARAMA QURDUNUN YETİŞDİRİLMƏSİ VƏ BARAMA İSTEHSALI",
  38: "01493 XƏZ DƏRİLİ HEYVANLARIN YETİŞDİRİLMƏSİ VƏ XƏZ DƏRİ İSTEHSALI",
  39: "01499 DİGƏR HEYVANLARIN YETİŞDİRİLMƏSİ",
  40: "01500 HEYVANDARLIQLA ƏLAQƏLİ BİTKİÇİLİK",
  41: "01611 BİTKİÇİLİK SAHƏSİNDƏ XİDMƏT GÖSTƏRİLMƏSİ",
  42: "01612 İRRİQASİYA SİSTEMİNİN İSTİSMARI",
  43: "01613 BİTKİÇİLİK SAHƏSİNDƏ DİGƏR XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  44: "01620 HEYVANDARLIQ SAHƏSİNDƏ XİDMƏTİN GÖSTƏRİLMƏSİ",
  45: "01630 KƏND TƏSƏRRÜFATI BİTKİLƏRİ MƏHSULLARININ EMALI ÜZRƏ XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  46: "01640 ƏKİLMƏYƏ (TOXUM FONDUNUN FORMALAŞDIRILMASINA) HAZIRLIQ ÜZRƏ XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  47: "01700 OVÇULUQ VƏ VƏHŞİ HEYVANLARIN YETİŞDİRİLMƏSİ VƏ BU SAHƏLƏRDƏ XİDMƏTİN GÖSTƏRİLMƏSİ",
  48: "02100 MEŞƏÇİLİK VƏ MEŞƏ TƏSƏRRÜFATI İLƏ BAĞLI SAİR FƏALİYYƏT",
  49: "02200 AĞAC TƏDARÜKÜ",
  50: "02301 YABANI BİTƏN QEYRİ-AĞAC MƏHSULLARININ YIĞILMASI",
  51: "02302 YABANI BİTƏN MEŞƏ GÖBƏLƏKLƏRİNİN VƏ YERDOMBALANLARININ, MEYVƏLƏRİNİN, GİLƏMEYVƏLƏRİN, QOZLARIN VƏ DİGƏR YEYİLƏN MEŞƏ MƏHSULLARININ YIĞILMASI",
  52: "02309 YABANI BİTƏN DİGƏR QEYRİ-AĞAC MEŞƏ MƏHSULLARININ YIĞILMASI",
  53: "02400 MEŞƏ TƏSƏRRÜFATI (MEŞƏ BECƏRMƏ VƏ AĞAC TƏDARÜKÜ) SAHƏSİNDƏ XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  54: "03110 DƏNİZ BALIQÇILIĞI",
  55: "03120 ÇAYLARDA, GÖLLƏRDƏ, SU ANBARLARINDA (ŞİRİN SULARDA) BALIQÇILIQ",
  56: "03210 DƏNİZ BALIQYETİŞDİRİLMƏSİ",
  57: "03220 ŞİRİN SU HÖVZƏLƏRİNDƏ BALIQYETİŞDİRMƏ",
  58: "05100 DAŞ KÖMÜRÜN HASİLATI",
  59: "05200 LİQNİTİN HASİLATI",
  60: "06100 XAM NEFTİN HASİLATI",
  61: "06200 TƏBİİ (YANAR) QAZ HASİLATI",
  62: "07100 DƏMİR FİLİZİNİN HASİLATI",
  63: "07210 URAN VƏ TORİUM FİLİZLƏRİNİN HASİLATI",
  64: "07291 QİYMƏTLİ METALLARIN VƏ QİYMƏTLİ DAŞLARIN İSTEHSALI (QİYMƏTLİ METALLARIN FİLİZLƏRDƏN, KONSENTRATLARDAN ALINMASI)",
  65: "07299 DİGƏR ƏLVAN METAL FİLİZLƏRİNİN HASİLATI",
  66: "08111 DEKORATİV VƏ TİKİNTİ DAŞLARININ HASİLATI",
  67: "08112 ƏHƏNG DAŞININ VƏ GİPSİN HASİLATI",
  68: "08113 TƏBAŞIR VƏ SUSUZLAŞDIRILMAMIŞ DELOMİTİN HASİLATI",
  69: "08114 ŞİST HASİLATI",
  70: "08121 ÇINQIL VƏ QUMUN HASİLATI",
  71: "08122 GİL VƏ KAOLİN HASİLATI",
  72: "08910 KİMYA SƏNAYESİ VƏ GÜBRƏ İSTEHSALI ÜÇÜN MİNERAL XAMMAL HASİLATI",
  73: "08920 TORF HASİLATI",
  74: "08930 DUZ HASİLATI",
  75: "08991 MƏDƏNÇIXARMA SƏNAYESİNİN DİGƏR QRUPLARINA DAXİL EDİLMƏYƏN SAİR SAHƏLƏRİ",
  76: "08992 TƏBİİ ASFALT VƏ BİTUM HASİLATI",
  77: "09101 NEFT VƏ TƏBİİ QAZ HASİLATI SAHƏSİNDƏ XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  78: "09102 NEFT VƏ TƏBİİ QAZIN HASİLATI ÜÇÜN QUYULARIN QAZILMASI",
  79: "09900 MƏDƏNÇIXARMA SƏNAYESİNİN DİGƏR SAHƏLƏRİNDƏ XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  80: "10110 ƏT İSTEHSALI VƏ KONSERVLƏŞDİRİLMƏSİ (QUŞ ƏTİNDƏN BAŞQA)",
  81: "10120 EV QUŞLARI VƏ DOVŞAN ƏTİNİN EMALI VƏ KONSERVLƏŞDİRİLMƏSİ",
  82: "10131 KOLBASA, SOSİSKA, SALYAMİ, PAŞTET, BEKON VƏ SAİR ƏT MƏMULATLARININ İSTEHSALI",
  83: "10132 ƏT, QUŞ VƏ EVDOVŞANI ƏTİNDƏN DİGƏR MƏMULATLARIN İSTEHSALI",
  84: "10201 BALIQ MƏHSULLARININ İSTEHSALI",
  85: "10202 BALIĞIN, XƏRÇƏNGKİMİLƏRİN VƏ MOLYUSKALARIN EMALI VƏ KONSERVLƏŞDİRİLMƏSİ",
  86: "10311 KARTOFUN EMALI VƏ KONSERVLƏŞDİRİLMƏSİ",
  87: "10312 KARTOF UNUNUN İSTEHSALI",
  88: "10320 MEYVƏ VƏ TƏRƏVƏZ ŞİRƏLƏRİNİN İSTEHSALI",
  89: "10391 DİGƏR QRUPLARA DAXİL EDİLMƏYƏN MEYVƏ VƏ TƏRƏVƏZLƏRİN KONSERVLƏŞDİRİLMƏSİ",
  90: "10392 MEYVƏ VƏ YA TƏRƏVƏZDƏN YEYİNTİ MƏHSULLARININ İSTEHSALI",
  91: "10393 CEM, MARMELAD VƏ JELENİN İSTEHSALI",
  92: "10411 TƏMİZLƏNMƏMİŞ YAĞ VƏ PİYLƏRİN İSTEHSALI",
  93: "10412 TƏMİZLƏNMİŞ YAĞ VƏ PİYLƏRİN İSTEHSALI",
  94: "10420 MARQARİN VƏ QARIŞDIRILMIŞ YEYİNTİ PİYLƏRİNİN İSTEHSALI",
  95: "10511 SÜD VƏ SÜD MƏHSULLARININ İSTEHSALI",
  96: "10512 PENDİR VƏ KƏSMİK İSTEHSALI",
  97: "10520 DONDURMA İSTEHSALI",
  98: "10611 BUĞDA EMALI SƏNAYESİ MƏHSULLARININ İSTEHSALI",
  99: "10612 DƏNLİ BİTKİLƏRDƏN UN VƏ YARMA İSTEHSALI",
  100: "10620 NİŞASTA VƏ NİŞASTA MƏHSULLARININ İSTEHSALI",
  101: "10710 ÇÖRƏK İSTEHSALI; UZUN MÜDDƏT SAXLANILA BİLMƏYƏN UNLU QƏNNADI MƏMULATLARININ İSTEHSALI",
  102: "10720 SUXARI VƏ PEÇENYE İSTEHSALI, UZUN MÜDDƏT SAXLANILA BİLƏN UNLU QƏNNADI MƏMULATLARININ İSTEHSALI",
  103: "10730 MAKARON, ƏRİŞTƏ VƏ OXŞAR MƏMULATLARIN İSTEHSALI",
  104: "10810 ŞƏKƏR (QƏND) İSTEHSALI",
  105: "10820 KAKAO, ŞOKOLAD VƏ ŞƏKƏRDƏN QƏNNADI MƏMULATLARININ İSTEHSALI",
  106: "10830 ÇAY VƏ KOFE İSTEHSALI (EMALI)",
  107: "10840 ƏDVİYYAT VƏ ŞİRƏLƏRİN İSTEHSALI",
  108: "10850 HAZIR YEMƏKLƏRİN İSTEHSALI",
  109: "10860 UŞAQ QİDALARININ VƏ PƏHRİZ ƏRZAQ MƏHSULLARININ İSTEHSALI",
  110: "10890 DİGƏR QRUPLARA DAXİL EDİLMƏYƏN SAİR QİDA MƏHSULLARININ İSTEHSALI",
  111: "10910 FERMALARDA SAXLANAN HEYVANLAR ÜÇÜN HAZIR YEMLƏRİN İSTEHSALI",
  112: "10920 EV HEYVANLARI ÜÇÜN HAZIR YEMLƏRİN İSTEHSALI",
  113: "11011 DİSTİLLƏ EDİLMİŞ VƏ TƏMİZLƏNMİŞ SPİRTLİ İÇKİLƏRİN İSTEHSALI",
  114: "11012 QICQIRMA MATERİALLARINDAN ETİL SPİRTİNİN İSTEHSALI",
  115: "11020 ÜZÜMDƏN ŞƏRAB İSTEHSALI",
  116: "11030 SİDR VƏ BAŞQA GİLƏMEYVƏ ŞƏRABLARININ İSTEHSALI",
  117: "11040 QICQIRMA MATERİALLARINDAN DİSTİLLƏ EDİLMƏMİŞ SAİR İÇKİLƏRİN İSTEHSALI",
  118: "11050 PİVƏ İSTEHSALI",
  119: "11060 SƏMƏNİ PİVƏSİNİN İSTEHSALI",
  120: "11071 MİNERAL SULAR VƏ DİGƏR SULARIN BUTULKALARDA İSTEHSALI",
  121: "11072 DİGƏR ALKOQOLSUZ İÇKİLƏRİN İSTEHSALI",
  122: "12000 TÜTÜN MƏMULATLARININ İSTEHSALI",
  123: "13101 PAMBIĞIN İLKİN EMALI",
  124: "13102 PAMBIQ PARÇA LİFİNİN ƏYİRİLMƏSİ",
  125: "13103 DARAMA MAŞININDA YUN LİFİNİN ƏYİRİLMƏSİ",
  126: "13104 KAMVOL YUN LİFİNİN ƏYİRİLMƏSİ",
  127: "13105 KƏTAN LİFİNİN ƏYİRİLMƏSİ",
  128: "13106 TƏBİİ İPƏK, SÜNİ VƏ SİNTETİK LİFLƏRİN HAZIRLANMASI",
  129: "13107 TİKİŞ SAPLARININ İSTEHSALI",
  130: "13108 SAİR TOXUCULUQ LİFLƏRİNİN HAZIRLANMASI VƏ ƏYİRİLMƏSİ",
  131: "13201 PAMBIQ PARÇALARIN İSTEHSALI",
  132: "13202 MAŞINLA DARANIB ƏYRİLMİŞ LİFLƏRDƏN YUN PARÇALARIN İSTEHSALI",
  133: "13203 EVDƏ DARANIB ƏYRİLMİŞ LİFLƏRDƏN YUN PARÇALARIN İSTEHSALI",
  134: "13204 İPƏK PARÇALARIN İSTEHSALI",
  135: "13300 PARÇALARIN BƏZƏDİLMƏSİ",
  136: "13910 TOXUCULUQ KƏTANLARININ İSTEHSALI",
  137: "13921 GEYİMDƏN BAŞQA HAZIR TOXUCULUQ MƏMULATLARININ İSTEHSALI",
  138: "13922 YATAQ, YEMƏK OTAĞI, TUALET VƏ MƏTBƏX AĞLARININ İSTEHSALI",
  139: "13930 XALÇA VƏ XALÇA MƏMULATLARININ İSTEHSALI",
  140: "13940 KANAT (BURAZ), KƏNDİR, İP VƏ TOR İSTEHSALI",
  141: "13950 TOXUNULMAYAN TOXUCULUQ MATERİALLARI VƏ ONLARDAN MƏMULATLARIN İSTEHSALI",
  142: "13960 TEXNİKİ ÜSULLA HAZIRLANAN DİGƏR TOXUCULUQ MƏMULATLARININ İSTEHSALI",
  143: "13990 DİGƏR QRUPLARA DAXİL EDİLMƏYƏN SAİR TOXUCULUQ MƏMULATLARININ İSTEHSALI",
  144: "14110 DƏRİDƏN GEYİM İSTEHSALI",
  145: "14120 XÜSUSİ (İŞ) GEYİMİN İSTEHSALI",
  146: "14130 ÜST GEYİMİN İSTEHSALI",
  147: "14140 ALT PALTARI İSTEHSALI",
  148: "14191 BAŞ GEYİMLƏRİNİN İSTEHSALI",
  149: "14199 SAİR GEYİM VƏ AKSESUARLARIN İSTEHSALI",
  150: "14200 XƏZ MƏMULATLARININ İSTEHSALI",
  151: "14311 TRİKOTAJ CORAB MƏMULATLARININ İSTEHSALI",
  152: "14312 TRİKOTAJ PULOVERLƏRİN, CEMPERLƏRİN VƏ BUNA BƏNZƏR MƏMULATLARIN İSTEHSALI",
  153: "14390 DİGƏR TRİKOTAJ MƏMULATLARININ İSTEHSALI",
  154: "15110 DƏRİNİN AŞILANMASI, RƏNGLƏNMƏSİ VƏ HAZIRLANMASI",
  155: "15120 DƏRİDƏN ÇAMADAN, ÇANTA VƏ DİGƏR MƏMULATLARIN İSTEHSALI",
  156: "15201 GÖN-DƏRİ AYAQQABILARIN İSTEHSALI",
  157: "15202 DİGƏR MATERİALLARDAN OLAN AYAQQABILARIN İSTEHSALI",
  158: "16100 TAXTA-ŞALBANIN KƏSİLMƏSİ VƏ YONULMASI, ODUNCAĞIN HOPDURULMASI",
  159: "16210 ŞPON, FANER VƏ AĞAC LÖVHƏLƏRİN İSTEHSALI",
  160: "16220 YIĞMA PARKET DÖŞƏMƏLƏRİN İSTEHSALI",
  161: "16230 AĞAC TİKİNTİ KONSTRUKSİYALARI VƏ XARRATLIQ MƏMULATLARININ İSTEHSALI",
  162: "16240 TAXTA TARALARIN İSTEHSALI",
  163: "16290 AĞAC VƏ MANTARDAN, NAZİK YONQARLARDAN VƏ HÖRMƏ ÜÇÜN MATERİALLARDAN SAİR MƏMULATLARIN İSTEHSALI",
  164: "17110 KAĞIZ KÜTLƏSİ (SELLÜLOZ) İSTEHSALI",
  165: "17120 KAĞIZ VƏ KARTON İSTEHSALI",
  166: "17210 BÜZMƏLƏNMİŞ KARTON VƏ KAĞIZ, KARTON VƏ KAĞIZ TARALARIN İSTEHSALI",
  167: "17220 TƏSƏRRÜFAT-MƏİŞƏT VƏ SANİTAR - GİGİYENİYA TƏYİNATLI KAĞIZ MƏMULATLARININ İSTEHSALI",
  168: "17230 DƏFTƏRXANA, KAĞIZ MƏMULATLARININ İSTEHSALI",
  169: "17240 DİVAR KAĞIZLARININ İSTEHSALI",
  170: "17290 KAĞIZ VƏ KARTONDAN SAİR MƏMULATLARIN İSTEHSALI",
  171: "18110 QƏZETLƏRİN ÇAP EDİLMƏSİ",
  172: "18121 DİGƏR POLİQRAFİYA MƏHSULLARININ (QƏZETLƏRDƏN ƏLAVƏ) ÇAP EDİLMƏSİ",
  173: "18122 ÇAP İŞLƏRİNİN YERİNƏ YETİRİLMƏSİ İLƏ ƏLAQƏDAR XİDMƏTLƏR",
  174: "18123 BÜTÜN NÖV QİYMƏTLİ KAĞIZ BLANKLARININ HAZIRLANMASI VƏ SATIŞI ÜZRƏ FƏALİYYƏT",
  175: "18124 CİDDİ HESABAT BLANKLARININ HAZIRLANMASI",
  176: "18130 ÇAP İŞLƏRİNİN HAZIRLANMASI",
  177: "18140 CİLDLƏMƏ VƏ BƏZƏK İŞLƏRİ",
  178: "18201 SƏS YAZILARININ SURƏTİNİN ÇIXARILMASI",
  179: "18202 VİDEOYAZILARIN SURƏTİNİN ÇIXARILMASI",
  180: "18203 MAŞIN DAŞIYICILARININ MƏLUMATLARININ SURƏTİNİN ÇIXARILMASI",
  181: "19100 KOKS İSTEHSALI",
  182: "19200 TƏMİZLƏNMİŞ NEFT MƏHSULLARININ İSTEHSALI, O CÜMLƏDƏN NEFT MƏHSULLARINDAN BRİKETLƏR",
  183: "20110 SƏNAYE QAZLARININ İSTEHSALI",
  184: "20120 BOYAQ MADDƏLƏRİ VƏ PİQMENTLƏRİN İSTEHSALI",
  185: "20130 SAİR ƏSAS QEYRİ-ÜZVİ KİMYƏVİ MADDƏLƏRİN İSTEHSALI",
  186: "20140 SAİR ƏSAS ÜZVİ KİMYƏVİ MADDƏLƏRİN İSTEHSALI",
  187: "20150 GÜBRƏ VƏ AZOT BİRLƏŞMƏLƏRİNİN İSTEHSALI",
  188: "20160 İLKİN FORMALARDA PLASTİK KÜTLƏ İSTEHSALI",
  189: "20170 İLKİN FORMADA SİNTETİK KAUÇUK İSTEHSALI",
  190: "20200 AQROKİMYƏVİ MƏHSULLARIN İSTEHSALI",
  191: "20300 BOYA, LAK VƏ BUNA BƏNZƏR ÖRTÜKLƏRİN, MƏTBƏƏ BOYALARININ VƏ MASTİKALARININ İSTEHSALI",
  192: "20410 SABUN VƏ YUYUCU TOZLARIN, TƏMİZLƏYİCİ VƏ CİLALAYICI VASİTƏLƏRİN İSTEHSALI",
  193: "20420 ƏTRİYYAT VƏ KOSMETİKA VASİTƏLƏRİNİN İSTEHSALI",
  194: "20510 PARTLAYICI MADDƏLƏRİN İSTEHSALI",
  195: "20520 YAPIŞQAN İSTEHSALI",
  196: "20530 EFİR YAĞLARININ İSTEHSALI",
  197: "20591 FOTOMATERİALLARIN İSTEHSALI",
  198: "20599 DİGƏR QRUPLARA DAXİL EDİLMƏMİŞ SAİR KİMYƏVİ MƏHSULLARIN İSTEHSALI",
  199: "20600 SÜNİ VƏ SİNTETİK KİMYƏVİ LİFLƏRİN İSTEHSALI",
  200: "21100 ƏSAS ƏCZAÇILIQ MƏHSULLARININ İSTEHSALI",
  201: "21200 ƏCZAÇILIQ PREPARATLARININ VƏ MATERİALLARININ İSTEHSALI",
  202: "22110 REZİN ŞİN, TƏKƏR VƏ KAMERALARIN İSTEHSALI VƏ BƏRPASI",
  203: "22191 REZİNDƏN SƏNAYE TƏYİNATLI MƏHSULLARIN VƏ YARIMFABRİKATLARIN İSTEHSALI",
  204: "22192 REZİN MƏMULATLARIN İSTEHSALI",
  205: "22193 MÜXTƏLİF NÖV MÖHÜRLƏRİN VƏ ŞTAMPLARIN HAZIRLANMASI",
  206: "22210 PLASTİK LÖVHƏLƏRİN, ZOLAQLARIN, BORULARIN, PROFİLLƏRİN İSTEHSALI",
  207: "22220 ƏMTƏƏLƏRİN QABLAŞDIRILMASI ÜÇÜN PLASTİK MƏMULATLARIN İSTEHSALI",
  208: "22230 TİKİNTİDƏ İSTİFADƏ EDİLƏN PLASTİK KÜTLƏDƏN MƏMULATLARIN İSTEHSALI",
  209: "22290 SAİR PLASTİK MƏMULATLARIN İSTEHSALI",
  210: "23110 ŞÜŞƏ TƏBƏQƏSİNİN İSTEHSALI",
  211: "23120 ŞÜŞƏ TƏBƏQƏSİNİN FORMALAŞDIRILMASI VƏ EMALI",
  212: "23130 İÇİBOŞ ŞÜŞƏ MƏMULATLARININ İSTEHSALI",
  213: "23140 ŞÜŞƏ LİFİN İSTEHSALI",
  214: "23190 TEXNİKİ ŞÜŞƏ MƏMULATLARI DAXİL OLMAQLA SAİR ŞÜŞƏ MƏMULATLARIN İSTEHSALI VƏ EMALI",
  215: "23200 ODADAVAMLI MƏMULATLARIN İSTEHSALI",
  216: "23310 SAXSI LÖVHƏCİKLƏRİN VƏ LÖVHƏLƏRİN İSTEHSALI",
  217: "23320 KƏRPİC, KİRƏMİT VƏ YANDIRILMIŞ GİLDƏN SAİR TİKİNTİ MƏMULATLARININ İSTEHSALI",
  218: "23410 TƏSƏRRÜFAT VƏ DEKORATİV SAXSI MƏMULATLARIN IN İSTEHSALI",
  219: "23420 SAXSI SANİTAR - TEXNİKİ MƏMULATLARIN İSTEHSALI",
  220: "23430 SAXSI İZOLYATORLARIN VƏ SAXSI İZOLYASİYA ARMATURUNUN İSTEHSALI",
  221: "23440 SAİR TEXNİKİ SAXSI MƏMULATLARIN İSTEHSALI",
  222: "23490 SAİR SAXSI MƏMULATLARIN İSTEHSALI",
  223: "23510 SEMENT İSTEHSALI",
  224: "23520 ƏHƏNG VƏ GİPS İSTEHSALI",
  225: "23610 TİKİNTİDƏ İSTİFADƏ ETMƏK ÜÇÜN BETONDAN MƏMULATLARIN İSTEHSALI",
  226: "23620 TİKİNTİDƏ İSTİFADƏ ETMƏK ÜÇÜN GİPS MƏMULATLARININ İSTEHSALI",
  227: "23630 ƏMTƏƏ BETONUNUN İSTEHSALI (HAZIR BETON QARIŞIĞI)",
  228: "23640 QURU BETON QATIŞIĞININ İSTEHSALI",
  229: "23650 LİFLİ SEMENTDƏN (AZBESTSEMENTDƏN) MƏMULATLARIN İSTEHSALI",
  230: "23690 BETON, GİPS VƏ SEMENTDƏN SAİR MƏMULATLARIN İSTEHSALI",
  231: "23700 DEKORATİV VƏ TİKİNTİ DAŞLARININ KƏSİLMƏSİ, EMALI VƏ NAXIŞLANMASI",
  232: "23910 ABRAZİV MƏMULATLARIN İSTEHSALI",
  233: "23990 DİGƏR QRUPLARA DAXİL EDİLMƏYƏN SAİR QEYRİ-METAL MİNERAL MƏHSULLARIN İSTEHSALI",
  234: "24100 ÇUQUN, POLAD VƏ FERROƏRİNTİ İSTEHSALI",
  235: "24200 POLADDAN BORU, İÇİ BOŞ PROFİLLƏR VƏ BİRLƏŞDİRİCİ HİSSƏLƏRİN İSTEHSALI",
  236: "24310 SOYUQ DARTMA",
  237: "24320 ENSİZ ZOLAQLARIN VƏ LENTLƏRİN SOYUQ YAYILMASI",
  238: "24330 MƏHSULLARIN SOYUQ ŞƏKİLDƏ ŞTAMPLANMASI VƏ ƏYİLMƏSİ",
  239: "24340 MƏFTİL İSTEHSALI",
  240: "24410 NƏCİB (QİYMƏTLİ) METALLARIN İSTEHSALI",
  241: "24420 ALÜMİNİUM İSTEHSALI",
  242: "24430 QURĞUŞUN, SİNK VƏ QALAY İSTEHSALI",
  243: "24440 MİS İSTEHSALI",
  244: "24450 SAİR ƏLVAN METALLARIN İSTEHSALI",
  245: "24460 NÜVƏ YANACAĞININ EMALI",
  246: "24510 ÇUQUN TÖKMƏ",
  247: "24520 POLAD TÖKMƏ",
  248: "24530 YÜNGÜL METALLARIN TÖKÜLMƏSİ",
  249: "24540 SAİR ƏLVAN METALLARIN TÖKÜLMƏSİ",
  250: "25110 METALDAN TİKİNTİ KONSTRUKSİYALARI VƏ ONLARIN HİSSƏLƏRİNİN İSTEHSALI",
  251: "25120 METAL QAPI VƏ PƏNCƏRƏLƏRİN İSTEHSALI",
  252: "25210 MƏRKƏZİ İSTİLİK RADİATORLARININ VƏ QAZANLARININ İSTEHSALI",
  253: "25290 METALDAN SAİR SİSTERNLƏRİN, ÇƏNLƏRİN VƏ KONTEYNERLƏRİN İSTEHSALI",
  254: "25300 MƏRKƏZİ İSTİLİK QAZANLARINDAN BAŞQA BUXAR QAZANLARININ İSTEHSALI",
  255: "25400 SİLAH VƏ HƏRBİ SURSAT İSTEHSALI",
  256: "25500 METALIN DÖYÜLMƏSİ, PRESLƏNMƏSİ, ŞTAMPLANMASI VƏ PROFİLLƏNMƏSİ, TOZ METALLURGİYASI",
  257: "25610 METALLARIN EMALI VƏ METALLARA ÖRTÜK ÇƏKİLMƏSİ",
  258: "25620 MAŞINQAYIRMANIN ƏSAS TEXNOLOJİ PROSESLƏRİ",
  259: "25710 BIÇAQ MƏMULATLARININ İSTEHSALI",
  260: "25720 QIFIL VƏ HƏNCAMA İSTEHSALI",
  261: "25730 ALƏTLƏRİN İSTEHSALI",
  262: "25910 METAL ÇƏLLƏKLƏRİN VƏ BUNA BƏNZƏR TUTUMLULARIN İSTEHSALI",
  263: "25920 YÜNGÜL METALLARDAN QABLAŞDIRMA MATERİALLARININ İSTEHSALI",
  264: "25930 MƏFTİL MƏMULATLARININ İSTEHSALI",
  265: "25940 BƏRKİDİCİ MƏMULATLARIN, ZƏNCİRLƏRİN VƏ YAYLARIN İSTEHSALI",
  266: "25990 SAİR HAZIR METAL MƏMULATLARININ İSTEHSALI",
  267: "26110 ELEKTRON DETALLARININ İSTEHSALI",
  268: "26120 ELEKTRON PLATALARININ İSTEHSALI",
  269: "26200 KOMPÜTERLƏRİN VƏ PERİFERİYA QURĞULARININ İSTEHSALI",
  270: "26301 ELEKTRO VƏ RADİOELEMENTLƏRİN İSTEHSALI",
  271: "26302 ÖTÜRÜCÜ CİHAZLARIN İSTEHSALI",
  272: "26303 SƏSİ VƏ TƏSVİRİ QƏBUL ETMƏK, YAZMAQ VƏ GÖSTƏRMƏK ÜÇÜN CİHAZLARIN İSTEHSALI",
  273: "26304 ƏMƏLİYYAT-AXTARIŞ FƏALİYYƏTİNİN HƏYATA KEÇİRİLMƏSİNƏ MÜVƏKKİL EDİLMƏMİŞ HÜQUQİ VƏ FİZİKİ ŞƏXSLƏR TƏRƏFİNDƏN QEYRİ LEQAL YOLLA İNFORMASİYA ALINMASI ÜÇÜN NƏZƏRDƏ TUTULMUŞ TEXNİKİ VASİTƏLƏRİN İŞLƏNİLMƏSİ, İSTEHSALI",
  274: "26400 MƏİŞƏT ELEKTRON AVADANLIQLARININ İSTEHSALI",
  275: "26510 NƏZARƏT-ÖLÇÜ CİHAZLARININ İSTEHSALI",
  276: "26520 BÜTÜN NÖVLƏRDƏN OLAN SAATLARIN İSTEHSALI",
  277: "26600 ŞUALANDIRMA, ELEKTRİK TİBB VƏ ELEKTROTERAPİYA AVADANLIQLARI",
  278: "26700 OPTİK CİHAZLARIN VƏ FOTOAVADANLIQLARIN İSTEHSALI",
  279: "26800 MAQNİT VƏ OPTİK DAŞIYICILARIN İSTEHSALI",
  280: "27111 ELEKTRİK MÜHƏRRİKLƏRİNİN, GENERATORLARININ VƏ TRANSFORMATORLARIN İSTEHSALI",
  281: "27112 ELEKTRİK MÜHƏRRİKLƏRİNİN, GENERATORLARININ VƏ TRANSFORMATORLARININ TƏMİRİ",
  282: "27120 ELEKTRİK PAYLAYICI VƏ TƏNZİMLƏYİCİ CİHAZLARININ İSTEHSALI",
  283: "27200 BATAREYALARIN VƏ AKKUMULYATORLARIN İSTEHSALI",
  284: "27310 OPTİK LİFLƏRDƏN KABEL İSTEHSALI",
  285: "27320 DİGƏR ELEKTRON VƏ ELEKTRİK NAQİLLƏRİNİN VƏ KABELLƏRİN İSTEHSALI",
  286: "27330 ELEKTRİK ŞƏBƏKƏLƏRİ ÜÇÜN CİHAZLARIN İSTEHSALI",
  287: "27400 ELEKTRİK LAMPALARI VƏ İŞIQLANDIRICI AVADANLIQLARIN İSTEHSALI",
  288: "27511 ELEKTRİK MƏİŞƏT CİHAZLARININ İSTEHSALI",
  289: "27512 ELEKTRİK QIZDIRICI MƏİŞƏT CİHAZLARININ İSTEHSALI",
  290: "27520 QEYRİ-ELEKTRİK MƏİŞƏT CİHAZLARININ İSTEHSALI",
  291: "27900 SAİR ELEKTRİK AVADANLIQLARININ İSTEHSALI",
  292: "28110 TƏYYARƏ, AVTOMOBİL VƏ MOTOSİKLET MÜHƏRRİKLƏRİNDƏN BAŞQA, MÜHƏRRİKLƏRİN VƏ TURBİNLƏRİN İSTEHSALI",
  293: "28120 HİDRAVLİK VƏ PNEVMATİK SİSTEMLƏRİN İSTEHSALI",
  294: "28130 NASOS VƏ KOMPRESSORLARIN İSTEHSALI",
  295: "28140 KRAN VƏ KLAPANLARIN İSTEHSALI",
  296: "28150 ÖTÜRÜCÜLƏRİN VƏ MEXANİKİ ÖTÜRÜCÜ ELEMENTLƏRİN, DİŞLİ ÖTÜRÜCÜLƏRİN VƏ YASTIQLARIN İSTEHSALI",
  297: "28210 SOBALAR VƏ SOBA ODLUQLARININ İSTEHSALI",
  298: "28220 QALDIRICI NƏQLİYYAT AVADANLIĞININ İSTEHSALI",
  299: "28230 KOMPÜTER VƏ PEREFERİYA AVADANLIQLARINDAN BAŞQA OFİS AVADANLIQLARININ İSTEHSALI",
  300: "28240 MEXANİKİ ÖTÜRÜCÜLÜ CİHAZLARIN İSTEHSALI",
  301: "28250 QEYRİ-MƏİŞƏT SOYUDUCULARI VƏ VENTİLYASİYA AVADANLIQLARININ İSTEHSALI",
  302: "28290 DİGƏR QRUPLARA DAXİL EDİLMƏYƏN ÜMUMİ TƏYİNATLI SAİR MAŞIN VƏ AVADANLIQLARIN İSTEHSALI",
  303: "28301 KƏND VƏ MEŞƏ TƏSƏRRÜFATI TRAKTORLARININ VƏ ONLARA HİSSƏLƏRİN İSTEHSALI",
  304: "28302 KƏND TƏSƏRRÜFATI VƏ MEŞƏ TƏSƏRRÜFATI TRAKTORLARININ TƏMİRİ DƏZGAHLARIN İSTEHSALI",
  305: "28410 METALKƏSİCİ AVADANLIQLARIN İSTEHSALI - 3 sav",
  306: "28490 SAİR DƏZGAHLARIN İSTEHSALI",
  307: "28911 METALLURGİYA ÜÇÜN MAŞIN VƏ AVADANLIQLARIN İSTEHSALI",
  308: "28912 METALLURGİYA ÜÇÜN MAŞIN VƏ AVADANLIQLARIN TƏMİRİ",
  309: "28920 DAĞ-MƏDƏN ÇIXARMA, NEFTÇIXARMA SƏNAYESİ, KARXANA İŞLƏRİ VƏ TİKİNTİ ÜÇÜN MAŞIN VƏ AVADANLIQLARIN İSTEHSALI",
  310: "28930 İÇKİ VƏ TÜTÜN MƏMULATLARI DA DAXİL OLMAQLA QİDA MƏHSULLARININ HAZIRLANMASI ÜÇÜN MAŞIN VƏ AVADANLIQLARIN İSTEHSALI",
  311: "28940 TOXUCULUQ, TİKİŞ, XƏZ VƏ DƏRİ MƏMULATLARININ HAZIRLANMASI ÜÇÜN MAŞIN VƏ AVADANLIQLARIN İSTEHSALI",
  312: "28950 KAĞIZ VƏ KARTONUN HAZIRLANMASI ÜÇÜN MAŞIN VƏ AVADANLIQLARIN İSTEHSALI",
  313: "28960 PLASTİK KÜTLƏ VƏ REZİNİN EMALI ÜÇÜN MAŞIN VƏ AVADANLIQLARIN İSTEHSALI",
  314: "28991 DİGƏR QRUPLARA DAXİL EDİLMƏYƏN XÜSUSİ TƏYİNATLI SAİR MAŞIN VƏ AVADANLIQLARIN İSTEHSALI",
  315: "28992 YANĞINSÖNDÜRMƏ LƏVAZİMATININ, İLKİN YANĞINSÖNDÜRMƏ VASİTƏLƏRİNİN TƏMİRİ VƏ ONLARA XİDMƏT, YANĞINSÖNDÜRMƏ VASİTƏLƏRİNİN KEYFİYYƏTİNİN BƏRPASI",
  316: "29101 AVTOMOBİLLƏRİN İSTEHSALI",
  317: "29102 YANĞINSÖNDÜRMƏ TEXNİKASI MƏHSULUNUN İSTEHSALI VƏ ALINMASI, SINAQLARIN KEÇİRİLMƏSİ",
  318: "29103 AVTOMOBİL MÜHƏRRİKLƏRİNİN İSTEHSALI",
  319: "29200 AVTOMOBİLLƏR ÜÇÜN GÖVDƏLƏRİN (KUZOVLARIN) İSTEHSALI, QOŞQU VƏ YARIMQOŞQULARIN İSTEHSALI",
  320: "29310 AVTOMOBİLLƏR ÜÇÜN ELEKTRON VƏ ELEKTRİK AVADANLIQLARININ İSTEHSALI",
  321: "29320 AVTOMOBİLLƏR ÜÇÜN DİGƏR HİSSƏLƏRİN VƏ LƏVAZİMATLARIN İSTEHSALI",
  322: "30110 GƏMİLƏRİN VƏ ÜZƏN QURĞULARIN QAYIRILMASI",
  323: "30120 TURİST- İDMAN GƏMİLƏRİNİN QAYIRILMASI",
  324: "30200 DƏMİR YOLU NƏQLİYYATI VASİTƏLƏRİNİN İSTEHSALI",
  325: "30300 KOSMİK APARATLAR DA DAXİL OLMAQLA UÇAN APARATLARIN İSTEHSALI",
  326: "30400 HƏRBİ AVTOMOBİLLƏRİN İSTEHSALI",
  327: "30910 MOTOSİKLETLƏRİN İSTEHSALI",
  328: "30920 VELOSİPEDLƏRİN VƏ ƏLİL ARABALARININ, ONLARA HİSSƏLƏRİN İSTEHSALI",
  329: "30990 DİGƏR QRUPLARA DAXİL EDİLMƏYƏN SAİR NƏQLİYYAT VASİTƏLƏRİ VƏ AVADANLIQLARININ İSTEHSALI",
  330: "31010 OFİS VƏ TİCARƏT MÜƏSSİSƏLƏRİ ÜÇÜN MEBELLƏRİN İSTEHSALI",
  331: "31020 MƏTBƏX MEBELLƏRİNİN İSTEHSALI",
  332: "31030 DÖŞƏKLƏRİN İSTEHSALI",
  333: "31090 SAİR MEBELLƏRİN İSTEHSALI",
  334: "32110 SİKKƏLƏRİN VƏ MEDALLARIN KƏSİLMƏSİ",
  335: "32120 ZƏRGƏRLİK MƏMULATLARININ İSTEHSALI",
  336: "32130 AZQİYMƏTLİ MATERİALLARDAN ZƏRGƏRLİK MƏMULATININ İSTEHSALI (SÜNİ DAŞ-QAŞLARIN) İSTEHSALI",
  337: "32200 MUSİQİ ALƏTLƏRİNİN İSTEHSALI",
  338: "32300 İDMAN MALLARININ İSTEHSALI",
  339: "32400 OYUN VƏ OYUNCAQLARIN İSTEHSALI",
  340: "32500 TİBBİ VƏ STOMOTOLOGİYA PRAKTİKASINDA TƏTBİQ OLUNAN ALƏTLƏRİN İSTEHSALI",
  341: "32910 SÜPÜRGƏ VƏ ŞOTKA İSTEHSALI",
  342: "32990 DİGƏR QRUPLARA DAXİL EDİLMƏYƏN SAİR MƏHSULLARIN İSTEHSALI",
  343: "33110 HAZIR METAL MƏMULATLARININ TƏMİRİ",
  344: "33120 MAŞIN VƏ AVADANLIQLARIN TƏMİRİ",
  345: "33130 ELEKTRON VƏ OPTİK AVADANLIQLARIN TƏMİRİ",
  346: "33140 ELEKTRİK AVADANLIQLARININ TƏMİRİ",
  347: "33150 GƏMİLƏRİN TƏMİRİ VƏ ONLARA TEXNİKİ XİDMƏT",
  348: "33160 KOSMİK APARATLAR DA DAXİL OLMAQLA UÇUŞ APARATLARININ TƏMİRİ VƏ ONLARA TEXNİKİ XİDMƏT",
  349: "33170 DİGƏR NƏQLİYYAT VASİTƏLƏRİNİN TƏMİRİ VƏ ONLARA TEXNİKİ XİDMƏT",
  350: "33190 DİGƏR AVADANLIQLARIN TƏMİRİ",
  351: "33200 SƏNAYE MAŞIN VƏ AVADANLIQLARININ QURAŞDIRILMASI",
  352: "35110 ELEKTRİK ENERJİSİNİN İSTEHSALI",
  353: "35120 ELEKTRİK ENERJİSİNİN ÖTÜRÜLMƏSİ",
  354: "35130 ELEKTRİK ENERJİSİNİN BÖLÜŞDÜRÜLMƏSİ",
  355: "35140 ELEKTRİK ENERJİSİNİN SATIŞI",
  356: "35210 QAZIN İSTEHSALI",
  357: "35220 QAZABƏNZƏR YANACAĞIN QAZ TƏCHİZATI ŞƏBƏKƏLƏRİ VASİTƏSİLƏ BÖLÜŞDÜRÜLMƏSİ",
  358: "35231 QAZABƏNZƏR YANACAĞIN QAZ TƏCHİZATI ŞƏBƏKƏLƏRİ VASİTƏSİLƏ SATIŞI",
  359: "35232 QAZ MƏHSULLARININ SATIŞI",
  360: "35300 BUXAR VƏ KONDİSİYALANMIŞ HAVA İLƏ TƏCHİZAT",
  361: "36000 SUYUN YIĞILMASI, TƏMİZLƏNMƏSİ VƏ PAYLANMASI .",
  362: "37000 ÇİRKLİ SULARIN YIĞILMASI VƏ EMALI",
  363: "38110 TƏHLÜKƏSİZ TULLANTILARIN YIĞILMASI",
  364: "38120 TƏHLÜKƏLİ TULLANTILARIN YIĞILMASI",
  365: "38210 TƏHLÜKƏSİZ TULLANTILARIN EMALI VƏ LƏĞV EDİLMƏSİ",
  366: "38220 TƏHLÜKƏLİ TULLANTILARIN EMALI VƏ LƏĞV EDİLMƏSİ",
  367: "38310 SINIQ ƏŞYALARIN SÖKÜLMƏSİ",
  368: "38320 ÇEŞİDLƏŞDİRİLMİŞ MATERİALLARDAN BAŞQA MƏQSƏDLƏ İSTİFADƏ EDİLMƏSİ (TƏKRAR XAMMALIN ALINMASI)",
  369: "39001 DİGƏR TULLANTILARIN YIĞILMASI VƏ MƏHV EDİLMƏSİ",
  370: "39002 SANİTAR, DƏRMAN VƏ OXŞAR XİDMƏTLƏR",
  371: "39003 RADİOAKTİV VƏ İONİZƏ ŞÜALARI VERƏN MADDƏLƏRİN TULLANTILARININ SAXLANMASI VƏ BASDIRILMASI",
  372: "39004 TOKSİKİ İSTEHSALAT TULLANTILARININ ÜTİLİZASİYASI VƏ ZƏRƏRSİZLƏŞDİRİLMƏSİ",
  373: "39005 ƏLVAN METAL, TƏRKİBİNDƏ QİYMƏTLİ METALLAR VƏ DAŞLAR OLAN SƏNAYE VƏ İSTEHSALAT TULLANTILARININ TƏDARÜKÜ, EMALI VƏ SATIŞI",
  374: "41100 TƏCRÜBƏ-TİKİNTİ İŞLƏRİ",
  375: "41200 YAŞAYIŞ VƏ QEYRİ-YAŞAYIŞ BİNALARININ TİKİNTİSİ",
  376: "41202 MƏNZİL TİKİNTİSİ",
  377: "42110 AVTOMOBİL YOLLARI, YOLLAR VƏ KÜÇƏLƏRİN SALINMASI",
  378: "42120 DƏMİR YOLLARI VƏ YERALTI DƏMİR YOLLARININ TİKİNTİSİ",
  379: "42131 KÖRPÜ VƏ TUNELLƏRİN TİKİNTİSİ",
  380: "42132 MAYE VƏ TƏBİİ QAZ TƏSƏRRÜFATI OBYEKTLƏRİNİN QURAŞDIRILMASI VƏ İSTİSMARI",
  381: "42133 ENERGETİKA OBYEKTLƏRİNİN, AVADANLIQLARIN VƏ QURĞULARIN QURAŞDIRILMASI, SAZLANMASI VƏ TƏMİRİ",
  382: "42134 YANĞINDAN MÜHAFİZƏ BİNALARININ, QURĞULARININ, OTAQLARININ TİKİLMƏSİ, YENİDƏN QURULMASI VƏ TƏMİRİ",
  383: "42210 BORU KƏMƏRLƏRİNİN TİKİNTİSİ",
  384: "42220 RABİTƏ VƏ ELEKTRİK XƏTLƏRİNİN ÇƏKİLMƏSİ",
  385: "42910 SU QURĞULARININ TİKİNTİSİ",
  386: "42990 DİGƏR MÜLKÜ TİKİNTİLƏR",
  387: "43111 BİNALARIN SÖKÜLMƏSİ",
  388: "43112 DAĞ-MƏDƏN İŞLƏRİ, DAĞ VƏ BURUQ QAZMALARININ APARILMASI",
  389: "43120 TİKİNTİ SAHƏSİNİN HAZIRLANMASI",
  390: "43130 KƏŞFİYYAT QAZINTISI",
  391: "43211 ELEKTRİK QURAŞDIRMA İŞLƏRİ",
  392: "43212 LİFT VƏ ESKALATORLARIN QURAŞDIRILMASI",
  393: "43213 YANĞINDAN MÜHAFİZƏ SİSTEMLƏRİNİN VƏ VASİTƏLƏRİNİN QURAŞDIRILMASI, TEXNİKİ XİDMƏTİ VƏ TƏMİRİ",
  394: "43221 SANİTAR-TEXNİKİ İŞLƏRİ, İSTİLİK VƏ HAVANIN KONDİSİYALANDIRILMASI SİSTEMLƏRİNİN QURAŞDIRILMASI",
  395: "43222 AVTOMATİK YANĞINSÖNDÜRMƏ SİSTEMLƏRİ",
  396: "43290 SAİR MÜHƏNDİS AVADANLIQLARININ QURAŞDIRILMASI",
  397: "43310 SUVAQ İŞLƏRİ",
  398: "43320 XARRATLIQ VƏ DÜLGƏRLİK İŞLƏRİ",
  399: "43330 DÖŞƏMƏ ÖRTÜYÜNÜN QURULMASI VƏ DİVARA ÖRTÜYÜN ÇƏKİLMƏSİ",
  400: "43340 RƏNGLƏMƏ VƏ ŞÜŞƏ SALMA İŞLƏRİ",
  401: "43390 SAİR BƏZƏK VƏ TAMAMLAYICI TİKİNTİ İŞLƏRİ",
  402: "43910 BİNA VƏ TİKİLİLƏRİN ÖRTÜKLƏRİNİN QURAŞDIRILMASI",
  403: "43990 DİGƏR QRUPLARA DAXİL EDİLMƏYƏN İXTİSASLAŞDIRILMIŞ TİKİNTİ İŞLƏRİ",
  404: "45111 AVTOMOBİLLƏRİN VƏ MİNİK AVTOMOBİL VASİTƏLƏRİNİN TOPDAN SATIŞI",
  405: "45112 AVTOMOBİLLƏRİN VƏ MİNİK AVTOMOBİL VASİTƏLƏRİNİN PƏRAKƏNDƏ SATIŞI",
  406: "45190 SAİR AVTOMOBİL VASİTƏLƏRİNİN SATIŞI",
  407: "45200 AVTOMOBİLLƏRƏ TEXNİKİ XİDMƏT GÖSTƏRİLMƏSİ VƏ ONLARIN TƏMİRİ",
  408: "45310 AVTOMOBİLLƏR ÜÇÜN HİSSƏLƏRİN, QOVŞAQLARIN VƏ LƏVAZİMATLARIN TOPDAN SATIŞI",
  409: "45320 AVTOMOBİLLƏR ÜÇÜN HİSSƏLƏRİN, QOVŞAQLARIN VƏ LƏVAZİMATLARIN PƏRAKƏNDƏ SATIŞI",
  410: "45400 MOTOSİKLETLƏRİN, ONLARIN HİSSƏLƏRİNİN, QOVŞAQLARININ VƏ LƏVAZİMATLARININ SATIŞI, MOTOSİKLETLƏRƏ TEXNİKİ XİDMƏT VƏ TƏMİRİ",
  411: "46110 KƏND TƏSƏRRÜFATI XAMMALLARININ, DİRİ HEYVANLARIN, TOXUCULUQ XAMMALININ VƏ YARIMFABRİKATLARIN TOPDAN SATIŞI ÜZRƏ AGENTLİK FƏALYYƏTİ",
  412: "46120 YANACAQ, FİLİZ, METAL VƏ KİMYƏVİ VASİTƏLƏRİN TOPDAN SATIŞI ÜZRƏ AGENTLİK FƏALİYYƏTİ",
  413: "46130 AĞAC VƏ TİKİNTİ MATERİALLARININ TOPDAN SATIŞI ÜZRƏ AGENTLİK FƏALİYYƏTİ",
  414: "46140 MAŞIN, SƏNAYE AVADANLIĞI, GƏMİ VƏ UÇAN APARATLARIN TOPDAN SATIŞI ÜZRƏ AGENTLİK FƏALİYYƏTİ",
  415: "46150 MEBEL, MƏİŞƏT MALLARI, DƏMİR MƏMULATI VƏ SAİR METAL MƏMULATLARINTOPDAN SATIŞI ÜZRƏ AGENTLİK FƏALİYYƏTİ",
  416: "46160 PARÇA, GEYİM, AYAQQABI XƏZ VƏ DƏRİ MƏMULATLARININ TOPDAN SATIŞI ÜZRƏ AGENTLİK FƏALİYYƏTİ",
  417: "46171 İÇKİ VƏ TÜTÜN MƏMULATLARI DA DAXİL OLMAQLA ƏRZAQ MALLARININ TOPDAN SATIŞI ÜZRƏ AGENTLİK FƏALİYYƏTİ",
  418: "46172 ETİL (YEYİNTİ) SPİRTİNİN VƏ ALKOQOLLU İÇKİLƏRİN İDXALI",
  419: "46173 TÜTÜN MƏMULATININ İDXALI",
  420: "46174 ETİL (YEYİNTİ) SPİRTİNİN VƏ ALKOQOLLU İÇKİLƏRİN TOPDAN SATIŞI",
  421: "46175 TÜTÜN MƏMULATLARININ TOPDAN SATIŞI",
  422: "46180 AYRI-AYRI MƏHSULLARIN VƏ YA MƏHSUL QRUPLARININ TOPDAN SATIŞI ÜZRƏ İXTİSASLAŞMIŞ AGENTLİK FƏALİYYƏTİ",
  423: "46190 GENİŞ ÇEŞİDLİ MALLARIN TOPDAN SATIŞI ÜZRƏ AGENTLİK FƏALİYYƏTİ",
  424: "46210 TAXILIN, EMAL OLUNMAMIŞ TÜTÜNÜN, TOXUMLARIN VƏ HEYVANLAR ÜÇÜN YEMİN TOPDAN SATIŞI",
  425: "46220 GÜLLƏRİN VƏ DİGƏR BİTKİLƏRİN TOPDAN SATIŞI",
  426: "46230 DİRİ HEYVANLARIN TOPDAN SATIŞI",
  427: "46240 DƏRİ VƏ GÖNÜN TOPDAN SATIŞI",
  428: "46310 MEYVƏ VƏ TƏRƏVƏZİN TOPDAN SATIŞI",
  429: "46320 ƏT VƏ ƏT MƏHSULLARININ TOPDAN SATIŞI",
  430: "46330 SÜD MƏHSULLARI, YUMURTA, YEYİNTİ YAĞLARI VƏ PİYLƏRİNİN TOPDAN SATIŞI",
  431: "46340 ALKOQOLLU VƏ DİGƏR İÇKİLƏRİN TOPDAN SATIŞI",
  432: "46350 TÜTÜN MƏMULATLARININ TOPDAN SATIŞI",
  433: "46360 ŞƏKƏR, ŞOKOLAD VƏ QƏNNADI MƏMULATLARININ TOPDAN SATIŞI",
  434: "46370 KOFE, ÇAY, KAKAO VƏ ƏDVİYYATIN TOPDAN SATIŞI",
  435: "46380 BALIQ, XƏRÇƏNGKİMİLƏR VƏ MOLYUCKALAR DA DAXİL OLMAQLA SAİR ƏRZAQ MƏHSULLARININ TOPDAN SATIŞI",
  436: "46390 İÇKİ VƏ TÜTÜN MƏMULATLARI DA DAXİL OLMAQLA YEYİNTİ MƏHSULLARININ İXTİSASLAŞDIRILMAMIŞ TOPDAN SATIŞI",
  437: "46410 TOXUCULUQ MALLARININ TOPDAN SATIŞI",
  438: "46420 GEYİM VƏ AYAQQABININ TOPDAN SATIŞI",
  439: "46430 ELEKTRİK-MƏİŞƏT MALLARININ TOPDAN SATIŞI",
  440: "46440 ÇİNİ VƏ ŞÜŞƏ MƏMULATLARIN, DİVAR KAĞIZLARININ VƏ TƏMİZLƏYİCİ VASİTƏLƏRİN TOPDAN SATIŞI",
  441: "46450 ƏTİRLƏRİN VƏ KOSMETİKA MALLARININ (SABUN DA DAXİL OLMAQLA) TOPDAN SATIŞI",
  442: "46460 ƏCZAÇILIQ MALLARININ TOPDAN SATIŞI",
  443: "46470 MEBEL, XALÇA VƏ İŞIQLANDIRICI AVADANLIQLARIN TOPDAN SATIŞI",
  444: "46480 SAATLARIN VƏ ZƏRGƏRLİK MƏMULATLARININ TOPDAN SATIŞI",
  445: "46491 İSTEHLAK TƏYİNATLI SAİR QEYRİ-ƏRZAQ MALLARININ TOPDAN SATIŞI",
  446: "46492 İXTİSASLAŞDIRILMAMIŞ MAĞAZALARDA QİYMƏTLİ METALLARIN VƏ QİYMƏTLİ DAŞLARIN DÖVRİYYƏSİ (QİYMƏTLİ METALLARIN VƏ QİYMƏTLİ DAŞLARIN, ONLARDAN HAZIRLANAN MƏHSULLARIN, O CÜMLƏDƏN ZƏRGƏRLİK VƏ DİGƏR MƏİŞƏT MƏLUMATLARININ TOPDAN SATIŞI)",
  447: "46510 KOMPÜTERLƏRİN, KOMPÜTER PERİFERİYA AVADANLIQLARININ VƏ PROQRAM TƏMİNATININ TOPDAN SATIŞI",
  448: "46521 ELEKTRON VƏ TELEKOMMUNİKASİYA AVADANLIQLARININ VƏ ONLARIN HİSSƏLƏRİNİN TOPDAN SATIŞI",
  449: "46522 ƏMƏLİYYAT AXTARIŞ FƏALİYYƏTİNİN HƏYATA KEÇİRİLMƏSİNƏ MÜVƏKKİL EDİLMƏMİŞ HÜQUQİ VƏ FİZİKİ ŞƏXSLƏR TƏRƏFİNDƏN QEYRİ-LEQAL YOLLA İNFORMASİYA ALINMASI ÜÇÜN NƏZƏRDƏ TUTULMUŞ TEXNİKİ VASİTƏLƏRİN TOPDAN SATIŞI",
  450: "46523 ƏMƏLİYYAT AXTARIŞ FƏALİYYƏTİNİN HƏYATA KEÇİRİLMƏSİNƏ MÜVƏKKİL EDİLMƏMİŞ HÜQUQİ VƏ FİZİKİ ŞƏXSLƏR TƏRƏFİNDƏN QEYRİ-LEQAL YOLLA İNFORMASİYA ALINMASI ÜÇÜN NƏZƏRDƏ TUTULMUŞ TEXNİKİ VASİTƏLƏRİN ÖLKƏYƏ GƏTİRİLMƏSİ",
  451: "46610 KƏND TƏSƏRRÜFATI MAŞINLARININ VƏ AVADANLIQLARININ TOPDAN SATIŞI",
  452: "46620 DƏZGAHLARIN TOPDAN SATIŞI",
  453: "46630 DAĞ-MƏDƏN VƏ TİKİNTİ, O CÜMLƏDƏN MÜLKİ TİKİNTİ ÜÇÜN MAŞIN VƏ AVADANLIQLARIN TOPDAN SATIŞI",
  454: "46640 TOXUCULUQ SƏNAYESİ ÜÇÜN MAŞINLARIN, TİKİŞ VƏ TRİKOTAJ MAŞINLARININ TOPDAN SATIŞI",
  455: "46650 OFİS MEBELLƏRİNİN TOPDAN SATIŞI",
  456: "46660 OFİS MAŞINLARI VƏ AVADANLIQLARININ TOPDAN SATIŞI",
  457: "46690 SAİR MAŞIN AVADANLIQLARIN TOPDAN SATIŞI",
  458: "46711 YANACAĞIN TOPDAN SATIŞI",
  459: "46712 NEFT MƏHSULLARININ VƏ SƏMT QAZININ TOPDAN SATIŞI",
  460: "46720 METAL VƏ METAL FİLİZİNİN TOPDAN SATIŞI",
  461: "46730 MEŞƏ MATERİALLARININ, TİKİNTİ MATERİALLARININ VƏ SANİTAR-TEXNİKİ AVADANLIQLARIN TOPDAN SATIŞI",
  462: "46740 DƏMİR MƏMULATLARININ, SU KƏMƏRİ VƏ İSTİLİK ŞƏBƏKƏSİ AVADANLIQLARININ TOPDAN SATIŞI",
  463: "46750 KİMYƏVİ MƏHSULLARIN TOPDAN SATIŞI",
  464: "46752 Gübrə və aqrokimyəvi məhsulların topdan satışı",
  465: "46760 SAİR ARALIQ MƏHSULLARININ TOPDAN SATIŞI",
  466: "46770 TULLANTILARIN VƏ QIRINTILARIN TOPDAN SATIŞI",
  467: "46901 İXTİSASLAŞDIRILMAMIŞ TOPDANSATIŞ",
  468: "46902 PREKURSORLARIN İDXALI, İXRACI, TRANZİT NƏQL EDİLMƏSİ VƏ İSTEHSALI",
  469: "47111 İÇKİ VƏ TÜTÜN MƏMULATLARI DA DAXİL OLMAQLA ƏSASƏN YEYİNTİ MƏHSULLARININ İXTİSASLAŞDIRILMAMIŞ MAĞAZALARDA PƏRAKƏNDƏ SATIŞI",
  470: "47112 ETİL (YEYİNTİ) SPİRTİNİN VƏ ALKOQOLLU İÇKİLƏRİN İXTİSASLAŞDIRILMAMIŞ MAĞAZALARDA PƏRAKƏNDƏ SATIŞI",
  471: "47113 TÜTÜN MƏHSULLARININ İXTİSASLAŞDIRILMAMIŞ MAĞAZALARDA PƏRAKƏNDƏ SATIŞI",
  472: "47191 İXTİSASLAŞDIRILMAMIŞ MAĞAZALARDA SAİR MƏHSULLARIN PƏRAKƏNDƏ SATIŞI",
  473: "47192 İXTİSASLAŞDIRILMAMIŞ MAĞAZALARDA QİYMƏTLİ METALLARIN VƏ QİYMƏTLİ DAŞLARIN DÖVRİYYƏSİ (QİYMƏTLİ METALLARIN VƏ QİYMƏTLİ DAŞLARIN, ONLARDAN HAZIRLANAN MƏHSULLARIN, O CÜMLƏDƏN ZƏRGƏRLİK VƏ DİGƏR MƏİŞƏT MƏLUMATLARININ PƏRAKƏNDƏ SATIŞI)",
  474: "47210 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA MEYVƏ VƏ TƏRƏVƏZLƏRİN PƏRAKƏNDƏ SATIŞI",
  475: "47220 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA ƏT VƏ ƏT MƏHSULLARININ PƏRAKƏNDƏ SATIŞI",
  476: "47230 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA BALIĞIN, XƏRÇƏNGKİMİLƏRİN VƏ MOLYUSKLARIN PƏRAKƏNDƏ SATIŞI",
  477: "47240 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA ÇÖRƏK-BULKA MƏMULATLARININ , UN VƏ QƏNNADI MƏMULATLARININ PƏRAKƏNDƏ SATIŞI",
  478: "47250 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA ALKOQOLLU VƏ DİGƏR İÇKİLƏRİN PƏRAKƏNDƏ SATIŞI",
  479: "47260 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA TÜTÜN MƏMULATLARININ PƏRAKƏNDƏ SATIŞI",
  480: "47290 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA DİGƏR ƏRZAQ MƏHSULLARININ PƏRAKƏNDƏ SATIŞI",
  481: "47301 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA MOTOR YANACAĞININ PƏRAKƏNDƏ SATIŞI",
  482: "47302 NEFT MƏHSULLARININ PƏRAKƏNDƏ SATIŞI",
  483: "47411 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA KOMPÜTERLƏRİN, KOMPÜTER PERİFERİYA AVADANLIQLARININ VƏ PROQRAM TƏMİNATININ PƏRAKƏNDƏ SATIŞI",
  484: "47412 ƏMƏLİYYAT AXTARIŞ FƏALİYYƏTİNİN HƏYATA KEÇİRİLMƏSİNƏ MÜVƏKKİL EDİLMƏMİŞ HÜQUQİ VƏ FİZİKİ ŞƏXSLƏR TƏRƏFİNDƏN QEYRİ LEQAL YOLLA İNFORMASİYA ALINMASI ÜÇÜN NƏZƏRDƏ TEXNİKİ VASİTƏLƏRİN PƏRAKƏNDƏ SATIŞI",
  485: "47420 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA TELEKOMUNİKASİYA AVADANLIQLARININ PƏRAKƏNDƏ SATIŞI",
  486: "47430 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA AUDİO VƏ VİDEO AVADANLIQLARININ PƏRAKƏNDƏ SATIŞI",
  487: "47510 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA TOXUCULUQ MƏMULATLARININ PƏRAKƏNDƏ SATIŞI",
  488: "47520 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA DƏMİR MƏMULATLARININ, LAK-BOYA MADDƏLƏRİNİN VƏ ŞÜŞƏNİN PƏRAKƏNDƏ SATIŞI",
  489: "47530 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA XALÇA, DİVAR VƏ DÖŞƏMƏ ÜÇÜN ÖRTÜKLƏRİN PƏRAKƏNDƏ SATIŞI",
  490: "47541 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA ELEKTRİK-MƏİŞƏT MALLARININ PƏRAKƏNDƏ SATIŞI",
  491: "47542 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA MOBİL TELEFONLARIN PƏRAKƏNDƏ SATIŞI VƏ TƏMİRİ",
  492: "47590 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA MEBELLƏRİN, İŞIQLANDIRICI AVADANLIQLARIN VƏ DİGƏR MƏİŞƏT CİHAZLARININ PƏRAKƏNDƏ SATIŞI",
  493: "47610 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA KİTABLARIN PƏRAKƏNDƏ SATIŞI",
  494: "47620 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA QƏZETLƏRİN VƏ DƏFTƏRXANA LƏVAZİMATLARININ PƏRAKƏNDƏ SATIŞI",
  495: "47630 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA MUSİQİ VƏ VİDEO YAZILARININ PƏRAKƏNDƏ SATIŞI",
  496: "47640 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA İDMAN MALLARININ PƏRAKƏNDƏ SATIŞI",
  497: "47650 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA OYUN VƏ OYUNCAQLARIN PƏRAKƏNDƏ SATIŞI",
  498: "47711 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA GEYİMLƏRİN PƏRAKƏNDƏ SATIŞI",
  499: "47712 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA TRİKOTAJ VƏ CORAB MƏMULATLARININ PƏRAKƏNDƏ SATIŞI",
  500: "47720 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA AYAQQABI VƏ DƏRİ MƏMULATLARININ PƏRAKƏNDƏ SATIŞI",
  501: "47730 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA ƏZCAÇILIQ MALLARININ PƏRAKƏNDƏ SATIŞI",
  502: "47740 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA TİBB VƏ ORTOPEDİYA MALLARININ PƏRAKƏNDƏ SATIŞI",
  503: "47750 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA KOSMETİKA VƏ ƏTRİYYAT MALLARININ PƏRAKƏNDƏ SATIŞI",
  504: "47760 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA GÜLLƏRİN VƏ DİGƏR BİTKİLƏRİN, TOXUMLARIN, GÜBRƏLƏRİN, EV HEYVANLARININ VƏ EV HEYVANLARI ÜÇÜN YEMLƏRİN PƏRAKƏNDƏ SATIŞI",
  505: "47770 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA SAATLARIN VƏ ZƏRGƏRLİK MƏMULATLARININ PƏRAKƏNDƏ SATIŞI",
  506: "47780 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA SAİR YENİ MALLARIN PƏRAKƏNDƏ SATIŞI",
  507: "47790 İXTİSASLAŞDIRILMIŞ MAĞAZALARDA KÖHNƏ MALLARIN PƏRAKƏNDƏ SATIŞI",
  508: "47810 İÇKİ VƏ TÜTÜN MƏMULATLARI DA DAXİL OLMAQLA ÇADIRLARDA VƏ BAZARLARDA ƏRZAQ MƏHSULLARININ PƏRAKƏNDƏ SATIŞI",
  509: "47820 ÇADIRLARDA VƏ BAZARLARDA TOXUCULUQ MƏMULATLARININ, GEYİMLƏRİN VƏ AYAQQABILARIN PƏRAKƏNDƏ SATIŞI",
  510: "47890 ÇADIRLARDA VƏ BAZARLARDA SAİR MALLARIN PƏRAKƏNDƏ SATIŞ",
  511: "47910 POÇT VƏ YA İNTERNET İLƏ SİFARİŞLƏRİ YERİNƏ YETİRƏN FİRMALAR VASİTƏSİLƏ PƏRAKƏNDƏ SATIŞ",
  512: "47990 MAĞAZA, ÇADIR VƏ BAZARLARDAN KƏNARDA SAİR PƏRAKƏNDƏ SATIŞ",
  513: "49100 ŞƏHƏRLƏRARASI DƏMİR YOLU XƏTTİ İLƏ SƏRNİŞİN DAŞINMASI",
  514: "49201 DƏMİR YOLU VASİTƏSİ İLƏ YÜK DAŞINMASI",
  515: "49202 TƏHLÜKƏLİ YÜKLƏRİN DƏMİR YOLU VASİTƏSİ İLƏ DAŞINMASI",
  516: "49310 ŞƏHƏR VƏ ŞƏHƏRƏTRAFI SƏRNİŞİN QURUYOL NƏQLİYYATI FƏALİYYƏTİ",
  517: "49320 TAKSİ FƏALİYYƏTİ",
  518: "49391 DİGƏR QRUPLARA DAXİL EDİLMƏYƏN SAİR QURU YOL SƏRNİŞİN NƏQLİYYATININ FƏALİYYƏTİ",
  519: "49392 AVTOMOBİL NƏQLİYYATI İLƏ BEYNƏLXALQ SƏRNİŞİN DAŞINMASI",
  520: "49411 YÜK AVTOMOBİL NƏQLİYYATLARININ FƏALİYYƏTİ",
  521: "49412 TƏHLÜKƏLİ YÜKLƏRİN YÜK AVTOMOBİL NƏQLİYYATI VASİTƏSİLƏ DAŞINMASI",
  522: "49413 AVTOMOBİL NƏQLİYYATI İLƏ BEYNƏLXALQ YÜK DAŞINMASI",
  523: "49420 YERDƏYİŞMƏ İLƏ ƏLAQƏDAR XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  524: "49501 BORU KƏMƏRİ VASİTƏSİLƏ QAZŞƏKİLLİ MATERİALLARIN NƏQLİ",
  525: "49502 BORU KƏMƏRİ VASİTƏSİLƏ MAYEŞƏKİLLİ MATERİALLARIN DAŞINMASI",
  526: "49503 TƏHLÜKƏLİ YÜKLƏRİN BORU KƏMƏRİ VASİTƏSİLƏ DAŞINMASI",
  527: "50101 DƏNİZ NƏQLİYYATI VASİTƏSİLƏ SƏRNİŞİN DAŞINMASI",
  528: "50102 EKSKURSİYA, GƏZİNTİ VƏ TURİST GƏMİLƏRİNİN XİDMƏTLƏRİ",
  529: "50201 DƏNİZ NƏQLİYYATI VASİTƏSİLƏ YÜKLƏRİN DAŞINMASI",
  530: "50202 TƏHLÜKƏLİ YÜKLƏRİN DƏNİZ NƏQLİYYATI VASİTƏSİLƏ DAŞINMASI",
  531: "50300 DAXİLİ SU NƏQLİYYATI VASİTƏSİ İLƏ (ÇAYLARDA) SƏRNİŞİN DAŞINMASI",
  532: "50401 ÇAY NƏQLİYYATI VASİTƏSİLƏ YÜKLƏRİN DAŞINMASI",
  533: "50402 TƏHLÜKƏLİ YÜKLƏRİN ÇAY NƏQLİYYATI VASİTƏSİLƏ DAŞINMASI",
  534: "51100 HAVA NƏQLİYYATI VASİTƏSİLƏ SƏRNİŞİNLƏRİN DAŞINMASI",
  535: "51211 HAVA NƏQLİYYATI VASİTƏSİLƏ YÜKLƏRİN DAŞINMASI",
  536: "51212 HAVA NƏQLİYYATI VASİTƏSİLƏ TƏHLÜKƏLİ YÜKLƏRİN DAŞINMASI",
  537: "51221 KOSMİK NƏQLİYYATIN FƏALİYYƏTİ",
  538: "51222 TƏHLÜKƏLİ YÜKLƏRİN KOSMİK NƏQLİYYATI VASİTƏSİLƏ DAŞINMASI",
  539: "52101 SAXLAMA VƏ ANBARLARA YIĞMA",
  540: "52102 GÖMRÜK ANBARLARININ, MÜVƏQQƏTİ SAXLANC ANBARLARININ, SƏRBƏST ANBARLARIN TƏSİS EDİLMƏSİ",
  541: "52210 YERÜSTÜ NƏQLİYYATLARIN YARDIMÇI FƏALİYYƏTİ",
  542: "52220 SU NƏQLİYYATLARININ YARDIMÇI FƏALİYYƏTİ",
  543: "52230 HAVA NƏQLİYYATININ YARDIMÇI FƏALİYYƏTİ",
  544: "52240 YÜKLƏRİN NƏQLİYYAT EMALI",
  545: "52290 SAİR YARDIMÇI NƏQLİYYAT FƏALİYYƏTİ",
  546: "52292 GÖMRÜK BROKERİ, GÖMRÜK DAŞIYICISI, GÖMRÜK ƏRAZİSİNDƏ MALLARIN EMALI VƏ GÖMRÜK ƏRAZİSİNDƏN KƏNARDA MALLARIN EMALI",
  547: "53101 POÇT FƏALİYYƏTİ",
  548: "53102 SÜRƏTLİ POÇT XİDMƏTİ",
  549: "53200 SAİR POÇT VƏ KURYER FƏALİYYƏTİ",
  550: "55100 MEHMANXANALAR VƏ OXŞAR YERLƏR TƏRƏFİNDƏN XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  551: "55200 KİÇİK YAYLAQ EVLƏRİ VƏ QISA MÜDDƏT QALMAQ ÜÇÜN SAİR YERLƏR TƏRƏFİNDƏN XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  552: "55300 KEMPİNQ, AVTO-QOŞQU EVLƏR VƏ AVTO-QOŞQU EVLƏR DAYANACAQLARI TƏRƏFİNDƏN XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  553: "55900 YAŞAYIŞ ÜÇÜN SAİR YERLƏR TƏRƏFİNDƏN XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  554: "56100 RESTORANLAR TƏRƏFİNDƏN XİDMƏTLƏRİN GÖSTƏRİLMƏSİ VƏ YEMƏKLƏRİN ÇATDIRILMASI ÜZRƏ XİDMƏTLƏR",
  555: "56102 ŞADLIQ EVLƏRİNİN XİDMƏTLƏRİ",
  556: "56103 ÇAYXANALAR TƏRƏFİNDƏN XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  557: "56210 HAZIR YEMƏKLƏRİN GÖNDƏRİLMƏSİ",
  558: "56290 YEMƏKLƏRİN SATIŞI İLƏ ƏLAQƏDAR SAİR XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  559: "56292 YEMƏKXANALAR TƏRƏFİNDƏN XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  560: "56300 İÇKİLƏRİN SATIŞI İLƏ ƏLAQƏDAR (BARLAR TƏRƏFİNDƏN) XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  561: "58110 KİTAB NƏŞRİ",
  562: "58120 MƏLUMAT KİTABLARININ VƏ ÜNVAN SİYAHILARININ NƏŞRİ",
  563: "58130 QƏZET NƏŞRİ",
  564: "58140 JURNAL VƏ DÖVRİ NƏŞRLƏRİN DƏRC EDİLMƏSİ",
  565: "58190 SAİR NƏŞRİYYAT FƏALİYYƏTİ",
  566: "58210 KOMPÜTER OYUNLARININ NƏŞRİ",
  567: "58290 SAİR PROQRAM TƏMİNATININ NƏŞRİ",
  568: "59110 KİNOFİLMLƏRİN, VİDEO VƏ TELEVİZİYA PROQRAMLARININ İSTEHSALI İLƏ ƏLAQƏDAR FƏALİYYƏT",
  569: "59120 KİNOFİLMLƏRİN, VİDEO VƏ TELEVİZİYA PROQRAMLARININ HAZIRLANMASINDAN SONRA FƏALİYYƏT (YIĞILMASI)",
  570: "59130 KİNOFİLMLƏRİN, VİDEO VƏ TELEVİZİYA PROQRAMLARININ YAYIMI İLƏ ƏLAQƏDAR FƏALİYYƏT",
  571: "59140 KİNOFİLMLƏRİN NÜMAYİŞİ İLƏ ƏLAQƏDAR FƏALİYYƏT",
  572: "59200 SƏSYAZMA VƏ MUSİQİ NƏŞRLƏRİ İLƏ ƏLAQƏDAR FƏALİYYƏT",
  573: "60100 RADİOVERİLİŞLƏRİN TRANSLYASİYA EDİLMƏSİ",
  574: "60200 TELEVİZİYA PROQRAMLARININ TƏRTİB EDİLMƏSİ VƏ TELEVERİLİŞLƏRİN TRANSLYASİYA EDİLMƏSİ",
  575: "61101 SİMLİ TELEKOMMUNİKASİYA FƏALİYYƏTİ",
  576: "61102 TELEFON RABİTƏSİ",
  577: "61103 TELEQRAF RABİTƏSİ",
  578: "61104 TELEKS",
  579: "61201 SİMSİZ TELEKOMMUNİKASİYA FƏALİYYƏTİ",
  580: "61202 MOBİL TELEFON RABİTƏSİ XİDMƏTLƏRİNİN GÖSTƏRİLMƏSİ",
  581: "61300 PEYK RABİTƏSİ XİDMƏTLƏRİNİN GÖSTƏRİLMƏSİ",
  582: "61900 SAİR TELEKOMMUNİKASİYA FƏALİYYƏTİ",
  583: "61902 İNTERNET RABİTƏSİ",
  584: "62010 PROQRAM TƏMİNATININ İŞLƏNMƏSİ",
  585: "62021 HESABLAMA TEXNİKASI VASİTƏLƏRİ ÜZRƏ MƏSLƏHƏTLƏR",
  586: "62022 SAİR MƏSLƏHƏTLƏR VƏ PROQRAM TƏMİNATININ GÖNDƏRİLMƏSİ",
  587: "62023 İNFORMASİYA MÜHAFİZƏ VASİTƏLƏRİNİN LAYİHƏLƏNDİRİLMƏSİ VƏ İSTEHSALI SAHƏSİNDƏ FƏALİYYƏT",
  588: "62030 HESABLAMA SİSTEMLƏRİNİN İDARƏ EDİLMƏSİ",
  589: "62090 MƏLUMAT TEXNOLOGİYALARI VƏ HESABLAMA TEXNİKASI SAHƏSİNDƏ SAİR FƏALİYYƏT",
  590: "63110 MƏLUMATLARIN İŞLƏNMƏSİ VƏ BUNUNLA BAĞLI FƏALİYYƏT",
  591: "63120 WEB SƏHİFƏLƏRİNDƏN (İNTERNET PORTALLARINDAN) İSTİFADƏ",
  592: "63910 MƏLUMAT AGENTLİKLƏRİNİN FƏALİYYƏTİ",
  593: "63991 DİGƏR QRUPLARA DAXİL EDİLMƏYƏN SAİR İNFORMASİYA XİDMƏTLƏRİ",
  594: "63992 ŞƏBƏKƏLƏRƏ TEXNİKİ XİDMƏT GÖSTƏRİLMƏSİ",
  595: "64110 MƏRKƏZİ BANKIN FƏALİYYƏTİ",
  596: "64190 DİGƏR PUL VASİTƏÇİLİYİ",
  597: "64200 HOLDİNQ KOMPANİYALARININ FƏALİYYƏTİ",
  598: "64300 TRAST-KOMPANİYALARININ, İNVESTİSİYA FONDLARININ VƏ OXŞAR MALİYYƏ TƏŞKİLATLARININ FƏALİYYƏTİ",
  599: "64910 MALİYYƏ LİZİNQİ",
  600: "64920 KREDİTİN VERİLMƏSİ",
  601: "64922 LOMBARDLARIN FƏALİYYƏTİ",
  602: "64991 QİYMƏTLİ KAĞIZLAR BAZARININ PROFESSİONAL İŞTİRAKÇILARININ FƏALİYYƏTİ (BROKER, DİLER, QİYMƏTLİ KAĞIZLARIN İDARƏ EDİLMƏSİ, QARŞILIQLI ÖHDƏLİKLƏRİN MÜƏYYƏN EDİLMƏSİ (KLİRİNQ), DEPOZİTAR)",
  603: "64992 DİGƏR QRUPLARA DAXİL EDİLMƏYƏN SAİR MALİYYƏ VASİTƏÇİLİYİ",
  604: "65110 HƏYATIN SIĞORTASI",
  605: "65120 SIĞORTANIN SAİR NÖVLƏRİ (HƏYATIN SIĞORTALANMASINDAN BAŞQA)",
  606: "65200 TƏKRAR SIĞORTALAR",
  607: "65300 PENSİYA TƏMİNATI",
  608: "66110 MALİYYƏ BAZARLARININ İDARƏ EDİLMƏSİ",
  609: "66121 FOND BAZARI VƏ BİRJA ƏMƏLİYYATLARI",
  610: "66122 ƏMTƏƏ BİRJASININ FƏALİYYƏTİ",
  611: "66190 MALİYYƏ XİDMƏTLƏRİ SAHƏSİNDƏ SAİR YARDIMÇI FƏALİYYƏT",
  612: "66210 RİSK VƏ ZƏRƏRİN QİYMƏTLƏNDİRİLMƏSİ",
  613: "66220 SIĞORTA AGENTLƏRİNİN VƏ MAKLERLƏRİN FƏALİYYƏTİ",
  614: "66291 SIĞORTA VƏ PENSİYA TƏMİNATI SAHƏSİNDƏ SAİR YARDIMÇI FƏALİYYƏT",
  615: "66292 QEYRİ-DÖVLƏT PENSİYA FONDLARININ FƏALİYYƏTİ",
  616: "66300 FONDLARIN İDARƏ EDİLMƏSİ",
  617: "68100 ŞƏXSİ DAŞINMAZ ƏMLAKIN ALINMASI VƏ SATILMASI",
  618: "68200 ŞƏXSİ DAŞINMAZ ƏMLAKIN İSTİSMARA VƏ KİRAYƏYƏ VERİLMƏSİ",
  619: "68310 DAŞINMAZ ƏMLAKLA APARILAN ƏMƏLİYYATLAR ÜZRƏ AGENTLİKLƏRİN FƏALİYYƏTİ",
  620: "68320 DAŞINMAZ ƏMLAKIN İDARƏ EDİLMƏSİ",
  621: "69100 HÜQUQ SAHƏSİNDƏ FƏALİYYƏT",
  622: "69102 XÜSUSİ NOTARİUSLARIN FƏALİYYƏTİ",
  623: "69201 MÜHASİBAT UÇOTU VƏ VERGİLƏR ÜZRƏ MƏSLƏHƏTLƏR",
  624: "69202 AUDİTOR FƏALİYYƏTİ",
  625: "70100 MƏRKƏZİ OFİSLƏRİN FƏALİYYƏTİ",
  626: "70210 İCTİMAİ ƏLAQƏLƏRİN SAXLANILMASI VƏ MƏLUMAT MÜBADİLƏSİ",
  627: "70220 KOMMERSİYA FƏALİYYƏTİ VƏ İDARƏETMƏ MƏSƏLƏLƏRİ ÜZRƏ SAİR MƏSLƏHƏTLƏR",
  628: "71111 MEMARLIQ SAHƏSİNDƏ FƏALİYYƏT",
  629: "71112 LAYİHƏ İŞLƏRİ",
  630: "71113 GEOLOJİ-KƏŞFİYYAT VƏ GEODEZİYA AXTARIŞ İŞLƏRİ",
  631: "71114 KARTOQRAFİYA FƏALİYYƏTİ",
  632: "71115 I VƏ II MƏSULİYYƏT SƏVİYYƏLİ BİNA VƏ TİKİLİLƏRİN DÖVLƏT STANDARTLARINA UYĞUN OLARAQ LAYİHƏLƏNDİRİLMƏSİ VƏ MÜHƏNDİS AXTARIŞI",
  633: "71120 MÜHƏNDİS AXTARIŞLARI VƏ BU SAHƏDƏ TEXNİKİ MƏSLƏHƏTLƏRİN VERİLMƏSİ",
  634: "71201 TEXNİKİ SINAQ VƏ NƏZARƏT",
  635: "71202 TƏHLÜKƏ POTENSİALLI OBYEKTLƏRDƏ İSTİSMAR OLUNAN AVADANLIQLARIN VƏ TEXNİKİ QURĞULARIN DİAQNOSTİKASI VƏ DİGƏR TEXNİK YOXLAMALARIN KEÇİRİLMƏSİ",
  636: "72110 BİOTEXNOLOGİYA SAHƏSİNDƏ TƏDQİQATLAR VƏ İŞLƏMƏLƏR",
  637: "72190 TƏBİİ VƏ TEXNİKİ ELMLƏR SAHƏSİNDƏ SAIR TƏDQİQATLAR VƏ İŞLƏMƏLƏR (BİOTEXNOLOGİYADAN BAŞQA)",
  638: "72200 İCTİMAİ VƏ HUMANİTAR ELMLƏR SAHƏSİNDƏ TƏDQİQAT LAR VƏ İŞLƏMƏLƏR",
  639: "73110 REKLAM AGENLİKLƏRİNİN FƏALİYYƏTİ",
  640: "73120 KÜTLƏVİ İNFORMASİYA VASİTƏLƏRİNİN REKLAM XİDMƏTİ GÖSTƏRİLMƏSİ ÜZRƏ FƏALİYYƏTİ",
  641: "73200 BAZAR KONYUNKTURASININ TƏDQİQİ VƏ İCTİMAİ RƏYİN ÖYRƏNİLMƏSİ",
  642: "74100 İXTİSASLAŞDIRILMIŞ DİZAYN FƏALİYYƏTİ",
  643: "74200 FOTOQRAFİYA SAHƏSİNDƏ FƏALİYYƏT",
  644: "74300 TƏRCÜMƏ ÜZRƏ XİDMƏTLƏR (YAZILI VƏ ŞİFAHİ)",
  645: "74900 DİGƏR QRUPLARA DAXİL EDİLMƏYƏN SAİR PEŞƏKAR, ELMİ VƏ TEXNİKİ FƏALİYYƏT",
  646: "75000 BAYTARLIQ FƏALİYYƏTİ",
  647: "77110 MİNİK AVTOMOBİLLƏRİNIN İCARƏSİ VƏ LİZİNQİ",
  648: "77120 YÜK AVTOMOBİLLƏRİNIN İCARƏSİ VƏ LİZİNQİ",
  649: "77210 İDMAN VƏ İSTİRAHƏT ÜÇÜN NƏZƏRDƏ TUTULMUŞ MALLARIN İCARƏSİ",
  650: "77220 VİDEOLENTLƏRİN VƏ DİSKLƏRİN KİRAYƏSİ",
  651: "77290 SAİR MƏİŞƏT MƏMULATLARININ VƏ ŞƏXSİ İSTİFADƏ ƏŞYALARININ KİRAYƏSİ",
  652: "77310 KƏND TƏSƏRRÜFATI AVADANLIQLARININ KİRAYƏSİ",
  653: "77320 TİKİNTİ MAŞIN VƏ AVADANLIQLARININ KİRAYƏSİ",
  654: "77330 HESABLAMA TEXNİKASINI DAXİL ETMƏKLƏ OFİS AVADANLIQLARININ KİRAYƏSİ",
  655: "77340 SU NƏQLİYYATI VASİTƏLƏRİ VƏ AVADANLIQLARININ KİRAYƏSİ",
  656: "77350 HAVA NƏQLİYYATI VASİTƏLƏRİ VƏ AVADANLIQLARININ KİRAYƏSİ",
  657: "77390 DİGƏR QRUPLARA DAXİL EDİLMƏYƏN SAİR MAŞIN VƏ AVADANLIQLARIN İCARƏSİ",
  658: "77400 MÜƏLLİFLİK HÜQUQU İLƏ MÜDAFİƏ OLUNAN ƏSƏRLƏRDƏN BAŞQA İNTELEKTUAL MÜLKİYYƏTİN VƏ OXŞAR MƏHSULLARIN İCARƏSİ",
  659: "78100 İŞƏ DÜZƏLTMƏ AGENTLİKLƏRİN FƏALİYYƏTİ",
  660: "78102 AZƏRBAYCAN RESPUBLİKASI VƏTƏNDAŞLARININ XARİCİ ÖLKƏLƏRDƏ İŞƏ DÜZƏLMƏLƏRİNDƏ VASİTƏÇİLİK FƏALİYYƏTİ",
  661: "78200 MÜVƏQQƏTİ İŞÇİ QÜVVƏSİ İLƏ TƏMİNAT ÜZRƏ FƏALİYYƏT",
  662: "78300 SAİR ƏMƏK EHTİYATLARININ TƏQDİM EDİLMƏSİ ÜZRƏ FƏALİYYƏT",
  663: "79110 SƏYAHƏT AGENTLİKLƏRİNİN FƏALİYYƏTİ",
  664: "79120 TUROPERATORLARIN FƏALİYYƏTİ",
  665: "79900 TURİZM SAHƏSİNDƏ ƏVVƏLCƏDƏN SİFARİŞETMƏ XİDMƏTLƏRİ VƏ BUNUNLA ƏLAQƏDAR FƏALİYYƏT",
  666: "80100 ŞƏXSİ MÜHAFİZƏÇİLƏRİN VƏ ŞƏXSİ MÜHAFİZƏ BÜROLARININ FƏALİYYƏTİ",
  667: "80200 TƏHLÜKƏSİZLİK SİSTEMLƏRİNİN FƏALİYYƏTİNİN TƏMİN EDİLMƏSİ",
  668: "80300 İSTİNTAQIN APARILMASI",
  669: "81100 YARDIMÇI-TEXNİKİ XİDMƏTLƏRİN FƏALİYYƏTİ",
  670: "81210 BİNALARIN ƏSASLI TƏMİZLƏNMƏSİ VƏ YIĞIŞDIRILMASI",
  671: "81220 SAİR İSTEHSAL VƏ YAŞAYIŞ BİNALARININ TƏMİZLƏNMƏSİ VƏ YIĞIŞDIRILMASI",
  672: "81290 TƏMİZLİK İŞLƏRİ ÜZRƏ SAİR FƏALİYYƏT",
  673: "81300 MƏNZƏRƏLƏRİN DƏYİŞDİRİLMƏSİ ÜZRƏ XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  674: "82110 MÜƏSSİSƏLƏRİN, TƏŞKİLATLARIN İNZİBATİ XİDMƏTLƏRİNİN KOMPLEKS FƏALİYYƏTİ",
  675: "82190 FOTOSURƏT, SƏNƏDLƏRİN HAZIRLANMASI VƏ SAİR XÜSUSİ İNZİBATİ XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  676: "82200 TELEFON MƏLUMAT MƏRKƏZLƏRİNİN FƏALİYYƏTİ",
  677: "82300 KONQRESSLƏRİN VƏ PEŞƏKAR SALONLARIN TƏŞKİLİ",
  678: "82910 ÖDƏNİŞLƏRİN YIĞILMASI VƏ KREDİT ƏMƏLİYYATLARI HAQQINDA HESABATLAR ÜZRƏ AGENTLİKLƏRİNİN FƏALİYYƏTİ",
  679: "82920 QABLAŞDIRMA",
  680: "82990 DİGƏR QRUPLARA DAXİL EDİLMƏYƏN BİZNESİN DƏSTƏKLƏNMƏSİNƏ YÖNƏLDİLMİŞ SAİR ƏLAVƏ FƏALİYYƏT",
  681: "84111 ÜMUMİ XARAKTERLİ DÖVLƏT İDARƏETMƏSİ",
  682: "84112 YERLİ ÖZÜNÜİDARƏETMƏ ORQANLARININ FƏALİYYƏTİ",
  683: "84120 SOSİAL PROQRAMLARIN İDARƏ EDİLMƏSİ",
  684: "84130 İQTİSADİ FƏALİYYƏTİN SƏMƏRƏLİ APARILMASINA YARDIM VƏ ONUN TƏNZİMLƏNMƏSİ",
  685: "84210 BEYNƏLXALQ FƏALİYYƏT",
  686: "84220 MÜDAFİƏ FƏALİYYƏTİ",
  687: "84230 ƏDLİYYƏ VƏ ƏDALƏT MƏHKƏMƏSİ SAHƏSİNDƏ FƏALİYYƏT",
  688: "84240 İCTİMAİ QAYDALARIN VƏ TƏHLÜKƏSİZLİYİN TƏMİN EDİLMƏSİ ÜZRƏ FƏALİYYƏT",
  689: "84251 FÖVQƏLADƏ HALLAR ZAMANI TƏHLÜKƏSİZLİYİN TƏMİN EDİLMƏSİ ÜZRƏ FƏALİYYƏT",
  690: "84252 MÜƏSSİSƏLƏRİN VƏ YAŞAYIŞ MƏNTƏQƏLƏRİNİN MÜQAVİLƏ ƏSASINDA YANĞINLARDAN QORUNMASI",
  691: "84300 MƏCBURİ SOSİAL SIĞORTA SAHƏSİNDƏ FƏALİYYƏT",
  692: "85100 MƏKTƏBƏQƏDƏR TƏHSİL",
  693: "85200 İBTİDAİ TƏHSİL (BİRİNCİ DƏRƏCƏLİ)",
  694: "85311 ÜMUMİ ORTA TƏHSİL (İKİNCİ DƏRƏCƏLİ)",
  695: "85312 HAZIRLIQ KURSLARININ FƏALİYYƏTİ",
  696: "85320 ORTA TEXNİKİ VƏ PEŞƏ TƏHSİLİ",
  697: "85410 NATAMAM ALİ TƏHSİL",
  698: "85420 ALİ TƏHSİL",
  699: "85510 BƏDƏN TƏRBİYYƏSİ VƏ İDMAN SAHƏSİNDƏ TƏHSİL FƏALİYYƏTİ",
  700: "85520 MƏDƏNİYYƏT SAHƏSİNDƏ TƏHSİL FƏALİYYƏTİ",
  701: "85530 SÜRÜCÜ HAZIRLAYAN MƏKTƏBLƏRİN FƏALİYYƏTİ",
  702: "85590 DİGƏR QRUPLARA DAXİL EDİLMƏYƏN TƏHSİL SAHƏSİNDƏ SAİR FƏALİYYƏT",
  703: "85600 TƏHSİL SAHƏSİNDƏ YARDIMÇI FƏALİYYƏT",
  704: "86100 XƏSTƏXANALARIN FƏALİYYƏTİ",
  705: "86210 ÜMUMİ HƏKİM PRAKTİKASI SAHƏSİNDƏ FƏALİYYƏT",
  706: "86220 XÜSUSİ HƏKİM PRAKTİKASI SAHƏSİNDƏ FƏALİYYƏT",
  707: "86230 STOMATOLOGİYA SAHƏSİNDƏ FƏALİYYƏT",
  708: "86900 SƏHİYYƏ SAHƏSİNDƏ SAİR FƏALİYYƏT",
  709: "87100 ORTA TİBB HEYYƏTİ TƏRƏFİNDƏN XƏSTƏLƏRƏ QULLUQ VƏ YAŞAYIŞ TƏMİNATI DA DAXİL OLMAQLA SOSİAL XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  710: "87200 ƏQLİ İNKİŞAFDAN QALAN ŞƏXSLƏR, ALKOQOL VƏ NARKOTİKLƏRDƏN SUİ-İSTİFADƏ EDƏN PSİXİKİ POZULMALARI VƏ YA FİZİKİ ÇATIŞMAMAZLIQLARI OLAN ŞƏXSLƏRƏ YAŞAYIŞ TƏMİNATI DAXİL OLMAQLA SOSİAL XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  711: "87300 QOCALAR VƏ ƏMƏK QABİLİYYƏTİNİ İTİRMİŞ ŞƏXSLƏR ÜÇÜN YAŞAYIŞ TƏMİNATI DAXİL OLMAQLA SOSİAL XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  712: "87900 YAŞAYIŞI TƏMİN ETMƏKLƏ SAİR SOSİAL TƏMİNAT",
  713: "88100 QOCALAR VƏ ƏMƏK QABİLİYYƏTİNİ İTİRMİŞ ŞƏXSLƏR ÜÇÜN YAŞAYIŞA TƏMİNATSIZ SOSİAL XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  714: "88910 UŞAQLARA GÜNDÜZ VAXTI QULLUQ ETMƏ",
  715: "88990 DİGƏR QRUPLARA DAXİL EDİLMƏYƏN YAŞAYIŞA TƏMİNATSIZ SAIR SOSİAL XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  716: "90010 SƏHNƏ SƏNƏTİ",
  717: "90012 MUSİQİÇİLƏR",
  718: "90020 SƏHNƏ SƏNƏTİ SAHƏSİNDƏ YARDIMÇI FƏALİYYƏT",
  719: "90030 İNCƏSƏNƏTİN SAİR SAHƏLƏRİ ÜZRƏ FƏALİYYƏT",
  720: "90040 TEATR VƏ KONSERT ZALLARININ FƏALİYYƏTİ",
  721: "91010 KİTABXANA VƏ ARXİVLƏRİN FƏALİYYƏTİ",
  722: "91020 MUZEYLƏRİN FƏALİYYƏTİ",
  723: "91030 TARİXİ YERLƏRİN VƏ BİNALARIN MÜHAFİZƏSİ SAHƏSİNDƏ FƏALİYYƏTİ",
  724: "91040 NƏBATƏT BAĞLARININ, HEYVANXANALARIN VƏ QORUQLARIN FƏALİYYƏTİ",
  725: "92000 HƏVƏSLƏNDİRİCİ OYUNLARIN KEÇİRİLMƏSİ SAHƏSİNDƏ FƏALİYYƏT",
  726: "93110 İDMAN OBYEKTLƏRİNİN İSTİSMARI VƏ İDARƏ EDİLMƏSİ",
  727: "93120 İDMAN KLUBLARININ FƏALİYYƏTİ",
  728: "93130 FİTNES KLUBLARININ FƏALİYYƏTİ",
  729: "93190 İDMAN SAHƏSİNDƏ SAİR FƏALİYYƏT",
  730: "93210 İSTİRAHƏT VƏ ƏYLƏNCƏ PARKLARININ FƏALİYYƏTİ",
  731: "93290 İSTİRAHƏT VƏ ƏYLƏNCƏLƏRİN TƏŞKİLİ ÜZRƏ SAİR FƏALİYYƏT",
  732: "94110 KOMMERSİYA VƏ SAHİBKAR TƏŞKİLATLARIN (CƏMİYYƏTLƏRİN, BİRLİKLƏRİN, İTTİFAQLARIN) FƏALİYYƏTİ",
  733: "94120 PEŞƏKAR BİRLİKLƏRİN (İTTİFAQLARIN) FƏALİYYƏTİ",
  734: "94200 HƏMKARLAR BİRLİKLƏRİNİN (İTTİFAQLARININ) FƏALİYYƏTİ",
  735: "94910 DİNİ TƏŞKİLATLARIN FƏALİYYƏTİ",
  736: "94920 SİYASİ TƏŞKİLATLARIN FƏALİYYƏTİ",
  737: "94990 DİGƏR QRUPLARA DAXİL EDİLMƏYƏN SAİR İCTİMAİ TƏŞKİLATLARIN FƏALİYYƏTİ",
  738: "95110 KOMPÜTERLƏRİN VƏ PERİFERİYA AVADANLIQLARININ TƏMİRİ",
  739: "95120 RABİTƏ AVADANLIQLARININ TƏMİRİ",
  740: "95210 MƏİŞƏT ELEKTRİK AVADANLIQLARININ TƏMİRİ",
  741: "95220 MƏİŞƏT TEXNİKASININ VƏ BAĞ TƏSƏRRÜFATI AVADANLIQLARININ TƏMİRİ",
  742: "95230 AYAQQABI VƏ DƏRİDƏN DİGƏR MƏMULATLARIN TƏMİRİ",
  743: "95240 MEBELLƏRİN VƏ EV ÜÇÜN OXŞAR MƏMULATLARIN TƏMİRİ",
  744: "95251 SAATLARIN VƏ ZƏRGƏRLİK MƏMULATLARININ TƏMİRİ",
  745: "95252 QİYMƏTLİ METALLARDAN VƏ QİYMƏTLİ DAŞLARDAN MƏHSULLARIN, O CÜMLƏDƏN ZƏRGƏRLİK VƏ DİGƏR MƏİŞƏT MƏMULATLARININ HAZIRLANMASI VƏ TƏMİRİ",
  746: "95290 SAİR ŞƏXSİ İSTİFADƏ ƏŞYALARININ VƏ MƏİŞƏT MALLARININ TƏMİRİ",
  747: "96010 TOXUCULUQ VƏ XƏZ MƏMULATLARININ YUYULMASI, KİMYƏVİ TƏMİZLƏNMƏSİ VƏ BOYANMASI",
  748: "96020 BƏRBƏRXANA VƏ GÖZƏLLİK SALONLARI TƏRƏFİNDƏN XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  749: "96030 DƏFN MƏRASİMLƏRİNİN TƏŞKİLİ VƏ BUNUNLA ƏLAQƏDAR XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  750: "96040 FİZİKİ RAHATLIĞIN TƏMİN EDİLMƏSİ ÜZRƏ FƏALİYYƏT",
  751: "96090 DİGƏR QRUPLARA DAXİL EDİLMƏYƏN SAİR FƏRDİ XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  752: "97000 MUZDLU İŞÇİLƏRLƏ ŞƏXSİ EV TƏSƏRRÜFATLARININ FƏALİYYƏTİ",
  753: "97002 MUZDLU İŞƏ GÖRƏ ƏMƏK HAQQINDAN GƏLİR VERGİSİ ÖDƏYƏN FİZİKİ ŞƏXS",
  754: "98100 ŞƏXSİ EV TƏSƏRRÜFATINDA FƏRDİ İSTEHLAK ÜÇÜN İSTEHSAL EDİLƏN MƏHSULLAR VƏ GÖSTƏRİLƏN XİDMƏTLƏR",
  755: "98200 EV TƏSƏRRÜFATLARI TƏRƏFİNDƏN ŞƏXSİ İSTEHLAK ÜÇÜN MÜXTƏLİF XİDMƏTLƏRİN GÖSTƏRİLMƏSİ",
  756: "99000 TOXUNULMAZLIQ HÜQUQU OLAN TƏŞKİLATLARIN FƏALİYYƏTİ",
  757: "99911 BİTKİÇİLİK",
};
