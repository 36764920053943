import React, { useEffect, useState } from "react";
import ButtonDropdown from "../../../ui/ButtonDropdown/ButtonDropdown";
import Dropdown from "./parts/dropdown/Dropdown";
import FilterModel from "../../../../models/table/Filter/FilterModel";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const Counter = styled.span`
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: #aa150a;
  border-radius: 50%;
  padding: 9px;
  z-index: 1;
  width: 13px;
  height: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function Filter({
  hideParts,
  width,
  heightOfParts,
  heightDropdown,
  columns,
  applyFiltersHandler,
  cancelAppliedFilters,
  quickFilters,
}) {
  /* props ----> 

    hideParts = {
    toolbar: true,
    body: true,
    footer: true,
  }
  heightOfParts = {
    toolbar: "15%",
    body: "70%",
    footer: "15%",
  }
  */

  const [filters, setFilters] = useState([
    new FilterModel({
      column: "",
      operator: "",
      value: "",
    }),
  ]);
  const [quickFiltersState, setQuickFiltersState] = useState([]);

  const [applied, setApplied] = useState(0);
  const [isApplied, setIsApplied] = useState(false);

  useEffect(() => {
    const filteredFilters =
      quickFilters &&
      Object.values(quickFilters)?.filter((item) => item?.value);
    // check quickFilters has not value
    // if (filteredFilters.length === 0) return;

    // if (checkQuickFilters(filteredFilters)) {
    //   cancelAppliedFiltersHandler();
    //   setQuickFiltersState([]);
    //   return;
    // }

    // const filtered = filteredFilters.filter((item) => item !== "");

    setQuickFiltersState(filteredFilters);

    setTimeout(() => {
      filterApplyHandler(filteredFilters);
    }, 500);
  }, [quickFilters]);

  const checkQuickFilters = (array) => {
    const isNoValue =
      array?.length === 0 || array?.every((item) => !item.value);

    return isNoValue;
  };

  const addingQuickFilters = (array) => {
    let newFilters = [];

    if (!isApplied) {
      newFilters = [...array];
      return newFilters;
    }

    newFilters = filters.filter(
      (item) => !array.find(({ id }) => item.id === id)
    );
    return newFilters;
  };

  const checkBaseFilters = (array) => {
    const isNoValue = array?.every(
      (item) => !item.column && !item.operator && !item.value
    );
    return isNoValue;
  };

  const heights = {
    toolbar: "35px",
    body: "100%",
    footer: "35px",
  };

  const addFilterHandler = () => {
    const currArr = [...filters];
    currArr.push(
      new FilterModel({
        column: "",
        operator: "",
        value: "",
      })
    );

    setFilters(currArr);
  };

  const resetFitltersHandler = () => {
    setFilters([new FilterModel({ column: "", operator: "", value: "" })]);
    setQuickFiltersState([]);
  };

  const onChangeHandler = (key, index, value) => {
    const arr = [...filters];

    if (key === "column") {
      arr[index] = new FilterModel({
        column: value,
        operator: "",
        value: "",
      });
    } else {
      if (key === "value" && value?.toString()?.includes(",")) {
        value = value.split(",");
      } else if (key === "value" && (value === "true" || value === "false")) {
        value = value === "false" ? false : true;
      }

      arr[index][key] = value;
    }

    setFilters([...arr]);
  };

  const deleteFilterHandler = (id) => {
    const arr = [...filters].filter((item) => item.id !== id);
    setFilters([...arr]);
  };

  const filterApplyHandler = (array) => {
    const readyArr = array?.length > 0 ? array : filters;
    const forFetching = readyArr
      ?.filter((item) => {
        return item?.column.length > 0 && item?.operator.length > 0;
      })
      .map((item) => ({
        column: item?.subColumn !== "" ? item?.subColumn : item.column,
        operator: item.operator,
        value:
          item?.subColumn !== "" && typeof item.value === "string"
            ? parseInt(item.value)
            : item.value,
      }));

    if (typeof applyFiltersHandler === "function") {
      applyFiltersHandler(forFetching);
    } else {
      console.error("applyFiltersHandler is not a function");
    }

    setApplied(forFetching?.length);
    setIsApplied(true);
  };
  const cancelAppliedFiltersHandler = () => {
    setApplied(0);
    setIsApplied(false);
    resetFitltersHandler();
    cancelAppliedFilters();
  };

  const resetRowHandler = (index) => {
    const arr = [...filters];
    arr[index] = new FilterModel({
      column: "",
      operator: "",
      value: "",
    });

    setFilters([...arr]);
  };

  return (
    <Container>
      <ButtonDropdown
        title={"Filter"}
        styleButton={{ padding: "5px 30px" }}
        width={width}
        dropdownComponent={
          <Dropdown
            hideParts={hideParts}
            heightOfParts={heights}
            heightContainer={heightDropdown}
            filters={filters}
            addBtnHandler={addFilterHandler}
            resetHandler={resetFitltersHandler}
            columns={columns}
            onChange={onChangeHandler}
            onDelete={deleteFilterHandler}
            applyHandler={filterApplyHandler}
            cancelHandler={cancelAppliedFiltersHandler}
            isApplied={applied > 0}
            quickFilters={quickFiltersState}
            resetRow={resetRowHandler}
          />
        }
      />
      {applied > 0 && isApplied && <Counter>{applied}</Counter>}
    </Container>
  );
}
