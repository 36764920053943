import React, { useState, useEffect } from "react";
import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  getChainlessPart,
  getNestedChildren,
  getUtilsEmployees,
} from "../../api/api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const NestedSelect = ({ state, dispatch }) => {
  const { nestedChildren, structureId } = state;
  const [nestedChildStates, setNestedChildStates] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [positionIdSelectedIndex, setPositionIdSelectedIndex] = useState(-1); // Track the index of the form where positionId is selected

  useEffect(() => {
    // Initialize nestedChildStates with the initial nestedChildren data
    setNestedChildStates([nestedChildren]);
    setSelectedValues([]);
    setPositionIdSelectedIndex(-1); // Reset the positionId selection index
  }, [nestedChildren]);

  const nestedHandleChange = async (e, index) => {
    const selectedNestedChild = e.target.value;

    setSelectedValues((prevSelectedValues) => {
      const updatedValues = [...prevSelectedValues];
      updatedValues[index] = selectedNestedChild;
      return updatedValues;
    });

    if (selectedNestedChild.expectedType.name === "position") {
      dispatch({
        type: "SET_POSITION_ID",
        payload: selectedNestedChild._id,
      });

      const response = await getUtilsEmployees(structureId);
      dispatch({
        type: "SET_UTILS_EMPLOYEES",
        payload: response.data,
      });

      if (index === 1) {
        setPositionIdSelectedIndex(1);
      } else {
        setNestedChildStates((prevNestedChildStates) =>
          prevNestedChildStates.slice(0, index + 1)
        );
      }
    } else {
      dispatch({
        type: "SET_POSITION_ID",
        payload: "",
      });

      let params = {
        parentId: selectedNestedChild._id,
      };

      const response = await getChainlessPart(params);
      setNestedChildStates((prevNestedChildStates) => {
        const updatedNestedChildStates = [...prevNestedChildStates];
        updatedNestedChildStates[index + 1] = response.data.records;
        return updatedNestedChildStates.slice(0, index + 2);
      });

      if (index >= positionIdSelectedIndex) {
        setPositionIdSelectedIndex(-1);
      }
    }
  };

  const renderSelectContent = (index) => {
    const nestedChild = nestedChildStates[index];
    if (nestedChild.length === 0) {
      return (
        <Typography id={`structureName-${index}`}>
          There is nothing to show
        </Typography>
      );
    }

    const hasValidOptions = nestedChild.some(
      (nestedItem) =>
        nestedItem.docModel === "CompanyPart" && nestedItem.source?.name
    );
    if (!hasValidOptions) {
      return (
        <Typography id={`structureName-${index}`}>
          There is nothing to show
        </Typography>
      );
    }
    return (
      <>
        <InputLabel id={`structureName-${index}`}>Strukturu seçin</InputLabel>
        <Select
          labelId={`structureName-${index}`}
          id={`structureName-${index}`}
          input={<Input />}
          fullWidth
          MenuProps={MenuProps}
          value={selectedValues[index] || ""}
          onChange={(e) => {
            nestedHandleChange(e, index);
          }}
        >
          {nestedChild.map((nestedItem, nestedIndex) => {
            if (
              nestedItem.docModel === "CompanyPart" &&
              nestedItem.source?.name
            ) {
              return (
                <MenuItem key={nestedIndex} value={nestedItem}>
                  {nestedItem.source?.name}
                </MenuItem>
              );
            }
            return null;
          })}
        </Select>
      </>
    );
  };

  return (
    <>
      {nestedChildStates.map((nestedChild, index) => (
        <FormControl key={index} sx={{ mb: 6 }} fullWidth>
          {renderSelectContent(index)}
        </FormControl>
      ))}
    </>
  );
};

export default NestedSelect;
