import { Close } from "@mui/icons-material";
import {
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import React, { useState } from "react";

export default function DialogHeader({
  title,
  onClose,
  contractNo,
  versionHandler,
  versionData,
  currVersionState,
}) {
  const handlerOnClose = () => {
    onClose();
  };

  const generateVersion = () => {
    if (versionData?.length <= 1) {
      return <MenuItem value={0}>1</MenuItem>;
    }

    return versionData?.map((item, index) => {
      return (
        <MenuItem
          key={index}
          value={item.value}
          sx={{ backgroundColor: "#0f0f0f" }}
        >
          {item.label}
        </MenuItem>
      );
    });
  };
  // const onChangeHandler = async (e) => {
  //   const value = e.target.value;

  //   await versionHandler(value);
  //   setCurrVersion(value);
  // };

  return (
    <Grid sx={styles.outerCont}>
      <Grid sx={styles.innerCont}>
        <Grid sx={styles.headerCont}>
          <Typography variant="h3" sx={styles.header}>
            {title}
          </Typography>
        </Grid>
        <Grid sx={styles.btnCont}>
          <IconButton sx={styles.btnCont} onClick={handlerOnClose}>
            <Close />
          </IconButton>
        </Grid>
        {contractNo && (
          <Grid
            marginTop={"5px"}
            marginLeft={"10px"}
            sx={{
              display: "flex",
              height: "20px",
            }}
          >
            {versionData && (
              <FormControl>
                <div style={{ height: "100%", marginRight: "4px" }}>
                  V.{" "}
                  <Select
                    disabled={versionData.length <= 1}
                    value={currVersionState}
                    // defaultValue={versionData[versionData.length - 1].value}
                    sx={{
                      height: "100%",
                      borderWidth: "0px",
                    }}
                    onChange={(e) => versionHandler(e.target.value)}
                  >
                    {generateVersion()}
                  </Select>
                </div>
              </FormControl>
            )}

            <Typography fontWeight={"bold"}>{contractNo}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

const styles = {
  outerCont: {
    width: "100%",
    marginTop: "7px",
    marginBottom: "7px",
    // transition: "opacity 0.3s ease-in-out",
  },
  innerCont: { position: "relative" },
  headerCont: {
    margin: "auto",
  },
  header: { width: "100%", textAlign: "center" },
  btnCont: { position: "absolute", top: -5, right: 0 },
  btn: {},
};
