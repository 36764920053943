import React, { useEffect, useState } from "react";
import { Field, FieldArray, ErrorMessage, Formik, Form } from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Button,
  Checkbox,
  Typography,
  FormHelperText,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { isValid, parse } from "date-fns";
import { getCommonData } from "../../api/api";

const ViewRewardReprimands = ({ employeeById }) => {
  const [rewardOrganizations, setRewardOrganizations] = useState([]);

  useEffect(() => {
    const handleOrganizations = async () => {
      const response = await getCommonData("organizations");
      setRewardOrganizations(response.data);
    };
    handleOrganizations();
  }, []);

  const initialValues = {
    reprimandsAndRewards: employeeById.reprimandsAndRewards || [],
  };

  return (
    <>
      {employeeById ? (
        <Formik
          initialValues={{
            reprimandsAndRewards: initialValues.reprimandsAndRewards || [], // Set the initial laborActivity values from employeeById
          }}
        >
          {({
            values,
            isValid,
            errors,
            touched,
            isSubmitting,
            handleChange,
          }) => (
            <Form style={{ pointerEvents: "none" }}>
              <FieldArray name="reprimandsAndRewards">
                {({ push, remove }) => (
                  <div>
                    {values.reprimandsAndRewards.map((form, index) => (
                      <div key={index}>
                        <>
                          <Typography mt={5} variant="h6">
                            {index + 1}Mükafat, təltif və tənbehləri
                          </Typography>

                          {/* Rewards/ Reprimands and  description */}
                          <Grid sx={{ display: "flex", gap: 3, mb: 3, mt: 5 }}>
                            <Grid sx={{ width: "100%" }}>
                              <FormControl fullWidth>
                                <InputLabel
                                  id={`reprimandsAndRewards[${index}].rType`}
                                >
                                  Tənbeh / Mükafat / Təltif
                                </InputLabel>
                                <Field
                                  as={Select}
                                  name={`reprimandsAndRewards[${index}].rType`}
                                  labelId={`rreprimandsAndRewards[${index}].rType`}
                                  label="Mükafat / Təltif / Tənbeh"
                                  id={`reprimandsAndRewards[${index}].rType`}
                                  defaultValue=""
                                  fullWidth
                                >
                                  <MenuItem value="prize">Mükafat</MenuItem>
                                  <MenuItem value="reprimand">Tənbeh</MenuItem>
                                  <MenuItem value="reward">Təltif</MenuItem>
                                </Field>
                              </FormControl>
                            </Grid>
                            <Grid sx={{ width: "100%" }}>
                              <Field
                                type="text"
                                name={`reprimandsAndRewards[${index}].description`}
                                as={TextField}
                                fullWidth
                                label="Mükafat / Təltif / Tənbehin məzmunu"
                                value={
                                  employeeById?.reprimandsAndRewards?.[index]
                                    ?.description
                                }
                              />
                            </Grid>
                          </Grid>

                          {/* start date and company */}
                          <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                            <Grid sx={{ width: "100%" }}>
                              <Field
                                name={`reprimandsAndRewards[${index}].date`}
                              >
                                {({ field, form }) => (
                                  <DatePicker
                                    {...field}
                                    label="Mükafat / Təltif / Tənbehin qüvvəyə mindiyi tarix"
                                    inputFormat="dd/MM/yyyy"
                                    value={
                                      employeeById?.reprimandsAndRewards?.[
                                        index
                                      ]?.date || null
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        helperText={
                                          form.errors?.reprimandsAndRewards?.[
                                            index
                                          ]?.date
                                        }
                                        error={Boolean(
                                          form.errors?.reprimandsAndRewards?.[
                                            index
                                          ]?.date
                                        )}
                                        fullWidth
                                      />
                                    )}
                                  />
                                )}
                              </Field>
                            </Grid>
                            <Grid sx={{ width: "100%" }}>
                              <FormControl
                                error={
                                  errors?.reprimandsAndRewards?.[index]
                                    ?.organization &&
                                  Boolean(
                                    errors?.reprimandsAndRewards?.[index]
                                      ?.organization
                                  )
                                }
                                fullWidth
                              >
                                <InputLabel
                                  id={`reprimandsAndRewards[${index}].organization`}
                                >
                                  Mükafat / Təltif / Tənbehin təqdim olunduğu
                                  müəssisə *
                                </InputLabel>
                                <Field
                                  as={Select}
                                  name={`reprimandsAndRewards[${index}].organization`}
                                  labelId={`reprimandsAndRewards[${index}].organization`}
                                  label="Mükafat / Təltif / Tənbehin təqdim olunduğu müəssisə *"
                                  id={`reprimandsAndRewards[${index}].organization`}
                                  defaultValue=""
                                  fullWidth
                                >
                                  <MenuItem
                                    selected
                                    key={index}
                                    value={
                                      employeeById?.reprimandsAndRewards[index]
                                        ?.organization
                                    }
                                  >
                                    {
                                      employeeById?.reprimandsAndRewards[index]
                                        ?.organization
                                    }
                                  </MenuItem>
                                </Field>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </>
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default ViewRewardReprimands;
