/* eslint-disable prettier/prettier */
import {
  Typography,
  Grid,
  Button,
  Dialog,
  Box,
  TextField,
  FormControl,
  Select,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Autocomplete,
  InputLabel,
  IconButton,
} from "@mui/material";
import { CloudDownload } from "@mui/icons-material";

import { CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import { spacing, style } from "@mui/system";

import React, { useEffect, useState } from "react";
import Header from "../../components/layouts/header/Header";
import {
  EMPLOYMENT_CONTRACT_TYPE,
  LEAVES_STATUS,
  OPERATORS_CONTAINING,
  PUBLIC_URL,
  clauses,
} from "../../constants";
// import { getDescendantProp } from "../../utils/helperFunctions";
import { DatePicker } from "@mui/x-date-pickers";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete as DeleteIcon } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { getAllEmployees } from "../../api/api";
import Column from "../../models/table/Column";
import Table from "../../components/table/Table";
import styled from "styled-components";

import {
  addNewMedicalOrganization,
  convertFileToBase64,
  createNewBulletinData,
  deleteTLOWCData,
  downloadDatagridDatas,
  fileToBase64,
  getBase64String,
  getBulletins,
  getMedicalOrganizationsList,
  getTLOWCData,
  updateTLOWCData,
} from "./services";
import { getDescendantProp, normalizeDate } from "../../utils/helperFunctions";
import Operator from "../../models/table/Operator";
import OperatorGenerator from "../../components/table/OperatorGenerator";
import { borderRadius, normalize } from "polished";
import { downloadFile } from "../../utils/downloadCSV";
import {
  getAllStructureRequestsNames,
  getAllStructuresForFilter,
  getPartsForFilter,
  getPositionsForFilter,
  getStaticFilterValues,
  getTopPartsForFilter,
} from "../../utils/forFilterDropdown/getStructureUtils";

const CloudUpload = styled(MuiCloudUpload)(spacing);

export default function Bulletin() {
  // datagrid states
  const [filters, setFilters] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [show_Accordion, set_show_Accordion] = useState(false);

  // employees
  const [employee, set_employee] = useState({});
  // butun tibbi muessiseler
  const [medicalOrgs, setMedicalOrgs] = useState([]);
  // elave olunan tibbi muessise
  const [medicalOrgName, setMedicalOrgName] = useState("");
  // secilen eqmi id-si
  const [dataID, setDataID] = useState("");
  // tek eqmi data si
  const [TLOWCData, setTLOWCData] = useState();

  // dialogs
  const [deleteDataDialogue, setDeleteDataDialogue] = useState(false);
  const [viewDataDialogue, setViewDataDialogue] = useState(false);
  const [editDataDialogue, setEditDataDialogue] = useState(false);
  const [medicalOrgAdd, setMedicalOrgAdd] = useState(false);
  const [pdfDownloadLink, setPdfDownloadLink] = useState("");

  // create button disable state
  const [create_btn_disable, set_create_btn_disable] = useState(false);
  // bulletin yarat melumatlari
  const [leave_state, set_leave_state] = useState({
    medicalOrganization: "",
    employee: "",
    startDate: "",
    endDate: "",
    submissionDate: "",
    returnDate: "",
    issuancePdf: [],
    reason: "",
    documentNumber: "",
  });
  // bulletin melumat yenileme
  const [updateDatas, setUpdateDatas] = useState({
    medicalOrganization: "",
    startDate: "",
    endDate: "",
    submissionDate: "",
    returnDate: "",
    documentNumber: "",
  });
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    set_create_btn_disable(
      leave_state?.submissionDate === "" ||
        leave_state?.medicalOrganization === "" ||
        leave_state?.returnDate === "" ||
        leave_state?.startDate === "" ||
        leave_state?.endDate === "" ||
        leave_state?.documentNumber === "" ||
        leave_state?.issuancePdf.length === 0 ||
        leave_state?.reason === "" ||
        leave_state?.employee === ""
    );
  }, [leave_state]);
  useEffect(() => {
    paginationFetchingHandler();
  }, [filters, limit, offset]);

  useEffect(() => {
    getEmployees();
    getMedicalOrganizations();
  }, []);

  const paginationFetchingHandler = async () => {
    if (filters.length) {
      const trimmedFilters = filters.map((item) => {
        if (Array.isArray(item.value)) {
          return {
            ...item,
            value: item.value.map((e) => e.trim()),
          };
        }

        return {
          ...item,
          value: item.value.trim(),
        };
      });
      const encodedFilter = encodeURIComponent(JSON.stringify(trimmedFilters));
      await getResponse(encodedFilter);
    } else {
      await getResponse();
    }
  };

  //api sorgulari

  // butun eqmi ler sorgusu
  const getResponse = async (filters = undefined) => {
    try {
      setIsLoading(true);

      const { data } = await getBulletins({
        limit: limit || 5,
        offset: offset || 0,
        filters,
      });
      setTotal(data.total);
      setRowData(
        data.data.map((item, index) => {
          return {
            ...item,
          };
        })
      );
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  // emekdaslarin siyahisi sorgusu
  const getEmployees = async () => {
    try {
      const response = await getAllEmployees();
      if (response?.data?.employees) {
        let emp = response?.data?.employees
          ?.filter((e) => e?.status !== "inactive")
          .map((e) => ({
            firstname: e?.firstname,
            lastname: e?.lastname,
            fathername: e?.fathername,
            id: e?._id,
          }));
        set_employee(emp);
      }
    } catch (error) {
      console.log("general statistics", error);
    }
  };
  // sehiyye orqanlari sorgusu
  const getMedicalOrganizations = async () => {
    try {
      const response = await getMedicalOrganizationsList();
      if (response?.data) {
        setMedicalOrgs(response.data);
      }
    } catch (error) {
      console.log("medicalorg", error);
    }
  };
  // yeni sehiyye orqani yaradilmasi sorgusu
  const createNewMedicalOrg = async (data) => {
    await addNewMedicalOrganization([
      {
        key: "medicalOrganizationList",
        values: [data],
      },
    ]);
    await getMedicalOrganizations();
  };
  // yeni bulleten yaradilma sorgusu
  const createNewBulletin = async (data) => {
    if (data.issuancePdf) {
      const file = data.issuancePdf;
      const base64Data = await fileToBase64(file);

      data = { ...data, issuancePdf: base64Data };
    }
    await createNewBulletinData(data);
    set_leave_state({
      medicalOrganization: "",
      employee: "",
      startDate: "",
      endDate: "",
      submissionDate: "",
      returnDate: "",
      issuancePdf: [],
      reason: "",
      documentNumber: "",
    });
  };
  // eqmi silinmesi sorgusu
  const deleteData = async (data) => {
    await deleteTLOWCData(data);
    await getResponse();
  };
  // eqmi melumatlari elde etme sorgusu
  const getData = async (id) => {
    const data = await getTLOWCData(id);
    setTLOWCData(data.data);
    const pdfFile = data.data.data.issuancePdf;
    if (pdfFile[0]) {
      setPdfDownloadLink(
        `${PUBLIC_URL}/api/file/${data.data.data.issuancePdf[0]._id}`
      );
    }

    setViewDataDialogue(true);
  };
  //edit ucun eqmi melumatlari elde etme sorgusu
  const getDataForEdit = async (id) => {
    const data = await getTLOWCData(id);
    const mainData = data?.data?.data;
    setUpdateDatas({
      id: mainData._id,
      returnDate: mainData?.returnDate,
      startDate: mainData?.startDate,
      endDate: mainData?.endDate,
      submissionDate: mainData?.submissionDate,
      medicalOrganization: mainData?.medicalOrganization,
      documentNumber: mainData?.documentNumber,
    });

    setEditDataDialogue(true);
  };

  // eqmi yenileme sorgusu
  const editData = async (data) => {
    updateTLOWCData(data);
    getResponse();
    setEditDataDialogue(false);
  };

  // datagrid melumatlari

  const operatorGeneratorHandler = (row, column) => {
    const operators = [
      new Operator({
        icon: <VisibilityIcon />,
        onClick: () => {
          getData(row._id);
        },
        type: 0,
      }),
      new Operator({
        icon: <EditIcon />,
        onClick: () => {
          getDataForEdit(row._id);
        },
        type: 0,
      }),
      new Operator({
        icon: <DeleteIcon />,
        onClick: () => {
          setDeleteDataDialogue(true);
          setDataID(row._id);
        },
        hideCondition: row.isUsedInStaff,
        type: 0,
      }),
    ];

    return <OperatorGenerator operators={operators} />;
  };

  const column = [
    new Column({
      path: "#",
      headerName: "Operatorlar",
      disableColSearch: true,
      // isStaticColumn: true,
      widthOfColumn: 200,
      renderCell: operatorGeneratorHandler,
    }),
    new Column({
      path: "employee.pin",
      headerName: "Fin",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.firstname",
      headerName: "Ad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.lastname",
      headerName: "Soyad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.fathername",
      headerName: "Ata adı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "",
      headerName: "Struktur",
      filterColumnKey: "baseStructures",
      filterType: OPERATORS_CONTAINING.IN,
      fetchFilterValuesV2: getStaticFilterValues,
      dataGridName: "temporary-loss",
      dependencieKeysOfFilter: {
        structures: ["baseStructures"],
      },
      keyForResponseDataFromFilter: "baseStructures",

      renderCell: (row, col) => {
        return row.contracts.map((item, index) => {
          return <div>{item?.baseStructures?.[0].organization?.name}</div>;
        });
      },
    }),
    new Column({
      path: "",
      headerName: "Sub-Struktur",
      filterType: OPERATORS_CONTAINING.IN,
      filterColumnKey: "subStructures",

      fetchFilterValuesV2: getStaticFilterValues,
      dataGridName: "temporary-loss",
      dependencieKeysOfFilter: {
        structures: ["baseStructures"],
        topPartOfStructures: ["topPartOfStructures"],
      },
      keyForResponseDataFromFilter: "topPartOfStructures",

      renderCell: (row, col) => {
        return row.contracts.map((item, index) => {
          return <div>{item?.subStructures?.[0].source?.name}</div>;
        });
      },
    }),
    new Column({
      path: "",
      headerName: "Vəzifə",
      filterColumnKey: "positions",

      filterType: OPERATORS_CONTAINING.IN,
      fetchFilterValuesV2: getStaticFilterValues,
      dataGridName: "temporary-loss",
      dependencieKeysOfFilter: {
        structures: ["topPartOfStructures"],
        topPartOfStructures: ["subStructures"],
      },
      keyForResponseDataFromFilter: "positions",
      renderCell: (row, col) => {
        return row.contracts.map((item, index) => {
          return <div>{item?.positions?.[0].source?.name}</div>;
        });
      },
    }),
    new Column({
      path: "reason",
      headerName: "Səbəb",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "documentNumber",
      headerName: "Sənəd No",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "submissionDate",
      headerName: "Təqdim olunma tarixi",
      filterType: OPERATORS_CONTAINING.DATE,

      getValue: (row, column) =>
        normalizeDate(getDescendantProp(row, column.path)),
    }),
    new Column({
      path: "medicalOrganization",
      headerName: "Səhiyyə orqanı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "startDate",
      headerName: "Başlama tarixi",
      filterType: OPERATORS_CONTAINING.DATE,

      getValue: (row, column) =>
        normalizeDate(getDescendantProp(row, column.path)),
    }),
    new Column({
      path: "endDate",
      headerName: "Bitmə tarixi",
      filterType: OPERATORS_CONTAINING.DATE,

      getValue: (row, column) =>
        normalizeDate(getDescendantProp(row, column.path)),
    }),
    new Column({
      path: "daysOutOfWork",
      headerName: "Müddət",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "returnDate",
      headerName: "İşə çıxma",
      filterType: OPERATORS_CONTAINING.DATE,

      getValue: (row, column) =>
        normalizeDate(getDescendantProp(row, column.path)),
    }),
  ];

  const downlaodHandler = async (columnsForExport) => {
    try {
      const colsExp = [];

      columnsForExport.forEach((item) => {
        if (item.isStaticColumn || !item.isShow) return;
        colsExp.push({
          fieldName: item.headerName,
          path: item.path,
          isArr: item.forExportIsArr,
          combineFields:
            item.combineFields?.length > 0 ? item.combineFields : undefined,
        });
      });

      const encodeColumns = encodeURIComponent(JSON.stringify(colsExp));
      const encodeFilters = encodeURIComponent(JSON.stringify([...filters]));
      const resp = await downloadDatagridDatas({
        columns: encodeColumns,
        filters: encodeFilters,
      });
      if (resp) downloadFile(resp);
    } catch (error) {
      console.log("err", error);
    }
  };

  // sehife basliqlari

  const headerData = {
    title: "Əmək qabiliyyətinin müvəqqəti itirilməsi",
    variant: "h1",
  };

  const headerData2 = {
    title: "ƏQMİ reyestri",
    variant: "h3",
  };

  return (
    <React.Fragment>
      <Grid sx={{ width: "100%", textAlign: "center", marginBottom: "50px" }}>
        <Header data={headerData} />
      </Grid>
      <Box>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => set_show_Accordion(!show_Accordion)}
            variant="contained"
            sx={{ height: "100%" }}
          >
            <Typography>Bulletin yarat</Typography>
          </Button>
        </div>
        {show_Accordion && (
          <>
            <div className="d-flex">
              {/* emekdaslar */}
              <div className="w-25 ">
                <InputLabel sx={{ marginBottom: "8px", color: "white" }}>
                  Əməkdaşlar
                </InputLabel>

                <Autocomplete
                  sx={{ width: "100%" }}
                  options={employee}
                  // onChange={onSaveEmployeeId}
                  renderInput={(params) => (
                    <TextField {...params} label="Əməkdaşı seçin" />
                  )}
                  getOptionLabel={(option) =>
                    `${option.firstname} ${option?.lastname} ${option?.fathername}`
                  }
                  inputValue={searchValue}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onInputChange={(event, newInputValue) => {
                    setSearchValue(newInputValue);
                  }}
                  onChange={(e, newInputValue) => {
                    set_leave_state({
                      ...leave_state,
                      employee: newInputValue?.id ? newInputValue.id : "",
                    });
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {`${option.firstname} ${option.lastname} ${option.fathername}`}
                      </li>
                    );
                  }}
                />
              </div>
              {/* sehiyye ocaqlari */}
              <div className="w-25 ms-2 ">
                <InputLabel sx={{ color: "white" }}>
                  Səhiyyə Ocaqları
                </InputLabel>
                <div className="mt-2">
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={leave_state?.medicalOrganization}
                      onChange={(e) =>
                        set_leave_state({
                          ...leave_state,
                          medicalOrganization: e.target.value,
                        })
                      }
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Səhiyyə ocağını seçin
                      </MenuItem>
                      {medicalOrgs?.length &&
                        medicalOrgs?.map((e, i) => (
                          <MenuItem key={i} value={e}>
                            {e}
                          </MenuItem>
                        ))}
                      <Button
                        sx={{ marginLeft: "10px" }}
                        variant="contained"
                        color="primary"
                        onClick={() => setMedicalOrgAdd(true)}
                      >
                        Əlavə et
                      </Button>
                    </Select>
                  </FormControl>
                </div>
              </div>
              {/* sened nomresi */}
              <div className="w-25 ms-2 ">
                <InputLabel sx={{ color: "white" }}>Sənəd nömrəsi</InputLabel>
                <div className="mt-2">
                  <FormControl fullWidth>
                    <TextField
                      value={leave_state?.documentNumber}
                      onChange={(e) =>
                        set_leave_state({
                          ...leave_state,
                          documentNumber: e.target.value,
                        })
                      }
                      displayEmpty
                    />
                  </FormControl>
                </div>
              </div>
              {/* file yukle */}
              <div
                className="w-25 ms-2 "
                style={{
                  display: "flex",
                }}
              >
                <InputLabel sx={{ color: "white" }}>Fayl yüklə</InputLabel>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <span className="upload" style={classes.mr_6}>
                    {leave_state.issuancePdf.length !== 0 ? (
                      <span>{leave_state.issuancePdf.name}</span>
                    ) : (
                      <></>
                    )}
                    <input
                      type="file"
                      id="raised-button-file"
                      name="templateFile"
                      accept=".pdf, .doc, .docx"
                      style={classes.d_none}
                      onChange={(e) => {
                        if (e.target.files.length > 0) {
                          const file = e.target.files[0];
                          set_leave_state({
                            ...leave_state,
                            issuancePdf: file,
                          });
                        }
                      }}
                    />
                    <label htmlFor="raised-button-file">
                      <Button
                        variant="outlined"
                        color="primary"
                        component="span"
                      >
                        <CloudUpload mr={2} /> Fayl yüklə
                      </Button>
                    </label>
                  </span>
                </div>
              </div>
            </div>
            {/* sebeb */}
            <div className="d-flex mt-3">
              <div className="w-100 ">
                <div>Səbəbi</div>
                <div className="mt-2">
                  <TextField
                    onChange={(e) =>
                      set_leave_state({
                        ...leave_state,
                        reason: e.target.value,
                      })
                    }
                    value={leave_state?.reason}
                    fullWidth
                    placeholder="Səbəbi"
                  />
                </div>
              </div>
            </div>
            {/* tarixler */}
            <div className="d-flex mt-3">
              <div className="w-25 ">
                <div>Təqdim etmə tarixi</div>
                <div className="mt-2">
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={leave_state?.submissionDate || null}
                    // maxDate={new Date()}
                    // minDate={new Date(new Date().getTime() - 86400000 * 30)}
                    onChange={(date) =>
                      set_leave_state({ ...leave_state, submissionDate: date })
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </div>
              </div>
              <div className="w-25 ms-2">
                <div>Başlama tarixi</div>
                <div className="mt-2">
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={leave_state?.startDate || null}
                    // maxDate={new Date()}
                    // minDate={new Date(new Date().getTime() - 86400000 * 30)}
                    onChange={(date) => {
                      set_leave_state({
                        ...leave_state,
                        startDate: date,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </div>
              </div>
              <div className="w-25 ms-2">
                <div>Bitmə tarixi</div>
                <div className="mt-2">
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={leave_state?.endDate || null}
                    // maxDate={new Date()}
                    // minDate={new Date(new Date().getTime() - 86400000 * 30)}
                    onChange={(date) =>
                      set_leave_state({ ...leave_state, endDate: date })
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </div>
              </div>
              <div className="w-25 ms-2">
                <div>İşə çıxma tarixi</div>
                <div className="mt-2">
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={leave_state?.returnDate || null}
                    // maxDate={new Date()}
                    // minDate={new Date(new Date().getTime() - 86400000 * 30)}
                    onChange={(date) =>
                      set_leave_state({ ...leave_state, returnDate: date })
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </div>
              </div>
            </div>
            {/* yarat button */}
            <div className="w-25 mt-3">
              <Button
                onClick={() => {
                  createNewBulletin(leave_state);
                }}
                variant="contained"
                disabled={create_btn_disable}
              >
                Yarat
              </Button>
            </div>
          </>
        )}
        <Grid sx={{ marginTop: "30px" }}>
          <Grid>
            <Grid>
              <Header data={headerData2} />
            </Grid>
          </Grid>
        </Grid>
        {/* table */}
        <div className="mt-4">
          <Table
            columns={column}
            data={rowData}
            totalRows={total}
            offset={offset}
            setOffset={(value) => setOffset(value)}
            limit={limit}
            setLimit={(value) => setLimit(value)}
            isLoading={isLoading}
            applyFiltersHandler={(filters) => setFilters(filters)}
            cancelAppliedFilters={() => setFilters([])}
            downloadHandler={(cols) => downlaodHandler(cols)}
          />
        </div>
      </Box>
      {/* add medical organization dialog */}
      <Dialog
        open={medicalOrgAdd}
        onClose={() => setMedicalOrgAdd(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Yeni tibb müəssisəsi əlavə edin
        </DialogTitle>
        <div style={{ padding: "10px 20px" }}>
          <TextField
            onChange={(e) => setMedicalOrgName(e.target.value)}
            value={medicalOrgName}
            fullWidth
            placeholder="Tibb müəssisəsinin adını daxil edin"
          />
        </div>
        <DialogActions>
          <Button
            onClick={() => {
              createNewMedicalOrg(medicalOrgName);
              setMedicalOrgName("");
              set_leave_state({
                ...leave_state,
                medicalOrganization: "",
              });
              setUpdateDatas({
                ...updateDatas,
                medicalOrganization: "",
              });
              setMedicalOrgAdd(false);
            }}
          >
            Əlavə et
          </Button>
        </DialogActions>
      </Dialog>
      {/* delete dialog */}
      <Dialog
        open={deleteDataDialogue}
        onClose={() => setDeleteDataDialogue(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Məlumat silinsin?</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteDataDialogue(false);
            }}
          >
            Xeyr
          </Button>
          <Button
            onClick={() => {
              setDeleteDataDialogue(false);
              deleteData(dataID);
            }}
          >
            Bəli
          </Button>
        </DialogActions>
      </Dialog>
      {/* view dialog */}
      <Dialog
        open={viewDataDialogue}
        onClose={() => {
          setPdfDownloadLink("");
          setViewDataDialogue(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ƏQMİ haqqında ətraflı məlumat
        </DialogTitle>
        {TLOWCData && (
          <DialogContent
            style={{
              padding: "10px 20px",
              width: "100%",
            }}
          >
            <div style={{ textAlign: "end" }}>
              <h4>Sənəd nömrəsi:{TLOWCData?.data?.documentNumber}</h4>
            </div>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                columnGap: "30px",
              }}
            >
              <div style={styles.formContainer}>
                <TextField
                  disabled
                  label="Ad"
                  value={TLOWCData?.data?.employee?.firstname}
                />
                <TextField
                  disabled
                  label="Soyad"
                  value={TLOWCData?.data?.employee?.lastname}
                />
                <TextField
                  disabled
                  label="Ata adı"
                  value={TLOWCData?.data?.employee?.fathername}
                />
                <TextField
                  disabled
                  label="Fin"
                  value={TLOWCData?.data?.employee?.pin}
                />
                <TextField
                  disabled
                  label="Sturktur"
                  value={TLOWCData?.data?.contracts
                    .map((item) => item?.baseStructures?.[0].organization?.name)
                    .join(",")}
                />
                <TextField
                  disabled
                  label="Sub-struktur"
                  value={TLOWCData?.data?.contracts
                    .map((item) => item?.subStructures?.[0].source?.name)
                    .join(",")}
                />
                <TextField
                  disabled
                  label="Vəzifə"
                  value={TLOWCData?.data?.contracts
                    .map((item) => item?.positions?.[0].source?.name)
                    .join(",")}
                />
              </div>
              <div style={styles.formContainer}>
                <TextField
                  disabled
                  label="Səhiyyə orqanı"
                  value={TLOWCData?.data?.medicalOrganization}
                />
                <TextField
                  disabled
                  label="Təqdim olunma tarixi"
                  value={normalizeDate(TLOWCData?.data?.submissionDate)}
                />
                <TextField
                  disabled
                  label="Başlama tarixi"
                  value={normalizeDate(TLOWCData?.data?.startDate)}
                />
                <TextField
                  disabled
                  label="Bitmə tarixi"
                  value={normalizeDate(TLOWCData?.data?.endDate)}
                />
                <TextField
                  disabled
                  label="İşə çıxma tarixi"
                  value={normalizeDate(TLOWCData?.data?.returnDate)}
                />
                <TextField
                  disabled
                  label="Müddət"
                  value={TLOWCData?.data?.daysOutOfWork}
                />
                <Grid sx={styles.downBtnCont}>
                  <IconButton
                    disabled={!pdfDownloadLink}
                    sx={styles.downloadBtn}
                    href={pdfDownloadLink}
                    target="_blank"
                  >
                    <CloudDownload sx={{ fontSize: 32, marginRight: 2 }} />
                    <Typography>Sənədi yüklə</Typography>
                  </IconButton>
                </Grid>
              </div>
            </div>
          </DialogContent>
        )}
      </Dialog>
      {/* edit dialog */}
      <Dialog
        open={editDataDialogue}
        onClose={() => setEditDataDialogue(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ƏQMİ məlumatlarını dəyiş
        </DialogTitle>
        {updateDatas && (
          <DialogContent
            style={{
              padding: "10px 20px",
              // width: "100vh",
            }}
          >
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                columnGap: "30px",
                width: "100%",
              }}
            >
              <div style={styles.formContainer}>
                <div>
                  <div>Təqdim etmə tarixi</div>
                  <div>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      value={updateDatas?.submissionDate || null}
                      // maxDate={new Date()}
                      // minDate={new Date(new Date().getTime() - 86400000 * 30)}
                      onChange={(date) =>
                        setUpdateDatas({
                          ...updateDatas,
                          submissionDate: date,
                        })
                      }
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </div>
                </div>
                <div>
                  <div>Başlama tarixi</div>
                  <div>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      value={updateDatas?.startDate || null}
                      // maxDate={new Date()}
                      // minDate={new Date(new Date().getTime() - 86400000 * 30)}
                      onChange={(date) => {
                        setUpdateDatas({
                          ...updateDatas,
                          startDate: date,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </div>
                </div>
                <div>
                  <div>Bitmə tarixi</div>
                  <div>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      value={updateDatas?.endDate || null}
                      // maxDate={new Date()}
                      // minDate={new Date(new Date().getTime() - 86400000 * 30)}
                      onChange={(date) =>
                        setUpdateDatas({ ...updateDatas, endDate: date })
                      }
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </div>
                </div>
                <div>
                  <div>İşə çıxma tarixi</div>
                  <div>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      value={updateDatas?.returnDate || null}
                      // maxDate={new Date()}
                      // minDate={new Date(new Date().getTime() - 86400000 * 30)}
                      onChange={(date) =>
                        setUpdateDatas({ ...updateDatas, returnDate: date })
                      }
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div style={styles.formContainer}>
                <div>
                  <div sx={{ color: "white" }}>Səhiyyə Ocaqları</div>
                  <div>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={updateDatas?.medicalOrganization}
                        onChange={(e) =>
                          setUpdateDatas({
                            ...updateDatas,
                            medicalOrganization: e.target.value,
                          })
                        }
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          Səhiyyə ocağını seçin
                        </MenuItem>
                        {medicalOrgs?.length &&
                          medicalOrgs?.map((e, i) => (
                            <MenuItem key={i} value={e}>
                              {e}
                            </MenuItem>
                          ))}
                        <Button
                          sx={{ marginLeft: "10px" }}
                          variant="contained"
                          color="primary"
                          onClick={() => setMedicalOrgAdd(true)}
                        >
                          Əlavə et
                        </Button>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div>
                  <div>Sənəd nömrəsi</div>
                  <div>
                    <TextField
                      fullWidth
                      value={updateDatas?.documentNumber}
                      onChange={(e) => {
                        setUpdateDatas({
                          ...updateDatas,
                          documentNumber: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    height: "100%",
                  }}
                >
                  <Grid sx={styles.downBtnCont}>
                    <Button
                      onClick={() => {
                        editData(updateDatas);
                      }}
                      variant="contained"
                    >
                      Dəyişiklik et
                    </Button>
                  </Grid>
                </div>
              </div>
            </div>
          </DialogContent>
        )}
      </Dialog>
    </React.Fragment>
  );
}

// CSS
const classes = {
  flex: {
    display: "flex",
    alignItems: "center",
  },
  cardContent: {
    padding: "16px",
    justifyContent: "space-between",
  },
  mb_3: {
    marginBottom: "8px",
  },
  mr_4: {
    marginRight: "4px",
  },
  mr_6: {
    marginRight: "6px",
  },
  mr_15: {
    marginRight: "15px",
  },
  d_none: {
    display: "none",
  },
  border_radius_8: {
    borderRadius: "8px",
  },
  select_styles: {
    position: "absolute",
    maxWidth: "20%",
    color: "#333",
  },
};

const styles = {
  downloadBtn: {
    margin: "auto",
    width: "100%",
    backgroundColor: "#4279d4",
    borderRadius: 1,
    color: "rgba(0,0,0,0.7)",
    "&:hover": {
      backgroundColor: "grey",
      color: "#fff",
    },
  },
  statisticContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
  },
  endContractsContainer: {
    display: "flex",
    flexDirection: "row",
    width: "auto",
    height: "400px",
    marginTop: "10px",
  },
  contractsSettingsCont: {
    width: "20%",
    borderRadius: 2,
    padding: 2.5,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    marginRight: 2,
  },
  settingsHeader: { fontSize: 16 },
  settingsLabelCont: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 2,
    marginLeft: 2,
  },
  allContractsContainer: {
    marginTop: 5,
    width: "100%",
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formContainer: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
};
