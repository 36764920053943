import styled from "@emotion/styled";
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AccountCircle, Close } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  Fab,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  TextField as MuiTextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Trash } from "react-feather";
import {
  deleteChat,
  getAllChats,
  getEmployeeByUserId,
  getMessages,
  getSingleChat,
  getUsers,
  markChatAsRead,
  postMessage,
} from "../../api/api";
import CreateChatDialog from "../../components/chat/CreateChatDialog";
import GroupChatUsers from "../../components/chat/GroupChatUsers";
import useAuth from "../../hooks/useAuth";
// import socket from "../../utils/socket/socketUtils";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import moment from "moment";
import ChatImageDialog from "../../components/chat/ChatImageDialog";
import DeleteChatDialog from "../../components/chat/DeleteChatDialog";
import { SocketContext } from "../../contexts/SocketContext";
import { chatReducer, initialState } from "../../redux/ChatReducer";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const ChatContainer = styled(Grid)`
  width: 100%;
  height: 65vh;
`;

const ChatSidebar = styled(Grid)`
  border-right: 1px solid ${(props) => props.theme.palette.divider};
`;

const ChatMain = styled(Grid)``;

const ChatMessages = styled.div`
  overflow-y: scroll;
  height: ${({ $existFile }) =>
    $existFile ? "calc(65vh - 174px)" : "calc(65vh - 94px)"};
`;

const ChatMessage = styled.div`
  margin: 30px;
  text-align: ${(props) => props.position};
`;

const ChatMessageInner = styled.div`
  display: inline-block;
`;

const ChatMessageTime = styled(Typography)`
  text-align: right;
  opacity: 0.8;
`;

const ChatMessageAvatar = styled(Avatar)`
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const ChatMessageBubble = styled.div`
  display: inline-block;
  margin-right: auto;
  background: ${(props) =>
    props.highlighted
      ? props.theme.palette.secondary.main
      : props.theme.palette.action.hover};
  color: ${(props) =>
    props.highlighted
      ? props.theme.palette.common.white
      : props.theme.palette.text.primary};
  border-radius: 3px;
  padding: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
  ${(props) => props.theme.shadows[1]};
`;

const ChatMessageBubbleName = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;

const ChatInput = styled(Grid)`
  min-height: 94px;
  padding: ${(props) => props.theme.spacing(5)};
`;

const Online = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

function ChatMessageComponent({
  currentUser,
  user,
  time,
  position = "left",
  handleMoreMessages,
  state,
  dispatch,
  messagesEndRef,
}) {
  const {
    selectedUser,
    getUserMessage,
    messageLimit,
    chatLength,
    readStatusUsers,
  } = state;
  const normalizeDate = (dateString) => {
    const date = moment(dateString);
    return date.format("HH:mm DD/MM/YYYY");
  };

  const [show, setShow] = useState(false);
  const [img, setImg] = useState(null);
  const formatContent = (content) => {
    const charLimit = 50;
    let formattedContent = "";
    for (let i = 0; i < content.length; i += charLimit) {
      formattedContent += content.slice(i, i + charLimit) + "\n";
    }
    return formattedContent.trim();
  };
  return (
    <>
      <>
        {Math.ceil((chatLength / 10) * 10) >= messageLimit ? (
          <Grid sx={{ textAlign: "center", mt: 2 }}>
            <Button
              mr={2}
              variant="contained"
              onClick={() => {
                handleMoreMessages(selectedUser);
              }}
            >
              Daha çoxu
            </Button>
          </Grid>
        ) : null}
      </>

      <div ref={messagesEndRef}>
        {getUserMessage.map((item, index) => (
          <ChatMessage
            key={index}
            // position={user.id === item.user["id"] ? "right" : "left"}
            style={{
              display: "flex",
              justifyContent: currentUser?._id === item.user._id && "end",
            }}
          >
            <ChatMessageInner>
              <ChatMessageTime variant="body2">
                {normalizeDate(new Date(item.sentAt))}
              </ChatMessageTime>
              {/* <ChatMessageAvatar alt="Lucy Lavender" src={avatar} /> */}
              <ChatMessageBubble
                highlighted={
                  user.id === item.user["id"]
                    ? position === "right"
                    : position === "left"
                }
                style={{
                  backgroundColor:
                    currentUser?._id !== item.user._id && "#1B2635",
                }}
              >
                <Box>
                  <ChatMessageBubbleName
                    variant="body1"
                    style={{
                      color: currentUser?._id !== item.user._id && "#4782da",
                      fontSize: "10px",
                    }}
                  >
                    {`${item.user.firstname} ${item.user.lastname} ${item.user.fathername}:`}
                  </ChatMessageBubbleName>
                </Box>
                <p
                  style={{
                    color:
                      currentUser?._id !== item.user._id ? "#fff" : "inherit",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {formatContent(item.content)}
                </p>
                {item.attachments && item.attachments.length > 0 && (
                  <Typography variant="body2">
                    <br />
                    <span
                      className="mb-2 ml-6 mr-6"
                      style={{
                        color: "#ccc",
                      }}
                    >
                      <strong>Fayllar</strong>: <br />
                    </span>
                    {item.attachments.map((attachment, index) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        {attachment.base64.substring(5, 10) === "image" && (
                          <img
                            src={attachment.base64}
                            width={"100px"}
                            onClick={() => {
                              setShow(true);
                              setImg(attachment.base64);
                            }}
                            alt=""
                          />
                        )}
                        {show && img && (
                          <Modal
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              maxWidth: "90%",
                            }}
                            open={show}
                            onClose={() => {
                              setShow(false);
                              setImg(null);
                            }}
                          >
                            <img
                              src={img}
                              style={{ maxWidth: "100%", height: "auto" }}
                              alt=""
                            />
                          </Modal>
                        )}
                        <a
                          href={attachment.base64}
                          download={attachment.name}
                          key={index}
                          style={{ color: "#FFF" }}
                        >
                          {`${index + 1}. ${attachment.name}`}
                        </a>
                        <br />
                      </div>
                    ))}
                  </Typography>
                )}
              </ChatMessageBubble>
              <Typography variant="body2">
                {user.id === item.user["id"] ? (
                  <>
                    {item.readUsers.length >= readStatusUsers
                      ? "Oxundu"
                      : "Göndərildi"}
                  </>
                ) : null}
              </Typography>
            </ChatMessageInner>
          </ChatMessage>
        ))}
      </div>
    </>
  );
}

function ChatWindow() {
  // User auth and get Users API
  const { user } = useAuth();
  const [currUser, setCurrUser] = useState();

  useEffect(() => {
    const getCurrUser = async (id) => {
      try {
        const { data } = await getEmployeeByUserId(id);
        data && setCurrUser(data);
      } catch (error) {
        console.log("err", error);
      }
    };
    user && getCurrUser(user.id);
  }, [user]);

  const [filesData, setFilesData] = useState([]);

  const [state, dispatch] = useReducer(chatReducer, initialState);
  const {
    users,
    chats,
    groupChat,
    selectedUser,
    chatId,
    getUserMessage,
    sendMessage,
    messageLimit,
    searchUsername,
    submitted,
  } = state;
  // const [users, setChatUsers] = useState([]);
  const socket = useContext(SocketContext);

  // Loading
  const [loading, setLoading] = useState(false);
  // const [submitted, setSubmitted] = useState(false);
  const [useEffectButton, setUseEffectButton] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Socket connection - listen new-message
  const [newMessage, setNewMessage] = useState(null);
  const [userAdded, setUserAdded] = useState(null);
  const [userRemoved, setUserRemoved] = useState(null);
  const [markAsRead, setMarkAsRead] = useState(null);
  const [chatDeleted, setChatDeleted] = useState(null);
  const [privateChatCreated, setPrivateChatCreated] = useState(null);
  const messagesEndRef = useRef(null);
  const listInnerRef = useRef();

  // Mark as read when USER AFK in CHAT
  const onScroll = async () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // TO DO SOMETHING HERE
        await markChatAsRead(chatId);
      }
    }
  };

  // Listen Socket Events
  useEffect(() => {
    const handleNewMessage = (message) => {
      setNewMessage(message);
    };

    const handleMarkAsRead = (readData) => {
      setMarkAsRead(readData);
    };

    const handleUserAdded = (userAddedData) => {
      setUserAdded(userAddedData);
    };

    const handleUserRemoved = (userRemovedData) => {
      setUserRemoved(userRemovedData);
    };

    const handleChatCreated = (privateChatCreated) => {
      setPrivateChatCreated(privateChatCreated);
    };

    const handleChatDeleted = (chatDeleted) => {
      setChatDeleted(chatDeleted);
      dispatch({
        type: "SET_CHAT_ID",
        payload: null,
      });
    };

    if (socket && socket.on) {
      socket.on("new-message", handleNewMessage);
      socket.on("mark-as-read", handleMarkAsRead);
      socket.on("user-added", handleUserAdded);
      socket.on("user-removed", handleUserRemoved);
      socket.on("chat-created", handleChatCreated);
      socket.on("chat-deleted", handleChatDeleted);
    }

    return () => {
      if (socket) {
        socket.off("new-message", handleNewMessage);
        socket.off("mark-as-read", handleMarkAsRead);
        socket.off("user-added", handleUserAdded);
        socket.off("user-removed", handleUserRemoved);
        socket.off("chat-created", handleChatCreated);
        socket.off("chat-deleted", handleChatDeleted);
      }
    };
  }, [socket]);

  const scrollLastItem = () => {
    setTimeout(() => {
      const lastMessageRef = messagesEndRef.current?.lastElementChild;
      if (lastMessageRef) {
        lastMessageRef.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  // New message socket push
  useEffect(() => {
    if (newMessage) {
      if (chatId === newMessage.chat) {
        const updatedMessages = [...getUserMessage, newMessage];
        dispatch({
          type: "SET_GET_USER_MESSAGES",
          payload: updatedMessages,
        });

        scrollLastItem();
      }
    }
  }, [newMessage]);

  // Mark as read push
  useEffect(() => {
    if (markAsRead) {
      dispatch((prevMessages) => {
        const updatedMessages = prevMessages.map((item) => {
          if (item.chat === markAsRead.chatId) {
            const updatedReadUsers = [...item.readUsers];
            const existingUser = updatedReadUsers.find(
              (userId) => userId === markAsRead.user.id
            );

            if (!existingUser) {
              updatedReadUsers.push(markAsRead.user.id);
            }

            return {
              ...item,
              readUsers: updatedReadUsers,
            };
          }

          return item;
        });

        return {
          type: "SET_GET_USER_MESSAGES",
          payload: updatedMessages,
        };
      });
    }
  }, [markAsRead, newMessage]);

  // Get users
  useEffect(() => {
    const getUser = async () => {
      setLoading(true);
      const response = await getUsers(searchUsername);
      dispatch({
        type: "SET_CHAT_USERS",
        payload: response.data.users,
      });
    };
    getUser(searchUsername);
    setLoading(false);
  }, [searchUsername]);

  // Get All Chats
  useEffect(() => {
    const getGroupChats = async () => {
      const response = await getAllChats();
      dispatch({
        type: "SET_CHATS",
        payload: response?.data?.chats,
      });
      dispatch({
        type: "SET_TOTAL_GROUP_CHATS",
        payload: response?.data?.total,
      });
    };
    getGroupChats();
  }, [
    newMessage,
    userAdded,
    userRemoved,
    privateChatCreated,
    submitted,
    chatDeleted,
  ]);

  // Get group chat
  useEffect(() => {
    if (useEffectButton) {
      if (chatId) {
        const getGroupSingleChat = async (chatId) => {
          const response = await getSingleChat(chatId);
          dispatch({
            type: "SET_GROUP_CHAT",
            payload: response.data,
          });
          dispatch({
            type: "SET_READ_STATUS_USERS",
            payload: response.data.members.reduce(
              (count, member) =>
                member.status !== "removed" ? count + 1 : count,
              0
            ),
          });

          setLoading(false);
        };

        getGroupSingleChat(chatId);
      }
    }
  }, [chatId, submitted]);

  // Get group chats messages and users - just last 10 messages
  const handleMessages = async (item) => {
    setUseEffectButton(true);
    const response = await getMessages(item._id, 0, 10);
    if (response) {
      dispatch({
        type: "SET_CHAT_LENGTH",
        payload: response.data.messages.total,
      });
      dispatch({
        type: "SET_GET_USER_MESSAGES",
        payload: response.data.messages.records,
      });
    } else {
      window.location.reload();
    }
    dispatch({
      type: "SET_CHAT_ID",
      payload: item._id,
    });
    dispatch({
      type: "SET_SELECTED_USER",
      payload: item,
    });

    scrollLastItem();
  };

  // Get before last 10 messages
  const handleMoreMessages = async (item) => {
    setUseEffectButton(true);
    const newMessageLimit = messageLimit + 10;

    dispatch({
      type: "SET_MESSAGE_LIMIT",
      payload: newMessageLimit,
    });

    const response = await getMessages(item._id, 0, newMessageLimit);
    if (response) {
      dispatch({
        type: "SET_CHAT_LENGTH",
        payload: response.data.messages.total,
      });
      dispatch({
        type: "SET_GET_USER_MESSAGES",
        payload: response.data.messages.records,
      });
    } else {
      window.location.reload();
    }
    dispatch({
      type: "SET_CHAT_ID",
      payload: item._id,
    });
    dispatch({
      type: "SET_SELECTED_USER",
      payload: item,
    });
  };

  const handleFileChange = async (event) => {
    const files = event.target.files;

    for (const file of files) {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        setFilesData((prevFiles) => [
          ...prevFiles,
          {
            name: file.name,
            base64: reader.result,
          },
        ]);
      };

      reader.onerror = (err) => {
        console.log("error");
      };
    }

    event.target.value = "";
  };

  // Post message
  const handlePostMessage = async (chatId, content) => {
    setIsSubmitting(true);
    setUseEffectButton(true);
    const response = await postMessage(chatId, content, filesData);
    const messageResponse = await getMessages(chatId, 0, messageLimit);

    response && setFilesData([]);

    dispatch({
      type: "SET_CHAT_LENGTH",
      payload: messageResponse.data.messages.total,
    });
    dispatch({
      type: "SET_GET_USER_MESSAGES",
      payload: messageResponse.data.messages.records,
    });
    // socket.emit("new-message", content);
    dispatch({
      type: "SET_SUBMITTED",
      payload: !submitted,
    });
    dispatch({
      type: "SET_SEND_MESSAGE",
      payload: "",
    });
    setUseEffectButton(false);
    scrollLastItem();
    setIsSubmitting(false);
  };

  // Delete chat
  const handleDeleteChat = async (chatId, userId) => {
    await deleteChat(chatId, userId);
    window.location.reload();
  };
  const isSendDisabled = !sendMessage.trim() && filesData.length === 0;
  return (
    <>
      {/* Chat Container */}
      <ChatContainer container component={Card}>
        {/* Sidebar */}

        <ChatSidebar item xs={12} md={4} lg={3}>
          <Grid item xs={12}>
            <Box p={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch({
                    type: "SET_CREATE_CHAT_DIALOG",
                    payload: true,
                  });
                }}
                fullWidth
              >
                Çat yarat
              </Button>
            </Box>

            {/* Create chat Dialog */}
            {!user ? null : (
              <CreateChatDialog state={state} dispatch={dispatch} user={user} />
            )}
          </Grid>
          <Divider />
          {/* All chats */}
          <List style={{ maxHeight: "58vh", overflow: "auto" }}>
            {chats &&
              chats.map((item, index) => {
                return (
                  <Badge
                    sx={{
                      width: "100%",
                      "& .MuiBadge-badge": {
                        marginLeft: "20px",
                        marginTop: "10px",
                      },
                    }}
                    color="success"
                    badgeContent="+1"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    invisible={item.newMessage === false}
                  >
                    <ListItem
                      key={index}
                      button
                      selected={selectedUser._id === item._id}
                      onClick={() => {
                        handleMessages(item);
                      }}
                    >
                      <ListItemIcon>
                        <Avatar alt={item.name} src={`${item.image}`} />
                      </ListItemIcon>
                      <ListItemText primary={item.name} />
                      {item.type === "group" ? (
                        <Tooltip
                          style={{ display: "flex" }}
                          title="Daha ətraflı"
                          placement="left"
                        >
                          <IconButton
                            onClick={() => {
                              setUseEffectButton(true);
                              dispatch({
                                type: "SET_OPEN_GROUP_CHAT_USERS",
                                payload: true,
                              });
                            }}
                          >
                            <AccountCircle />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              dispatch({
                                type: "SET_DELETE_CHAT_DIALOG",
                                payload: true,
                              });
                              dispatch({
                                type: "SET_CHAT_ID",
                                payload: item._id,
                              });
                            }}
                            color="error"
                          >
                            <Trash />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Çatı sil" placement="left">
                          <IconButton
                            onClick={() => {
                              dispatch({
                                type: "SET_DELETE_CHAT_DIALOG",
                                payload: true,
                              });
                              dispatch({
                                type: "SET_CHAT_ID",
                                payload: item._id,
                              });
                            }}
                            color="error"
                          >
                            <Trash />
                          </IconButton>
                        </Tooltip>
                      )}
                    </ListItem>
                  </Badge>
                );
              })}
          </List>

          {/* Delete Chat Dialog */}
          <DeleteChatDialog
            state={state}
            dispatch={dispatch}
            handleDeleteChat={handleDeleteChat}
          />

          {/* Upload custom image */}
          <ChatImageDialog state={state} dispatch={dispatch} />

          {/* Group chat users */}
          {!user ? (
            <div>Loading...</div>
          ) : (
            <GroupChatUsers user={user} state={state} dispatch={dispatch} />
          )}
        </ChatSidebar>

        {/* Main */}
        <ChatMain item xs={12} md={8} lg={9}>
          {chatId && (
            <>
              <ChatMessages
                $existFile={filesData.length > 0}
                onScroll={() => onScroll()}
                ref={listInnerRef}
              >
                <ChatMessageComponent
                  currentUser={currUser}
                  user={user}
                  // privateChat={privateChat}
                  state={state}
                  dispatch={dispatch}
                  handleMoreMessages={handleMoreMessages}
                  avatar="/static/img/avatars/avatar-2.jpg"
                  time="20 minutes ago"
                  messagesEndRef={messagesEndRef}
                />
              </ChatMessages>
              <Divider />
              <ChatInput container>
                <Grid sx={{ width: "100%", height: "auto" }}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handlePostMessage(chatId, sendMessage);
                    }}
                  >
                    {filesData.length > 0 && (
                      <Grid>
                        <Typography variant="h6" fontSize={12} mb={2}>
                          Əlavə olunmuş fayllar:
                        </Typography>
                        <Grid
                          sx={{
                            display: "flex",
                            overflowX: "auto",
                            height: "60px",
                            paddingTop: "10px",
                          }}
                        >
                          {filesData.map((item) => (
                            <Grid
                              sx={{
                                width: "auto",
                                padding: "5px 10px",
                                border: "1px solid #fff",
                                borderRadius: "20px 5px 5px 5px",
                                position: "relative",
                                marginRight: "5px",
                                height: "80%",
                              }}
                            >
                              <Typography whiteSpace={"nowrap"}>
                                {item.name}
                              </Typography>
                              <Grid
                                sx={{
                                  position: "absolute",
                                  right: -5,
                                  top: -10,
                                  zIndex: 1111,
                                }}
                              >
                                <IconButton
                                  sx={{
                                    backgroundColor: "#fff",
                                    height: "10px",
                                    width: "10px",
                                    ":hover": {
                                      backgroundColor: "#ccc",
                                    },
                                  }}
                                  color="error"
                                  onClick={() =>
                                    setFilesData((prev) =>
                                      prev.filter((_item) => _item !== item)
                                    )
                                  }
                                >
                                  <Close
                                    sx={{ widht: "12px", height: "12px" }}
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    )}
                    <Grid sx={{ display: "flex" }}>
                      <Grid item style={{ flexGrow: 1 }}>
                        <TextField
                          variant="outlined"
                          label="Mesajınızı yazın"
                          fullWidth
                          placeholder={sendMessage}
                          defaultValue={sendMessage}
                          value={sendMessage}
                          onChange={(e) =>
                            dispatch({
                              type: "SET_SEND_MESSAGE",
                              payload: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid item sx={{ display: "flex" }}>
                        <Box ml={2}>
                          <label htmlFor="file-upload">
                            <Fab
                              variant="contained"
                              component="span"
                              size="medium"
                              color={
                                filesData.length > 0 ? "secondary" : "success"
                              }
                              style={{
                                backgroundColor:
                                  filesData.length > 0 ? "" : undefined,
                                color:
                                  filesData.length > 0 ? "white" : undefined,
                              }}
                            >
                              <FileUploadIcon />
                            </Fab>
                          </label>
                          <input
                            id="file-upload"
                            type="file"
                            hidden
                            multiple
                            onChange={handleFileChange}
                            on
                          />
                        </Box>

                        <Box ml={2}>
                          <Fab color="primary" aria-label="add" size="medium">
                            <IconButton
                              type="submit"
                              disabled={isSendDisabled || isSubmitting}
                            >
                              <SendIcon />
                            </IconButton>
                          </Fab>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </ChatInput>
            </>
          )}
        </ChatMain>
      </ChatContainer>

      {/* Loading dialog */}
      {loading && (
        <Dialog open={true}>
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

function Chat() {
  const { user } = useAuth();
  return (
    <React.Fragment>
      <ToastContainer />
      <Helmet title="Chat" />
      <Grid
        justifyContent="space-between"
        alignItems="center"
        container
        spacing={10}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Chat
          </Typography>
        </Grid>
        {/* <Grid item>
          <div>
            {user ? (
              <Button variant="contained" color="primary">
                Hello, {user.username}
              </Button>
            ) : (
              <>Loading...</>
            )}
          </div>
        </Grid> */}
      </Grid>

      <Divider my={6} />

      <ChatWindow />
    </React.Fragment>
  );
}

export default Chat;
