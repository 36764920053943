/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from 'react';
import { GENERAL_BLOCK, GENERAL_BUTTON } from '../../styled';
import "./style.css";

const OptionGroup = ({ data, value, set_value, position, buttonGroup, default_label, field }) => {
    const [dropdown, show_dropdown] = useState(false);
    const [selected, set_selected] = useState(value);
    let div = useRef();
    useEffect(() => {
        set_selected(value);
    }, [value]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (div && !div?.current?.contains(event.target)) {
                show_dropdown(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [div]);
    let data_len = data !== undefined && (field === "workingCondition" ? Object.keys(data || {})?.length : data?.length);
    return (
        <div ref={div} className='position-relative'>
            <div
                onClick={() => show_dropdown(!dropdown)} style={{
                    border: "1px solid rgba(255, 255, 255, 0.23)",
                    padding: "10px", minHeight: "42px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: "space-between",
                    color: "#fff"
                }}>
                {selected?.length ? selected : default_label}
                <GENERAL_BUTTON
                    bord="0"
                    bg_color="transparent"
                    clr="#fff"
                    onClick={() => set_value("")}
                >x</GENERAL_BUTTON>

            </div>
            <GENERAL_BLOCK className='w-100'
                poss={position}
                bord="1px solid rgba(255, 255, 255, 0.23)"
                zIndex="11"
                d_play={dropdown ? "block" : "none"}
                bg_color="#1b2635"
                hei={data_len > 5 ? "250px" : "unset"}
                o_flow_y={data_len > 5 ? "scroll" : "unset"}
            >
                {
                    field === "workingCondition" ? Object.keys(data || {})?.map(l => (
                        <>
                            <div className='ps-3 p-2' style={{ background: "#6060601c", fontWeight: "bold", color: "#717171" }}>{l}</div>
                            {
                                data?.[l]?.map(c => {
                                    return <button
                                        className={`p-2 item-option ${selected == c?.name ? "selected" : ""}`}
                                        style={{ background: selected == c?.name ? "#486df1" : "transparent", border: 0, color: "white", textAlign: "left", width: "100%" }}
                                        onClick={(e) => {
                                            show_dropdown(false)
                                            set_selected(e.target.innerText);
                                            set_value([c?.parent, c?.name])
                                        }}>
                                        {l === "Normal" ? "Normal" : c?.name}
                                    </button>
                                }
                                )
                            }
                        </>
                    )) : data?.map(l => (
                        <>
                            <div className='ps-3 p-2' style={{ background: "#6060601c", fontWeight: "bold", color: "#717171" }}>{l?.parent}
                            </div>
                            {
                                l?.children?.sub_title &&
                                <div style={{ background: "#6060601c", fontWeight: "bold", color: "rgb(199 199 199)" }}>{l?.children?.sub_title}</div>
                            }
                            {
                                l?.children?.children?.map(c => {
                                    return <button
                                        className={`p-2 item-option ${selected == c ? "selected" : ""}`}
                                        style={{ background: selected == c ? "#486df1" : "transparent", border: 0, color: "white", textAlign: "left", width: "100%" }}
                                        onClick={(e) => {
                                            show_dropdown(false)
                                            set_selected(e.target.innerText);
                                            set_value(e.target.innerText)
                                        }}>
                                        {c}
                                    </button>
                                }
                                )
                            }
                        </>
                    ))
                }
                {
                    buttonGroup?.length && buttonGroup?.map(b => (
                        <div className='mt-2 mb-2 ms-2'>
                            <GENERAL_BUTTON
                                bord="1px solid #407ad680"
                                bg_color="transparent"
                                hei="37px"
                                brad="4px"
                                clr="#407ad680"
                                className='w-25'
                                hover_bg="#1a2c4a80"
                                onClick={() => b?.onClick(true)}
                            >{b?.label}</GENERAL_BUTTON>
                            {/* <Button
                                sx={{
                                    mt: 3,
                                    ml: 3,
                                }}
                                variant="contained"
                                onClick={() => {
                                    b?.onClick();

                                }}
                            >
                                {b?.label}
                            </Button> */}
                        </div>
                    ))
                }
            </GENERAL_BLOCK>
        </div >

    )
}


let style = { border: "1px solid rgba(255, 255, 255, 0.23)", zIndex: "11", background: "#1b2635" }
export default OptionGroup