import { useState } from "react";
import style from "../myProfileStyle.module.css";
import { CardContent, Grid, Card, Tooltip, Typography } from "@mui/material";
// import MCViewLaborActivity from "../Personal_informations_view_part/MCViewLaborActivity";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LaborActivity from "../Personal_informations_view_part/LaborActicity";

export default function ShowLaborActivity({ employeeById }) {
  const [showLaborActivity, setShowLaborActivity] = useState(false);

  return (
    <>
      <Grid className={style.myCabinetThirdSection}>
        <Card mb={6}>
          <CardContent sx={{ "&:last-child": { pb: 4 } }}>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid sx={{ width: "100%" }}>
                <Typography variant="h6">Əmək fəaliyyəti</Typography>
              </Grid>

              <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{ justifyContent: "flex-end" }}
              >
                {/* <Grid item> */}
                <Tooltip title="Əmək fəaliyyətini göstər">
                  {showLaborActivity ? (
                    <KeyboardArrowUpIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setShowLaborActivity(!showLaborActivity);
                      }}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setShowLaborActivity(!showLaborActivity);
                      }}
                    />
                  )}
                </Tooltip>
              </Grid>
            </Grid>

            {showLaborActivity && (
              <>
                {employeeById?.laborActivity?.length ? (
                  <LaborActivity employeeById={employeeById} />
                ) : (
                  <Typography>Göstəriləcək əmək fəaliyyəti yoxdur</Typography>
                  // <MCViewLaborActivity employeeById={employeeById} />
                )}
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
