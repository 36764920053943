import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { getEmployeeById } from "../../../api/api";
import {
  getVacationRequests,
  getVacationsWithFilter,
} from "../../../api/vacationRequests/vacationRequests";
import EmpReportingForm from "../../../components/vacations/employee/EmpReportingForm";
import CardTotals from "../../../layouts/vacations/cards/CardTotals";
import VacationCards from "../../../layouts/vacations/cards/VacationCards";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const typeOfDialog = "employee-request";

export default function EmptyCard() {
  const navigate = useNavigate();
  const [totalRequests, setTotalRequests] = useState();
  const [requests, setRequests] = useState({
    approved: "",
    pending: "",
    reRequested: "",
    rejected: "",
  });

  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const cardTitles = {
    pendingTitle: "Yaratdığım və qərar gözləyən sorğular",
    approvedTitle: "Təsdiq edilmiş sorğularım",
    rejectedTitle: "İmtina edilmiş / düzəltməli olduğum sorğular",
    rerequestedTitle: "Düzəldib yenidən göndərdiyim sorğular",
  };
  const [daysCount, setDaysCount] = useState("");

  const { currentEmployee } = useSelector((state) => state.commonThings);

  useEffect(() => {
    const getVacations = async () => {
      try {
        const response = await getVacationRequests(limit, offset);

        if (response && response.data) {
          setTotalRequests({
            approved: response.data.approved.total,
            pending: response.data.pending.total,
            rejected: response.data.rejected.total,
            rerequested: response.data.reRequested.total,
          });
          setRequests(response.data);
        }
      } catch (err) {
        console.log("error: ", err);
      }
    };
    getVacations();
    currentEmployee && getdaysCount();
  }, [currentEmployee]);

  const getdaysCount = async () => {
    try {
      const { data } = await getEmployeeById(currentEmployee.id);
      data && setDaysCount(data.vacationDays);
    } catch (error) {
      console.log("err", error);
    }
  };

  const handlePagination = async (value, status) => {
    const newOffset = (value - 1) * 5;
    try {
      const response = await getVacationsWithFilter(limit, newOffset, status);
      if (response && response.data) {
        if (status === "pending") {
          setRequests((prevState) => ({
            ...prevState,
            pending: response.data,
          }));
        } else if (status === "approved") {
          setRequests((prevState) => ({
            ...prevState,
            approved: response.data,
          }));
        } else if (status === "rejected") {
          setRequests((prevState) => ({
            ...prevState,
            rejected: response.data,
          }));
        } else {
          setRequests((prevState) => ({
            ...prevState,
            reRequested: response.data,
          }));
        }
      }
    } catch (err) {
      console.log("error: ", err);
    }
  };

  return (
    <Grid>
      <Grid mb={7} sx={{ alignItems: "center", display: "flex", gap: 5 }}>
        <Grid>
          <CardTotals totalRequests={totalRequests} />
        </Grid>
        <Grid sx={{ width: "100%" }}>
          <Card px={4} py={3}>
            <EmpReportingForm />
          </Card>
        </Grid>
      </Grid>
      <Grid
        mb={7}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid
          sx={{
            backgroundColor: "#407ad6",
            p: 3,
            borderRadius: 1,
            display: "flex",
            gap: 3,
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              border: 4,
              width: 50,
              height: 50,
              borderRadius: "50%",
              borderColor: "rgba(15, 76, 117, 1)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {daysCount ? (
              <Typography variant="h4">{daysCount}</Typography>
            ) : (
              <CircularProgress color="inherit" size={22} />
            )}
          </Box>
          <Typography variant="h4">Qalan məzuniyyət günlərimin sayı</Typography>
        </Grid>
        <Button
          size="large"
          variant="contained"
          endIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            navigate("/create-vacation-request");
          }}
        >
          Yeni Məzuniyyət Sorğusu Yarat
        </Button>
      </Grid>

      <Grid>
        <VacationCards
          requests={requests}
          totalRequests={totalRequests}
          limit={limit}
          cardTitles={cardTitles}
          handlePagination={handlePagination}
          isFromEmp={true}
        />
      </Grid>
    </Grid>
  );
}

// function EmployeeVacations() {
//   const { user } = useAuth();

//   const dispatch = useDispatch();

//   const { currentEmployee } = useSelector((state) => state.commonThings);

//   useEffect(() => {
//     if (user) {
//       const getUser = async () => {
//         try {
//           const response = await getEmployeeByUserId(user.id);
//           if (response && response.data) {
//             dispatch(setCurrentEmployee(response.data));
//           }
//         } catch (err) {
//           console.log("error: ", err);
//         }
//       };
//       getUser();
//     }
//   }, [user]);

//   const headerTitle = "Məzuniyyət Sorğularım";

//   return (
//     <React.Fragment>
//       <VacationHeader
//         headerTitle={headerTitle}
//         routes={routes}
//         employee={currentEmployee}
//       />

//       <ToastContainer />

//       <Divider my={6} />

//       <Grid container spacing={6}>
//         <Grid item xs={12}>
//           <EmptyCard />
//         </Grid>
//       </Grid>
//     </React.Fragment>
//   );
// }

// export default EmployeeVacations;
