import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";

const EditBoard = ({
  openEditBoard,
  setOpenEditBoard,
  boardTitle,
  handleEditBoard,
}) => {
  const [boardName, setBoardName] = useState(boardTitle);

  useEffect(() => {
    setBoardName(boardTitle);
  }, [boardTitle]);

  return (
    <>
      <Dialog
        fullWidth
        open={openEditBoard}
        onClose={() => {
          setOpenEditBoard(false);
          setBoardName("");
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4">Lövhə adını dəyişdirin</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Adı"
            type="text"
            value={boardName}
            onChange={(e) => setBoardName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenEditBoard(false);
              setBoardName("");
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          <Button
            onClick={() => {
              setOpenEditBoard(false);
              handleEditBoard(boardName);
            }}
            color="primary"
            disabled={boardName.trim() === ""}
          >
            Dəyişdir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditBoard;
