export const structureServiceGenerator = (data) => {
  const newFields = {};

  for (const key in data.data) {
    let obj = {};

    if (key === "contract_general_info") {
      obj = {
        ...newFields,
        [key]: [...data.data[key]],
      };
      obj.contract_general_info[0].value = "Struktur dəyişiklik sorğusu";
    } else {
      obj = { ...newFields, [key]: data.data[key] };
    }

    Object.assign(newFields, obj);
  }

  return newFields;
};
