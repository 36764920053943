import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  structureNames: [],
  selectedStructureId: "",
};

export const structureNameSlice = createSlice({
  name: "structureName",
  initialState,
  reducers: {
    setStructureNames: (state, action) => {
      state.structureNames = action.payload;
    },
    setStructureId: (state, action) => {
      state.selectedStructureId = action.payload;
    },
  },
});

export const { setStructureNames, setStructureId } = structureNameSlice.actions;

export default structureNameSlice.reducer;
