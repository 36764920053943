import React, { useEffect, useState } from "react";
import FieldItem from "../../../../../components/dialogs/documentDialog/dialogParts/parts/FieldItem";
import { formatDateTime } from "../../../../../utils/helperFunctions";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import {
  GROUP_NAME,
  LABOUR_CONTRACTS_CUSTOM_RENDERS_METHODS,
} from "../../../../../components/dialogs/documentDialog/dialogConstants";
import moment from "moment";

function FieldsContChange({ data }) {
  const [constFields, setConstFields] = useState([]);
  const [updatesFields, setUpdatesFields] = useState([]);

  useEffect(() => {
    data && generateFields();
  }, [data]);

  const generateFields = () => {
    const statics = [];
    const updates = [];

    for (const key in data) {
      const group = data[key];
      if (key === "updates") {
        updates.push(group);
      } else {
        statics.push({ [key]: group });
      }
    }
    setConstFields(statics);
    setUpdatesFields(updates);
  };

  const renderField = (field, index) => {
    let newVal;

    const transformedValues = {
      indefinite: "Müddətsiz",
      term: "Müddətli",
      monday: "Bazar ertəsi",
      tuesday: "Çərşənbə axşamı",
      wednesday: "Çərşənbə",
      thursday: "Cümə axşamı",
      friday: "Cümə",
      saturday: "Şənbə",
      sunday: "Bazar",
    };

    const isValidDate = (date) => {
      return moment(date, moment.ISO_8601, true).isValid();
    };

    if (field?.type === "date") {
      if (field?.value && typeof field.value === "object") {
        newVal = Object.values(field.value)
          .map((val) => {
            if (val === " ") return null;
            if (field.type !== "number") {
              if (isValidDate(val)) return moment(val).format("DD/MM/YYYY");
              if (transformedValues[val]) return transformedValues[val];
            }
            return val;
          })
          .filter(Boolean)
          .join(" -> ");
      }
    } else if (field?.type === "number") {
      if (field?.value && typeof field.value === "object") {
        newVal = Object.values(field.value)
          .map((val) => {
            if (val === " ") return null;
            return val;
          })
          .filter(Boolean)
          .join(" -> ");
      }
    } else if (field?.type === "array") {
      if (Array.isArray(field?.value)) {
        newVal = field.value
          .map((val) => {
            if (val === " ") return null;
            if (val?.date) {
              return moment(val.date).format("DD/MM/YYYY");
            }
          })
          .filter(Boolean)
          .join(" -> ");
      }
    }

    if (field.isHide) return;

    const label = field.label ? field.label : "__";
    const value = typeof field.value === "object" ? newVal : renderValue(field);
    return <FieldItem key={index} label={label} value={value} index={index} />;
  };

  const renderValue = (field) => {
    if (!field.value) return "-";
    if (field.renderValue) {
      const render = field.renderValue;
      return render(field.value);
    }
    switch (field.type) {
      case "string":
        return field.value;
      case "date":
        if (!field.value) {
          return "__";
        }
        return formatDateTime(field.value);
      case "array":
        return "array";
      case "object":
        return "object";
      default:
        return field.value;
    }
  };

  const findKeyGroup = (key) => {
    switch (key) {
      case "labourPeriod":
        return "labour_period";
      case "wage":
        return "for_wage";
      case "protection":
        return "for_protection";
      case "workVacationTime":
        return "for_work_vacation_time";
      case "vacation":
        return "for_vacation";
      case "physicalEducationSport":
        return "physical_education_sport";
      case "socialProtection":
        return "social_protection";
      case "regulationPropertyRelation":
        return "regulation_property_relations";
      default:
        return key;
    }
  };

  const generateGroupComponent = (group) => {
    const ui = [];
    for (const key in group) {
      const fields = group[key];
      const utils =
        LABOUR_CONTRACTS_CUSTOM_RENDERS_METHODS["labour-contract"]?.[
          findKeyGroup(key)
        ];
      ui.push(
        <Accordion sx={{ backgroundColor: "rgba(0,0,0,0.1)" }} key={key}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "16px",
                color: "rgba(200,200,200,1)",
              }}
            >
              {GROUP_NAME[key]}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ marginX: "10px" }}>
            {fields.length > 0 &&
              fields?.map((item, index) => {
                return renderField(
                  {
                    ...item,
                    renderValue: utils && utils[item.key],
                    key: key,
                  },
                  index
                );
              })}
          </AccordionDetails>
        </Accordion>
      );
    }
    return ui;
  };

  return (
    <div style={{ width: "50%" }}>
      <div>
        {constFields.length > 0 &&
          constFields.map((group) => generateGroupComponent(group))}
      </div>
      <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
        <p style={{ textAlign: "center", width: "100%", fontSize: 18 }}>
          Dəyişikliklər
        </p>
      </div>
      <div>
        {updatesFields.length > 0 &&
          updatesFields.map((group) => generateGroupComponent(group))}
      </div>
    </div>
  );
}

export default FieldsContChange;
