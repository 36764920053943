import React from "react";
import styled from "@emotion/styled";
import { Link, NavLink } from "react-router-dom";
import Logo from "./../../assets/logo/logo.svg";
import LogoWithBorder from "./../../assets/logo/logoWithBorder.svg";
import LogoWithoutBorder from "./../../assets/logo/logoWithoutBorder.svg";

import { green } from "@mui/material/colors";

import {
  Box,
  Chip,
  Drawer as MuiDrawer,
  ListItemButton,
  Grid,
} from "@mui/material";

// import Logo from "../../vendor/blink-logo-female.png";
import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";

const Drawer = styled(MuiDrawer)`
  border-right: 0;
  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 32px;
  height: 32px;
`;

const BrandChip = styled(Chip)`
  background-color: ${green[700]};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 55%;
  height: 18px;
  margin-left: 2px;
  margin-top: -16px;
  padding: 3px 0;

  span {
    padding-left: ${(props) => props.theme.spacing(1.375)};
    padding-right: ${(props) => props.theme.spacing(1.375)};
  }
`;

const Sidebar = ({ items, showFooter = true, ...rest }) => {
  return (
    <Drawer variant="permanent" {...rest}>
      {/* <Brand component={NavLink} to="/">
        <BrandIcon />{" "}
        <Box ml={1}>
          Blink-Bi <BrandChip label="PRO" />
        </Box>
      </Brand> */}
      <Link to="/private">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: "20px", mb: "20px" }}
        >
          <Box
            component="img"
            src={LogoWithoutBorder}
            sx={{
              width: "70%",
              objectFit: "contain",
            }}
          />
        </Grid>
      </Link>

      <SidebarNav items={items} />
      {!!showFooter && <Footer />}
    </Drawer>
  );
};

export default Sidebar;
