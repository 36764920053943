import React, { useEffect, useState } from "react";
import {
  Field,
  FieldArray,
  ErrorMessage,
  useFormikContext,
  Formik,
  Form,
} from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Button,
  Checkbox,
  FormHelperText,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

const educationType = [
  { value: "primary", name: "Ümumi təhsil - İbtidati təhsil" },
  { value: "bachelor", name: "Ali təhsil - bakalavriat" },
  { value: "master", name: "Ali təhsil - magistratura" },
  { value: "doctoral", name: "Ali təhsil - doktorantura" },
  { value: "generalSecondary", name: "Ümumi təhsil - ümumi orta təhsil" },
  { value: "completeSecondary", name: "Ümumi təhsil - tam orta təhsil" },
  { value: "firstVocational", name: "İlk peşə-ixtisas təhsili" },
  { value: "secondarySpecial", name: "Orta ixtisas təhsili" },
  {
    value: "secBasisOfGeneralSec",
    name: "Ümumi orta təhsil bazasında orta ixtisas təhsili",
  },
  {
    value: "secBasisOfCompleteSec",
    name: "Tam orta təhsil bazasında orta ixtisas təhsili",
  },
  {
    value: "incompleteHigherTill1993",
    name: "Ali təhsil - 1993 cü ilə qədər natamam ali təhsil",
  },
  {
    value: "completeHigherTill1993",
    name: "Ali təhsil - 1997 ci ilə qədər tam ali təhsil",
  },
];

const countries = [
  "Albaniya",
  "Almaniya",
  "Anqola",
  "Antiqua və Barbuda",
  "Argentina",
  "Avstraliya",
  "Avstriya",
  "Azərbaycan",
  "Bahamalar",
  "Bahrayn",
  "Banqladeş",
  "Barbados",
  "Belarus",
  "Belçika",
  "Boliviya",
  "Bosniya və Herseqovina",
  "Botswana",
  "Braziliya",
  "Böyük Britaniya",
  "Büyükdəniz adası",
  "Bəhreyn",
  "Bəruney",
  "Cənubi Afrika",
  "Cənubi Sudan",
  "Cənubi Koreya",
  "Çad",
  "Çexiya",
  "Çili",
  "Çin",
  "Çernoqoriya",
  "Çexoslavakiya",
  "Danemarka",
  "Dominika",
  "Dominikan Respublikası",
  "Ekvador",
  "Ekvatorial Qvineya",
  "Eritreya",
  "Estoniya",
  "Əfqanıstan",
  "Fici",
  "Filippin",
  "Finlandiya",
  "Fransa",
  "Fransız Qvineyası",
  "Fələstin",
  "Gana",
  "Qabon",
  "Qambiya",
  "Qazaxıstan",
  "Qatar",
  "Qrenada",
  "Qvineya",
  "Qvineya-Bisau",
  "Gürcüstan",
  "Haiti",
  "Hollandiya",
  "Honduras",
  "Honkonq",
  "Hindistan",
  "Horvatiya",
  "Indoneziya",
  "İngiltərə",
  "İordaniya",
  "İraq",
  "İran",
  "İrlandiya",
  "İslandiya",
  "İspaniya",
  "İsrail",
  "İsveç",
  "İsveçrə",
  "İtaliya",
  "Kamboca",
  "Kamerun",
  "Kanada",
  "Keniya",
  "Kipr",
  "Kiribati",
  "Kolumbiya",
  "Komor Adaları",
  "Kongo",
  "Kosta Rika",
  "Kotd’İvor",
  "Kuba",
  "Kuveyt",
  "Kırqızıstan",
  "Latviya",
  "Lesoto",
  "Livan",
  "Liberiya",
  "Litva",
  "Lixtenşteyn",
  "Liviya",
  "Lüksemburq",
  "Makedoniya",
  "Malavi",
  "Malayziya",
  "Maldiv adaları",
  "Malta",
  "Maroq",
  "Marşal adaları",
  "Mauritaniya",
  "Mauritius",
  "Meksika",
  "Moldova",
  "Monako",
  "Monqolustan",
  "Monteneqro",
  "Morokko",
  "Mozambik",
  "Myanma",
  "Mərakeş",
  "Nambiya",
  "Nauru",
  "Nepal",
  "Niderland",
  "Niger",
  "Nigeriya",
  "Nikaraqua",
  "Norveç",
  "Pakistan",
  "Palau",
  "Panama",
  "Paraqvay",
  "Portuqaliya",
  "Polşa",
  "Rumıniya",
  "Rusiya",
  "Ruanda",
  "San-Marino",
  "Svazilend",
  "Salvador",
  "Samoa",
  "San-Tome və Prinsip",
  "Seneqal",
  "Seyşel adaları",
  "Sinhapur",
  "Slovakiya",
  "Sloveniya",
  "Solomon adaları",
  "Somali",
  "Sudan",
  "Surinam",
  "Suriya",
  "Svədistan",
  "Səudiyyə Ərəbistanı",
  "Sərbiya",
  "Tacikistan",
  "Tanzaniya",
  "Tailand",
  "Tayvan",
  "Toga",
  "Tonqa",
  "Trinidad və Tobaqo",
  "Tunis",
  "Turkmənistan",
  "Tuvalu",
  "Türkiyə",
  "Ukrayna",
  "Urugvay",
  "Vanuatu",
  "Vatikan",
  "Venesuela",
  "Vyetnam",
  "Yamayka",
  "Yaponiya",
  "Yeni Zelandiya",
  "Yunanıstan",
  "Yəmən",
  "Zambiya",
  "Zimbabve",
];

const ViewRelationshipInfo = ({ employeeById }) => {
  //   Initial values
  const initialValues = {
    relationshipInfo: employeeById.relationshipInfo || [],
  };

  // Update the initialValues object with parsed dates
  const modifiedValues = {
    ...initialValues,
    relationshipInfo: initialValues.relationshipInfo.map((item) => ({
      ...item,
      idCardNumber:
        item.isForeigner === true
          ? { number: item.idCardNumber }
          : {
              code: item?.idCardNumber?.startsWith("AA")
                ? item.idCardNumber.slice(0, 2)
                : item?.idCardNumber?.startsWith("AZE")
                ? item.idCardNumber.slice(0, 3)
                : "",
              number: item?.idCardNumber?.startsWith("AA")
                ? item.idCardNumber.slice(2)
                : item?.idCardNumber?.startsWith("AZE")
                ? item.idCardNumber.slice(3)
                : "",
            },
    })),
  };
  return (
    <>
      {employeeById ? (
        <Formik
          initialValues={{
            relationshipInfo: modifiedValues.relationshipInfo || [], // Set the initial laborActivity values from employeeById
          }}
        >
          {({
            values,
            isValid,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleChange,
          }) => (
            <Form style={{ pointerEvents: "none" }}>
              <FieldArray name="relationshipInfo">
                <div>
                  {values.relationshipInfo.map((form, index) => (
                    <div key={index}>
                      <>
                        <Typography mt={3} mb={3} variant="h6">
                          {index + 1 + ")"}Qohumluq məlumatı{" "}
                        </Typography>

                        {/* Relation type and full name */}
                        <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                          <Grid sx={{ width: "100%" }}>
                            <FormControl
                              error={
                                errors?.relationshipInfo?.[index]
                                  ?.relationType &&
                                Boolean(
                                  errors?.relationshipInfo?.[index]
                                    ?.relationType
                                )
                              }
                              fullWidth
                            >
                              <InputLabel
                                id={`relationshipInfo[${index}].relationType`}
                              >
                                Qohumluq əlaqəsi növü *
                              </InputLabel>
                              <Field
                                as={Select}
                                name={`relationshipInfo[${index}].relationType`}
                                labelId={`relationshipInfo[${index}].relationType`}
                                label="Qohumluq əlaqəsi növü *"
                                id={`relationshipInfo[${index}].relationType-select`}
                                defaultValue=""
                                fullWidth
                              >
                                <MenuItem value="father">Atam</MenuItem>
                                <MenuItem value="mother">Anam </MenuItem>
                                <MenuItem value="brother">Qardaşım </MenuItem>
                                <MenuItem value="sister">Bacım </MenuItem>
                                <MenuItem value="grandmother">Nənəm </MenuItem>
                                <MenuItem value="grandfather">Babam </MenuItem>
                                <MenuItem value="husband">Yoldaşım </MenuItem>
                                <MenuItem value="father-in-law">
                                  Qaynatam{" "}
                                </MenuItem>
                                <MenuItem value="mother-in-law">
                                  Qaynanam{" "}
                                </MenuItem>
                                <MenuItem value="child">Övladım </MenuItem>
                                <MenuItem value="grandchild">Nəvəm </MenuItem>
                              </Field>
                              {errors.relationshipInfo?.[index]?.relationType &&
                                errors.relationshipInfo?.[index]
                                  ?.relationType && (
                                  <FormHelperText error>
                                    {
                                      errors.relationshipInfo?.[index]
                                        ?.relationType
                                    }
                                  </FormHelperText>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid sx={{ width: "100%" }}>
                            <Field
                              type="text"
                              name={`relationshipInfo[${index}].fullname`}
                              as={TextField}
                              label="Soyadı Adı Atasının adı *"
                              fullWidth
                              value={
                                employeeById?.relationshipInfo?.[index]
                                  ?.fullname
                              }
                            />
                          </Grid>
                        </Grid>

                        {/* fin and national id card number */}
                        <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                          {(values.relationshipInfo[index]?.isMyiExist &&
                            values.relationshipInfo[index].isForeigner) ||
                          !values.relationshipInfo[index].isForeigner ? (
                            <Grid sx={{ width: "100%" }}>
                              <Field
                                type="text"
                                name={`relationshipInfo[${index}].pin`}
                                as={TextField}
                                fullWidth
                                label="FIN *"
                                value={
                                  employeeById?.relationshipInfo?.[index]?.pin
                                }
                              />
                            </Grid>
                          ) : null}

                          <Grid sx={{ width: "100%", display: "flex", gap: 3 }}>
                            {values.relationshipInfo[index]
                              .isForeigner ? null : (
                              <Grid sx={{ width: 1 / 5 }}>
                                <Field
                                  as={Select}
                                  name={`relationshipInfo[${index}].idCardNumber.code`}
                                  labelId={`relationshipInfo[${index}].idCardNumber.code`}
                                  label=""
                                  id={`relationshipInfo[${index}].idCardNumber.code`}
                                  defaultValue=""
                                  fullWidth
                                >
                                  <MenuItem value="AA">AA</MenuItem>
                                  <MenuItem value="AZE">AZE</MenuItem>
                                </Field>
                              </Grid>
                            )}

                            <Grid
                              sx={{
                                width:
                                  values.relationshipInfo[index].isForeigner &&
                                  !values.relationshipInfo[index]?.isMyiExist
                                    ? "50%"
                                    : values.relationshipInfo[index].isForeigner
                                    ? "100%"
                                    : 4 / 5,
                              }}
                            >
                              <Field
                                type="text"
                                name={`relationshipInfo[${index}].idCardNumber.number`}
                                as={TextField}
                                fullWidth
                                label={
                                  values.relationshipInfo[index].isForeigner
                                    ? "Passportun nömrəsi *"
                                    : "Vəsiqəsinin nömrəsi *"
                                }
                              />
                            </Grid>

                            {values.relationshipInfo[index]?.isMyiExist &&
                              values.relationshipInfo[index].isForeigner && (
                                <Field
                                  type="text"
                                  name={`relationshipInfo[${index}].tRPNumber`}
                                  as={TextField}
                                  fullWidth
                                  label="MYİ seriya Nömrəsi"
                                  value={
                                    employeeById?.relationshipInfo?.[index]
                                      ?.tRPNumber
                                  }
                                  required
                                />
                              )}
                            {values.relationshipInfo[index].isForeigner && (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      employeeById.relationshipInfo[index]
                                        ?.isMyiExist
                                    }
                                    onChange={handleChange}
                                    name={`relationshipInfo[${index}].isMyiExist`}
                                    color="primary"
                                  />
                                }
                                label="Müvəqqəti yaşayış icazəm var"
                              />
                            )}
                          </Grid>
                        </Grid>

                        {/* DOB and education info */}
                        <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                          <Field name={`relationshipInfo[${index}].dob`}>
                            {({ field, form }) => (
                              <DatePicker
                                {...field}
                                label="Doğum tarixi *"
                                inputFormat="dd/MM/yyyy"
                                value={
                                  employeeById?.relationshipInfo?.[index]
                                    ?.dob || null
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    helpertext={
                                      form.errors?.relationshipInfo?.[index]
                                        ?.dob
                                    }
                                    error={Boolean(
                                      form.errors?.relationshipInfo?.[index]
                                        ?.dob
                                    )}
                                    fullWidth
                                  />
                                )}
                              />
                            )}
                          </Field>
                          <Grid sx={{ width: "100%" }}>
                            <FormControl
                              error={
                                errors?.relationshipInfo?.[index]?.education &&
                                Boolean(
                                  errors?.relationshipInfo?.[index]?.education
                                )
                              }
                              fullWidth
                            >
                              <InputLabel
                                id={`relationshipInfo[${index}].education`}
                              >
                                Təhsili *
                              </InputLabel>
                              <Field
                                as={Select}
                                name={`relationshipInfo[${index}].education`}
                                labelId={`relationshipInfo[${index}].education`}
                                label="Təhsili"
                                id={`relationshipInfo[${index}].education-select`}
                                defaultValue=""
                                fullWidth
                              >
                                {educationType.map((item, index) => (
                                  <MenuItem
                                    key={index}
                                    value={
                                      employeeById?.relationshipInfo?.[index]
                                        ?.education
                                    }
                                  >
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </Field>
                            </FormControl>
                          </Grid>
                        </Grid>

                        {/* Service period and same workplace */}
                        <Grid
                          sx={{
                            display: "flex",
                            gap: 3,
                            alignItems: "center",
                          }}
                        >
                          <Grid sx={{ width: "100%" }}>
                            <Field
                              type="text"
                              name={`relationshipInfo[${index}].placeOfWork`}
                              as={TextField}
                              fullWidth
                              label="İş yeri (vəzifəsi) *"
                              value={
                                employeeById?.relationshipInfo?.[index]
                                  ?.placeOfWork
                              }
                            />
                          </Grid>
                          {values.relationshipInfo[index].isForeigner ? (
                            <FormControl
                              error={errors.citizenship && errors.citizenship}
                              fullWidth
                            >
                              <InputLabel id="citizenship">
                                Vətəndaşlığı *
                              </InputLabel>
                              <Field
                                as={Select}
                                name={`relationshipInfo[${index}].citizenship`}
                                labelId="citizenship"
                                label="citizenship"
                                id="citizenship-select"
                                fullWidth
                                required
                              >
                                {countries.map((item, index) => (
                                  <MenuItem
                                    key={index}
                                    value={
                                      employeeById?.relationshipInfo?.[index]
                                        ?.citizenship
                                    }
                                  >
                                    {
                                      employeeById?.relationshipInfo?.[index]
                                        ?.citizenship
                                    }
                                  </MenuItem>
                                ))}
                              </Field>
                              {errors?.relationshipInfo?.[index]?.citizenship &&
                                errors?.relationshipInfo?.[index]
                                  ?.citizenship && (
                                  <FormHelperText error>
                                    {
                                      errors?.relationshipInfo?.[index]
                                        ?.citizenship
                                    }
                                  </FormHelperText>
                                )}
                            </FormControl>
                          ) : null}
                        </Grid>
                        <Grid sx={{ marginTop: "20px", width: "50%" }}>
                          <FieldArray
                            name={`relationshipInfo[${index}].mobile`}
                          >
                            <>
                              {values.relationshipInfo[index].mobile &&
                                values.relationshipInfo[index].mobile.map(
                                  (mobile, ind) => (
                                    <Grid
                                      mb={3}
                                      key={ind}
                                      container
                                      spacing={2}
                                      alignItems="center"
                                    >
                                      <Grid item xs={9}>
                                        <Field
                                          type="tel"
                                          name={`relationshipInfo[${index}].mobile[${ind}]`}
                                          as={TextField}
                                          fullWidth
                                          label={`Mobil ${ind + 1}`}
                                          value={
                                            employeeById?.relationshipInfo?.[
                                              index
                                            ]?.mobile?.[ind]
                                          }
                                        />
                                        <ErrorMessage
                                          name={`relationshipInfo[${index}].mobil[${ind}]`}
                                          component="div"
                                        />
                                      </Grid>
                                    </Grid>
                                  )
                                )}
                            </>
                          </FieldArray>
                        </Grid>
                      </>
                    </div>
                  ))}
                </div>
              </FieldArray>
            </Form>
          )}
        </Formik>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default ViewRelationshipInfo;
