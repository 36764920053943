import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";

const EditChecklistItem = ({
  checklistItemDialog,
  setChecklistItemDialog,
  selectedCheckListItem,
  handleEditChecklistItem,
}) => {
  const [itemName, setItemName] = useState();

  useEffect(() => {
    if (selectedCheckListItem) {
      setItemName(selectedCheckListItem?.title);
    }
  }, [checklistItemDialog]);

  return (
    <>
      <Dialog
        fullWidth
        open={checklistItemDialog}
        onClose={() => {
          setChecklistItemDialog(false);
          setItemName("");
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4">Checklist item dəyişdir</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Checklist adı"
            type="text"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setChecklistItemDialog(false);
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          <Button
            onClick={() => {
              setChecklistItemDialog(false);
              handleEditChecklistItem(selectedCheckListItem._id, itemName);
            }}
            color="primary"
          >
            Dəyişdir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditChecklistItem;
