import {
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getCommonData, updateEmployee } from "../../api/api";
import { checkUniqueness } from "../../utils/checkUniquenessForEmployee";
import { socialStatusValidation } from "../employees/Validations";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomAccordion from "../ui/Accordion/Accordion";

const EditSocialStatus = ({
  employeeById,
  submitted,
  setSubmitted,
  setSelectedType,
  setOpenAddDialog,
}) => {
  const [socialTypes, setSocialTypes] = useState([]);
  const [isDate, setIsDate] = useState(false);
  const [pastData, setPastData] = useState([]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    const modifiedValues = {
      ...values,
      socialStatus:
        values.socialStatus &&
        values.socialStatus.map((social) => ({
          ...social,
          date: social.date ? moment(social.date).format("MM/DD/yyyy") : null,
        })),
    };
    await updateEmployee(employeeById._id, modifiedValues);
    setIsDate(false);
    setSubmitting(false);
    setSubmitted(!submitted);
    resetForm({ values });
  };

  useEffect(() => {
    const handleSocialType = async () => {
      try {
        const response = await getCommonData("socialStatusTypes");
        const socialTypes = response?.data || [];
        const combinedData = [];
        setSocialTypes(socialTypes);
        if (socialTypes.length) {
          combinedData.push({ socialTypes: [...socialTypes] });
        }
        handlePastData(combinedData);
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };
    handleSocialType();
  }, [submitted]);

  const handleStatusSelect = (item) => {
    const selectedStatus = socialTypes.find((status) => {
      return status.name === item;
    });
    return selectedStatus;
  };
  function handlePastData(data) {
    setPastData(data);
  }
  function handlePush(index) {
    socialTypes &&
      socialTypes.push({
        name: employeeById?.socialStatus[index]?.statusType,
        value: employeeById?.socialStatus[index]?.value,
      });
  }
  function handleHidden(name, data, index) {
    return pastData.every((past) => {
      return !past[name]?.some((item) => item.name === data);
    });
  }

  return (
    <CustomAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h6>Sosial Statusları</h6>
      </AccordionSummary>
      <AccordionDetails>
        {employeeById ? (
          <Formik
            initialValues={{
              socialStatus: employeeById.socialStatus || [], // Set the initial laborActivity values from employeeById
            }}
            onSubmit={handleSubmit}
            validationSchema={socialStatusValidation}
          >
            {({
              values,
              isValid,
              errors,
              touched,
              isSubmitting,
              handleChange,
              setFieldValue,
              setTouched,
              dirty,
            }) => (
              <>
                <Form>
                  <FieldArray name="socialStatus">
                    {({ push, remove }) => (
                      <div>
                        {values?.socialStatus &&
                          values?.socialStatus.map((form, index) => (
                            <div key={index}>
                              <>
                                {handlePush(index)}
                                <Typography mt={3} mb={3} variant="h6">
                                  {index + 1})Sosial Status
                                </Typography>

                                {/* status type and date */}
                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid sx={{ width: "100%" }}>
                                    <FormControl
                                      error={
                                        touched?.socialStatus?.[index]
                                          ?.statusType &&
                                        errors?.socialStatus?.[index]
                                          ?.statusType &&
                                        Boolean(
                                          errors?.socialStatus?.[index]
                                            ?.statusType
                                        )
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`socialStatus[${index}].statusType`}
                                      >
                                        Sosial Status növü
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`socialStatus[${index}].statusType`}
                                        labelId={`rsocialStatus[${index}].statusType`}
                                        label="Sosial Status növü"
                                        id={`socialStatus[${index}].statusType`}
                                        defaultValue=""
                                        onChange={(event) => {
                                          checkUniqueness(
                                            values.socialStatus,
                                            "statusType",
                                            event,
                                            setFieldValue,
                                            `socialStatus[${index}]`,
                                            "sosial status",
                                            handleStatusSelect
                                          );
                                        }}
                                        fullWidth
                                        error={
                                          touched?.socialStatus?.[index]
                                            ?.statusType &&
                                          errors?.socialStatus?.[index]
                                            ?.statusType &&
                                          Boolean(
                                            errors?.socialStatus?.[index]
                                              ?.statusType
                                          )
                                        }
                                      >
                                        {/* <MenuItem
                                      selected
                                      value={
                                        employeeById?.socialStatus[index]
                                          ?.statusType
                                      }
                                      hidden
                                    >
                                      {
                                        employeeById?.socialStatus[index]
                                          ?.statusType
                                      }
                                    </MenuItem> */}
                                        {[
                                          ...socialTypes.filter(
                                            (item, index, self) =>
                                              index ===
                                              self.findIndex(
                                                (t) => t.name === item.name
                                              )
                                          ),
                                        ]
                                          .sort((a, b) =>
                                            (a?.name || "").localeCompare(
                                              b?.name || "",
                                              "az"
                                            )
                                          )
                                          .map((item, index) => (
                                            <MenuItem
                                              hidden={handleHidden(
                                                "socialTypes",
                                                item.name,
                                                index
                                              )}
                                              key={index}
                                              value={item.name}
                                            >
                                              {item.name}
                                            </MenuItem>
                                          ))}
                                        <Button
                                          sx={{
                                            mt: 3,
                                            ml: 3,
                                          }}
                                          variant="outlined"
                                          onClick={() => {
                                            setOpenAddDialog(true);
                                            setSelectedType([
                                              "socialStatusTypes",
                                              { name: "Sosial statuslar" },
                                            ]);
                                          }}
                                        >
                                          Yeni sosial status növü əlavə et
                                        </Button>
                                      </Field>
                                      {touched?.socialStatus?.[index]
                                        ?.statusType &&
                                        errors?.socialStatus?.[index]
                                          ?.statusType &&
                                        errors?.socialStatus?.[index]
                                          ?.statusType && (
                                          <FormHelperText error>
                                            {
                                              errors?.socialStatus?.[index]
                                                ?.statusType
                                            }
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                    {/* {isSubmitting && (
                                  <ErrorMessage
                                    name={`socialStatus[${index}].statusType`}
                                    component="div"
                                  />
                                )} */}
                                  </Grid>
                                  <Grid
                                    onClick={() => setIsDate(true)}
                                    sx={{ width: "100%" }}
                                  >
                                    <Field name={`socialStatus[${index}].date`}>
                                      {({ field, form }) => (
                                        <>
                                          <DatePicker
                                            {...field}
                                            label="Sosial Statusun qüvvəyə minmə tarixi"
                                            inputFormat="dd/MM/yyyy"
                                            value={field.value || null}
                                            onChange={(date) => {
                                              if (
                                                date instanceof Date &&
                                                !isNaN(date)
                                              ) {
                                                form.setFieldValue(
                                                  `socialStatus[${index}].date`,
                                                  date
                                                );
                                              } else {
                                                form.setFieldValue(
                                                  `socialStatus[${index}].date`,
                                                  ""
                                                );
                                              }
                                            }}
                                            maxDate={new Date()}
                                            minDate={new Date(1940, 0, 1)}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                helperText={
                                                  isDate &&
                                                  form.errors?.socialStatus?.[
                                                    index
                                                  ]?.date &&
                                                  form.errors?.socialStatus?.[
                                                    index
                                                  ]?.date
                                                }
                                                error={
                                                  isDate &&
                                                  form.errors?.socialStatus?.[
                                                    index
                                                  ]?.date &&
                                                  Boolean(
                                                    form.errors?.socialStatus?.[
                                                      index
                                                    ]?.date
                                                  )
                                                }
                                                fullWidth
                                              />
                                            )}
                                          />
                                        </>
                                      )}
                                    </Field>
                                  </Grid>
                                </Grid>

                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      type="text"
                                      value={values.socialStatus[index].value}
                                      as={TextField}
                                      name={`socialStatus[${index}].value`}
                                      label="Sosial Statusun dəyəri"
                                      fullWidth
                                      InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "19px",
                                            }}
                                          >
                                            &#8380;
                                          </span>
                                        ),
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </>

                              <Button
                                sx={{ mt: 3 }}
                                variant="contained"
                                color="error"
                                type="button"
                                onClick={() => {
                                  remove(index);
                                  setIsDate(false);
                                }}
                              >
                                Sosial statusu sil
                              </Button>
                            </div>
                          ))}
                        <Button
                          sx={{ mt: 3 }}
                          variant="contained"
                          type="button"
                          onClick={() => {
                            push({ statusType: "", data: "", value: "" });
                            setIsDate(false);
                          }}
                        >
                          Sosial status əlavə et
                        </Button>
                      </div>
                    )}
                  </FieldArray>

                  <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!isValid || isSubmitting || !dirty}
                    >
                      Dəyişiklikləri yadda saxla
                    </Button>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        ) : (
          <div>Loading...</div>
        )}
      </AccordionDetails>
    </CustomAccordion>
  );
};

export default EditSocialStatus;
