import { api } from "../../api/axiosInterceptor";

export const getFitlersData = async ({
  dataGridName,
  structures = [],
  topPartOfStructures = [],
  baseStructureName = "",
  topPartOfStructureName = "",
  subStructureName = "",
  positionName = "",
}) => {
  console.log(subStructureName);
  let url = `/employee/static-data/filters?dataGridName=${dataGridName}`;

  if (structures.length > 0) {
    url += `&structures=${structures.join(",")}`;
  }
  if (topPartOfStructures.length > 0) {
    url += `&topPartOfStructures=${topPartOfStructures.join(",")}`;
  }

  if (baseStructureName.length > 0) {
    url += `&baseStructureName=${baseStructureName}`;
  }
  if (topPartOfStructureName.length > 0) {
    url += `&topPartOfStructureName=${topPartOfStructureName}`;
  }
  if (subStructureName.length > 0) {
    url += `&subStructureName=${subStructureName}`;
  }
  if (positionName.length > 0) {
    url += `&positionName=${positionName}`;
  }

  return await api.get(url);
};
