import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfDay } from "date-fns";
import { Field, FieldArray, useFormikContext } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { getCommonData } from "../../api/api";

const today = endOfDay(new Date());

const Documents = ({
  values,
  setSelectedType,
  setOpenAddDialog,
  submitted,
}) => {
  const { handleChange, errors, setErrors, setFieldValue } = useFormikContext();

  const handleFileUpload = useCallback(
    async (event, index) => {
      const file = event.target.files[0];

      if (!file) {
        return; // No file selected
      }

      // Check if the file is a blob
      if (!(file instanceof Blob)) {
        console.error("File is not a Blob");
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        const base64Image = reader.result;
        const fieldName = `documents[${index}].fileBase64`;
        handleChange({ target: { name: fieldName, value: base64Image } });
      };

      reader.onerror = () => {
        console.error("Error reading file");
      };

      reader.readAsDataURL(file);

      // Save the filename in the form state
      setFieldValue(`documents[${index}].fileName`, file.name);

      if (errors?.documents?.[index]?.fileBase64) {
        setErrors({
          ...errors,
          documents: [
            ...errors.documents.slice(0, index),
            {
              ...errors.documents[index],
              fileBase64: "",
            },
            ...errors.documents.slice(index + 1),
          ],
        });
      }
    },
    [handleChange, errors, setFieldValue]
  );

  const [documentsType, setDocumentsType] = useState([]);
  const documentList = [
    ".pdf",
    ".doc",
    ".docx",
    ".xls",
    ".xlsx",
    ".ppt",
    ".pptx",
    ".txt",
    ".rtf",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "text/plain",
    "application/rtf",
  ];

  const imageTypes = ["image/*"];

  const acceptTypes = [...imageTypes, ...documentList].join(", ");
  useEffect(() => {
    const handleDocumentsType = async () => {
      const response = await getCommonData("documentTypes");
      setDocumentsType(response.data);
    };
    handleDocumentsType();
  }, [submitted]);
  const fileInputRef = React.createRef();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <FieldArray name="documents">
      {({ push, remove }) => (
        <div>
          {values?.documents &&
            values?.documents.map((form, index) => (
              <div key={index}>
                <>
                  <Typography mt={3} mb={3} variant="h6">
                    {index + 1}) Şəxsi işi - Sənədlər və şəkillər
                  </Typography>

                  {/* documents category and itself*/}
                  <Grid
                    sx={{
                      display: "flex",
                      gap: 3,
                      mb: 3,
                      alignItems: "center",
                    }}
                  >
                    <Grid sx={{ width: "100%" }}>
                      <FormControl
                        error={
                          errors?.documents?.[index]?.docType &&
                          Boolean(errors?.documents?.[index]?.docType)
                        }
                        fullWidth
                      >
                        <InputLabel id={`documents[${index}].docType`}>
                          Sənədin növü *
                        </InputLabel>
                        <Field
                          as={Select}
                          name={`documents[${index}].docType`}
                          labelId={`documents[${index}].docType`}
                          label="Sənədin növü *"
                          id={`documents[${index}].docType`}
                          defaultValue=""
                          fullWidth
                          error={
                            errors?.documents?.[index]?.docType &&
                            Boolean(errors?.documents?.[index]?.docType)
                          }
                          onChange={async (e) => {
                            setFieldValue(
                              `documents[${index}].docType`,
                              e.target.value
                            );
                            if (errors?.documents?.[index]?.docType) {
                              setErrors({
                                ...errors,
                                documents: [
                                  ...errors.documents.slice(0, index),
                                  {
                                    ...errors.documents[index],
                                    docType: "",
                                  },
                                  ...errors.documents.slice(index + 1),
                                ],
                              });
                            }
                          }}
                        >
                          {documentsType &&
                            documentsType
                              .sort((a, b) => a.localeCompare(b, "az"))
                              .map((item, index) => (
                                <MenuItem value={item} key={index}>
                                  {item}
                                </MenuItem>
                              ))}
                          <Button
                            sx={{
                              mt: 3,
                              ml: 3,
                            }}
                            variant="outlined"
                            onClick={() => {
                              setOpenAddDialog(true);
                              setSelectedType([
                                "documentTypes",
                                { name: "Sənədlərin siyahısı" },
                              ]);
                            }}
                          >
                            Yeni sənəd növü əlavə et
                          </Button>
                        </Field>
                        {errors?.documents?.[index]?.docType && (
                          <FormHelperText error>
                            {errors?.documents?.[index]?.docType}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid sx={{ width: "100%", position: "relative" }}>
                      <div className="fileInpt">
                        <div className="fileInptTop">
                          <input
                            type="file"
                            accept={acceptTypes}
                            onChange={(event) => handleFileUpload(event, index)}
                            fullWidth
                            ref={fileInputRef}
                            style={{ visibility: "hidden" }}
                          />
                          <button type="button" onClick={handleButtonClick}>
                            Choose File
                          </button>
                        </div>
                        {values.documents[index].fileName && (
                          <Typography variant="body2">
                            {`Selected file: ${values.documents[index].fileName}`}
                          </Typography>
                        )}
                      </div>
                      <hr style={{ position: "absolute" }} />
                      {errors?.documents?.[index]?.fileBase64 && (
                        <FormHelperText error>
                          {errors?.documents?.[index]?.fileBase64}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>

                  {/* start date and end date */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Grid sx={{ width: "100%" }}>
                      <Field name={`documents[${index}].issuanceDate`}>
                        {({ field, form }) => (
                          <DatePicker
                            {...field}
                            label="Sənədin verilmə tarixi *"
                            inputFormat="dd/MM/yyyy"
                            value={field.value || null}
                            onChange={(date) => {
                              if (date instanceof Date && !isNaN(date)) {
                                form.setFieldValue(
                                  `documents[${index}].issuanceDate`,
                                  date
                                );
                                if (errors?.documents?.[index]?.issuanceDate) {
                                  setErrors({
                                    ...errors,
                                    documents: [
                                      ...errors.documents.slice(0, index),
                                      {
                                        ...errors.documents[index],
                                        issuanceDate: "",
                                      },
                                      ...errors.documents.slice(index + 1),
                                    ],
                                  });
                                }
                              } else {
                                form.setFieldValue(
                                  `documents[${index}].issuanceDate`,
                                  ""
                                );
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                helperText={
                                  form.errors?.documents?.[index]?.issuanceDate
                                }
                                error={Boolean(
                                  form.errors.documents?.[index]?.issuanceDate
                                )}
                                fullWidth
                              />
                            )}
                            maxDate={today}
                            minDate={new Date(1940, 0, 1)}
                            disableFuture
                            clearable
                            clearText="Clear"
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid sx={{ width: "100%" }}>
                      <Field name={`documents[${index}].expirationDate`}>
                        {({ field, form }) => (
                          <DatePicker
                            {...field}
                            label="Sənədin qüvvədən düşmə tarixi (müddətsiz ola bilər) *"
                            inputFormat="dd/MM/yyyy"
                            value={field.value || null}
                            onChange={(date) => {
                              if (date instanceof Date && !isNaN(date)) {
                                form.setFieldValue(
                                  `documents[${index}].expirationDate`,
                                  date
                                );
                              } else {
                                form.setFieldValue(
                                  `documents[${index}].expirationDate`,
                                  ""
                                );
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                helperText={
                                  form.errors?.documents?.[index]
                                    ?.expirationDate
                                }
                                error={Boolean(
                                  form.errors.documents?.[index]?.expirationDate
                                )}
                                fullWidth
                              />
                            )}
                            minDate={today}
                            disablePast
                            // clearable
                            // clearText="Clear"
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </>

                <Button
                  sx={{ mt: 3 }}
                  variant="contained"
                  color="error"
                  type="button"
                  onClick={() => remove(index)}
                >
                  Sənədi sil
                </Button>
              </div>
            ))}
          <Button
            sx={{ mt: 3 }}
            variant="contained"
            type="button"
            onClick={() => push({})}
          >
            Sənəd əlavə et
          </Button>
        </div>
      )}
    </FieldArray>
  );
};

export default Documents;
