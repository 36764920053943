import { composeWithDevTools } from "@redux-devtools/extension";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import { persistedReducer } from "./rootReducer";

export const store = configureStore(
  {
    reducer: persistedReducer,
  },
  composeWithDevTools()
);

export const persistor = persistStore(store);
