import React from "react";
import { Helmet } from "react-helmet-async";
import { Typography } from "@mui/material";

const Header = ({ data }) => {
  return (
    <>
      <Helmet title={data?.title} />
      <Typography variant={data?.variant} gutterBottom display="inline">
        {data?.title}
      </Typography>
    </>
  );
};

export default Header;
