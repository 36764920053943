import React, { useState } from "react";
import { Grid } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import SickIcon from "@mui/icons-material/Sick";
import InventoryIcon from "@mui/icons-material/Inventory";
import TableChartIcon from "@mui/icons-material/TableChart";
import MessageIcon from "@mui/icons-material/Message";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
// import "./Navbar.css";
import style from "./myProfileStyle.module.css";

import { Link } from "react-router-dom";

const Navbar = ({ navbarStatus }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={style.container}
      onMouseLeave={() => {
        setTimeout(() => {
          if (!document.querySelector(".nav-list:hover")) {
            setIsHovered(false);
          }
        }, 100);
        navbarStatus(false);
      }}
    >
      <div
        className={style.button}
        onMouseEnter={() => {
          setIsHovered(true);
          navbarStatus(true);
        }}

        // onClick={() => {
        //   if (!isHovered) {
        //     setIsHovered(true);
        //     navbarStatus(true);
        //   } else {
        //     setIsHovered(false);
        //     navbarStatus(false);
        //   }
        // }}
      >
        <MenuIcon sx={{ width: "80%", height: "80%" }} />
      </div>
      <div
        className={isHovered ? style.navlistshow : style.navlist}
        // onMouseEnter={() => setIsHovered(true)}
        // onMouseLeave={() => setIsHovered(false)}
      >
        <Grid className={style.navbarMain}>
          <Grid className={style.navItem}>
            <PersonIcon className={style.icon} />
            <Link to="/my-cabinet" className={style.navItemText}>
              Şəxsi Məlumatlarım
            </Link>
          </Grid>
          <Grid className={style.navItem}>
            <LocalAtmIcon className={style.icon} />
            <Link to="/my-cabinet/mywages" className={style.navItemText}>
              Əməkhaqqlarım
            </Link>
          </Grid>
          <Grid className={style.navItem}>
            <ReceiptLongIcon className={style.icon} />
            <Link
              to="/my-cabinet/orderscontracts"
              className={style.navItemText}
            >
              Əmr və müqavilələr
            </Link>
          </Grid>
          <Grid className={style.navItem}>
            <HistoryEduIcon className={style.icon} />
            <Link
              to="/my-cabinet/explanationsapplications"
              className={style.navItemText}
            >
              Ərizə və izahatlar
            </Link>
          </Grid>
          <Grid className={style.navItem}>
            <BeachAccessIcon className={style.icon} />
            <Link to="/my-cabinet/vacations" className={style.navItemText}>
              Məzuniyyətlərim
            </Link>
          </Grid>
          <Grid className={style.navItem}>
            <SickIcon className={style.icon} />
            <Link
              to="/my-cabinet/sickleavecertificate"
              className={style.navItemText}
            >
              Xəstəlik vərəqələrim
            </Link>
          </Grid>
          <Grid className={style.navItem}>
            <InventoryIcon className={style.icon} />
            <Link to="/my-cabinet/inventories" className={style.navItemText}>
              İnventarlarım
            </Link>
          </Grid>
          <Grid className={style.navItem}>
            <TableChartIcon className={style.icon} />
            <Link to="" className={style.navItemText}>
              İş qrafikim
            </Link>
          </Grid>
          <Grid className={style.navItem}>
            <MessageIcon className={style.icon} />
            <Link to="/my-cabinet/requests" className={style.navItemText}>
              Dəyişiklik sorğularım
            </Link>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Navbar;
