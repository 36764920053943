import { api } from "../../api/axiosInterceptor";

// GET: /employee/e -> Get Employees
export const getEmployees = async (payload) => {
  try {
    const response = await api.get(`/employee/e`, {
      params: {
        limit: payload.limit,
        offset: payload.skip,
        // search: payload.name || "",
        filter: payload.filter && payload.filter,
      },
    });
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};
export const updateEmployeeById = async (staffId, employeeId) => {
  try {
    const res = await api.patch(`/employee/staff/add-employee/${staffId}`, {
      id: employeeId,
    });
    return res.data;
  } catch (error) {
    console.log("error: ", error);
  }
};
export const getOneEmployee = async (id) => {
  try {
    const response = await api.get(`/employee/e/${id}`);
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};
// PATCH: /employee/staff/add-employee/:id -> Add Employee to Staff
export const addEmpToStaff = async (payload) => {
  try {
    // const response = await api.patch(
    //   `/employee/staff/add-employee/${payload.staffId}`,
    //   {
    //     id: payload.empId,
    //   }
    // );
    const response = await api.post(
      `/employee/employee-request/user/employmentAppRequest`,
      {
        structureId: payload.structureId,
        positionId: payload.positionId,
        jobStartDate: payload.jobStartDate,
        agreementDate: payload.agreementDate,
        contractId: payload.contractId,
        staffId: payload.staffId,
        employeeId: payload.employeeId,
      }
    );
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const removeStaff = async (id) => {
  try {
    const response = await api.delete(`/employee/staff/${id}`);
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const removeEmployeeFromStaff = async (staffId, employee_id) => {
  try {
    const response = await api.patch(
      `/employee/staff/remove-employee/${staffId}`,
      { id: employee_id }
    );
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};
// POST: /employee/contract/static/:contractName -> Generate Contract
export const generateContract = async (payload) => {
  try {
    const response = await api.post(
      `/employee/contract/static/employment-application`,
      payload
    );
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const getCommand = async (id) => {
  try {
    const response = await api.get(`/employee/contract/${id}`);
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const checkOrder = async (id) => {
  try {
    const response = await api.get(
      `/employee/contract/static/labour-order?staffId=${id}`
    );
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const addOrder = async (id, orderClause) => {
  try {
    const response = await api.post(
      `/employee/contract/static/labour-order?staffId=${id}`,
      { orderClause }
    );
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const getVacants = async ({ offset = 0, search, staffUnit }) => {
  return await api.get(
    `/employee/e/vacants?offset=${offset}&search=${search}&staffUnit=${staffUnit}`
  );
};

export const downloadEmps = async ({
  limit,
  offset,
  filter = undefined,
  columns = undefined,
}) => {
  const withFilters = filter ? `&filter=${filter}` : "";
  const withCols = columns ? `&columns=${columns}` : "";

  const endpoint = `/employee/e/download?limit=${limit}&offset=${offset}${withFilters}${withCols}`;

  return await api.get(endpoint);
};
