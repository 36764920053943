import { Grid, InputBase, Typography } from "@mui/material";
import React from "react";

export default function CommentInput({ label, value }) {
  return (
    <Grid sx={styles.container}>
      <Typography color={"white"}>{label}</Typography>
      <InputBase
        readOnly={true}
        value={value}
        minRows={3}
        disabled={true}
        maxRows={3}
        multiline={true}
        placeholder="Şərh yoxdur..."
        contentEditable={false}
        sx={styles.input}
        inputProps={{
          style: {
            color: value && styles.input.color,
            opacity: "1",
            "-webkit-text-fill-color": styles.input.color,
          },
        }}
      />
    </Grid>
  );
}

const styles = {
  container: { width: "100%", marginTop: 5 },
  input: {
    backgroundColor: "#FFF",
    color: "#000",
    paddingY: 5,
    paddingX: "40px",
    width: "100%",
    borderRadius: 3,
    lineHeight: "24px",
  },
};
