import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const CheckboxInput = ({
  labelContent,
  name,
  id,
  onChange,
  checked = false,
}) => {
  return (
    <FormGroup className="bg-white" style={{ borderRadius: "5px" }}>
      <FormControlLabel
        className="m-0 px-2 text-333"
        control={
          <Checkbox
            style={{ padding: "5px", height: "33px" }}
            id={id}
            name={name}
            className="ps-0 pe-1 text-333"
            onChange={onChange}
            checked={checked}
          />
        }
        label={labelContent}
      />
    </FormGroup>
  );
};

export default CheckboxInput;
