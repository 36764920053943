import { Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const CustomButton = ({
  bgColor,
  hoverBgColor,
  Icon,
  width,
  content,
  disabled = false,
  onClick = () => {},
}) => {
  const useStyles = makeStyles(() => ({
    generalStyles: {
      textTransform: "capitalize",
    },
    buttonStyles: {
      backgroundColor: bgColor,
      borderRadius: "4px",
      color: "white",
      "&:hover": {
        backgroundColor: hoverBgColor,
      },
    },
    disableButtonStyles: {
      backgroundColor: "#AFAFAF !important",
      color: "#fff !important",
    },
  }));

  const classes = useStyles();
  return (
    <Button
      variant="contained"
      className={`${width ? width : "w-100"} ${
        !disabled ? classes.buttonStyles : classes.disableButtonStyles
      } ${classes.generalStyles}`}
      startIcon={Icon && <Icon />}
      disabled={disabled}
      onClick={onClick}
    >
      {content}
    </Button>
  );
};

export default CustomButton;
