import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

const DeleteDataDialog = ({
  openStructureDelete,
  setOpenStructureDelete,
  dataTable,
  submitted,
  setSubmitted,
  structureId,
  setStructureId,
  handleStructureDelete,
}) => {
  return (
    <>
      {structureId === dataTable._id ? (
        <Dialog
          open={openStructureDelete}
          onClose={() => {
            setOpenStructureDelete(false);
            setStructureId();
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Silmək istədiyinizdən əminsiniz ?
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenStructureDelete(false);
                setStructureId();
              }}
              color="primary"
            >
              Ləğv et
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setOpenStructureDelete(false);
                handleStructureDelete(structureId);
              }}
            >
              Bəli
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};

export default DeleteDataDialog;
