import React, { useEffect, useState } from "react";
import NestedSelect from "../../utils/NestedSelect";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  getAllStructuresEmptyStaff,
  getStructuresEmptyStaffs,
} from "../../api/api";
import moment from "moment";
import { toast } from "react-toastify";
import * as employeeServices from "../../services/employees/EmployeeServices";
import { getStructureById } from "../../services/structure/StructureServices";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddToStaff = ({
  employee,
  positionId,
  setPositionId,
  setAddToStaff,
  structureId,
  setStructureId,
  structureById,
  setStructureById,
  staffId,
  setStaffId,
}) => {
  const [allStructures, setAllStructures] = useState([]);

  const [organizationId, setOrganizationId] = useState();
  const [nestedChildren, setNestedChildren] = useState([]);
  const [contractDownload, setContractDownload] = useState();
  const [contractPosition, setContractPosition] = useState();

  const [utilsEmployees, setUtilsEmployees] = useState();

  // Get all structures
  useEffect(() => {
    const handleAllStructures = async () => {
      try {
        const response = await getAllStructuresEmptyStaff({
          getStructures: true,
        });
        setAllStructures(response.data);
      } catch (error) {
        console.log("err", error);
      }
    };
    handleAllStructures();
  }, []);

  // Handle organization name
  const handleOrganizationName = async (e) => {
    const selectedStructure = e?.target?.value;
    setStructureId(selectedStructure?._id);
    setOrganizationId(selectedStructure?.organization?._id);

    const response = await getStructuresEmptyStaffs({
      structureId: selectedStructure?._id,
    });
    setNestedChildren(response?.data?.children);
  };

  useEffect(() => {
    if (structureId) {
      const fetchStructureById = async () => {
        const res = await getStructureById(structureId);
        setStructureById(res);
      };
      fetchStructureById();
    }
  }, [structureId]);

  // Generate contract
  const generateContract = async () => {
    try {
      const payload = {
        docModel: "Employee",
        subjects: [employee?._id],
        data: {
          structure: structureId,
          position: contractPosition,
          agreementDate: moment().format("MM/DD/YYYY"),
          jobStartDate: moment().format("MM/DD/YYYY"),
          staffId: staffId,
        },
      };

      const response = await employeeServices.generateContract(payload);
      if (response) {
        // setConfirmButtonActive(true);
        setContractDownload(response?._id);
        setAddToStaff((prevValue) => ({
          ...prevValue,
          employeeId: employee?._id,
          staffId: positionId?._id,
          structureId: structureId,
          positionId: contractPosition,
          contractId: response?._id,
        }));
        toast.success("Ərizə uğurla tərtib edildi və yönləndirildi!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        // setConfirmButtonActive(false);
        toast.error("Hər hansı bir xəta baş verdi!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      console.log("error: ", error);
      toast.error("Server xətası baş verdi!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  return (
    <Box>
      <Box>
        <Typography p={5} textAlign="center" variant="h3">
          Əməkdaşın ştata təyin olunması
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 15,
        }}
      >
        <Box
          sx={
            allStructures.length
              ? {
                  width: 1 / 3,
                  py: 2,
                  maxHeight: "55vh",
                  overflowY: "auto",
                }
              : {
                  width: 3 / 3,
                  py: 2,
                  maxHeight: "55vh",
                  overflowY: "auto",
                }
          }
        >
          <FormControl sx={{ mb: 6 }} fullWidth>
            {allStructures.length ? (
              <>
                <InputLabel id="organizationName">Təşkilatı seçin</InputLabel>
                <Select
                  labelId="organizationName"
                  id="organizationName"
                  input={<Input />}
                  fullWidth
                  MenuProps={MenuProps}
                  onChange={(e) => {
                    handleOrganizationName(e);
                  }}
                >
                  {allStructures.map((structure, index) => {
                    if (structure?.organization?.name) {
                      return (
                        <MenuItem key={index} value={structure}>
                          {structure?.organization?.name}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
              </>
            ) : (
              <Grid
                sx={{
                  fontSize: "20px",
                  textAlign: "center",
                }}
              >
                Mövcud struktur(lar)da boş ştat yoxdur
              </Grid>
            )}
          </FormControl>
          {structureId && organizationId && nestedChildren !== "" ? (
            <NestedSelect
              nestedChildren={nestedChildren}
              structureId={structureId}
              setPositionId={setPositionId}
              setUtilsEmployees={setUtilsEmployees}
              setContractPosition={setContractPosition}
              setStaffId={setStaffId}
            />
          ) : null}
        </Box>
        {structureById?.isVerified === true && (
          <Box
            sx={{
              width: 2 / 3,
              py: 2,
              maxHeight: "55vh",
              overflowY: "auto",
            }}
          >
            <Typography variant="h5" mb={3}>
              Ştat Vahidi - {positionId?.staffUnit}
            </Typography>
            <Typography variant="h5" mb={3}>
              Vəzifə - {positionId?.position.name}
            </Typography>
            <Typography variant="h5" mb={5}>
              Vəzifə maaşı - {positionId?.positionSalary} ₼
            </Typography>
            <Button
              onClick={generateContract}
              variant="contained"
              color="success"
              sx={{ color: "white" }}
            >
              Ərizəni Tərtib et
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AddToStaff;
