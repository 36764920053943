import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { addCommonData, getCommonData } from "../../api/api";
import { handleUniqueness } from "../employees/Validations";

const pinRegex = /^[a-hj-np-zA-HJ-NP-Z0-9]*$/;

const AddCommonDataDialog = ({
  openAddDialog,
  setOpenAddDialog,
  state,
  dispatch,
  selected_type,
}) => {
  const { selectedType, submitted } = state;
  const [errors, setErrors] = useState({
    name: false,
    lastname: false,
    fathername: false,
    pin: false,
    type: false,
    value: false,
    vacation: false,
  });
  const [name, setName] = useState("");

  const [lastname, setLastname] = useState("");
  const [fathername, setFathername] = useState("");
  const [pin, setPin] = useState("");

  const [socialValue, setSocialValue] = useState("");
  const [wageType, setWageType] = useState("number");
  const [reason, setReason] = useState("");
  const [itemTypes, setItemTypes] = useState([]);
  const [itemType, setItemType] = useState("");
  const [vacationDays, setVacationDays] = useState("");
  const addWorkValidation = (selected_type) => {
    let baseSchema = Yup.object({
      name: Yup.string().required("Ad daxil edilməlidir"),
      type: Yup.string().required("Tipi daxil edilməlidir"),
      value: Yup.string().required("Dəyəri daxil edilməlidir"),
      vacation: Yup.string().required("Məzuniyyət daxil edilməlidir"),
      reason: Yup.string().required("Səbəbi daxil edilməlidir"),
    });

    if (selected_type !== "İş şəraitinə görə əlavələr") {
      baseSchema = baseSchema.shape({
        lastname: Yup.string().required("Soyad daxil edilməlidir"),
        fathername: Yup.string().required("Ata adı daxil edilməlidir"),
        pin: Yup.string()
          .min(6, "FIN nömrəsi minimum 6 simvol olmalıdır")
          .max(7, "FIN nömrəsi maksimum 7 simvol olmalıdır")
          .matches(pinRegex, "FIN nömrəsi düzgün deyil")
          .required("FIN nömrəsi daxil edilməlidir")
          .test(
            "check-pin",
            "Bu PIN artıq istifadə edilib",
            async function (value) {
              const response = await handleUniqueness("pin", value);
              return response;
            }
          ),
      });
    }

    return baseSchema;
  };

  const isValidInput = (input) => {
    const regex = /^[a-zA-Z0-9ƏəİıÖöÜüÇçŞşĞğ\s\S]*$/;
    return regex.test(input);
  };

  const isDisabled = (key) => {
    if (key === "recruiterList") {
      // Check conditions for "recruiterList"
      return !(
        name?.trim() &&
        lastname?.trim() &&
        fathername?.trim() &&
        pin?.trim() &&
        isValidInput(pin?.trim())
      );
    } else if (
      selectedType[0] === "socialStatusTypes" ||
      selectedType[0] === "overtimeRules" ||
      selectedType[0] === "academicDegrees" ||
      selectedType[0] === "supplementalWageLabour" ||
      selectedType[0] === "supplementalWageLabourItems"
    ) {
      return !(
        name?.trim() &&
        isValidInput(name.trim()) &&
        Boolean(socialValue?.trim())
      );
      // return true;
    } else {
      // Default case or any other case
      return (
        !(name?.trim() || isValidInput(name?.trim())) ||
        (selected_type === "İş şəraitinə görə əlavələr" &&
          Object?.values(errors)?.some((error) => error))
      );
    }
  };

  const handleAddCommonData = async (key, name, value) => {
    let payload = [];
    if (
      key === "socialStatusTypes" ||
      key === "overtimeRules" ||
      key === "academicDegrees"
    ) {
      payload = [{ key: key, values: [{ name: name, value: Number(value) }] }];
    } else if (key === "supplementalWageLabour") {
      payload = [
        {
          key: key,
          values: [
            {
              name: name,
              value: Number(value),
              reason: reason,
              valueType: wageType,
            },
          ],
        },
      ];
    } else if (key === "supplementalWageLabourItems") {
      payload = [
        {
          key: key,
          values: [
            {
              name: name,
              value: Number(value),
              reason: reason,
              valueType: wageType,
              parent: itemType,
              vacationDays: Number(vacationDays),
            },
          ],
        },
      ];
    } else if (key === "inventoryItems" || key === "carModels") {
      payload = [{ key: key, values: [{ name: name, parent: itemType }] }];
    } else if (key === "recruiterList") {
      payload = [
        {
          key: key,
          values: [
            {
              name: name,
              lastname: lastname,
              fathername: fathername,
              pin: pin,
            },
          ],
        },
      ];
    } else {
      payload = [{ key: key, values: [name] }];
    }

    await addCommonData(payload);
    setItemType("");
    setName();
    setLastname();
    setFathername();
    setPin();
    setSocialValue();
    setReason();
    setVacationDays();
    setOpenAddDialog(false);

    dispatch({ type: "SET_SUBMITTED", payload: !submitted });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleAddCommonData(selectedType[0], name, socialValue);
  };
  const getInventoryTypes = async () => {
    if (selectedType[0] === "inventoryItems") {
      try {
        const response = await getCommonData("inventoryTypes");
        setItemTypes(response.data);
      } catch (error) {
        console.log(error);
      }
    } else if (selectedType[0] === "carModels") {
      try {
        const response = await getCommonData("carManufacturers");
        setItemTypes(response.data);
      } catch (error) {
        console.log(error);
      }
    } else if (selectedType[0] === "supplementalWageLabourItems") {
      try {
        const response = await getCommonData("supplementalWageLabourItems");
        const types = ["Ağır Zərərli", "Zərərli"];

        setItemTypes(types);
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    const checkErrors = async () => {
      const checkPin = async (value) => {
        if (!value || value.trim() === "")
          return "FIN nömrəsi daxil edilməlidir";
        else if (value.length < 6)
          return "FIN nömrəsi minimum 6 simvol olmalıdır";
        else if (value.length > 7)
          return "FIN nömrəsi maksimum 7 simvol olmalıdır";
        else if (!pinRegex.test(value)) return "FIN nömrəsi düzgün deyil";
        else if (await !handleUniqueness("pin", value))
          return "Bu FİN artıq istifadə edilib";
        else return false;
      };

      const newErrors = {
        name: !name || name.trim() === "",
        type: !itemType || itemType.trim() === "",
        value: !socialValue || socialValue.trim() === "",
        vacation: !vacationDays || vacationDays.trim() === "",
        lastname:
          selected_type !== "İş şəraitinə görə əlavələr"
            ? !lastname || lastname.trim() === ""
            : false,
        fathername:
          selected_type !== "İş şəraitinə görə əlavələr"
            ? !fathername || fathername.trim() === ""
            : false,
        pin:
          selected_type !== "İş şəraitinə görə əlavələr"
            ? await checkPin(pin)
            : false,
      };
      setErrors(newErrors);
    };

    checkErrors();
  }, [name, itemType, socialValue, vacationDays, lastname, fathername, pin]);

  useEffect(() => {
    getInventoryTypes();
  }, [selectedType]);
  return (
    <>
      <Dialog
        fullWidth
        open={openAddDialog}
        onClose={() => {
          setOpenAddDialog(false);
          setItemType("");
          setName("");
          setLastname("");
          setFathername("");
          setPin("");
          setSocialValue("");
          setReason("");
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4">{selectedType[1].name} əlavə et</Typography>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              name: "",
              type: "",
              value: "",
              vacation: "",
              reason: "",
              lastname: "",
              fathername: "",
              pin: "",
            }}
            validationSchema={addWorkValidation}
          >
            <Form onSubmit={handleSubmit}>
              <Field
                as={TextField}
                autoFocus
                margin="dense"
                id="name"
                value={name}
                name="name"
                label="Adı *"
                type="text"
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
              {selectedType[0] !== "recruiterList" && errors.name ? (
                <ErrorMessage
                  name="name"
                  className="error_work"
                  component="span"
                  style={{ marginBottom: "10px" }}
                />
              ) : null}

              {selectedType[0] === "recruiterList" ? (
                <>
                  {/* <FormControl fullWidth>
                    <Field
                      as={TextField}
                      margin="dense"
                      id="firstname"
                      name="firstname"
                      value={firstname}
                      label="Adı *"
                      onKeyDown={(e) => {
                        if (e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setFirstname(e.target.value);
                      }}
                    />
                    {errors.firstname ? (
                      <ErrorMessage
                        name="firstname"
                        className="error_work"
                        component="span"
                      />
                    ) : null}
                  </FormControl> */}
                  <FormControl fullWidth>
                    <Field
                      as={TextField}
                      margin="dense"
                      id="lastname"
                      name="lastname"
                      value={lastname}
                      label="Soyadı *"
                      onKeyDown={(e) => {
                        if (e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setLastname(e.target.value);
                      }}
                    />
                    {errors.lastname ? (
                      <ErrorMessage
                        name="lastname"
                        className="error_work"
                        component="span"
                      />
                    ) : null}
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      as={TextField}
                      margin="dense"
                      id="fathername"
                      name="fathername"
                      value={fathername}
                      label="Ata adı *"
                      onKeyDown={(e) => {
                        if (e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setFathername(e.target.value);
                      }}
                    />
                    {errors.fathername ? (
                      <ErrorMessage
                        name="fatername"
                        className="error_work"
                        component="span"
                      />
                    ) : null}
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      as={TextField}
                      margin="dense"
                      id="pin"
                      name="pin"
                      value={pin}
                      label="FİN *"
                      onKeyDown={(e) => {
                        if (e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setPin(e.target.value);
                      }}
                    />
                    {errors?.pin?.length > 0 ? (
                      <span style={{ fontSize: "12px", color: "#f44336" }}>
                        {errors.pin}
                      </span>
                    ) : null}
                  </FormControl>
                </>
              ) : null}

              {(selectedType[0] === "inventoryItems" ||
                selectedType[0] === "carModels" ||
                selectedType[0] === "supplementalWageLabourItems") && (
                <FormControl fullWidth sx={{ mt: 3 }}>
                  <InputLabel id={`type`}>Tipi *</InputLabel>
                  <Field
                    as={Select}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="type"
                    value={itemType}
                    label="Age"
                    onChange={(e) => {
                      setItemType(e.target.value);
                    }}
                  >
                    {itemTypes &&
                      itemTypes.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                  </Field>
                  {errors.type ? (
                    <ErrorMessage
                      name="type"
                      className="error_work"
                      component="span"
                    />
                  ) : null}
                </FormControl>
              )}

              {selectedType[0] === "socialStatusTypes" ||
              selectedType[0] === "overtimeRules" ||
              selectedType[0] === "academicDegrees" ||
              selectedType[0] === "supplementalWageLabour" ||
              selectedType[0] === "supplementalWageLabourItems" ? (
                <Box sx={{ display: "flex", gap: 5 }}>
                  <FormControl fullWidth>
                    <Field
                      as={TextField}
                      margin="dense"
                      id="value"
                      name="value"
                      value={socialValue}
                      label="Dəyəri *"
                      type="number"
                      onKeyDown={(e) => {
                        if (e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setSocialValue(e.target.value);
                      }}
                    />
                    {errors.value ? (
                      <ErrorMessage
                        name="value"
                        className="error_work"
                        component="span"
                      />
                    ) : null}
                  </FormControl>

                  {(selectedType[0] === "supplementalWageLabour" ||
                    selectedType[0] === "supplementalWageLabourItems") && (
                    <FormControl component="fieldset" variant="standard">
                      <RadioGroup
                        aria-label="position"
                        name="position"
                        value={wageType}
                        onChange={(e) => {
                          setWageType(e.target.value);
                        }}
                        row
                      >
                        <FormControlLabel
                          value="number"
                          control={<Radio color="primary" />}
                          label="Dəyər"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="percentage"
                          control={<Radio color="primary" />}
                          label="Faiz"
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                </Box>
              ) : null}

              {selectedType[0] === "supplementalWageLabourItems" ? (
                <FormControl fullWidth>
                  <Field
                    as={TextField}
                    margin="dense"
                    id="vacation"
                    name="vacation"
                    value={vacationDays}
                    label="Məzuniyyət günlərinin sayı *"
                    type="number"
                    onChange={(e) => setVacationDays(e.target.value)}
                  />
                  {errors.vacation ? (
                    <ErrorMessage
                      name="vacation"
                      className="error_work"
                      component="span"
                    />
                  ) : null}
                </FormControl>
              ) : null}

              {selectedType[0] === "supplementalWageLabour" ||
              selectedType[0] === "supplementalWageLabourItems" ? (
                <Field
                  as={TextField}
                  margin="dense"
                  id="reason"
                  name="reason"
                  label="Səbəbi"
                  type="text"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  fullWidth
                />
              ) : null}

              <DialogActions>
                <Button
                  onClick={() => {
                    setOpenAddDialog(false);
                    setName("");
                    setLastname("");
                    setFathername("");
                    setPin("");
                    setSocialValue("");
                    setWageType("");
                    setReason("");
                    setItemTypes("");
                    setItemType("");
                    setVacationDays("");
                  }}
                  color="primary"
                >
                  Ləğv et
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isDisabled(selectedType[0])}
                >
                  Əlavə et
                </Button>
              </DialogActions>
            </Form>
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddCommonDataDialog;
