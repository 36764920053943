import { Typography, Grid, Card, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { STAFFS_STATUS_TYPE } from "../../../constants";
import { statisticHeaders } from "../appointmentItems";
import DonutChart from "../../../components/chart/DonutChart";
import { getStaffStatistics } from "../../../services/staffTracking/StaffTrackingServices";

export default function AppointmentStatistics() {
  const [data, setData] = useState({
    [STAFFS_STATUS_TYPE.PENDING_APPLICATION]: 0,
    [STAFFS_STATUS_TYPE.PENDING_CONTRACT]: 0,
    [STAFFS_STATUS_TYPE.PENDING_CONTRACT_APPROVE]: 0,
    [STAFFS_STATUS_TYPE.PENDING_ORDER]: 0,
    [STAFFS_STATUS_TYPE.PENDING_ORDER_APPROVE]: 0,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);

      const { data } = await getStaffStatistics();
      data &&
        setData({
          [STAFFS_STATUS_TYPE.PENDING_APPLICATION]:
            data.byStatus[STAFFS_STATUS_TYPE.PENDING_APPLICATION],
          [STAFFS_STATUS_TYPE.PENDING_CONTRACT]:
            data.byStatus[STAFFS_STATUS_TYPE.PENDING_CONTRACT],
          [STAFFS_STATUS_TYPE.PENDING_CONTRACT_APPROVE]:
            data.byStatus[STAFFS_STATUS_TYPE.PENDING_CONTRACT_APPROVE],
          [STAFFS_STATUS_TYPE.PENDING_ORDER]:
            data.byStatus[STAFFS_STATUS_TYPE.PENDING_ORDER],
          [STAFFS_STATUS_TYPE.PENDING_ORDER_APPROVE]:
            data.byStatus[STAFFS_STATUS_TYPE.PENDING_ORDER_APPROVE],
        });
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  };

  const colorHandler = (key) => {
    switch (key) {
      case STAFFS_STATUS_TYPE.PENDING_APPLICATION:
        return "#a57c1b";
      case STAFFS_STATUS_TYPE.PENDING_CONTRACT:
        return "#786028";
      case STAFFS_STATUS_TYPE.PENDING_CONTRACT_APPROVE:
        return "#363445";
      case STAFFS_STATUS_TYPE.PENDING_ORDER:
        return "#48446e";
      case STAFFS_STATUS_TYPE.PENDING_ORDER_APPROVE:
        return "#5e569b";
      default:
        break;
    }
  };

  const dataDoun = {
    labels: ["a", "a", "a", "a", "a"],
    datasets: [
      {
        data: [5, 7, 3, 6, 8],
        backgroundColor: ["red", "blue", "yellow", "green", "orange"],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "right",
      },
    },
  };

  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <Card sx={{ width: "100%" }}>
        <div
          style={{
            ...styles.container,
            marginBottom: 6,
            paddingTop: 2.5,
            width: "100%",
            // height: "auto",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {/* <Grid
        pt={2.5}
        marginBottom={6}
        key="statistics"
        width={"100%"}
        height={"auto"}
        sx={styles.container}
      > */}
          {/* <Grid sx={{ display: "flex", flexDirection: "row" }}> */}
          <Grid width={"50%"} p={"5px"}>
            {statisticHeaders.map((item, index) => (
              <Grid
                key={`${item} ${index}`}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: colorHandler(item.key),
                  marginY: "5px",
                  borderRadius: "10px",
                  paddingX: "10px",
                }}
              >
                {/* <Grid
                height={"10px"}
                sx={{ backgroundColor: "red", borderRadius: "5px" }}
                width={"10px"}
                mr={"5px"}
              /> */}
                <Typography fontSize={18}>{item.label}</Typography>
                <Typography fontSize={18}>{data[item.key]}</Typography>
              </Grid>
            ))}
          </Grid>
          <Grid width={"50%"} margin={"auto"}>
            <DonutChart colorHandler={colorHandler} data={data} total={20} />
          </Grid>
          {/* </Grid> */}
          {/* </Grid> */}
        </div>
      </Card>
    </Box>
  );
}

const styles = {
  container: {
    // backgroundColor: "rgba(255,255,255,0.05)",
    borderRadius: "10px",
  },
};
