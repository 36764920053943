import React from "react";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
// Helpers
import { templateInputSource, templateInputTypes } from "../../../constants";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const _SimpleDataTable = ({ headers, data, formik, readOnly = false }) => {
  const classes = useStyles();

  // const handleChangeFiled = (selectedIndex, field, value) => {
  //   const copiedArray = data.map((item, index) => {
  //     if (index === selectedIndex) {
  //       if (field === "min" || field === "max") {
  //         if (value === "0") {
  //           return { ...item, [field]: +value };
  //         }
  //         if (value.toString().includes("."))
  //           value = parseInt(value.toString().split("."));
  //         if (isNaN(value) || value < 1) value = 0;
  //         if (value.length > 1 && value[0] === 0) value = value[1];
  //         // value = value.replace(/^0+/, "");
  //       } else if (field === "type" && value === "date") {
  //         item = { ...item, [field]: value, min: "", max: "" };
  //       }
  //       return { ...item, [field]: value };
  //     }
  //     return item;
  //   });

  //   formik.setFieldValue("fields", copiedArray);
  // };

  const handleChangeFiled = (selectedIndex, field, value) => {
    const copiedArray = data.map((item, index) => {
      if (index === selectedIndex) {
        let updatedItem = { ...item };

        if (field === "min" || field === "max") {
          value = processMinMaxValue(value);
          updatedItem[field] = +value;
        } else if (field === "type" && value === "date") {
          updatedItem = { ...updatedItem, [field]: value, min: "", max: "" };
        } else {
          updatedItem[field] = value;
        }

        return updatedItem;
      }
      return item;
    });

    formik.setFieldValue("fields", copiedArray);
  };

  const processMinMaxValue = (value) => {
    if (value === "0") {
      return value;
    }

    if (value.toString().includes(".")) {
      value = parseInt(value.toString().split("."));
    }

    if (isNaN(value) || value < 1) {
      value = 0;
    }

    if (value.toString().length > 1 && value.toString()[0] === "0") {
      value = parseInt(value.toString()[1]);
    }

    return value;
  };

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            {headers?.length > 0
              ? headers.map((header, index) => (
                  <TableCell
                    key={index + 1}
                    align={index > 0 ? "center" : "left"}
                  >
                    {header}
                  </TableCell>
                ))
              : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length > 0
            ? data?.map((row, index) => (
                <TableRow key={index + 1}>
                  <TableCell component="th" scope="row">
                    {row?.name}
                  </TableCell>
                  <TableCell>
                    <FormControl sx={{ minWidth: 120 }}>
                      <InputLabel
                        htmlFor="max-width"
                        style={
                          row?.name !== "docNumber" &&
                          Boolean(
                            formik.touched?.inputType ||
                              formik.errors?.fields?.[index]?.type
                          )
                            ? { color: "#f44336" }
                            : null
                        }
                      >
                        Type*
                      </InputLabel>
                      <Select
                        name="inputType"
                        label="Type*"
                        value={row?.type}
                        onChange={(e) =>
                          handleChangeFiled(index, "type", e.target.value)
                        }
                        onBlur={formik.handleBlur}
                        error={
                          row?.name !== "docNumber" &&
                          Boolean(
                            formik.touched?.inputType &&
                              formik.errors?.fields?.[index]?.type
                          )
                        }
                        disabled={row?.name === "docNumber"}
                      >
                        {templateInputTypes.length > 0
                          ? templateInputTypes.map((inputType, index) => (
                              <MenuItem value={inputType} key={index + 1}>
                                {inputType}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                      <FormHelperText
                        sx={{
                          width: "100%",
                          height: "20px",
                          display: "flex",
                          // alignItems: "center",
                          // whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          position: "absolute",
                          top: "52px",
                          color: "#f44336",
                        }}
                      >
                        {formik.touched?.inputType && (
                          <span
                            style={
                              formik.touched?.inputType
                                ? { color: "#f44336" }
                                : null
                            }
                          >
                            {formik.errors?.fields?.[index]?.type}
                          </span>
                        )}
                      </FormHelperText>
                    </FormControl>
                  </TableCell>

                  <TableCell>
                    <TextField
                      name="min"
                      type="text"
                      id="outlined-basic"
                      className={classes.input}
                      label="Min"
                      variant="outlined"
                      value={row?.min}
                      onChange={(e) =>
                        handleChangeFiled(
                          index,
                          "min",
                          parseInt(e.target.value, 10)
                        )
                      }
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched?.min &&
                          formik.errors?.fields?.[index]?.min
                      )}
                      FormHelperTextProps={{
                        sx: {
                          width: "100%",
                          height: "20px",
                          display: "flex",
                          // alignItems: "center",
                          // whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          position: "absolute",
                          top: "52px",
                          color: "#f44336",
                        },
                      }}
                      helperText={formik.errors?.fields?.[index]?.min}
                      disabled={
                        row.type === "date" || row?.name === "docNumber"
                      }
                    />
                  </TableCell>
                  <TableCell sx={{ height: "170px" }}>
                    <TextField
                      name="max"
                      type="text"
                      id="outlined-basic"
                      className={classes.input}
                      label="Max"
                      variant="outlined"
                      value={row?.max}
                      onChange={(e) =>
                        handleChangeFiled(index, "max", e.target.value)
                      }
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched?.max &&
                          formik.errors?.fields?.[index]?.max
                      )}
                      FormHelperTextProps={{
                        sx: {
                          width: "100%",
                          height: "20px",
                          display: "flex",
                          // alignItems: "center",
                          // whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          position: "absolute",
                          top: "52px",
                          transition: "opacity 0.3s ease-in-out",
                          color: "#f44336",
                        },
                      }}
                      helperText={formik.errors?.fields?.[index]?.max}
                      disabled={
                        row.type === "date" || row?.name === "docNumber"
                      }
                    />
                  </TableCell>
                  {/* <TableCell>
                    <TextField
                      name="defValue"
                      type="text"
                      id="outlined-basic"
                      label="Default Value"
                      variant="outlined"
                      value={row?.defValue}
                      onChange={(e) =>
                        handleChangeFiled(index, "defValue", e.target.value)
                      }
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched?.defValue &&
                          formik.errors?.fields?.[index]?.defValue
                      )}
                      helperText={
                        formik.touched?.defValue &&
                        formik.errors?.fields?.[index]?.defValue
                      }
                      disabled={data[index]?.isRequired ? true : false}
                    />
                  </TableCell> */}
                  {/* <TableCell align="center">
                    <Checkbox
                      name="isRequired"
                      checked={row?.isRequired}
                      onChange={() =>
                        handleChangeFiled(
                          index,
                          "isRequired",
                          !data[index]?.isRequired
                        )
                      }
                    />
                  </TableCell> */}
                  {/* <TableCell>
                    <FormControl sx={{ mt: 2, minWidth: 120 }}>
                      <InputLabel htmlFor="max-width">Source</InputLabel>
                      <Select
                        name="source"
                        label="Source"
                        value={row?.source}
                        onChange={(e) =>
                          handleChangeFiled(index, "source", e.target.value)
                        }
                      >
                        {templateInputSource.length > 0
                          ? templateInputSource.map((inputSource, index) => (
                              <MenuItem value={inputSource} key={index + 1}>
                                {inputSource}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </TableCell> */}
                  <TableCell>
                    <TextField
                      name="label"
                      type="text"
                      id="outlined-basic"
                      label="Label*"
                      variant="outlined"
                      value={row?.label}
                      onChange={(e) =>
                        handleChangeFiled(index, "label", e.target.value)
                      }
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched?.label &&
                          formik.errors?.fields?.[index]?.label
                      )}
                      FormHelperTextProps={{
                        sx: {
                          width: "100%",
                          height: "20px",
                          display: "flex",
                          alignItems: "center",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          position: "absolute",
                          top: "50px",
                        },
                      }}
                      helperText={
                        formik.touched?.label &&
                        formik.errors?.fields?.[index]?.label
                      }
                    />
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </Paper>
  );
};

const SimpleDataTable = React.memo(_SimpleDataTable);

export default SimpleDataTable;
