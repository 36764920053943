import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import DocumentDialog from "../../../../components/dialogs/documentDialog/DocumentDialog";
import { getContract } from "../../../../services/documentations/ContractServices";
import {
  getEmployees,
  updateContract,
} from "../../../../services/labourContractUpdate/labourContractUpdateService";
import CenterComponent from "./parts/CenterComponent";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CONT_CHANGE_SCHEMA } from "./validation";

const LabourContractUpdateSections = {
  labour_period: "labourPeriod",
  for_wage: "wage",
  for_protection: "protection",
  for_work_vacation_time: "workVacationTime",
  for_vacation: "vacation",
  physical_education_sport: "physicalEducationSport",
  social_protection: "socialProtection",
  regulation_property_relations: "regulationPropertyRelation",
};

export const IndividualModal = ({
  open,
  onClose,
  contIdForChange = undefined,
  empForChange = undefined,
  fetchData,
}) => {
  // old data of contract
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [staffId, setStaffId] = useState("");

  const [staffsList, setStaffList] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState("");
  const [selectedCont, setSelectedCont] = useState("");

  const {
    control,
    handleSubmit,
    watch,
    setValue: setFormValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      labour_period: {
        contractPeriodType: { value: "", changed: false },
        probationPeriod: {
          type: "",
          value: "",
          changed: false,
        },
        probationRefusalTime: {
          type: "",
          value: "",
          changed: false,
        },
        reasonOfTemporaryContract: { value: "", changed: false },
        contractStartDate: { value: "", changed: false },
        contractEndDate: { value: "", changed: false },
        contractPeriod: { value: "", changed: false },
        jobStartDate: { value: "", changed: false },
      },
      for_wage: {
        currentSalary: { value: "", changed: false },
        wage: { value: "", changed: false },
        wageBonus: { type: "", value: "", changed: false },
        bonusPeriod: { value: "", changed: false },
        givenBonus: { value: "", changed: false },
        workingCondition: { value: "", changed: false },
        extraDueCondition: { value: "", changed: false },
        overtime: { value: "", changed: false },
        wageDayOfWeek: { value: "", changed: false },
        wageDaysOfMonth: { value: "", changed: false },
        wageDayOfMonth: { value: "", changed: false },
        employeeBankName: { start: "", end: "", changed: false },
        conditionsOfSalaryPayment: { value: "", changed: false },
      },
      for_protection: {
        providedProtectiveEquipment: { value: "", changed: false },
        providedFood: { value: "", changed: false },
        instructionalPeriod: { value: "", changed: false },
      },
      for_work_vacation_time: {
        workingMode: { value: "", changed: false },
        jobStartHour: { value: "", changed: false },
        jobEndHour: { value: "", changed: false },
        lunchStartHour: { value: "", changed: false },
        lunchEndHour: { value: "", changed: false },
        partTimeWorkHour: { value: "", changed: false },
        workTurnCount: { value: "", changed: false },
        firstTurnStartHour: { value: "", changed: false },
        firstTurnEndHour: { value: "", changed: false },
        secondTurnStartHour: { value: "", changed: false },
        secondTurnEndHour: { value: "", changed: false },
        thirdTurnStartHour: { value: "", changed: false },
        thirdTurnEndHour: { value: "", changed: false },
        fourthTurnStartHour: { value: "", changed: false },
        fourthTurnEndHour: { value: "", changed: false },
        providedCar: { value: "", changed: false },
        offDays: { value: "", changed: false },
      },
      for_vacation: {
        employeeJobYear: { start: "", end: "", changed: false },
        vacationDaysCount: { value: "", changed: false },
        forWorkConditions: { value: "", changed: false },
        forSeniorityLevel: { value: "", changed: false },
        forParental: { value: "", changed: false },
        forCollectiveAgreement: { value: "", changed: false },
        totalVacationDays: { value: "", changed: false },
        socialHouseholdBenefit: { value: "", changed: false },
        aboutEventOrPayer: { value: "", changed: false },
      },
      physical_education_sport: {
        physicalEducationAndSportsConditions: { value: "", changed: false },
        otherAdditionalConditions: { value: "", changed: false },
      },
      social_protection: {
        monthlyInsuranceFee: { value: "", changed: false },
        extraSumInsured: { value: "", changed: false },
      },
      regulation_property_relations: {
        shareOwnershipOfEnterprise: { value: "", changed: false },
        dividendAmount: { value: "", changed: false },
        personalPropertiesUsedByEmployee: { value: "", changed: false },
      },
    },
    resolver: yupResolver(CONT_CHANGE_SCHEMA),
  });
  // all employees
  const [emps, setEmps] = useState([]);

  useEffect(() => {
    fetchEmps();
  }, []);

  useEffect(() => {
    if (selectedEmp) {
      const founded = emps?.find((item) => item.employee._id === selectedEmp);
      founded && setStaffList(founded.staffs);
    }
  }, [selectedEmp, selectedCont]);

  // fetch employees :
  const fetchEmps = async () => {
    try {
      setLoading(true);
      const { data } = await getEmployees();
      data && setEmps(data);

      if (empForChange && data.length > 0) {
        const foundedEmp = data.find(
          (item) => item.employee._id === empForChange
        );

        foundedEmp && setSelectedEmp(foundedEmp?.employee?._id);
        contIdForChange && setSelectedCont(contIdForChange);
        contIdForChange && (await fetchCurrCont(contIdForChange));
      }
    } catch (error) {
      console.log("err", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCurrCont = async (id) => {
    try {
      setLoading(true);
      const { data } = await getContract(id, true);
      setStaffId(data.staffs[0]._id);
      const modifiedData = modifingContForRender(data);
      setData(modifiedData);
    } catch (error) {
      console.log("err", error);
    } finally {
      setLoading(false);
    }
  };

  const modifingContForRender = (data) => {
    const keys = Object.keys(LabourContractUpdateSections);
    const modifiedData = [];
    keys.map((item) => {
      modifiedData.push({ [item]: data.data[item] });
    });
    return modifiedData;
  };

  const onChangeEmp = () => {};
  const onChangeCont = () => {};

  const onSubmit = async (data) => {
    try {
      const willSubmit = await swal({
        title: "Əminsiniz mi?",
        text: "Mövcud müqaviləyə dəyişiklik etməyə əminsiniz?",
        icon: "warning",
        dangerMode: true,
        buttons: ["Ləğv et", "Tətbiq et"],
      });

      if (willSubmit) {
        const payload = generateDataForEndPoint(data);
        const resp = await updateContract(payload);
        fetchData();
        onClose();
        if (resp) {
          swal(
            "Tamamlandı!",
            "Əmək müqavilələrinə dəyişiklik sorğusu göndərildi!",
            "success"
          );
        }
      }
    } catch (error) {
      swal("Xəta", "Xəta baş verdi", "error");
      console.log("err", error);
    }
  };

  const generateDataForEndPoint = (state) => {
    const obj = {
      staffs: [staffId],
      updates: [],
    };
    for (const key in state) {
      if (Object.hasOwnProperty.call(state, key)) {
        let _obj = {};
        for (const k in state[key]) {
          if (state[key][k].changed === true) {
            _obj = {
              ..._obj,
              [k]: mutationSpecialFields(state[key][k].value, k),
            };
          }
        }

        if (Object.values(_obj).length > 0)
          obj.updates.push({
            section: keyGenerator(key),
            data: _obj,
          });
      }
    }
    return obj;
  };

  /* 
probationPeriodType
wage
wageBonus
givenBonus
extraDueCondition
overtime
forWorkConditions
forParental
forCollectiveAgreement
socialHouseholdBenefit
monthlyInsuranceFee
extraSumInsured
shareOwnershipOfEnterprise
dividendAmount
*/

  const mutationSpecialFields = (val, key) => {
    switch (key) {
      case "probationPeriod":
      case "probationPeriodType":
      case "wage":
      case "givenBonus":
      case "extraDueCondition":
      case "reasonOfTemporaryContract":
      case "bonusPeriod":
      case "overtime":
      case "forWorkConditions":
      case "forParental":
      case "socialHouseholdBenefit":
      case "monthlyInsuranceFee":
      case "extraSumInsured":
      case "shareOwnershipOfEnterprise":
      case "dividendAmount":
        return parseInt(val);
      default:
        return val;
    }
  };

  const keyGenerator = (key) => {
    // Vacation = "vacation",

    switch (key) {
      case "labour_period":
        return "labourPeriod";
      case "for_wage":
        return "wage";
      case "for_vacation":
        return "vacation";
      case "for_protection":
        return "protection";
      case "for_work_vacation_time":
        return "workVacationTime";
      case "physical_education_sport":
        return "physicalEducationSport";
      case "social_protection":
        return "SocialProtection";
      case "regulation_property_relations":
        return "regulationPropertyRelation";
      default:
        break;
    }
  };

  // const onChange = (event, group, index) => {
  //   const key = Object.keys(group)[0];

  //   const arr = changedFields;
  //   arr[key][index].value = event.target.value;

  //   console.log(arr);
  //   setChangedFields(arr);
  // };

  return (
    <DocumentDialog
      open={open}
      loading={loading}
      onClose={onClose}
      options={{ title: "Ə.M. dəyişiklik" }}
      components={{
        body: (
          <div style={{ height: "90%" }}>
            <CenterComponent
              onChangeEmp={onChangeEmp}
              onChangeCont={onChangeCont}
              data={data}
              emps={emps}
              setSelectedEmp={setSelectedEmp}
              setSelectedCont={setSelectedCont}
              contValue={selectedCont}
              empValue={selectedEmp}
              fetchContract={fetchCurrCont}
              // changedState={[changedFields, setChangedFields]}
              staffsList={staffsList}
              control={control}
              handleSubmit={handleSubmit}
              watch={watch}
              setFormValue={setFormValue}
              errors={errors}
            />
          </div>
        ),
        footer: (
          <Grid sx={{ display: "flex", flexDirection: "row", height: "50px" }}>
            <Button
              color="error"
              variant="contained"
              title="Ləğv et"
              sx={{ width: "50%" }}
              onClick={onClose}
            >
              <Typography>Ləğv et</Typography>
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              title="Tətbiq et"
              sx={{ width: "50%" }}
              disabled={!selectedCont}
            >
              <Typography>Tətbiq et</Typography>
            </Button>
          </Grid>
        ),
      }}
    />
  );
};
