import React from "react";
import { Field, FieldArray, Formik, Form } from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { banks } from "../../constants/constants";

const ViewBankInfo = ({ employeeById }) => {
  return (
    <>
      {employeeById ? (
        <Formik
          initialValues={{
            bankInfos: employeeById.bankInfos || [],
          }}
        >
          {({
            values,
            isValid,
            errors,
            touched,
            isSubmitting,
            handleChange,
          }) => (
            <Form style={{ pointerEvents: "none" }}>
              <FieldArray name="bankInfos">
                {({ push, remove }) => (
                  <div>
                    {values.bankInfos.map((form, index) => (
                      <div key={index}>
                        <>
                          <Typography mt={3} mb={3} variant="h6">
                            {index + 1 + ")"}Bank məlumatları
                          </Typography>

                          <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                            <Grid sx={{ width: "100%" }}>
                              <FormControl
                                // error={
                                //   errors.bankName && errors.bankInfos[index].bankName
                                // }
                                fullWidth
                              >
                                <InputLabel id={`bankInfos[${index}].bankName`}>
                                  Banklar
                                </InputLabel>
                                <Field
                                  as={Select}
                                  name={`bankInfos[${index}].bankName`}
                                  labelId={`bankInfos[${index}].bankName`}
                                  label="Banklar"
                                  id="bankName-select"
                                  fullWidth
                                  //   error={
                                  //     errors.bankInfos[index].bankName &&
                                  //     Boolean(errors.bankInfos[index].bankName)
                                  //   }
                                >
                                  {banks.map((item, index) => (
                                    <MenuItem
                                      key={index}
                                      value={
                                        employeeById?.bankInfos?.[index]
                                          ?.bankName
                                      }
                                    >
                                      {
                                        employeeById?.bankInfos?.[index]
                                          ?.bankName
                                      }
                                    </MenuItem>
                                  ))}
                                </Field>

                                {/* {errors.bankInfos[index].bankName &&
                          errors.bankInfos[index].bankName && (
                            <FormHelperText error>
                              {errors.bankInfos[index].bankName}
                            </FormHelperText>
                          )} */}
                              </FormControl>
                            </Grid>
                            <Grid sx={{ width: "100%" }}>
                              <Field
                                type="number"
                                name={`bankInfos[${index}].bankCode`}
                                as={TextField}
                                label="Bank kod"
                                value={
                                  employeeById?.bankInfos?.[index]?.bankCode
                                }
                                fullWidth
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                          </Grid>

                          <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                            <Grid sx={{ width: "100%" }}>
                              <Field
                                type="text"
                                name={`bankInfos[${index}].bankVoen`}
                                as={TextField}
                                label="Bank VÖEN"
                                value={
                                  employeeById?.bankInfos?.[index]?.bankVoen
                                }
                                fullWidth
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid sx={{ width: "100%" }}>
                              <Field
                                type="text"
                                name={`bankInfos[${index}].accountNumber`}
                                as={TextField}
                                value={
                                  employeeById?.bankInfos?.[index]
                                    ?.accountNumber
                                }
                                label="Hesab nömrəsi"
                                fullWidth
                              />
                            </Grid>
                          </Grid>

                          <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                            <Grid sx={{ width: "100%" }}>
                              <Field
                                type="text"
                                name={`bankInfos[${index}].iban`}
                                as={TextField}
                                label="IBAN"
                                value={employeeById?.bankInfos?.[index]?.iban}
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        </>
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default ViewBankInfo;
