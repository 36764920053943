import { api } from "../../api/axiosInterceptor";
import { BASE_URL } from "../../constants";

// GET: /employee/s?limit=10&offset=0&name=SOCA -> Get Company Structure
export const getCompanyStructures = async (payload) => {
  try {
    const response = await api.get(`/employee/s`, {
      params: {
        limit: payload.limit,
        offset: payload.skip,
        name: payload.name || "",
      },
    });
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

// GET: /employee/s/:id -> Get Structure by Id
export const getStructureById = async (id) => {
  try {
    const response = await api.get(`/employee/s/${id}`);
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

// POST: /employee/s -> Create Company Structure
export const createCompanyStructure = async (payload) => {
  try {
    const response = await api.post("/employee/s", payload);
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

// POST: employee/s/logo/:id -> Save and Update Logo
export const saveUpdateLogo = async (id, file, deleteLogo = false) => {
  try {
    const queryParams = new URLSearchParams({ deleteLogo });
    const response = await fetch(
      `${BASE_URL}/employee/s/logo/${id}?${queryParams}`,
      {
        method: "POST",
        body: file,
      }
    );
    return await response.json();
  } catch (error) {
    console.log("error: ", error);
  }
};

// POST: /employee/s/edit/request -> Create Edit Request
export const createEditRequest = async (payload) => {
  try {
    const response = await api.post(
      `employee/employee-request/user/structureRequest`,
      payload
    );
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
    throw error;
  }
};

// PUT: /employee/s/:id -> Update Company Structure
export const updateCompanyStructure = async (payload, id) => {
  try {
    const response = await api.put(`/employee/s/${id}`, payload);
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

// PUT: /employee/s/child/:id -> Add Child
export const addChild = async (id, payload) => {
  try {
    const response = await api.put(`/employee/s/child/${id}`, payload);
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

// PATCH: /employee/s/rel/:relId -> Add Source to Rel
export const addSourceToRel = async (relId, payload) => {
  try {
    const response = await api.patch(`/employee/s/rel/${relId}`, payload);
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

// DELETE: /employee/s/:id -> Delete Type
export const deleteCompanyStructure = async (id) => {
  try {
    const response = await api.delete(`/employee/s/${id}`);
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const deleteChild = async (childId, structure_id) => {
  try {
    const response = await api.delete(`/employee/s/child/${structure_id}`, {
      data: { childId },
    });
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const deleteChildRel = async (rel_id) => {
  try {
    const response = await api.delete(`/employee/s/child/mark/${rel_id}`);
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const getStructureNamesForStructureRequests = () => {
  return api.get("employee/s/editRequest/getNames");
};

export const getAllStructures = async () => {
  return await api.get("/employee/s");
};

export const getTopPartsStructure = async (id) => {
  const url = id
    ? `/employee/s/top-part-rels?structureId=${id}`
    : `/employee/s/top-part-rels`;
  return await api.get(url);
};

export const getPartsStructure = async (id) => {
  const url = id
    ? `/employee/s/part-rels?topPartRelId=${id}`
    : `/employee/s/part-rels`;
  return await api.get(url);
};

export const getPositionsStructure = async (id) => {
  const url = id
    ? `/employee/s/position-rels?partRelId=${id}`
    : `/employee/s/position-rels`;
  return await api.get(url);
};

export const makeChangesDone = async (id) => {
  try {
    const response = await api.patch(`employee/s/editRequest/done/${id}`);
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const setRecruiter = async (id) => {
  return await api.patch(`/employee/s/${id}/recruiter`);
};

export const getNestedStructuresChildren = async (structureId, parentId) => {
  return await api.get(
    `employee/s/child/nested?structureId=${structureId}&parentId=${parentId}`
  );
};

export const updateStructureChild = async ({ structureId, payload }) => {
  /*  payload
        {
          "relId": "667ec48a386df811d9a23b45",
          // ? optionals:
          "partId": "666c524cb5d744428093bd45",
          "max_salary": "1200"
        }
  */

  return await api.patch(`employee/s/child/${structureId}`, payload);
};

export const verifyStructureAndRecruiter = async (staffId, structureId) => {
  return api.patch(`/employee/s/verify`, { structureId, staffId });
};
