import { api } from "../../api/axiosInterceptor";

export const getStructureChangeRequests = (payload) => {
  return api.get("employee/s/editRequest/all/", {
    params: {
      limit: payload.limit,
      skip: payload.offset,
      ...(payload.filters?.length && { filter: payload.filters }),
      csv: payload.CSV,
    },
  });
};

export const getDownloadStructureRequest = async ({
  columns,
  filters = undefined,
  isWithFilter,
}) => {
  const url = isWithFilter
    ? `employee/s/editRequest/all/csv?filter=${filters}&columns=${columns}`
    : `employee/s/editRequest/all/csv?columns=${columns}`;

  return await api.get(url);
};

export const getDownloadCommands = async ({
  columns,
  filter = undefined,
  isWithFilter,
}) => {
  const url = isWithFilter
    ? `employee/contract/filter/download?filter=${filter}&columns=${columns}`
    : `employee/contract/filter/download?columns=${columns}`;

  return await api.get(url);
};
