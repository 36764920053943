/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import { useSelector } from "react-redux";
// Material UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
// Components
// Services
import * as employeeServices from "../../../services/employees/EmployeeServices";
// Helpers
import moment from "moment";
import { toast } from "react-toastify";

const OrderDialog = ({
  classes,
  open,
  onClose,
  staffId,
  onClosePosition,
  isUpdated,
  setIsUpdated,
  position,
  data,
}) => {
  const { structureById } = useSelector((state) => state.structure);
  const [selectedEmp, setSelectedEmp] = useState(null);
  const [confirmButtonActive, setConfirmButtonActive] = useState(false);
  const [downloadId, setDownloadId] = useState("");
  const [clause, set_clause] = useState("Maddə 1");

  const onSaveEmployeeId = (selectedEmp) => {
    setSelectedEmp(selectedEmp);
  };

  const addOrder = async () => {
    try {
      const response = await employeeServices.addOrder(staffId, clause);
      // const response = true;
      if (response) {
        toast.success("Əmr uğurla əlavə edildi!");
        onClose();
        // onClosePosition();
        setIsUpdated(!isUpdated);
      } else {
        toast.error(
          "Hər hansı bir xəta baş verdi. Zəhmət olmasa, inspektdən network hissəni açın!"
        );
      }
    } catch (error) {
      console.log("error: ", error);
      toast.error("Server xətası baş verdi!");
    }
  };

  const generateContract = async () => {
    try {
      const payload = {
        docModel: "Employee",
        subjects: [selectedEmp._id],
        data: {
          structure: structureById._id,
          position: position,
          jobStartDate: moment().format("MM/DD/YYYY"),
          agreementDate: moment().format("MM/DD/YYYY"),
        },
      };

      const response = await employeeServices.generateContract(payload);
      if (response) {
        setConfirmButtonActive(true);
        setDownloadId(response._id);
        toast.success("Ərizə uğurla tərtib edildi");
      } else {
        setConfirmButtonActive(false);
        toast.error("Hər hansı bir xəta baş verdi!");
      }
    } catch (error) {
      console.log("error: ", error);
      toast.error("Server xətası baş verdi!");
    }
  };
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className="vacation_dialog"
    >
      <DialogTitle id="form-dialog-title" align="center">
        Əmr yarat
      </DialogTitle>
      <DialogContent>
        <div>
          {data &&
            Object.keys(data)?.map((k) => (
              <>
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ fontSize: "16px" }}
                >
                  <div>{data[k]?.label}</div>
                  <div className={k === "orderClause" && "w-50"}>
                    {k === "orderClause" ? (
                    "Maddə 81. Əmək müqavilələri bağlanmasının sənədləşdirilməsi"
                    ) : (
                      data[k]?.value
                    )}
                  </div>
                </div>
                <hr />
              </>
            ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button type="button" onClick={onClose} color="primary">
          Ləğv et
        </Button>
        <Button type="submit" color="primary" onClick={addOrder}>
          Əmr Yarat
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderDialog;
