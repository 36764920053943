import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { getEmployees } from "../../../../services/employees/EmployeeServices";
import { removeEmptyStrings } from "../../../../utils/removeEmptyString";

const AddMember = (props) => {
  const {
    findEmployeeDialog,
    setFindEmployeeDialog,
    selectedUser,
    setSelectedUser,
    assignMembersToCard,
    taskDetails,
  } = props;

  const [employees, setEmployees] = useState([]);
  const [totalEmployee, setTotalEmployee] = useState();
  const [employeesPayload, setEmployeesPayload] = useState({
    limit: 5,
    skip: 0,
    firstname: "",
    lastname: "",
    fathername: "",
    // filter:
    //   "%5B%7B%22column%22%3A%22status%22%2C%22operator%22%3A%22equalsTo%22%2C%22value%22%3A%22active%22%7D%5D",
  });
  const [page, setPage] = useState(1);

  // Get all employees
  const getAllEmployees = async () => {
    try {
      const modifiedValue = removeEmptyStrings(employeesPayload);

      const generatedFilters = encodeURIComponent(
        JSON.stringify([
          { column: "status", operator: "equalsTo", value: "active" },
          {
            column: "firstname",
            operator: "contains",
            value: employeesPayload.firstname || "",
          },
          {
            column: "lastname",
            operator: "contains",
            value: employeesPayload.lastname || "",
          },
          {
            column: "fathername",
            operator: "contains",
            value: employeesPayload.fathername || "",
          },
        ])
      );

      const payload = {
        limit: modifiedValue?.limit,
        skip: modifiedValue?.skip,
        filter: generatedFilters,
      };

      const response = await getEmployees(payload);
      if (response) {
        setEmployees(response.employees);
        setTotalEmployee(response.total);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (taskDetails?.cardMembers) {
      const ids = taskDetails.cardMembers.map(
        (employee) => employee.employee._id
      );
      setSelectedUser(ids);
    }
  }, [findEmployeeDialog]);

  useEffect(() => {
    getAllEmployees();
  }, [employeesPayload]);

  const handleToggle = (value) => () => {
    const currentIndex = selectedUser.indexOf(value);
    const newChecked = [...selectedUser];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedUser(newChecked);
  };
  const convertToEnglish = (text) => {
    // Define character mappings
    const charMap = {
      İ: "I",
      // Add more character mappings as needed
    };

    // Replace characters using regular expression
    return text.replace(/[İ]/g, (char) => charMap[char]);
  };
  return (
    <>
      <Dialog
        fullWidth
        open={findEmployeeDialog}
        onClose={() => {
          setFindEmployeeDialog(false);
          setSelectedUser([]);
          setEmployeesPayload({
            limit: 5,
            skip: 0,
            name: "",
            // filter: "",
          });
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" mb={-3}>
          <Typography variant="h4" mb={4}>
            Əməkdaşı seçin
          </Typography>
        </DialogTitle>
        <>
          <DialogContent>
            {/* User Search */}
            <Typography variant="h6" fontSize={12} textAlign={"center"}>
              Axtarış üçün daxil edin:
            </Typography>
            <Box
              my={3}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <TextField
                sx={{ width: "33%" }}
                label="Ad"
                onChange={(e) => {
                  setEmployeesPayload((prevState) => ({
                    ...prevState,
                    firstname: convertToEnglish(e.target.value),
                  }));
                }}
                variant="outlined"
                fullWidth
              />
              <TextField
                sx={{ width: "33%" }}
                label="Soyad"
                onChange={(e) => {
                  setEmployeesPayload((prevState) => ({
                    ...prevState,
                    lastname: convertToEnglish(e.target.value),
                  }));
                }}
                variant="outlined"
                fullWidth
              />
              <TextField
                sx={{ width: "33%" }}
                label="Ata adı"
                onChange={(e) => {
                  setEmployeesPayload((prevState) => ({
                    ...prevState,
                    fathername: convertToEnglish(e.target.value),
                  }));
                }}
                variant="outlined"
                fullWidth
              />
            </Box>

            {/* User list */}
            <List
              dense
              fullWidth
              sx={{
                maxHeight: "40vh",
                bgcolor: "background.paper",
                overflow: "auto",
                mb: 3,
              }}
            >
              {employees.map((item, index) => {
                const labelId = `checkbox-list-secondary-label-${item.username}`;
                // if (item._id !== user.id) {
                return (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        onChange={handleToggle(item._id)}
                        checked={selectedUser.indexOf(item._id) !== -1}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemAvatar>
                        <Avatar
                          alt={`Avatar n°${index + 1}`}
                          src={`/static/img/avatars/user.png`}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        id={item._id}
                        primary={
                          item.firstname +
                          " " +
                          item.lastname +
                          " " +
                          item.fathername
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                );
                // }
              })}
            </List>
            <Pagination
              mb={2}
              sx={{ display: "flex", justifyContent: "center" }}
              count={Math.ceil(totalEmployee / employeesPayload.limit)}
              variant="outlined"
              shape="rounded"
              page={page}
              color="secondary"
              onChange={(event, value) => {
                setPage(value);
                if (value !== page) {
                  setEmployeesPayload((prevState) => ({
                    ...prevState,
                    skip: (value - 1) * 5,
                  }));
                }
              }}
            />
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                setFindEmployeeDialog(false);
                setSelectedUser([]);
              }}
              color="primary"
            >
              Ləğv et
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setFindEmployeeDialog(false);
                assignMembersToCard(taskDetails._id, selectedUser);
                setSelectedUser([]);
              }}
              disabled={selectedUser.length === 0}
            >
              Seç
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </>
  );
};

export default AddMember;
