// React
import { useState } from "react";

// Material UI
import { Button, Grid } from "@mui/material";
import { RemoveRedEye } from "@mui/icons-material";

import DataTable from "../../../components/layouts/dataTable/DataTable";
import { OPERATORS_CONTAINING } from "../../../constants";
import Table from "../../../components/table/Table";
import Column from "../../../models/table/Column";

export default function MCRequestsTable() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [totalRow, setTotalRow] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    handleClosePopover();
  };

  const columns = [
    new Column({
      path: "reqno",
      headerName: "Sorğu nömrəsi",
    }),
    new Column({
      path: "reason",
      headerName: "İzah",
    }),
    new Column({
      path: "status",
      headerName: "status",
    }),
    new Column({
      path: "createdate",
      headerName: "Yaranma tarixi",
    }),
    new Column({
      path: "updatedate",
      headerName: "Yenilənmə tarixi",
    }),
    new Column({
      path: "categories",
      headerName: "Kateqoriyalar",
    }),
    new Column({
      path: "#",
      headerName: "Əməliyyatlar",
    }),
  ];

  const rows = [
    {
      id: 1,
      reqno: "DS000001",
      reason: "Soyad",
      status: "Close",
      createdate: "09/10/2023",
      updatedate: "10/10/2023",
      categories: "Şəxsi məlumatlar",
    },
    {
      id: 2,
      reqno: "DS000002",
      reason: "Bank hesabı",
      status: "Close",
      createdate: "09/10/2023",
      updatedate: "10/10/2023",
      categories: "Bank məlumatlartı",
    },
  ];

  return (
    <>
      <Grid sx={{ width: "100%" }}>
        <Table
          columns={columns}
          data={rows}
          totalRows={2}
          offset={offset}
          setOffset={(value) => setOffset(value)}
          limit={limit}
          setLimit={(value) => setLimit(value)}
          isLoading={isLoading}
        />
      </Grid>
    </>
  );
}
