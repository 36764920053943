import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { addCommonData, getCommonData } from "../../api/api";

const NewCommonDataDialog = ({
  openAddDialog,
  setOpenAddDialog,
  selectedType,
  submitted,
  setSubmitted,
  setCarItems,
  setInventoryItems,
}) => {
  const [commonName, setCommonName] = useState();
  const [commonNameError, setCommonNameError] = useState(false);
  const [socialValue, setSocialValue] = useState();
  const [socialValueError, setSocialValueError] = useState(false);
  const [wageType, setWageType] = useState("number");
  const [reason, setReason] = useState();
  const [itemTypes, setItemTypes] = useState([]);
  const [itemType, setItemType] = useState();

  const handleAddCommonData = async (key, name, value) => {
    let payload = [];
    if (
      key === "socialStatusTypes" ||
      key === "overtimeRules" ||
      key === "academicDegrees"
    ) {
      payload = [{ key: key, values: [{ name: name, value: Number(value) }] }];
    } else if (key === "supplementalWageLabour") {
      payload = [
        {
          key: key,
          values: [
            {
              name: name,
              value: Number(value),
              reason: reason,
              valueType: wageType,
            },
          ],
        },
      ];
    } else if (key === "inventoryItems" || key === "carModels") {
      payload = [{ key: key, values: [{ name: name, parent: itemType }] }];
    } else {
      payload = [{ key: key, values: [name] }];
    }

    await addCommonData(payload);
    setItemType("");
    setItemType("");
    setCommonName();
    setSocialValue();
    setReason();
    setOpenAddDialog(false);
    setSubmitted(!submitted);
    if (key === "carModels") {
      setTimeout(() => {
        setCarItems();
      }, 500);
    }
    if (key === "inventoryItems") {
      setTimeout(() => {
        setInventoryItems();
      }, 500);
    }
  };
  const validateInput = (value, type) => {
    switch (type) {
      case "commonName":
        const nameRegex =
          /^\s*[a-zA-ZüçğıöəşÜÇĞIİÖƏŞ0-9-]+(?:\s+[a-zA-ZüçğıöəşÜÇĞIİÖƏŞ0-9-]+)*\s*$/;

        return nameRegex.test(value);

      case "socialValue":
        return value === "" || (!isNaN(value) && parseFloat(value) >= 0);

      default:
        return true;
    }
  };
  const handleInputChange = (value, setter, setError, type) => {
    const isValid = validateInput(value, type);

    if (isValid) {
      setter(value);
      setError(false);
    } else {
      setError(true);
    }
  };
  const getInventoryTypes = async () => {
    if (selectedType[0] === "inventoryItems") {
      try {
        const response = await getCommonData("inventoryTypes");
        setItemTypes(response.data);
      } catch (error) {
        console.log(error);
      }
    } else if (selectedType[0] === "carModels") {
      try {
        const response = await getCommonData("carManufacturers");
        setItemTypes(response.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getInventoryTypes();
  }, [selectedType]);
  return (
    <>
      <Dialog
        fullWidth
        open={openAddDialog}
        onClose={() => {
          setOpenAddDialog(false);
          setItemType("");
          setItemType("");
          setCommonName();
          setSocialValue();
          setReason();
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4"> {selectedType[1].name} əlavə et</Typography>
        </DialogTitle>
        <DialogContent>
          {selectedType[0] === "positions" ||
          selectedType[0] === "structures" ? (
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Adı"
              type="text"
              onChange={(e) =>
                handleInputChange(
                  e.target.value.trim(),
                  setCommonName,
                  setCommonNameError,
                  "commonNames"
                )
              }
              fullWidth
              sx={{ mb: 3 }}
            />
          ) : (
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Adı"
              type="text"
              onChange={(e) =>
                handleInputChange(
                  e.target.value.trim(),
                  setCommonName,
                  setCommonNameError,
                  "commonName"
                )
              }
              fullWidth
              error={commonNameError}
              helperText={
                commonNameError ? "Yalnız hərflər və rəqəmlər qəbul edilir" : ""
              }
              sx={{ mb: 3 }}
            />
          )}

          {(selectedType[0] === "inventoryItems" ||
            selectedType[0] === "carModels") && (
            <FormControl fullWidth>
              <InputLabel id={`type`}>Tipi *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={itemType}
                label="Age"
                onChange={(e) => {
                  setItemType(e.target.value);
                }}
              >
                {itemTypes.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {selectedType[0] === "socialStatusTypes" ||
          selectedType[0] === "overtimeRules" ||
          selectedType[0] === "academicDegrees" ||
          selectedType[0] === "supplementalWageLabour" ? (
            <Box sx={{ display: "flex", gap: 5 }}>
              <TextField
                margin="dense"
                id="value"
                label="Dəyəri"
                type="number"
                value={socialValue}
                onChange={(e) =>
                  handleInputChange(
                    e.target.value,
                    setSocialValue,
                    setSocialValueError,
                    "socialValue"
                  )
                }
                fullWidth
                error={socialValueError}
                helperText={
                  socialValueError ? "Yalnız müsbət rəqəmlər qəbul edilir" : ""
                }
              />

              {selectedType[0] === "supplementalWageLabour" && (
                <FormControl component="fieldset" variant="standard">
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    value={wageType}
                    onChange={(e) => {
                      setWageType(e.target.value);
                    }}
                    row
                  >
                    <FormControlLabel
                      value="number"
                      control={<Radio color="primary" />}
                      label="Dəyər"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="percentage"
                      control={<Radio color="primary" />}
                      label="Faiz"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </Box>
          ) : null}

          {selectedType[0] === "supplementalWageLabour" ? (
            <TextField
              margin="dense"
              id="reason"
              label="Səbəbi"
              type="text"
              onChange={(e) => setReason(e.target.value)}
              fullWidth
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddDialog(false)} color="primary">
            Ləğv et
          </Button>
          <Button
            onClick={() => {
              handleAddCommonData(selectedType[0], commonName, socialValue);
            }}
            color="primary"
            disabled={commonNameError || socialValueError}
          >
            Əlavə et
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewCommonDataDialog;
