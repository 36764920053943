import { Avatar, Grid, Typography } from "@mui/material";
import React from "react";

const UserHeader = ({ employee }) => {
  return (
    <Grid src={employee?.picture} sx={{ display: "flex", gap: 3 }}>
      <Grid>
        <Avatar src={employee?.picture} />
      </Grid>
      <Grid>
        <Typography>
          {employee.firstname} {employee.lastname}
        </Typography>
        <Typography>
          {employee?.position?.name
            ? employee.position.name
            : "Vəzifə qeyd olunmayıb"}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default UserHeader;
