import React, { useEffect, useState } from "react";
import HeaderDialog from "../../../components/dialogs/HeaderDialog";
import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getEmployees } from "../../../services/employees/EmployeeServices";
import {
  addEmployeeNotificationList,
  getNotificationSettings,
  putEmployeeNotificationList,
} from "../../../services/documentations/EmploymentContractsService";
import { toast, ToastContainer } from "react-toastify";
import * as moment from "moment";

const listDateType = [
  { key: "Gün", value: "day" },
  { key: "Həftə", value: "week" },
  { key: "Ay", value: "month" },
  { key: "İl", value: "year" },
];

export default function AddEmploymentDialog({
  onClose,
  mode,
  refresh,
  currList,
  editData,
}) {
  const [employeeList, setEmployeeList] = useState([]);

  const [validationDayCount, setValidationDayCount] = useState(0);
  const [borderLimit, setBorderLimit] = useState({
    type: "",
    value: "",
  });
  const [initialValues, setInitialValues] = useState();
  const [texts, setTexts] = useState({
    header: "",
    button: "",
  });

  useEffect(() => {
    textsHandler();
    getEmployeesList();
    getNotificationDate();
  }, []);

  useEffect(() => {
    if (employeeList && employeeList.length > 0) {
      editData
        ? setInitialValues({
            employee: editData.employee.id,
            dateType: editData.timeUnit,
            dateValue: editData.timeValue,
          })
        : setInitialValues({
            employee: "",
            dateType: "",
            dateValue: "",
          });
    }
  }, [employeeList, editData]);

  const textsHandler = () => {
    !editData
      ? setTexts({
          header: "Yeni əməkdaş əlavə et",
          button: "Daxil et",
        })
      : setTexts({
          header: "Mövcud əməkdaşa düzəliş et",
          button: "Düzəliş et",
        });
  };

  const getNotificationDate = async () => {
    try {
      const { data } = await getNotificationSettings();

      data && getDayCount(data.unit, data.value);
      data &&
        setBorderLimit({
          type: data.unit,
          value: data.value,
        });
    } catch (error) {}
  };

  const getEmployeesList = async () => {
    const payload = { limit: 9999, skip: 0, name: "" };

    try {
      const data = await getEmployees(payload);

      const arr = data.employees.filter(
        ({ id: id1 }) => !currList.some(({ id: id2 }) => id2 === id1)
      );

      editData && arr.push(editData.employee);

      setEmployeeList([...arr]);
    } catch (error) {
      console.log("err", error);
    }
  };

  const getDayCount = (type, period, date = new Date()) => {
    let totalDays;
    let endDate;

    switch (type) {
      case "day":
        totalDays = period;
        break;
      case "week":
        totalDays = period * 7;
        break;
      case "month":
        endDate = moment(date).add(period, type);
        totalDays = endDate.diff(moment(date), "days");
        break;
      case "year":
        endDate = moment(date).add(period, type);
        totalDays = endDate.diff(moment(date), "days");
        break;
      default:
        break;
    }

    setValidationDayCount(totalDays);
  };

  // input handlers
  const onChangeHandler = (e, key, setFieldValue) => {
    const value = e.target.value;

    switch (key) {
      case "employee":
        setFieldValue("employee", value);
        break;
      case "dateType":
        setFieldValue("dateType", value);
        break;
      case "dateValue":
        dateValueHandler(value, setFieldValue);
        return;

      default:
        break;
    }
  };

  const dateValueHandler = (value, setFieldValue) => {
    value = parseInt(value, 10);
    if (value.toString().includes("."))
      value = parseInt(value.toString().split("."));
    if (isNaN(value) || value < 1) value = 0;

    setFieldValue("dateValue", value);
  };

  const submitHandler = async (values, actions) => {
    const { employee, dateType, dateValue } = values;

    const payload = !editData
      ? {
          employeeId: employee,
          timeUnit: dateType,
          timeValue: dateValue,
          notificationType: mode === "mail" ? "mail" : "system",
        }
      : {
          employeeId: editData.employee,
          timeUnit: dateType,
          timeValue: dateValue,
          notificationType: mode === "mail" ? "mail" : "system",
          newEmployeeId: employee,
        };

    try {
      const { data } = !editData
        ? await addEmployeeNotificationList(payload)
        : await putEmployeeNotificationList(payload);
      toast.info("Əlavə edildi", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      refresh();
      onClose();
      actions.resetForm();
      actions.setSubmitting(false);
    } catch (error) {
      console.log("err", error);
    }
  };

  const normalizeDate = () => {
    switch (borderLimit.type) {
      case "day":
        return "gün";
      case "week":
        return "həftə";
      case "month":
        return "ay";
      case "year":
        return "il";
      default:
        break;
    }
  };

  // const initialValues = editData
  //   ? {
  //       employee: editData.employee,
  //       dateType: editData.timeUnit,
  //       dateValue: editData.timeValue,
  //     }
  //   : {
  //       employee: "",
  //       dateType: "",
  //       dateValue: "",
  //     };

  const validationSchema = Yup.object().shape({
    employee: Yup.mixed().required("Əməkdaş təyin edin"),
    dateType: Yup.mixed().required("Müddət tipini təyin edin"),
    dateValue: Yup.number()
      .min(0, "Value must be")
      .required("Müddət təyin edin")
      .positive("Müddət müsbət dəyər olmalıdır")
      .integer("Müddət rəqəm olmalıdır")
      .when("dateType", (dateType, schema) => {
        switch (dateType) {
          case "day":
            return schema.max(
              validationDayCount,
              `Maksimum gün dəyəri ${validationDayCount} olmalıdır`
            );
          case "week":
            return schema.max(
              parseInt(validationDayCount / 7, 10),
              `Maksimum həftə dəyəri ${parseInt(
                validationDayCount / 7,
                10
              )}  olmalıdır`
            );
          case "month":
            return schema.max(
              parseInt(validationDayCount / 30.44, 10),
              `Maksimum ay dəyəri ${parseInt(
                validationDayCount / 30.44,
                10
              )} olmalıdır`
            );
          case "year":
            return schema.max(
              parseInt(validationDayCount / 365, 10),
              `Maksimum il dəyəri ${parseInt(
                validationDayCount / 365,
                10
              )} olmalıdır`
            );
          default:
            return schema;
        }
      }),
  });

  return (
    <Grid sx={styles.container}>
      <ToastContainer />
      <HeaderDialog onClose={onClose} title={texts.header} />

      <Grid width={"90%"} sx={{ paddingY: 2.5, margin: "auto" }}>
        {initialValues && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => submitHandler(values, actions)}
          >
            {({ values, setFieldValue, field }) => (
              <Form>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid width={"30%"}>
                    <InputLabel>Əməkdaşlar</InputLabel>
                    <Select
                      id="employee"
                      name="employee"
                      fullWidth
                      value={values.employee}
                      defaultValue={initialValues.employee}
                      onChange={(e) =>
                        onChangeHandler(e, "employee", setFieldValue)
                      }
                    >
                      <MenuItem value={{}} disabled>
                        Əməkdaş seçin
                      </MenuItem>
                      {employeeList.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.firstname +
                            " " +
                            item.lastname +
                            " " +
                            item.fathername}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage
                      name="employee"
                      component="div"
                      sx={{ color: "red" }} // Use sx instead of s
                    />
                  </Grid>

                  <Grid width={"30%"}>
                    <InputLabel>Müddət tipi</InputLabel>
                    <Select
                      id="dateType"
                      name="dateType"
                      fullWidth
                      value={values.dateType}
                      onChange={(e) =>
                        onChangeHandler(e, "dateType", setFieldValue)
                      }
                      disabled={values.employee === -1 || !values.employee}
                    >
                      <MenuItem value={-1} hidden>
                        Müddət tipini seçin
                      </MenuItem>
                      {listDateType.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                          {item.key}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage
                      name="dateType"
                      component="div"
                      sx={{ color: "red" }} // Use sx instead of s
                    />
                  </Grid>
                  <Grid width={"30%"}>
                    <InputLabel>Müddət</InputLabel>
                    <TextField
                      name="dateValue"
                      fullWidth
                      variant="outlined"
                      value={values.dateValue}
                      onChange={(e) =>
                        onChangeHandler(e, "dateValue", setFieldValue)
                      }
                      disabled={values.dateType === -1 || !values.dateType}
                    />
                    <ErrorMessage
                      name="dateValue"
                      component="div"
                      sx={{ color: "red" }} // Use sx instead of s
                    />
                  </Grid>
                </Grid>
                <Grid
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 5,
                  }}
                >
                  <Button type="submit" variant="contained">
                    <Typography>{texts.button}</Typography>
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
        <Grid sx={{ marginTop: 5 }}>
          <Typography sx={{ width: "100%", textAlign: "center", marginTop: 5 }}>
            Təyin olunan vaxt limiti:
            <span style={{ fontSize: "16px" }}>
              {borderLimit.value} {normalizeDate()}
            </span>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

const styles = {
  container: {
    padding: "5px",
    height: 400,
    width: 600,
  },
};
