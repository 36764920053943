import { toast } from "react-toastify";
import { api } from "../../api/axiosInterceptor";

export const getBulletins = (payload) => {
  return api.get("employee/temporary-loss-of-work-capacity", {
    params: {
      offset: payload.offset,
      limit: payload.limit,
      ...(payload.filters?.length && { filter: payload.filters }),
    },
  });
};

export const createNewBulletinData = async (data) => {
  try {
    const response = await api.post(
      `/employee/temporary-loss-of-work-capacity`,
      data
    );
    toast.success("Yeni bulleten uğurla əlavə edildi!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err?.response?.data?.errors.map((item) => {
      return toast.error("Xəta baş verdi", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getMedicalOrganizationsList = async () => {
  try {
    const response = await api.get(
      `employee/common?selectedField=medicalOrganizationList`
    );
    return response;
  } catch (err) {
    console.log(err);
    // err?.response?.data?.errors?.map((item) => {
    //   return toast.error("Xəta baş verdi", {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",
    //   });
    // });
  }
};

export const addNewMedicalOrganization = async (payload) => {
  try {
    const response = await api.post(`employee/common`, {
      data: payload,
    });
    toast.success("Uğurla əlavə edildi!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err?.response?.data?.errors.map((item) => {
      return toast.error("Xəta baş verdi", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const deleteTLOWCData = async (id) => {
  try {
    const response = await api.delete(
      `employee/temporary-loss-of-work-capacity/${id}`
    );
    toast.success("Uğurla silindi!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getTLOWCData = (id) => {
  return api.get(`employee/temporary-loss-of-work-capacity/${id}`);
};

export const updateTLOWCData = async (data) => {
  try {
    const response = await api.put(
      `employee/temporary-loss-of-work-capacity/${data.id}`,
      {
        ...data,
      }
    );
    toast.success("Uğurla yeniləndi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
}

export const downloadDatagridDatas = async ({
  columns,
  filters = undefined,
}) => {
  const url = `employee/temporary-loss-of-work-capacity/download?filter=${filters}&columns=${columns}&lang=az`;

  return await api.get(url);
};
