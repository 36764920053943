import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";

const EditComment = ({
  openEditComment,
  setOpenEditComment,
  selectedComment,
  setSelectedComment,
  handleEditComment,
}) => {
  const [comment, setComment] = useState("");

  useEffect(() => {
    setComment(selectedComment.content);
  }, [openEditComment]);

  return (
    <>
      <Dialog
        fullWidth
        open={openEditComment}
        onClose={() => {
          setOpenEditComment(false);
          setComment("");
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4">Şərhi dəyişdir</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            multiline
            rows={2}
            id="name"
            label="Şərh"
            type="text"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenEditComment(false);
              setComment("");
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          <Button
            onClick={() => {
              setOpenEditComment(false);
              handleEditComment(selectedComment._id, {
                content: comment,
              });
              setComment("");
            }}
            color="primary"
            disabled={comment.trim() !== "" ? false : true}
          >
            Əlavə et
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditComment;
