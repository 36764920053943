/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
// Material UI
import styled from "@emotion/styled";
import { DeleteSharp } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  CardContent,
  Card as MuiCard,
  TextField,
  Tooltip,
} from "@mui/material";
import { spacing } from "@mui/system";
// Components
import ReactSelect from "../../layouts/select/ReactSelect";
// Utils
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { setStructureDataById } from "../../../redux/features/slices/structure.slice";
import * as structureServices from "../../../services/structure/StructureServices";
import { deleteChild } from "../../../services/structure/StructureServices";
import { generateCapatilazeWord } from "../../../utils/helperFunctions";

const Card = styled(MuiCard)(spacing);

const StructureSelectCard = ({
  type,
  classes,
  item,
  structure_id,
  isExistLeft = false,
  loadOptionsForCompanyParts,
  handleSaveCompanyParts,
  bgColor = "",
  position = "relative",
  isBaseLevel = false,
  childData,
  isContentCenter = false,
  isContentLeft,
  isEditMode,
  onCancel = () => {},
  defaultValue,
  value,
  clearValue,
  set_position_options,
  includedId,
  options,
  handleNewPosition,
  handleClear,
  set_new_position,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  async function deleteInnerChild() {
    try {
      let response = await deleteChild(item?.child?._id, id);
      if (response) {
        const response = await structureServices.getStructureById(id);
        dispatch(setStructureDataById(response));
        swal("Struktur hissəsi uğurla silindi!", "Tamamlandı");
      }
    } catch (error) {
      if (error.message === "Request already exist") {
        enqueueSnackbar("Hazırda dəyişiklik sorğusu mövcuddur", {
          variant: "error",
        });
      } else enqueueSnackbar("Xəta baş verdi", { variant: "error" });
    }
  }

  const [search, set_search] = useState("");
  useEffect(() => {
    if (set_position_options) {
      set_position_options((options) => ({
        ...options,
        name: search,
        type: item?.child?.expectedType?._id,
        notUsed: true,
        parentRel: isBaseLevel,
        includedId: includedId,
      }));
    }
  }, [item, search, set_position_options]);
  const [value_, setvalue] = useState(value);
  useEffect(() => {
    setvalue(value);
    if (
      (value === null || value === undefined || value === "") &&
      !isEditMode?.open
    ) {
      if (set_new_position) {
        set_new_position((new_position) => ({
          ...new_position,
          relId: "",
          max_salary: "",
          source: null,
          position: "",
        }));
      }
    }
    set_search(
      value === null || value === undefined || value === "" ? "" : search
    );
  }, [value]);

  return (
    <div>
      {type == "table" ? (
        // <ReactSelect

        //   loadOptions={(_search, loadedOptions) =>
        //     loadOptionsForCompanyParts(
        //       _search,
        //       loadedOptions,
        //       item?.child,
        //       true,
        //       isBaseLevel,
        //       childData
        //     )
        //   }
        //   className="text-dark"
        //   placehodler={`${generateCapatilazeWord(
        //     item?.child?.expectedType?.name === "position"
        //       ? "Vəzifə"
        //       : item?.child?.expectedType?.name
        //   )} Seçin`}
        //   _onChange={({ value }) =>
        //     handleSaveCompanyParts(
        //       value,
        //       item?.child?._id,
        //       item?.child?.expectedType?.name
        //     )
        //   }
        //   defaultValue={defaultValue}
        //   value={value}
        //   clearValue={clearValue}
        // />
        <div>
          <Autocomplete
            options={options?.options}
            clearOnEscape={true}
            freeSolo
            onChange={(e, _) =>
              handleNewPosition(
                _?.value,
                item?.child?._id,
                item?.child?.expectedType?.name
              )
            }
            renderInput={(params) => {
              return (
                <>
                  <TextField
                    {...params}
                    value={value_}
                    label="Vəzifə seçin"
                    className="auto-position"
                  />
                </>
              );
            }}
            // getOptionLabel={(option) => option.label === value ? value : option.label}
            inputValue={search}
            onFocus={(e) => handleClear(item?.child?._id, e)}
            // value={value_}
            onInputChange={(event, newInputValue) => set_search(newInputValue)}
            renderOption={(props, option) => (
              <li {...props} key={option?.key}>
                {option.label}
              </li>
            )}
          />
          {/* <Button onClick={() => set_search("")}>Clear</Button> */}
        </div>
      ) : (
        <Card
          className={classes?.card_content}
          style={{
            backgroundColor: bgColor,
            position: position,
            overflow: "initial",
          }}
        >
          <CardContent
            className={`d-flex ${
              isContentCenter
                ? "justify-content-center"
                : isContentLeft
                ? "justify-content-between"
                : "justify-content-end"
            } align-items-center`}
          >
            <div
              className={`${isExistLeft ? classes?.select_styles_left : ""} ${
                classes?.select_styles
              }`}
            >
              <ReactSelect
                loadOptions={(_search, loadedOptions) =>
                  loadOptionsForCompanyParts(
                    _search,
                    loadedOptions,
                    item?.child,
                    true,
                    isBaseLevel,
                    childData
                  )
                }
                placehodler={`${generateCapatilazeWord(
                  item?.child?.expectedType?.name
                )} Seçin`}
                _onChange={({ value }) =>
                  handleSaveCompanyParts(
                    value,
                    item?.child?._id,
                    item?.child?.expectedType?.name
                  )
                }
                defaultValue={defaultValue}
              />
            </div>
            {isEditMode ? (
              <Button
                sx={{ marginLeft: "5px" }}
                variant="contained"
                color="error"
                onClick={onCancel}
              >
                İmtina
              </Button>
            ) : (
              item?.child?.editable && (
                <Tooltip title="Struktur hissəni sil" className="ms-2">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={deleteInnerChild}
                  >
                    <DeleteSharp />
                  </Button>
                </Tooltip>
              )
            )}
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default StructureSelectCard;
