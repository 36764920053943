import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import React, { useCallback, useEffect, useReducer, useState } from "react";
// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";
// Material UI
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
// Components
import TableWithDynamicInputs from "../../../../components/layouts/dataTable/TableWithDynamicInputs";
import Header from "../../../../components/layouts/header/Header";
import ReactSelect from "../../../../components/layouts/select/ReactSelect";
// Services
import { BASE_URL, SUBJECT_TYPES, TYPES_ENUM } from "../../../../constants";
import * as contractServices from "../../../../services/documentations/ContractServices";
import * as templateServices from "../../../../services/documentations/TemplateServices";
import {
  checkTypePerPage,
  findCaseHttpRequest,
} from "../../../../utils/helperFunctions";
// Sweetalert
import swal from "sweetalert";
// Moment
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";

const Divider = styled(MuiDivider)(spacing);

const ERROR_MESSAGES = {
  max: (obj, label) => {
    if (obj.valueType === "string")
      return `${label} dəyərinin uzunluğu maksimum ${obj.requiredValue} olmalıdır. Mövcud uzunluq: ${obj.value}`;
    else if (obj.valueType === "number")
      return `${label} dəyəri maksimum ${obj.requiredValue} olmalıdır. Mövcud dəyər: ${obj.value}`;
  },
  min: (obj, label) => {
    if (obj.valueType === "string")
      return `${label} dəyərinin uzunluğu minimum ${obj.requiredValue} olmalıdır. Mövcud uzunluq: ${obj.value}`;
    else if (obj.valueType === "number")
      return `${label} dəyəri minimum ${obj.requiredValue} olmalıdır. Mövcud dəyər: ${obj.value}`;
  },
  invalidNumber: (obj, label) => {
    return `${label} dəyəri rəqəm olmalıdır`;
  },
  invalidDate: (obj, label) => {
    return `${label} dəyəri tarix olmalıdır`;
  },
  invalidType: (obj, label) => {
    const lastWordMsg = obj.msg.split(" ").pop();
    const required = () => {
      switch (lastWordMsg) {
        case "string":
          return "tekst";
        case "date":
          return "tarix";
        case "number":
          return "rəqəm";
        default:
          return lastWordMsg;
      }
    };

    return `${label} dəyəri ${required()} olmalıdır`;
  },
};

const CommandCreate = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [download, setDownload] = useState(true);

  const [contractFields, setContractFields] = useState([]);
  const [downloadId, setDownloadId] = useState("");
  const navigate = useNavigate();
  const data = new URLSearchParams(document.location.search);
  const [value, setValue] = useState(data.get("value"));
  const [label, setLabel] = useState(data.get("label"));
  const [defaultValue, setDefaultValue] = useState({});
  const [subjects, setSubjects] = useState({});

  const validationSchema = Yup.object().shape({
    contractType: Yup.string().required("Məcburi xanadır"),
    docModel: Yup.string().required("Məcburi xanadır"),
    data: Yup.object().shape(createValidtaion(contractFields)),
    subjects: Yup.array().of(Yup.string().required("dassag")),
  });
  function createValidtaion(contracts) {
    const data = {};
    for (let element of contracts) {
      let schemaItem;

      if (element.type === "string") {
        schemaItem = Yup.string();
      } else if (element.type === "number") {
        schemaItem = Yup.number();
      } else if (element.type === "date") {
        schemaItem = Yup.date();
      }

      if (element.min) {
        schemaItem = schemaItem.min(
          element.min,
          `Bu xana minimum ${element.min} simvoldan ibarət olmalıdır`
        );
      }

      if (element.max) {
        schemaItem = schemaItem.max(
          element.max,
          `Bu xana maksimum ${element.max} simvoldan ibarət olmalıdır`
        );
      }

      data[element.name] = schemaItem;
    }

    return data;
  }

  const labelStyle = {
    color: "#FFF",
    marginBottom: "10px",
    marginLeft: "5px",
  };

  const formik = useFormik({
    errors: {},
    initialValues: {
      contractType: "",
      docModel: "Employee",
      subjects: [],
      data: {},
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const willCreate = await swal({
          title: "Əminsiniz mi?",
          text: "Bu məlumatı əlavə etmək üçün əminsiniz mi?",
          icon: "warning",
          dangerMode: true,
          buttons: ["Ləğv et", "Əlavə et"],
        });
        if (willCreate) {
          const _response = await contractServices.createContract(values);
          if (_response) {
            setDownloadId(_response._id);
            swal("Əlavə olundu!", "Məlumat uğurla əlavə edildi!", "success");
            // formik.resetForm();
          } else {
            swal("Səhv!", "Hər hansı bir xəta baş verdi!", "error");
          }
        }
        setIsSaved(true);
        setDownload(false);
      } catch (error) {
        const errObj = error?.response?.data?.errors[0];
        const msgObj = JSON.parse(error?.response?.data?.errors[0].message);
        const labelName = contractFields.find(
          (item) => item.name === errObj.field
        )?.label;

        swal("Səhv!", ERROR_MESSAGES[msgObj.type](msgObj, labelName), "error");
      }
    },
  });
  const resetHandler = () => {
    formik.resetForm();
    setIsSaved(false);
    navigate("/documentation/command/create");
  };

  const loadOptionsForTypes = async (_search, loadedOptions) => {
    const _start = loadedOptions.length;
    const { contractTypes, total } = await templateServices.getTypes({
      limit: 10,
      skip: _start,
      type: checkTypePerPage(window.location.pathname, "command")
        ? "order"
        : "",
      name: _search,
    });

    return {
      options: contractTypes.map((item) => ({
        value: item._id,
        label: item.name,
      })),
      hasMore: _start < total - 1,
    };
  };

  const loadOptionsForModelTypes = (_search) => {
    const _response = SUBJECT_TYPES;

    return {
      options: _response.map((item) => ({
        value: item.name,
        label: item.name,
      })),
      hasMore: false,
    };
  };

  const loadOptionsForSubjects = async (_search, loadedOptions) => {
    const _start = loadedOptions?.length;
    const _value = formik?.values?.docModel;
    try {
      const _response = await findCaseHttpRequest(_start, _search, _value);
      return {
        options: _response.val[_response.arrKey].map((item) => ({
          value: item._id,
          label:
            item[_response.field] + " " + item.lastname + " " + item.fathername,
        })),
        hasMore: _start < _response.val.total - 1,
      };
    } catch (error) {
      console.log("err: ", error);
      // return {
      //   options: [],
      //   hasMore: false,
      // };
    }
  };

  const _handleChange = (data) => {
    const subjects = [];
    for (const item of data) {
      subjects.push(item.value);
    }
    formik.setFieldValue("subjects", subjects);
  };

  const _getContractTypes = async (value, label) => {
    try {
      setLoading(true);
      formik.setFieldValue("contractType", value);
      const _response = await templateServices.getTypeById(value);
      if (_response && _response?.fields && _response?.fields?.length > 0) {
        const contractFields = _response.fields.filter(
          (fieldItem) => fieldItem.name !== "docNumber"
        );

        setContractFields(contractFields);

        const filledFields = contractFields.reduce((acc, curr) => {
          if (curr.type === TYPES_ENUM.NUMBER) {
            acc[curr.name] = curr.min ?? 0;
          } else if (curr.type === TYPES_ENUM.DATE) {
            acc[curr.name] = moment(new Date()).format("MM/DD/YYYY");
          } else {
            acc[curr.name] = "";
          }
          return acc;
        }, {});
        formik.setFieldValue("data", filledFields);
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };

  const headerData = {
    title: "Əmr yarat",
    variant: "h3",
  };

  const _isSavedDisabled =
    formik.values.contractType === "" ||
    formik.values.docModel === "" ||
    formik.values.subjects.length === 0;

  useEffect(() => {
    if (value && label) {
      setDefaultValue({ value: value, label: label });
      _getContractTypes(value, label);
    }
  }, [value, label]);

  const handleChangeData = () => {
    if (
      formik.values.contractType !== "" &&
      formik.values.docModel !== "" &&
      formik.values.subjects.length !== 0
    ) {
      setIsSaved(false);
    }
  };

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        style={isOpen ? { height: "90vh" } : null}
        onReset={() => {
          formik.resetForm();
          setIsSaved(false);
          navigate("/documentation/command/create");
        }}
      >
        <Card mb={6} style={{ height: "700px", overflow: "scroll" }}>
          <CardContent>
            <Grid justifyContent="space-between" container spacing={10}>
              <Grid item>
                <Header data={headerData} />
              </Grid>
            </Grid>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/documentation/commands")}
              >
                Əmrlər
              </Button>
            </div>
            <Divider my={6} />

            <Typography variant="h3" textAlign="center" mb={5}>
              Yeni Əmr əlavə et
            </Typography>

            <Grid container spacing={6} mb={3}>
              <Grid item md={6} style={{ color: "#333" }}>
                <Grid sx={labelStyle}>Əmr növü</Grid>
                {!value ? (
                  <>
                    <ReactSelect
                      placehodler={"Command Type*"}
                      loadOptions={loadOptionsForTypes}
                      _isMenuOpen={() => setIsOpen(true)}
                      _isMenuClose={() => setIsOpen(false)}
                      _onChange={({ value, label }) => {
                        handleChangeData();

                        _getContractTypes(value, label);
                      }}
                    />
                  </>
                ) : (
                  <AsyncPaginate
                    isDisabled={true}
                    additional={{
                      buttonProps: {
                        style: {
                          backgroundColor: "#3498db",
                          color: "#fff",
                          border: "none",
                        },
                      },
                    }}
                    value={defaultValue}
                  />
                )}
              </Grid>
              {/* <Grid item md={4} style={{ color: "#333" }}>
                <Grid sx={labelStyle}>Subyektin(lərin) tipi</Grid>

                <ReactSelect
                  loadOptions={loadOptionsForModelTypes}
                  placehodler={"Document Model*"}
                  isSearchable={false}
                  _isMenuOpen={() => setIsOpen(true)}
                  _isMenuClose={() => setIsOpen(false)}
                  _onChange={({ value }) => {
                    handleChangeData();
                    formik.setFieldValue("docModel", value);
                  }}
                  // isClearable={true}
                />
              </Grid> */}
              <Grid item md={6} style={{ color: "#333" }}>
                <>
                  <Grid sx={labelStyle}>Subyekt</Grid>
                  <ReactSelect
                    options={subjects}
                    cacheOptions={false}
                    loadOptions={loadOptionsForSubjects}
                    placehodler={"Subjects*"}
                    isDisabled={formik.values.docModel === ""}
                    isMultiple={true}
                    _isMenuOpen={() => setIsOpen(true)}
                    _isMenuClose={() => setIsOpen(false)}
                    _onChange={(item) => {
                      handleChangeData();
                      _handleChange(item);
                    }}
                  />
                </>
              </Grid>
            </Grid>

            {formik.values.contractType !== "" ? (
              loading ? (
                <Box display="flex" justifyContent="center" my={6}>
                  <CircularProgress />
                </Box>
              ) : (
                <div style={{ marginTop: "25px" }}>
                  {contractFields.length > 0 ? (
                    <>
                      <TableWithDynamicInputs
                        // headers={["Name", ""]}
                        data={contractFields}
                        formik={formik}
                      />
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {/* {isSaved && (
                          <Button
                            type="reset"
                            variant="contained"
                            color="error"
                            mt={3}
                            onClick={() => setTrigger((prev) => !prev)}
                          >
                            Yeni əmr yarat
                          </Button>
                        )} */}
                      </Grid>
                      <Grid container justifyContent="flex-end" mt={2.5}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          mt={3}
                          style={{ marginRight: "25px" }}
                          disabled={_isSavedDisabled || isSaved}
                        >
                          Yadda saxla
                        </Button>
                        <a
                          href={`${BASE_URL}/employee/contract/${downloadId}/download`}
                          download
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            mt={3}
                            disabled={download}
                            onClick={() => setDownload(true)}
                          >
                            Fayl Yüklə
                          </Button>
                        </a>
                      </Grid>
                    </>
                  ) : (
                    <Typography variant="h3" textAlign="center">
                      Hər hansı bir məlumat tapılmadı
                    </Typography>
                  )}
                </div>
              )
            ) : null}
          </CardContent>
        </Card>
      </form>
    </>
  );
};

export default CommandCreate;
