import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers";
import { parse } from "date-fns";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { createStaticContract } from "../../../api/contractRequests/contractRequests";
import { createVacationRequest } from "../../../api/vacationRequests/vacationRequests";
import {
  getActiveStaffs,
  getWorkYears,
} from "../../../services/staff/StaffServices";
import { removeEmptyStrings } from "../../../utils/helperFunctions";

const validationSchema = Yup.object().shape({
  sourceOfVacation: Yup.string().required("Məzuniyyətin mənbəyi mütləqdir"),
  vacationType: Yup.string().required("Məzuniyyətin növü mütləqdir"),
  replacementStaffId: Yup.string().required("Əvəzləyici şəxs mütləqdir"),
  startDate: Yup.string()
    .required("Məzuniyyətin başlama tarixi mütləqdir")
    .test(
      "is-valid-date",
      "Başlama tarixi düzgün formatda olmalıdır",
      (value) => {
        return moment(value, "DD/MM/YYYY", true).isValid(); // Validate the date format
      }
    ),
  endDate: Yup.string()
    .required("Məzuniyyətin bitmə tarixi mütləqdir")
    .test(
      "is-valid-date",
      "Bitmə tarixi düzgün formatda olmalıdır",
      (value) => {
        return moment(value, "DD/MM/YYYY", true).isValid(); // Validate the date format
      }
    )
    .test(
      "is-end-date-after-start-date",
      "Məzuniyyətin bitmə tarixi, başlama tarixindən əvvəl ola bilməz",
      function (value) {
        const { startDate } = this.parent;
        const startDateObj = moment(startDate, "DD/MM/YYYY");
        const endDateObj = moment(value, "DD/MM/YYYY");

        return endDateObj.isAfter(startDateObj, "day");
      }
    ),
  jobStartDate: Yup.string()
    .required("İşə başlama tarixi mütləqdir")
    .test(
      "is-valid-date",
      "İşə başlama tarixi düzgün formatda olmalıdır",
      (value) => {
        return moment(value, "DD/MM/YYYY", true).isValid();
      }
    )
    .test(
      "is-job-start-date-after-start-date",
      "İşə başlama tarixi, məzuniyyətin başlama tarixindən əvvəl ola bilməz",
      function (value) {
        const { startDate } = this.parent;
        const startDateObj = moment(startDate, "DD/MM/YYYY");
        const jobStartDateObj = moment(value, "DD/MM/YYYY");

        return jobStartDateObj.isAfter(startDateObj, "day");
      }
    ),
  fromMain: Yup.number().test(
    "is-combine-date-valid",
    // "Stop",
    function (value) {
      const fromAdditional = this.parent.fromAdditional;
      const combineDate = fromAdditional + value;
      const total = this.parent.total;

      const unUsed = this.parent.fromUnUsedMain;

      if (value > unUsed) {
        return this.createError({
          message: `Əsas günlərin sayı ${unUsed} gündən çox ola bilməz`,
        });
      }
      if (combineDate > total) {
        return this.createError({
          message: `Əsas və əlavə günlərin cəmi ${total} gündən çox ola bilməz`,
        });
      }

      return true;
    }
  ),
  fromAdditional: Yup.number().test(
    "is-combine-date-valid",
    // "Stop",
    function (value) {
      const fromMain = this.parent.fromMain;
      const combineDate = fromMain + value;
      const total = this.parent.total;

      const unUsed = this.parent.fromUnUsedAdditional;

      if (value > unUsed) {
        return this.createError({
          message: `Əlavə günlərin sayı ${unUsed} gündən çox ola bilməz`,
        });
      }
      if (combineDate > total) {
        return this.createError({
          message: `Əsas və əlavə günlərin cəmi ${total} gündən çox ola bilməz`,
        });
      }

      return true;
    }
  ),
});

const CreateVacationForm = ({ sourceOfVacationArray, reasonArray }) => {
  const formikRef = useRef();

  const navigate = useNavigate();
  const [allVacationReports, setAllVacationReports] = useState({
    staffId: "",
    vacationTrackingId: "",
    sourceOfVacationId: "",
    startDate: "",
    endDate: "",
    vacationType: "",
    sourceOfVacation: [],
    positionName: "",
  });
  const [contractId, setContractId] = useState();
  const [responseStatus, setResponseStatus] = useState();
  const [progress, setProgress] = useState(0);
  const [isEdited, setIsEdited] = useState(false);
  const [activeEmps, setActiveEmps] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDate, setIsDate] = useState({
    jobStartDate: false,
    startDate: false,
    endDate: false,
  });

  const { currentEmployee, allEmployees } = useSelector(
    (state) => state?.commonThings
  );

  useEffect(() => {
    isEdited && setProgress(0);
    isEdited && setResponseStatus();
  }, [isEdited]);

  useEffect(() => {
    getActiveEmployees();
    getInitialValues();
  }, []);

  const getActiveEmployees = async () => {
    try {
      const { data } = await getActiveStaffs();
      data && setActiveEmps(data?.records);
    } catch (error) {
      console.log("err", error);
    }
  };
  const getInitialValues = async () => {
    try {
      const { data } = await getWorkYears();
      data &&
        data.map((item, index) => {
          if (item?.vacationReports?.length) {
            setAllVacationReports((prevState) => ({
              ...prevState,
              sourceOfVacation: item?.vacationReports,
              vacationTrackingId: item?._id,
              staffId: item?.staff?._id,
              positionName: item?.position?.source?.name,
            }));
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
  const todayDate = new Date();
  const contractName = "vacation-application";

  // Create vacation request
  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const {
      sourceOfVacation,
      total,
      fromUnUsedMain,
      fromUnUsedAdditional,
      ...restValues
    } = values;

    const modifiedValues = {
      ...restValues,
      vacationTrackingId: allVacationReports?.vacationTrackingId,
      endDate: restValues?.endDate
        ? new Date(
            restValues?.endDate.split("/").reverse().join("-")
          ).toISOString()
        : "",
      startDate: restValues?.startDate
        ? new Date(
            restValues?.startDate.split("/").reverse().join("-")
          ).toISOString()
        : "",
      jobStartDate: restValues?.jobStartDate
        ? new Date(
            restValues?.jobStartDate.split("/").reverse().join("-")
          ).toISOString()
        : "",
      sourceOfVacationId:
        values?.vacationType === "labor"
          ? allVacationReports?.sourceOfVacationId
          : "",
      fromMain: values?.vacationType === "labor" ? values?.fromMain : "",
      fromAdditional:
        values?.vacationType === "labor" ? values?.fromAdditional : "",
      contractId: contractId,
    };
    const result = removeEmptyStrings(modifiedValues);
    const response = await createVacationRequest(result);
    setSubmitting(false);
    setIsDate({
      jobStartDate: false,
      startDate: false,
      endDate: false,
    });
    if (response && response.data) {
      setTimeout(() => {
        navigate("/emp-vacation-requests");
      }, 2000);
    }
  };

  // Create vacation contract
  const handleVacationContract = async (values) => {
    const isValid = await handleManualValidation();

    if (!isValid) return;

    setProgress(0);

    const vacationContract = {
      docModel: "Employee",
      subjects: [currentEmployee?._id],
      data: {
        staffId: allVacationReports?.staffId,
        vacationTrackingId: allVacationReports?.vacationTrackingId,
        sourceOfVacationId: allVacationReports?.sourceOfVacationId,
        startDate: allVacationReports?.startDate,
        endDate: allVacationReports?.endDate,
        vacationType: allVacationReports?.vacationType,
        // sourceOfVacation: values.sourceOfVacation,
        // startDate: values.startDate,
        // endDate: values.endDate,
        // vacationType: values.vacationType,
        // // vacationDays: 28,
        // vacationCreatedDate: moment(todayDate).format("MM/DD/yyyy"),
        // jobStartDate: values.jobStartDate,
      },
    };

    try {
      setIsLoading(true);
      const response = await createStaticContract(
        contractName,
        vacationContract
      );
      if (response) {
        setResponseStatus(response?.status);
        setContractId(response?.data?._id);
      }
      if (response?.status === 201) {
        const timer = setInterval(() => {
          setProgress((oldProgress) => {
            if (oldProgress === 100) {
              clearInterval(timer);
            }
            const diff = Math.random() * 10;
            return Math.min(oldProgress + diff, 100);
          });
        }, 200);
      }
      setIsDate({
        jobStartDate: false,
        startDate: false,
        endDate: false,
      });
    } catch (error) {
      console.log("err", error);
    } finally {
      setIsLoading(false);
    }
    setIsEdited(false);
  };

  const handleManualValidation = async () => {
    try {
      if (formikRef?.current) {
        const errors = await formikRef?.current.validateForm();
        const isValid = Object.keys(errors).length === 0;
        return isValid;
      }
      return false;
    } catch (error) {
      console.log("err", error);
    }
  };

  function daysBetweenDates(dateString1, dateString2) {
    const dateParts1 = dateString1.split("/");
    const dateParts2 = dateString2.split("/");

    const date1 = new Date(dateParts1[2], dateParts1[1] - 1, dateParts1[0]);
    const date2 = new Date(dateParts2[2], dateParts2[1] - 1, dateParts2[0]);
    const timeDiff = Math.abs(date2 - date1);

    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    return daysDiff + 1;
  }
  function addDays(dateStr, daysToAdd) {
    const [day, month, year] = dateStr.split("/").map(Number);
    const date = new Date(year, month - 1, day);
    date.setDate(date.getDate() + daysToAdd);

    return date;
  }
  function parseDate(dateStr) {
    return moment(dateStr, "DD/MM/YYYY").toDate();
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        sourceOfVacation: "",
        vacationType: "",
        replacementStaffId: "",
        startDate: "",
        endDate: "",
        fromMain: "",
        fromUnUsedMain: "",
        fromAdditional: "",
        fromUnUsedAdditional: "",
        total: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        isValid,
        touched,
        isSubmitting,
        setFieldValue,
        values,
        resetForm,
        handleChange,
      }) => (
        <Form>
          <>
            {/* EmployeeId and Source of Vacation */}
            <Grid sx={{ display: "flex", gap: 3, mb: 3, mt: 3 }}>
              <Grid sx={{ width: "100%" }}>
                <Field
                  type="text"
                  name="employeeId"
                  as={TextField}
                  fullWidth
                  disabled
                  onChange={(e) => {
                    handleChange(e);
                    setIsEdited(true);
                  }}
                  label={
                    currentEmployee?.firstname + " " + currentEmployee?.lastname
                  }
                  error={errors?.firstname && Boolean(errors?.firstname)}
                  helperText={errors?.firstname && errors?.firstname}
                />
              </Grid>
              <Grid
                onClick={() =>
                  setIsDate((prevDate) => ({
                    ...prevDate,
                    jobStartDate: true,
                  }))
                }
                sx={{ width: "100%", pr: 1 }}
              >
                <Field name={`jobStartDate`}>
                  {({ field, form }) => {
                    const selectedEndDate = form?.values?.endDate;

                    return (
                      <DatePicker
                        {...field}
                        label="İşə başlama tarixi *"
                        inputFormat="dd/MM/yyyy"
                        value={
                          field?.value instanceof Date
                            ? field?.value
                            : parseDate(field?.value)
                        }
                        minDate={
                          selectedEndDate
                            ? parseDate(allVacationReports?.endDate)
                            : new Date()
                        }
                        onChange={(date) => {
                          if (date instanceof Date && !isNaN(date)) {
                            const formattedDate =
                              moment(date).format("DD/MM/YYYY");
                            form.setFieldValue(`jobStartDate`, formattedDate);
                            setAllVacationReports((prevState) => ({
                              ...prevState,
                              startDate: date,
                            }));
                          } else {
                            form.setFieldValue(`jobStartDate`, "");
                          }
                          setIsEdited(true);
                        }}
                        disabled={
                          values?.vacationType === "" ||
                          (values?.vacationType === "labor" &&
                            values?.sourceOfVacation === "")
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={
                              values?.vacationType === "labor"
                                ? values.sourceOfVacation !== "" &&
                                  isDate.jobStartDate &&
                                  form?.errors?.jobStartDate &&
                                  form?.errors?.jobStartDate
                                : form?.errors?.jobStartDate &&
                                  isDate.jobStartDate &&
                                  form?.errors?.jobStartDate
                            }
                            error={
                              values?.vacationType === "labor"
                                ? values.sourceOfVacation !== "" &&
                                  isDate.jobStartDate &&
                                  form?.errors?.jobStartDate &&
                                  Boolean(form?.errors?.jobStartDate)
                                : form?.errors?.jobStartDate &&
                                  isDate.jobStartDate &&
                                  Boolean(form?.errors?.jobStartDate)
                            }
                            fullWidth
                          />
                        )}
                      />
                    );
                  }}
                </Field>
              </Grid>
            </Grid>

            {/* Məzuniyyətin növü and Əvəzləyici şəxs */}
            <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
              <Grid sx={{ width: "100%" }}>
                <FormControl
                  error={errors?.vacationType && errors?.vacationType}
                  fullWidth
                >
                  <InputLabel id="vacationType">Məzuniyyətun növü</InputLabel>
                  <Field
                    as={Select}
                    name="vacationType"
                    labelId="vacationType"
                    label="Məzuniyyətin növü"
                    id="vacationType-select"
                    onChange={(e) => {
                      const selectedItem = e?.target?.value;

                      // Update the vacationType in your custom state first
                      setAllVacationReports((prevState) => ({
                        ...prevState,
                        vacationType: selectedItem,
                      }));

                      // Clear relevant fields
                      setFieldValue("sourceOfVacation", "");
                      setFieldValue("endDate", "");
                      setFieldValue("startDate", "");
                      setFieldValue("jobStartDate", "");

                      // Mark the form as edited
                      setIsEdited(true);

                      // Lastly, handle Formik's internal change
                      handleChange(e);
                    }}
                    fullWidth
                    error={
                      errors?.vacationType && Boolean(errors?.vacationType)
                    }
                  >
                    {reasonArray.map((item, index) => {
                      if (
                        currentEmployee?.gender === "male" &&
                        item?.value === "maternity"
                      )
                        return;
                      return (
                        <MenuItem key={index} value={item?.value}>
                          {item?.name}
                        </MenuItem>
                      );
                    })}
                  </Field>
                  {errors?.vacationType && errors?.vacationType && (
                    <FormHelperText error>
                      {errors?.vacationType}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid sx={{ width: "100%" }}>
                <FormControl
                  error={
                    touched?.replacementStaffId &&
                    errors?.replacementStaffId &&
                    errors?.replacementStaffId
                  }
                  fullWidth
                >
                  <InputLabel id="replacementStaffId">
                    Əvəzləyici şəxs
                  </InputLabel>
                  <Field
                    as={Select}
                    name="replacementStaffId"
                    labelId="replacementStaffId"
                    label="Əvəzləyici şəxs"
                    id="replacementStaffId-select"
                    onChange={(e) => {
                      handleChange(e);
                      setIsEdited(true);
                    }}
                    fullWidth
                    error={
                      touched?.replacementStaffId &&
                      errors?.replacementStaffId &&
                      Boolean(errors?.replacementStaffId)
                    }
                  >
                    {activeEmps &&
                      activeEmps.map((item, index) => {
                        const emp = item?.employee[0];

                        if (
                          emp?.id === currentEmployee?.id ||
                          emp === undefined
                        )
                          return;
                        return (
                          <MenuItem key={index} value={item?._id}>
                            {emp?.firstname +
                              " " +
                              emp?.lastname +
                              ` ( ${
                                item.position ? item?.position?.name : ""
                              } )`}
                          </MenuItem>
                        );
                      })}
                  </Field>
                  {touched?.replacementStaffId &&
                    errors?.replacementStaffId &&
                    errors?.replacementStaffId && (
                      <FormHelperText error>
                        {errors?.replacementStaffId}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
            </Grid>

            {/* Məzuniyyətin başlama tarixi and Məzuniyyət Ərizəsi Yarat */}
            <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
              {values.vacationType === "labor" && (
                <Grid sx={{ width: "100%" }}>
                  <FormControl
                    error={
                      touched?.sourceOfVacation &&
                      errors?.sourceOfVacation &&
                      errors?.sourceOfVacation
                    }
                    fullWidth
                  >
                    <InputLabel id="sourceOfVacation">
                      Məzuniyyətin mənbəyi
                    </InputLabel>
                    <Field
                      as={Select}
                      name="sourceOfVacation"
                      labelId="sourceOfVacation"
                      label="Məzuniyyətin mənbəyi"
                      id="sourceOfVacation-select"
                      onChange={(e) => {
                        const selectedItem =
                          allVacationReports?.sourceOfVacation.find(
                            (item) =>
                              `${item?.startWorkYear} - ${item?.endWorkYear} iş ili` ===
                              e.target.value
                          );

                        setFieldValue("total", selectedItem?.unUsed?.total);
                        setFieldValue(
                          "fromUnUsedMain",
                          selectedItem?.unUsed?.main
                        );
                        setFieldValue(
                          "fromUnUsedAdditional",
                          selectedItem?.unUsed?.additional
                        );

                        setAllVacationReports((prevState) => ({
                          ...prevState,
                          sourceOfVacationId: selectedItem?._id,
                        }));
                        handleChange(e);
                        setIsEdited(true);
                      }}
                      value={values?.sourceOfVacation}
                      fullWidth
                      error={
                        touched?.sourceOfVacation &&
                        errors?.sourceOfVacation &&
                        Boolean(errors?.sourceOfVacation)
                      }
                    >
                      <MenuItem
                        sx={{
                          fontSize: "14px",
                          paddingLeft: "8px",
                        }}
                        disabled
                      >
                        {allVacationReports?.positionName &&
                          allVacationReports?.positionName}
                      </MenuItem>
                      {allVacationReports?.sourceOfVacation?.map(
                        (item, index) => (
                          <MenuItem
                            key={index}
                            value={`${item?.startWorkYear} - ${item?.endWorkYear} iş ili`} // Ensure value is formatted correctly
                          >
                            {`${moment(item?.startWorkYear).format(
                              "YYYY"
                            )} - ${moment(item?.endWorkYear).format(
                              "YYYY"
                            )} iş ili`}
                          </MenuItem>
                        )
                      )}
                    </Field>

                    {touched?.sourceOfVacation &&
                      errors?.sourceOfVacation &&
                      errors?.sourceOfVacation && (
                        <FormHelperText error>
                          {errors?.sourceOfVacation}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
              )}
              {values?.vacationType === "labor" && (
                <Grid sx={{ width: "100%", display: "flex", gap: 3 }}>
                  <Grid sx={{ width: "50%" }}>
                    <Field
                      type="number"
                      name="fromMain"
                      as={TextField}
                      fullWidth
                      value={values?.fromMain}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      label="Əsas"
                      disabled={
                        values?.vacationType === "" ||
                        (values?.vacationType === "labor" &&
                          values?.sourceOfVacation === "")
                      }
                      error={
                        touched?.fromMain &&
                        errors?.fromMain &&
                        Boolean(errors?.fromMain)
                      }
                      helperText={
                        touched?.fromMain &&
                        errors?.fromMain &&
                        errors?.fromMain
                      }
                    />
                    {values?.sourceOfVacation !== "" ? (
                      <span>
                        Əsas günlərin sayı:{" "}
                        {
                          allVacationReports?.sourceOfVacation?.find(
                            (item) => item?.unUsed?.main !== undefined
                          )?.unUsed?.main
                        }
                      </span>
                    ) : null}
                  </Grid>
                  <Grid sx={{ width: "50%" }}>
                    <Field
                      type="number"
                      name="fromAdditional"
                      as={TextField}
                      fullWidth
                      value={values?.fromAdditional}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      label="Əlavə"
                      error={
                        touched?.fromAdditional &&
                        errors?.fromAdditional &&
                        Boolean(errors?.fromAdditional)
                      }
                      helperText={
                        touched?.fromAdditional &&
                        errors?.fromAdditional &&
                        errors?.fromAdditional
                      }
                    />
                    {values?.sourceOfVacation !== "" ? (
                      <span>
                        Əlavə günlərin sayı:{" "}
                        {
                          allVacationReports?.sourceOfVacation?.find(
                            (item) => item?.unUsed?.additional !== undefined
                          )?.unUsed?.additional
                        }
                      </span>
                    ) : null}
                  </Grid>
                </Grid>
              )}
            </Grid>

            {/*  Məzuniyyətin bitme tarixi  and progress */}
            <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
              <Grid
                onClick={() =>
                  setIsDate((prevDate) => ({
                    ...prevDate,
                    startDate: true,
                  }))
                }
                sx={{ width: "100%" }}
              >
                <Field name="startDate">
                  {({ field, form }) => {
                    return (
                      <DatePicker
                        {...field}
                        label="Məzuniyyətin başlama tarixi"
                        inputFormat="dd/MM/yyyy"
                        value={
                          field.value instanceof Date
                            ? field?.value
                            : parseDate(field?.value)
                        }
                        onChange={(date) => {
                          if (date instanceof Date && !isNaN(date.getTime())) {
                            const formattedDate =
                              moment(date).format("DD/MM/YYYY");
                            form.setFieldValue("startDate", formattedDate);
                            setAllVacationReports((prevState) => ({
                              ...prevState,
                              startDate: date,
                            }));
                          } else {
                            form.setFieldValue("startDate", "");
                          }
                        }}
                        minDate={new Date()}
                        maxDate={allVacationReports?.endDate}
                        disabled={
                          values?.vacationType === "" ||
                          (values?.vacationType === "labor" &&
                            values?.sourceOfVacation === "")
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={
                              values?.vacationType === "labor"
                                ? values.sourceOfVacation !== "" &&
                                  isDate.startDate &&
                                  form?.errors?.startDate &&
                                  form?.errors?.startDate
                                : form?.errors?.startDate &&
                                  isDate.startDate &&
                                  form?.errors?.startDate
                            }
                            error={
                              values?.vacationType === "labor"
                                ? values.sourceOfVacation !== "" &&
                                  isDate.startDate &&
                                  form?.errors?.startDate &&
                                  Boolean(form?.errors?.startDate)
                                : form?.errors?.startDate &&
                                  isDate.startDate &&
                                  Boolean(form?.errors?.startDate)
                            }
                            fullWidth
                          />
                        )}
                      />
                    );
                  }}
                </Field>
              </Grid>
              <Grid sx={{ width: "100%" }}>
                <Button
                  variant="outlined"
                  color={progress === 100 ? "success" : "primary"}
                  disabled={responseStatus === 201 || !isValid}
                  fullWidth
                  sx={{
                    height: "100%",
                  }}
                  startIcon={<CreateNewFolderIcon />}
                  onClick={() => {
                    handleVacationContract(values);
                  }}
                  // disabled={progress !== 0}
                >
                  Məzuniyyət Ərizəsi Yarat
                </Button>
              </Grid>
            </Grid>

            <Grid
              onClick={() =>
                setIsDate((prevDate) => ({
                  ...prevDate,
                  endDate: true,
                }))
              }
              sx={{ display: "flex", gap: 3, mb: 3 }}
            >
              <Field name="endDate">
                {({ field, form }) => {
                  return (
                    <DatePicker
                      {...field}
                      label="Məzuniyyətin bitmə tarixi"
                      inputFormat="dd/MM/yyyy"
                      value={
                        field?.value instanceof Date
                          ? field?.value
                          : parse(field?.value, "dd/MM/yyyy", new Date())
                      }
                      onChange={(date) => {
                        if (date instanceof Date && !isNaN(date.getTime())) {
                          const formattedDate =
                            moment(date).format("DD/MM/YYYY");
                          form.setFieldValue("endDate", formattedDate);
                          setAllVacationReports((prevState) => ({
                            ...prevState,
                            endDate: date,
                          }));
                        } else {
                          form.setFieldValue("endDate", "");
                        }
                        setIsEdited(true);
                      }}
                      minDate={
                        form?.values?.startDate
                          ? parse(
                              form?.values?.startDate,
                              "dd/MM/yyyy",
                              new Date()
                            )
                          : new Date()
                      }
                      maxDate={
                        form?.values?.jobStartDate &&
                        values.vacationType === "labor"
                          ? parse(
                              form?.values?.jobStartDate,
                              "dd/MM/yyyy",
                              new Date()
                            )
                          : null
                      }
                      disabled={
                        values?.vacationType === "" ||
                        (values?.vacationType === "labor" &&
                          values?.sourceOfVacation === "")
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={
                            values?.vacationType === "labor"
                              ? values.sourceOfVacation !== "" &&
                                isDate.endDate &&
                                form?.errors?.endDate &&
                                form?.errors?.endDate
                              : form?.errors?.endDate &&
                                isDate.endDate &&
                                form?.errors?.endDate
                          }
                          error={
                            values?.vacationType === "labor"
                              ? values.sourceOfVacation !== "" &&
                                isDate.endDate &&
                                form?.errors?.endDate &&
                                Boolean(form?.errors?.endDate)
                              : form?.errors?.endDate &&
                                isDate.endDate &&
                                Boolean(form?.errors?.endDate)
                          }
                          fullWidth
                        />
                      )}
                    />
                  );
                }}
              </Field>

              <Grid
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: 3,
                }}
              >
                {isLoading && <CircularProgress />}
                {/* <LinearProgress
                  sx={{
                    height: "50%",
                    borderRadius: 1,
                    width: "100%",
                  }}
                  variant="determinate"
                  value={progress}
                  color={progress === 100 ? "success" : "primary"}
                /> */}
                {/* <Typography mt={0.5}>{Math.round(progress)}%</Typography> */}
              </Grid>
            </Grid>
            <Typography width={"100%"} textAlign={"center"} fontSize={18}>
              Məzuniyyət günlərinin sayı:{" "}
              <span>
                {daysBetweenDates(values?.startDate, values?.endDate)
                  ? daysBetweenDates(values?.startDate, values?.endDate)
                  : "başlama və bitmə tarixi təyin edilməyib"}
              </span>
            </Typography>
          </>

          {/* Submit button */}
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={
                !isValid ||
                isSubmitting ||
                responseStatus !== 201 ||
                progress !== 100
              }
            >
              Məzuniyyət Sorğusu Yarat
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CreateVacationForm;
