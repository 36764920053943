import { useState } from "react";
import style from "../myProfileStyle.module.css";
import { CardContent, Grid, Card, Tooltip, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MCViewAcademicDegree from "../Personal_informations_view_part/MCViewAcademicDegree";

export default function ShowAcademicDegreeInfo({ employeeById }) {
  const [showAcademicDegree, setShowAcademicDegree] = useState(false);

  return (
    <>
      <Grid className={style.myCabinetThirdSection}>
        <Card mb={6}>
          <CardContent sx={{ "&:last-child": { pb: 4 } }}>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid sx={{ width: "100%" }}>
                <Typography variant="h6">Elmi dərəcələri göstər</Typography>
              </Grid>

              <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{ justifyContent: "flex-end" }}
              >
                {/* <Grid item> */}
                <Tooltip title="Elmi dərəcələri göstər">
                  {showAcademicDegree ? (
                    <KeyboardArrowUpIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setShowAcademicDegree(!showAcademicDegree);
                      }}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setShowAcademicDegree(!showAcademicDegree);
                      }}
                    />
                  )}
                </Tooltip>
              </Grid>
            </Grid>

            {showAcademicDegree && (
              <>
                {employeeById?.academicDegrees.length === 0 ? (
                  <Typography>Göstəriləcək elmi dərəcə yoxdur</Typography>
                ) : (
                  <MCViewAcademicDegree employeeById={employeeById} />
                )}
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
