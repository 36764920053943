import moment from "moment";
import React from "react";

export const obj = {
  gender: {
    label: "Cinsiyyət",
    renderValue: (value) => {
      return value.map((val) => {
        const transformedValues = {
          male: "Kişi",
          female: "Qadın",
        };
        const valEnd = Object.entries(val)
          .map(([key, value]) => transformedValues[value])
          .filter(Boolean);
        return valEnd.join(" -> ");
      });
    },
  },
  employeePhotoAsBase64: {
    label: "Profil şəkili",
    renderValue: (value) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      return value
        .map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        })
        .toString();
    },
  },
  isCivilServant: {
    label: "Dövlət qulluqçusu",
    renderValue: (value, data) => {
      const bollValues = {
        false: "Xeyr",
        true: "Bəli",
      };
      if (data.operation === "delete" || data.operation === "create") {
        return bollValues[value];
      } else {
        return value.map((val) => {
          const valEnd = Object.entries(val)
            .map(([key, value]) => bollValues[value])
            .filter(Boolean);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  dob: {
    label: "Doğum tarixi",
    renderValue: (value, data) => {
      if (data.operation === "delete" || data.operation === "create") {
        let formatted = value.map((date) => {
          return moment(date).format("DD/MM/YYYY");
        });
        return formatted.join(" -> ");
      } else {
        let formattedDates = value.map((val) => {
          let dates = Object.values(val);
          let formatted = dates.map((date) => {
            return moment(date).format("DD/MM/YYYY");
          });
          return formatted.join(" -> ");
        });
        return formattedDates.join(", ");
      }
    },
  },
  fathername: {
    label: "Ata adı",
    renderValue: (value) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      return value.map((val) => {
        valEnd = Object.values(val);
        return valEnd.join(" -> ");
      });
    },
  },
  firstname: {
    label: "Adı",
    renderValue: (value) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      return value.map((val) => {
        valEnd = Object.values(val);
        return valEnd.join(" -> ");
      });
    },
  },
  lastname: {
    label: "Soyadı",
    renderValue: (value) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      return value.map((val) => {
        valEnd = Object.values(val);
        return valEnd.join(" -> ");
      });
    },
  },
  idCardAuthority: {
    label: "Şəxsiyyət vəsiqəsini verən təşkilat",
    renderValue: (value) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      return value.map((val) => {
        valEnd = Object.values(val);
        return valEnd.join(" -> ");
      });
    },
  },
  idCardIssuanceDate: {
    label: "Ş.V. verilmə tarixi",
    renderValue: (value) => {
      let formattedDates = value.map((val) => {
        let dates = Object.values(val);
        let formatted = dates.map((date) => {
          return moment(date).format("DD/MM/YYYY");
        });
        return formatted.join(" -> ");
      });
      return formattedDates.join(", ");
    },
  },
  idCardNumber: {
    label: "Ş.V/Seriya №",
    renderValue: (value, data) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join("");
        });
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  idCardValidityPeriod: {
    label: "Ş.V. etibarlılıq Müddəti",
    renderValue: (value) => {
      let formattedDates = value.map((val) => {
        let dates = Object.values(val);
        let formatted = dates.map((date) => {
          return moment(date).format("DD/MM/YYYY");
        });
        return formatted.join(" -> ");
      });
      return formattedDates.join(", ");
    },
  },
  nationality: {
    label: "Milliyyət",
    renderValue: (value) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      return value.map((val) => {
        valEnd = Object.values(val);
        return valEnd.join(" -> ");
      });
    },
  },
  pin: {
    label: "Fərdi identifikasiya Nömrəsi (FİN)",
    renderValue: (value, data) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  placeOfBirth: {
    label: "Doğulduğu yer",
    renderValue: (value) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      return value.map((val) => {
        valEnd = Object.values(val);
        return valEnd.join(" -> ");
      });
    },
  },
  ssn: {
    label: "SSN",
    renderValue: (value) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      return value.map((val) => {
        valEnd = Object.values(val).filter((v) => v !== "");
        return valEnd.join(" -> ");
      });
    },
  },
  department: {
    label: "Struktur bölmə",
    renderValue: (value, data) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  endDate: {
    label: "Bitmə tarixi",
    renderValue: (value, data) => {
      if (data.operation === "delete" || data.operation === "create") {
        let formatted = value.map((date) => {
          return moment(date).format("DD/MM/YYYY");
        });
        return formatted.join(" -> ");
      } else {
        let formattedDates = value.map((val) => {
          let dates = Object.values(val);
          let formatted = dates.map((date) => {
            return moment(date).format("DD/MM/YYYY");
          });
          return formatted.join(" -> ");
        });
        return formattedDates.join(", ");
      }
    },
  },
  startDate: {
    label: "Başlama tarixi",
    renderValue: (value, data) => {
      if (data.operation === "delete" || data.operation === "create") {
        let formatted = value.map((date) => {
          return moment(date).format("DD/MM/YYYY");
        });
        return formatted.join(" -> ");
      } else {
        let formattedDates = value.map((val) => {
          let dates = Object.values(val);
          let formatted = dates.map((date) => {
            return moment(date).format("DD/MM/YYYY");
          });
          return formatted.join(" -> ");
        });
        return formattedDates.join(", ");
      }
    },
  },
  organizationName: {
    label: "Təşkilatın adı",
    renderValue: (value, data) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  position: {
    label: "Vəzifə",
    renderValue: (value, data) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  education: {
    label: "Təhsili",
    renderValue: (value, data) => {
      const transformedValues = {
        bachelor: "Ali təhsil - Bakalavr",
        master: "Ali təhsil - Magistr",
        doctoral: "Ali təhsil - Doktorantura",
        primary: "Ümumi təhsil - İbtidai təhsil",
        generalSecondary: "Ümumi təhsil - Ümumi orta təhsil",
        completeSecondary: "Ümumi təhsil - Tam orta təhsil",
        secondarySpecial: "Orta ixtisas təhsili",
        firstVocational: "İlk peşə - İxtisas təhsili",
        secBasisOfCompleteSec: "Tam orta təhsil bazasında orta ixtisas təhsili",
        secBasisOfGeneralSec:
          "Ümumi orta təhsil bazasında orta ixtisas təhsili",
        incompleteHigherTill1993:
          "Ali təhsil - 1993 cü ilə qədər natamam ali təhsil",
        completeHigherTill1993:
          "Ali təhsil - 1997 ci ilə qədər natamam ali təhsil",
      };
      if (data.operation === "delete" || data.operation === "create") {
        return transformedValues[value];
      } else {
        return value.map((val) => {
          const valEnd = Object.entries(val)
            .map(([key, value]) => transformedValues[value])
            .filter(Boolean);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  fullname: {
    label: "Soyadı Adı Atasının adı",
    renderValue: (value, data) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  placeOfWork: {
    label: "İş yeri (vəzifəsi)",
    renderValue: (value, data) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  relationType: {
    label: "Qohumluq əlaqəsi növü",
    renderValue: (value, data) => {
      const transformedValues = {
        grandmother: "Nənəm",
        grandfather: "Babam",
        father: "Atam",
        mother: "Anam",
        "mother-in-law": "Qayınanam",
        brother: "Qardaşım",
        sister: "Bacım",
        "father-in-law": "Qayınatam",
        husband: "Yoldaşım",
        child: "Övladım",
        grandchild: "Nəvəm",
      };

      if (data.operation === "delete" || data.operation === "create") {
        return transformedValues[value] || value;
      } else {
        return value.map((val) => {
          const valEnd = Object.entries(val)
            .map(([key, value]) => {
              return transformedValues[value] || value;
            })
            .filter(Boolean);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  edType: {
    label: "Təhsil növü",
    renderValue: (value, data) => {
      const transformedValues = {
        bachelor: "Ali təhsil - Bakalavr",
        master: "Ali təhsil - Magistr",
        doctoral: "Ali təhsil - Doktorantura",
        primary: "Ümumi təhsil - İbtidai təhsil",
        generalSecondary: "Ümumi təhsil - Ümumi orta təhsil",
        completeSecondary: "Ümumi təhsil - Tam orta təhsil",
        secondarySpecial: "Orta ixtisas təhsili",
        firstVocational: "İlk peşə - İxtisas təhsili",
        secBasisOfCompleteSec: "Tam orta təhsil bazasında orta ixtisas təhsili",
        secBasisOfGeneralSec:
          "Ümumi orta təhsil bazasında orta ixtisas təhsili",
        incompleteHigherTill1993:
          "Ali təhsil - 1993 cü ilə qədər natamam ali təhsil",
        completeHigherTill1993:
          "Ali təhsil - 1997 ci ilə qədər natamam ali təhsil",
      };
      if (data.operation === "delete" || data.operation === "create") {
        return transformedValues[value];
      } else {
        return value.map((val) => {
          const valEnd = Object.entries(val)
            .map(([key, value]) => transformedValues[value])
            .filter(Boolean);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  faculty: {
    label: "Təhsil sahəsi (fakultə)",
    renderValue: (value, data) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  institution: {
    label: "Təhsil müəssisəsi",
    renderValue: (value, data) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  issuer: {
    label: "Elmi dərəcəni verən müəssisə",
    renderValue: (value, data) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  name: {
    label: "Əməkhaqqına əlavənin səbəbi",
    renderValue: (value, data) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  name1: {
    label: "Elmi dərəcə",
    renderValue: (value, data) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  note: {
    label: "Qeyd",
    renderValue: (value, data) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  date: {
    label: "Tarix",
    renderValue: (value, data) => {
      if (data.operation === "delete" || data.operation === "create") {
        let formatted = value.map((date) => {
          return moment(date).format("DD/MM/YYYY");
        });
        return formatted.join(" -> ");
      } else {
        let formattedDates = value.map((val) => {
          let dates = Object.values(val);
          let formatted = dates.map((date) => {
            return moment(date).format("DD/MM/YYYY");
          });
          return formatted.join(" -> ");
        });
        return formattedDates.join(", ");
      }
    },
  },
  statusType: {
    label: "Sosial Status növü",
    renderValue: (value, data) => {
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  description: {
    label: "Mükafat / Təltif / Tənbehin məzmunu",
    renderValue: (value, data) => {
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  organization: {
    label: "Mükafat / Təltif / Tənbehin təqdim olunduğu müəssisə",
    renderValue: (value, data) => {
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  rType: {
    label: "Tənbeh / Mükafat / Təltif",
    renderValue: (value, data) => {
      const transformedValues = {
        reprimand: "Tənbeh",
        prize: "Mükafat",
        reward: "Təltif",
      };
      if (data.operation === "delete" || data.operation === "create") {
        return transformedValues[value];
      } else {
        return value.map((val) => {
          const valEnd = Object.entries(val)
            .map(([key, value]) => transformedValues[value])
            .filter(Boolean);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  actualAddress: {
    label: "Ünvanı (faktiki)",
    renderValue: (value) => {
      return value.map((val, index) => {
        if (val.address && val.city && val.district) {
          const address = Object.values(val.address).join(" -> ");
          const city = Object.values(val.city).join(" -> ");
          const district = Object.values(val.district).join(" -> ");
          const formattedValue =
            // eslint-disable-next-line no-useless-concat
            "Address:" +
            " " +
            address +
            "<br>" +
            " " +
            "Şəhər:" +
            " " +
            city +
            "<br>" +
            " " +
            "Küçə:" +
            " " +
            district;
          return (
            <span
              key={`${val}/${index}`}
              dangerouslySetInnerHTML={{ __html: formattedValue }}
            />
          );
        } else if (val.address && val.city) {
          const address = Object.values(val.address).join(" -> ");
          const city = Object.values(val.city).join(" -> ");
          const formattedValue =
            // eslint-disable-next-line no-useless-concat
            "Address:" + " " + address + "<br>" + " " + "Şəhər:" + " " + city;
          return (
            <span
              key={`${val.city}/${val.address}${index}`}
              dangerouslySetInnerHTML={{ __html: formattedValue }}
            />
          );
        } else if (val.address && val.district) {
          const address = Object.values(val.address).join(" -> ");
          const district = Object.values(val.district).join(" -> ");
          const formattedValue =
            // eslint-disable-next-line no-useless-concat
            "Address:" +
            " " +
            address +
            "<br>" +
            " " +
            "Küçə:" +
            " " +
            district;
          return (
            <span
              key={`${val.district}/${val.address}${index}`}
              dangerouslySetInnerHTML={{ __html: formattedValue }}
            />
          );
        } else if (val.city && val.district) {
          const city = Object.values(val.city).join(" -> ");
          const district = Object.values(val.district).join(" -> ");
          const formattedValue =
            // eslint-disable-next-line no-useless-concat
            "Şəhər:" + " " + city + "<br>" + " " + "Küçə:" + " " + district;
          return (
            <span
              key={`${val.city}/${val.district}${index}`}
              dangerouslySetInnerHTML={{ __html: formattedValue }}
            />
          );
        } else if (val.address) {
          const address = Object.values(val.address).join(" -> ");
          return <span key={`/${val.address}${index}`}>{address}</span>;
        } else if (val.city) {
          const city = Object.values(val.city).join(" -> ");
          return <span key={`/${val.city}${index}`}>{city}</span>;
        } else if (val.district) {
          const district = Object.values(val.district).join(" -> ");
          return <span key={`/${val.district}${index}`}>{district}</span>;
        }
        return null;
      });
    },
  },
  registeredAddress: {
    label: "Ünvanı (qeydiyyat)",
    renderValue: (value) => {
      return value.map((val, index) => {
        if (val.address && val.city && val.district) {
          const address = Object.values(val.address).join(" -> ");
          const city = Object.values(val.city).join(" -> ");
          const district = Object.values(val.district).join(" -> ");
          const formattedValue =
            // eslint-disable-next-line no-useless-concat
            "Address:" +
            " " +
            address +
            "<br>" +
            " " +
            "Şəhər:" +
            " " +
            city +
            "<br>" +
            " " +
            "Küçə:" +
            " " +
            district;
          return (
            <span
              key={`${val}|?/${index}`}
              dangerouslySetInnerHTML={{ __html: formattedValue }}
            />
          );
        } else if (val.address && val.city) {
          const address = Object.values(val.address).join(" -> ");
          const city = Object.values(val.city).join(" -> ");
          const formattedValue =
            // eslint-disable-next-line no-useless-concat
            "Address:" + " " + address + "<br>" + " " + "Şəhər:" + " " + city;
          return (
            <span
              key={`${val.address}/${val.city}|?/${index}`}
              dangerouslySetInnerHTML={{ __html: formattedValue }}
            />
          );
        } else if (val.address && val.district) {
          const address = Object.values(val.address).join(" -> ");
          const district = Object.values(val.district).join(" -> ");
          const formattedValue =
            // eslint-disable-next-line no-useless-concat
            "Address:" +
            " " +
            address +
            "<br>" +
            " " +
            "Küçə:" +
            " " +
            district;
          return (
            <span
              key={`${val.address}/${val.district}|?/${index}`}
              dangerouslySetInnerHTML={{ __html: formattedValue }}
            />
          );
        } else if (val.city && val.district) {
          const city = Object.values(val.city).join(" -> ");
          const district = Object.values(val.district).join(" -> ");
          const formattedValue =
            // eslint-disable-next-line no-useless-concat
            "Şəhər:" + " " + city + "<br>" + " " + "Küçə:" + " " + district;
          return (
            <span
              key={`${val.district}/${val.city}|?/${index}`}
              dangerouslySetInnerHTML={{ __html: formattedValue }}
            />
          );
        } else if (val.address) {
          const address = Object.values(val.address).join(" -> ");
          return <span key={`${val.address}/|?/${index}`}>{address}</span>;
        } else if (val.city) {
          const city = Object.values(val.city).join(" -> ");
          return <span key={`${val.city}/|?/${index}`}>{city}</span>;
        } else if (val.district) {
          const district = Object.values(val.district).join(" -> ");
          return <span key={`${val.district}/|?/${index}`}>{district}</span>;
        }
        return null;
      });
    },
  },
  email: {
    label: "Email",
    renderValue: (value) => {
      let newEmail;
      let deleteEmail;
      return value[0].changes.map((items, index) => {
        if (items.operation === "update") {
          if (items.data) {
            const emails = Object.values(items.data.data).join(" -> ");
            return (
              <div key={`${items.operation}//${index}`}>
                {emails.split("\n").map((email, index) => (
                  <React.Fragment key={`${email}//${index}`}>
                    {email}
                    <br />
                  </React.Fragment>
                ))}
              </div>
            );
          } else {
            return "Email silinmişdir";
          }
        }
        if (items.operation === "create") {
          if (typeof items.data === "string") {
            newEmail = items.data;
            // eslint-disable-next-line no-useless-concat
            return "Yeni email əlavə olunub:" + " " + newEmail;
          } else {
            newEmail = items.data.data;
            // eslint-disable-next-line no-useless-concat
            return "Yeni email əlavə olunub:" + " " + newEmail;
          }
        }
        if (items.operation === "delete") {
          deleteEmail = items.data;
          // eslint-disable-next-line no-useless-concat
          return "Email silinmişdir:" + " " + deleteEmail;
        }
      });
    },
  },
  mobile: {
    label: "Mobil nömrə",
    renderValue: (value, change) => {
      return value[0].changes.map((items, index) => {
        if (items.operation === "delete") {
          return (
            <div>
              <>{`${items.data.code}${items.data.number}`}</>
              <br />
              <span style={{ fontSize: "14px" }}>Mobil nömrə silinmişdir</span>
            </div>
          );
        } else if (items.operation === "create") {
          return (
            <div>
              <>{`${items.data}`}</>
              <br />
              <span style={{ fontSize: "14px" }}>
                Yeni mobil nömrə əlavə olunub
              </span>
            </div>
          );
        } else if (items.operation === "update") {
          if (items.data) {
            if (items.data.number) {
              const numbers = Object.values(items.data.number).join(" -> +994");
              return (
                <div key={`${numbers}//${index}index`}>
                  {numbers.split("\n").map((number, index) => (
                    <React.Fragment key={`${number}//${index}index`}>
                      {`+994${number}`}
                      <br />
                      <span>Mobil nömrə dəyişdirilib</span>
                    </React.Fragment>
                  ))}
                </div>
              );
            } else {
              const numbers = Object.values(items.data).join(" -> ");
              return (
                <div key={`${numbers}//${index}index`}>
                  {numbers.split("\n").map((number, index) => (
                    <React.Fragment key={`${number}//${index}index`}>
                      {`${number}`}
                      <br />
                      <span>Mobil nömrə dəyişdirilib</span>
                    </React.Fragment>
                  ))}
                </div>
              );
            }
          } else {
            return (
              <span style={{ fontSize: "14px" }}>Mobil nömrə silinmişdir</span>
            );
          }
        } else {
          const numbers = Object.values(items.data.number).join(" -> +994");
          return (
            <div key={`${numbers}//${index}index`}>
              {numbers.split("\n").map((number, index) => (
                <React.Fragment key={`${number}//${index}index`}>
                  {`+994${number}`}
                  <br />
                  <span>Mobil nömrə dəyişdirilib</span>
                </React.Fragment>
              ))}
            </div>
          );
        }
      });
    },
  },
  bankCode: {
    label: "Bank kodu",
    renderValue: (value, data) => {
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  bankName: {
    label: "Bank adı",
    renderValue: (value, data) => {
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  bankVoen: {
    label: "Bank VÖEN",
    renderValue: (value, data) => {
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  accountNumber: {
    label: "Hesab nömrəsi",
    renderValue: (value, data) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  iban: {
    label: "IBAN nömrəsi",
    renderValue: (value, data) => {
      // eslint-disable-next-line no-unused-vars
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  docType: {
    label: "Sənədin növü",
    renderValue: (value, data) => {
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  expirationDate: {
    label: "Sənədin qüvvədən düşmə tarixi (müddətsiz ola bilər)",
    renderValue: (value, data) => {
      if (data.operation === "delete" || data.operation === "create") {
        let formatted = value.map((date) => {
          return moment(date).format("DD/MM/YYYY");
        });
        return formatted.join(" -> ");
      } else {
        let formattedDates = value.map((val) => {
          let dates = Object.values(val);
          let formatted = dates.map((date) => {
            return moment(date).format("DD/MM/YYYY");
          });
          return formatted.join(" -> ");
        });
        return formattedDates.join(", ");
      }
    },
  },
  issuanceDate: {
    label: "Sənədin verilmə tarixi",
    renderValue: (value, data) => {
      if (data.operation === "delete" || data.operation === "create") {
        let formatted = value.map((date) => {
          return moment(date).format("DD/MM/YYYY");
        });
        return formatted.join(" -> ");
      } else {
        let formattedDates = value.map((val) => {
          let dates = Object.values(val);
          let formatted = dates.map((date) => {
            return moment(date).format("DD/MM/YYYY");
          });
          return formatted.join(" -> ");
        });
        return formattedDates.join(", ");
      }
    },
  },
  fileBase64: {
    label: "Şəkil",
    renderValue: (value, data) => {
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
  pic: {
    label: "Şəkil",
    renderValue: (value, data) => {
      let valEnd;
      if (data.operation === "delete" || data.operation === "create") {
        return value.join(" -> ");
      } else {
        return value.map((val) => {
          valEnd = Object.values(val);
          return valEnd.join(" -> ");
        });
      }
    },
  },
};
export const headObj = {
  id: {
    label: "Sorğunu növü",
    renderValue: (value) => {
      return `Əməkdaşın məlumat dəyişikliyi`;
    },
  },
  firstname: {
    label: "Sorğunu yaradanın A.S.A.",
    renderValue: (value) => {
      return `${value.firstname} ${value.lastname} ${value.fathername}`;
    },
  },
  createdAt: {
    label: "Sorğunu yaradılma tarixi",
    renderValue: (value) => {
      return moment(value.createdAt).format("DD/MM/YYYY");
    },
  },
};
