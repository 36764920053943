import React, { useEffect, useState } from "react";
import {
  Field,
  FieldArray,
  ErrorMessage,
  useFormikContext,
  Formik,
  Form,
} from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Button,
  Checkbox,
  FormHelperText,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { getCommonData } from "../../api/api";
import { isValid, parse } from "date-fns";

const educationType = [
  { value: "primary", name: "Ümumi təhsil - İbtidati təhsil" },
  { value: "bachelor", name: "Ali təhsil - bakalavriat" },
  { value: "master", name: "Ali təhsil - magistratura" },
  { value: "doctoral", name: "Ali təhsil - doktorantura" },
  { value: "generalSecondary", name: "Ümumi təhsil - ümumi orta təhsil" },
  { value: "completeSecondary", name: "Ümumi təhsil - tam orta təhsil" },
  { value: "firstVocational", name: "İlk peşə-ixtisas təhsili" },
  { value: "secondarySpecial", name: "Orta ixtisas təhsili" },
  {
    value: "secBasisOfGeneralSec",
    name: "Ümumi orta təhsil bazasında orta ixtisas təhsili",
  },
  {
    value: "secBasisOfCompleteSec",
    name: "Tam orta təhsil bazasında orta ixtisas təhsili",
  },
  {
    value: "incompleteHigherTill1993",
    name: "Ali təhsil - 1993 cü ilə qədər natamam ali təhsil",
  },
  {
    value: "completeHigherTill1993",
    name: "Ali təhsil - 1997 ci ilə qədər tam ali təhsil",
  },
];

const ViewEducationInfo = ({ employeeById }) => {
  const [universities, setUniversities] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const initialValues = {
    education: employeeById.education || [],
  };
  useEffect(() => {
    const initializeData = async () => {
      for (const education of initialValues.education) {
        const [keyword, hasFaculties] = foundKey(education.edType);
        await getData([keyword, hasFaculties]);
      }
    };

    initializeData();
  }, [initialValues.education]);

  const getData = async (key) => {
    const [keyword, hasFaculties] = key;

    const { data } = await getCommonData(keyword);
    setUniversities(data);

    if (hasFaculties) {
      const facultyResponse = await getCommonData("facultyList");
      setFaculties(facultyResponse.data);
    } else {
      setFaculties([]);
    }
  };
  const foundKey = (field) => {
    let keyword;
    let hasFaculties = false;
    switch (field) {
      case "bachelor":
      case "incompleteHigherTill1993":
      case "completeHigherTill1993":
      case "master":
      case "doctoral":
        keyword = "universityList";
        hasFaculties = true;
        break;
      case "primary":
      case "generalSecondary":
      case "completeSecondary":
        keyword = "schoolList";
        hasFaculties = false;
        break;
      case "firstVocational":
      case "secondarySpecial":
        keyword = "vocationalSchoolList";
        hasFaculties = true;
        break;
      case "secBasisOfGeneralSec":
      case "secBasisOfCompleteSec":
        keyword = "otherInstitutions";
        hasFaculties = false;
        break;
      default:
        keyword = "";
        hasFaculties = false;
        break;
    }

    return [keyword, hasFaculties];
  };

  return (
    <>
      {employeeById ? (
        <Formik
          initialValues={{
            education: initialValues.education || [], // Set the initial laborActivity values from employeeById
          }}
        >
          {({
            values,
            isValid,
            errors,
            touched,
            isSubmitting,
            handleChange,
          }) => (
            <Form style={{ pointerEvents: "none" }}>
              <FieldArray name="education">
                {({ push, remove }) => (
                  <div>
                    {values.education.map((form, index) => (
                      <div key={index}>
                        <>
                          <Typography sx={{ mt: 5 }} variant="h6">
                            {index + 1})Təhsil məlumatları
                          </Typography>

                          {/* Education type and institution */}
                          <Grid sx={{ display: "flex", gap: 3, mb: 3, mt: 5 }}>
                            <Grid sx={{ width: "100%" }}>
                              <FormControl
                                error={
                                  errors?.education?.[index]?.edType &&
                                  Boolean(errors?.education?.[index]?.edType)
                                }
                                fullWidth
                              >
                                <InputLabel id={`education[${index}].edType`}>
                                  Təhsil növü
                                </InputLabel>
                                <Field
                                  as={Select}
                                  required
                                  name={`education[${index}].edType`}
                                  labelId={`reducation[${index}].edType`}
                                  label="Təhsil növü"
                                  id={`education[${index}].edType`}
                                  defaultValue=""
                                  fullWidth
                                  error={
                                    errors?.education?.[index]?.edType &&
                                    Boolean(errors?.education?.[index]?.edType)
                                  }
                                >
                                  {educationType.map((item, index) => (
                                    <MenuItem
                                      key={index}
                                      value={
                                        employeeById?.education?.[index]?.edType
                                      }
                                    >
                                      {item.name}
                                    </MenuItem>
                                  ))}
                                </Field>
                                {errors.education?.[index]?.edType &&
                                  errors.education?.[index]?.edType && (
                                    <FormHelperText error>
                                      {errors.education?.[index]?.edType}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Grid>
                            <Grid sx={{ width: "100%" }}>
                              <FormControl
                                error={
                                  errors?.education?.[index]?.institution &&
                                  Boolean(
                                    errors?.education?.[index]?.institution
                                  )
                                }
                                fullWidth
                              >
                                <InputLabel
                                  id={`education[${index}].institution`}
                                >
                                  Təhsil müəssisəsi
                                </InputLabel>
                                <Field
                                  as={Select}
                                  name={`education[${index}].institution`}
                                  labelId={`education[${index}].institution`}
                                  label="Təhsil müəssisəsi"
                                  id={`education[${index}].institution`}
                                  defaultValue=""
                                  fullWidth
                                  error={
                                    errors?.education?.[index]?.institution &&
                                    Boolean(
                                      errors?.education?.[index]?.institution
                                    )
                                  }
                                >
                                  <MenuItem
                                    selected
                                    key={index}
                                    value={
                                      employeeById?.education[index]
                                        ?.institution
                                    }
                                  >
                                    {
                                      employeeById?.education[index]
                                        ?.institution
                                    }
                                  </MenuItem>
                                </Field>
                                {errors.education?.[index]?.institution &&
                                  errors.education?.[index]?.institution && (
                                    <FormHelperText error>
                                      {errors.education?.[index]?.institution}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Grid>
                          </Grid>

                          {/* education[${index}] field */}

                          {!(
                            values.education[index].edType === "primary" ||
                            values.education[index].edType ===
                              "completeSecondary" ||
                            values.education[index].edType ===
                              "generalSecondary"
                          ) && (
                            <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                              <Grid sx={{ width: "100%" }}>
                                <FormControl
                                  error={
                                    errors?.education?.[index]?.faculty &&
                                    Boolean(errors?.education?.[index]?.faculty)
                                  }
                                  fullWidth
                                >
                                  <InputLabel
                                    id={`education[${index}].faculty`}
                                  >
                                    Təhsil sahəsi (fakultə)
                                  </InputLabel>
                                  <Field
                                    as={Select}
                                    name={`education[${index}].faculty`}
                                    labelId={`education[${index}].faculty`}
                                    label="Təhsil sahəsi (fakultə)"
                                    id={`education[${index}].faculty`}
                                    defaultValue=""
                                    fullWidth
                                    error={
                                      errors?.education?.[index]?.faculty &&
                                      Boolean(
                                        errors?.education?.[index]?.faculty
                                      )
                                    }
                                  >
                                    <MenuItem
                                      selected
                                      key={index}
                                      value={
                                        employeeById?.education[index]?.faculty
                                      }
                                    >
                                      {employeeById?.education[index]?.faculty}
                                    </MenuItem>
                                  </Field>
                                  {errors.education?.[index]?.faculty &&
                                    errors.education?.[index]?.faculty && (
                                      <FormHelperText error>
                                        {errors.education?.[index]?.faculty}
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                            </Grid>
                          )}

                          {/*start date and end date */}
                          <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                            <Grid sx={{ width: "100%" }}>
                              <Field name={`education[${index}].startDate`}>
                                {({ field, form }) => {
                                  const selectedEndDate =
                                    form.values.education[index]?.endDate;

                                  return (
                                    <DatePicker
                                      {...field}
                                      label="Təhsilin başlama tarixi"
                                      inputFormat="dd/MM/yyyy"
                                      value={
                                        employeeById?.education?.[index]
                                          ?.startDate
                                      }
                                      maxDate={
                                        selectedEndDate
                                          ? new Date(selectedEndDate)
                                          : undefined
                                      }
                                      minDate={new Date(1940, 0, 1)}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          helperText={
                                            form.errors?.education?.[index]
                                              ?.startDate
                                          }
                                          error={Boolean(
                                            form.errors?.education?.[index]
                                              ?.startDate
                                          )}
                                          fullWidth
                                        />
                                      )}
                                    />
                                  );
                                }}
                              </Field>
                            </Grid>
                            {!employeeById?.education?.[index]
                              ?.stillStudying && (
                              <Grid sx={{ width: "100%" }}>
                                <Field name={`education[${index}].endDate`}>
                                  {({ field, form }) => {
                                    const selectedStartDate =
                                      form.values.education[index]?.startDate;

                                    return (
                                      <DatePicker
                                        {...field}
                                        label="Təhsilin bitmə tarixi"
                                        inputFormat="dd/MM/yyyy"
                                        value={
                                          employeeById?.education?.[index]
                                            ?.endDate
                                        }
                                        minDate={
                                          selectedStartDate
                                            ? new Date(selectedStartDate)
                                            : undefined
                                        }
                                        onChange={(date) => {
                                          form.setFieldValue(
                                            `education[${index}].endDate`,
                                            date
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            helperText={
                                              form.errors?.education?.[index]
                                                ?.endDate
                                            }
                                            error={Boolean(
                                              form.errors?.education?.[index]
                                                ?.endDate
                                            )}
                                            fullWidth
                                          />
                                        )}
                                      />
                                    );
                                  }}
                                </Field>
                              </Grid>
                            )}
                          </Grid>
                          <Grid>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    values.education[index]?.stillStudying
                                  }
                                  color="primary"
                                  name={`education[${index}].stillStudying`}
                                />
                              }
                              label="Təhsil davam edir"
                            />
                          </Grid>
                        </>
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default ViewEducationInfo;
