import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";
import { handleOtp } from "../../services/otp/OtpServices";
import { RemoveRedEye, VisibilityOff } from "@mui/icons-material";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function GetOtp({ step, setStep }) {
  const [signature, setSignature] = useState("");
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate();
  const { getOtp } = useAuth();
  const errorObj = {
    "Provide valid user details":
      "Etibarlı istifadəçi məlumatlarını təqdim edin",
    "User not found": "İstifadəçi tapılmadı",
    "Please provide a valid OTP code":
      "Zəhmət olmasa etibarlı OTP kodu təqdim edin",
    "No valid OTP code found": "Etibarlı OTP kodu tapılmadı",
    "Maximum OTP attempts exceeded": "Maksimum OTP cəhdlərinin sayı aşıldı",
    "OTP code has expired":
      "OTP kodunun vaxtı bitmişdir.Zəhmət olmasa yeni OTP kodu əldə edin",
    "Invalid OTP code": "Yanlış OTP kodu",
    "OTP code has already been used": "OTP kodu artıq istifadə edilmişdir",
    "Please provide a new password ": "Zəhmət olmasa yeni parol təqdim edin",
    "Invalid OTP signature": "Yanlış OTP imzası",
  };

  return (
    <Formik
      initialValues={{
        email: "",
        otp: "",
        newPassword: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email:
          step === 0
            ? Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required")
            : undefined,
        otp: step === 1 ? Yup.string().required("OTP is required") : undefined,
        newPassword:
          step === 2
            ? Yup.string().required("New password is required")
            : undefined,
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        if (step === 0) {
          const getOtpData = {
            email: values.email,
            type: "forgotPassword",
          };
          try {
            getOtp(values.email);
            const res = await handleOtp("otp-request", getOtpData);
            if (res.status === 201) {
              toast.success(
                "Təsdiq kodu e-poçt ünvanına uğurla göndərilmişdir!",
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                }
              );
              setStep(1);
            }
          } catch (error) {
            const errorMessage =
              error.response?.data?.errors[0]?.message || error.message;
            const uiErrorMessage =
              errorObj[errorMessage] || "Something went wrong";
            toast.error(uiErrorMessage, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            setStatus({ success: false });
            setErrors({ submit: errorMessage });
            setSubmitting(false);
          }
        } else if (step === 1) {
          const verifyOtpData = {
            email: values.email,
            type: "forgotPassword",
            otp: values.otp.toString(),
          };
          try {
            const res = await handleOtp("otp-verify", verifyOtpData);
            if (res.status === 200) {
              toast.success("OTP kodu uğurla təsdiqlənmişdir!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              setSignature(res.data.signature);
              setStep(2);
            }
          } catch (error) {
            const errorMessage =
              error.response?.data?.errors[0]?.message || error.message;
            const uiErrorMessage =
              errorObj[errorMessage] || "Something went wrong";
            toast.error(uiErrorMessage, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            setStatus({ success: false });
            setErrors({ submit: errorMessage });
            setSubmitting(false);
          }
        } else if (step === 2) {
          const resetPasswordData = {
            email: values.email,
            signature: signature,
            newPassword: values.newPassword.toString(),
          };
          try {
            const res = await handleOtp("reset-password", resetPasswordData);
            if (res.status === 202) {
              toast.success("Şifrə uğurla yenilənmişdir!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              navigate("/auth/sign-in");
              setStep(0);
            }
          } catch (error) {
            const errorMessage =
              error.response?.data?.errors[0]?.message || error.message;
            const uiErrorMessage =
              errorObj[errorMessage] || "Something went wrong";
            toast.error(uiErrorMessage, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            setStatus({ success: false });
            setErrors({ submit: errorMessage });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <>
          <form noValidate onSubmit={handleSubmit}>
            {step === 0 && (
              <TextField
                type="email"
                name="email"
                label="E-poçt ünvanı"
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
            )}
            {step === 1 && (
              <TextField
                type="number"
                name="otp"
                label="OTP"
                value={values.otp}
                error={Boolean(touched.otp && errors.otp)}
                fullWidth
                helperText={touched.otp && errors.otp}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
            )}
            {step === 2 && (
              <div style={{ position: "relative" }}>
                <TextField
                  type={showPass ? "text" : "password"}
                  name="newPassword"
                  label="Yeni Şifrə"
                  value={values.newPassword}
                  error={Boolean(touched.newPassword && errors.newPassword)}
                  fullWidth
                  helperText={touched.newPassword && errors.newPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
                <IconButton
                  sx={{ position: "absolute", top: "22%", right: "2%" }}
                  onClick={() => setShowPass((prev) => !prev)}
                >
                  {!showPass ? <RemoveRedEye /> : <VisibilityOff />}
                </IconButton>
              </div>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              {step === 0 && "Təsdiq kodu al"}
              {step === 1 && "OTP Təsdiqlə"}
              {step === 2 && "Şifrəni Yenilə"}
            </Button>
          </form>
        </>
      )}
    </Formik>
  );
}

export default GetOtp;
