import {
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfDay } from "date-fns";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  getAllStructures,
  getCommonData,
  getPositions,
  getTopParts,
  updateEmployee,
} from "../../api/api";
import { removeEmptyStrings } from "../../utils/removeEmptyString";
import { editLaborActivity } from "../employees/Validations";
import CustomAccordion from "../ui/Accordion/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const today = endOfDay(new Date());

function EditLaborActivity({
  employeeById,
  submitted,
  setSubmitted,
  setOpenAddDialog,
  setSelectedType,
}) {
  const [organizations, setOrganizations] = useState([]);
  const [positions, setPositions] = useState([]);
  const [otherStructures, setOtherStructures] = useState([]);
  const [mainOrganizations, setMainOrganizations] = useState([]);
  const [mainStructures, setMainStructures] = useState([]);
  const [pastData, setPastData] = useState([]);
  const [mainPositions, setMainPositions] = useState([]);
  const [structuresIds, setStructuresIds] = useState([]);
  const [isDate, setIsDate] = useState(false);
  // Params for main structures api
  const [structuresParams, setStructuresParams] = useState({
    offset: 0,
    limit: 9999,
    structureId: "",
  });

  // Handle Submit
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    // Handle form submission
    let modifiedValues = {
      // ...values,
      laborActivity:
        values.laborActivity &&
        values.laborActivity.map((activity) => ({
          ...activity,
          isCivilServant: activity?.isCivilServant
            ? activity?.isCivilServant
            : false,
          startDate: activity.startDate
            ? moment(activity.startDate).format("MM/DD/yyyy")
            : null,
          endDate: activity.endDate
            ? moment(activity.endDate).format("MM/DD/yyyy")
            : null,
        })),
    };
    modifiedValues = removeEmptyStrings(modifiedValues);
    // Handle form submission
    await updateEmployee(employeeById._id, modifiedValues);
    setIsDate(false);
    setSubmitting(false);
    setSubmitted(!submitted);
    resetForm({ values });
  };

  useEffect(() => {
    const handleApi = async () => {
      try {
        const organizationsResponse = await getCommonData("organizations");
        const positionResponse = await getCommonData("positions");
        const otherStructuresResponse = await getCommonData("structures");
        const mainOrganizationsResponse = await getAllStructures();

        const otherStructures = otherStructuresResponse?.data || [];
        const organizations = organizationsResponse?.data || [];
        const position = positionResponse?.data || [];
        setOtherStructures(otherStructures);
        setOrganizations(organizations);
        setPositions(position);
        setMainOrganizations(mainOrganizationsResponse.data?.structures);
        const combinedData = [];
        if (otherStructures.length) {
          combinedData.push({ otherStructures: [...otherStructures] });
        }

        if (organizations.length) {
          combinedData.push({ organizations: [...organizations] });
        }

        if (position.length) {
          combinedData.push({ position: [...position] });
        }

        handlePastData(combinedData);
      } catch (error) {
        console.log("err", error);
      }
    };
    handleApi();
  }, [submitted]);

  const getOrganizationsStructure = () => {
    for (let i = 0; i < employeeById.laborActivity.length; i++) {
      if (employeeById.laborActivity[i].sameWorkplace) {
        const organizationName = mainOrganizations.find(
          (item) =>
            item.organization.name ===
            employeeById.laborActivity[i].organizationName
        );
        setStructuresIds((prevItems) => {
          const updatedItems = [...prevItems];
          updatedItems[i] = organizationName;
          return updatedItems;
        });
      }
    }
  };

  const getStructuresAndPositions = async () => {
    try {
      if (structuresIds) {
        for (let index = 0; index < structuresIds.length; index++) {
          try {
            const value = structuresIds[index];
            const payload = {
              offset: 0,
              limit: 9999,
              structureId: value,
            };

            const response = await getPositions(payload);
            const mainStructuresResponse = await getTopParts(payload);

            setMainPositions((prevItems) => {
              const updatedItems = [...prevItems];
              updatedItems[index] = response?.data?.parts;
              return updatedItems;
            });
            setMainStructures((prevItems) => {
              const updatedItems = [...prevItems];
              updatedItems[index] = mainStructuresResponse?.data?.parts;
              return updatedItems;
            });
          } catch (error) {
            console.log(error);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  function handlePastData(data) {
    setPastData(data);
  }

  useEffect(() => {
    getStructuresAndPositions();
  }, [structuresIds]);

  const initialValues = {
    laborActivity: employeeById.laborActivity || [],
  };
  function handlePush(index) {
    otherStructures &&
      otherStructures.push(employeeById?.laborActivity[index]?.department);
    organizations &&
      organizations.push(employeeById?.laborActivity[index]?.organizationName);
    positions && positions.push(employeeById?.laborActivity[index]?.position);
  }
  function handleHidden(name, data) {
    return pastData.every((past) => !past[name]?.includes(data));
  }
  return (
    <CustomAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h6>Əmək fəaliyyəti</h6>
      </AccordionSummary>
      <AccordionDetails>
        {employeeById ? (
          <Formik
            initialValues={{
              laborActivity: initialValues.laborActivity || [],
            }}
            validationSchema={editLaborActivity}
            onSubmit={handleSubmit}
          >
            {({
              values,
              isValid,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleChange,
              dirty,
            }) => (
              <>
                <Form>
                  <FieldArray name="laborActivity">
                    {({ push, remove }) => (
                      <div>
                        {values.laborActivity &&
                          values.laborActivity.map((form, index) => {
                            handlePush(index);
                            return (
                              <div key={index}>
                                <>
                                  <Typography mt={3} mb={3} variant="h6">
                                    {index + 1})Əmək fəaliyyəti
                                  </Typography>

                                  {/* Organization name and department */}
                                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                    <Grid sx={{ width: "100%" }}>
                                      {values.laborActivity[index]
                                        ?.sameWorkplace ? (
                                        <FormControl
                                          error={
                                            // isSubmitting &&
                                            touched?.laborActivity?.[index]
                                              ?.organizationName &&
                                            errors?.laborActivity?.[index]
                                              ?.organizationName &&
                                            Boolean(
                                              errors?.laborActivity?.[index]
                                                ?.organizationName
                                            )
                                          }
                                          fullWidth
                                        >
                                          <InputLabel
                                            id={`laborActivity[${index}].organizationName`}
                                          >
                                            Təşkilatın adı *
                                          </InputLabel>
                                          <Field
                                            as={Select}
                                            name={`laborActivity[${index}].organizationName`}
                                            labelId={`laborActivity[${index}].organizationName`}
                                            label="Təşkilatın adı *"
                                            id={`laborActivity[${index}].organizationName`}
                                            error={
                                              // isSubmitting &&
                                              touched?.laborActivity?.[index]
                                                ?.organizationName &&
                                              errors?.laborActivity?.[index]
                                                ?.organizationName &&
                                              Boolean(
                                                errors?.laborActivity?.[index]
                                                  ?.organizationName
                                              )
                                            }
                                            fullWidth
                                          >
                                            <MenuItem hidden>
                                              {
                                                values.laborActivity[index]
                                                  .organizationName
                                              }
                                            </MenuItem>
                                            {mainOrganizations &&
                                              mainOrganizations
                                                .sort((a, b) =>
                                                  a?.organization?.name.localeCompare(
                                                    b?.organization?.name,
                                                    "az"
                                                  )
                                                )
                                                .reduce((acc, item) => {
                                                  if (
                                                    !acc.some(
                                                      (i) =>
                                                        i?.organization
                                                          ?.name ===
                                                        item?.organization?.name
                                                    )
                                                  ) {
                                                    acc.push(item);
                                                  }
                                                  return acc;
                                                }, [])
                                                .map((item) => (
                                                  <MenuItem
                                                    key={item._id}
                                                    value={
                                                      item?.organization?.name
                                                    }
                                                    onClick={() => {
                                                      const updatedStructureIds =
                                                        [...structuresIds];

                                                      updatedStructureIds[
                                                        index
                                                      ] = item._id;

                                                      setStructuresIds(
                                                        updatedStructureIds
                                                      );
                                                      setStructuresParams(
                                                        (prevValues) => ({
                                                          ...prevValues,
                                                          structureId: item._id,
                                                        })
                                                      );
                                                    }}
                                                  >
                                                    {item?.organization?.name}
                                                  </MenuItem>
                                                ))}
                                            <Button
                                              sx={{
                                                mt: 3,
                                                ml: 3,
                                              }}
                                              variant="outlined"
                                              onClick={() => {
                                                setOpenAddDialog(true);
                                                setSelectedType([
                                                  "organizations",
                                                  { name: "Təşkilatlar" },
                                                ]);
                                              }}
                                            >
                                              Yeni təşkilat əlavə et
                                            </Button>
                                          </Field>
                                          {touched?.laborActivity?.[index]
                                            ?.organizationName &&
                                            errors?.laborActivity?.[index]
                                              ?.organizationName &&
                                            errors?.laborActivity?.[index]
                                              ?.organizationName && (
                                              <FormHelperText error>
                                                {
                                                  errors.laborActivity?.[index]
                                                    ?.organizationName
                                                }
                                              </FormHelperText>
                                            )}
                                        </FormControl>
                                      ) : (
                                        <FormControl
                                          error={
                                            touched?.laborActivity?.[index]
                                              ?.organizationName &&
                                            errors?.laborActivity?.[index]
                                              ?.organizationName &&
                                            Boolean(
                                              errors?.laborActivity?.[index]
                                                ?.organizationName
                                            )
                                          }
                                          fullWidth
                                        >
                                          <InputLabel
                                            id={`laborActivity[${index}].organizationName`}
                                          >
                                            Təşkilatın adı
                                          </InputLabel>
                                          <Field
                                            as={Select}
                                            name={`laborActivity[${index}].organizationName`}
                                            labelId={`laborActivity[${index}].organizationName`}
                                            label="Təşkilatın adı"
                                            id={`laborActivity[${index}].organizationName`}
                                            // defaultValue=""
                                            error={
                                              touched?.laborActivity?.[index]
                                                ?.organizationName &&
                                              errors?.laborActivity?.[index]
                                                ?.organizationName &&
                                              Boolean(
                                                errors?.laborActivity?.[index]
                                                  ?.organizationName
                                              )
                                            }
                                            fullWidth
                                          >
                                            {organizations &&
                                              [...new Set(organizations)]
                                                .sort((a, b) =>
                                                  a.localeCompare(b, "az")
                                                )
                                                .map((item, index) => (
                                                  <MenuItem
                                                    hidden={handleHidden(
                                                      "organizations",
                                                      item
                                                    )}
                                                    value={item}
                                                  >
                                                    {item}
                                                  </MenuItem>
                                                ))}
                                            <Button
                                              sx={{
                                                mt: 3,
                                                ml: 3,
                                              }}
                                              variant="outlined"
                                              onClick={() => {
                                                setOpenAddDialog(true);
                                                setSelectedType([
                                                  "organizations",
                                                  { name: "Təşkilatlar" },
                                                ]);
                                              }}
                                            >
                                              Yeni təşkilat əlavə et
                                            </Button>
                                          </Field>
                                          {errors.laborActivity?.[index]
                                            ?.organizationName &&
                                            touched?.laborActivity?.[index]
                                              ?.organizationName &&
                                            errors.laborActivity?.[index]
                                              ?.organizationName && (
                                              <FormHelperText error>
                                                {
                                                  errors.laborActivity?.[index]
                                                    ?.organizationName
                                                }
                                              </FormHelperText>
                                            )}
                                        </FormControl>
                                      )}
                                    </Grid>

                                    <Grid sx={{ width: "100%" }}>
                                      {!values.laborActivity[index]
                                        ?.sameWorkplace ? (
                                        <FormControl
                                          error={
                                            errors?.laborActivity?.[index]
                                              ?.department &&
                                            touched?.laborActivity?.[index]
                                              ?.department &&
                                            Boolean(
                                              errors?.laborActivity?.[index]
                                                ?.department
                                            )
                                          }
                                          fullWidth
                                        >
                                          <InputLabel
                                            id={`laborActivity[${index}].department`}
                                          >
                                            Struktur bölmə *
                                          </InputLabel>
                                          <Field
                                            as={Select}
                                            name={`laborActivity[${index}].department`}
                                            labelId={`laborActivity[${index}].department`}
                                            label="Struktur bölmə *"
                                            id={`laborActivity[${index}].department`}
                                            defaultValue=""
                                            error={
                                              errors?.laborActivity?.[index]
                                                ?.department &&
                                              touched?.laborActivity?.[index]
                                                ?.department &&
                                              Boolean(
                                                errors?.laborActivity?.[index]
                                                  ?.department
                                              )
                                            }
                                            fullWidth
                                          >
                                            {/* <MenuItem
                                          selected
                                          value={
                                            employeeById?.laborActivity[index]
                                              ?.department
                                          }
                                        >
                                          {
                                            employeeById?.laborActivity[index]
                                              ?.department
                                          }
                                        </MenuItem> */}

                                            {otherStructures &&
                                              [
                                                ...new Set(
                                                  otherStructures.filter(
                                                    Boolean
                                                  )
                                                ),
                                              ]
                                                .sort((a, b) =>
                                                  a.localeCompare(b, "az")
                                                )
                                                .map((item, index) => (
                                                  <MenuItem
                                                    hidden={handleHidden(
                                                      "otherStructures",
                                                      item
                                                    )}
                                                    key={index}
                                                    value={item}
                                                  >
                                                    {item}
                                                  </MenuItem>
                                                ))}
                                            <Button
                                              sx={{
                                                mt: 3,
                                                ml: 3,
                                              }}
                                              variant="outlined"
                                              onClick={() => {
                                                setOpenAddDialog(true);
                                                setSelectedType([
                                                  "structures",
                                                  { name: "Strukturlar" },
                                                ]);
                                              }}
                                            >
                                              Yeni struktur bölmə əlavə et
                                            </Button>
                                          </Field>
                                          {errors.laborActivity?.[index]
                                            ?.department &&
                                            touched?.laborActivity?.[index]
                                              ?.department &&
                                            errors.laborActivity?.[index]
                                              ?.department && (
                                              <FormHelperText error>
                                                {
                                                  errors.laborActivity?.[index]
                                                    ?.department
                                                }
                                              </FormHelperText>
                                            )}
                                        </FormControl>
                                      ) : (
                                        <FormControl
                                          error={
                                            errors?.laborActivity?.[index]
                                              ?.department &&
                                            touched?.laborActivity?.[index]
                                              ?.department &&
                                            Boolean(
                                              errors?.laborActivity?.[index]
                                                ?.department
                                            )
                                          }
                                          fullWidth
                                        >
                                          <InputLabel
                                            id={`laborActivity[${index}].department`}
                                          >
                                            {mainStructures[index]?.length === 0
                                              ? "Göstəriləcək struktur bölmə yoxdur *"
                                              : "Struktur bölmə *"}
                                          </InputLabel>
                                          <Field
                                            as={Select}
                                            name={`laborActivity[${index}].department`}
                                            labelId={`laborActivity[${index}].department`}
                                            label={
                                              mainStructures[index]?.length ===
                                              0
                                                ? "Göstəriləcək struktur bölmə yoxdur *"
                                                : "Struktur bölmə *"
                                            }
                                            id={`laborActivity[${index}].department`}
                                            defaultValue=""
                                            disabled={
                                              mainStructures[index]?.length ===
                                              0
                                            }
                                            error={
                                              errors?.laborActivity?.[index]
                                                ?.department &&
                                              touched?.laborActivity?.[index]
                                                ?.department &&
                                              Boolean(
                                                errors?.laborActivity?.[index]
                                                  ?.department
                                              )
                                            }
                                            fullWidth
                                          >
                                            <MenuItem
                                              selected
                                              hidden
                                              value={
                                                employeeById?.laborActivity[
                                                  index
                                                ]?.department
                                              }
                                            >
                                              {
                                                employeeById?.laborActivity[
                                                  index
                                                ]?.department
                                              }
                                            </MenuItem>
                                            {mainStructures[index] &&
                                              mainStructures[index]
                                                .sort((a, b) =>
                                                  a?.source?.name.localeCompare(
                                                    b?.source?.name,
                                                    "az"
                                                  )
                                                )
                                                .reduce((acc, item) => {
                                                  if (
                                                    !acc.some(
                                                      (i) =>
                                                        i?.source?.name ===
                                                        item?.source?.name
                                                    )
                                                  ) {
                                                    acc.push(item);
                                                  }
                                                  return acc;
                                                }, [])
                                                .map((item, index) => (
                                                  <MenuItem
                                                    key={index}
                                                    value={item?.source?.name}
                                                  >
                                                    {item?.source?.name}
                                                  </MenuItem>
                                                ))}
                                            <Button
                                              sx={{
                                                mt: 3,
                                                ml: 3,
                                              }}
                                              variant="outlined"
                                              onClick={() => {
                                                setOpenAddDialog(true);
                                                setSelectedType([
                                                  "structures",
                                                  { name: "Strukturlar" },
                                                ]);
                                              }}
                                            >
                                              Yeni struktur bölmə əlavə et
                                            </Button>
                                          </Field>
                                          {errors.laborActivity?.[index]
                                            ?.department &&
                                            touched?.laborActivity?.[index]
                                              ?.department &&
                                            errors.laborActivity?.[index]
                                              ?.department && (
                                              <FormHelperText error>
                                                {
                                                  errors.laborActivity?.[index]
                                                    ?.department
                                                }
                                              </FormHelperText>
                                            )}
                                        </FormControl>
                                      )}
                                    </Grid>
                                  </Grid>

                                  {/* Position name and Civil Servant */}
                                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                    <Grid sx={{ width: "100%" }}>
                                      {!values.laborActivity[index]
                                        ?.sameWorkplace ? (
                                        <FormControl
                                          error={
                                            errors?.laborActivity?.[index]
                                              ?.position &&
                                            touched?.laborActivity?.[index]
                                              ?.position &&
                                            Boolean(
                                              errors?.laborActivity?.[index]
                                                ?.position
                                            )
                                          }
                                          fullWidth
                                        >
                                          <InputLabel
                                            id={`laborActivity[${index}].position`}
                                          >
                                            Vəzifə *
                                          </InputLabel>
                                          <Field
                                            as={Select}
                                            name={`laborActivity[${index}].position`}
                                            labelId={`laborActivity[${index}].position`}
                                            label="Vəzifə *"
                                            id={`laborActivity[${index}].position`}
                                            defaultValue=""
                                            fullWidth
                                            error={
                                              errors?.laborActivity?.[index]
                                                ?.position &&
                                              touched?.laborActivity?.[index]
                                                ?.position &&
                                              Boolean(
                                                errors?.laborActivity?.[index]
                                                  ?.position
                                              )
                                            }
                                          >
                                            <MenuItem
                                              hidden
                                              selected
                                              value={
                                                employeeById?.laborActivity[
                                                  index
                                                ]?.position
                                              }
                                            >
                                              {
                                                employeeById?.laborActivity[
                                                  index
                                                ]?.position
                                              }
                                            </MenuItem>
                                            {positions &&
                                              [...new Set(positions)]
                                                .sort((a, b) =>
                                                  a.localeCompare(b, "az")
                                                )
                                                .map((item, index) => (
                                                  <MenuItem
                                                    hidden={handleHidden(
                                                      "position",
                                                      item
                                                    )}
                                                    value={item}
                                                  >
                                                    {item}
                                                  </MenuItem>
                                                ))}
                                            <Button
                                              sx={{
                                                mt: 3,
                                                ml: 3,
                                              }}
                                              variant="outlined"
                                              onClick={() => {
                                                setOpenAddDialog(true);
                                                setSelectedType([
                                                  "positions",
                                                  { name: "Vəzifələr" },
                                                ]);
                                              }}
                                            >
                                              Yeni vəzifə əlavə et
                                            </Button>
                                          </Field>
                                          {errors.laborActivity?.[index]
                                            ?.position &&
                                            touched?.laborActivity?.[index]
                                              ?.position &&
                                            errors.laborActivity?.[index]
                                              ?.position && (
                                              <FormHelperText error>
                                                {
                                                  errors.laborActivity?.[index]
                                                    ?.position
                                                }
                                              </FormHelperText>
                                            )}
                                        </FormControl>
                                      ) : (
                                        <FormControl
                                          error={
                                            errors?.laborActivity?.[index]
                                              ?.position &&
                                            touched?.laborActivity?.[index]
                                              ?.position &&
                                            Boolean(
                                              errors?.laborActivity?.[index]
                                                ?.position
                                            )
                                          }
                                          fullWidth
                                        >
                                          <InputLabel
                                            id={`laborActivity[${index}].position`}
                                          >
                                            {mainPositions[index]?.length === 0
                                              ? "Göstəriləcək vəzifə yoxdur *"
                                              : "Vəzifə *"}
                                          </InputLabel>
                                          <Field
                                            as={Select}
                                            name={`laborActivity[${index}].position`}
                                            labelId={`laborActivity[${index}].position`}
                                            label="Vəzifə *"
                                            id={`laborActivity[${index}].position`}
                                            disabled={
                                              mainPositions[index]?.length ===
                                                0 &&
                                              !values.laborActivity[index]
                                                .position
                                            }
                                            defaultValue=""
                                            fullWidth
                                            error={
                                              errors?.laborActivity?.[index]
                                                ?.position &&
                                              touched?.laborActivity?.[index]
                                                ?.position &&
                                              Boolean(
                                                errors?.laborActivity?.[index]
                                                  ?.position
                                              )
                                            }
                                          >
                                            <MenuItem
                                              hidden
                                              selected
                                              value={
                                                employeeById?.laborActivity[
                                                  index
                                                ]?.position
                                              }
                                            >
                                              {
                                                employeeById?.laborActivity[
                                                  index
                                                ]?.position
                                              }
                                            </MenuItem>
                                            {mainPositions[index] &&
                                              [...new Set(mainPositions[index])]
                                                .sort((a, b) =>
                                                  a?.source?.name.localeCompare(
                                                    b?.source?.name,
                                                    "az"
                                                  )
                                                )
                                                .reduce((acc, item) => {
                                                  if (
                                                    !acc.some(
                                                      (i) =>
                                                        i?.source?.name ===
                                                        item?.source?.name
                                                    )
                                                  ) {
                                                    acc.push(item);
                                                  }
                                                  return acc;
                                                }, [])
                                                .map((item, index) => (
                                                  <MenuItem
                                                    key={index}
                                                    value={item?.source?.name}
                                                  >
                                                    {item?.source?.name}
                                                  </MenuItem>
                                                ))}
                                            <Button
                                              sx={{
                                                mt: 3,
                                                ml: 3,
                                              }}
                                              variant="outlined"
                                              onClick={() => {
                                                setOpenAddDialog(true);
                                                setSelectedType([
                                                  "positions",
                                                  { name: "Vəzifələr" },
                                                ]);
                                              }}
                                            >
                                              Yeni vəzifə əlavə et
                                            </Button>
                                          </Field>
                                          {errors.laborActivity?.[index]
                                            ?.position &&
                                            touched?.laborActivity?.[index]
                                              ?.position &&
                                            errors.laborActivity?.[index]
                                              ?.position && (
                                              <FormHelperText error>
                                                {
                                                  errors.laborActivity?.[index]
                                                    ?.position
                                                }
                                              </FormHelperText>
                                            )}
                                        </FormControl>
                                      )}
                                    </Grid>

                                    <Grid sx={{ width: "100%" }}>
                                      <FormControl
                                        fullWidth
                                        error={
                                          errors?.laborActivity?.[index]
                                            ?.isCivilServant &&
                                          touched?.laborActivity?.[index]
                                            ?.isCivilServant &&
                                          Boolean(
                                            errors?.laborActivity?.[index]
                                              ?.isCivilServant
                                          )
                                        }
                                      >
                                        <InputLabel
                                          id={`laborActivity[${index}].isCivilServant`}
                                        >
                                          Dövlət qulluqçusu *
                                        </InputLabel>
                                        <Field
                                          as={Select}
                                          name={`laborActivity[${index}].isCivilServant`}
                                          labelId={`laborActivity[${index}].isCivilServant`}
                                          label="Dövlət qulluqçusu *"
                                          id={`laborActivity[${index}].isCivilServant-select`}
                                          defaultValue={false}
                                          fullWidth
                                          error={
                                            errors?.laborActivity?.[index]
                                              ?.isCivilServant &&
                                            touched?.laborActivity?.[index]
                                              ?.isCivilServant &&
                                            Boolean(
                                              errors?.laborActivity?.[index]
                                                ?.isCivilServant
                                            )
                                          }
                                        >
                                          <MenuItem value={true}>Bəli</MenuItem>
                                          <MenuItem value={false}>
                                            Xeyr
                                          </MenuItem>
                                        </Field>
                                        {errors.laborActivity?.[index]
                                          ?.isCivilServant &&
                                          touched?.laborActivity?.[index]
                                            ?.isCivilServant &&
                                          errors.laborActivity?.[index]
                                            ?.isCivilServant && (
                                            <FormHelperText error>
                                              {
                                                errors.laborActivity?.[index]
                                                  ?.isCivilServant
                                              }
                                            </FormHelperText>
                                          )}
                                      </FormControl>
                                    </Grid>
                                  </Grid>

                                  {/* Job start and end date */}
                                  <Grid
                                    onClick={() => setIsDate(true)}
                                    sx={{ display: "flex", gap: 3, mb: 3 }}
                                  >
                                    <Field
                                      name={`laborActivity[${index}].startDate`}
                                    >
                                      {({ field, form }) => {
                                        const endDate =
                                          form.values.laborActivity[index]
                                            ?.endDate;
                                        return (
                                          <DatePicker
                                            {...field}
                                            label="İşə başlama tarixi"
                                            inputFormat="dd/MM/yyyy"
                                            value={
                                              form?.values?.laborActivity[index]
                                                ?.startDate
                                                ? field?.value
                                                : ""
                                            }
                                            onChange={(date) => {
                                              if (
                                                date instanceof Date &&
                                                !isNaN(date)
                                              ) {
                                                form.setFieldValue(
                                                  `laborActivity[${index}].startDate`,
                                                  date
                                                );
                                              } else {
                                                form.setFieldValue(
                                                  `laborActivity[${index}].startDate`,
                                                  ""
                                                );
                                              }
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                helperText={
                                                  isDate &&
                                                  form.errors?.laborActivity?.[
                                                    index
                                                  ]?.startDate
                                                }
                                                error={
                                                  isDate &&
                                                  Boolean(
                                                    form.errors.laborActivity?.[
                                                      index
                                                    ]?.startDate
                                                  )
                                                }
                                                fullWidth
                                              />
                                            )}
                                            maxDate={
                                              endDate
                                                ? new Date(endDate)
                                                : today
                                            }
                                            minDate={new Date(1940, 0, 1)}
                                            disableFuture
                                            clearable
                                            clearText="Clear"
                                          />
                                        );
                                      }}
                                    </Field>

                                    {!values.laborActivity[index]?.present && (
                                      <Field
                                        name={`laborActivity[${index}].endDate`}
                                      >
                                        {({ field, form }) => {
                                          const startDate =
                                            form.values.laborActivity[index]
                                              ?.startDate;

                                          return (
                                            <DatePicker
                                              {...field}
                                              label="İşin bitmə tarixi"
                                              inputFormat="dd/MM/yyyy"
                                              value={
                                                form?.values?.laborActivity[
                                                  index
                                                ]?.endDate
                                                  ? field?.value
                                                  : ""
                                              }
                                              onChange={(date) => {
                                                if (
                                                  date instanceof Date &&
                                                  !isNaN(date)
                                                ) {
                                                  form.setFieldValue(
                                                    `laborActivity[${index}].endDate`,
                                                    date
                                                  );
                                                } else {
                                                  form.setFieldValue(
                                                    `laborActivity[${index}].endDate`,
                                                    ""
                                                  );
                                                }
                                              }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  helperText={
                                                    isDate &&
                                                    form.errors
                                                      ?.laborActivity?.[index]
                                                      ?.endDate
                                                  }
                                                  error={
                                                    isDate &&
                                                    Boolean(
                                                      form.errors
                                                        .laborActivity?.[index]
                                                        ?.endDate
                                                    )
                                                  }
                                                  fullWidth
                                                />
                                              )}
                                              maxDate={today}
                                              disableFuture
                                              clearable
                                              clearText="Clear"
                                              minDate={
                                                startDate
                                                  ? new Date(startDate)
                                                  : undefined
                                              }
                                              minDateMessage="End date should not be before the start date"
                                            />
                                          );
                                        }}
                                      </Field>
                                    )}
                                  </Grid>

                                  {/* Service period and same workplace */}
                                  <Grid
                                    sx={{
                                      display: "flex",
                                      gap: 3,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Grid
                                      sx={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        ml: 2,
                                      }}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              values.laborActivity[index]
                                                ?.sameWorkplace || false
                                            }
                                            // disabled={
                                            //   values.laborActivity[index]
                                            //     ?.sameWorkplace
                                            // }
                                            onChange={handleChange}
                                            name={`laborActivity[${index}].sameWorkplace`}
                                            color="primary"
                                          />
                                        }
                                        label="Bu təşkilatda"
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            onChange={(e) => {
                                              const { checked } = e.target;
                                              setFieldValue(
                                                `laborActivity[${index}].present`,
                                                checked
                                              );
                                              if (checked) {
                                                // If present is checked, set endDate to empty string
                                                setFieldValue(
                                                  `laborActivity[${index}].endDate`,
                                                  ""
                                                );
                                              }
                                            }}
                                            name={`laborActivity[${index}].present`}
                                            color="primary"
                                            checked={
                                              values.laborActivity[index]
                                                ?.present || false
                                            }
                                          />
                                        }
                                        label="Hal hazırda işləyir"
                                      />
                                      <ErrorMessage
                                        name={`laborActivity[${index}].sameWorkplace`}
                                        component="div"
                                      />
                                    </Grid>
                                  </Grid>
                                </>

                                <Button
                                  sx={{ mt: 3 }}
                                  variant="contained"
                                  color="error"
                                  type="button"
                                  onClick={() => {
                                    remove(index);
                                    setIsDate(false);
                                  }}
                                >
                                  Əmək fəaliyyətini sil
                                </Button>
                              </div>
                            );
                          })}
                        <Button
                          sx={{ mt: 3 }}
                          variant="contained"
                          type="button"
                          onClick={() => {
                            setIsDate(false);
                            push({
                              sameWorkplace: false,
                              present: false,
                              organizationName: "",
                              department: "",
                              position: "",
                              startDate: "",
                              endDate: "",
                              isCivilServant: false,
                            });
                          }}
                        >
                          Yeni əmək fəaliyyəti əlavə et
                        </Button>
                      </div>
                    )}
                  </FieldArray>

                  {/* Submit button */}
                  <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      Dəyişiklikləri yadda saxla
                    </Button>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        ) : (
          <div>Loading...</div>
        )}
      </AccordionDetails>
    </CustomAccordion>
  );
}

export default EditLaborActivity;
