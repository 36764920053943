import React from "react";
import DocumentDialog from "../../components/dialogs/documentDialog/DocumentDialog";
import { COLORS_STATUS } from "../../constants";
import { updateChangeRequests } from "../../api/changeRequests";

const ChangeRequestsModal = ({
  open,
  datas,
  status,
  loading,
  graphicId,
  fieldsData,
  handleClose,
  paginationHandler,
  cancelAppliedFiltersHandler,
}) => {
  async function handleSubmit() {
    try {
      const res = await updateChangeRequests(graphicId, {
        status: "approve",
        description: "I approved change request",
      });
      handleClose();
      paginationHandler();
      cancelAppliedFiltersHandler();
      return res;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
  async function handleReject() {
    try {
      const res = await updateChangeRequests(graphicId, {
        status: "reject",
        description: "I rejected change request",
      });
      handleClose();
      paginationHandler();
      cancelAppliedFiltersHandler();
      return res;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
  const generateBgColor = () => {
    switch (status) {
      case "pending":
        return COLORS_STATUS.BLUE;
      case "approved":
        return COLORS_STATUS.GREEN;
      case "rejected":
        return COLORS_STATUS.RED;
      default:
        return COLORS_STATUS.GREY;
    }
  };

  const generateHeader = () => {
    const docType = "əməkdaşların məlumat dəyişikliyi sorğusu";

    switch (status) {
      case "pending":
        return `Gözləyən ${docType}`;
      case "approved":
        return `Qüvvədə olan ${docType}`;
      case "rejected":
        return `İmtina edilmiş ${docType}`;
      default:
        return "Gözlüyür";
    }
  };
  return (
    <DocumentDialog
      open={open}
      onClose={handleClose}
      loading={loading}
      contractNo={datas?.requestNO}
      options={{
        bgColor: generateBgColor(),
        title: generateHeader(),
      }}
      isGroup={true}
      signingProps={{
        rejectBtnTitle: "Ləğv et",
        acceptBtnTitle: "Təsdiq et",
        handlerAccept: handleSubmit,
        handlerReject: handleReject,
        hide: datas.status === "pending" ? false : true,
        withoutCommentDialog: true,
      }}
      downloadBtnProps={{
        hide: true,
      }}
      fieldsData={fieldsData}
      commentsProps={{
        hide: true,
      }}
    />
  );
};

export default ChangeRequestsModal;
