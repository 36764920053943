import React, { useEffect } from "react";

import { THEMES } from "../constants";

const initialState = {
  theme: THEMES.DARK,
  // setTheme: (theme) => {},
};

const ThemeContext = React.createContext(initialState);

function ThemeProvider({ children }) {
  const [theme, _setTheme] = React.useState(initialState.theme);

  // useEffect(() => {
  //   const storedTheme = localStorage.getItem("theme");

  //   // JSON.parse create problem on chrome, brave | Works mozilla very well

  //   if (storedTheme) {
  //     _setTheme(JSON.parse(storedTheme));
  //   }
  // }, []);

  const setTheme = (theme) => {
    localStorage.setItem("theme", JSON.stringify(theme));
    _setTheme(theme);
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeProvider, ThemeContext };
