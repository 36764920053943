import React, { useEffect, useRef, useState } from "react";
import { Field, FieldArray, ErrorMessage } from "formik";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { Form, Formik } from "formik";
import { getActiveStaffs } from "../../services/staff/StaffServices";
import { getEmployees } from "../../services/employees/EmployeeServices";

const EditDecisionForm = ({ state, dispatch, setIsValid }) => {
  const { utilsEmployees, approvementChain, selectedDecisionChain } = state;
  const [formValues, setFormValues] = useState(null);
  const [activeEmps, setActiveEmps] = useState([]);
  const [employeesData, setEmployeesData] = useState(null);
  const chainEnd = useRef(null);

  let uri = [
    {
      column: "status",
      operator: "equalsTo",
      value: "active",
    },
  ];

  let encode = encodeURI(JSON.stringify(uri));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getEmployees({ ...formValues, filter: encode });
        setEmployeesData(data);
      } catch (error) {
        console.log("Error fetching employees:", error);
      }
    };

    if (formValues) {
      fetchData();
    }
  }, [formValues, encode]);

  useEffect(() => {
    const getEmps = async () => {
      try {
        const { data } = await getActiveStaffs();
        setActiveEmps(data?.records);
      } catch (error) {
        console.log("err", error);
      }
    };

    getEmps();
  }, []);

  useEffect(() => {
    if (formValues) {
      dispatch({
        type: "SET_APPROVEMENT_CHAIN",
        payload: formValues?.decisionChainArray,
      });
    }
  }, [formValues]);

  const scrollLastItem = () => {
    setTimeout(() => {
      const lastMessageRef = chainEnd?.current?.lastElementChild;
      if (lastMessageRef) {
        lastMessageRef.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };
  return (
    <Formik
      initialValues={{
        decisionChainArray: selectedDecisionChain?.approvementChain,
      }}
    >
      {({ values }) => {
        setFormValues(values);

        //TODO: line 80

        return (
          <Form>
            <FieldArray name="decisionChainArray">
              {({ push, remove }) => (
                <div ref={chainEnd}>
                  {values?.decisionChainArray?.map((form, index) => (
                    <Grid
                      key={index}
                      sx={{
                        alignItems: "center",
                        mb: 3,
                      }}
                    >
                      {typeof form === "object" && (
                        <Typography>
                          {index + 1}) Təsdiq edici{" "}
                          {values?.decisionChainArray[index]?.employee?.[0]
                            ?.firstname &&
                          values?.decisionChainArray[index]?.employee?.[0]
                            ?.lastname
                            ? "- " +
                              values?.decisionChainArray[index]?.employee?.[0]
                                ?.firstname +
                              " " +
                              values?.decisionChainArray[index]?.employee?.[0]
                                ?.lastname
                            : null}
                        </Typography>
                      )}
                      {/* status type and date */}
                      <Grid
                        fullWidth
                        sx={{ display: "flex", gap: 3, mt: 5, mb: 3 }}
                      >
                        <Grid
                          fullWidth
                          sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            gap: 3,
                          }}
                        >
                          <FormControl fullWidth>
                            <InputLabel id={`decisionChainArray[${index}]`}>
                              {values?.decisionChainArray[index]?.employee?.[0]
                                ?.firstname &&
                              values?.decisionChainArray[index]?.employee?.[0]
                                ?.lastname
                                ? values?.decisionChainArray[index]
                                    ?.employee?.[0]?.firstname +
                                  " " +
                                  values?.decisionChainArray[index]
                                    ?.employee?.[0]?.lastname
                                : ""}
                            </InputLabel>
                            <Field
                              as={Select}
                              name={`decisionChainArray[${index}]`}
                              labelId={`decisionChainArray[${index}]`}
                              label={
                                values?.decisionChainArray[index]?.employee?.[0]
                                  ?.firstname &&
                                values?.decisionChainArray[index]?.employee?.[0]
                                  ?.lastname
                                  ? values?.decisionChainArray[index]
                                      ?.employee?.[0]?.firstname +
                                    " " +
                                    values?.decisionChainArray[index]
                                      ?.employee?.[0]?.lastname
                                  : ""
                              }
                              id={`decisionChainArray[${index}]`}
                              fullWidth
                              onBlur={() => setFormValues(values)}
                              renderValue={(value) => {
                                const selectedItem =
                                  employeesData &&
                                  employeesData?.employees?.find((item) =>
                                    item.staffs.some(
                                      (staff) => staff?._id === value?._id
                                    )
                                  );

                                if (selectedItem) {
                                  const selectedStaff =
                                    selectedItem?.staffs?.find(
                                      (staff) => staff?._id === value?._id
                                    );
                                  if (
                                    selectedStaff &&
                                    selectedStaff.position &&
                                    selectedStaff.position.name
                                  ) {
                                    return `${selectedItem?.firstname} ${selectedItem?.lastname} ${selectedStaff.position.name}`;
                                  } else {
                                    return `${selectedItem?.firstname} ${selectedItem?.lastname}`;
                                  }
                                } else {
                                  return "";
                                }
                              }}
                            >
                              {employeesData &&
                                employeesData?.employees &&
                                employeesData?.employees?.map((item, index) => {
                                  if (item?.staffs?.length === 1) {
                                    const positionName =
                                      item?.staffs[0]?.position?.name;
                                    const fullName = `${item?.firstname} ${item?.lastname}`;
                                    return (
                                      <MenuItem
                                        key={item?.staffs[0]?._id}
                                        value={item?.staffs[0]?._id}
                                        onClick={() => setIsValid(false)}
                                      >
                                        {fullName}{" "}
                                        {positionName ? positionName : ""}
                                      </MenuItem>
                                    );
                                  } else {
                                    const fullName = `${item?.firstname} ${item?.lastname}`;
                                    return [
                                      <MenuItem
                                        disabled
                                        value={item?.staffs?.map(
                                          (staff) => staff?._id
                                        )}
                                      >
                                        {fullName}
                                      </MenuItem>,
                                      item?.staffs?.map((staff) => {
                                        const positionName =
                                          staff?.position?.name;
                                        return (
                                          <MenuItem
                                            className="disable_worker"
                                            value={staff?._id}
                                            key={staff?.position?._id}
                                            onClick={() => setIsValid(false)}
                                          >
                                            {positionName ? positionName : ""}
                                          </MenuItem>
                                        );
                                      }),
                                    ];
                                  }
                                })}
                            </Field>
                          </FormControl>
                          <IconButton
                            onClick={() => remove(index)}
                            size="small"
                          >
                            <Delete color="error" />
                          </IconButton>
                        </Grid>
                        <ErrorMessage
                          name={`decisionChainArray[${index}]`}
                          component="div"
                        />
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ mt: 3 }}
                    variant="contained"
                    type="button"
                    onClick={() => {
                      push({});
                      scrollLastItem();
                      setIsValid(true);
                    }}
                  >
                    Təsdiq edici əlavə et
                  </Button>
                </div>
              )}
            </FieldArray>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditDecisionForm;
