import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LabelIcon from "@mui/icons-material/Label";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import PersonIcon from "@mui/icons-material/Person";
import ShortTextIcon from "@mui/icons-material/ShortText";
import TocIcon from "@mui/icons-material/Toc";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getCardDetails, updateCard } from "../../../../api/tasks/taskRequests";
import Checklist from "../../checklist/Checklist";
import SelectDate from "../../date/SelectDate";
import SelectAttachment from "../attachment/SelectAttachment";
import EditComment from "../card/EditComment";
import AddChecklist from "../checklist/AddChecklist";
import AddMember from "./AddMember";
import SelectLabel from "./SelectLabel";

const EditTask = ({
  openEditTask,
  setOpenEditTask,
  taskDetails,
  setTaskDetails,
  assignMembersToCard,
  handleAddLabel,
  allLabels,
  handleAssignLabel,
  handleUpdateCard,
  handleAddCommentToCard,
  handleRemoveComment,
  handleEditComment,
  handleAddChecklist,
  setSubmitted,
  submitted,
  setIsSubmitting,
  boardData,
}) => {
  // ** Add member section
  const [findEmployeeDialog, setFindEmployeeDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);

  // ** Description state
  const [descData, setDescData] = useState("");

  // ** Activity state
  const [activityData, setActivityData] = useState("");
  const [selectedComment, setSelectedComment] = useState();
  const [openEditComment, setOpenEditComment] = useState(false);

  // ** Checklist dialog
  const [checklistDialog, setChecklistDialog] = useState(false);

  // ** Add labels section
  const [openSelectLabel, setOpenSelectLabel] = useState(false);

  // ** Attachment dialogs
  const [openAttachments, setOpenAttachments] = useState(false);

  // ** Edit mode
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (taskDetails._id) {
      setIsSubmitting(true);
      const handleCardDetails = async () => {
        const response = await getCardDetails(taskDetails._id);
        setTaskDetails(response.data);
        setDescData(response?.data?.description?.trim());
        setIsSubmitting(false);
      };
      handleCardDetails();
    }
  }, [openEditTask, submitted]);

  // ** Handle archive card
  const handleArchiveCard = async () => {
    setIsSubmitting(true);
    let data = {
      isArchived: true,
    };
    await updateCard(taskDetails._id, data);
    setSubmitted(!submitted);
    setIsSubmitting(false);
  };
  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth
        open={openEditTask}
        onClose={() => {
          setOpenEditTask(false);
          setTaskDetails();
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4">{taskDetails?.title}</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={5}>
            {/* Left side of edit card */}
            <Grid
              item
              xs={8}
              sx={{ display: "flex", flexDirection: "column", gap: 10 }}
            >
              {/* Members, labels, due date */}
              <Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  {/* Members */}
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 1,
                      maxWidth: "150px",
                    }}
                  >
                    {taskDetails.cardMembers.map((item) => (
                      <Tooltip
                        key={item.employee._id}
                        title={
                          item.employee.firstname +
                          " " +
                          item.employee.lastname +
                          ` ( Click to remove )`
                        }
                      >
                        <Avatar
                          sx={{
                            width: 32,
                            height: 32,
                            fontSize: 13,
                            fontWeight: "600",
                          }}
                        >
                          {item.employee?._id &&
                            item.employee.firstname[0] +
                              item.employee.lastname[0]}
                        </Avatar>
                      </Tooltip>
                    ))}
                  </Box>

                  {/* Labels */}
                  <Box sx={{ display: "flex", gap: 1 }}>
                    {taskDetails.labels.map((item) => (
                      <Box
                        key={item._id}
                        sx={{
                          backgroundColor: item.color,
                          borderRadius: "4px",
                          px: 2,
                          py: 1,
                          display: "flex",
                          alignContent: "center",
                          maxWidth: "400px",
                          minHeight: "32px",
                        }}
                      >
                        {item.title}
                      </Box>
                    ))}
                  </Box>
                </Box>

                {(taskDetails?.startDate || taskDetails?.dueDate) && (
                  <Box sx={{ mt: 3 }}>
                    <Typography variant="h4">Due date</Typography>
                    <Box>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Typography>Start date -</Typography>
                        <Typography>
                          {" "}
                          {moment(taskDetails.startDate).format(
                            "MMM DD [at] h:mm A"
                          )}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Typography>End date -</Typography>
                        <Typography>
                          {" "}
                          {moment(taskDetails.dueDate).format(
                            "MMM DD [at] h:mm A"
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>

              {/* Description */}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
                  <ShortTextIcon />
                  <Typography variant="h4">Açıqlama</Typography>
                </Box>
                {editMode ? (
                  <ClickAwayListener
                    onClickAway={() => {
                      setEditMode(false);
                    }}
                  >
                    <TextField
                      id="filled-multiline-static"
                      // label="Description"
                      multiline
                      rows={4}
                      placeholder="Təsvirinizi bura yazın"
                      value={descData}
                      variant="filled"
                      sx={{ width: "100%", pl: 10 }}
                      onChange={(e) => {
                        setDescData(e.target.value);
                      }}
                    />
                  </ClickAwayListener>
                ) : (
                  <TextField
                    id="filled-multiline-static"
                    // label="Description"
                    multiline
                    rows={4}
                    placeholder="Təsvirinizi bura yazın"
                    value={descData}
                    variant="filled"
                    sx={{ width: "100%", pl: 10 }}
                    onClick={() => {
                      setEditMode(true);
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                )}

                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  {editMode ? (
                    <Button
                      onClick={() => {
                        let data = {
                          description: descData,
                        };
                        handleUpdateCard(taskDetails._id, data);
                        setEditMode(false);
                      }}
                      variant="outlined"
                      disabled={descData?.trim() === "" ? true : false}
                    >
                      Yadda saxla
                    </Button>
                  ) : null}
                </Box>
              </Box>

              {/* Checklist */}
              {taskDetails?.checklists && taskDetails.checklists.length > 0 && (
                <Checklist
                  checklists={taskDetails.checklists}
                  submitted={submitted}
                  setSubmitted={setSubmitted}
                />
              )}

              {/* Activity */}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
                  <TocIcon />
                  <Typography variant="h4">Fəaliyyət</Typography>
                </Box>

                {taskDetails.comments.length > 0 &&
                  taskDetails.comments.map((item, index) => (
                    <Box key={index} sx={{ display: "flex", gap: 2 }}>
                      <Avatar
                        sx={{
                          width: 32,
                          height: 32,
                          fontSize: 13,
                          fontWeight: "600",
                        }}
                      >
                        {item.employee?.firstname &&
                          item.employee.firstname[0] +
                            item.employee.lastname[0]}
                      </Avatar>

                      <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
                        <TextField
                          id="activityField"
                          label="Fəaliyyət"
                          multiline
                          maxRows={4}
                          value={item.content}
                          placeholder="Təsvirinizi bura yazın"
                          variant="filled"
                          sx={{ width: "100%" }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              setOpenEditComment(true);
                              setSelectedComment(item);
                            }}
                            size="small"
                          >
                            <EditIcon
                              sx={{ fontSize: "16px", cursor: "pointer" }}
                            />
                          </IconButton>

                          <IconButton
                            onClick={() => {
                              handleRemoveComment(taskDetails._id, item._id);
                            }}
                            size="small"
                          >
                            <DeleteIcon sx={{ fontSize: "16px" }} />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  ))}

                <Box sx={{ display: "flex", gap: 2 }}>
                  {/* <Avatar
                    sx={{
                      width: 32,
                      height: 32,
                      fontSize: 13,
                      fontWeight: "600",
                    }}
                  >
                    MR
                  </Avatar> */}

                  <TextField
                    id="activityField"
                    label="Fəaliyyət"
                    multiline
                    rows={2}
                    onChange={(e) => {
                      setActivityData(e.target.value);
                    }}
                    value={activityData}
                    placeholder="Təsvirinizi bura yazın"
                    variant="filled"
                    sx={{ width: "100%", mr: 8, ml: 10 }}
                  />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    onClick={() => {
                      handleAddCommentToCard(taskDetails._id, {
                        content: activityData,
                      });
                      setActivityData("");
                    }}
                    variant="outlined"
                    disabled={activityData?.trim() === "" ? true : false}
                  >
                    Əlavə et
                  </Button>
                </Box>
              </Box>
            </Grid>

            {/* Right side of edit card */}
            <Grid item xs={4}>
              <Box>
                <Typography>Karta Əlavə et</Typography>
                <Button
                  variant="contained"
                  startIcon={<PersonIcon />}
                  sx={{
                    width: "100%",
                    my: 3,
                    justifyContent: "flex-start",
                  }}
                  onClick={() => {
                    setFindEmployeeDialog(true);
                  }}
                >
                  Üzvlər
                </Button>
                <Button
                  variant="contained"
                  startIcon={<LabelIcon />}
                  sx={{
                    width: "100%",
                    justifyContent: "flex-start",
                    mb: 3,
                  }}
                  onClick={() => {
                    setOpenSelectLabel(true);
                  }}
                >
                  Etiketlər
                </Button>
                <Button
                  variant="contained"
                  startIcon={<LibraryAddCheckIcon />}
                  sx={{
                    width: "100%",
                    justifyContent: "flex-start",
                    mb: 3,
                  }}
                  onClick={() => {
                    setChecklistDialog(true);
                  }}
                >
                  Yoxlama siyahısı
                </Button>
                <SelectDate
                  taskDetails={taskDetails}
                  submitted={submitted}
                  setSubmitted={setSubmitted}
                />
                <Button
                  variant="contained"
                  startIcon={<AttachFileIcon />}
                  sx={{
                    width: "100%",
                    justifyContent: "flex-start",
                    mb: 3,
                  }}
                  onClick={() => {
                    setOpenAttachments(true);
                  }}
                >
                  Təhkim
                </Button>
                <Button
                  variant="contained"
                  startIcon={<DeleteIcon />}
                  sx={{
                    width: "100%",
                    justifyContent: "flex-start",
                    mb: 3,
                  }}
                  onClick={() => {
                    setOpenEditTask(false);
                    handleArchiveCard();
                  }}
                >
                  Kartı silin
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenEditTask(false);
              setTaskDetails();
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          {/* <Button
            onClick={() => {
              setOpenEditTask(false);
            }}
            color="primary"
          >
            Əlavə et
          </Button> */}
        </DialogActions>
      </Dialog>

      {/* Add member dialog */}
      {findEmployeeDialog && (
        <AddMember
          findEmployeeDialog={findEmployeeDialog}
          setFindEmployeeDialog={setFindEmployeeDialog}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          assignMembersToCard={assignMembersToCard}
          taskDetails={taskDetails}
        />
      )}

      {/* Select label dialog */}
      {openSelectLabel && (
        <SelectLabel
          boardData={boardData}
          openSelectLabel={openSelectLabel}
          setOpenSelectLabel={setOpenSelectLabel}
          taskDetails={taskDetails}
          setSubmitted={setSubmitted}
          submitted={submitted}
          handleAddLabel={handleAddLabel}
          allLabels={allLabels}
          handleAssignLabel={handleAssignLabel}
        />
      )}

      {/* Edit comment dialog */}
      {openEditComment && (
        <EditComment
          openEditComment={openEditComment}
          setOpenEditComment={setOpenEditComment}
          selectedComment={selectedComment}
          setSelectedComment={setSelectedComment}
          handleEditComment={handleEditComment}
        />
      )}

      {checklistDialog && (
        <AddChecklist
          checklistDialog={checklistDialog}
          setChecklistDialog={setChecklistDialog}
          cardId={taskDetails._id}
          handleAddChecklist={handleAddChecklist}
        />
      )}

      {openAttachments && (
        <SelectAttachment
          openAttachments={openAttachments}
          setOpenAttachments={setOpenAttachments}
          cardId={taskDetails._id}
          setIsSubmitting={setIsSubmitting}
          attachments={taskDetails?.attachments}
          setSubmitted={setSubmitted}
          submitted={submitted}
        />
      )}
    </>
  );
};

export default EditTask;
