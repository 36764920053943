import React from "react";
import {
  Divider as MuiDivider,
  Grid,
  Typography,
  Card,
  Box,
} from "@mui/material";
import styled from "@emotion/styled";

import { spacing } from "@mui/system";
import Header from "../../components/layouts/header/Header";
import Breadcrumb from "../../components/layouts/breadcrumb/Breadcrumb";

import { headerData } from "./appointmentItems";
import AppointmentTable from "./parts/AppointmentTable";
import AppointmentStatistics from "./parts/AppointmentStatistics";

const Divider = styled(MuiDivider)(spacing);

export default function AppointmentPage() {
  return (
    <Grid container>
      <Grid key="header-container">
        <Header data={headerData} />
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <AppointmentStatistics />
        </Grid>
        <Grid item xs={12}>
          <AppointmentTable />
        </Grid>
      </Grid>
    </Grid>
  );
}
