import React, { useEffect, useState } from "react";
import { getEmployees } from "../../../services/employees/EmployeeServices";
import { addRemainderVacationTracking } from "../../../api/employeeRequests/employeeRequests";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

const VacationTrackingForm = ({ submited, setSubmited }) => {
  const [empData, setEmpData] = useState([]);
  const [show_Accordion, set_show_Accordion] = useState(false);
  const [pastEndWorkYear, setPastEndWorkYear] = useState([]);
  const [leave_state, set_leave_state] = useState({
    staffId: "",
    employeeId: "",
    startWorkYear: "",
    endWorkYear: "",
    reserved: { main: null, additional: null },
    used: { main: null, additional: null },
    unUsed: { main: null, additional: null },
  });
  const filterModel = [
    {
      column: "status",
      operator: "equalsTo",
      value: "active",
    },
  ];
  const getEmployeeData = async () => {
    const filter = encodeURIComponent(JSON.stringify(filterModel));
    try {
      const response = await getEmployees({ filter: filter });

      setEmpData(response?.employees);
    } catch (err) {
      console.log(err);
    }
  };
  const isLeaveStateEmpty = () => {
    return (
      !leave_state.staffId ||
      !leave_state.employeeId ||
      !leave_state.startWorkYear ||
      !leave_state.endWorkYear ||
      Object.values(leave_state.reserved).some(
        (value) => value === null || value === ""
      ) ||
      Object.values(leave_state.used).some(
        (value) => value === null || value === ""
      ) ||
      Object.values(leave_state.unUsed).some(
        (value) => value === null || value === ""
      )
    );
  };
  async function handleSubmit(e) {
    e.preventDefault();
    setSubmited(true);
    try {
      const modifiedValues = {
        staffId: leave_state?.staffId,
        startWorkYear: leave_state?.startWorkYear,
        endWorkYear: leave_state?.endWorkYear,
        reserved: {
          main: leave_state?.reserved?.main,
          additional: leave_state?.reserved?.additional,
        },
        used: {
          main: leave_state?.used?.main,
          additional: leave_state?.used?.additional,
        },
        unUsed: {
          main: leave_state?.unUsed?.main,
          additional: leave_state?.unUsed?.additional,
        },
      };
      await addRemainderVacationTracking(modifiedValues);
      setSubmited(false);
      set_show_Accordion(false);
      set_leave_state({
        staffId: "",
        employeeId: "",
        startWorkYear: "",
        endWorkYear: "",
        reserved: { main: null, additional: null },
        used: { main: null, additional: null },
        unUsed: { main: null, additional: null },
      });
      setPastEndWorkYear([]);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getEmployeeData();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button
          onClick={() => set_show_Accordion(!show_Accordion)}
          variant="contained"
          sx={{ height: "100%" }}
        >
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Qalıq məzuniyyət əlavə et <AddIcon />
          </p>
        </Button>
      </div>
      {show_Accordion && (
        <form onSubmit={handleSubmit}>
          <div className="d-flex">
            <div className="w-25 ms-2">
              <div>Əməkdaş</div>
              <div className="mt-2">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Əməkdaş</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={leave_state?.employeeId}
                    label="Əməkdaş"
                    placeholder="ok"
                    onChange={(e) =>
                      set_leave_state({
                        ...leave_state,
                        employeeId: e.target.value,
                      })
                    }
                  >
                    {empData?.length &&
                      empData?.map((e) => (
                        <MenuItem value={e?.id}>
                          {e?.firstname} {e?.lastname} {e?.fathername}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <div>Yaranmış məzuniyyət hüququ</div>
              <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
                <TextField
                  type="number"
                  onChange={(e) =>
                    set_leave_state({
                      ...leave_state,
                      reserved: {
                        main: e.target.value,
                        additional: leave_state?.reserved?.additional,
                      },
                    })
                  }
                  label="Əsas"
                  value={leave_state?.reserved?.main}
                  disabled={!leave_state.staffId}
                  fullWidth
                />
                <TextField
                  type="number"
                  onChange={(e) =>
                    set_leave_state({
                      ...leave_state,
                      reserved: {
                        main: leave_state?.reserved?.main,
                        additional: e.target.value,
                      },
                    })
                  }
                  label="Əlavə"
                  value={leave_state?.reserved?.additional}
                  fullWidth
                  disabled={!leave_state.staffId}
                />
              </div>
            </div>
            <div className="w-25 ms-2">
              <div>Vəzifə</div>
              <div className="mt-2">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Vəzifə</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={leave_state?.staffId}
                    label="Vəzifə"
                    placeholder="ok"
                    disabled={!leave_state.employeeId}
                    onChange={(e) => {
                      const selectedStaff = empData
                        ?.find((emp) => emp?.id === leave_state.employeeId)
                        ?.staffs.find((staff) => staff.id === e.target.value);

                      if (selectedStaff?.workYears.length > 0) {
                        setPastEndWorkYear(selectedStaff.workYears);
                      } else {
                        setPastEndWorkYear([]);
                      }

                      set_leave_state({
                        ...leave_state,
                        staffId: e.target.value,
                      });
                    }}
                  >
                    {empData?.length &&
                      empData
                        .filter((e) => e?.id === leave_state.employeeId)
                        .map((e, index) =>
                          e?.staffs.map((staff, index) => (
                            <MenuItem
                              key={`${staff.id}-${index}`}
                              value={staff?.id}
                            >
                              {staff?.position?.name}
                            </MenuItem>
                          ))
                        )}
                  </Select>
                </FormControl>
              </div>

              <div>İstifadə edilmiş məzuniyyət</div>
              <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
                <TextField
                  type="number"
                  onChange={(e) =>
                    set_leave_state({
                      ...leave_state,
                      used: {
                        main: e.target.value,
                        additional: leave_state?.used?.additional,
                      },
                    })
                  }
                  disabled={!leave_state.staffId}
                  label="Əsas"
                  value={leave_state?.used?.main}
                  fullWidth
                />
                <TextField
                  type="number"
                  onChange={(e) =>
                    set_leave_state({
                      ...leave_state,
                      used: {
                        main: leave_state?.used?.main,
                        additional: e.target.value,
                      },
                    })
                  }
                  disabled={!leave_state.staffId}
                  label="Əlavə"
                  value={leave_state?.used?.additional}
                  fullWidth
                />
              </div>
            </div>
            <div className="w-25 ms-2">
              <div>İş ilinin başlaması</div>
              <div className="mt-2">
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  label="İş ilinin başlaması"
                  value={leave_state?.startWorkYear || null}
                  disabled={!leave_state.staffId}
                  maxDate={
                    pastEndWorkYear.length > 0
                      ? pastEndWorkYear.map((past) => new Date(past?.start))
                      : new Date()
                  }
                  onChange={(date) => {
                    const startDate = new Date(date);
                    const isGreaterThanPast = pastEndWorkYear.some(
                      (past) => startDate > new Date(past?.start)
                    );

                    if (isGreaterThanPast) {
                      set_leave_state({
                        ...leave_state,
                        startWorkYear: "",
                        endWorkYear: "",
                      });
                    } else {
                      const newEndDate = new Date(
                        startDate.setFullYear(startDate.getFullYear() + 1)
                      );
                      set_leave_state({
                        ...leave_state,
                        startWorkYear: date,
                        endWorkYear: newEndDate,
                      });
                    }
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </div>

              <div>Qalıq məzuniyyət günləri</div>
              <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
                <TextField
                  type="number"
                  onChange={(e) =>
                    set_leave_state({
                      ...leave_state,
                      unUsed: {
                        main: e.target.value,
                        additional: leave_state?.unUsed?.additional,
                      },
                    })
                  }
                  label="Əsas"
                  value={leave_state?.unUsed?.main}
                  disabled={!leave_state.staffId}
                  fullWidth
                />
                <TextField
                  type="number"
                  onChange={(e) =>
                    set_leave_state({
                      ...leave_state,
                      unUsed: {
                        main: leave_state?.unUsed?.main,
                        additional: e.target.value,
                      },
                    })
                  }
                  disabled={!leave_state.staffId}
                  label="Əlavə"
                  value={leave_state?.unUsed?.additional}
                  fullWidth
                />
              </div>
            </div>
            <div className="w-25 ms-2">
              <div>Bitmə tarixi</div>

              <div className="mt-2">
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  label="Bitmə tarixi"
                  value={leave_state?.endWorkYear || null}
                  minDate={new Date(leave_state.startWorkYear)}
                  InputProps={{
                    readOnly: true,
                    style: {
                      pointerEvents: "none",
                      cursor: "default",
                    },
                  }}
                  disabled={!leave_state.staffId}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </div>
            </div>
          </div>
          <div className="w-25 ms-2 mt-3">
            <Button
              type="submit"
              variant="contained"
              disabled={isLeaveStateEmpty() || submited}
            >
              Əlavə et
            </Button>
          </div>
        </form>
      )}
    </>
  );
};

export default VacationTrackingForm;
