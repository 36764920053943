import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { createDecisionChain, getChainlessPart } from "../../api/api";
import DecisionForm from "./DecisionForm";
import NestedSelect from "./NestedSelect";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddDecisionChain = ({ state, dispatch }) => {
  const {
    addDecisionChainDialog,
    allStructures,
    structureId,
    organizationId,
    nestedChildren,
    positionId,
    approvementChain,
    submitted,
  } = state;

  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (
      structureId &&
      organizationId &&
      positionId &&
      approvementChain?.every((obj) => Object?.keys(obj)?.length !== 0) &&
      approvementChain?.length !== 0
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [structureId, organizationId, positionId, approvementChain]);

  useEffect(() => {
    const handleAllStructures = async () => {
      try {
        const response = await getChainlessPart();
        dispatch({
          type: "GET_ALL_STRUCTURES",
          payload: response.data.records,
        });
      } catch (error) {
        console.log("err", error);
      }
    };
    handleAllStructures();
  }, []);

  const handleOrganizationName = async (e) => {
    dispatch({
      type: "SET_STRUCTURE_ID",
      payload: e.target.value._id,
    });
    dispatch({
      type: "SET_ORGANIZATION_ID",
      payload: e.target.value.organization._id,
    });

    const response = await getChainlessPart({
      structureId: e.target.value._id,
    });

    dispatch({
      type: "GET_NESTED_CHILDREN",
      payload: response.data.records,
    });
  };

  const handleSubmit = async () => {
    await createDecisionChain(structureId, positionId, approvementChain);
    dispatch({
      type: "SET_SUBMITTED",
      payload: !submitted,
    });
    dispatch({
      type: "SET_ADD_DECISION_CHAIN_DIALOG",
      payload: false,
    });
    dispatch({
      type: "SET_POSITION_ID",
      payload: null,
    });
    dispatch({
      type: "SET_STRUCTURE_ID",
      payload: null,
    });
    setIsValid(true);
  };
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={addDecisionChainDialog}
        onClose={() => {
          dispatch({
            type: "SET_ADD_DECISION_CHAIN_DIALOG",
            payload: false,
          });
          dispatch({
            type: "SET_STRUCTURE_ID",
            payload: null,
          });
          dispatch({
            type: "SET_ORGANIZATION_ID",
            payload: null,
          });
          dispatch({
            type: "SET_POSITION_ID",
            payload: null,
          });
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography p={5} textAlign="center" variant="h3">
            Yeni Matrisin Əlavə Olunması
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ px: "50px" }}>
          <Grid sx={{ display: "flex", width: "100%", gap: 15 }}>
            <Grid
              sx={{
                width: 1 / 3,
                py: 2,
                maxHeight: "55vh",
                overflowY: "auto",
              }}
            >
              <FormControl sx={{ mb: 6 }} fullWidth>
                <InputLabel id="organizationName">Təşkilatı seçin</InputLabel>
                <Select
                  labelId="organizationName"
                  id="organizationName"
                  input={<Input />}
                  fullWidth
                  MenuProps={MenuProps}
                  onChange={(e) => {
                    handleOrganizationName(e);
                  }}
                >
                  {allStructures.map((structure, index) => {
                    if (structure.organization.name) {
                      return (
                        <MenuItem key={index} value={structure}>
                          {structure.organization.name}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
              </FormControl>
              {structureId && organizationId && nestedChildren !== "" ? (
                <NestedSelect state={state} dispatch={dispatch} />
              ) : null}
            </Grid>
            <Grid
              sx={{
                width: 2 / 3,
                py: 2,
                maxHeight: "55vh",
                overflowY: "auto",
              }}
            >
              {positionId ? (
                <DecisionForm
                  state={state}
                  dispatch={dispatch}
                  setIsValid={setIsValid}
                />
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              dispatch({
                type: "SET_ADD_DECISION_CHAIN_DIALOG",
                payload: false,
              });
            }}
          >
            Ləğv et
          </Button>
          <Button
            disabled={isValid}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            Əlavə et
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddDecisionChain;
