import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { deleteDecisionChain } from "../../api/api";

const DeleteDecisionChain = ({ state, dispatch }) => {
  const { deleteDecisionDialog, selectedDecisionChain, submitted } = state;

  const handleDeleteDecision = async () => {
    dispatch({
      type: "SET_LOADING_DIALOG",
      payload: true,
    });
    await deleteDecisionChain(selectedDecisionChain._id);
    dispatch({
      type: "SET_SUBMITTED",
      payload: !submitted,
    });
    dispatch({
      type: "SET_DELETE_DECISION_DIALOG",
      payload: false,
    });
    dispatch({
      type: "SET_ROW_ID",
      payload: "",
    });
    dispatch({
      type: "SET_LOADING_DIALOG",
      payload: false,
    });
  };
  return (
    <>
      <Dialog
        open={deleteDecisionDialog}
        onClose={() => {
          dispatch({
            type: "SET_DELETE_DECISION_DIALOG",
            payload: false,
          });
          dispatch({
            type: "SET_ROW_ID",
            payload: "",
          });
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Silmək istədiyinizdən əminsiniz ?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch({
                type: "SET_DELETE_DECISION_DIALOG",
                payload: false,
              });
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          <Button
            color="primary"
            onClick={() => {
              handleDeleteDecision();
            }}
          >
            Bəli
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteDecisionChain;
