import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../constants";
import WorkerBuilder from "../../models/workerBuilder/WorkerBuilder";
import { setEmpStaticData } from "../../redux/features/slices/commonThings.slice";
import fetchWorker from "./fetch.worker";

const worker = new WorkerBuilder(fetchWorker);

function getToken(tokenName) {
  let token = window.localStorage.getItem(tokenName);
  if (!token) {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].split("=");
      if (cookie[0] === tokenName) {
        token = cookie[1];
        break;
      }
    }
  }

  return token;
}

export default function useStaticData() {
  const [loading, setLoading] = useState(true);
  const [trigger, setTrigger] = useState(false);
  const dispatch = useDispatch();
  const empStaticData = useSelector(
    (state) => state.commonThings.empStaticData
  );

  useEffect(() => {
    existHasStaticData();
  }, [trigger]);

  const existHasStaticData = () => {
    const currentList =
      empStaticData.length > 0 ? empStaticData : getLocalStorage();
    const accessToken = getToken("accessToken");
    const refreshToken = getToken("refreshToken");

    worker.onmessage = (e) => {
      if (e.data) {
        setLocalStorage(e.data);
      } else {
        setLocalStorage(getLocalStorage());
      }
      worker.terminate();
      setLoading(false);
    };

    worker.postMessage({
      headers: {
        Authorization: `Bearer ${accessToken}`,
        RefreshToken: refreshToken,
      },
      versionsURL: `${BASE_URL}/employee/static-data/versions`,
      dataURL: `${BASE_URL}/employee/static-data?keywords=`,
      list: currentList,
    });
  };

  const getLocalStorage = () => {
    return JSON.parse(localStorage.getItem("staticData")) || [];
  };

  const setLocalStorage = (data) => {
    if (!data || data.length === 0) return;

    localStorage.setItem("staticData", JSON.stringify(data));
    dispatch(setEmpStaticData(data));
  };

  const triggerHandler = () => {
    setTrigger((prevTrigger) => !prevTrigger);
  };

  return [empStaticData, loading, triggerHandler];
}
