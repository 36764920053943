import { api } from "../../api/axiosInterceptor";

export const getEmployees = async () => {
  return await api.get(`/employee/hr/labour-contract-update//employees`);
};

export const getNextStructure = async (
  structureId = undefined,
  partId = undefined
) => {
  let url = `/employee/hr/labour-contract-update/nested-children`;
  if (structureId)
    url = `/employee/hr/labour-contract-update/nested-children?structure=${structureId}`;
  else if (partId)
    url = `/employee/hr/labour-contract-update/nested-children?parent=${partId}`;

  return await api.get(url);
};

export const updateContract = async (payload) => {
  return await api.post(`/employee/hr/labour-contract-update`, payload);
};

export const getStructureEmployees = async (
  structureId,
  partId = undefined
) => {
  let url = partId
    ? `/employee/hr/labour-contract-update/structure-employees?partId=${partId}`
    : `/employee/hr/labour-contract-update/structure-employees?structureId=${structureId}`;
  return await api.get(url);
};

export const getContractUpdates = async ({ limit, offset, filters }) => {
  return await api.get(`/employee/hr/labour-contract-update/contracts`, {
    params: {
      limit: limit,
      offset: offset,
      filter: filters,
    },
  });
};
export const getContract = async (id) => {
  return await api.get(`/employee/labour-contract/hr/${id}?mapFields=false`);
};

export const getContractUpdate = async (id, isGroup = false) => {
  return await api.get(
    `/employee/hr/labour-contract-update/${id}?groupBy=${isGroup}`
  );
};

export const getContractChangeStatistics = async (startDate, endDate) => {
  return await api.get(
    `/employee/hr/labour-contract-update/statistics?startDate=${startDate}&endDate=${endDate}`
  );
};

export const downloadContractChangesCSV = async ({
  filter,
  columns,
  isWithFilter,
}) => {
  const str = isWithFilter
    ? `/employee/labour-contract-update/download?filter=${filter}&columns=${columns}`
    : `/employee/labour-contract-update/download?columns=${columns}`;

  return await api.get(str);
};

// employment contract services
export const getContractData = async (id) => {
  return await api.get(`employee/labour-contract/hr/${id}?groupBy=true`);
};
