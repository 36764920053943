import React from "react";
import { OPERATORS_CONTAINING } from "../../../constants";
import Column from "../../../models/table/Column";
import styled from "styled-components";
import { getStaticFilterValues } from "../../../utils/forFilterDropdown/getStructureUtils";
import moment from "moment";
const Td = styled.td`
  padding: 10px;
  margin: 0;
  font-weight: 100;
`;
const capitalizeFirstWord = (str) => {
  const words = str?.split(" ");
  if (words?.length > 0) {
    words[0] =
      words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
  }
  return words?.join(" ");
};
export const columnsVacation = [
  new Column({
    path: "employeeTableNumber",
    headerName: "Tabel nömrəsi",
    filterType: OPERATORS_CONTAINING.TEXT,
    getValue: (row, col) => {
      return `${row?.employee?.tableNumber}`;
    },
  }),
  new Column({
    path: "employeeFirstName",
    headerName: "Əməkdaşın adı",
    filterType: OPERATORS_CONTAINING.TEXT,
    getValue: (row, col) => {
      return capitalizeFirstWord(`${row?.employee?.firstname}`);
    },
  }),
  new Column({
    path: "employeeLastName",
    headerName: "Əməkdaşın soyadı",
    filterType: OPERATORS_CONTAINING.TEXT,
    getValue: (row, col) => {
      return capitalizeFirstWord(` ${row?.employee?.lastname}`);
    },
  }),
  new Column({
    path: "employeeFatherName",
    headerName: "Əməkdaşın ata adı",
    filterType: OPERATORS_CONTAINING.TEXT,
    getValue: (row, col) => {
      return capitalizeFirstWord(`${row?.employee?.fathername}`);
    },
  }),
  new Column({
    path: "baseStructure",
    headerName: "Təşkilat",
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <>
            {row?.vacationTrackings.map((vacation, index) => {
              const baseStructureName =
                vacation?.baseStructure?.organization?.name;
              return (
                <React.Fragment key={index}>
                  <Td>
                    <div>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>
                          {" "}
                          {baseStructureName}
                        </p>
                      </div>
                    </div>
                  </Td>
                </React.Fragment>
              );
            })}
          </>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <Td>
            {row?.vacationTrackings.map((vacation, index) => {
              const baseStructureName =
                vacation?.baseStructure?.organization?.name;
              return (
                <div>
                  <div className="subHeaderVacation">
                    <p title={baseStructureName} className="specialText">
                      {baseStructureName}
                    </p>
                  </div>
                  <hr
                    style={{
                      display:
                        row?.vacationTrackings?.length - 1 === index
                          ? "none"
                          : "block",
                    }}
                    className="vacationDivide"
                  />
                </div>
              );
            })}
          </Td>
        );
      }
      return null;
    },
    filterType: OPERATORS_CONTAINING.IN,
    filterColumnKey: "baseStructure",
    fetchFilterValuesV2: getStaticFilterValues,
    dataGridName: "vacation-tracking",
    keyForResponseDataFromFilter: "baseStructures",
    rowSpan: "2",
  }),
  new Column({
    path: "topPartOfStructure",
    headerName: "Department",
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <>
            {row?.vacationTrackings.map((vacation, index) => {
              const topPartOfStructureName =
                vacation?.topPartOfStructure?.source?.name;
              return (
                <React.Fragment key={index}>
                  <Td>
                    <div>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>
                          {" "}
                          {topPartOfStructureName}
                        </p>
                      </div>
                    </div>
                  </Td>
                </React.Fragment>
              );
            })}
          </>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <Td>
            {row?.vacationTrackings.map((vacation, index) => {
              const topPartOfStructureName =
                vacation?.topPartOfStructure?.source?.name;
              return (
                <div>
                  <div className="subHeaderVacation">
                    <p title={topPartOfStructureName} className="specialText">
                      {" "}
                      {topPartOfStructureName}
                    </p>
                  </div>
                  <hr
                    style={{
                      display:
                        row?.vacationTrackings?.length - 1 === index
                          ? "none"
                          : "block",
                    }}
                    className="vacationDivide"
                  />
                </div>
              );
            })}
          </Td>
        );
      }
      return null;
    },
    filterColumnKey: "topPartOfStructure",
    fetchFilterValuesV2: getStaticFilterValues,
    dataGridName: "vacation-tracking",
    dependencieKeysOfFilter: {
      structures: ["baseStructure._id"],
    },
    keyForResponseDataFromFilter: "topPartOfStructures",
    filterType: OPERATORS_CONTAINING.IN,
    rowSpan: "2",
  }),
  new Column({
    path: "subStructure",
    headerName: "Sub-struktur",
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <>
            {row?.vacationTrackings.map((vacation, index) => {
              const subStructureName = vacation?.subStructure?.source?.name;
              return (
                <React.Fragment key={index}>
                  <Td>
                    <div>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>
                          {" "}
                          {subStructureName}
                        </p>
                      </div>
                    </div>
                  </Td>
                </React.Fragment>
              );
            })}
          </>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <Td>
            {row?.vacationTrackings.map((vacation, index) => {
              const subStructureName = vacation?.subStructure?.source?.name;
              return (
                <div>
                  <div className="subHeaderVacation">
                    <p title={subStructureName} className="specialText">
                      {" "}
                      {subStructureName}
                    </p>
                  </div>
                  <hr
                    style={{
                      display:
                        row?.vacationTrackings?.length - 1 === index
                          ? "none"
                          : "block",
                    }}
                    className="vacationDivide"
                  />
                </div>
              );
            })}
          </Td>
        );
      }
      return null;
    },
    rowSpan: "2",
    filterType: OPERATORS_CONTAINING.IN,
    filterColumnKey: "subStructure",
    fetchFilterValuesV2: getStaticFilterValues,
    dataGridName: "vacation-tracking",
    dependencieKeysOfFilter: {
      structures: ["baseStructure._id"],
      topPartOfStructures: ["topPartOfStructure._id"],
    },
    keyForResponseDataFromFilter: "subStructures",
  }),
  new Column({
    path: "position",
    headerName: "Vəzifə",
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <>
            {row?.vacationTrackings.map((vacation, index) => {
              const positionName = vacation?.position?.source?.name;
              return (
                <React.Fragment key={index}>
                  <Td>
                    <div>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}> {positionName}</p>
                      </div>
                    </div>
                  </Td>
                </React.Fragment>
              );
            })}
          </>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <Td>
            {row?.vacationTrackings.map((vacation, index) => {
              const positionName = vacation?.position?.source?.name;
              return (
                <div>
                  <div className="subHeaderVacation">
                    <p title={positionName} className="specialText">
                      {" "}
                      {positionName}
                    </p>
                  </div>
                  <hr
                    style={{
                      display:
                        row?.vacationTrackings?.length - 1 === index
                          ? "none"
                          : "block",
                    }}
                    className="vacationDivide"
                  />
                </div>
              );
            })}
          </Td>
        );
      }
      return null;
    },
    rowSpan: "2",
    filterType: OPERATORS_CONTAINING.IN,
    filterColumnKey: "position",
    fetchFilterValuesV2: getStaticFilterValues,
    dataGridName: "vacation-tracking",
    dependencieKeysOfFilter: {
      structures: ["baseStructure._id"],
      topPartOfStructures: ["topPartOfStructure._id"],
    },
    keyForResponseDataFromFilter: "positions",
  }),
  new Column({
    path: "vacationReportStartDate",
    headerName: "İş ilinin başlaması",
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <>
            {row?.vacationTrackings.map((vacation, index) => {
              const startWorkYear =
                vacation?.vacationReports?.[0]?.startWorkYear;
              return (
                <React.Fragment key={index}>
                  <Td>
                    <div>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>
                          {" "}
                          {moment(startWorkYear).format("DD/MM/YYYY")}
                        </p>
                      </div>
                    </div>
                  </Td>
                </React.Fragment>
              );
            })}
          </>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <Td>
            {row?.vacationTrackings.map((vacation, index) => {
              const startWorkYear =
                vacation?.vacationReports?.[0]?.startWorkYear;
              return (
                <div>
                  <div className="subHeaderVacation">
                    <p style={{ textAlign: "center" }}>
                      {" "}
                      {moment(startWorkYear).format("DD/MM/YYYY")}
                    </p>
                  </div>
                  <hr
                    style={{
                      display:
                        row?.vacationTrackings?.length - 1 === index
                          ? "none"
                          : "block",
                    }}
                    className="vacationDivide"
                  />
                </div>
              );
            })}
          </Td>
        );
      }
      return null;
    },
    filterType: OPERATORS_CONTAINING.DATE,
    rowSpan: "2",
  }),
  new Column({
    path: "vacationReportEndDate",
    headerName: "İş ilinin bitməsi",
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <>
            {row?.vacationTrackings.map((vacation, index) => {
              const endWorkYear = vacation?.vacationReports?.[0]?.endWorkYear;
              return (
                <React.Fragment key={index}>
                  <Td>
                    <div>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>
                          {" "}
                          {moment(endWorkYear).format("DD/MM/YYYY")}
                        </p>
                      </div>
                    </div>
                  </Td>
                </React.Fragment>
              );
            })}
          </>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <Td>
            {row?.vacationTrackings.map((vacation, index) => {
              const endWorkYear = vacation?.vacationReports?.[0]?.endWorkYear;
              return (
                <div>
                  <div className="subHeaderVacation">
                    <p style={{ textAlign: "center" }}>
                      {" "}
                      {moment(endWorkYear).format("DD/MM/YYYY")}
                    </p>
                  </div>
                  <hr
                    style={{
                      display:
                        row?.vacationTrackings?.length - 1 === index
                          ? "none"
                          : "block",
                    }}
                    className="vacationDivide"
                  />
                </div>
              );
            })}
          </Td>
        );
      }
      return null;
    },
    filterType: OPERATORS_CONTAINING.DATE,
    rowSpan: "2",
  }),
  new Column({
    // path: "position.name",
    headerName: "Yaranmış məzuniyyət hüququ",
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <>
            {row?.vacationTrackings.map((vacation, index) => {
              const reservedName = vacation?.vacationReports?.[0]?.reserved;

              return (
                <React.Fragment key={index}>
                  <Td>
                    <div className="subHeaderVacation">
                      <p style={{ textAlign: "center" }}>
                        {reservedName?.main}
                      </p>
                    </div>
                  </Td>
                  <Td>
                    <div className="subHeaderVacation">
                      <p style={{ textAlign: "center" }}>
                        {reservedName?.additional}
                      </p>
                    </div>
                  </Td>
                  <Td>
                    <div className="subHeaderVacation">
                      <p style={{ textAlign: "center" }}>
                        {reservedName?.total}
                      </p>
                    </div>
                  </Td>
                </React.Fragment>
              );
            })}
          </>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <Td colSpan={"3"}>
            {row?.vacationTrackings.map((vacation, index) => {
              const reservedName = vacation?.vacationReports?.[0]?.reserved;

              return (
                <React.Fragment key={index}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      gap: "20px",
                      borderBottom:
                        row?.vacationTrackings?.length - 1 === index
                          ? "none"
                          : "1px solid rgba(255, 255, 255, 0.05)",
                    }}
                  >
                    <Td>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>
                          {reservedName?.main}
                        </p>
                      </div>
                    </Td>
                    <Td>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>
                          {reservedName?.additional}
                        </p>
                      </div>
                    </Td>
                    <Td>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>
                          {reservedName?.total}
                        </p>
                      </div>
                    </Td>
                  </div>
                  {/* <hr
                      style={{
                        display:
                          row?.vacationTrackings?.length - 1 === index
                            ? "none"
                            : "block",
                      }}
                      className="vacationDivide"
                    /> */}
                </React.Fragment>
              );
            })}
          </Td>
        );
      }
      return null;
    },
    isSubHeader: true,
    colSpan: "3",
    subHeader: [
      {
        name: "Əsas",
        colmun: "vacationReportReservedMain",
        filterType: OPERATORS_CONTAINING.NUMBER,
      },
      {
        name: "Əlavə",
        colmun: "vacationReportReservedAdditional",
        filterType: OPERATORS_CONTAINING.NUMBER,
      },
      {
        name: "Cəmi",
        colmun: "vacationReportReservedTotal",
        filterType: OPERATORS_CONTAINING.NUMBER,
      },
    ],
    filterType: OPERATORS_CONTAINING.NUMBER,
  }),
  new Column({
    headerName: "İstifadə edilmiş məzuniyyət",
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <>
            {row?.vacationTrackings.map((vacation, index) => {
              const usedName = vacation?.vacationReports?.[0]?.used;

              return (
                <React.Fragment key={index}>
                  <Td>
                    <div className="subHeaderVacation">
                      <p style={{ textAlign: "center" }}>{usedName?.main}</p>
                    </div>
                  </Td>
                  <Td>
                    <div className="subHeaderVacation">
                      <p style={{ textAlign: "center" }}>
                        {usedName?.additional}
                      </p>
                    </div>
                  </Td>
                  <Td>
                    <div className="subHeaderVacation">
                      <p style={{ textAlign: "center" }}>{usedName?.total}</p>
                    </div>
                  </Td>
                </React.Fragment>
              );
            })}
          </>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <Td colSpan={"3"}>
            {row?.vacationTrackings.map((vacation, index) => {
              const usedName = vacation?.vacationReports?.[0]?.used;
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    gap: "20px",
                    borderBottom:
                      row?.vacationTrackings?.length - 1 === index
                        ? "none"
                        : "1px solid rgba(255, 255, 255, 0.05)",
                  }}
                  key={index}
                >
                  <Td>
                    <div className="subHeaderVacation">
                      <p style={{ textAlign: "center" }}>{usedName?.main}</p>
                    </div>
                  </Td>
                  <Td>
                    <div className="subHeaderVacation">
                      <p style={{ textAlign: "center" }}>
                        {usedName?.additional}
                      </p>
                    </div>
                  </Td>
                  <Td>
                    <div className="subHeaderVacation">
                      <p style={{ textAlign: "center" }}>{usedName?.total}</p>
                    </div>
                  </Td>
                </div>
              );
            })}
          </Td>
        );
      }
      return null;
    },
    isSubHeader: true,
    colSpan: "3",
    subHeader: [
      {
        name: "Əsas",
        colmun: "vacationReportUsedMain",
        filterType: OPERATORS_CONTAINING.NUMBER,
      },
      {
        name: "Əlavə",
        colmun: "vacationReportUsedAdditional",
        filterType: OPERATORS_CONTAINING.NUMBER,
      },
      {
        name: "Cəmi",
        colmun: "vacationReportUsedTotal",
        filterType: OPERATORS_CONTAINING.NUMBER,
      },
    ],
    filterType: OPERATORS_CONTAINING.NUMBER,
  }),
  new Column({
    headerName: "Qalıq məzuniyyət günləri",
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <>
            {row?.vacationTrackings.map((vacation, index) => {
              const unUsedName = vacation?.vacationReports?.[0]?.unUsed;

              return (
                <React.Fragment key={index}>
                  <Td>
                    <div className="subHeaderVacation">
                      <p style={{ textAlign: "center" }}>{unUsedName?.main}</p>
                    </div>
                  </Td>
                  <Td>
                    <div className="subHeaderVacation">
                      <p style={{ textAlign: "center" }}>
                        {unUsedName?.additional}
                      </p>
                    </div>
                  </Td>
                  <Td>
                    <div className="subHeaderVacation">
                      <p style={{ textAlign: "center" }}>{unUsedName?.total}</p>
                    </div>
                  </Td>
                </React.Fragment>
              );
            })}
          </>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <Td colSpan={"3"}>
            {row?.vacationTrackings.map((vacation, index) => {
              const unUsedName = vacation?.vacationReports?.[0]?.unUsed;
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    gap: "20px",
                    borderBottom:
                      row?.vacationTrackings?.length - 1 === index
                        ? "none"
                        : "1px solid rgba(255, 255, 255, 0.05)",
                  }}
                  key={index}
                >
                  <Td>
                    <div className="subHeaderVacation">
                      <p style={{ textAlign: "center" }}>{unUsedName?.main}</p>
                    </div>
                  </Td>
                  <Td>
                    <div className="subHeaderVacation">
                      <p style={{ textAlign: "center" }}>
                        {unUsedName?.additional}
                      </p>
                    </div>
                  </Td>
                  <Td>
                    <div className="subHeaderVacation">
                      <p style={{ textAlign: "center" }}>{unUsedName?.total}</p>
                    </div>
                  </Td>
                </div>
              );
            })}
          </Td>
        );
      }
      return null;
    },
    isSubHeader: true,
    colSpan: "3",
    filterType: OPERATORS_CONTAINING.NUMBER,
    subHeader: [
      {
        name: "Əsas",
        colmun: "vacationReportUnUsedMain",
        filterType: OPERATORS_CONTAINING.NUMBER,
      },
      {
        name: "Əlavə",
        colmun: "vacationReportUnUsedAdditional",
        filterType: OPERATORS_CONTAINING.NUMBER,
      },
      {
        name: "Cəmi",
        colmun: "vacationReportUnUsedTotal",
        filterType: OPERATORS_CONTAINING.NUMBER,
      },
    ],
  }),
];
