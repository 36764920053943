import { api } from "../../api/axiosInterceptor";

export const getEmployeeRequestManager = async (id, isGroup = false) => {
  const url = isGroup
    ? `/employee/employee-request/manager/${id}?groupBy=${isGroup}`
    : `/employee/employee-request/manager/${id}`;
  return await api.get(url);
};

export const getEmployeeRequest = async (id, isGroup = false) => {
  const url = isGroup
    ? `/employee/vacation-request/${id}?groupBy=${isGroup}`
    : `/employee/vacation-request/${id}`;
  return await api.get(url);
};

export const getDownloadVacationrRequest = async ({
  columns,
  filters = undefined,
  isWithFilter,
}) => {
  const url = isWithFilter
    ? `/employee/employee-request/manager/download?requestType=VacationRequest&columns=${columns}&filter=${filters}`
    : `/employee/employee-request/manager/download?requestType=VacationRequest&columns=${columns}`;

  return await api.get(url);
};
