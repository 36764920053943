import React, { useState } from "react";
import { Field, FieldArray, Formik, Form } from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  Tooltip,
  Box,
  CardContent,
  Card,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";

import { updateEmployee } from "../../api/api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { bankInfoValidation } from "../employees/Validations";
import CustomAccordion from "../ui/Accordion/Accordion";

const EditBankInfo = ({ employeeById, submitted, setSubmitted }) => {
  const handleSubmit = async (
    values,
    { setSubmitting, validateForm, resetForm }
  ) => {
    setSubmitting(true);

    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      // const hasDuplicate = checkUniquenessForNext(
      //   values.bankInfos,
      //   "iban",
      //   "IBAN"
      // );

      const modifiedValues = {
        ...values,
        bankInfos:
          values?.bankInfos &&
          values?.bankInfos.map((item) => ({
            ...item,
            bankCode: parseInt(item.bankCode),
            accountNumber: item.accountNumber.trim(),
            iban: item.iban.trim(),
          })),
      };
      await updateEmployee(employeeById._id, modifiedValues);
      setSubmitting(false);
      setSubmitted(!submitted);
      resetForm({ values });
    } else {
      setSubmitting(false);
    }
  };

  const handleBankSelect = (item) => {
    const selectBank = banks.find((bank) => {
      return bank.name === item;
    });
    return selectBank;
  };

  const banks = [
    { name: "Azərbaycan Beynəlxalq Bankı", voen: "9900001881", code: "805250" },
    { name: "Accessbank", voen: "1400057421", code: "505000" },
    { name: "AFB Bank", voen: "1301703781", code: "503217" },
    { name: "Azərbaycan Sənayə Bankı", voen: "9900007981", code: "509664" },
    { name: "Azər-Türk bankı", voen: "9900006111", code: "507699" },
    { name: "Bank Avrasiya", voen: "1700792251", code: "505129" },
    { name: "Bank of Baku", voen: "1700038881", code: "506924" },
    { name: "BTB Bank", voen: "1302164881", code: "501145" },
    { name: "Bank Respublika", voen: "9900001901", code: "505668" },
    { name: "Bank VTB (Azərbaycan)", voen: "1400117231", code: "506623" },
    { name: "Expressbank", voen: "1500031691", code: "505099" },
    { name: "Kapital Bank", voen: "9900003611", code: "200004" },
    { name: "Melli İran Bankı ( Bakı )", voen: "1300036291", code: "509761" },
    { name: "Muğanbank", voen: "1400122681", code: "506162" },
    { name: "Naxçıvanbank", voen: "0200432771", code: "505152" },
    { name: "YELO Bank", voen: "9900014901", code: "507064" },
    { name: "PAŞA Bank", voen: "1700767721", code: "505141" },
    { name: "Rabitəbank", voen: "9900001061", code: "506399" },
    { name: "Turanbank", voen: "1300016391", code: "507462" },
    { name: "Unibank", voen: "1300017201", code: "505754" },
    { name: "Xalq Bank", voen: "2000296061", code: "505055" },
    { name: "Yapı Kredi Bank Azərbaycan", voen: "9900009021", code: "509987" },
    { name: "Ziraat Bank Azərbaycan", voen: "1303953611", code: "512372" },
  ];

  return (
    <CustomAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h6>Bank məlumatları</h6>
      </AccordionSummary>
      <AccordionDetails>
        {employeeById ? (
          <Formik
            initialValues={{
              bankInfos: employeeById.bankInfos || [], // Set the initial laborActivity values from employeeById
            }}
            validationSchema={bankInfoValidation(employeeById)}
            onSubmit={handleSubmit}
          >
            {({
              values,
              isValid,
              errors,
              isSubmitting,
              handleChange,
              setFieldValue,
              validateForm,
              setErrors,
              touched,
              dirty,
            }) => (
              <>
                <Form>
                  <FieldArray name="bankInfos">
                    {({ push, remove }) => (
                      <div>
                        {values?.bankInfos &&
                          values?.bankInfos.map((form, index) => (
                            <div key={index}>
                              <>
                                <Typography mt={3} mb={3} variant="h6">
                                  {index + 1})Bank məlumatları
                                </Typography>

                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid sx={{ width: "100%" }}>
                                    <FormControl
                                      error={
                                        errors?.bankName &&
                                        touched?.bankInfos?.bankName &&
                                        errors?.bankInfos[index]?.bankName
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`bankInfos[${index}].bankName`}
                                      >
                                        Banklar
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`bankInfos[${index}].bankName`}
                                        labelId={`bankInfos[${index}].bankName`}
                                        label="Banklar"
                                        id="bankName-select"
                                        onChange={(e) => {
                                          setFieldValue(
                                            `bankInfos[${index}].bankName`,
                                            e.target.value
                                          );
                                          setFieldValue(
                                            `bankInfos[${index}].bankCode`,
                                            handleBankSelect(e.target.value)
                                              .code
                                          );
                                          setFieldValue(
                                            `bankInfos[${index}].bankVoen`,
                                            handleBankSelect(e.target.value)
                                              .voen
                                          );
                                        }}
                                        fullWidth
                                        error={
                                          errors?.bankInfos?.[index]
                                            ?.bankName &&
                                          touched?.bankInfos?.[index]
                                            ?.bankName &&
                                          Boolean(
                                            errors?.bankInfos?.[index]?.bankName
                                          )
                                        }
                                      >
                                        {banks &&
                                          banks
                                            .sort((a, b) =>
                                              a.name.localeCompare(b.name, "az")
                                            )
                                            .map((item, index) => (
                                              <MenuItem
                                                key={index}
                                                value={item.name}
                                              >
                                                {item.name}
                                              </MenuItem>
                                            ))}
                                      </Field>

                                      {/* {errors?.bankInfos[index]?.bankName &&
                                  errors?.bankInfos[index]?.bankName && (
                                    <FormHelperText error>
                                      {errors?.bankInfos[index]?.bankName}
                                    </FormHelperText>
                                  )} */}
                                    </FormControl>
                                  </Grid>
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      type="number"
                                      name={`bankInfos[${index}].bankCode`}
                                      as={TextField}
                                      label="Bank kod"
                                      value={values.bankInfos[index].bankCode}
                                      defaultValue={
                                        values.bankInfos[index].bankCode
                                      }
                                      fullWidth
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </Grid>
                                </Grid>

                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      type="text"
                                      name={`bankInfos[${index}].bankVoen`}
                                      as={TextField}
                                      label="Bank VÖEN"
                                      value={values.bankInfos[index].bankVoen}
                                      defaultValue={
                                        values.bankInfos[index].bankVoen
                                      }
                                      fullWidth
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </Grid>
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      type="text"
                                      name={`bankInfos[${index}].accountNumber`}
                                      as={TextField}
                                      label="Müxbir Hesab nömrəsi *"
                                      error={
                                        errors?.bankInfos?.[index]
                                          ?.accountNumber &&
                                        touched?.bankInfos?.[index]
                                          ?.accountNumber &&
                                        Boolean(
                                          errors?.bankInfos?.[index]
                                            ?.accountNumber
                                        )
                                      }
                                      helperText={
                                        errors?.bankInfos?.[index]
                                          ?.accountNumber &&
                                        touched?.bankInfos?.[index]
                                          ?.accountNumber &&
                                        errors?.bankInfos?.[index]
                                          ?.accountNumber
                                      }
                                      fullWidth
                                    />
                                  </Grid>
                                </Grid>

                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      type="text"
                                      name={`bankInfos[${index}].iban`}
                                      as={TextField}
                                      label="IBAN"
                                      error={
                                        errors?.bankInfos?.[index]?.iban &&
                                        touched?.bankInfos?.[index]?.iban &&
                                        Boolean(
                                          errors?.bankInfos?.[index]?.iban
                                        )
                                      }
                                      helperText={
                                        errors?.bankInfos?.[index]?.iban &&
                                        touched?.bankInfos?.[index]?.iban &&
                                        errors?.bankInfos?.[index]?.iban
                                      }
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      type="text"
                                      name={`bankInfos[${index}].cardExpirationDate`}
                                      as={TextField}
                                      label="Kartın etibarlıq müddəti"
                                      onChange={async (e) => {
                                        let value = e.target.value;

                                        // Automatically add slash after MM
                                        if (/^\d{2}$/.test(value)) {
                                          value = value + "/";
                                        }
                                        if (value.length > 7) {
                                          value = value.slice(0, 7);
                                        }

                                        setFieldValue(
                                          `bankInfos[${index}].cardExpirationDate`,
                                          value
                                        );

                                        if (
                                          errors?.bankInfos?.[index]
                                            ?.cardExpirationDate
                                        ) {
                                          setErrors({
                                            ...errors,
                                            bankInfos: [
                                              ...errors.bankInfos.slice(
                                                0,
                                                index
                                              ),
                                              {
                                                ...errors.bankInfos[index],
                                                cardExpirationDate: "",
                                              },
                                              ...errors.bankInfos.slice(
                                                index + 1
                                              ),
                                            ],
                                          });
                                        }
                                      }}
                                      error={
                                        errors?.bankInfos?.[index]
                                          ?.cardExpirationDate &&
                                        touched?.bankInfos?.[index]
                                          ?.cardExpirationDate &&
                                        Boolean(
                                          errors?.bankInfos?.[index]
                                            ?.cardExpirationDate
                                        )
                                      }
                                      helperText={
                                        errors?.bankInfos?.[index]
                                          ?.cardExpirationDate &&
                                        touched?.bankInfos?.[index]
                                          ?.cardExpirationDate &&
                                        errors?.bankInfos?.[index]
                                          ?.cardExpirationDate
                                      }
                                      fullWidth
                                    />
                                  </Grid>
                                </Grid>
                              </>

                              <Button
                                sx={{ mt: 3 }}
                                variant="contained"
                                color="error"
                                type="button"
                                onClick={() => remove(index)}
                              >
                                Bank məlumatını sil
                              </Button>
                            </div>
                          ))}
                        <Button
                          sx={{ mt: 3 }}
                          variant="contained"
                          type="button"
                          onClick={() =>
                            push({ bankCode: "", bankVoen: "", iban: "" })
                          }
                        >
                          Bank məlumatları əlavə et
                        </Button>
                      </div>
                    )}
                  </FieldArray>

                  <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      Dəyişiklikləri yadda saxla
                    </Button>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        ) : (
          <div>Loading...</div>
        )}
      </AccordionDetails>
    </CustomAccordion>
  );
};

export default EditBankInfo;
