import React, { useState } from "react";
import { ToastContainer } from "react-toastify";

// Components
import Header from "../../components/layouts/header/Header";
import Breadcrumb from "../../components/layouts/breadcrumb/Breadcrumb";
import MyCabinetNavbar from "./MyCabinetNavbar";

// Material UI
import { Divider, Grid } from "@mui/material";
import InventoryIcon from "@mui/icons-material/Inventory";

// CSS
import style from "./myProfileStyle.module.css";
import MCInventories from "./inventories_tables/MCInventoriesTable";
import MCTransportationTable from "./inventories_tables/MCTransportationTable";
import Navbar from "./Navbar";

export default function Inventories() {
  const [navbarStatus, setNavbarStatus] = useState(false);

  const headerData = {
    title: "Inventarlar",
    variant: "h3",
  };

  // const showHandler = () => setNavbarStatus(!navbarStatus);

  const navbarStatusHandler = (currentValue) => {
    setNavbarStatus(currentValue);
  };

  return (
    <>
      <Grid
        className={style.navbarPlace}
        // onMouseEnter={showHandler}
        // onMouseLeave={showHandler}
      >
        {/* {navbarStatus === false ? (
          <Grid className={style.navbarProfile}>
            <InventoryIcon sx={{ width: "95%", height: "95%" }} />
          </Grid>
        ) : (
          <MyCabinetNavbar />
        )} */}
        <Navbar navbarStatus={navbarStatusHandler} />
      </Grid>
      <React.Fragment>
        <Grid>
          <Grid>
            <Grid>
              <Header data={headerData} />
            </Grid>
          </Grid>
          <ToastContainer />
          <Divider sx={{ marginTop: 5 }} />
          <Grid
            className={
              style.infoSection +
              " " +
              (navbarStatus ? style.animation2 : style.animation1)
            }
          >
            <Grid className={style.inventoriesFirstSection}>
              <Grid
                sx={{ width: "100%", textAlign: "center", fontSize: "30px" }}
              >
                İnventar cədvəli
              </Grid>
              <MCInventories />
            </Grid>
            <Grid className={style.inventoriesSecondSection}>
              <Grid
                sx={{ width: "100%", textAlign: "center", fontSize: "30px" }}
              >
                Nəqliyyat vasitələri
              </Grid>
              <MCTransportationTable />
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    </>
  );
}
