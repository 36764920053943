import { CircularProgress, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import Circle from "../layouts/circleDetail/Circle";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function StatisticDiagramCircle({
  headerText,
  data,
  height,
  keys,
  colors,
  titles,
  loading,
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        padding: "10px",
        borderRadius: "2px",
        height: height,
      }}
    >
      <Grid item key="header-container" m={"auto"}>
        <Typography variant="h3" width={"100%"} textAlign={"center"}>
          {headerText}
        </Typography>
      </Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1px",
        }}
        // justifyContent={isSmallScreen ? "center" : "normal"}
        // alignItems="flex-start"
        marginTop="10px"
      >
        {loading ? (
          <Grid sx={styles.loadingContainer}>
            <CircularProgress color="inherit" />
          </Grid>
        ) : (
          keys?.map((item, index) => {
            return (
              <Grid
                item
                key={index}
                sx={{
                  width: `${100 / Object.keys(data).length}%`,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  padding: "10px",
                  flex: "1 15%",
                }}
              >
                <Circle
                  value={data[item]}
                  color={colors[item]}
                  borderWidth={5}
                  size={1.2}
                />
                <Grid width="100%" sx={{ marginTop: "10px" }}>
                  <Typography textAlign="center" color={colors[item]}>
                    {titles[item]}
                  </Typography>
                </Grid>
              </Grid>
            );
          })
        )}
      </Grid>
    </Grid>
  );
}

const styles = {
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
