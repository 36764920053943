import { useState } from "react";

import style from "../myProfileStyle.module.css";
import { CardContent, Grid, Card, Tooltip, Typography } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import MCViewContactInfo from "../Personal_informations_view_part/MCViewContactInfo";

export default function ShowContactInfo({ employeeById }) {
  const [showContactInfo, setShowContactInfo] = useState(false);

  return (
    <>
      <Grid className={style.myCabinetThirdSection}>
        <Card mb={6}>
          <CardContent sx={{ "&:last-child": { pb: 4 } }}>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid sx={{ width: "100%" }}>
                <Typography variant="h6">Əlaqə məlumatları</Typography>
              </Grid>

              <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{ justifyContent: "flex-end" }}
              >
                <Tooltip title="Əlaqə məlumatlarını göstər">
                  {showContactInfo ? (
                    <KeyboardArrowUpIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setShowContactInfo(!showContactInfo);
                      }}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setShowContactInfo(!showContactInfo);
                      }}
                    />
                  )}
                </Tooltip>
              </Grid>
            </Grid>

            {showContactInfo && (
              <>
                <MCViewContactInfo employeeById={employeeById} />
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
