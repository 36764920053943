import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const EditAttachment = ({
  openEditAttachment,
  setOpenEditAttachment,
  selectedAttachment,
  handleEditAttachment,
}) => {
  const [editAttachmentName, setEditAttachmentName] = useState("");

  useEffect(() => {
    if (openEditAttachment) {
      setEditAttachmentName(selectedAttachment.name);
    }
  }, [openEditAttachment]);

  return (
    <>
      <Dialog
        fullWidth
        open={openEditAttachment}
        onClose={() => {
          setOpenEditAttachment(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4">Təhkimi redaktə edin</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Adı"
            type="text"
            value={editAttachmentName}
            onChange={(e) => setEditAttachmentName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenEditAttachment(false);
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          <Button
            onClick={() => {
              setOpenEditAttachment(false);
              handleEditAttachment(editAttachmentName);
            }}
            color="primary"
            disabled={editAttachmentName.trim() === ""}
          >
            Dəyişdir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditAttachment;
