import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getCommonData, updateCommontData } from "../../api/api";
import { ErrorMessage, Field } from "formik";
import { handleUniqueness } from "../employees/Validations";

const pinRegex = /^[a-hj-np-zA-HJ-NP-Z0-9]*$/;

const EditCommonDataDialog = ({
  openEditDialog,
  setOpenEditDialog,
  state,
  dispatch,
}) => {
  const { selectedRow, selectedType, submitted } = state;

  const [errors, setErrors] = useState({
    name: false,
    firstname: false,
    lastname: false,
    fathername: false,
    pin: false,
    type: false,
    value: false,
    vacation: false,
  });

  //   update company by id
  const [newName, setNewName] = useState(selectedRow?.name);
  const [firstname, setFirstname] = useState(selectedRow?.firstname);
  const [lastname, setLastname] = useState(selectedRow?.lastname);
  const [fathername, setFathername] = useState(selectedRow?.fathername);
  const [pin, setPin] = useState(selectedRow?.pin);

  const [socialValue, setSocialValue] = useState(selectedRow.value);
  const [reason, setReason] = useState(selectedRow.reason);
  const [wageType, setWageType] = useState(selectedRow.valueType);
  const [itemTypes, setItemTypes] = useState([]);
  const [itemType, setItemType] = useState(selectedRow.parent);
  const [vacationDays, setVacationDays] = useState(selectedRow.vacationDays);

  const handleUpdateCommonName = async () => {
    let payload = [];
    if (
      selectedType[0] === "socialStatusTypes" ||
      selectedType[0] === "overtimeRules" ||
      selectedType[0] === "academicDegrees"
    ) {
      payload = [
        {
          field: selectedType[0],
          oldValue: selectedRow.name,
          newValue: {
            name: newName ? newName : selectedRow.name,
            value: socialValue ? Number(socialValue) : selectedRow.value,
          },
        },
      ];
    } else if (selectedType[0] === "supplementalWageLabour") {
      payload = [
        {
          field: selectedType[0],
          oldValue: selectedRow.name,
          newValue: {
            name: newName ? newName : selectedRow.name,
            value: socialValue ? Number(socialValue) : selectedRow.value,
            reason: reason ? reason : selectedRow.reason,
            valueType: wageType ? wageType : selectedRow.wageType,
          },
        },
      ];
    } else if (selectedType[0] === "supplementalWageLabourItems") {
      payload = [
        {
          field: selectedType[0],
          oldValue: selectedRow.name,
          newValue: {
            name: newName ? newName : selectedRow.name,
            value: socialValue ? Number(socialValue) : selectedRow.value,
            reason: reason ? reason : selectedRow.reason,
            valueType: wageType ? wageType : selectedRow.wageType,
            parent: itemType ? itemType : selectedRow.parent,
            vacationDays: vacationDays
              ? Number(vacationDays)
              : Number(selectedRow.vacationDays),
          },
        },
      ];
    } else if (
      selectedType[0] === "inventoryItems" ||
      selectedType[0] === "carModels"
    ) {
      payload = [
        {
          field: selectedType[0],
          oldValue: selectedRow.name,
          newValue: {
            name: newName ? newName : selectedRow.name,
            parent: itemType ? itemType : selectedRow.parent,
          },
        },
      ];
    } else if (selectedType[0] === "recruiterList") {
      payload = [
        {
          field: selectedType[0],
          oldValue: {
            name: selectedRow?.name,
            lastname: selectedRow?.lastname,
            fathername: selectedRow?.fathername,
            pin: selectedRow?.pin,
          },
          newValue: {
            name: newName ? newName : selectedRow?.name,
            lastname: lastname ? lastname : selectedRow?.lastname,
            fathername: fathername ? fathername : selectedRow?.fathername,
            pin: pin ? pin : selectedRow?.pin,
          },
        },
      ];
    } else {
      payload = [
        {
          field: selectedType[0],
          oldValue: selectedRow.name,
          newValue: newName ? newName : selectedRow.name,
        },
      ];
    }
    await updateCommontData(payload);
    setItemType("");
    setNewName();
    setSocialValue();
    setReason();
    setVacationDays();
    dispatch({ type: "SET_SUBMITTED", payload: !submitted });
  };

  const getInventoryTypes = async () => {
    if (selectedType[0] === "inventoryItems") {
      try {
        const response = await getCommonData("inventoryTypes");
        setItemTypes(response.data);
      } catch (error) {
        console.log(error);
      }
    } else if (selectedType[0] === "carModels") {
      try {
        const response = await getCommonData("carManufacturers");
        setItemTypes(response.data);
      } catch (error) {
        console.log(error);
      }
    } else if (selectedType[0] === "supplementalWageLabourItems") {
      // try {
      //   const response = await getCommonData("supplementalWageLabourItems");
      const types = ["Ağır Zərərli", "Zərərli"];
      setItemTypes(types);
      // } catch (error) {
      //   console.log(error);
      // }
    }
  };

  const isValidInput = (input) => {
    const regex = /^[a-hj-np-zA-HJ-NP-Z0-9]*$/;
    return regex.test(input);
  };

  const isDisabled = (key) => {
    if (key === "recruiterList") {
      // Check conditions for "recruiterList"
      return !(
        (newName?.trim() || newName !== selectedRow.name) &&
        (lastname?.trim() || lastname !== selectedRow.lastname) &&
        (fathername?.trim() || fathername !== selectedRow.fathername) &&
        ((pin?.trim() && isValidInput(pin?.trim())) || pin !== selectedRow.pin)
      );
    } else {
      // Default case or any other case
      return (
        (!selectedRow?.name || !newName?.trim()) &&
        itemType === selectedRow.parent
      );
    }
  };

  useEffect(() => {
    getInventoryTypes();
  }, [selectedType]);

  useEffect(() => {
    const checkErrors = async () => {
      const checkPin = async (value) => {
        if (!value || value.trim() === "")
          return "FIN nömrəsi daxil edilməlidir";
        else if (value.length < 6)
          return "FIN nömrəsi minimum 6 simvol olmalıdır";
        else if (value.length > 7)
          return "FIN nömrəsi maksimum 7 simvol olmalıdır";
        else if (!pinRegex.test(value)) return "FIN nömrəsi düzgün deyil";
        else if (await !handleUniqueness("pin", value))
          return "Bu FİN artıq istifadə edilib";
        else return false;
      };

      const newErrors = {
        pin: await checkPin(
          pin !== selectedRow?.pin?.trim() ? pin : selectedRow?.pin?.trim()
        ),
      };
      setErrors(newErrors);
    };

    checkErrors();
  }, [pin]);

  useEffect(() => {
    setItemType(selectedRow.parent);
  }, [selectedRow]);
  return (
    <>
      <Dialog
        fullWidth
        open={openEditDialog}
        onClose={() => {
          setOpenEditDialog(false);
          setNewName("");
          setItemType("");
          setItemType("");
          setSocialValue();
          setReason();
          setVacationDays();
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Adı dəyişdir</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Adını dəyişdir"
            defaultValue={selectedRow?.name?.trim()}
            type="text"
            onChange={(e) => setNewName(e.target.value)}
            fullWidth
            sx={{ mb: 3 }}
          />

          {selectedType[0] === "recruiterList" ? (
            <>
              <TextField
                margin="dense"
                label="Soyadını dəyişdir"
                defaultValue={selectedRow?.lastname?.trim()}
                type="text"
                fullWidth
                sx={{ mb: 3 }}
                id="lastname"
                name="lastname"
                onKeyDown={(e) => {
                  if (e.key === "-") {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setLastname(e.target.value);
                }}
              />
              <TextField
                margin="dense"
                id="fathername"
                name="fathername"
                style={{ width: "100%" }}
                defaultValue={selectedRow?.fathername?.trim()}
                label="Ata adını dəyişdir"
                onKeyDown={(e) => {
                  if (e.key === "-") {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setFathername(e.target.value);
                }}
              />
              <TextField
                margin="dense"
                id="pin"
                name="pin"
                defaultValue={selectedRow?.pin?.trim()}
                style={{ width: "100%" }}
                value={pin}
                label="FİN-i dəyişdir"
                onKeyDown={(e) => {
                  if (e.key === "-") {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setPin(e.target.value);
                }}
              />
              {errors?.pin?.length > 0 ? (
                <span style={{ fontSize: "12px", color: "#f44336" }}>
                  {errors.pin}
                </span>
              ) : null}
            </>
          ) : null}

          {(selectedType[0] === "inventoryItems" ||
            selectedType[0] === "carModels" ||
            selectedType[0] === "supplementalWageLabourItems") && (
            <FormControl fullWidth>
              <InputLabel id={`type`}>Tipi *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={itemType ? itemType : selectedRow.parent}
                label="Age"
                onChange={(e) => {
                  setItemType(e.target.value);
                }}
              >
                {itemTypes.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.trim()}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}

          {selectedType[0] === "socialStatusTypes" ||
          selectedType[0] === "overtimeRules" ||
          selectedType[0] === "academicDegrees" ||
          selectedType[0] === "supplementalWageLabour" ||
          selectedType[0] === "supplementalWageLabourItems" ? (
            <Box sx={{ display: "flex", gap: 5 }}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Dəyəri dəyişdir"
                defaultValue={selectedRow.value}
                onKeyDown={(e) => {
                  if (e.key === "-") {
                    e.preventDefault();
                  }
                }}
                type="number"
                onChange={(e) => setSocialValue(e.target.value)}
                fullWidth
              />

              {(selectedType[0] === "supplementalWageLabour" ||
                selectedType[0] === "supplementalWageLabourItems") && (
                <FormControl component="fieldset" variant="standard">
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    defaultValue={selectedRow.valueType}
                    onChange={(e) => {
                      setWageType(e.target.value);
                    }}
                    row
                  >
                    <FormControlLabel
                      value="number"
                      control={<Radio color="primary" />}
                      label="Dəyər"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="percentage"
                      control={<Radio color="primary" />}
                      label="Faiz"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </Box>
          ) : null}

          {selectedType[0] === "supplementalWageLabourItems" ? (
            <TextField
              margin="dense"
              id="reason"
              label="Məzuniyyət günlərinin sayı"
              onKeyDown={(e) => {
                if (e.key === "-") {
                  e.preventDefault();
                }
              }}
              type="number"
              defaultValue={selectedRow.vacationDays}
              onChange={(e) => setVacationDays(e.target.value)}
              fullWidth
            />
          ) : null}

          {selectedType[0] === "supplementalWageLabour" ||
          selectedType[0] === "supplementalWageLabourItems" ? (
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Səbəbini dəyişdir"
              defaultValue={selectedRow.reason}
              type="text"
              onChange={(e) => setReason(e.target.value)}
              fullWidth
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenEditDialog(false);
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          <Button
            color="primary"
            onClick={() => {
              setOpenEditDialog(false);
              handleUpdateCommonName();
            }}
            disabled={isDisabled(selectedType[0])}
          >
            Redaktə et
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditCommonDataDialog;
