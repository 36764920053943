//fetch.worker.js

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  //fetch.worker.js

  const fetchJSON = async (url, options) => {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(
        `Failed to fetch: ${response.status} ${response.statusText}`
      );
    }
    return await response.json();
  };

  const getAllKeywords = (versionsList) => {
    return versionsList.map((item) => item.keyword);
  };

  const fetchKeywordData = async (keyword, url, headers) => {
    const response = await fetchJSON(`${url}"${keyword}"`, {
      method: "GET",
      headers: { ...headers },
    });
    return { [response.keyword]: response };
  };

  const constructURLWithKeywords = (url, keywords) => {
    const queryString = keywords.map((keyword) => `"${keyword}"`).join(",");
    return `${url}[${queryString}]`;
  };

  const fetchListData = async (keywords, url, headers) => {
    try {
      const constructedURL = constructURLWithKeywords(url, keywords);
      const response = await fetchJSON(constructedURL, {
        method: "GET",
        headers,
      });
      const modifiedList = response.reduce((acc, item) => {
        acc[item.keyword] = item;
        return acc;
      }, {});
      return modifiedList;
    } catch (error) {
      console.error("Error fetching list data:", error);
      return {};
    }
  };

  const updateListWithNewData = async (currentList, versions, url, headers) => {
    const keywordsToUpdate = versions.filter(
      (version) =>
        !currentList[version.keyword] ||
        currentList[version.keyword].version !== version.version
    );
    if (keywordsToUpdate.length === 0) {
      return null;
    }
    const newData = await fetchListData(
      getAllKeywords(keywordsToUpdate),
      url,
      headers
    );
    return { ...currentList, ...newData };
  };
  // eslint-disable-next-line no-restricted-globals
  self.onmessage = async (e) => {
    const { headers, versionsURL, dataURL, list } = e.data;

    try {
      const versions = await fetchJSON(versionsURL, { method: "GET", headers });
      const newList =
        list && Object.keys(list).length > 0
          ? await updateListWithNewData(list, versions, dataURL, headers)
          : await fetchListData(getAllKeywords(versions), dataURL, headers);
      if (!newList) {
        console.log("There is no new data");
      }
      postMessage(newList);
    } catch (error) {
      console.error("Worker error:", error);
    }
  };
};
