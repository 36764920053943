export const CONTRACT_CHANGES_HEADERS_STATISTICS = [
  {
    key: "pending",
    label: "İmza gözləyən əmək müqaviləsinə dəyişiklik edilmiş müqavilələri",
  },
  {
    key: "rejected",
    label: "İmtina edilmiş əmək müqaviləsinə dəyişiklik edilmiş müqavilələri",
  },
  {
    key: "approved",
    label: "İmzalanmış əmək müqaviləsinə dəyişiklik edilmiş müqavilələri",
  },
];
