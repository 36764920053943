import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import DocLayout from "./layouts/Doc";
import PresentationLayout from "./layouts/Presentation";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import GetOtp from "./pages/auth/GetOtp";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";

// Components
import Accordion from "./pages/components/accordion";
import Alerts from "./pages/components/alerts";
import Avatars from "./pages/components/avatars";
import Badges from "./pages/components/badges";
import Buttons from "./pages/components/buttons";
import Cards from "./pages/components/cards";
import Chips from "./pages/components/chips";
import Dialogs from "./pages/components/dialogs";
import Lists from "./pages/components/lists";
import Menus from "./pages/components/menus";
import Pagination from "./pages/components/pagination";
import Progress from "./pages/components/progress";
import Snackbars from "./pages/components/snackbars";
import Tooltips from "./pages/components/tooltips";

// Form components
import SelectionCtrls from "./pages/forms/SelectionControls";
import Selects from "./pages/forms/selects";
import TextFields from "./pages/forms/TextFields";

// Icon components
import MaterialIcons from "./pages/icons/MaterialIcons";

// Page components
import Blank from "./pages/pages/blank";
import InvoiceDetails from "./pages/pages/InvoiceDetails";
import InvoiceList from "./pages/pages/InvoiceList";
import Orders from "./pages/pages/Orders";
import Pricing from "./pages/pages/pricing";
import Projects from "./pages/pages/Projects";
import Settings from "./pages/pages/settings";
// import Chat from "./pages/pages/Chat";

// Operations components
import Management from "./pages/operations/Management";
import StaffDetails from "./pages/operations/StaffDetails";
import StructureNaming from "./pages/operations/StructureNaming";

// Documentation SubPages
import Commands from "./pages/documentation/command/Commands";
import CommandCreate from "./pages/documentation/command/create/CommandCreate";
import Contracts from "./pages/documentation/contract/Contracts";
import ContractCreate from "./pages/documentation/contract/create/ContractCreate";
import TemplateCreate from "./pages/documentation/template/create/TemplateCreate";
import TemplateEdit from "./pages/documentation/template/edit/TemplateEdit";
import Templates from "./pages/documentation/template/Templates";

// Company structure
import CompanyStructure from "./pages/company-structure/CompanyStructure";
import CompanyStructureDetail from "./pages/company-structure/detail/CompanyStructureDetail";

// Employees
import AddEmployee from "./pages/employee/AddEmployee";
import EditEmployee from "./pages/employee/EditEmployee";
import Employees from "./pages/employee/Employees";
import ViewEmployee from "./pages/employee/ViewEmployee";
import DataTable from "./pages/operations/DataTable";

// Chat structure
import Chat from "./pages/chat/Chat";

// Table components
import AdvancedTable from "./pages/tables/AdvancedTable";
import SimpleTable from "./pages/tables/SimpleTable";

// Documentation
import APICalls from "./pages/docs/APICalls";
import Auth0 from "./pages/docs/auth/Auth0";
import Cognito from "./pages/docs/auth/Cognito";
import Firebase from "./pages/docs/auth/Firebase";
import JWT from "./pages/docs/auth/JWT";
import Changelog from "./pages/docs/Changelog";
import Deployment from "./pages/docs/Deployment";
import EnvironmentVariables from "./pages/docs/EnvironmentVariables";
import ESLintAndPrettier from "./pages/docs/ESLintAndPrettier";
import GettingStarted from "./pages/docs/GettingStarted";
import Guards from "./pages/docs/Guards";
import Internationalization from "./pages/docs/Internationalization";
import Redux from "./pages/docs/Redux";
import Routing from "./pages/docs/Routing";
import Support from "./pages/docs/Support";
import Theming from "./pages/docs/Theming";
import Welcome from "./pages/docs/Welcome";

// Vacation Request
import DecisionChain from "./pages/decisionChain/DecisionChain";
import CreateVacationRequest from "./pages/vacation/createVacation/CreateVacationRequest";
import EmployeeVacations from "./pages/vacation/empPage/VacationEmployeePage";
import VacationHrPage from "./pages/vacation/hrPage/VacationHrPage";
import UpdateVacationRequest from "./pages/vacation/updateVacation/UpdateVacationRequest";

// Ticketing
import NewTicket from "./pages/ticketing/NewTicket";
import TicketingDetails from "./pages/ticketing/TicketingDetails";
import TicketList from "./pages/ticketing/TicketList";

// Landing

// Protected routes
import { Navigate } from "react-router-dom";
import AppointmentPage from "./pages/appointment/AppointmentPage";
import Application from "./pages/documentation/application/Application";
import ApplicationCreate from "./pages/documentation/application/create/ApplicationCreate";
import SigningContracts from "./pages/documentation/signing/SigningContracts";
import EmploymentContract from "./pages/employment-contracts";
import Leave from "./pages/leaves/Bulletin";
import Removal from "./pages/leaves/Removal";
import UnknownReason from "./pages/leaves/UnknownReason";
import StructureDetails from "./pages/operations/StructureDetails";
import ProtectedPage from "./pages/protected/ProtectedPage";
import DetailedHrVacations from "./pages/vacation/hrPage/DetailedHrVacations";
import VacationApproversPage from "./pages/vacation/mngrPage/VacationApproversPage";

// Mycabinet
import ChangeRequests from "./pages/change-requests/ChangeRequests";
import CompanyStructureRequests from "./pages/company-structure/structure-requests/CompanyStructureRequests";
import RegisterOfApplications from "./pages/documentation/application/registerOfApplication/RegisterOfApplications";
import RegisterOfCommands from "./pages/documentation/command/registerOfCommands/RegisterOfCommands";
import RegisterOfContracts from "./pages/documentation/contract/registerOfContracts/RegisterOfContracts";
import ViewTypeofContracts from "./pages/documentation/contract/ViewTypeofContract";
import EmpContsChanges from "./pages/employment-contracts-changes/EmpContsChanges";
import GraphicSettings from "./pages/graphic-settings/GraphicSettings";
import ExplanationsApplications from "./pages/myProfile/ExplanationsApplications";
import Inventories from "./pages/myProfile/Inventories";
import MyProfile from "./pages/myProfile/MyProfile";
import MyWages from "./pages/myProfile/MyWages";
import OrdersContracts from "./pages/myProfile/OrdersContracts";
import MyRequests from "./pages/myProfile/Requests";
import SickLeaveCertificate from "./pages/myProfile/SickLeaveCertificate";
import Vacations from "./pages/myProfile/Vacations";
import TSDesignSettings from "./pages/operations/TSDesignSettings";
import ProductionCalendarPage from "./pages/production-calendar/ProductionCalendarPage";
import WorkHourAccountPage from "./pages/work-hour-account-table/WorkHourAccountPage";
import WorkingHoursGraphic from "./pages/working-time/working-hours-graphic/WorkingHoursGraphic";
import TaskManagement from "./tasks/TaskManagement";
import VacationTracking from "./pages/vacation/vacation-tracking/VacationTracking";
import Dismissal from "./pages/leaves/Dismissal";
import VacationSchedule from "./pages/vacation/vacation-schedule/VacationSchedule";

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));
const Analytics = async(() => import("./pages/dashboards/Analytics"));
const SaaS = async(() => import("./pages/dashboards/SaaS"));

// Form components
const Pickers = async(() => import("./pages/forms/pickers"));
const Editors = async(() => import("./pages/forms/editors"));
const Formik = async(() => import("./pages/forms/formik"));

// Icon components
const FeatherIcons = async(() => import("./pages/icons/FeatherIcons"));
const Profile = async(() => import("./pages/pages/profile"));
const Calendar = async(() => import("./pages/pages/Calendar"));

// Table components
const DataGrid = async(() => import("./pages/tables/DataGrid"));

// Chart components
const Chartjs = async(() => import("./pages/charts/Chartjs"));
const ApexCharts = async(() => import("./pages/charts/ApexCharts"));

// Maps components
const GoogleMaps = async(() => import("./pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("./pages/maps/VectorMaps"));

const LabourContracts = async(() =>
  import("./pages/labour-contracts/LabourContracts")
);

// Task Managament
const TaskManagament = async(() =>
  import("./pages/task-management/TaskManagement")
);

const routes = [
  {
    path: "/",
    element: <PresentationLayout />,
    children: [
      // {
      //   path: "",
      //   element: <Landing />,
      // },
      {
        path: "",
        element: <Navigate to="/auth/sign-in" replace={true} />,
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "default",
        element: <Default />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
      {
        path: "saas",
        element: <SaaS />,
      },
    ],
  },
  {
    path: "operations",
    element: <DashboardLayout />,
    children: [
      {
        path: "structure-details",
        element: <StructureDetails />,
      },
      {
        path: "employer-details",
        element: <StaffDetails />,
      },
      {
        path: "structure-naming",
        element: <StructureNaming />,
      },
      {
        path: "data-table",
        element: <DataTable />,
      },
      {
        path: "ts-design-settings",
        element: <TSDesignSettings />,
      },
      // {
      //   path: "management",
      //   element: <Management />,
      // },
    ],
  },
  {
    path: "documentation",
    element: <DashboardLayout />,
    children: [
      {
        path: "commands/register-of-commands",
        element: <Commands />,
      },
      {
        path: "commands",
        element: <RegisterOfCommands />,
      },
      {
        path: "command/create",
        element: <CommandCreate />,
      },
      {
        path: "contract/register-of-contracts",
        element: <Contracts />,
      },
      {
        path: "contract/viewtypeofcontracts",
        element: <ViewTypeofContracts />,
      },
      {
        path: "contract/create",
        element: <ContractCreate />,
      },
      {
        path: "contracts",
        element: <RegisterOfContracts />,
      },
      {
        path: "template/:index",
        element: <Templates />,
      },
      {
        path: "template/create",
        element: <TemplateCreate />,
      },
      {
        path: "template/edit/:id",
        element: <TemplateEdit />,
      },
      {
        path: "application/register-of-applications",
        element: <Application />,
      },
      {
        path: "application/create",
        element: <ApplicationCreate />,
      },
      {
        path: "application",
        element: <RegisterOfApplications />,
      },
    ],
  },
  {
    path: "/signing",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <SigningContracts />,
      },
    ],
  },
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "employees",
        element: <Employees />,
      },
      {
        path: "employees/add-employee",
        element: <AddEmployee />,
      },
      {
        path: `employees/:id`,
        element: <EditEmployee />,
      },
      {
        path: `employees/:id/view`,
        element: <ViewEmployee />,
      },
      {
        path: "/change-requests",
        element: <ChangeRequests />,
      },
    ],
  },
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "/employment-contracts",
        element: <EmploymentContract />,
      },
      {
        path: "/employment-contracts-changes",
        element: <EmpContsChanges />,
      },
    ],
  },
  {
    path: "/decision-chain",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <DecisionChain />,
      },
    ],
  },
  {
    path: "/create-vacation-request",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <CreateVacationRequest />,
      },
    ],
  },
  {
    path: "/update-vacation/:id",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <UpdateVacationRequest />,
      },
    ],
  },
  {
    path: "/hr-vacation-requests",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <VacationHrPage />,
      },
      {
        path: "detailed",
        element: <DetailedHrVacations />,
      },
    ],
  },
  {
    path: "/vacation-tracking",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <VacationTracking />,
      },
    ],
  },
  {
    path: "/vacation-schedule",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <VacationSchedule />,
      },
    ],
  },
  {
    path: "/emp-vacation-requests",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <EmployeeVacations />,
      },
    ],
  },
  {
    path: "/approver-vacation-requests",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <VacationApproversPage />,
      },
    ],
  },
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "company-structure",
        element: <CompanyStructure />,
      },
      {
        path: "company-structure/:id",
        element: <CompanyStructureDetail />,
      },
      {
        path: "/structure-requests",
        element: <CompanyStructureRequests />,
      },
    ],
  },
  {
    path: "/labour-contract/:id",
    element: <LabourContracts />,
    // children: [
    //   {
    //     path: "labour-contract/:id",
    //     element: <LabourContracts />,
    //   },
    //   {
    //     path: "labour-contract/edit/:id",
    //     element: <LabourContracts />,
    //   },
    // ],
  },
  {
    path: "/labour-contract/edit/:id",
    element: <LabourContracts />,
    // children: [
    //   {
    //     path: "labour-contract/:id",
    //     element: <LabourContracts />,
    //   },
    //   {
    //     path: "labour-contract/edit/:id",
    //     element: <LabourContracts />,
    //   },
    // ],
  },
  {
    path: "/leaves",
    element: <DashboardLayout />,
    children: [
      {
        path: "/leaves/removal",
        element: <Removal />,
      },
      {
        path: "/leaves/bulletin",
        element: <Leave />,
      },
      {
        path: "/leaves/dismissal",
        element: <Dismissal />,
      },
      {
        path: "/leaves/unknown-reason",
        element: <UnknownReason />,
      },
    ],
  },
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "chat",
        element: <Chat />,
      },
    ],
  },
  {
    path: "pages",
    element: <DashboardLayout />,
    children: [
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      // {
      //   path: "chat",
      //   element: <Chat />,
      // },
      {
        path: "blank",
        element: <Blank />,
      },
    ],
  },
  {
    path: "projects",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Projects />,
      },
    ],
  },
  {
    path: "invoices",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <InvoiceList />,
      },
      {
        path: "detail",
        element: <InvoiceDetails />,
      },
    ],
  },
  {
    path: "orders",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Orders />,
      },
    ],
  },
  {
    path: "tasks",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <TaskManagement />,
      },
    ],
  },
  {
    path: "calendar",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Calendar />,
      },
    ],
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "getotp",
        element: <GetOtp />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "components",
    element: <DashboardLayout />,
    children: [
      {
        path: "accordion",
        element: <Accordion />,
      },
      {
        path: "alerts",
        element: <Alerts />,
      },
      {
        path: "avatars",
        element: <Avatars />,
      },
      {
        path: "badges",
        element: <Badges />,
      },
      {
        path: "buttons",
        element: <Buttons />,
      },
      {
        path: "cards",
        element: <Cards />,
      },
      {
        path: "chips",
        element: <Chips />,
      },
      {
        path: "dialogs",
        element: <Dialogs />,
      },
      {
        path: "lists",
        element: <Lists />,
      },
      {
        path: "menus",
        element: <Menus />,
      },
      {
        path: "pagination",
        element: <Pagination />,
      },
      {
        path: "progress",
        element: <Progress />,
      },
      {
        path: "snackbars",
        element: <Snackbars />,
      },
      {
        path: "tooltips",
        element: <Tooltips />,
      },
    ],
  },
  {
    path: "forms",
    element: <DashboardLayout />,
    children: [
      {
        path: "pickers",
        element: <Pickers />,
      },
      {
        path: "selection-controls",
        element: <SelectionCtrls />,
      },
      {
        path: "selects",
        element: <Selects />,
      },
      {
        path: "text-fields",
        element: <TextFields />,
      },
      {
        path: "editors",
        element: <Editors />,
      },
      {
        path: "formik",
        element: <Formik />,
      },
    ],
  },
  {
    path: "tables",
    element: <DashboardLayout />,
    children: [
      {
        path: "simple-table",
        element: <SimpleTable />,
      },
      {
        path: "advanced-table",
        element: <AdvancedTable />,
      },
      {
        path: "data-grid",
        element: <DataGrid />,
      },
    ],
  },
  {
    path: "icons",
    element: <DashboardLayout />,
    children: [
      {
        path: "material-icons",
        element: <MaterialIcons />,
      },
      {
        path: "feather-icons",
        element: <FeatherIcons />,
      },
    ],
  },
  {
    path: "charts",
    element: <DashboardLayout />,
    children: [
      {
        path: "chartjs",
        element: <Chartjs />,
      },
      {
        path: "apexcharts",
        element: <ApexCharts />,
      },
    ],
  },
  {
    path: "maps",
    element: <DashboardLayout />,
    children: [
      {
        path: "google-maps",
        element: <GoogleMaps />,
      },
      {
        path: "vector-maps",
        element: <VectorMaps />,
      },
    ],
  },
  {
    path: "documentation",
    element: <DocLayout />,
    children: [
      {
        path: "welcome",
        element: <Welcome />,
      },
      {
        path: "getting-started",
        element: <GettingStarted />,
      },
      {
        path: "routing",
        element: <Routing />,
      },
      {
        path: "auth/auth0",
        element: <Auth0 />,
      },
      {
        path: "auth/cognito",
        element: <Cognito />,
      },
      {
        path: "auth/firebase",
        element: <Firebase />,
      },
      {
        path: "auth/jwt",
        element: <JWT />,
      },
      {
        path: "guards",
        element: <Guards />,
      },
      {
        path: "environment-variables",
        element: <EnvironmentVariables />,
      },
      {
        path: "deployment",
        element: <Deployment />,
      },
      {
        path: "theming",
        element: <Theming />,
      },
      {
        path: "api-calls",
        element: <APICalls />,
      },
      {
        path: "redux",
        element: <Redux />,
      },
      {
        path: "internationalization",
        element: <Internationalization />,
      },
      {
        path: "eslint-and-prettier",
        element: <ESLintAndPrettier />,
      },
      {
        path: "support",
        element: <Support />,
      },
    ],
  },
  {
    path: "appointment-requests",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <AppointmentPage />,
      },
    ],
  },
  {
    path: "changelog",
    element: <DocLayout />,
    children: [
      {
        path: "",
        element: <Changelog />,
      },
    ],
  },
  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
  {
    path: "ticketing",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <TicketList />,
      },
      {
        path: "details/:id",
        element: <TicketingDetails />,
      },
      {
        path: "new",
        element: <NewTicket />,
      },
    ],
  },
  {
    path: "my-cabinet",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <MyProfile />,
      },
      {
        path: "mywages",
        element: <MyWages />,
      },
      {
        path: "vacations",
        element: <Vacations />,
      },
      {
        path: "inventories",
        element: <Inventories />,
      },
      {
        path: "requests",
        element: <MyRequests />,
      },
      {
        path: "sickleavecertificate",
        element: <SickLeaveCertificate />,
      },
      {
        path: "explanationsapplications",
        element: <ExplanationsApplications />,
      },
      {
        path: "orderscontracts",
        element: <OrdersContracts />,
      },
    ],
  },
  {
    path: "",
    element: <DashboardLayout />,
    children: [
      {
        path: "work-hours-graphic",
        element: <WorkingHoursGraphic />,
      },
      {
        path: "production-calendar",
        element: <ProductionCalendarPage />,
      },
      {
        path: "work-hour-account-table",
        element: <WorkHourAccountPage />,
      },
      {
        path: "graphic-settings",
        element: <GraphicSettings />,
      },
    ],
  },
];

export default routes;
