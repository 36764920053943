import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";

const EditChecklist = ({
  editChecklistDialog,
  setEditChecklistDialog,
  selectedChecklist,
  handleEditChecklist,
}) => {
  const [checklistName, setChecklistName] = useState();

  useEffect(() => {
    if (selectedChecklist) {
      setChecklistName(selectedChecklist?.title);
    }
  }, [editChecklistDialog]);

  return (
    <>
      <Dialog
        fullWidth
        open={editChecklistDialog}
        onClose={() => {
          setEditChecklistDialog(false);
          setChecklistName("");
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4">Yoxlama siyahısı dəyişdir</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Yoxlama siyahısı adı"
            type="text"
            value={checklistName}
            onChange={(e) => setChecklistName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setEditChecklistDialog(false);
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          <Button
            onClick={() => {
              setEditChecklistDialog(false);
              handleEditChecklist(selectedChecklist._id, checklistName);
            }}
            color="primary"
          >
            Dəyişdir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditChecklist;
