import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  templateData: null,
  templateDataById: null,
};

export const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    saveTemplateData: (state, action) => {
      state.templateData = action.payload;
    },
    getTemplateDataById: (state, action) => {
      state.templateDataById = action.payload;
    },
  },
});

export const { saveTemplateData, getTemplateDataById } = templateSlice.actions;

export default templateSlice.reducer;
