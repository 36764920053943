import React from "react";
import styled from "styled-components";
import ButtonCustom from "../../../../../../ui/Button/ButtonCustom";

const Container = styled.div`
  width: 100%;
  padding: 3px 0px;
  display: flex;
  justify-content: end;
  height: 100%;
`;

const Footer = ({
  isApplied,
  cancelHandler = (e) => {},
  applyHandler = (e) => {},
  resetHandler = (e) => {},
}) => {
  const cancel = () => {
    cancelHandler();
  };

  return (
    <div style={{ display: "flex", flexDirec: "row", height: "100%" }}>
      <ButtonCustom
        styleContainer={{
          // width: "1",
          height: "100%",
          width: "235px",
        }}
        width={"100%"}
        fontSize={"11px"}
        title={"Mövcud filter forumu sıfırla"}
        backgroundColor={"#710c04"}
        onClick={resetHandler}
      />
      <Container>
        {isApplied && (
          <ButtonCustom
            styleContainer={{
              width: "max-content",
              height: "100%",
              marginRight: "5px",
            }}
            fontSize={"11px"}
            title={"Cədvələ tətbiq edilmiş filterləri ləğv et"}
            onClick={cancel}
            backgroundColor={"#710c04"}
            disabled={!isApplied}
          />
        )}

        <ButtonCustom
          styleContainer={{ width: "max-content", height: "100%" }}
          fontSize={"11px"}
          title={"Tətbiq et"}
          onClick={applyHandler}
        />

        {/* <button style={{ zIndex: 99 }} onClick={cancel}>
        aaa
      </button> */}
      </Container>
    </div>
  );
};

export default Footer;
