import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { getAllEmployees, getEmployeeByUserId } from "../../../api/api";
import useAuth from "../../../hooks/useAuth";

import { useDispatch, useSelector } from "react-redux";
import {
  setAllEmployee,
  setCurrentEmployee,
} from "../../../redux/features/slices/commonThings.slice";

import CreateVacationForm from "../../../components/vacations/create/CreateVacationForm";
import { VACATION_TYPES } from "../../../constants";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function VacationRequestComponent() {
  const dispatch = useDispatch();
  // Selectors
  const { allEmployees } = useSelector((state) => state.commonThings);

  const { user } = useAuth();

  //   Get current employee
  useEffect(() => {
    if (user) {
      const getEmployees = async () => {
        try {
          const response = await getEmployeeByUserId(user.id);
          if (response && response.data) {
            dispatch(setCurrentEmployee(response.data));
          }
        } catch (err) {
          console.log(err);
        }
      };
      getEmployees();
    }
  }, [user]);

  let limit = 9999;

  //   Get all employee
  useEffect(() => {
    const getEmployees = async () => {
      try {
        const response = await getAllEmployees(limit);
        if (response && response.data) {
          dispatch(setAllEmployee(response?.data));
        }
      } catch (err) {
        console.log("error: ", err);
      }
    };
    getEmployees();
  }, []);

  if (!user || !allEmployees) {
    return <>Loading...</>;
  }

  const sourceOfVacationArray = [
    "2021/2022-ci il iş ili",
    "2022/2023-ci il iş ili",
  ];

  // const reasonArray = [
  //   { value: "free", name: "Ödənişsiz" },
  //   { value: "paid", name: "Ödənişli" },
  //   { value: "maternity", name: "Analıq" },
  // ];

  return (
    <Card mb={6}>
      <CardContent>
        <CreateVacationForm
          sourceOfVacationArray={sourceOfVacationArray}
          reasonArray={VACATION_TYPES}
        />
      </CardContent>
    </Card>
  );
}

function CreateVacationRequest() {
  return (
    <React.Fragment>
      <ToastContainer />

      <Helmet title="Məzuniyyət sorğusu yaradılması" />
      <Typography variant="h3" gutterBottom display="inline">
        Məzuniyyət sorğusunun yaradılması
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <VacationRequestComponent />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CreateVacationRequest;
