import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Modal } from "@mui/material";
import React from "react";
import HeaderDialog from "../../../../components/dialogs/HeaderDialog";
import GraphicForm from "../GraphicForm/GraphicForm";
import styles from "../GraphicModal/GraphicModal.module.css";

const GraphicModal = ({ handleClose, handleOpen, open, getData, setData }) => {
  return (
    <div className={styles.GraphicModal}>
      <Button
        className="GraphicModalBtn"
        onClick={handleOpen}
        variant="contained"
        startIcon={<AddIcon />}
      >
        Qrafik əlavə et
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.mainBox}>
          <HeaderDialog title={"İş qrafik əlavə et"} onClose={handleClose} />
          <Box className={styles.childBox}>
            <GraphicForm
              setData={setData}
              getData={getData}
              handleClose={handleClose}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default GraphicModal;
