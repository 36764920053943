import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

const DeleteChatDialog = ({
  selectedChat,
  handleDeleteChat,
  state,
  dispatch,
}) => {
  const { chatId, deleteChatDialog } = state;
  return (
    <Dialog
      open={deleteChatDialog}
      onClose={() =>
        dispatch({
          type: "SET_DELETE_CHAT_DIALOG",
          payload: false,
        })
      }
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Silmək istədiyinizdən əminsiniz ?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Bu əməliyyata davam etsəniz çat bütün istifadəçilər üçün silinəcək!
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() =>
            dispatch({
              type: "SET_DELETE_CHAT_DIALOG",
              payload: false,
            })
          }
          color="primary"
        >
          Ləğv et
        </Button>
        <Button
          onClick={() => {
            handleDeleteChat(chatId);
          }}
          color="primary"
        >
          Bəli
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteChatDialog;
