import React, { useEffect, useState } from "react";
import { Field, FieldArray, ErrorMessage, Formik, Form } from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { getCommonData } from "../../api/api";

const ViewSalarySupplements = ({ employeeById }) => {
  const [supplementName, setSupplementName] = useState([]);

  useEffect(() => {
    const handleSupplements = async () => {
      // const response = await getCommonData("supplementalWageLabour");
      const response = await getCommonData("supplementalWageGeneral");
      setSupplementName(response.data);
    };
    handleSupplements();
  }, []);

  const initialValues = {
    supplementalWages: employeeById.supplementalWages || [],
  };

  return (
    <>
      {employeeById ? (
        <Formik
          initialValues={{
            supplementalWages: initialValues.supplementalWages || [], // Set the initial laborActivity values from employeeById
          }}
        >
          {({
            values,
            isValid,
            errors,
            touched,
            isSubmitting,
            handleChange,
            setFieldValue,
          }) => (
            <Form style={{ pointerEvents: "none" }}>
              <FieldArray name="supplementalWages">
                {({ push, remove }) => (
                  <div>
                    {values.supplementalWages.map((form, index) => (
                      <div key={index}>
                        <>
                          <Typography mt={3} mb={3} variant="h6">
                            {index + 1 + ")"}Əməkhaqqına əlavələr
                          </Typography>

                          <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                            <Grid sx={{ width: "100%" }}>
                              <FormControl fullWidth>
                                <InputLabel
                                  id={`supplementalWages[${index}].name`}
                                >
                                  Əməkhaqqına əlavənin səbəbi
                                </InputLabel>
                                <Field
                                  as={Select}
                                  name={`supplementalWages[${index}].name`}
                                  labelId={`supplementalWages[${index}].name`}
                                  label="Əməkhaqqına əlavənin səbəbi"
                                  id={`supplementalWages[${index}].name`}
                                  fullWidth
                                >
                                  <MenuItem
                                    selected
                                    key={index}
                                    value={
                                      employeeById?.supplementalWages[index]
                                        ?.name
                                    }
                                  >
                                    {
                                      employeeById?.supplementalWages[index]
                                        ?.name
                                    }
                                  </MenuItem>
                                  {/* {supplementName.map((item, index) => (
                                    <MenuItem key={index} value={item.name}>
                                      {item.name}
                                    </MenuItem>
                                  ))} */}
                                </Field>
                              </FormControl>
                              <ErrorMessage
                                name={`supplementalWages[${index}].name`}
                                component="div"
                              />
                            </Grid>
                            <Grid sx={{ width: "100%" }}>
                              <Field
                                type="text"
                                value={values.supplementalWages[index].value}
                                as={TextField}
                                name={`supplementalWages[${index}].value`}
                                label="Əməkhaqqına əlavənin dəyəri"
                                fullWidth
                                InputProps={{
                                  readOnly: true,
                                  endAdornment:
                                    values.supplementalWages[index]
                                      .valueType === "number" ? (
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "19px",
                                        }}
                                      >
                                        &#8380;
                                      </span>
                                    ) : (
                                      <span>%</span>
                                    ),
                                }}
                              />
                            </Grid>
                          </Grid>
                        </>
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default ViewSalarySupplements;
