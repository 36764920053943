import { enqueueSnackbar } from "notistack";
import { api } from "../../api/axiosInterceptor";
import { BASE_URL } from "../../constants";

export const getAllProductionCalendar = async (
  id,
  year,
  startDate,
  endDate
) => {
  try {
    const response = await api.get(
      `/employee/prod-calendar?year=${year}&startDate=${startDate}&endDate=${endDate}&structureId=${id}`
    );
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const postProductionCalendar = async (id, year) => {
  try {
    const response = await api.post(
      `/employee/prod-calendar?year=${year}&structureId=${id}`
    );
    return await response.data;
  } catch (error) {
    if (
      error &&
      error?.response?.data?.errors?.[0]?.message === "Year already exists"
    ) {
      enqueueSnackbar(`${year} ili üçün təqvim artıq mövcuddur.`, {
        variant: "error",
      });
    }
    console.log("error: ", error);
  }
};

export const patchProductionCalendar = async (data) => {
  try {
    const response = await api.patch(`/employee/prod-calendar`, { ...data });
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const deleteProductionCalendar = async (data) => {
  try {
    const response = await api.delete(`/employee/prod-calendar`, {
      data: { ...data },
    });
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};
