import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "./axiosInterceptor";

export const getGraphicSettings = async (params) => {
  try {
    let endpoint = "employee/work-schedule";
    if (params && params.id) {
      endpoint += `/${params.id}`;
      delete params.id;
    }

    const response = await api.get(endpoint, { params: params });
    return response;
  } catch (err) {
    handleApiError(err);
  }
};

export const getGraphicSettingsDownloadCSV = async ({
  filter,
  columns,
  isWithFilter,
}) => {
  const str = isWithFilter
    ? `/employee/work-schedule/download?filter=${filter}&columns=${columns}`
    : `/employee/work-schedule/download?columns=${columns}`;

  return await api.get(str);
};

export const postGraphicSetting = async (data) => {
  try {
    const response = await api.post(`employee/work-schedule/`, data);
    toast.success("Successfully added", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    handleApiError(err);
  }
};

export const updateGraphicSetting = async (id, data) => {
  try {
    const response = await api.put(`employee/work-schedule/${id}`, data);
    toast.success("Successfully updated", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response.data;
  } catch (err) {
    handleApiError(err);
  }
};

export const deleteGraphicSetting = async (id) => {
  try {
    const response = await api.delete(`employee/work-schedule/${id}`);
    toast.success("Successfully delete", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    handleApiError(err);
  }
};
const handleApiError = (err) => {
  err.response.data.errors.map((item) => {
    return toast.error(item.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  });
};
