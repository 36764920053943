import { Button, Grid, Typography } from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { getEmployees } from "../../services/employees/EmployeeServices";
import { getActiveStaffs } from "../../services/staff/StaffServices";
import DecisionFormControl from "./DecisionFormControl";
const DecisionForm = ({ dispatch, setIsValid }) => {
  const [formValues, setFormValues] = useState(null);
  const [activeEmps, setActiveEmps] = useState([]);
  const [employeesData, setEmployeesData] = useState(null);
  const chainEnd = useRef(null);

  let uri = [
    {
      column: "status",
      operator: "equalsTo",
      value: "active",
    },
  ];

  let encode = encodeURI(JSON.stringify(uri));

  useEffect(() => {
    const getEmps = async () => {
      try {
        const { data } = await getActiveStaffs();
        setActiveEmps(data.records);
      } catch (error) {
        console.log("err", error);
      }
    };

    getEmps();
  }, []);

  useEffect(() => {
    if (formValues) {
      dispatch({
        type: "SET_APPROVEMENT_CHAIN",
        payload: formValues.decisionChainArray,
      });
    }
  }, [formValues]);

  const scrollLastItem = () => {
    setTimeout(() => {
      const lastMessageRef = chainEnd.current?.lastElementChild;
      if (lastMessageRef) {
        lastMessageRef.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getEmployees({ ...formValues, filter: encode });
        setEmployeesData(data);
      } catch (error) {
        console.log("Error fetching employees:", error);
      }
    };

    if (formValues) {
      fetchData();
    }
  }, [formValues, encode]);

  const schema = () =>
    Yup.object().shape({
      decisionChainArray: Yup.array()
        .of(
          Yup.mixed().test(
            "is-equal",
            "Təsdiqləyici əməkdaş eyni ola bilməz",
            function (value, { parent }) {
              if (
                typeof value === "object" &&
                Object.keys(value).length === 0
              ) {
                setIsValid(true);
                return this.createError({
                  message: "Zəhmət olmasa təsdiqləyici əməkdaşı seçin",
                  path: this.path,
                });
              }

              const counts = parent.reduce((acc, elem) => {
                acc[elem] = (acc[elem] || 0) + 1;
                return acc;
              }, {});
              const allUnique = Object?.values(counts)?.every(
                (count) => count === 1
              );
              setIsValid(!allUnique);

              return counts[value] === 1;
            }
          )
        )
        .required("Zəhmət olmasa təsdiqləyici əməkdaşı seçin"),
    });

  return (
    <Formik
      initialValues={{
        decisionChainArray: [],
      }}
      validationSchema={schema}
    >
      {({ values, handleChange }) => {
        setFormValues(values);
        return (
          <Form>
            <FieldArray name="decisionChainArray">
              {({ push, remove }) => (
                <div ref={chainEnd}>
                  {values.decisionChainArray.map((form, index) => (
                    <Grid
                      key={index}
                      sx={{
                        alignItems: "center",
                        mb: 3,
                      }}
                    >
                      <Typography>{index + 1}) Təsdiq edici</Typography>

                      {/* status type and date */}
                      <Grid
                        fullWidth
                        sx={{ display: "flex", gap: 3, mt: 5, mb: 3 }}
                      >
                        <DecisionFormControl
                          index={index}
                          remove={remove}
                          values={values}
                          handleChange={handleChange}
                          setFormValues={setFormValues}
                          employeesData={employeesData}
                        />
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ mt: 3 }}
                    variant="contained"
                    type="button"
                    onClick={() => {
                      push({});
                      scrollLastItem();
                    }}
                  >
                    Təsdiq edici əlavə et
                  </Button>
                </div>
              )}
            </FieldArray>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DecisionForm;
