import React, { createContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { BASE_URL } from "../constants";
import { getAccessToken } from "../utils/token";

const SocketContext = createContext();

const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [accessToken, setAccessToken] = useState(
    window.localStorage.getItem("accessToken")
  );

  useEffect(() => {
    const token = getAccessToken();

    const newSocket = io(`${BASE_URL.replace("/api", "")}`, {
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });

    setSocket(newSocket);

    // Cleanup the socket connection when the component unmounts
    return () => {
      newSocket.disconnect();
    };
  }, [accessToken]);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "accessToken") {
        setAccessToken(event.newValue);
      }
    };

    const handleCustomEvent = () => {
      setAccessToken(window.localStorage.getItem("accessToken"));
    };

    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("myCustomEvent", handleCustomEvent);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("myCustomEvent", handleCustomEvent);
    };
  }, []);

  useEffect(() => {
    if (socket && socket.on) {
      socket.on("connect", () => {
        // Socket connection is established
        console.log("Socket connected");
      });

      socket.on("connect_error", (error) => {
        // Connection error occurred
        console.error("Socket connection error:", error);
      });

      socket.on("connect_timeout", (timeout) => {
        // Connection timeout occurred
        console.error("Socket connection timeout:", timeout);
      });

      socket.on("error", (error) => {
        // Generic error event
        console.error("Socket error:", error);
      });
    }
  }, [socket]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };
