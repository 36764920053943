import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Card, CardContent, Grid, Pagination, Typography } from "@mui/material";
import { yellow } from "@mui/material/colors";
import moment from "moment";
import React from "react";
import { EMPLOYEE_REQUEST_TYPE_NORMALIZED_NAME } from "../../../../constants";

const ApproveCard = (props) => {
  const {
    cardTitles,
    approvedRequests,
    setHandleVacationDialog,
    setdialogTitle,
    setDialogColor,
    setDialogContent,
    totalRequests,
    limit,
    handlePagination,
    approver,
    approvePagination,
    setApprovePagination,
    dialogHandler,
  } = props;

  const getEmployeeDetails = (arr) => {
    const foundedChainItem = arr
      .slice()
      .reverse()
      .find((item) => item.status === "approved");

    if (!foundedChainItem) return "";

    const { firstname, lastname } = foundedChainItem?.staff?.employee[0];
    const position = foundedChainItem?.staff?.position?.name;

    return `${firstname} ${lastname} (${position})`;
  };
  return (
    <Card sx={{ width: "100%" }}>
      <CardContent>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 5,
            height: 60,
          }}
        >
          <Typography variant="h4">{cardTitles.approvedTitle}</Typography>
          <CheckCircleIcon sx={{ width: 30, height: 30 }} color="success" />
        </Grid>
        <Grid>
          {approvedRequests?.length === 0 && (
            <Typography color={yellow[500]} sx={{ mb: 8, mt: 10 }} variant="h6">
              Göstəriləcək sorğu yoxdur
            </Typography>
          )}
          {approvedRequests?.map((item, index) => (
            <Grid
              sx={{
                p: 3,
                border: 1,
                borderRadius: 1,
                borderColor: "rgba(27, 94, 32, 0.5)",
                mb: 5,
                backgroundColor: "rgba(27, 94, 32, 0.5)",
              }}
              key={index}
            >
              <Grid mb={1}>
                <Typography
                  sx={{
                    color: "#fff",
                    textAlign: "center",
                    borderBottom: "1px solid rgba(255,255,255, 0.1)",
                    fontSize: "14px ",
                  }}
                >
                  {EMPLOYEE_REQUEST_TYPE_NORMALIZED_NAME[item.docModel]}
                </Typography>
              </Grid>
              <Typography>Sorğu NO - {item?.requestNO}</Typography>
              <Typography>
                Sorğu Növü -{" "}
                {/* {item.docModel === "VacationRequest"
                  ? "Məzuniyyət / İşdən azadolma / İşə təyin olma"
                  : null} */}
                {EMPLOYEE_REQUEST_TYPE_NORMALIZED_NAME[item.docModel]}
              </Typography>
              <Typography>
                Sorğunun Yaradılma Tarixi -{" "}
                {moment(item?.originalRequest?.createdAt).format("DD/MM/yyyy")}
              </Typography>
              <Typography>
                Sorğunun Təsdiq Tarixi -{" "}
                {moment(item?.contract.decisionDate).format("DD/MM/yyyy") ||
                  "--/--/----"}
              </Typography>
              {approver ? (
                <Grid>
                  <Typography>
                    Sorğu Yaradan -{" "}
                    {item.creator?.firstname +
                      " " +
                      item.creator?.lastname +
                      " ( " +
                      item.creator?.position?.name +
                      " ) "}
                  </Typography>
                  <Typography>
                    Subyektin adı soyadı -{" "}
                    {item.originalRequest?.employee?.firstname &&
                      item.originalRequest?.employee?.lastname &&
                      `${item.originalRequest.employee.firstname} ${item.originalRequest.employee.lastname}`}
                  </Typography>
                  <Typography>
                    Struktur -{" "}
                    {item.decisionChain
                      ? item.decisionChain.topCompanyPartRel.source.name
                      : ""}
                  </Typography>
                </Grid>
              ) : (
                getEmployeeDetails(item.approvementChain) && (
                  <Typography>
                    Sorğunu Təsdiq Edən -{" "}
                    {/* {item.approvementChain
                    .slice()
                    .reverse()
                    .find((item) => item.status === "approved")?.staff
                    .employee[0].firstname +
                    " " +
                    item.approvementChain
                      .slice()
                      .reverse()
                      .find((employee) => employee.status === "approved")
                      ?.employee.lastname} */}
                    {getEmployeeDetails(item.approvementChain)}
                  </Typography>
                )
              )}
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: 2,
                }}
              >
                <RemoveRedEyeIcon
                  onClick={(e) => {
                    // setHandleVacationDialog(true);
                    setdialogTitle("Təsdiq Edilmiş Sorğu");
                    setDialogColor("#214833");
                    setDialogContent(item);
                    dialogHandler(item._id);
                  }}
                  sx={{
                    cursor: "pointer",
                    transition: "all 0.3s",
                    "&:hover": {
                      color: "#aaa",
                    },
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
        {approvedRequests?.length > 0 && (
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              height: "100%",
              marginTop: 2,
            }}
          >
            <Pagination
              size="small"
              count={Math.ceil(totalRequests?.approved / limit)}
              variant="outlined"
              shape="rounded"
              color="secondary"
              page={approvePagination}
              onChange={(event, value) => {
                setApprovePagination(value);
                handlePagination(value, "approved");
              }}
            />
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default ApproveCard;
