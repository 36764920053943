import { Grid, Typography } from "@mui/material";
import React from "react";

const VacationReporting = (props) => {
  const { reportingTitle, children } = props;

  return (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "space-between",
        gap: 8,
        alignItems: "center",
      }}
    >
      <Grid>
        <Typography noWrap variant="h3">
          {reportingTitle}
        </Typography>
      </Grid>
      <Grid sx={{ width: "100%", alignItems: "center" }}>{children}</Grid>
      {/* <Grid
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          px: 3,
          py: 2,
          borderRadius: 2,
          backgroundColor: "#0F4C75",
          cursor: "pointer",
        }}
      >
        <CloudDownloadIcon sx={{ width: 30, height: 30 }} />
        <Typography variant="h4">Yüklə</Typography>
      </Grid> */}
    </Grid>
  );
};

export default VacationReporting;
