import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfDay, isAfter } from "date-fns";
import { ErrorMessage, Field, FieldArray, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import {
  getAllStructures,
  getCommonData,
  getPositions,
  getTopParts,
} from "../../api/api";

const today = endOfDay(new Date());

const LaborActivity = ({
  values,
  setSelectedType,
  setOpenAddDialog,
  submitted,
  setStructuresIds,
  structuresIds,
}) => {
  const { handleChange } = useFormikContext();
  const [organizations, setOrganizations] = useState([]);
  const [positions, setPositions] = useState([]);
  const [otherStructures, setOtherStructures] = useState([]);
  const [mainOrganizations, setMainOrganizations] = useState([]);
  const [mainStructures, setMainStructures] = useState([]);
  const [mainPositions, setMainPositions] = useState([]);

  // Params for main structures api
  const [structuresParams, setStructuresParams] = useState({
    offset: 0,
    limit: 9999,
    structureId: "",
  });

  const { errors, setErrors, setFieldValue } = useFormikContext();

  useEffect(() => {
    const handleApi = async () => {
      try {
        const organizationsResponse = await getCommonData("organizations");
        const positionResponse = await getCommonData("positions");
        const otherStructures = await getCommonData("structures");
        const mainOrganizationsResponse = await getAllStructures();

        setOtherStructures(otherStructures?.data);
        setOrganizations(organizationsResponse?.data);
        setPositions(positionResponse?.data);
        setMainOrganizations(mainOrganizationsResponse?.data?.structures);
      } catch (error) {
        console.log("err", error);
      }
    };
    handleApi();
  }, [submitted]);

  const getStructuresAndPositions = async () => {
    try {
      if (structuresIds) {
        for (let index = 0; index < structuresIds?.length; index++) {
          try {
            const value = structuresIds[index];
            const payload = {
              offset: 0,
              limit: 9999,
              structureId: value,
            };

            const response = await getPositions(payload);
            const mainStructuresResponse = await getTopParts(payload);

            setMainPositions((prevItems) => {
              const updatedItems = [...prevItems];
              updatedItems[index] = response?.data?.parts;
              return updatedItems;
            });
            setMainStructures((prevItems) => {
              const updatedItems = [...prevItems];
              updatedItems[index] = mainStructuresResponse?.data?.parts;
              return updatedItems;
            });
          } catch (error) {
            console.log(error);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStructuresAndPositions();
  }, [structuresParams]);
  return (
    <FieldArray name="laborActivity">
      {({ push, remove }) => (
        <div>
          {values?.laborActivity &&
            values?.laborActivity.map((form, index) => {
              return (
                <div key={`${form} ${index}`}>
                  <>
                    <Typography mt={3} mb={3} variant="h6">
                      {index + 1})Əmək fəaliyyəti
                    </Typography>

                    {/* Organization name and department */}
                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Grid sx={{ width: "100%" }}>
                        {values?.laborActivity[index]?.sameWorkplace ? (
                          <FormControl
                            error={
                              errors?.laborActivity?.[index]
                                ?.organizationName &&
                              Boolean(
                                errors?.laborActivity?.[index]?.organizationName
                              )
                            }
                            fullWidth
                          >
                            <InputLabel
                              id={`laborActivity[${index}].organizationName`}
                            >
                              Təşkilatın adı *
                            </InputLabel>
                            <Field
                              as={Select}
                              name={`laborActivity[${index}].organizationName`}
                              labelId={`laborActivity[${index}].organizationName`}
                              label="Təşkilatın adı *"
                              id={`laborActivity[${index}].organizationName`}
                              error={
                                errors?.laborActivity?.[index]
                                  ?.organizationName &&
                                Boolean(
                                  errors?.laborActivity?.[index]
                                    ?.organizationName
                                )
                              }
                              fullWidth
                              onChange={(event) => {
                                setFieldValue(
                                  `laborActivity[${index}].organizationName`,
                                  event.target.value
                                );

                                if (
                                  errors?.laborActivity?.[index]
                                    ?.organizationName
                                ) {
                                  setErrors({
                                    ...errors,
                                    laborActivity: [
                                      ...errors.laborActivity.slice(0, index),
                                      {
                                        ...errors.laborActivity[index],
                                        organizationName: "",
                                      },
                                      ...errors.laborActivity.slice(index + 1),
                                    ],
                                  });
                                }
                              }}
                            >
                              {mainOrganizations &&
                                mainOrganizations
                                  .filter((item) => item?.organization?.name)
                                  .sort((a, b) =>
                                    a?.organization?.name.localeCompare(
                                      b?.organization?.name,
                                      "az"
                                    )
                                  )
                                  .reduce((acc, item) => {
                                    if (
                                      !acc.some(
                                        (i) =>
                                          i?.organization?.name ===
                                          item?.organization?.name
                                      )
                                    ) {
                                      acc.push(item);
                                    }
                                    return acc;
                                  }, [])
                                  .map((item, index) => (
                                    <MenuItem
                                      key={`${item._id} ${index}`}
                                      value={item?.organization?.name}
                                      onClick={() => {
                                        const updatedStructureIds = [
                                          ...structuresIds,
                                        ];

                                        updatedStructureIds[index] = item._id;

                                        setStructuresIds(updatedStructureIds);
                                        setStructuresParams((prevValues) => ({
                                          ...prevValues,
                                          structureId: item._id,
                                        }));
                                      }}
                                    >
                                      {item?.organization?.name}
                                    </MenuItem>
                                  ))}
                            </Field>
                            {errors?.laborActivity?.[index]?.organizationName &&
                              errors?.laborActivity?.[index]
                                ?.organizationName && (
                                <FormHelperText error>
                                  {
                                    errors.laborActivity?.[index]
                                      ?.organizationName
                                  }
                                </FormHelperText>
                              )}
                          </FormControl>
                        ) : (
                          <FormControl
                            error={
                              errors?.laborActivity?.[index]
                                ?.organizationName &&
                              Boolean(
                                errors?.laborActivity?.[index]?.organizationName
                              )
                            }
                            fullWidth
                          >
                            <InputLabel
                              id={`laborActivity[${index}].organizationName`}
                            >
                              Təşkilatın adı *
                            </InputLabel>
                            <Field
                              as={Select}
                              name={`laborActivity[${index}].organizationName`}
                              labelId={`laborActivity[${index}].organizationName`}
                              label="Təşkilatın adı"
                              id={`laborActivity[${index}].organizationName`}
                              defaultValue=""
                              error={
                                errors?.laborActivity?.[index]
                                  ?.organizationName &&
                                Boolean(
                                  errors?.laborActivity?.[index]
                                    ?.organizationName
                                )
                              }
                              fullWidth
                              onChange={(event) => {
                                setFieldValue(
                                  `laborActivity[${index}].organizationName`,
                                  event.target.value
                                );

                                if (
                                  errors?.laborActivity?.[index]
                                    ?.organizationName
                                ) {
                                  setErrors({
                                    ...errors,
                                    laborActivity: [
                                      ...errors.laborActivity.slice(0, index),
                                      {
                                        ...errors.laborActivity[index],
                                        organizationName: "",
                                      },
                                      ...errors.laborActivity.slice(index + 1),
                                    ],
                                  });
                                }
                              }}
                            >
                              {organizations &&
                                [...new Set(organizations)]
                                  .sort((a, b) => a.localeCompare(b, "az"))
                                  .map((item, index) => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                  ))}
                              <Button
                                sx={{
                                  mt: 3,
                                  ml: 3,
                                }}
                                variant="outlined"
                                onClick={() => {
                                  setOpenAddDialog(true);
                                  setSelectedType([
                                    "organizations",
                                    { name: "Təşkilatlar" },
                                  ]);
                                }}
                              >
                                Yeni təşkilat əlavə et
                              </Button>
                            </Field>
                            {errors.laborActivity?.[index]?.organizationName &&
                              errors.laborActivity?.[index]
                                ?.organizationName && (
                                <FormHelperText error>
                                  {
                                    errors.laborActivity?.[index]
                                      ?.organizationName
                                  }
                                </FormHelperText>
                              )}
                          </FormControl>
                        )}
                      </Grid>

                      <Grid sx={{ width: "100%" }}>
                        {!values.laborActivity[index]?.sameWorkplace ? (
                          <FormControl
                            error={
                              errors?.laborActivity?.[index]?.department &&
                              Boolean(
                                errors?.laborActivity?.[index]?.department
                              )
                            }
                            fullWidth
                          >
                            <InputLabel
                              id={`laborActivity[${index}].department`}
                            >
                              Struktur bölmə *
                            </InputLabel>
                            <Field
                              as={Select}
                              name={`laborActivity[${index}].department`}
                              labelId={`laborActivity[${index}].department`}
                              label="Struktur bölmə *"
                              id={`laborActivity[${index}].department`}
                              defaultValue=""
                              error={
                                errors?.laborActivity?.[index]?.department &&
                                Boolean(
                                  errors?.laborActivity?.[index]?.department
                                )
                              }
                              fullWidth
                              onChange={(event) => {
                                setFieldValue(
                                  `laborActivity[${index}].department`,
                                  event.target.value
                                );

                                if (
                                  errors?.laborActivity?.[index]?.department
                                ) {
                                  setErrors({
                                    ...errors,
                                    laborActivity: [
                                      ...errors.laborActivity.slice(0, index),
                                      {
                                        ...errors.laborActivity[index],
                                        department: "",
                                      },
                                      ...errors.laborActivity.slice(index + 1),
                                    ],
                                  });
                                }
                              }}
                            >
                              {otherStructures &&
                                [...new Set(otherStructures.filter(Boolean))]
                                  .sort((a, b) => a.localeCompare(b, "az"))
                                  .map((item, index) => (
                                    <MenuItem
                                      key={`${item} ${index}`}
                                      value={item}
                                    >
                                      {item}
                                    </MenuItem>
                                  ))}
                              <Button
                                sx={{
                                  mt: 3,
                                }}
                                variant="outlined"
                                onClick={() => {
                                  setOpenAddDialog(true);
                                  setSelectedType([
                                    "structures",
                                    { name: "Strukturlar" },
                                  ]);
                                }}
                              >
                                Yeni struktur bölmə əlavə et
                              </Button>
                            </Field>
                            {errors.laborActivity?.[index]?.department &&
                              errors.laborActivity?.[index]?.department && (
                                <FormHelperText error>
                                  {errors.laborActivity?.[index]?.department}
                                </FormHelperText>
                              )}
                          </FormControl>
                        ) : (
                          <FormControl
                            error={
                              errors?.laborActivity?.[index]?.department &&
                              Boolean(
                                errors?.laborActivity?.[index]?.department
                              )
                            }
                            fullWidth
                          >
                            <InputLabel
                              id={`laborActivity[${index}].department`}
                            >
                              {mainStructures[index]?.length === 0
                                ? "Göstəriləcək struktur bölmə yoxdur *"
                                : "Struktur bölmə *"}
                            </InputLabel>
                            <Field
                              as={Select}
                              name={`laborActivity[${index}].department`}
                              labelId={`laborActivity[${index}].department`}
                              label={
                                mainStructures[index]?.length === 0
                                  ? "Göstəriləcək struktur bölmə yoxdur *"
                                  : "Struktur bölmə *"
                              }
                              id={`laborActivity[${index}].department`}
                              defaultValue=""
                              disabled={mainStructures[index]?.length === 0}
                              error={
                                errors?.laborActivity?.[index]?.department &&
                                Boolean(
                                  errors?.laborActivity?.[index]?.department
                                )
                              }
                              fullWidth
                              onChange={(event) => {
                                setFieldValue(
                                  `laborActivity[${index}].department`,
                                  event.target.value
                                );

                                if (
                                  errors?.laborActivity?.[index]?.department
                                ) {
                                  setErrors({
                                    ...errors,
                                    laborActivity: [
                                      ...errors.laborActivity.slice(0, index),
                                      {
                                        ...errors.laborActivity[index],
                                        department: "",
                                      },
                                      ...errors.laborActivity.slice(index + 1),
                                    ],
                                  });
                                }
                              }}
                            >
                              {mainStructures[index] &&
                                Array.from(
                                  new Set(
                                    mainStructures[index].map(
                                      (item) => item?.source?.name
                                    )
                                  )
                                )
                                  .sort((a, b) => a.localeCompare(b, "az"))
                                  .map((uniqueName, index) => (
                                    <MenuItem
                                      key={`${uniqueName}-${index}`}
                                      value={uniqueName}
                                    >
                                      {uniqueName}
                                    </MenuItem>
                                  ))}
                            </Field>
                            {errors.laborActivity?.[index]?.department &&
                              errors.laborActivity?.[index]?.department && (
                                <FormHelperText error>
                                  {errors?.laborActivity?.[index]?.department}
                                </FormHelperText>
                              )}
                          </FormControl>
                        )}
                      </Grid>
                    </Grid>

                    {/* Position name and Civil Servant */}
                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Grid sx={{ width: "100%" }}>
                        {!values?.laborActivity[index]?.sameWorkplace ? (
                          <FormControl
                            error={
                              errors?.laborActivity?.[index]?.position &&
                              Boolean(errors?.laborActivity?.[index]?.position)
                            }
                            fullWidth
                          >
                            <InputLabel id={`laborActivity[${index}].position`}>
                              Vəzifə *
                            </InputLabel>
                            <Field
                              as={Select}
                              name={`laborActivity[${index}].position`}
                              labelId={`laborActivity[${index}].position`}
                              label="Vəzifə *"
                              id={`laborActivity[${index}].position`}
                              defaultValue=""
                              fullWidth
                              error={
                                errors?.laborActivity?.[index]?.position &&
                                Boolean(
                                  errors?.laborActivity?.[index]?.position
                                )
                              }
                              onChange={(event) => {
                                setFieldValue(
                                  `laborActivity[${index}].position`,
                                  event.target.value
                                );

                                if (errors?.laborActivity?.[index]?.position) {
                                  setErrors({
                                    ...errors,
                                    laborActivity: [
                                      ...errors.laborActivity.slice(0, index),
                                      {
                                        ...errors.laborActivity[index],
                                        position: "",
                                      },
                                      ...errors.laborActivity.slice(index + 1),
                                    ],
                                  });
                                }
                              }}
                            >
                              {positions &&
                                [...new Set(positions)]
                                  .sort((a, b) => a.localeCompare(b, "az"))
                                  .map((item, index) => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                  ))}
                              <Button
                                sx={{
                                  mt: 3,
                                  ml: 3,
                                }}
                                variant="outlined"
                                onClick={() => {
                                  setOpenAddDialog(true);
                                  setSelectedType([
                                    "positions",
                                    { name: "Vəzifələr" },
                                  ]);
                                }}
                              >
                                Yeni vəzifə əlavə et
                              </Button>
                            </Field>
                            {errors.laborActivity?.[index]?.position &&
                              errors.laborActivity?.[index]?.position && (
                                <FormHelperText error>
                                  {errors.laborActivity?.[index]?.position}
                                </FormHelperText>
                              )}
                          </FormControl>
                        ) : (
                          <FormControl
                            error={
                              errors?.laborActivity?.[index]?.position &&
                              Boolean(errors?.laborActivity?.[index]?.position)
                            }
                            fullWidth
                          >
                            <InputLabel id={`laborActivity[${index}].position`}>
                              {mainPositions[index]?.length === 0
                                ? "Göstəriləcək vəzifə yoxdur *"
                                : "Vəzifə *"}
                            </InputLabel>
                            <Field
                              as={Select}
                              name={`laborActivity[${index}].position`}
                              labelId={`laborActivity[${index}].position`}
                              label="Vəzifə *"
                              id={`laborActivity[${index}].position`}
                              disabled={
                                mainPositions[index]?.length === 0 &&
                                !values?.laborActivity[index]?.position
                              }
                              defaultValue=""
                              fullWidth
                              error={
                                errors?.laborActivity?.[index]?.position &&
                                Boolean(
                                  errors?.laborActivity?.[index]?.position
                                )
                              }
                              onChange={(event) => {
                                setFieldValue(
                                  `laborActivity[${index}].position`,
                                  event.target.value
                                );

                                if (errors?.laborActivity?.[index]?.position) {
                                  setErrors({
                                    ...errors,
                                    laborActivity: [
                                      ...errors.laborActivity.slice(0, index),
                                      {
                                        ...errors.laborActivity[index],
                                        position: "",
                                      },
                                      ...errors.laborActivity.slice(index + 1),
                                    ],
                                  });
                                }
                              }}
                            >
                              {mainPositions[index] &&
                                Array.from(
                                  new Set(
                                    mainPositions[index].map(
                                      (item) => item?.source?.name
                                    )
                                  )
                                )
                                  .sort((a, b) => a.localeCompare(b, "az"))
                                  .map((uniqueName, index) => (
                                    <MenuItem
                                      key={`${uniqueName}-${index}`}
                                      value={uniqueName}
                                    >
                                      {uniqueName}
                                    </MenuItem>
                                  ))}
                            </Field>
                            {errors.laborActivity?.[index]?.position &&
                              errors.laborActivity?.[index]?.position && (
                                <FormHelperText error>
                                  {errors.laborActivity?.[index]?.position}
                                </FormHelperText>
                              )}
                          </FormControl>
                        )}
                      </Grid>

                      <Grid sx={{ width: "100%" }}>
                        <FormControl
                          fullWidth
                          error={
                            errors?.laborActivity?.[index]?.isCivilServant &&
                            Boolean(
                              errors?.laborActivity?.[index]?.isCivilServant
                            )
                          }
                        >
                          <InputLabel
                            id={`laborActivity[${index}].isCivilServant`}
                          >
                            Dövlət qulluqçusu *
                          </InputLabel>
                          <Field
                            as={Select}
                            name={`laborActivity[${index}].isCivilServant`}
                            labelId={`laborActivity[${index}].isCivilServant`}
                            label="Dövlət qulluqçusu *"
                            id={`laborActivity[${index}].isCivilServant-select`}
                            defaultValue={false}
                            fullWidth
                            error={
                              errors?.laborActivity?.[index]?.isCivilServant &&
                              Boolean(
                                errors?.laborActivity?.[index]?.isCivilServant
                              )
                            }
                            onChange={(event) => {
                              setFieldValue(
                                `laborActivity[${index}].isCivilServant`,
                                event.target.value
                              );

                              if (
                                errors?.laborActivity?.[index]?.isCivilServant
                              ) {
                                setErrors({
                                  ...errors,
                                  laborActivity: [
                                    ...errors.laborActivity.slice(0, index),
                                    {
                                      ...errors.laborActivity[index],
                                      isCivilServant: "",
                                    },
                                    ...errors.laborActivity.slice(index + 1),
                                  ],
                                });
                              }
                            }}
                          >
                            <MenuItem value={true}>Bəli</MenuItem>
                            <MenuItem value={false}>Xeyr</MenuItem>
                          </Field>

                          {errors.laborActivity?.[index]?.isCivilServant &&
                            errors.laborActivity?.[index]?.isCivilServant && (
                              <FormHelperText error>
                                {errors.laborActivity?.[index]?.isCivilServant}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Job start and end date */}
                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Field name={`laborActivity[${index}].startDate`}>
                        {({ field, form }) => {
                          const endDate =
                            form?.values?.laborActivity[index]?.endDate;

                          return (
                            <DatePicker
                              {...field}
                              label="İşə başlama tarixi *"
                              inputFormat="dd/MM/yyyy"
                              value={field.value || null}
                              onChange={(date) => {
                                if (date && !isAfter(date, today)) {
                                  form.setFieldValue(
                                    `laborActivity[${index}].startDate`,
                                    date
                                  );
                                  if (
                                    errors?.laborActivity?.[index]?.startDate
                                  ) {
                                    setErrors({
                                      ...errors,
                                      laborActivity: [
                                        ...errors.laborActivity.slice(0, index),
                                        {
                                          ...errors.laborActivity[index],
                                          startDate: "",
                                        },
                                        ...errors.laborActivity.slice(
                                          index + 1
                                        ),
                                      ],
                                    });
                                  }
                                } else {
                                  form.setFieldValue(
                                    `laborActivity[${index}].startDate`,
                                    ""
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  helperText={
                                    form.errors?.laborActivity?.[index]
                                      ?.startDate
                                  }
                                  error={Boolean(
                                    form.errors?.laborActivity?.[index]
                                      ?.startDate
                                  )}
                                  fullWidth
                                />
                              )}
                              minDate={new Date(1940, 0, 1)}
                              maxDate={endDate ? endDate : today}
                              disableFuture
                              clearable
                              clearText="Clear"
                            />
                          );
                        }}
                      </Field>
                      {!values.laborActivity[index]?.present && (
                        <Field name={`laborActivity[${index}].endDate`}>
                          {({ field, form }) => {
                            const startDate =
                              form?.values?.laborActivity[index]?.startDate;
                            const present =
                              values.laborActivity[index]?.present;
                            return (
                              <DatePicker
                                {...field}
                                label="İşin bitmə tarixi *"
                                inputFormat="dd/MM/yyyy"
                                value={present ? null : field.value || null}
                                onChange={(date) => {
                                  if (date && !isAfter(date, today)) {
                                    form.setFieldValue(
                                      `laborActivity[${index}].endDate`,
                                      date
                                    );
                                    if (
                                      errors?.laborActivity?.[index]?.endDate
                                    ) {
                                      setErrors({
                                        ...errors,
                                        laborActivity: [
                                          ...errors.laborActivity.slice(
                                            0,
                                            index
                                          ),
                                          {
                                            ...errors.laborActivity[index],
                                            endDate: "",
                                          },
                                          ...errors.laborActivity.slice(
                                            index + 1
                                          ),
                                        ],
                                      });
                                    }
                                  } else {
                                    form.setFieldValue(
                                      `laborActivity[${index}].endDate`,
                                      ""
                                    );
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    helperText={
                                      present
                                        ? null
                                        : form.errors?.laborActivity?.[index]
                                            ?.endDate
                                    }
                                    error={
                                      present
                                        ? null
                                        : Boolean(
                                            form.errors.laborActivity?.[index]
                                              ?.endDate
                                          )
                                    }
                                    fullWidth
                                  />
                                )}
                                minDate={
                                  startDate ? new Date(startDate) : undefined
                                }
                                maxDate={today}
                                disableFuture
                                clearable
                                clearText="Clear"
                                minDateMessage="End date should not be before the start date"
                                disabled={present}
                              />
                            );
                          }}
                        </Field>
                      )}
                    </Grid>

                    {/* Service period and same workplace */}
                    <Grid
                      sx={{ display: "flex", gap: 3, alignItems: "center" }}
                    >
                      {/* <Grid sx={{ width: "100%" }}>
                    <Field
                      type="text"
                      name={`laborActivity[${index}].workingHours`}
                      as={TextField}
                      fullWidth
                      label="İş saatları"
                    />
                    <ErrorMessage
                      name={`laborActivity[${index}].workingHours`}
                      component="div"
                    />
                  </Grid> */}
                      <Grid
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          ml: 2,
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                values.laborActivity[index]?.sameWorkplace ||
                                false
                              }
                              onChange={handleChange}
                              name={`laborActivity[${index}].sameWorkplace`}
                              color="primary"
                            />
                          }
                          label="Bu təşkilatda"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => {
                                const { checked } = e.target;
                                setFieldValue(
                                  `laborActivity[${index}].present`,
                                  checked
                                );
                                if (checked) {
                                  // If present is checked, set endDate to empty string
                                  setFieldValue(
                                    `laborActivity[${index}].endDate`,
                                    ""
                                  );
                                }
                              }}
                              name={`laborActivity[${index}].present`}
                              color="primary"
                              checked={
                                values.laborActivity[index]?.present || false
                              }
                            />
                          }
                          label="Hal hazırda işləyir"
                        />

                        <ErrorMessage
                          name={`laborActivity[${index}].sameWorkplace`}
                          component="div"
                        />
                      </Grid>
                    </Grid>
                  </>

                  <Button
                    sx={{ mt: 3 }}
                    variant="contained"
                    color="error"
                    type="button"
                    onClick={() => remove(index)}
                  >
                    Əmək fəaliyyətini sil
                  </Button>
                </div>
              );
            })}
          <Button
            sx={{ mt: 3 }}
            variant="contained"
            type="button"
            onClick={() =>
              push({
                sameWorkplace: false,
                present: false,
                organizationName: "",
                department: "",
                position: "",
                startDate: "",
                endDate: "",
                isCivilServant: false,
              })
            }
          >
            Yeni əmək fəaliyyəti əlavə et
          </Button>
        </div>
      )}
    </FieldArray>
  );
};

export default LaborActivity;
