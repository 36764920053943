import { api } from "../axiosInterceptor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const createStaticContract = async (contractName, data) => {
  try {
    const response = await api.post(
      `employee/contract/static/${contractName}`,
      data
    );

    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getContracts = async (limit) => {
  try {
    return await api.get(`/employee/doc-sign/?offset=0&limit=${limit}`);
  } catch (err) {
    err.response.data.errors?.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getFilteredContracts = async (offset, limit, status) => {
  try {
    return await api.get(
      `/employee/doc-sign/filter?offset=${offset}&limit=${limit}&status=${status}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const postCommentSignContract = async (data, id) => {
  try {
    return await api.post(`/employee/doc-sign/${id}`, data);
  } catch (error) {
    console.log("err", error);
  }
};

export const downloadContractRequestManager = async (params) => {
  return await api.get(`/employee/doc-sign/download`, params);
};

export const approveLabourContract = async (id) => {
  return await api.patch(`/employee/labour-contract/hr/${id}/approve`);
};

// export const downloadStaticContract = async (id) => {
//   try {
//     const response = await api.post(
//       `employee/contract/64d34df1c0eb697ac60d9b46/download`
//     );
//     toast.success("Successfully downloaded", {
//       position: "top-right",
//       autoClose: 5000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "dark",
//     });
//     return response; // Return the response data
//   } catch (err) {
//     err.response.data.errors.map((item) => {
//       return toast.error(item.message, {
//         position: "top-right",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "dark",
//       });
//     });
//   }
// };
