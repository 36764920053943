import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  GROUP_NAME,
  LABOUR_CHANGE_INPUT_FIELDS,
  LABOUR_CONTRACTS_CUSTOM_RENDERS_METHODS,
} from "../../../../../components/dialogs/documentDialog/dialogConstants";
import FieldItem from "../../../../../components/dialogs/documentDialog/dialogParts/parts/FieldItem";
import { formatDateTime } from "../../../../../utils/helperFunctions";

function LeftComponent({ data, changedState }) {
  const renderField = (field, index, groupKey) => {
    if (field.isHide) return;

    const label = field.label ? field.label : "__";
    const value = renderValue(field);

    return (
      <div
        style={{
          borderBottom: "1px solid rgba(255,255,255,0.1)",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "5px",
        }}
      >
        <div style={{}}>
          <FieldItem
            key={index}
            label={label}
            value={value}
            index={index}
            group={{ [field.key]: field.group }}
            disableLine={true}
            disableValue={true}
          />
        </div>
        {/* <TextField
          variant="standard"
          sx={{ width: "45%", marginTop: "15px" }}
        /> */}
        {handlerInput(field, groupKey)}
      </div>
    );
  };

  const handlerOnGroup = () => {
    const uiParts = [];

    if (!data) return;

    for (const key in data) {
      const group = data[key];

      const values = Object.values(group)[0];
      const groupKey = Object.keys(group)[0];

      const utils =
        LABOUR_CONTRACTS_CUSTOM_RENDERS_METHODS["labour-contract"]?.[groupKey];

      uiParts.push(
        <Accordion sx={{ backgroundColor: "rgba(0,0,0,0.1)" }} key={groupKey}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "16px",
                color: "rgba(200,200,200,1)",
              }}
            >
              {GROUP_NAME[groupKey]}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ marginX: "10px" }}>
            {values?.map((item, index) =>
              renderField(
                {
                  ...item,
                  renderValue: utils && utils[item.key],
                },
                index,
                groupKey
              )
            )}
          </AccordionDetails>
        </Accordion>
      );
    }
    return uiParts;
  };

  const renderValue = (field) => {
    if (!field.value) return "-";
    if (typeof field.value === "object") return;

    if (field.renderValue) {
      const render = field.renderValue;
      return render(field.value);
    }
    switch (field.type) {
      case "string":
        return field.value;
      case "date":
        if (!field.value) {
          return "__";
        }
        return formatDateTime(field.value);
      case "array":
        return "array";
      case "object":
        return "object";
      default:
        return field.value;
    }
  };

  const handlerInput = (field, groupKey) => {
    const prop = field?.key ? LABOUR_CHANGE_INPUT_FIELDS[field.key] : undefined;
    const [state, setState] = changedState;

    if (!prop) return;
    const { type, options, validations, customFields } = prop();

    switch (type) {
      case "select":
        return (
          <Select
            value={state[groupKey][field.key].value}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                [groupKey]: {
                  ...prev[groupKey],
                  [field.key]: { value: e.target.value, changed: true },
                },
              }))
            }
            sx={{ width: "100%", marginTop: "15px" }}
          >
            {options?.map((item) => (
              <MenuItem value={item.key}>{item.name}</MenuItem>
            ))}
          </Select>
        );
      case "text":
        return (
          <TextField
            value={state[groupKey][field.key].value}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                [groupKey]: {
                  ...prev[groupKey],
                  [field.key]: { value: e.target.value, changed: true },
                },
              }))
            }
            variant="standard"
            sx={{ width: "100%", marginTop: "15px" }}
          />
        );
      case "number":
        return (
          <TextField
            value={state[groupKey][field.key].value}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                [groupKey]: {
                  ...prev[groupKey],
                  [field.key]: { value: +e.target.value, changed: true },
                },
              }))
            }
            variant="standard"
            type="number"
            sx={{ width: "100%", marginTop: "15px" }}
          />
        );
      case "date":
        return (
          <TextField
            value={state[groupKey][field.key].value}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                [groupKey]: {
                  ...prev[groupKey],
                  [field.key]: { value: e.target.value, changed: true },
                },
              }))
            }
            variant="standard"
            type="date"
            sx={{ width: "100%", marginTop: "15px" }}
          />
        );
      case "input-select":
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TextField
              value={state[groupKey][field.key][customFields[0]]}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  [groupKey]: {
                    ...prev[groupKey],
                    [field.key]: {
                      ...prev[field.key],
                      [customFields[0]]: e.target.value,
                      changed: true,
                    },
                  },
                }))
              }
              variant="outlined"
              type="number"
              sx={{ width: "50%", marginTop: "15px" }}
            />
            <Select
              value={state[groupKey][field.key][customFields[1]]}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  [groupKey]: {
                    ...prev[groupKey],
                    [field.key]: {
                      ...prev[field.key],
                      [customFields[1]]: e.target.value,
                      changed: true,
                    },
                  },
                }))
              }
              sx={{ width: "50%", marginTop: "15px" }}
            >
              {options?.map((item) => (
                <MenuItem value={item.key}>{item.name}</MenuItem>
              ))}
            </Select>
          </div>
        );
      case "custom":
        return;
      case "time":
        return (
          <TextField
            type="time"
            value={state[groupKey][field.key].value}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                [groupKey]: {
                  ...prev[groupKey],
                  [field.key]: { value: e.target.value, changed: true },
                },
              }))
            }
            variant="standard"
            sx={{ width: "100%", marginTop: "15px" }}
          />
        );
      case "date-date":
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TextField
              value={state[groupKey][field.key][customFields[0]]}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  [groupKey]: {
                    ...prev[groupKey],
                    [field.key]: {
                      ...prev[field.key],
                      [customFields[0]]: e.target.value,
                      changed: true,
                    },
                  },
                }))
              }
              variant="outlined"
              type="date"
              sx={{ width: "50%", marginTop: "15px" }}
            />
            <TextField
              value={state[groupKey][field.key][customFields[1]]}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  [groupKey]: {
                    ...prev[groupKey],
                    [field.key]: {
                      ...prev[field.key],
                      [customFields[1]]: e.target.value,
                      changed: true,
                    },
                  },
                }))
              }
              variant="outlined"
              type="date"
              sx={{ width: "50%", marginTop: "15px" }}
            />
          </div>
        );
      default:
        return (
          <TextField
            value={state[groupKey][field.key].value}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                [field.key]: { value: e.target.value, changed: true },
              }))
            }
            variant="standard"
            sx={{ width: "100%", marginTop: "15px" }}
          />
        );
    }
  };

  return (
    <div>
      <div>{data && handlerOnGroup()}</div>
    </div>
  );
}

export default LeftComponent;
