import { Grid, Typography } from "@mui/material";
import React from "react";

export default function Circle({
  value,
  color,
  size,
  backgroundColor,
  borderWidth,
  textColor,
}) {
  const width = size > 0 ? 100 * size : 100;
  const height = size > 0 ? 100 * size : 100;
  const borderRadius = size > 0 ? 50 * size : 50;

  return (
    <Grid
      sx={{
        width: width,
        height: height,
        borderRadius: borderRadius,
        backgroundColor: backgroundColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: textColor,
        border: `${borderWidth > 0 ? borderWidth : 1}px solid ${color}`,
      }}
    >
      <Typography fontSize={20}>{value}</Typography>
    </Grid>
  );
}
