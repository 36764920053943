import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import moment from "moment";

// Components
import Header from "../../components/layouts/header/Header";
import Breadcrumb from "../../components/layouts/breadcrumb/Breadcrumb";
import MyCabinetNavbar from "./MyCabinetNavbar";

// Material UI
import { Divider, Grid } from "@mui/material";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";

// CSS
import style from "./myProfileStyle.module.css";
import { useSelector } from "react-redux";
import EmptyCard from "../vacation/empPage/VacationEmployeePage";
import { useDispatch } from "react-redux";
import { setCurrentEmployee } from "../../redux/features/slices/commonThings.slice";
import { getEmployeeByUserId } from "../../api/api";
import useAuth from "../../hooks/useAuth";
import Navbar from "./Navbar";

export default function Vacations() {
  const { user } = useAuth();

  const dispatch = useDispatch();

  const { currentEmployee } = useSelector((state) => state.commonThings);

  useEffect(() => {
    if (user) {
      const getUser = async () => {
        try {
          const response = await getEmployeeByUserId(user.id);
          if (response && response.data) {
            dispatch(setCurrentEmployee(response.data));
          }
        } catch (err) {
          console.log("error: ", err);
        }
      };
      getUser();
    }
  }, [user]);

  const [navbarStatus, setNavbarStatus] = useState(false);

  const showHandler = () => setNavbarStatus(!navbarStatus);

  // Header data
  const headerData = {
    title: "Məzuniyyətlər",
    variant: "h3",
  };

  const navbarStatusHandler = (currentValue) => {
    setNavbarStatus(currentValue);
  };

  return (
    <>
      <Grid
        className={style.navbarPlace}
        // onMouseEnter={showHandler}
        // onMouseLeave={showHandler}
      >
        {/* {navbarStatus === false ? (
          <Grid className={style.navbarProfile}>
            <BeachAccessIcon sx={{ width: "100%", height: "100%" }} />
          </Grid>
        ) : (
          <MyCabinetNavbar />
        )} */}
        <Navbar navbarStatus={navbarStatusHandler} />
      </Grid>
      <React.Fragment>
        <Grid>
          <Grid>
            <Grid>
              <Header data={headerData} />
            </Grid>
          </Grid>
          <ToastContainer />
          <Divider sx={{ marginTop: 5 }} />
          <Grid
            className={
              style.infoSection +
              " " +
              (navbarStatus ? style.animation2 : style.animation1)
            }
          >
            <Grid className={style.vacationSecondSection}>
              <EmptyCard />
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    </>
  );
}
