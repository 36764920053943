import { CloudDownload } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import DocSVG from "../../../../layouts/signing/dialog/docSVG/DocSVG";
import CommentInput from "./parts/CommentInput";

export default function DialogRightCont({
  comments,
  commentsProps,
  downloadBtnProps,
}) {
  /*
    comments = [
      {
        label: `Şərh - təsdiqləyici ${name}`
        value: 'tesdiq edirem',
      }
  ]
  -------- 
    downloadBtnProps = {
      generateHref: undefined, // exmaple: (id) => return `{baseUrl}/.../${id}/download`
      value: undefined,
      hide: false,
      disable: undefined,
    },
  --------
    commentsProps = {
      hide: false,
    },
  */

  const href = downloadBtnProps.generateHref
    ? downloadBtnProps.generateHref(downloadBtnProps.value)
    : "";

  const handlerComments = () => {
    if (!comments || !comments.length > 0) return;

    return comments.map((item, index) => {
      if (!item.label) return <p key={item}>Melumat duzgun deyil</p>;

      return <CommentInput key={index} label={item.label} value={item.value} />;
    });
  };

  return (
    <Grid sx={styles.container}>
      <Grid sx={styles.docCont}>
        <DocSVG style={styles.doc} />

        {/* downlaod button */}
        {!downloadBtnProps.hide ? (
          <Grid sx={styles.downBtnCont}>
            <IconButton
              sx={styles.downloadBtn}
              href={href}
              target="_blank"
              disabled={downloadBtnProps.disable}
            >
              <CloudDownload sx={{ fontSize: 32, marginRight: 2 }} />
              <Typography>Sənədi yüklə</Typography>
            </IconButton>
          </Grid>
        ) : null}
      </Grid>

      {!commentsProps.hide ? (
        <Grid sx={styles.commentCont}>{handlerComments()}</Grid>
      ) : null}
    </Grid>
  );
}

const styles = {
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  docCont: {
    height: "350px",
    position: "relative",
  },
  doc: {
    width: "260px",
    height: "350px",
    backgroundColor: "#fff",
  },
  downBtnCont: {
    width: "100%",
    position: "absolute",
    bottom: "20px",
    display: "flex",
    justifyContent: "center",
  },
  downloadBtn: {
    margin: "auto",
    width: "200px",
    backgroundColor: "#d9d9d9",
    borderRadius: 1,
    color: "rgba(0,0,0,0.7)",
    "&:hover": {
      backgroundColor: "grey",
      color: "#fff",
    },
  },
  commentCont: {
    width: "100%",
    paddingX: "20px",
    overflow: "hidden",
    height: "100%",
    overflow: "auto",
    marginTop: "5px",
  },
};
